export const UPDATE_CI_PROVIDER_REPOS = 'UPDATE_CI_PROVIDER_REPOS';
export const UPDATE_CI_PROVIDER_REPOS_PENDING = 'UPDATE_CI_PROVIDER_REPOS_PENDING';
export const UPDATE_CI_PROVIDER_REPOS_FULFILLED = 'UPDATE_CI_PROVIDER_REPOS_FULFILLED';
export const UPDATE_CI_PROVIDER_REPOS_REJECTED = 'UPDATE_CI_PROVIDER_REPOS_REJECTED';

export const GET_CI_PROVIDER_REPOS = 'GET_CI_PROVIDER_REPOS';
export const GET_CI_PROVIDER_REPOS_PENDING = 'GET_CI_PROVIDER_REPOS_PENDING';
export const GET_CI_PROVIDER_REPOS_FULFILLED = 'GET_CI_PROVIDER_REPOS_FULFILLED';
export const GET_CI_PROVIDER_REPOS_REJECTED = 'GET_CI_PROVIDER_REPOS_REJECTED';

export const FLUSH_CI_PROVIDER_REPOS = 'FLUSH_CI_PROVIDER_REPOS';

export const CREATE_NEW_PROJECT_WITH_SELECTED_REPO = 'CREATE_NEW_PROJECT_WITH_SELECTED_REPO';
export const CREATE_NEW_PROJECT_WITH_SELECTED_REPO_PENDING = 'CREATE_NEW_PROJECT_WITH_SELECTED_REPO_PENDING';
export const CREATE_NEW_PROJECT_WITH_SELECTED_REPO_FULFILLED = 'CREATE_NEW_PROJECT_WITH_SELECTED_REPO_FULFILLED';
export const CREATE_NEW_PROJECT_WITH_SELECTED_REPO_REJECTED = 'CREATE_NEW_PROJECT_WITH_SELECTED_REPO_REJECTED';
