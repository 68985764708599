import {
  GET_PULL_REQUEST_TEST_RUNS_LIST_PENDING,
  GET_PULL_REQUEST_TEST_RUNS_LIST_FULFILLED,
  GET_PULL_REQUEST_TEST_RUNS_LIST_REJECTED,
  GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS_PENDING,
  GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS_FULFILLED,
  GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS_REJECTED,
  SET_PULL_REQUEST_TEST_RUNS_SELECTED_HIDE_FORK,
  SET_PULL_REQUEST_TEST_RUNS_STATUSES_SELECTED,
  SET_PULL_REQUEST_TEST_RUNS_BRANCHES_SELECTED,
  SET_PULL_REQUEST_TEST_RUNS_TAGS_SELECTED,
} from 'store/constants/pull-request-test-runs';

const initialState = {
  filterId: '',
  testRunsFetching: false,
  testRunsFetched: false,
  testRunsError: null,
  testRunsFilterFetching: false,
  testRunsFilterFetched: false,
  testRunsFilterError: null,
  filterContent: [],
  selectedTestRunsStatuses: [],
  selectedTestRunsBranches: [],
  selectedTestRunsTags: [],
  content: [],
  isFetchingByInfiniteTable: false,
  afterKey: null,
  lastPage: false,
  hideForkBranch: false,
};

const TAGS = {
  main: 'main',
  filter: 'filter',
};

const generateId = (tag, action) => {
  const { ciProvider, repoOwner, repoName } = action.meta || {
    ciProvider: undefined,
    repoOwner: undefined,
    repoName: undefined,
  };
  return `${tag}-${ciProvider}-${repoOwner}-${repoName}`;
};

const getSelectedTestRunsBranches = (state, action) => {
  if (state.filterId === generateId(TAGS.filter, action)) {
    return state.selectedTestRunsBranches;
  }
  return [];
};

const getSelectedTestRunsStatuses = (state, action) => {
  const newFilterId = generateId(TAGS.filter, action);
  if (state.filterId === newFilterId) {
    return state.selectedTestRunsStatuses;
  }
  return [];
};

const getSelectedTestRunsTags = (state, action) => {
  const newFilterId = generateId(TAGS.filter, action);
  if (state.filterId === newFilterId) {
    return state.selectedTestRunsTags;
  }
  return [];
};

export function pullRequestTestRuns(state = initialState, action) {
  switch (action.type) {
    case GET_PULL_REQUEST_TEST_RUNS_LIST_PENDING:
      return {
        ...state,
        testRunsFetching: true,
        testRunsFetched: false,
        testRunsError: null,
        isFetchingByInfiniteTable: true,
        lastPage: false,
        afterKey: null,
      };
    case GET_PULL_REQUEST_TEST_RUNS_LIST_FULFILLED: {
      const testRunsNewData = { ...action.payload.data };

      if (action.meta.isFirstPage) {
        testRunsNewData.content = action.payload.data.content;
      } else {
        testRunsNewData.content = [...state.content, ...action.payload.data.content];
      }

      return {
        ...state,
        testRunsFetching: false,
        testRunsFetched: true,
        testRunsError: null,
        afterKey: action.payload.data.afterKey,
        lastPage: action.payload.data.lastPage,
        content: testRunsNewData.content,
        selectedTestRunsBranches: getSelectedTestRunsBranches(state, action),
        selectedTestRunsStatuses: getSelectedTestRunsStatuses(state, action),
        selectedTestRunsTags: getSelectedTestRunsTags(state, action),
      };
    }
    case GET_PULL_REQUEST_TEST_RUNS_LIST_REJECTED:
      return {
        ...state,
        content: [],
        testRunsFetching: false,
        testRunsFetched: false,
        testRunsError: action.payload,
        afterKey: null,
        lastPage: false,
        selectedTestRunsBranches: [],
        selectedTestRunsStatuses: [],
        selectedTestRunsTags: [],
      };

    case GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS_PENDING:
      return {
        ...state,
        testRunsFilterFetching: true,
        testRunsFilterFetched: false,
        testRunsFilterError: null,
      };
    case GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS_FULFILLED:
      return {
        ...state,
        filterId: generateId(TAGS.filter, action),
        testRunsFilterFetching: false,
        testRunsFilterFetched: true,
        testRunsFilterError: null,
        filterContent: action.payload.data,
        selectedTestRunsBranches: getSelectedTestRunsBranches(state, action),
        selectedTestRunsStatuses: getSelectedTestRunsStatuses(state, action),
        selectedTestRunsTags: getSelectedTestRunsTags(state, action),
      };
    case GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS_REJECTED:
      return {
        ...state,
        filterId: generateId(TAGS.filter, action),
        testRunsFilterFetching: false,
        testRunsFilterFetched: false,
        testRunsFilterError: action.payload,
        selectedTestRunsBranches: [],
        selectedTestRunsStatuses: [],
        selectedTestRunsTags: [],
      };

    case SET_PULL_REQUEST_TEST_RUNS_SELECTED_HIDE_FORK:
      return {
        ...state,
        selectedTestRunsBranches: [],
        selectedTestRunsStatuses: [],
        selectedTestRunsTags: [],
        hideForkBranch: action.payload.data,
      };

    case SET_PULL_REQUEST_TEST_RUNS_BRANCHES_SELECTED:
      return {
        ...state,
        selectedTestRunsBranches: action.payload.data,
      };
    case SET_PULL_REQUEST_TEST_RUNS_STATUSES_SELECTED:
      return {
        ...state,
        selectedTestRunsStatuses: action.payload.data,
      };
    case SET_PULL_REQUEST_TEST_RUNS_TAGS_SELECTED:
      return {
        ...state,
        selectedTestRunsTags: action.payload.data,
      };
    default:
      return state;
  }
}
