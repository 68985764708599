import React, { Component } from 'react';
import { Select } from 'semantic-ui-react';
import './TestCaseStatusFilter.scss';

const statusOptions = [
	{ key: 'All', value: 'All', text: 'All' },
	{ key: 'Successful', value: 'Successful', text: 'Successful' },
	{ key: 'Failed', value: 'Failed', text: 'Failed' },
	{ key: 'Skipped', value: 'Skipped', text: 'Skipped' },
	{ key: 'Aborted', value: 'Aborted', text: 'Aborted' },
];

export class TestCaseStatusFilter extends Component {
	render() {
		const { filter, handleChange } = this.props;

		return (
			<div className="status-filter-container">
				<Select
					size="mini"
					placeholder="Select Status"
					value={filter || 'All'}
					onChange={handleChange}
					options={statusOptions}
				/>
			</div>
		);
	}
}
