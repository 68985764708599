import md5 from 'react-native-md5';
import { getRandomIndex } from './random-util';
const COLOR_PALETTE = [
  '#DCDFE5',
  '#4367FD',
  '#03DAC5',
  '#8479E1',
  '#5AC8FA',
  '#8899A6',
  '#278EA5',
  '#5D8BF4',
  '#FFBD69',
  '#BC4873',
];

export function getStringColorByCalc(str) {
  let str_md5v = md5.str_md5(str);

  let hash = 0;
  for (let i = 0; i < str_md5v.length; i++) {
    hash = str_md5v.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

let copyColorPalette = [...COLOR_PALETTE];
const colorPaletteUseMap = {};

export function getStringColor(str) {
  let fnColor = colorPaletteUseMap[str];
  if (fnColor !== undefined) {
    return fnColor;
  } else {
    if (copyColorPalette.length > 0) {
      let colorIndex = 0;
      fnColor = copyColorPalette[colorIndex];
      colorPaletteUseMap[str] = fnColor;
      copyColorPalette.splice(colorIndex, 1);
      return fnColor;
    }
  }
  return getStringColorByCalc(str);
}

export function getRandomColor() {
  let colorIndex = getRandomIndex(COLOR_PALETTE.length);
  return COLOR_PALETTE[colorIndex];
}
