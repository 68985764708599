import {
  GET_MY_ORGANIZATION_USER_LIST,
  GET_USER_ACTIVE_ORGANIZATION_LIST,
  GET_USER_ALL_ORGANIZATION_LIST,
  UPDATE_ORGANIZATION,
  SWITCH_ORGANIZATION,
  INVITE_USERS_TO_CURRENT_ORGANIZATION,
  CHANGE_USER_ROLE_IN_ORGANIZATION,
  REMOVE_USER_FROM_ORGANIZATION,
  LEAVE_FROM_ORGANIZATION,
} from 'store/constants/organization';
import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';

export function getActiveOrganizationList() {
  return {
    type: GET_USER_ACTIVE_ORGANIZATION_LIST,
    payload: axios.get(CONFIG.apiUrl + '/organizations/accepted', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getAllOrganizationList() {
  return {
    type: GET_USER_ALL_ORGANIZATION_LIST,
    payload: axios.get(CONFIG.apiUrl + '/organizations', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getMyOrganizationUserList() {
  return {
    type: GET_MY_ORGANIZATION_USER_LIST,
    payload: axios.get(CONFIG.apiUrl + '/organizations/me/users', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function updateOrganizationName(organizationName) {
  return {
    type: UPDATE_ORGANIZATION,
    payload: axios.patch(
      CONFIG.apiUrl + '/organizations/me',
      { organizationName },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function switchOrganization(id) {
  return {
    type: SWITCH_ORGANIZATION,
    payload: axios.post(
      CONFIG.apiUrl + `/organizations/${id}/switch`,
      {},
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function inviteUsers(emailAddressList) {
  return {
    type: INVITE_USERS_TO_CURRENT_ORGANIZATION,
    payload: axios.post(
      CONFIG.apiUrl + '/organizations/me/invite',
      { emails: emailAddressList },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function changeUserRoleInOrganization(userId, role) {
  return {
    type: CHANGE_USER_ROLE_IN_ORGANIZATION,
    payload: axios.put(
      CONFIG.apiUrl + `/organizations/me/users/${userId}`,
      { role },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function removeUserFromOrganization(userId) {
  return {
    type: REMOVE_USER_FROM_ORGANIZATION,
    payload: axios.delete(CONFIG.apiUrl + `/organizations/me/users/${userId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function leaveOrganization(organizationId) {
  return {
    type: LEAVE_FROM_ORGANIZATION,
    payload: axios.delete(CONFIG.apiUrl + `/organizations/${organizationId}/leave`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
