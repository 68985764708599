import React from 'react';
import { hasArrayElement } from 'utils/array-util';
import './MostFailedTab.scss';

export const MostFailedTab = ({ onClick, data }) => {
  if (!hasArrayElement(data)) {
    return <div className="most-failed-text">Does not have failed test</div>;
  } else {
    return (
      <div className="most-failed-container">
        {data.map(item => {
          return (
            <div className="most-failed-text" onClick={() => onClick(item)} key={item.testRunId}>
              {item.testName}
            </div>
          );
        })}
      </div>
    );
  }
};
