import { connect } from 'react-redux';
import { RepositoryWorkflowRunsList } from 'components';
import { withRouter } from 'react-router';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { getPullRequestWorkflows } from 'store/actions/pull-request-workflow-runs';
import { getPullRequest_WorkflowRuns_WorkflowRunFilters } from 'store/actions/pull-request-workflow-runs-filter';

function mapStateToProps(store) {
  return {
    workflowRunsMeta: store.pullRequestWorkflowRunsMeta,
    workflowRuns: store.pullRequestWorkflowRuns,
  };
}

function mapDispatchToProps() {
  return {
    getWorkflowRuns(
      ciProvider,
      repoOwner,
      repoName,
      pageNo,
      pageSize,
      workflowIds,
      branches,
      conclusions,
      events,
      prId,
      hideForkBranches,
      onSuccess,
      onError,
    ) {
      dispatchWithExceptionHandler(
        getPullRequestWorkflows(
          ciProvider,
          repoOwner,
          repoName,
          pageNo,
          pageSize,
          workflowIds,
          branches,
          conclusions,
          events,
          prId,
          hideForkBranches,
        ),
        false, //Organization Switch caused Error
        onSuccess,
        onError,
      );
    },
    getWorkflowRunFilters(ciProvider, repoOwner, repoName, prId, hideForkBranches, onSuccess, onError) {
      dispatchWithExceptionHandler(
        getPullRequest_WorkflowRuns_WorkflowRunFilters(ciProvider, repoOwner, repoName, prId, hideForkBranches),
        false, //  Organization Switch caused Error
        onSuccess,
        onError,
      );
    },
  };
}

const RepositoryPRWorkflowRunsListContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepositoryWorkflowRunsList),
);
export default RepositoryPRWorkflowRunsListContainer;
