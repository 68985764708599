import { GET_PULL_REQUEST_WORKFLOW_RUNS_LIST } from 'store/constants/pull-request-workflow-runs';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

export function getPullRequestWorkflows(
  ciProvider,
  repoOwner,
  repoName,
  pageNo,
  pageSize,
  workflowIds,
  branches,
  conclusions,
  events,
  prId,
  hideFork,
) {
  const data = {}; //Fetch All Data..
  console.log('API is ', CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/workflow-runs`);
  if (pageNo !== undefined && pageSize !== undefined && workflowIds !== undefined) {
    data['page'] = pageNo;
    data['size'] = pageSize;
    data['workflowIds'] = workflowIds;
    data['branches'] = branches;
    data['conclusions'] = conclusions;
    data['events'] = events;
    data['pullRequestNumber'] = parseInt(prId + '');
    data['hideForkBranch'] = hideFork;
  }
  return {
    type: GET_PULL_REQUEST_WORKFLOW_RUNS_LIST,
    payload: axios.post(CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/workflow-runs`, data, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
