/* eslint-disable */
import thundraForesight from 'assets/images/foresight-logo.svg';
import liveLogo from 'assets/images/live-tag.svg';
import auth from 'auth/auth-factory';
import { Tooltip } from 'components';
import { ThemeButtonContainer, UserInvitationModalContainer } from 'containers';
import React from 'react';
import routeList, { computePath } from 'routes';
import { Icon, Image, Menu, Popup, Sidebar, TransitionablePortal } from 'semantic-ui-react';
import { CONFIG, isDemoEnv, isPublicDomain } from 'utils/config-util.js';
import { isUserInInvitedBillingAdminRole, isUserInInvitedUserRole } from 'utils/user-role-util.js';
import './ForesightTopbar.scss';

import avatarOwnerLogo from 'assets/images/avatar-owner.svg';
import { BlueButton } from 'custom-components/Button/Button';
import { Link } from 'react-router-dom';
import { isInPricingWhiteList } from 'utils/common-util';
import CircleLeftDays from './TopbarComponents/CircleLeftDays/CircleLeftDays';
import GlobalNotification from './TopbarComponents/GlobalNotification/GlobalNotification';
import { ALink } from 'custom-components/ALink/ALink';
import { DOMAIN_ICON, UI_ICON } from 'assets/font-icons/IconManager';
const maxActiveOrganizationListInTopBarCount = 3;

export default class ForesightTopbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserMenuOpen: false,
      isUserInvitationModalOpen: false,
      mobileMenuVisible: false,
      giveError: false,
    };
  }

  checkAppVersionAndClearStores = () => {
    const appVersion = process.env.REACT_APP_VERSION;
    const cacheAppVersion = localStorage.getItem('REACT_APP_VERSION');
    if (!cacheAppVersion) {
      localStorage.setItem('REACT_APP_VERSION', appVersion);
    } else if (appVersion !== cacheAppVersion) {
      console.log('ReduxStore Reset Called');
      this.props.clearReduxStore();
      localStorage.setItem('REACT_APP_VERSION', appVersion);
    }
  };

  isMenuItemActive = title => {
    return this.props.history.location.pathname.includes(title);
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.getOrganizationId(prevProps) !== this.getOrganizationId(this.props)) {
      this.fetchData();
    }
  }

  getOrganizationId = props => {
    if (props.userAccount) {
      const { userAccount } = props.userAccount;
      return userAccount.organization?.id;
    }
    return null;
  };

  fetchData = () => {
    const userRole = this.props.userAccount?.userAccount?.role;
    this.props.getActiveOrganizationList();
    const organizationId = this.getOrganizationId(this.props);

    // organizationId not null means that user is in organization
    // isDemoEnv false is for private domain
    // Role is not User means that user Admin,BillingAdmin,Owner
    // WhiteList Check important than ActiveSubscription Fetch Must Called
    if (organizationId && !isDemoEnv() && isUserInInvitedUserRole(userRole) == false) {
      this.props.getWhiteList(
        organizationId,
        data => {
          // success
          if (!isInPricingWhiteList(data?.value?.data)) {
            this.props.getActiveSubscription(organizationId);
          }
        },
        err => {
          //err
          console.log(err);
          this.props.getActiveSubscription(organizationId);
        },
      );
    }
  };

  renderUserMenuTriggerItem = () => {
    console.log('ForesightTopbar, renderUserMenuTriggerItem; userAccount: ', this.props.userAccount);
    const { userAccount } = this.props.userAccount;
    const userName = userAccount.fullName && userAccount.fullName.length > 0 ? userAccount.fullName : userAccount.email;
    let nameChar = '-';
    if (typeof userName === 'string' && userName.length > 0) {
      nameChar = userName.toUpperCase().charAt(0);
    }
    return (
      <Menu.Item
        className="user-item menu-item"
        as="a"
        onClick={() => {
          //console.log('renderUserMenuTriggerItem; onClick - handleUserMenuOpen');
          this.handleUserMenuOpen();
        }}
      >
        <div className="user-item-circle">{nameChar}</div>
      </Menu.Item>
    );
  };

  renderLiveIconTooltip = () => {
    return (
      <div>Every time these projects' CI pipeline is triggered, you can see the status of their build process.</div>
    );
  };

  handleUserMenuClose = () => {
    this.setState({ isUserMenuOpen: false });
  };

  handleUserMenuOpen = () => {
    this.setState({ isUserMenuOpen: true });
  };

  handleLocationPath = path => {
    this.props.history.push(path);
  };

  getMenuItemWithIconLogo = (title, subtitle, isSelected, isAccountOwner, isHomeButtonVisible) => {
    const text = title ? title.split(' ') : undefined;
    return (
      <div className="table-menu-item">
        {text ? (
          <div className={`menu-letter-avatar ${isSelected ? 'selected-item' : ''}`}>{`${text[0][0]}${
            text[1] ? text[1][0] : ''
          }`}</div>
        ) : (
          <i className={`${UI_ICON.PROFILE.AVATAR} avatar-icon`} />
        )}
        <div className={`${subtitle ? '' : 'with-owner-icon'} title-wrapper`}>
          <div className={`title ${isSelected ? 'selected-item' : ''}`}>{title}</div>
          <div className={`subtitle ${isSelected ? 'selected-item' : ''}`}>{subtitle}</div>
          {isAccountOwner && (
            <Popup
              className="owner-popup right"
              trigger={<Image className="avatar-owner-img" src={avatarOwnerLogo} fluid />}
              hideOnScroll={true}
              content="Owner"
              position="right center"
            />
          )}
        </div>
        {isHomeButtonVisible && <i className={DOMAIN_ICON.SELECTED_ORG + ' active-organization-icon'} />}
      </div>
    );
  };

  onUserAccountSuccess = user => {
    console.log('getUserAccount success => ', user);
  };

  onUserAccountError = error => {
    console.log('getUserAccount error => ', error);
  };

  onSwitchOrganizationSuccess = () => {
    if (this.props.match.path !== routeList.repositories.path) {
      this.props.history.push(routeList.repositories.path);
    } else {
      this.props.getRepos(
        () => {},
        () => {
          if (err?.response?.status === 401) {
            Sentry.captureMessage('Repo Fetching 401 ForesightTopbar onSwitchOrganizationSuccess');
          }
        },
      );
    }
    this.props.getUserAccount(this.onUserAccountSuccess, this.onUserAccountError);
  };

  handleInviteUserClick = () => {
    this.setState({
      isUserInvitationModalOpen: true,
    });
  };

  renderUserMenuPopup = () => {
    const { userAccount } = this.props.userAccount;
    const { userActiveOrganizationList } = this.props.organization;
    const isBillingAdmin = isUserInInvitedBillingAdminRole(userAccount.role);

    const orgList = [...userActiveOrganizationList];

    const homeOrganizationIndex = orgList.findIndex(item => item.invitedUser.active === true);
    const homeOrganization = orgList.splice(homeOrganizationIndex, 1);

    const orderedOrganizationList = [];
    orderedOrganizationList.push(...homeOrganization, ...orgList.slice(0, maxActiveOrganizationListInTopBarCount - 1));

    const userName = userAccount.fullName && userAccount.fullName.length > 0 ? userAccount.fullName : 'No name';
    return (
      <TransitionablePortal
        closeOnDocumentClick={true}
        onClose={this.handleUserMenuClose}
        closeOnEscape={false}
        open={this.state.isUserMenuOpen}
        transition={{ animation: 'fade', duration: 300 }}
      >
        <div className="custom-profile-menu-wrapper">
          <div className="menu-item-wrapper">
            <div className="transition-header">ACCOUNT</div>
            <div className="transition-icon-menu-item">
              <Link to={routeList.settingsProfile.path}>
                {this.getMenuItemWithIconLogo(userName, userAccount.email, true, false, false)}
              </Link>
            </div>
          </div>
          {!isUserInInvitedUserRole(userAccount.role) && (
            <div className="menu-item-wrapper">
              <div className="transition-header">CURRENT ORGANIZATION</div>
              {!isBillingAdmin && (
                <Link to={routeList.teamOrganization.path + '/organization'}>
                  <div className="transition-text-menu-item">Organization settings</div>
                </Link>
              )}
              {!isBillingAdmin && (
                <div onClick={this.handleInviteUserClick} className="transition-text-menu-item">
                  Invite user to organization
                </div>
              )}
              <Link to={routeList.teamOrganization.path + '/billing'}>
                <div className="transition-text-menu-item">Billing</div>
              </Link>
              {!isBillingAdmin && !isDemoEnv() && (
                <Link to={routeList.integrations.path}>
                  <div className="transition-text-menu-item">Integrations</div>
                </Link>
              )}
            </div>
          )}
          <div className="menu-item-wrapper">
            <div className="transition-header">ORGANIZATIONS</div>
            {orderedOrganizationList &&
              orderedOrganizationList.map(item => {
                return (
                  <div
                    onClick={() => {
                      this.props.switchOrganization(item.organization.id, this.onSwitchOrganizationSuccess);
                    }}
                    key={item.organization.id}
                    className="transition-icon-menu-item"
                  >
                    {this.getMenuItemWithIconLogo(
                      item.organization.organizationName,
                      undefined,
                      item.invitedUser.active,
                      item.invitedUser.accountOwner,
                      item.invitedUser.active,
                    )}
                  </div>
                );
              })}
            <Link to={routeList.organizationList.path}>
              <div className="transition-text-menu-item">View all organizations</div>
            </Link>
          </div>
          <div className="menu-item-wrapper">
            {!isDemoEnv() && (
              <a href={CONFIG.FORESIGHT_DEMO_ENV_URL} target="_blank" rel="noopener noreferrer">
                <div className="transition-text-menu-item single">Playground</div>
              </a>
            )}
            <a href="https://docs.runforesight.com" target="_blank" rel="noopener noreferrer">
              <div className="transition-text-menu-item single">Documentation</div>
            </a>

            <div className="transition-text-menu-item single" as="a" id={'sidebar-intercom-item'}>
              Get help
            </div>

            {false && <ThemeButtonContainer />}
            {isDemoEnv() && (
              <div className="transition-text-menu-item single" id="get-started-id">
                <a href="https://app.runforesight.com" target="_blank" rel="noopener noreferrer">
                  Get started
                </a>
              </div>
            )}
          </div>
          <div className="menu-item-wrapper no-border">
            <div onClick={() => auth.logout()} className="transition-text-menu-item single">
              Sign out
            </div>
          </div>
        </div>
      </TransitionablePortal>
    );
  };

  onUserInviteModalClose = () => {
    this.setState({
      isUserInvitationModalOpen: false,
    });
  };

  calculateLeftPricingDate = () => {
    const { activeSubscription } = this.props.activeSubscription;
    if (activeSubscription.trial) {
      const unitFirst = new Date(0);
      unitFirst.setUTCSeconds(activeSubscription.nextInvoiceDate);

      const trialEnd = new Date(unitFirst.toISOString().substring(0, 10));
      const now = new Date(new Date().toISOString().substring(0, 10));

      const diffTime = Math.abs(trialEnd - now);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    }
    return -1;
  };

  getPublicDomainName = () => {
    const host = window.location.host;
    if (typeof host === 'string') {
      const domainName = window.location.host.split('.app.runforesight')[0];
      return domainName.capitalize();
    }
    return '';
  };

  renderMobileMenu() {
    const { userAccount } = this.props.userAccount;
    const isBillingAdmin = isUserInInvitedBillingAdminRole(userAccount.role);
    const { mobileMenuVisible } = this.state;
    const leftDays = this.calculateLeftPricingDate();
    return (
      <Sidebar
        as={Menu}
        animation="push"
        direction="left"
        icon="labeled"
        inverted
        vertical
        onHide={() => this.setState({ mobileMenuVisible: false })}
        visible={mobileMenuVisible}
        width="thin"
      >
        {isDemoEnv() && !isPublicDomain() && (
          <Menu.Item id="live-tag-container">
            <Tooltip blackEdition={true} content={this.renderLiveIconTooltip}>
              <div className="image">
                <Image className="live-tag" src={liveLogo} fluid />
              </div>
            </Tooltip>
          </Menu.Item>
        )}
        {!isBillingAdmin && (
          <Menu.Item
            active={this.isMenuItemActive(routeList.repositories.path)}
            name="repositories"
            className="menu-item"
          >
            <Link to={routeList.repositories.path}>
              <div className="repositories-header">Repositories</div>
            </Link>
          </Menu.Item>
        )}
        {!isBillingAdmin && (
          <Menu.Item active={this.isMenuItemActive(routeList.workflows.path)} name="Workflows" className="menu-item">
            <Link to={routeList.workflows.path}>
              <div className="workflows-header">Workflows</div>
            </Link>
          </Menu.Item>
        )}
        {!isBillingAdmin && (
          <Menu.Item active={this.isMenuItemActive(routeList.testSuites.path)} name="TestSuites" className="menu-item">
            <Link to={routeList.testSuites.path}>
              <div className="repositories-header">Test Suites</div>
            </Link>
          </Menu.Item>
        )}
        {!isBillingAdmin && (
          <Menu.Item
            active={this.isMenuItemActive(routeList.workflows.highlights)}
            name="Highlights"
            className="menu-item"
          >
            <Link to={routeList.highlights.path}>
              <div className="highlight-header">Highlights</div>
            </Link>
          </Menu.Item>
        )}

        {!isBillingAdmin && !isDemoEnv() && (
          <Menu.Item active={this.isMenuItemActive(routeList.rules.path)} name="Rules" className="menu-item">
            <Link to={routeList.rules.path}>
              <div className="highlight-header">Rules</div>
            </Link>
          </Menu.Item>
        )}

        {leftDays !== -1 && (
          <div className="other-item">
            <CircleLeftDays daysValue={leftDays} />
          </div>
        )}
      </Sidebar>
    );
  }

  renderRepositoryMenu = isBillingAdmin => {
    if (isBillingAdmin) {
      return '';
    } else {
      return (
        <Menu.Item
          active={this.isMenuItemActive(routeList.repositories.path)}
          name="repositories"
          className="menu-item header"
        >
          <Link to={routeList.repositories.path}>
            <div className="repositories-header">Repositories</div>
          </Link>
        </Menu.Item>
      );
    }
  };

  renderWorkflowsMenu = billingAdmin => {
    if (billingAdmin) {
      return '';
    } else {
      return (
        <Menu.Item
          active={this.isMenuItemActive(routeList.workflows.path)}
          name="Workflows"
          className="menu-item header"
        >
          <Link to={routeList.workflows.path}>
            <div className="workflows-header">Workflows</div>
          </Link>
        </Menu.Item>
      );
    }
  };

  renderHighlightMenu = billingAdmin => {
    if (billingAdmin) {
      return '';
    } else {
      return (
        <Menu.Item
          active={this.isMenuItemActive(routeList.highlights.path)}
          name="Highlights"
          className="menu-item header"
        >
          <Link to={routeList.highlights.path}>
            <div className="highlight-header">Highlights</div>
          </Link>
        </Menu.Item>
      );
    }
  };

  renderTestMenu = billingAdmin => {
    if (billingAdmin) {
      return '';
    } else {
      return (
        <Menu.Item
          active={this.isMenuItemActive(routeList.testSuites.path)}
          name="TestSuites"
          className="menu-item header"
        >
          <Link to={routeList.testSuites.path}>
            <div className="highlight-header">Test Suites</div>
          </Link>
        </Menu.Item>
      );
    }
  };

  renderRepositoryAndHighlightMenu = isBillingAdmin => {
    if (isDemoEnv()) {
      return (
        <>
          {this.renderHighlightMenu(isBillingAdmin)}
          {this.renderWorkflowsMenu(isBillingAdmin)}
          {this.renderTestMenu(isBillingAdmin)}
          {this.renderRepositoryMenu(isBillingAdmin)}
        </>
      );
    } else {
      return (
        <>
          {this.renderRepositoryMenu(isBillingAdmin)}
          {this.renderHighlightMenu(isBillingAdmin)}
          {this.renderWorkflowsMenu(isBillingAdmin)}
          {this.renderTestMenu(isBillingAdmin)}
        </>
      );
    }
  };

  renderTopBar() {
    // console.log('ForesightTopbar, render; props: ', this.props);
    this.checkAppVersionAndClearStores();

    const { isUserInvitationModalOpen: isUserInvitationModalOpen } = this.state;
    const { userAccount } = this.props.userAccount;
    const isBillingAdmin = isUserInInvitedBillingAdminRole(userAccount.role);
    const isInvited = isUserInInvitedUserRole(userAccount.role);
    const leftDays = this.calculateLeftPricingDate();
    const { whiteList } = this.props.whiteList;
    if (this.state.giveError) {
      throw new Error('Error');
    }
    return (
      <>
        {!isInPricingWhiteList(whiteList) && leftDays !== -1 && (
          <GlobalNotification
            linkText="pick a plan"
            onClick={() => this.props.history.push('/organization/billing')}
            leftDays={leftDays}
          />
        )}
        {this.renderMobileMenu()}
        <div className="topbar-menu-wrapper">
          <Menu className="topbar-menu">
            <Menu.Item name="placeholder" id="menu-item-grid">
              <i
                className="icon-Iconsmenu"
                id="mobile-menu-icon"
                onClick={() => this.setState({ mobileMenuVisible: true })}
              />
              <Icon id="no-grid" />
            </Menu.Item>
            <Menu.Item name="logo" className="menu-item logo">
              <div className="thundra-test-analytics-logo">
                <div className="image">
                  <Image
                    src={thundraForesight}
                    fluid
                    onClick={() => this.props.history.push(computePath(routeList.repositories.path))}
                  />
                </div>
              </div>
            </Menu.Item>
            {isDemoEnv() && !isPublicDomain() && (
              <Menu.Item id="live-tag-container">
                <Tooltip blackEdition={true} content={this.renderLiveIconTooltip}>
                  <div className="image">
                    <Image className="live-tag" src={liveLogo} fluid />
                  </div>
                </Tooltip>
              </Menu.Item>
            )}
            {isDemoEnv() && isPublicDomain() && (
              <Menu.Item id="live-tag-container">
                <Tooltip blackEdition={true} content={this.getPublicDomainName()}>
                  <div className="public-domain-tag">{this.getPublicDomainName()}</div>
                </Tooltip>
              </Menu.Item>
            )}

            {this.renderRepositoryAndHighlightMenu(isBillingAdmin)}

            {!isBillingAdmin && !isDemoEnv() && (
              <Menu.Item active={this.isMenuItemActive(routeList.rules.path)} name="Rules" className="menu-item header">
                <Link to={routeList.rules.path}>
                  <div className="highlight-header">Rules</div>
                </Link>
              </Menu.Item>
            )}

            {
              <Menu.Menu position="right" className="docs">
                {!isBillingAdmin && !isInvited && !isDemoEnv() && (
                  <div className="doc-item-btn connect-btn-top-bar">
                    <BlueButton
                      onClick={() => this.handleLocationPath(routeList.startInstallGitHubAppTrigger.path)}
                      content="Connect Pipeline"
                    ></BlueButton>
                  </div>
                )}

                {!isInPricingWhiteList(whiteList) && leftDays !== -1 && (
                  <div className="other-item circle-left-days-classes">
                    <CircleLeftDays daysValue={leftDays} />
                  </div>
                )}
                {false && <ThemeButtonContainer />}

                {!isDemoEnv() && <Menu.Menu>{this.renderUserMenuTriggerItem()}</Menu.Menu>}
              </Menu.Menu>
            }
            {isUserInvitationModalOpen && !isDemoEnv() && (
              <UserInvitationModalContainer
                isUserInvitationModalOpen={isUserInvitationModalOpen}
                onUserInviteModalClose={this.onUserInviteModalClose}
              />
            )}
          </Menu>

          {isDemoEnv() && !isPublicDomain() && (
            <Menu id="topbar-banner">
              <span className="topbar-banner-text">
                You are exploring Foresight attached open-source projects.&nbsp;
              </span>
              <ALink
                href={`http://app.runforesight.com${routeList.signup.path}?utm_source=fslive&utm_medium=connect-your-repo`}
                className="start-link"
              >
                Connect your own repository
              </ALink>
            </Menu>
          )}

          {!isDemoEnv() && this.renderUserMenuPopup()}
        </div>
      </>
    );
  }

  render() {
    return this.renderTopBar();
  }
}
