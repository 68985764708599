import React, { Component } from 'react';
import './SuccessRatesWidget.scss';
import { WidgetCard } from 'components/HighlightsPage/HighlightParts/WidgetCard';
import { toFixed } from 'utils/math';

export class SuccessRatesWidget extends Component {

    constructor(props) {
        super(props);
        this.state = {
            chartData: [],
        };
    }

    componentDidMount() {
        this.convertHistogramData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.convertHistogramData();
        }
    }

    convertHistogramData() {
        const { data: { executionInfos, name } } = this.props;
        const chartData = {};


        for (const [key, value] of Object.entries(executionInfos).sort()) {
            const obj = chartData[key];
            if (obj) {
                chartData[key] = {
                    ...obj,
                    [name]: value.successRate,
                };
            }
            else {
                if (key !== 'repoWorkflowName') {
                    chartData[key] = {
                        [name]: value.successRate,
                        time: key,
                    };
                }
            }
        }

        const convertData = Object.values(chartData);
        this.setState({
            chartData: convertData,
        });
    }

    render() {
        const { chartData } = this.state;
        return (
            <div className='success-rate-timeline-chart'>
                <div className='chart-title'>Workflow Success Rates</div>
                <WidgetCard
                    chartDomain={[0, 100]}
                    chartData={chartData}
                    legendValueFormatter={(entry) => toFixed(entry.value, 0) + '%'}
                />
            </div>
        );
    }
}