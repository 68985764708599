import {
  GET_FAILED_TEST_CASE_FULFILLED,
  GET_FAILED_TEST_CASE_PENDING,
  GET_FAILED_TEST_CASE_REJECTED,
  GET_HAVE_TEST_RUNS_FULFILLED,
  GET_HAVE_TEST_RUNS_PENDING,
  GET_HAVE_TEST_RUNS_REJECTED,
  GET_JOB_CPU_METRICS_FULFILLED,
  GET_JOB_CPU_METRICS_PENDING,
  GET_JOB_CPU_METRICS_REJECTED,
  GET_JOB_HISTORY_FULFILLED,
  GET_JOB_HISTORY_PENDING,
  GET_JOB_HISTORY_REJECTED,
  GET_JOB_MEMORY_METRICS_FULFILLED,
  GET_JOB_MEMORY_METRICS_PENDING,
  GET_JOB_MEMORY_METRICS_REJECTED,
  GET_JOB_PROCESSES_FULFILLED,
  GET_JOB_PROCESSES_PENDING,
  GET_JOB_PROCESSES_REJECTED,
  GET_JOB_TAB_CPU_METRICS_FULFILLED,
  GET_JOB_TAB_CPU_METRICS_PENDING,
  GET_JOB_TAB_CPU_METRICS_REJECTED,
  GET_JOB_TAB_DISK_METRICS_FULFILLED,
  GET_JOB_TAB_DISK_METRICS_PENDING,
  GET_JOB_TAB_DISK_METRICS_REJECTED,
  GET_JOB_TAB_MEMORY_METRICS_FULFILLED,
  GET_JOB_TAB_MEMORY_METRICS_PENDING,
  GET_JOB_TAB_MEMORY_METRICS_REJECTED,
  GET_JOB_TAB_NETWORK_METRICS_FULFILLED,
  GET_JOB_TAB_NETWORK_METRICS_PENDING,
  GET_JOB_TAB_NETWORK_METRICS_REJECTED,
  GET_JOB_TEST_COUNTS_FULFILLED,
  GET_JOB_TEST_COUNTS_PENDING,
  GET_JOB_TEST_COUNTS_REJECTED,
  GET_MOST_FAILED_TEST_FULFILLED,
  GET_MOST_FAILED_TEST_PENDING,
  GET_MOST_FAILED_TEST_REJECTED,
  GET_STEP_CPU_METRICS_FULFILLED,
  GET_STEP_CPU_METRICS_PENDING,
  GET_STEP_CPU_METRICS_REJECTED,
  GET_STEP_HISTORY_FULFILLED,
  GET_STEP_HISTORY_PENDING,
  GET_STEP_HISTORY_REJECTED,
  GET_STEP_MEMORY_METRICS_FULFILLED,
  GET_STEP_MEMORY_METRICS_PENDING,
  GET_STEP_MEMORY_METRICS_REJECTED,
  GET_STEP_PROCESSES_FULFILLED,
  GET_STEP_PROCESSES_PENDING,
  GET_STEP_PROCESSES_REJECTED,
  GET_STEP_TAB_CPU_METRICS_FULFILLED,
  GET_STEP_TAB_CPU_METRICS_PENDING,
  GET_STEP_TAB_CPU_METRICS_REJECTED,
  GET_STEP_TAB_DISK_METRICS_FULFILLED,
  GET_STEP_TAB_DISK_METRICS_PENDING,
  GET_STEP_TAB_DISK_METRICS_REJECTED,
  GET_STEP_TAB_MEMORY_METRICS_FULFILLED,
  GET_STEP_TAB_MEMORY_METRICS_PENDING,
  GET_STEP_TAB_MEMORY_METRICS_REJECTED,
  GET_STEP_TAB_NETWORK_METRICS_FULFILLED,
  GET_STEP_TAB_NETWORK_METRICS_PENDING,
  GET_STEP_TAB_NETWORK_METRICS_REJECTED,
  GET_WORKFLOW_HISTORY_FULFILLED,
  GET_WORKFLOW_HISTORY_PENDING,
  GET_WORKFLOW_HISTORY_REJECTED,
  GET_WORKFLOW_TIMELINE_FULFILLED,
  GET_WORKFLOW_TIMELINE_PENDING,
  GET_WORKFLOW_TIMELINE_REJECTED,
} from 'store/constants/workflow-timeline';

const workflowrunTimelineInitialState = {
  workflowRunTimelineTraceChart: {},
  workflowRunTimelineTraceChartFetching: false,
  workflowRunTimelineTraceChartError: false,
  workflowRunTimelineTraceChartFetched: false,

  haveTestRunContent: {},
  haveTestRunContentFetching: false,
  haveTestRunContentError: false,
  haveTestRunContentFetched: false,

  mostFailedTestContent: {},
  mostFailedTestContentFetching: false,
  mostFailedTestContentError: false,
  mostFailedTestContentFetched: false,

  workflowHistoryContent: {},
  workflowHistoryContentFetching: false,
  workflowHistoryContentError: false,
  workflowHistoryContentFetched: false,

  /* TIME LINE JOB PAGE */
  jobTestCountContent: {},
  jobTestCountContentFetching: false,
  jobTestCountContentError: false,
  jobTestCountContentFetched: false,

  jobCpuMetricsContent: {},
  jobCpuMetricsContentFetching: false,
  jobCpuMetricsContentError: false,
  jobCpuMetricsContentFetched: false,

  jobMemoryMetricsContent: {},
  jobMemoryMetricsContentFetching: false,
  jobMemoryMetricsContentError: false,
  jobMemoryMetricsContentFetched: false,

  jobHistoryContent: {},
  jobHistoryContentFetching: false,
  jobHistoryContentError: false,
  jobHistoryContentFetched: false,

  /* TIMELINE STEP PAGE */
  stepHistoryContent: {},
  stepHistoryContentFetching: false,
  stepHistoryContentError: false,
  stepHistoryContentFetched: false,

  stepCpuMetricsContent: {},
  stepCpuMetricsContentFetching: false,
  stepCpuMetricsContentError: false,
  stepCpuMetricsContentFetched: false,

  stepMemoryMetricsContent: {},
  stepMemoryMetricsContentFetching: false,
  stepMemoryMetricsContentError: false,
  stepMemoryMetricsContentFetched: false,

  /* TIMELINE JOB TAB PAGE */
  jobFailedTestCaseContent: {},
  jobFailedTestCaseContentFetching: false,
  jobFailedTestCaseContentError: false,
  jobFailedTestCaseContentFetched: false,

  jobProcessesContent: {},
  jobProcessesContentFetching: false,
  jobProcessesContentError: false,
  jobProcessesContentFetched: false,

  jobTabCpuMetricsContent: {},
  jobTabCpuMetricsContentFetching: false,
  jobTabCpuMetricsContentError: false,
  jobTabCpuMetricsContentFetched: false,

  jobTabMemoryMetricsContent: {},
  jobTabMemoryMetricsContentFetching: false,
  jobTabMemoryMetricsContentError: false,
  jobTabMemoryMetricsContentFetched: false,

  jobTabDiskMetricsContent: {},
  jobTabDiskMetricsContentFetching: false,
  jobTabDiskMetricsContentError: false,
  jobTabDiskMetricsContentFetched: false,

  jobTabNetworkMetricsContent: {},
  jobTabNetworkMetricsContentFetching: false,
  jobTabNetworkMetricsContentError: false,
  jobTabNetworkMetricsContentFetched: false,

  /* TIMELINE STEP TAB PAGE */
  stepProcessesContent: {},
  stepProcessesContentFetching: false,
  stepProcessesContentError: false,
  stepProcessesContentFetched: false,

  stepTabCpuMetricsContent: {},
  stepTabCpuMetricsContentFetching: false,
  stepTabCpuMetricsContentError: false,
  stepTabCpuMetricsContentFetched: false,

  stepTabMemoryMetricsContent: {},
  stepTabMemoryMetricsContentFetching: false,
  stepTabMemoryMetricsContentError: false,
  stepTabMemoryMetricsContentFetched: false,

  stepTabDiskMetricsContent: {},
  stepTabDiskMetricsContentFetching: false,
  stepTabDiskMetricsContentError: false,
  stepTabDiskMetricsContentFetched: false,

  stepTabNetworkMetricsContent: {},
  stepTabNetworkMetricsContentFetching: false,
  stepTabNetworkMetricsContentError: false,
  stepTabNetworkMetricsContentFetched: false,
};

export default function workflowRunTimeline(state = workflowrunTimelineInitialState, action) {
  switch (action.type) {
    case GET_WORKFLOW_TIMELINE_PENDING:
      return {
        ...state,
        workflowRunTimelineTraceChartFetching: true,
        workflowRunTimelineTraceChartFetched: false,
        workflowRunTimelineTraceChartError: null,
      };

    case GET_WORKFLOW_TIMELINE_FULFILLED:
      return {
        ...state,
        workflowRunTimelineTraceChartFetching: false,
        workflowRunTimelineTraceChartFetched: true,
        workflowRunTimelineTraceChartError: false,
        workflowRunTimelineTraceChart: action.payload.data,
      };
    case GET_WORKFLOW_TIMELINE_REJECTED:
      return {
        ...state,
        workflowRunTimelineTraceChartFetching: false,
        workflowRunTimelineTraceChartFetched: false,
        workflowRunTimelineTraceChartError: true,
        workflowRunTimelineTraceChart: {},
      };

    case GET_HAVE_TEST_RUNS_PENDING:
      return {
        ...state,
        haveTestRunContentFetching: true,
        haveTestRunContentFetched: false,
        haveTestRunContentError: null,
      };

    case GET_HAVE_TEST_RUNS_FULFILLED:
      return {
        ...state,
        haveTestRunContentFetching: false,
        haveTestRunContentFetched: true,
        haveTestRunContentError: false,
        haveTestRunContent: action.payload.data,
      };
    case GET_HAVE_TEST_RUNS_REJECTED:
      return {
        ...state,
        haveTestRunContentFetching: false,
        haveTestRunContentFetched: false,
        haveTestRunContentError: true,
        haveTestRunContent: {},
      };

    case GET_MOST_FAILED_TEST_PENDING:
      return {
        ...state,
        mostFailedTestContentFetching: true,
        mostFailedTestContentFetched: false,
        mostFailedTestContentError: null,
      };

    case GET_MOST_FAILED_TEST_FULFILLED:
      return {
        ...state,
        mostFailedTestContentFetching: false,
        mostFailedTestContentFetched: true,
        mostFailedTestContentError: false,
        mostFailedTestContent: action.payload.data,
      };
    case GET_MOST_FAILED_TEST_REJECTED:
      return {
        ...state,
        mostFailedTestContentFetching: false,
        mostFailedTestContentFetched: false,
        mostFailedTestContentError: true,
        mostFailedTestContent: {},
      };

    case GET_WORKFLOW_HISTORY_PENDING:
      return {
        ...state,
        workflowHistoryContentFetching: true,
        workflowHistoryContentFetched: false,
        workflowHistoryContentError: null,
      };

    case GET_WORKFLOW_HISTORY_FULFILLED:
      return {
        ...state,
        workflowHistoryContentFetching: false,
        workflowHistoryContentFetched: true,
        workflowHistoryContentError: false,
        workflowHistoryContent: action.payload.data,
      };
    case GET_WORKFLOW_HISTORY_REJECTED:
      return {
        ...state,
        workflowHistoryContentFetching: false,
        workflowHistoryContentFetched: false,
        workflowHistoryContentError: true,
        workflowHistoryContent: {},
      };

    case GET_JOB_TEST_COUNTS_PENDING:
      return {
        ...state,
        jobTestCountContentFetching: true,
        jobTestCountContentFetched: false,
        jobTestCountContentError: null,
      };

    case GET_JOB_TEST_COUNTS_FULFILLED:
      return {
        ...state,
        jobTestCountContentFetching: false,
        jobTestCountContentFetched: true,
        jobTestCountContentError: false,
        jobTestCountContent: action.payload.data,
      };
    case GET_JOB_TEST_COUNTS_REJECTED:
      return {
        ...state,
        jobTestCountContentFetching: false,
        jobTestCountContentFetched: false,
        jobTestCountContentError: true,
        jobTestCountContent: {},
      };

    case GET_JOB_CPU_METRICS_PENDING:
      return {
        ...state,
        jobCpuMetricsContentFetching: true,
        jobCpuMetricsContentFetched: false,
        jobCpuMetricsContentError: null,
      };

    case GET_JOB_CPU_METRICS_FULFILLED:
      return {
        ...state,
        jobCpuMetricsContentFetching: false,
        jobCpuMetricsContentFetched: true,
        jobCpuMetricsContentError: false,
        jobCpuMetricsContent: action.payload.data,
      };
    case GET_JOB_CPU_METRICS_REJECTED:
      return {
        ...state,
        jobCpuMetricsContentFetching: false,
        jobCpuMetricsContentFetched: false,
        jobCpuMetricsContentError: true,
        jobCpuMetricsContent: {},
      };

    case GET_JOB_MEMORY_METRICS_PENDING:
      return {
        ...state,
        jobMemoryMetricsContentFetching: true,
        jobMemoryMetricsContentFetched: false,
        jobMemoryMetricsContentError: null,
      };

    case GET_JOB_MEMORY_METRICS_FULFILLED:
      return {
        ...state,
        jobMemoryMetricsContentFetching: false,
        jobMemoryMetricsContentFetched: true,
        jobMemoryMetricsContentError: false,
        jobMemoryMetricsContent: action.payload.data,
      };
    case GET_JOB_MEMORY_METRICS_REJECTED:
      return {
        ...state,
        jobMemoryMetricsContentFetching: false,
        jobMemoryMetricsContentFetched: false,
        jobMemoryMetricsContentError: true,
        jobMemoryMetricsContent: {},
      };

    case GET_JOB_HISTORY_PENDING:
      return {
        ...state,
        jobHistoryContentFetching: true,
        jobHistoryContentFetched: false,
        jobHistoryContentError: null,
      };

    case GET_JOB_HISTORY_FULFILLED:
      return {
        ...state,
        jobHistoryContentFetching: false,
        jobHistoryContentFetched: true,
        jobHistoryContentError: false,
        jobHistoryContent: action.payload.data,
      };
    case GET_JOB_HISTORY_REJECTED:
      return {
        ...state,
        jobHistoryContentFetching: false,
        jobHistoryContentFetched: false,
        jobHistoryContentError: true,
        jobHistoryContent: {},
      };

    case GET_STEP_HISTORY_PENDING:
      return {
        ...state,
        stepHistoryContentFetching: true,
        stepHistoryContentFetched: false,
        stepHistoryContentError: null,
      };

    case GET_STEP_HISTORY_FULFILLED:
      return {
        ...state,
        stepHistoryContentFetching: false,
        stepHistoryContentFetched: true,
        stepHistoryContentError: false,
        stepHistoryContent: action.payload.data,
      };
    case GET_STEP_HISTORY_REJECTED:
      return {
        ...state,
        stepHistoryContentFetching: false,
        stepHistoryContentFetched: false,
        stepHistoryContentError: true,
        stepHistoryContent: {},
      };

    case GET_STEP_CPU_METRICS_PENDING:
      return {
        ...state,
        stepCpuMetricsContentFetching: true,
        stepCpuMetricsContentFetched: false,
        stepCpuMetricsContentError: null,
      };

    case GET_STEP_CPU_METRICS_FULFILLED:
      return {
        ...state,
        stepCpuMetricsContentFetching: false,
        stepCpuMetricsContentFetched: true,
        stepCpuMetricsContentError: false,
        stepCpuMetricsContent: action.payload.data,
      };
    case GET_STEP_CPU_METRICS_REJECTED:
      return {
        ...state,
        stepCpuMetricsContentFetching: false,
        stepCpuMetricsContentFetched: false,
        stepCpuMetricsContentError: true,
        stepCpuMetricsContent: {},
      };

    case GET_STEP_MEMORY_METRICS_PENDING:
      return {
        ...state,
        stepMemoryMetricsContentFetching: true,
        stepMemoryMetricsContentFetched: false,
        stepMemoryMetricsContentError: null,
      };

    case GET_STEP_MEMORY_METRICS_FULFILLED:
      return {
        ...state,
        stepMemoryMetricsContentFetching: false,
        stepMemoryMetricsContentFetched: true,
        stepMemoryMetricsContentError: false,
        stepMemoryMetricsContent: action.payload.data,
      };
    case GET_STEP_MEMORY_METRICS_REJECTED:
      return {
        ...state,
        stepMemoryMetricsContentFetching: false,
        stepMemoryMetricsContentFetched: false,
        stepMemoryMetricsContentError: true,
        stepMemoryMetricsContent: {},
      };

    case GET_FAILED_TEST_CASE_PENDING:
      return {
        ...state,
        jobFailedTestCaseContentFetching: true,
        jobFailedTestCaseContentFetched: false,
        jobFailedTestCaseContentError: null,
      };

    case GET_FAILED_TEST_CASE_FULFILLED:
      return {
        ...state,
        jobFailedTestCaseContentFetching: false,
        jobFailedTestCaseContentFetched: true,
        jobFailedTestCaseContentError: false,
        jobFailedTestCaseContent: action.payload.data,
      };
    case GET_FAILED_TEST_CASE_REJECTED:
      return {
        ...state,
        jobFailedTestCaseContentFetching: false,
        jobFailedTestCaseContentFetched: false,
        jobFailedTestCaseContentError: true,
        jobFailedTestCaseContent: {},
      };

    case GET_JOB_PROCESSES_PENDING:
      return {
        ...state,
        jobProcessesContentFetching: true,
        jobProcessesContentFetched: false,
        jobProcessesContentError: null,
      };

    case GET_JOB_PROCESSES_FULFILLED:
      return {
        ...state,
        jobProcessesContentFetching: false,
        jobProcessesContentFetched: true,
        jobProcessesContentError: false,
        jobProcessesContent: action.payload.data,
      };
    case GET_JOB_PROCESSES_REJECTED:
      return {
        ...state,
        jobProcessesContentFetching: false,
        jobProcessesContentFetched: false,
        jobProcessesContentError: true,
        jobProcessesContent: {},
      };

    case GET_JOB_TAB_CPU_METRICS_PENDING:
      return {
        ...state,
        jobTabCpuMetricsContentFetching: true,
        jobTabCpuMetricsContentFetched: false,
        jobTabCpuMetricsContentError: null,
      };

    case GET_JOB_TAB_CPU_METRICS_FULFILLED:
      return {
        ...state,
        jobTabCpuMetricsContentFetching: false,
        jobTabCpuMetricsContentFetched: true,
        jobTabCpuMetricsContentError: false,
        jobTabCpuMetricsContent: action.payload.data,
      };
    case GET_JOB_TAB_CPU_METRICS_REJECTED:
      return {
        ...state,
        jobTabCpuMetricsContentFetching: false,
        jobTabCpuMetricsContentFetched: false,
        jobTabCpuMetricsContentError: true,
        jobTabCpuMetricsContent: {},
      };

    case GET_JOB_TAB_MEMORY_METRICS_PENDING:
      return {
        ...state,
        jobTabMemoryMetricsContentFetching: true,
        jobTabMemoryMetricsContentFetched: false,
        jobTabMemoryMetricsContentError: null,
      };

    case GET_JOB_TAB_MEMORY_METRICS_FULFILLED:
      return {
        ...state,
        jobTabMemoryMetricsContentFetching: false,
        jobTabMemoryMetricsContentFetched: true,
        jobTabMemoryMetricsContentError: false,
        jobTabMemoryMetricsContent: action.payload.data,
      };
    case GET_JOB_TAB_MEMORY_METRICS_REJECTED:
      return {
        ...state,
        jobTabMemoryMetricsContentFetching: false,
        jobTabMemoryMetricsContentFetched: false,
        jobTabMemoryMetricsContentError: true,
        jobTabMemoryMetricsContent: {},
      };

    case GET_JOB_TAB_DISK_METRICS_PENDING:
      return {
        ...state,
        jobTabDiskMetricsContentFetching: true,
        jobTabDiskMetricsContentFetched: false,
        jobTabDiskMetricsContentError: null,
      };

    case GET_JOB_TAB_DISK_METRICS_FULFILLED:
      return {
        ...state,
        jobTabDiskMetricsContentFetching: false,
        jobTabDiskMetricsContentFetched: true,
        jobTabDiskMetricsContentError: false,
        jobTabDiskMetricsContent: action.payload.data,
      };
    case GET_JOB_TAB_DISK_METRICS_REJECTED:
      return {
        ...state,
        jobTabDiskMetricsContentFetching: false,
        jobTabDiskMetricsContentFetched: false,
        jobTabDiskMetricsContentError: true,
        jobTabDiskMetricsContent: {},
      };

    case GET_JOB_TAB_NETWORK_METRICS_PENDING:
      return {
        ...state,
        jobTabNetworkMetricsContentFetching: true,
        jobTabNetworkMetricsContentFetched: false,
        jobTabNetworkMetricsContentError: null,
      };

    case GET_JOB_TAB_NETWORK_METRICS_FULFILLED:
      return {
        ...state,
        jobTabNetworkMetricsContentFetching: false,
        jobTabNetworkMetricsContentFetched: true,
        jobTabNetworkMetricsContentError: false,
        jobTabNetworkMetricsContent: action.payload.data,
      };
    case GET_JOB_TAB_NETWORK_METRICS_REJECTED:
      return {
        ...state,
        jobTabNetworkMetricsContentFetching: false,
        jobTabNetworkMetricsContentFetched: false,
        jobTabNetworkMetricsContentError: true,
        jobTabNetworkMetricsContent: {},
      };

    case GET_STEP_PROCESSES_PENDING:
      return {
        ...state,
        stepProcessesContentFetching: true,
        stepProcessesContentFetched: false,
        stepProcessesContentError: null,
      };

    case GET_STEP_PROCESSES_FULFILLED:
      return {
        ...state,
        stepProcessesContentFetching: false,
        stepProcessesContentFetched: true,
        stepProcessesContentError: false,
        stepProcessesContent: action.payload.data,
      };
    case GET_STEP_PROCESSES_REJECTED:
      return {
        ...state,
        stepProcessesContentFetching: false,
        stepProcessesContentFetched: false,
        stepProcessesContentError: true,
        stepProcessesContent: {},
      };

    case GET_STEP_TAB_CPU_METRICS_PENDING:
      return {
        ...state,
        stepTabCpuMetricsContentFetching: true,
        stepTabCpuMetricsContentFetched: false,
        stepTabCpuMetricsContentError: null,
      };

    case GET_STEP_TAB_CPU_METRICS_FULFILLED:
      return {
        ...state,
        stepTabCpuMetricsContentFetching: false,
        stepTabCpuMetricsContentFetched: true,
        stepTabCpuMetricsContentError: false,
        stepTabCpuMetricsContent: action.payload.data,
      };
    case GET_STEP_TAB_CPU_METRICS_REJECTED:
      return {
        ...state,
        stepTabCpuMetricsContentFetching: false,
        stepTabCpuMetricsContentFetched: false,
        stepTabCpuMetricsContentError: true,
        stepTabCpuMetricsContent: {},
      };

    case GET_STEP_TAB_MEMORY_METRICS_PENDING:
      return {
        ...state,
        stepTabMemoryMetricsContentFetching: true,
        stepTabMemoryMetricsContentFetched: false,
        stepTabMemoryMetricsContentError: null,
      };

    case GET_STEP_TAB_MEMORY_METRICS_FULFILLED:
      return {
        ...state,
        stepTabMemoryMetricsContentFetching: false,
        stepTabMemoryMetricsContentFetched: true,
        stepTabMemoryMetricsContentError: false,
        stepTabMemoryMetricsContent: action.payload.data,
      };
    case GET_STEP_TAB_MEMORY_METRICS_REJECTED:
      return {
        ...state,
        stepTabMemoryMetricsContentFetching: false,
        stepTabMemoryMetricsContentFetched: false,
        stepTabMemoryMetricsContentError: true,
        stepTabMemoryMetricsContent: {},
      };

    case GET_STEP_TAB_DISK_METRICS_PENDING:
      return {
        ...state,
        stepTabDiskMetricsContentFetching: true,
        stepTabDiskMetricsContentFetched: false,
        stepTabDiskMetricsContentError: null,
      };

    case GET_STEP_TAB_DISK_METRICS_FULFILLED:
      return {
        ...state,
        stepTabDiskMetricsContentFetching: false,
        stepTabDiskMetricsContentFetched: true,
        stepTabDiskMetricsContentError: false,
        stepTabDiskMetricsContent: action.payload.data,
      };
    case GET_STEP_TAB_DISK_METRICS_REJECTED:
      return {
        ...state,
        stepTabDiskMetricsContentFetching: false,
        stepTabDiskMetricsContentFetched: false,
        stepTabDiskMetricsContentError: true,
        stepTabDiskMetricsContent: {},
      };

    case GET_STEP_TAB_NETWORK_METRICS_PENDING:
      return {
        ...state,
        jobTabNetworkMetricsContentFetching: true,
        jobTabNetworkMetricsContentFetched: false,
        jobTabNetworkMetricsContentError: null,
      };

    case GET_STEP_TAB_NETWORK_METRICS_FULFILLED:
      return {
        ...state,
        stepTabNetworkMetricsContentFetching: false,
        stepTabNetworkMetricsContentFetched: true,
        stepTabNetworkMetricsContentError: false,
        stepabNetworkMetricsContent: action.payload.data,
      };
    case GET_STEP_TAB_NETWORK_METRICS_REJECTED:
      return {
        ...state,
        stepTabNetworkMetricsContentFetching: false,
        stepTabNetworkMetricsContentFetched: false,
        stepTabNetworkMetricsContentError: true,
        stepTabNetworkMetricsContent: {},
      };

    default:
      return state;
  }
}
