import {
  GET_CPU_METRICS_FULFILLED,
  GET_CPU_METRICS_PENDING,
  GET_CPU_METRICS_REJECTED,
  GET_DISK_METRICS_FULFILLED,
  GET_DISK_METRICS_PENDING,
  GET_DISK_METRICS_REJECTED,
  GET_MEMORY_METRICS_FULFILLED,
  GET_MEMORY_METRICS_PENDING,
  GET_MEMORY_METRICS_REJECTED,
  GET_NETWORK_METRICS_FULFILLED,
  GET_NETWORK_METRICS_PENDING,
  GET_NETWORK_METRICS_REJECTED,
} from 'store/constants/workflow-metrics';

/* Setting the initial state of the reducer. */
const workflowMetricsInitial = {
  cpuMetrics: {
    fetching: false,
    fetched: false,
    error: null,
    data: {},
  },
  memoryMetrics: {
    fetching: false,
    fetched: false,
    error: null,
    data: {},
  },
  diskMetrics: {
    fetching: false,
    fetched: false,
    error: null,
    data: {},
  },
  networkMetrics: {
    fetching: false,
    fetched: false,
    error: null,
    data: {},
  },
};

/**
 * It takes the current state and an action and returns a new state
 * @param [state] - This is the current state of the reducer.
 * @param action - The action object that was dispatched.
 */
export default function workflowMetrics(state = workflowMetricsInitial, action) {
  switch (action.type) {
    // CPU metrics
    case GET_CPU_METRICS_PENDING:
      return {
        ...state,
        cpuMetrics: {
          fetching: true,
          fetched: false,
          error: false,
          data: {},
        },
      };
    case GET_CPU_METRICS_FULFILLED:
      return {
        ...state,
        cpuMetrics: {
          fetching: false,
          fetched: true,
          error: false,
          data: action.payload.data,
        },
      };
    case GET_CPU_METRICS_REJECTED:
      return {
        ...state,
        cpuMetrics: {
          fetching: false,
          fetched: false,
          error: true,
          data: {},
        },
      };

    // MEMORY METRICS

    case GET_MEMORY_METRICS_PENDING:
      return {
        ...state,
        memoryMetrics: {
          fetching: true,
          fetched: false,
          error: false,
          data: {},
        },
      };
    case GET_MEMORY_METRICS_FULFILLED:
      return {
        ...state,
        memoryMetrics: {
          fetching: false,
          fetched: true,
          error: false,
          data: action.payload.data,
        },
      };
    case GET_MEMORY_METRICS_REJECTED:
      return {
        ...state,
        memoryMetrics: {
          fetching: false,
          fetched: false,
          error: true,
          data: {},
        },
      };

    // DISK metrics
    case GET_DISK_METRICS_PENDING:
      return {
        ...state,
        diskMetrics: {
          fetching: true,
          fetched: false,
          error: false,
          data: {},
        },
      };
    case GET_DISK_METRICS_FULFILLED:
      return {
        ...state,
        diskMetrics: {
          fetching: false,
          fetched: true,
          error: false,
          data: action.payload.data,
        },
      };
    case GET_DISK_METRICS_REJECTED:
      return {
        ...state,
        diskMetrics: {
          fetching: false,
          fetched: false,
          error: true,
          data: {},
        },
      };

    // NETWORK metrics
    case GET_NETWORK_METRICS_PENDING:
      return {
        ...state,
        networkMetrics: {
          fetching: true,
          fetched: false,
          error: false,
          data: {},
        },
      };
    case GET_NETWORK_METRICS_FULFILLED:
      return {
        ...state,
        networkMetrics: {
          fetching: false,
          fetched: true,
          error: false,
          data: action.payload.data,
        },
      };
    case GET_NETWORK_METRICS_REJECTED:
      return {
        ...state,
        networkMetrics: {
          fetching: false,
          fetched: false,
          error: true,
          data: {},
        },
      };

    default:
      return state;
  }
}
