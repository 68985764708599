import ForesightBreadcrumb, { createForesightBreadCrumbDataItem } from 'custom-components/Breadcrumb/Breadcrumb';
import React, { Component } from 'react';
import routeList, { computePath } from 'routes';
import './RepositoryWorkflowBreadcrumb.scss';

export class RepositoryWorkflowBreadcrumb extends Component {
  createBreadCrumbDataItem = (text, data, handleOnClick) => {
    return {
      text: text,
      data: data,
      handleOnClick: handleOnClick,
    };
  };

  renderBreadcrumb = () => {
    const { workflowRunOverview } = this.props.workflowRuns;
    const { repo } = workflowRunOverview;
    const repoName = repo && repo.name ? repo.name : 'default-repo';

    const pathData = {};
    const data = [];
    data.push(
      createForesightBreadCrumbDataItem('Repositories', pathData, () => {
        this.props.history.push(routeList.repositories.path);
      }),
    );
    data.push(createForesightBreadCrumbDataItem(repoName, pathData));
    data.push(
      createForesightBreadCrumbDataItem('Workflow runs', pathData, () => {
        const ciProvider = repo.provider.toLowerCase();
        const repoOwner = repo.owner;
        const repoName = repo.name;
        this.props.history.push(
          computePath(routeList.repositoryWorkflowRuns.path, {
            ciProvider: ciProvider,
            repoOwner: repoOwner,
            repoName: repoName,
          }),
        );
      }),
    );
    data.push(createForesightBreadCrumbDataItem('Run Overview', pathData));

    return (
      <div className="breadcrumb">
        <ForesightBreadcrumb type="link-item" breadcrumbData={data} />
      </div>
    );
  };

  render() {
    return this.renderBreadcrumb();
  }
}
