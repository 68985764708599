import React, { Component } from 'react';
import './RepositoryWorkflowRunsFilter.scss';
import { Menu, Accordion, Icon } from 'semantic-ui-react';
import { LoadingDimmer, SomethingError } from 'components/ComponentPlaceholder';
import ForesightCheckboxList from 'custom-components/CheckboxList/CheckboxList';
import { getConclusionToReadableMapping } from 'utils/workflowrun-conclusion-status-tooltips';
import { ForesightCheckbox } from 'custom-components/Checkbox/Checkbox';
import { isEqualDeep } from 'utils/common-util';
import { hasArrayElement } from 'utils/array-util';
import _ from 'lodash';

const ACTIVATE_SCROLL_THRESHOLD = 7;

const spanStyle = {
  background: '#242c37',
  padding: '4px 8px',
  borderRadius: '50%',
  marginLeft: '7px',
  color: '#ffffff',
};

export default class RepositoryWorkflowRunsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStatusFilterGroupCollapsed: false,
      isBranchFilterGroupCollapsed: false,
      isWorkflowsGroupCollapsed: false,
      isEventsFilterGroupCollapsed: false,
      isLabelsFilterGroupCollapsed: false,
      filterListBranch: [],
    };
  }

  callStoreSelectedWorkflowIds = selectedWorkflowIds => {
    const { prId } = this.props;
    if (prId) {
      this.props.setSelectedWorkflowIds(selectedWorkflowIds, prId);
    } else {
      this.props.setSelectedWorkflowIds(selectedWorkflowIds);
    }
  };

  callStoreSelectedBranches = selectedBranches => {
    const { prId } = this.props;
    if (prId) {
      this.props.setSelectedBranches(selectedBranches, prId);
    } else {
      this.props.setSelectedBranches(selectedBranches);
    }
  };

  callStoreSelectedConclusions = selectedConclusions => {
    const { prId } = this.props;
    if (prId) {
      this.props.setSelectedConclusions(selectedConclusions, prId);
    } else {
      this.props.setSelectedConclusions(selectedConclusions);
    }
  };

  callStoreSelectedEvents = selectedEvents => {
    const { prId } = this.props;
    if (prId) {
      this.props.setSelectedEvents(selectedEvents, prId);
    } else {
      this.props.setSelectedEvents(selectedEvents);
    }
  };

  callStoreSelectedHideFork = selectedHideFork => {
    const { prId } = this.props;
    if (prId) {
      this.props.setSelectedHideFork(selectedHideFork, prId);
    } else {
      this.props.setSelectedHideFork(selectedHideFork);
    }
  };

  callStoreSelectedLabels = selectedLabels => {
    const { prId } = this.props;
    if (prId) {
      this.props.setSelectedLabels(selectedLabels, prId);
    } else {
      this.props.setSelectedLabels(selectedLabels);
    }
  };

  findWorkflowsElements = names => {
    const { workflowLabelsData, workflowRunsMeta } = this.props.workflowRunsMeta;
    const findItemsMap = names.map(name => workflowLabelsData.find(item => name === item.name));
    const allLabelWorkflowIds = findItemsMap.flatMap(item => item.workflowIds);
    const allWorkflowIds = workflowRunsMeta.flatMap(item => item.id);
    const filteredForWorkflowIds = allLabelWorkflowIds.filter(item => allWorkflowIds.includes(item));
    this.callStoreSelectedWorkflowIds(filteredForWorkflowIds);
  };

  /* The above code is handling the checkbox click event for the workflow filter. */
  handleWorkflowCheckboxClick = (id, data) => {
    const { workflowRunsMeta, selectedWorkflowIds } = this.props.workflowRunsMeta;
    const copyOfIds = [...selectedWorkflowIds];
    let findId = workflowRunsMeta.find(item => item.name === data.label);
    if (data.checked) {
      copyOfIds.push(findId?.id);
      this.callStoreSelectedWorkflowIds(copyOfIds);
    } else {
      const index = copyOfIds.filter(item => item !== findId?.id);
      this.callStoreSelectedWorkflowIds(index);
    }
  };

  /* A function that is called when the select all checkbox is clicked. It is used to filter the
  workflows. */
  handleWorkflowCheckboxClickAllFilter = (data, filteredData) => {
    if (data.checked) {
      const copyOfIds = filteredData.map(item => item.id);
      this.props.setSelectedWorkflowIds(copyOfIds);
    } else {
      this.callStoreSelectedWorkflowIds([]);
    }
  };

  renderWorkflowFilterList = () => {
    const { workflowRunsMeta, selectedWorkflowIds, fetching, fetched } = this.props.workflowRunsMeta;

    let filterWorkflowItems = workflowRunsMeta.map(el => {
      return { checked: selectedWorkflowIds.includes(el.id), name: el.name, id: el.id };
    });

    return (
      <ForesightCheckboxList
        searchBarPlaceholder="Search for a workflow"
        ellipsisLength={15}
        emptyJsx={<div style={{ marginTop: 10, fontWeight: 500 }}> Workflows Not Found </div>}
        parentName="Select All"
        data={filterWorkflowItems}
        loading={fetching}
        fetched={fetched}
        subHeader="Workflow Names"
        handleCheckboxOnClick={this.handleWorkflowCheckboxClick}
        handleSelectAllToggle={this.handleWorkflowCheckboxClickAllFilter}
      />
    );
  };

  /* A function that is called when a checkbox is clicked. It is used to filter the branches. */
  handleBranchCheckboxClickFilter = (event, data) => {
    const { selectedWorkflowRunBranches } = this.props.workflowRunsMeta;
    if (data.checked) {
      this.setState(
        prevState => {
          return {
            ...prevState,
            filterListBranch: [...selectedWorkflowRunBranches, data.label],
          };
        },
        () => this.callStoreSelectedBranches(this.state.filterListBranch),
      );
    } else {
      this.setState(
        prevState => {
          return {
            ...prevState,
            filterListBranch: selectedWorkflowRunBranches.filter(item => item !== data.label),
          };
        },
        () => this.callStoreSelectedBranches(this.state.filterListBranch),
      );
    }
  };

  handleBranchCheckboxClickAllFilter = (data, filteredData) => {
    if (data.checked) {
      const newFilterBranchData = filteredData.map(item => item.name);
      this.setState(
        prevState => {
          return {
            ...prevState,
            filterListBranch: newFilterBranchData,
          };
        },
        () => this.callStoreSelectedBranches(this.state.filterListBranch),
      );
    } else {
      this.setState(
        prevState => {
          return {
            ...prevState,
            filterListBranch: [],
          };
        },
        () => this.callStoreSelectedBranches(this.state.filterListBranch),
      );
    }
  };

  getBranchFilterForm = () => {
    const { fetching, fetched, selectedWorkflowRunBranches, workflowRunFilters } = this.props.workflowRunsMeta;
    if (fetched && Object.values({ workflowRunFilters })?.length > 0) {
      let componentData = workflowRunFilters.branches || [];
      let filterBranchItems = componentData.map(item => {
        return { name: item, checked: selectedWorkflowRunBranches.includes(item) };
      });
      return (
        filterBranchItems && (
          <ForesightCheckboxList
            searchBarPlaceholder="Search for a branch"
            parentName="Select All"
            data={filterBranchItems}
            emptyJsx={<div style={{ marginTop: 10, fontWeight: 500 }}> Branch Not Found </div>}
            loading={fetching}
            fetched={fetched}
            subHeader="BRANCH NAMES"
            handleCheckboxOnClick={this.handleBranchCheckboxClickFilter}
            handleSelectAllToggle={this.handleBranchCheckboxClickAllFilter}
          />
        )
      );
    }
  };

  handleStatusCheckboxClickFilter = (id, data) => {
    const upperCaseLabel = data?.label.toUpperCase().replace(/\s/g, '_');
    const { selectedWorkflowRunConclusions } = this.props.workflowRunsMeta;
    let statusArr = [...selectedWorkflowRunConclusions];
    if (data.checked) {
      statusArr.push(upperCaseLabel);

      this.callStoreSelectedConclusions(statusArr);
    } else {
      const filterArr = statusArr.filter(item => item !== upperCaseLabel);
      this.callStoreSelectedConclusions(filterArr);
    }
  };

  handleStatusCheckboxClickAllFilter = (data, filteredData) => {
    if (data.checked) {
      const statusArr = filteredData.map(item => item.name.toUpperCase().replace(/\s/g, '_'));
      this.props.setSelectedConclusions(statusArr);
    } else {
      this.callStoreSelectedConclusions([]);
    }
  };

  getFilterStatusCheckList = () => {
    const {
      fetching,
      fetched,
      workflowRunFilters: { conclusions },
      selectedWorkflowRunConclusions,
    } = this.props.workflowRunsMeta;

    if (conclusions) {
      let filterStatusItems = conclusions.map(item => {
        return { name: getConclusionToReadableMapping(item), checked: selectedWorkflowRunConclusions.includes(item) };
      });

      return (
        <>
          <ForesightCheckboxList
            searchBarPlaceholder="Search for a status"
            parentName="Select All"
            data={filterStatusItems}
            loading={fetching}
            fetched={fetched}
            emptyJsx={<div style={{ marginTop: 10, fontWeight: 500 }}> Status Not Found </div>}
            subHeader="STATUS NAMES"
            handleCheckboxOnClick={this.handleStatusCheckboxClickFilter}
            handleSelectAllToggle={this.handleStatusCheckboxClickAllFilter}
          />
        </>
      );
    }
  };

  getConclusionFilterForm = () => {
    return (
      <>
        <div className="branches-box">{this.getFilterStatusCheckList()}</div>
        {this.props.workflowRunsMeta?.workflowRunFilters?.conclusions?.length > ACTIVATE_SCROLL_THRESHOLD && (
          <div className="scroll-shadow" />
        )}
      </>
    );
  };

  handleEventsCheckboxClickFilter = (id, data) => {
    const { selectedWorkflowRunEvents } = this.props.workflowRunsMeta;
    let eventsArr = [...selectedWorkflowRunEvents];
    if (data.checked) {
      eventsArr.push(data?.label);

      this.callStoreSelectedEvents(eventsArr);
    } else {
      const filterArr = eventsArr.filter(item => item !== data?.label);
      this.callStoreSelectedEvents(filterArr);
    }
  };

  handleEventsCheckboxClickAllFilter = (data, filteredData) => {
    if (data.checked) {
      const eventsArr = filteredData.map(item => item.name);
      this.props.setSelectedEvents(eventsArr);
    } else {
      this.callStoreSelectedEvents([]);
    }
  };

  getFilterEventsCheckList = () => {
    const {
      fetching,
      fetched,
      workflowRunFilters: { events },
      selectedWorkflowRunEvents,
    } = this.props.workflowRunsMeta;

    if (events) {
      let filterEventsItems = events.map(item => {
        return { name: item, checked: selectedWorkflowRunEvents.includes(item) };
      });

      return (
        <>
          <ForesightCheckboxList
            searchBarPlaceholder="Search for a event"
            parentName="Select All"
            data={filterEventsItems}
            loading={fetching}
            fetched={fetched}
            emptyJsx={<div style={{ marginTop: 10, fontWeight: 500 }}> Events Not Found </div>}
            subHeader="EVENT NAMES"
            handleCheckboxOnClick={this.handleEventsCheckboxClickFilter}
            handleSelectAllToggle={this.handleEventsCheckboxClickAllFilter}
          />
        </>
      );
    }
  };

  getEventsFilterForm = () => {
    return (
      <>
        <div className="branches-box">{this.getFilterEventsCheckList()}</div>
        {this.props.workflowRunsMeta?.workflowRunFilters?.events?.length > ACTIVATE_SCROLL_THRESHOLD && (
          <div className="scroll-shadow" />
        )}
      </>
    );
  };

  handleLabelsCheckboxClickFilter = (id, data) => {
    const { selectedWorkflowRunLabels } = this.props.workflowRunsMeta;
    let labelsArr = [...selectedWorkflowRunLabels];
    if (data.checked) {
      labelsArr.push(data?.label);

      this.callStoreSelectedLabels(labelsArr);
      this.findWorkflowsElements(labelsArr);
    } else {
      const filterArr = labelsArr.filter(item => item !== data?.label);
      this.callStoreSelectedLabels(filterArr);
      this.findWorkflowsElements(filterArr);
    }
  };

  handleLabelsCheckboxClickAllFilter = (data, filteredData) => {
    if (data.checked) {
      const copyOfNames = filteredData.map(item => item.name);
      this.callStoreSelectedLabels(copyOfNames);
      this.findWorkflowsElements(copyOfNames);
    } else {
      this.callStoreSelectedLabels([]);
      this.findWorkflowsElements([]);
    }
  };

  filterAsWorkflowIdForLabel = () => {
    const { workflowLabelsData, workflowRunsMeta } = this.props.workflowRunsMeta;
    const allLabelWorkflowIds = workflowLabelsData.flatMap(item => item.workflowIds);
    const allWorkflowIds = workflowRunsMeta.flatMap(item => item.id);
    const filteredForWorkflowIds = allLabelWorkflowIds.filter(item => allWorkflowIds.includes(item));
    const names = filteredForWorkflowIds.map(item =>
      _.find(workflowLabelsData, function (o) {
        return o.workflowIds.includes(item);
      }),
    );
    return names;
  };

  getFilterLabelsCheckList = () => {
    const { workflowLabelsDataFetching, workflowLabelsDataFetched, workflowLabelsData, selectedWorkflowRunLabels } =
      this.props.workflowRunsMeta;

    if (workflowLabelsData) {
      const dataLabels = this.filterAsWorkflowIdForLabel();
      let filterLabelItems = dataLabels.map(item => {
        return {
          name: item.name,
          checked: selectedWorkflowRunLabels.includes(item.name),
        };
      });

      return (
        <>
          <ForesightCheckboxList
            searchBarPlaceholder="Search for a labels"
            parentName="Select All"
            data={filterLabelItems}
            loading={workflowLabelsDataFetching}
            fetched={workflowLabelsDataFetched}
            emptyJsx={<div style={{ marginTop: 10, fontWeight: 500 }}> Labels Not Found </div>}
            subHeader="LABEL NAMES"
            handleCheckboxOnClick={this.handleLabelsCheckboxClickFilter}
            handleSelectAllToggle={this.handleLabelsCheckboxClickAllFilter}
          />
        </>
      );
    }
  };

  getLabelsFilterForm = () => {
    return (
      <>
        <div className="branches-box">{this.getFilterLabelsCheckList()}</div>
        {this.props.workflowRunsMeta?.workflowRunFilters?.events?.length > ACTIVATE_SCROLL_THRESHOLD && (
          <div className="scroll-shadow" />
        )}
      </>
    );
  };

  handleClick = property => {
    switch (property) {
      case 'STATUS': {
        this.setState({
          isStatusFilterGroupCollapsed: !this.state.isStatusFilterGroupCollapsed,
        });
        break;
      }
      case 'BRANCH': {
        this.setState({
          isBranchFilterGroupCollapsed: !this.state.isBranchFilterGroupCollapsed,
        });
        break;
      }
      case 'WORKFLOWS': {
        this.setState({
          isWorkflowsGroupCollapsed: !this.state.isWorkflowsGroupCollapsed,
        });
        break;
      }
      case 'EVENTS': {
        this.setState({
          isEventsFilterGroupCollapsed: !this.state.isEventsFilterGroupCollapsed,
        });
        break;
      }
      case 'LABELS': {
        this.setState({
          isLabelsFilterGroupCollapsed: !this.state.isLabelsFilterGroupCollapsed,
        });
        break;
      }
      default:
        break;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    const { prId } = this.props;
    const { ciProvider: ciProviderNext, repoOwner: repoOwnerNext, repoName: repoNameNext } = nextProps.match.params;

    const { selectedWorkflowRunHideFork } = this.props.workflowRunsMeta;

    const { selectedWorkflowRunHideFork: nextHideFork } = nextProps.workflowRunsMeta;

    if (ciProvider !== ciProviderNext || repoOwnerNext !== repoOwner || repoNameNext !== repoName) {
      if (prId) {
        this.props.getWorkflowRunFilters(
          ciProviderNext,
          repoOwnerNext,
          repoNameNext,
          prId,
          selectedWorkflowRunHideFork,
        );
      } else {
        this.props.getWorkflowRunFilters(ciProviderNext, repoOwnerNext, repoNameNext, selectedWorkflowRunHideFork);
      }
    }

    if (!isEqualDeep(selectedWorkflowRunHideFork, nextHideFork)) {
      if (prId) {
        this.props.getWorkflowRunFilters(ciProviderNext, repoOwnerNext, repoNameNext, prId, nextHideFork);
      } else {
        this.props.getWorkflowRunFilters(ciProviderNext, repoOwnerNext, repoNameNext, nextHideFork);
      }
    }
  }

  render() {
    const { fetching, fetched, error } = this.props.workflowRunsMeta;
    const {
      isStatusFilterGroupCollapsed,
      isBranchFilterGroupCollapsed,
      isWorkflowsGroupCollapsed,
      isEventsFilterGroupCollapsed,
      isLabelsFilterGroupCollapsed,
    } = this.state;

    const {
      selectedWorkflowIds,
      selectedWorkflowRunConclusions,
      selectedWorkflowRunBranches,
      selectedWorkflowRunEvents,
      selectedWorkflowRunHideFork,
      selectedWorkflowRunLabels,
    } = this.props.workflowRunsMeta;

    if (fetched || fetching) {
      return (
        <div className="workflow-runs-filter-container">
          <div className="workflow-runs-filter-head-container">
            <div className="filter-by-title">Filter By</div>
            <div className="filter-by-checkbox">
              <ForesightCheckbox
                checked={selectedWorkflowRunHideFork}
                label={'Hide forks'}
                onChange={(e, data) => this.callStoreSelectedHideFork(data.checked)}
              />
            </div>
          </div>
          <div className="workflow-filters">
            <Accordion as={Menu} vertical>
              <Menu.Item className="border-box">
                <Accordion.Title index={0} onClick={() => this.handleClick('BRANCH')}>
                  BRANCH
                  {hasArrayElement(selectedWorkflowRunBranches) && (
                    <span style={spanStyle}>{selectedWorkflowRunBranches.length}</span>
                  )}
                  <Icon name={isBranchFilterGroupCollapsed ? 'angle up' : 'angle down'} />
                </Accordion.Title>
                <Accordion.Content active={isBranchFilterGroupCollapsed} content={this.getBranchFilterForm()} />
              </Menu.Item>
              <Menu.Item id="workflows-filter-group" className="border-box">
                <Accordion.Title index={1} onClick={() => this.handleClick('WORKFLOWS')}>
                  WORKFLOWS
                  {hasArrayElement(selectedWorkflowIds) && <span style={spanStyle}>{selectedWorkflowIds.length}</span>}
                  <Icon name={isWorkflowsGroupCollapsed ? 'angle up' : 'angle down'} />
                </Accordion.Title>
                <Accordion.Content active={isWorkflowsGroupCollapsed} content={this.renderWorkflowFilterList()} />
              </Menu.Item>
              <Menu.Item className="border-box">
                <Accordion.Title index={2} onClick={() => this.handleClick('STATUS')}>
                  STATUS
                  {hasArrayElement(selectedWorkflowRunConclusions) && (
                    <span style={spanStyle}>{selectedWorkflowRunConclusions.length}</span>
                  )}
                  <Icon name={isStatusFilterGroupCollapsed ? 'angle up' : 'angle down'} />
                </Accordion.Title>
                <Accordion.Content
                  className="accordion-content-container"
                  active={isStatusFilterGroupCollapsed}
                  content={this.getConclusionFilterForm()}
                />
              </Menu.Item>
              <Menu.Item className="border-box">
                <Accordion.Title index={3} onClick={() => this.handleClick('EVENTS')}>
                  EVENTS
                  {hasArrayElement(selectedWorkflowRunEvents) && (
                    <span style={spanStyle}>{selectedWorkflowRunEvents.length}</span>
                  )}
                  <Icon name={isEventsFilterGroupCollapsed ? 'angle up' : 'angle down'} />
                </Accordion.Title>
                <Accordion.Content
                  className="accordion-content-container"
                  active={isEventsFilterGroupCollapsed}
                  content={this.getEventsFilterForm()}
                />
              </Menu.Item>
              <Menu.Item className="border-box">
                <Accordion.Title index={3} onClick={() => this.handleClick('LABELS')}>
                  LABELS
                  {hasArrayElement(selectedWorkflowRunLabels) && (
                    <span style={spanStyle}>{selectedWorkflowRunLabels.length}</span>
                  )}
                  <Icon name={isLabelsFilterGroupCollapsed ? 'angle up' : 'angle down'} />
                </Accordion.Title>
                <Accordion.Content
                  className="accordion-content-container"
                  active={isLabelsFilterGroupCollapsed}
                  content={this.getLabelsFilterForm()}
                />
              </Menu.Item>
            </Accordion>
          </div>
        </div>
      );
    } else if (error) {
      return (
        <div className="workflow-runs-filter-container">
          <div className="filter-by-title">Filter By</div>
          <div className="workflow-filters">
            <SomethingError msg={error} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="workflow-runs-filter-container">
          <div className="filter-by-title">Filter By</div>
          <div className="workflow-filters">
            <LoadingDimmer msg={'Unknown State'} />
          </div>
        </div>
      );
    }
  }
}
