import React, { Component } from 'react';
import { MainLayoutContainer } from 'containers';
import routeList from 'routes';
import { TeamOrganizationContainer } from 'containers';
import './TeamOrganizationPage.scss';

class TeamOrganizationPage extends Component {
  render() {
    return (
      <MainLayoutContainer title={routeList.teamOrganization.title}>
        <div className="team-organization-page-wrapper">
          <TeamOrganizationContainer />
        </div>
      </MainLayoutContainer>
    );
  }
}

export default TeamOrganizationPage;
