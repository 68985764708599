import React, { Component } from 'react';
import { Cell, Pie, PieChart } from 'recharts';


const SUCCEED_COLORS = ['#2DCD8A', 'rgba(255, 255, 255, 0.08)'];
const FAILED_COLORS = ['#B0100D', 'rgba(255, 255, 255, 0.08)'];

export class StatusPieWidget extends Component {

    render() {

        const { type, data } = this.props;
        const COLORS = type === 'failed' ? FAILED_COLORS : SUCCEED_COLORS;

        return (
            <div className='status-pie-widget-container'>
                <PieChart className="piechart" width={80} height={80} >
                    <Pie
                        data={data}
                        fill="#8884d8"
                        stroke='rgba(255, 255, 255, 0.00)'
                        innerRadius={32}
                        outerRadius={40}
                        paddingAngle={0}
                        dataKey="value"
                    >

                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}

                    </Pie>
                    <text x={40} y={40} fontSize='14px' fontWeight='700' fill="#DCDFE5" textAnchor="middle" dominantBaseline="middle">
                        {/* data[0] is meaning of success and fail count data */}
                        {Math.round(data[0].value)}%
                    </text>
                </PieChart>
            </div>
        );


    }


}