import {
  CREATE_RULES_FULFILLED,
  CREATE_RULES_PENDING,
  CREATE_RULES_REJECTED,
  GET_ALL_RULES_FULFILLED,
  GET_ALL_RULES_PENDING,
  GET_ALL_RULES_REJECTED,
  GET_RULE_FULFILLED,
  GET_RULE_PENDING,
  GET_RULE_REJECTED,
  GET_WORKFLOW_NAMES_FULFILLED,
  GET_WORKFLOW_NAMES_PENDING,
  GET_WORKFLOW_NAMES_REJECTED,
} from 'store/constants/rules';

const initialRuleListState = {
  ruleList: [],
  ruleListFetching: false,
  ruleListFetched: false,
  ruleListError: null,
};

const initialRuleState = {
  rule: {},
  ruleFetching: false,
  ruleFetched: false,
  ruleError: null,
};

const initialCreateRuleState = {
  createRule: {},
  createRuleFetching: false,
  createRuleFetched: false,
  createRuleError: null,
};

const initialWorkflowNameListState = {
  workflowNameList: [],
  workflowNameListFetching: false,
  workflowNameListFetched: false,
  workflowNameListError: null,
};

export function ruleList(state = initialRuleListState, action) {
  switch (action.type) {
    /* A reducer for the ruleList. */
    case GET_ALL_RULES_PENDING:
      return { ...state, ruleListFetching: true, ruleListFetched: false, ruleListError: null };
    case GET_ALL_RULES_FULFILLED:
      return {
        ...state,
        ruleListFetching: false,
        ruleListFetched: true,
        ruleListError: false,
        ruleList: action.payload.data,
      };
    case GET_ALL_RULES_REJECTED:
      return { ...state, ruleListFetching: false, ruleListFetched: false, ruleListError: action.payload };

    default:
      return state;
  }
}

export function rule(state = initialRuleState, action) {
  switch (action.type) {
    /* A reducer for the rule. */
    case GET_RULE_PENDING:
      return { ...state, ruleFetching: true, ruleFetched: false, ruleError: null };
    case GET_RULE_FULFILLED:
      return {
        ...state,
        ruleFetching: false,
        ruleFetched: true,
        ruleError: false,
        rule: action.payload.data,
      };
    case GET_RULE_REJECTED:
      return { ...state, ruleFetching: false, ruleFetched: false, ruleError: action.payload };
    default:
      return state;
  }
}

export function workflowNameList(state = initialWorkflowNameListState, action) {
  /* A reducer for Workflow Names. */
  switch (action.type) {
    case GET_WORKFLOW_NAMES_PENDING:
      return { ...state, workflowNameListFetching: true, workflowNameListFetched: false, workflowNameListError: null };
    case GET_WORKFLOW_NAMES_FULFILLED:
      return {
        ...state,
        workflowNameListFetching: false,
        workflowNameListFetched: true,
        workflowNameListError: false,
        workflowNameList: action.payload.data,
      };
    case GET_WORKFLOW_NAMES_REJECTED:
      return {
        ...state,
        workflowNameListFetching: false,
        workflowNameListFetched: false,
        workflowNameListError: action.payload,
      };
    default:
      return state;
  }
}

export function createRule(state = initialCreateRuleState, action) {
  /* A reducer for Workflow Names. */
  switch (action.type) {
    case CREATE_RULES_PENDING:
      return { ...state, createRuleFetching: true, createRuleFetched: false, createRuleError: null };
    case CREATE_RULES_FULFILLED:
      return {
        ...state,
        createRuleFetching: false,
        createRuleFetched: true,
        createRuleError: false,
        createRule: action.payload.data,
      };
    case CREATE_RULES_REJECTED:
      return {
        ...state,
        createRuleFetching: false,
        createRuleFetched: false,
        createRuleError: action.payload,
      };
    default:
      return state;
  }
}
