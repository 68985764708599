import React, { Component } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { hasArrayElement } from 'utils/array-util';
import './LineChartViewer.scss';

export class LineChartViewer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opacity: new Array(this.props.dataProps.length).fill(0.8),
		};
	}

  handleMouseEnter = e => {
  	e.preventDefault();
  	const newOpacity = {};
  	this.props.dataProps.forEach(type => {
  		newOpacity[type.dataKey] = e.dataKey === type.dataKey ? 0.8 : 0.2;
  	});
  	this.setState({ opacity: newOpacity });
  };

  handleMouseLeave = e => {
  	e.preventDefault();
  	const newOpacity = {};
  	this.props.dataProps.forEach(type => {
  		newOpacity[type.dataKey] = 0.8;
  	});
  	this.setState({ opacity: newOpacity });
  };

  renderLines = () => {
  	return this.props.dataProps.map(prop => {
  		return (
  			<Line
  				type="linear"
  				connectNulls={true}
  				dot={{ fill: prop.color }}
  				key={prop.dataKey}
  				dataKey={prop.dataKey}
  				name={prop.name}
  				strokeWidth={1.5}
  				stroke={prop.color}
  				fillOpacity={this.state.opacity[prop.dataKey]}
  				strokeOpacity={this.state.opacity[prop.dataKey]}
  				fill={'url(#' + prop.dataKey.replace(/\s+/g, '') + ')'}
  				animationDuration={800}
  				animationEasing={'ease-in-out'}
  			/>
  		);
  	});
  };

  renderLegend = props => {
  	const { payload } = props;
  	const valueFormatter = this.props.legendProps?.valueFormatter;
  	const tFormatter = valueFormatter ? valueFormatter : val => val;

  	return (
  		<div className="line-chart-legend-container">
  			{payload.map((entry, index) => {
  				const val = tFormatter(entry);
  				return (
  					<div key={'item-' + index} className="legend">
  						<div className="legend-icon" style={{ backgroundColor: entry.color }} />
  						<div className="legend-title">{val}</div>
  					</div>
  				);
  			})}
  		</div>
  	);
  };

  renderTooltip = props => {
  	const { payload, label: xVal } = props;
  	const valueFormatter = this.props.tooltipProps?.valueFormatter;
  	const tFormatter = valueFormatter ? valueFormatter : val => val;
  	let listItemsDOM = '';
  	if (Array.isArray(payload)) {
  		listItemsDOM = payload.map((entry, index) => {
  			const val = tFormatter(entry, xVal);
  			return (
  				<li key={'item-' + index}>
  					<div className="li-item">
  						<div className="legend-icon" style={{ backgroundColor: entry.color }} />
  						<div>{val}</div>
  					</div>
  				</li>
  			);
  		});
  	}

  	return (
  		<div className="line-chart-tooltip-container">
  			<ul>{listItemsDOM}</ul>
  		</div>
  	);
  };

  render() {
  	const { data, size, xAxisProps, yAxisProps, syncId } = this.props;
  	const styleObj = { width: size.width, height: size.height };
  	if (hasArrayElement(data)) {
  		return (
  			<div style={styleObj}>
  				<ResponsiveContainer>
  					<LineChart width={size.width} height={size.height} data={data} syncId={syncId}>
  						<XAxis axisLine={false} ticks={xAxisProps.ticks} tick={{ stroke: '#788496', strokeWidth: '0.5px' }} />
  						<YAxis
  							axisLine={false}
  							tickFormatter={yAxisProps.tickFormatter}
  							tick={{ stroke: '#788496', strokeWidth: '0.5px' }}
  						/>
  						<CartesianGrid stroke="#333940" horizontal={true} vertical={false} />
  						<Tooltip content={this.renderTooltip} />
  						{this.renderLines()}
  						<Legend
  							verticalAlign={'top'}
  							iconType="square"
  							content={this.renderLegend}
  							onMouseEnter={this.handleMouseEnter}
  							onMouseLeave={this.handleMouseLeave}
  						/>
  					</LineChart>
  				</ResponsiveContainer>
  			</div>
  		);
  	}

  	return <div>Data Not Applicable</div>;
  }
}
