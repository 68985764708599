import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
import { CardModal } from 'components/ChangePricingPlanPage/CardModal';
import { LoadingDimmer, NoDataAvailable, SomethingError } from 'components/ComponentPlaceholder';
import { ALink } from 'custom-components/ALink/ALink';
import { BlueButton, GrayBorderButton } from 'custom-components/Button/Button';
import {
  InfoPanel,
  InfoPanelText,
  InfoPanelTitle,
  InfoPanelTitleContainer,
  InfoPanelWarningIcon,
} from 'custom-components/InfoPanel/InfoPanel';
import { PageSubHeader, PageSubHeaderText, PageSubHeaderTitle } from 'custom-components/PageSubHeader/PageSubHeader';
import React from 'react';
import { Link } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import routeList, { computePath } from 'routes';
import { hasArrayElement } from 'utils/array-util';
import { CONFIG } from 'utils/config-util';
import { formatDate } from 'utils/date-util';

class Plan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      stripe: null,
    };
  }

  componentDidMount() {
    // Load stripe.js
    const stripeScript = document.createElement('script');

    stripeScript.src = 'https://js.stripe.com/v3/';
    stripeScript.async = true;
    console.log('StripeAPIKey:' + CONFIG.STRIPE_API_KEY);

    stripeScript.onload = () => {
      this.setState({
        stripe: window.Stripe(CONFIG.STRIPE_API_KEY),
      });
    };

    document.body && document.body.appendChild(stripeScript);
  }

  handleCardModalOpen = e => {
    e.preventDefault();
    this.setState({
      isModalOpen: true,
    });
  };

  handleCardModalClose = () => {
    console.log('handle card modal close');
    this.setState({ isModalOpen: false });
  };

  renderStripeCardForm = () => {
    const { stripe, isModalOpen } = this.state;

    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <CardModal
            stripe={stripe}
            open={isModalOpen}
            onUpsertCardSource={(stripeToken, onSuccess, onError) => {
              console.log('upsert card started');
              this.props.upsertCard(
                this.props.organizationId,
                stripeToken,
                () => {
                  console.log('upsert card success');
                  onSuccess();
                  this.props.getBillingCustomer(this.props.organizationId);
                },
                onError,
              );
            }}
            upsertCardResult={this.props.upsertCardResult}
            onClose={this.handleCardModalClose}
          />
        </Elements>
      </StripeProvider>
    );
  };

  calculateNextBillingDate = () => {
    const { activeSubscription } = this.props.activeSubscription;
    return activeSubscription.nextInvoiceDate ? formatDate(activeSubscription.nextInvoiceDate * 1000) : 'Canceled';
  };

  calculateNextBillingUsers = () => {
    const { activeSubscription } = this.props.activeSubscription;
    return activeSubscription.activeCommitterLimit - activeSubscription.freeCommitterLimit;
  };

  calculateNextBillingTotal = () => {
    const { activeSubscription } = this.props.activeSubscription;
    return activeSubscription.nextInvoiceTotal !== null ? '$' + activeSubscription.nextInvoiceTotal / 100 : 'N/A';
  };

  calculateHowManyExtraPaidUser = (activeCommiterCount, activeCommitterLimit) => {
    return activeCommiterCount - activeCommitterLimit;
  };

  calculateIsUserNeedToPay = (activeCommiterCount, activeCommitterLimit) => {
    return this.calculateHowManyExtraPaidUser(activeCommiterCount, activeCommitterLimit) > 0;
  };

  getActiveCommitterCount = activeCommiter => {
    if (hasArrayElement(activeCommiter)) {
      return activeCommiter.length;
    }
    return 0;
  };

  renderPlan = () => {
    //console.log('render plan');
    const { billingPlans } = this.props.billingPlans;
    const { billingCustomer } = this.props.billingCustomer;
    const { activeSubscription } = this.props.activeSubscription;

    let planName = '-';
    let planPeriod = 'Monthly';
    let nextBillingDateTitle = 'Next Billing Date';
    let activeCommiterCount = -1;
    let activeCommitterLimit = -1;
    let freeCommitterLimit = -1;
    let billingPlanWarningInfo = false;
    let changePlanButtonTitle = 'Change Plan';

    if (activeSubscription) {
      activeCommiterCount = this.getActiveCommitterCount(activeSubscription.activeCommitters);
      activeCommitterLimit = activeSubscription.activeCommitterLimit;
      freeCommitterLimit = activeSubscription.freeCommitterLimit;
      if (activeSubscription.trial) {
        planName = 'Trial';
        nextBillingDateTitle = 'Trial End Date';
        changePlanButtonTitle = 'Pick a Plan';
      }

      if (!activeSubscription.trial && this.calculateIsUserNeedToPay(activeCommiterCount, activeCommitterLimit)) {
        billingPlanWarningInfo = true;
      }
    }

    if (hasArrayElement(billingPlans)) {
      const plan = billingPlans[0];
      if (planName !== 'Trial') {
        planName = activeCommitterLimit <= freeCommitterLimit ? 'Free Plan' : 'Standard Plan';
      }
      planPeriod = plan.annually ? 'Annualy' : 'Monthly';
    }

    let extraPaidNeedTextJSX = '';
    if (this.calculateIsUserNeedToPay(activeCommiterCount, activeCommitterLimit)) {
      extraPaidNeedTextJSX = (
        <>
          You have{' '}
          <span className="active-comitter-number">
            {' '}
            +{Math.abs(this.calculateHowManyExtraPaidUser(activeCommiterCount, activeCommitterLimit))}{' '}
          </span>{' '}
          new committers
        </>
      );
    }

    return (
      <section className="plan-container">
        <div className="plan-header">
          <PageSubHeader>
            <PageSubHeaderTitle>Plan</PageSubHeaderTitle>
            <PageSubHeaderText>
              Foresight has flexible plans that scales with your team.{' '}
              <ALink href="https://www.runforesight.com/pricing">Learn more about plans</ALink>
            </PageSubHeaderText>
          </PageSubHeader>
        </div>
        {billingPlanWarningInfo && (
          <div className="plan-warning">
            <InfoPanel>
              <InfoPanelTitleContainer>
                <InfoPanelWarningIcon />
                <InfoPanelTitle>You have exceeded your committer limit</InfoPanelTitle>
              </InfoPanelTitleContainer>
              <InfoPanelText>
                The committer limit of your plan is {activeCommitterLimit}. However, you had {activeCommiterCount}{' '}
                committers this month. To avoid losing access to your account, please
                <Link to={computePath(routeList.changePlan.path)}> update your plan</Link>.
              </InfoPanelText>
            </InfoPanel>
          </div>
        )}
        <div className="plan-summary-section-container">
          <div className="plan-summary-part-one">
            <div className="plan-summary-section-header-container">
              <div className="plan-summary-section-header-left">
                {planName} ({planPeriod})
              </div>
              <div className="plan-summary-section-header-right">
                <Link to={computePath(routeList.changePlan.path)}>
                  <BlueButton content={changePlanButtonTitle} />
                </Link>
              </div>
            </div>
            <div className="plan-summary-section-content-container">
              <div className="user-plan-summary-left-section">
                <div className="user-plan-summary-left-section-header">
                  <div className="committer-sub-main-header"> Committers</div>
                  <div className="committer-sub-header">
                    <Link style={{ textDecoration: 'none' }} to={computePath(routeList.changePlan.path)}>
                      <GrayBorderButton content="See all" />
                    </Link>
                  </div>
                </div>

                <div className="paid-committers">
                  <div className="plan-subheader paid-committers-title">Paid Committer Limit</div>
                  <div className="paid-committers-text">
                    <span>{activeCommitterLimit}</span> Free up to {freeCommitterLimit}
                  </div>
                </div>
                <div className="active-committers">
                  <div className="plan-subheader active-committers-title">Active Committers</div>
                  <div className="active-committers-text">
                    <span>{activeCommiterCount}</span>
                    {extraPaidNeedTextJSX}
                  </div>
                </div>
              </div>
              <div className="plan-vertical-line" />
              <div className="user-plan-summary-right-section">
                <div className="user-plan-summary-right-section-billing">Billing</div>

                <div className="next-plan-date">
                  <div className="plan-subheader next-plan-date-title">{nextBillingDateTitle}</div>
                  <div className="next-plan-date-text">{this.calculateNextBillingDate()}</div>
                </div>
                <div className="plan-total">
                  <div className="plan-subheader plan-total-title">Billing Total</div>
                  <div className="billing-total-value-info">
                    <span className="billing-total-value">{this.calculateNextBillingTotal()}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="plan-summary-part-two">
            <div className="payment-method-container">
              <div className="payment-method-header">
                <i className={DOMAIN_ICON.BILLING.CREDIT_CARD} />
                <div className="payment-method-header-title">Payment Method</div>
              </div>
              {billingCustomer.sourceExists ? (
                <div>
                  <div className={'payment-method-credit-card-text'}>**** {billingCustomer.last4}</div>
                  <div className={'payment-method-credit-card-text'}>{billingCustomer.name}</div>
                  <div className={'payment-method-credit-card-text'}>{billingCustomer.addressLine1}</div>
                  <div className={'payment-method-credit-card-text'}>
                    {billingCustomer.addressCity ? billingCustomer.addressCity + ' / ' : ''}
                    {billingCustomer.addressState ? billingCustomer.addressState + ' / ' : ''}
                    {billingCustomer.addressCountry ? billingCustomer.addressCountry + ' / ' : ''}
                    {billingCustomer.addressZip}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="payment-card-change">
              <ALink style={{ fontSize: '12px', fontWeight: 500 }} onClick={this.handleCardModalOpen}>
                {billingCustomer.sourceExists ? 'Change Payment Method' : 'Add Card'}
              </ALink>
              {this.renderStripeCardForm()}
            </div>
          </div>
        </div>
      </section>
    );
  };

  render() {
    const { fetched: fetchedPlans, fetching: fetchingPlans, error: errorPlans } = this.props.billingPlans;
    const { fetched: fetchedCustomer, fetching: fetchingCustomer, error: errorCustomer } = this.props.billingCustomer;
    const {
      fetched: fetchedActiveSubscription,
      fetching: fetchingActiveSubscription,
      error: errorActiveSubscription,
    } = this.props.activeSubscription;

    if (fetchingPlans || fetchingCustomer || fetchingActiveSubscription) {
      return (
        <div style={{ marginTop: 10 }}>
          <LoadingDimmer msg={'Loading...'} />
        </div>
      );
    }

    if (errorPlans || errorCustomer || errorActiveSubscription) {
      return (
        <div style={{ marginTop: 10 }}>
          <SomethingError />
        </div>
      );
    }

    if (fetchedPlans || fetchedCustomer || fetchedActiveSubscription) {
      return this.renderPlan();
    }

    return (
      <div style={{ marginTop: 10 }}>
        <NoDataAvailable />
      </div>
    );
  }
}

export default Plan;
