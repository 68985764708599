import { RepositoryPRTestRunsTabContainer } from 'containers';
import React from 'react';
import './PRTestRuns.scss';

export class PRTestRuns extends React.Component {
  render() {
    return (
      <div className="pr-test-runs-tab-conainer">
        <RepositoryPRTestRunsTabContainer />
      </div>
    );
  }
}
