import React, { Component } from 'react';
import HeatMapWidget from 'components/HighlightsPage/HighlightParts/HeatMapWidget/HeatMapComponent/HeatMapWidget';
import moment from 'moment';
import './CountOf30DaysWorkflows.scss';

export class CountOf30DaysWorkflows extends Component {

    calculateStartDate = () => {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
        return new Date(moment(startDate).format('YYYY/MM/DD'));
    };

    calculateEndDate = () => {
        const endDate = new Date();
        return new Date(moment(endDate).format('YYYY/MM/DD'));
    };


    render() {
        return (
            <>
                <HeatMapWidget
                    chartData={this.props.data}
                    startDate={this.calculateStartDate()}
                    endDate={this.calculateEndDate()}
                    range={4}
                    title={'# of Workflow 30 days'}
                />
            </>
        );
    }
}