import React, { Component } from 'react';
import './CiProviderIntegrationsPage.scss';
import { MainLayoutContainer } from 'containers';
import { renderLogo } from 'utils/git-provider-logos';
import routeList from 'routes';
import { getCiProviderConfigLink } from 'utils/git-provider-config-links';
import { isUserInInvitedUserRole } from 'utils/user-role-util.js';
import { LoadingDimmer } from 'components';
import { Button, Modal } from 'semantic-ui-react';
import { CI_PROVIDER } from 'enums/git-provider';
import { NotificationIntegration } from 'components/NotificationIntegration';
import _ from 'lodash';
import { CONFIG } from 'utils/config-util';
import {
  PageHeader,
  PageHeaderBackButton,
  PageHeaderBackButtonTitleContainer,
  PageHeaderTitle,
  PageHeaderTitleContainer,
} from 'custom-components/PageHeader/PageHeader';
import { PageSubHeader, PageSubHeaderText, PageSubHeaderTitle } from 'custom-components/PageSubHeader/PageSubHeader';
import { Link } from 'react-router-dom';
import { BlueButton } from 'custom-components/Button/Button';
import { UI_ICON } from 'assets/font-icons/IconManager';

class CiProviderIntegrationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCIProviderIntegrationDeleted: false,
      deletedIntegration: undefined,
      isNotificationIntegrationConnected: false,
    };
  }

  componentDidMount() {
    this.props.getCIProviderIntegrations();
    this.props.getListWebHook();
    this.props.getNotificationPreferences();
  }

  onGetCIProviderIntegrationsError() {
    return <div>An error ocurred</div>;
  }

  deleteCIProviderIntegration = (provider, integration) => {
    this.setState({
      deletedIntegration: integration,
    });

    this.props.deleteCIProviderIntegration(provider, this.onDeleteCIProviderIntegrationSuccess);
  };

  onDeleteCIProviderIntegrationSuccess = () => {
    this.setState({
      isCIProviderIntegrationDeleted: true,
    });
    this.props.getCIProviderIntegrations();
  };

  closeCIProviderIntegrationDeletedModal = () => {
    this.setState({
      isCIProviderIntegrationDeleted: true,
      deletedIntegration: undefined,
    });

    this.props.history.push(routeList.repositories.path);
  };

  getModalAfterDeleteCIProviderIntegration = () => {
    const { isCIProviderIntegrationDeleted, deletedIntegration } = this.state;
    return (
      <Modal
        className="stop-repo-watching-modal"
        open={isCIProviderIntegrationDeleted}
        onClose={() => this.setState({ isStopWatchingModalOpen: false })}
      >
        <div className="title-section">
          <i className={UI_ICON.ALERT.WARNING} />
          <div className="title">You have disconnected your GitHub account</div>
        </div>

        <div className="description">
          You have disconnected from your GitHub account. Please don't forget to uninstall Foresight app from the
          GitHub. You can uninstall the app from here.
        </div>
        <div className="description">
          <a
            className="ci-config-link"
            href={getCiProviderConfigLink(deletedIntegration)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Check your GitHub permissions
          </a>
        </div>
        <div className="delete-actions">
          <Button primary onClick={this.closeCIProviderIntegrationDeletedModal}>
            OK
          </Button>
        </div>
      </Modal>
    );
  };

  render() {
    const { isCIProviderListFetching, ciProviderList, isDeleteCIProviderFetching } = this.props.ciConnect;
    const { userAccount } = this.props;

    if (isCIProviderListFetching) {
      return <LoadingDimmer />;
    }

    return (
      <MainLayoutContainer>
        <div className="integrations-container">
          {this.getModalAfterDeleteCIProviderIntegration(CI_PROVIDER.GITHUB)}

          <PageHeader>
            <PageHeaderTitleContainer>
              <PageHeaderBackButtonTitleContainer>
                <PageHeaderBackButton onClick={() => this.props.history.push(routeList.repositories.path)} />
                <PageHeaderTitle>Integrations </PageHeaderTitle>
              </PageHeaderBackButtonTitleContainer>
            </PageHeaderTitleContainer>
          </PageHeader>

          <div className="refresh-permission">
            <PageSubHeader>
              <PageSubHeaderTitle>CI Tools</PageSubHeaderTitle>
              <PageSubHeaderText>
                If you have recently updated permissions on your CI provider,{' '}
                <Link to={routeList.integrations.path}>click to refresh this page to apply your changes.</Link>
              </PageSubHeaderText>
            </PageSubHeader>

            <div className="integrations-list">
              {ciProviderList.map(integration => {
                return (
                  <div key={integration.integrationId} className="integration">
                    <div className="ci-logo">{renderLogo(integration.provider)}</div>
                    <div className="ci-body">
                      <div className="ci-provider-title">GitHub</div>
                      <div className="ci-link-wrapper">
                        {!isUserInInvitedUserRole(userAccount.userAccount.role) && (
                          <a
                            className="ci-config-link"
                            href={getCiProviderConfigLink(integration)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Configure your permissions
                          </a>
                        )}
                      </div>
                    </div>
                    {!isUserInInvitedUserRole(userAccount.userAccount.role) && (
                      <div className="ci-status-button-container">
                        <Button
                          onClick={() => this.deleteCIProviderIntegration(CI_PROVIDER.GITHUB, integration)}
                          className="ci-disconnect-status-button"
                          loading={isDeleteCIProviderFetching}
                          disabled={isDeleteCIProviderFetching}
                        >
                          Disconnect
                        </Button>
                      </div>
                    )}
                  </div>
                );
              })}
              {ciProviderList.length === 0 && (
                <div key={_.uniqueId} className="integration">
                  <div className="ci-logo">{renderLogo('GITHUB')}</div>
                  <div className="ci-body">
                    <div className="ci-provider-title">GitHub</div>
                    <div className="ci-link-wrapper">
                      {!isUserInInvitedUserRole(userAccount.userAccount.role) && (
                        <a className="ci-config-link-disabled" rel="noopener noreferrer">
                          Configure your permissions
                        </a>
                      )}
                    </div>
                  </div>
                  {!isUserInInvitedUserRole(userAccount.userAccount.role) && (
                    <div className="ci-status-button-container">
                      <BlueButton
                        onClick={() => (window.location.href = CONFIG.FORESIGHT_GITHUB_APP)}
                        primary
                        loading={isDeleteCIProviderFetching}
                        disabled={isDeleteCIProviderFetching}
                      >
                        Connect
                      </BlueButton>
                    </div>
                  )}
                </div>
              )}
              <NotificationIntegration {...this.props} />
            </div>
          </div>
        </div>
      </MainLayoutContainer>
    );
  }
}

export default CiProviderIntegrationsPage;
