import { RepositoryPullRequestsList } from 'components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getPullRequests } from 'store/actions/pull-requests';
import { getPullRequests_Filters } from 'store/actions/pull-requests-filter';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    pullRequestsMeta: store.pullRequestsMeta,
    pullRequests: store.pullRequests,
  };
}

function mapDispatchToProps() {
  return {
    getPullRequests(
      ciProvider,
      repoOwner,
      repoName,
      selectedPullRequests_Authors,
      selectedPullRequests_WorkflowRunBranches,
      selectedPullRequests_Labels,
      selectedPullRequests_States,
      selectedPullRequests_HideFork,
      pageNo,
      pageSize,
      onSuccess,
      onError,
    ) {
      dispatchWithExceptionHandler(
        getPullRequests(
          ciProvider,
          repoOwner,
          repoName,
          selectedPullRequests_Authors,
          selectedPullRequests_WorkflowRunBranches,
          selectedPullRequests_Labels,
          selectedPullRequests_States,
          selectedPullRequests_HideFork,
          pageNo,
          pageSize,
        ),
        true,
        onSuccess,
        onError,
      );
    },
    getPullRequests_Filters(ciProvider, repoOwner, repoName, onSuccess, onError) {
      dispatchWithExceptionHandler(getPullRequests_Filters(ciProvider, repoOwner, repoName), true, onSuccess, onError);
    },
  };
}

const RepositoryPullRequestsListContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepositoryPullRequestsList),
);
export default RepositoryPullRequestsListContainer;
