import { CONFIG } from 'utils/config-util.js';

export const AUTH_CONFIG = {
	domain: CONFIG.REACT_APP_AUTH_DOMAIN,
	auth0CustomDataNamespace: CONFIG.AUTH0_CUSTOM_DATA_NAMESPACE,
	clientId: CONFIG.REACT_APP_AUTH_CLIENT_ID,
	callbackUrl: CONFIG.REACT_APP_AUTH_CALLBACK_URL,
	apiUrl: CONFIG.REACT_APP_AUTH_API_URL,
	allowedConnectionNames: CONFIG.ALLOWED_CONNECTION_NAMES ? CONFIG.ALLOWED_CONNECTION_NAMES : [],
	defaultDatabaseConnection: CONFIG.DEFAULT_CONNECTION_NAME,
	logoutReturnTo: CONFIG.REACT_APP_AUTH_LOGOUT_RETURN_TO,
	logoutReturnToError: CONFIG.REACT_APP_AUTH_LOGOUT_RETURN_TO_ERROR,
};
