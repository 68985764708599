export const GET_TEST_OVERVIEW = 'GET_TEST_OVERVIEW';
export const GET_TEST_OVERVIEW_PENDING = 'GET_TEST_OVERVIEW_PENDING';
export const GET_TEST_OVERVIEW_REJECTED = 'GET_TEST_OVERVIEW_REJECTED';
export const GET_TEST_OVERVIEW_FULFILLED = 'GET_TEST_OVERVIEW_FULFILLED';

export const GET_TEST_HISTORY = 'GET_TEST_HISTORY';
export const GET_TEST_HISTORY_PENDING = 'GET_TEST_HISTORY_PENDING';
export const GET_TEST_HISTORY_REJECTED = 'GET_TEST_HISTORY_REJECTED';
export const GET_TEST_HISTORY_FULFILLED = 'GET_TEST_HISTORY_FULFILLED';

export const GET_TEST_SCREENSHOTS = 'GET_TEST_SCREENSHOTS';
export const GET_TEST_SCREENSHOTS_PENDING = 'GET_TEST_SCREENSHOTS_PENDING';
export const GET_TEST_SCREENSHOTS_REJECTED = 'GET_TEST_SCREENSHOTS_REJECTED';
export const GET_TEST_SCREENSHOTS_FULFILLED = 'GET_TEST_SCREENSHOTS_FULFILLED';

export const GET_TEST_LOGS = 'GET_TEST_LOGS';
export const GET_TEST_LOGS_PENDING = 'GET_TEST_LOGS_PENDING';
export const GET_TEST_LOGS_REJECTED = 'GET_TEST_LOGS_REJECTED';
export const GET_TEST_LOGS_FULFILLED = 'GET_TEST_LOGS_FULFILLED';
