//Summary Simple Status Cards
export const GET_SUMMARY_OF_LATEST_10_WORKFLOWS = 'GET_SUMMARY_OF_LATEST_10_WORKFLOWS';
export const GET_SUMMARY_OF_LATEST_10_WORKFLOWS_PENDING = 'GET_SUMMARY_OF_LATEST_10_WORKFLOWS_PENDING';
export const GET_SUMMARY_OF_LATEST_10_WORKFLOWS_FULFILLED = 'GET_SUMMARY_OF_LATEST_10_WORKFLOWS_FULFILLED';
export const GET_SUMMARY_OF_LATEST_10_WORKFLOWS_REJECTED = 'GET_SUMMARY_OF_LATEST_10_WORKFLOWS_REJECTED';

//GitHub Style Heatmap
export const GET_COUNT_OF_30_DAYS_WORKFLOWS = 'GET_COUNT_OF_30_DAYS_WORKFLOWS';
export const GET_COUNT_OF_30_DAYS_WORKFLOWS_PENDING = 'GET_COUNT_OF_30_DAYS_WORKFLOWS_PENDING';
export const GET_COUNT_OF_30_DAYS_WORKFLOWS_FULFILLED = 'GET_COUNT_OF_30_DAYS_WORKFLOWS_FULFILLED';
export const GET_COUNT_OF_30_DAYS_WORKFLOWS_REJECTED = 'GET_COUNT_OF_30_DAYS_WORKFLOWS_REJECTED';

//Statistics Overall 
export const GET_OVERALL_COUNT_STATS_OF_WORKFLOWS = 'GET_OVERALL_COUNT_STATS_OF_WORKFLOWS';
export const GET_OVERALL_COUNT_STATS_OF_WORKFLOWS_PENDING = 'GET_OVERALL_COUNT_STATS_OF_WORKFLOWS_PENDING';
export const GET_OVERALL_COUNT_STATS_OF_WORKFLOWS_FULFILLED = 'GET_OVERALL_COUNT_STATS_OF_WORKFLOWS_FULFILLED';
export const GET_OVERALL_COUNT_STATS_OF_WORKFLOWS_REJECTED = 'GET_OVERALL_COUNT_STATS_OF_WORKFLOWS_REJECTED';

//Standart HeatMap
export const GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS = 'GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS';
export const GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS_PENDING = 'GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS_PENDING';
export const GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS_FULFILLED = 'GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS_FULFILLED';
export const GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS_REJECTED = 'GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS_REJECTED';

//Stack BarChart
export const GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS = 'GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS';
export const GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS_PENDING = 'GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS_PENDING';
export const GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS_FULFILLED = 'GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS_FULFILLED';
export const GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS_REJECTED = 'GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS_REJECTED';

//Cost Summary
export const GET_TOTAL_COST_SUMMARY = 'GET_TOTAL_COST_SUMMARY';
export const GET_TOTAL_COST_SUMMARY_PENDING = 'GET_TOTAL_COST_SUMMARY_PENDING';
export const GET_TOTAL_COST_SUMMARY_FULFILLED = 'GET_TOTAL_COST_SUMMARY_FULFILLED';
export const GET_TOTAL_COST_SUMMARY_REJECTED = 'GET_TOTAL_COST_SUMMARY_REJECTED';

//LineChart
export const GET_COST_OF_MOST_COSTLY_10_WORKFLOWS = 'GET_COST_OF_MOST_COSTLY_10_WORKFLOWS';
export const GET_COST_OF_MOST_COSTLY_10_WORKFLOWS_PENDING = 'GET_COST_OF_MOST_COSTLY_10_WORKFLOWS_PENDING';
export const GET_COST_OF_MOST_COSTLY_10_WORKFLOWS_FULFILLED = 'GET_COST_OF_MOST_COSTLY_10_WORKFLOWS_FULFILLED';
export const GET_COST_OF_MOST_COSTLY_10_WORKFLOWS_REJECTED = 'GET_COST_OF_MOST_COSTLY_10_WORKFLOWS_REJECTED';

//LineChart
export const GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS = 'GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS';
export const GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_PENDING = 'GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_PENDING';
export const GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_FULFILLED = 'GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_FULFILLED';
export const GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_REJECTED = 'GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_REJECTED';

//Table
export const GET_OVERALL_AVG_DURATION_OF_LONGEST_10_WORKFLOWS = 'GET_OVERALL_AVG_DURATION_OF_LONGEST_10_WORKFLOWS';
export const GET_OVERALL_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_PENDING = 'GET_OVERALL_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_PENDING';
export const GET_OVERALL_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_FULFILLED = 'GET_OVERALL_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_FULFILLED';
export const GET_OVERALL_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_REJECTED = 'GET_OVERALL_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_REJECTED';

//Avg and Total Duration Summary
export const GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS = 'GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS';
export const GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS_PENDING = 'GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS_PENDING';
export const GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS_FULFILLED = 'GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS_FULFILLED';
export const GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS_REJECTED = 'GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS_REJECTED';


//LineChart
export const GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS = 'GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS';
export const GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_PENDING = 'GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_PENDING';
export const GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_FULFILLED = 'GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_FULFILLED';
export const GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_REJECTED = 'GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_REJECTED';

//Table
export const GET_OVERALL_SUM_DURATION_OF_LONGEST_10_WORKFLOWS = 'GET_OVERALL_SUM_DURATION_OF_LONGEST_10_WORKFLOWS';
export const GET_OVERALL_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_PENDING = 'GET_OVERALL_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_PENDING';
export const GET_OVERALL_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_FULFILLED = 'GET_OVERALL_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_FULFILLED';
export const GET_OVERALL_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_REJECTED = 'GET_OVERALL_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_REJECTED';


