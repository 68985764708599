export const GET_JOB_TEST_SUMMARY = 'GET_JOB_TEST_SUMMARY';
export const GET_JOB_TEST_SUMMARY_PENDING = 'GET_JOB_TEST_SUMMARY_PENDING';
export const GET_JOB_TEST_SUMMARY_REJECTED = 'GET_JOB_TEST_SUMMARY_REJECTED';
export const GET_JOB_TEST_SUMMARY_FULFILLED = 'GET_JOB_TEST_SUMMARY_FULFILLED';

export const GET_JOB_FAILED_TESTS = 'GET_JOB_FAILED_TESTS';
export const GET_JOB_FAILED_TESTS_PENDING = 'GET_JOB_FAILED_TESTS_PENDING';
export const GET_JOB_FAILED_TESTS_REJECTED = 'GET_JOB_FAILED_TESTS_REJECTED';
export const GET_JOB_FAILED_TESTS_FULFILLED = 'GET_JOB_FAILED_TESTS_FULFILLED';

export const GET_JOB_COVERAGE = 'GET_JOB_COVERAGE';
export const GET_JOB_COVERAGE_PENDING = 'GET_JOB_COVERAGE_PENDING';
export const GET_JOB_COVERAGE_REJECTED = 'GET_JOB_COVERAGE_REJECTED';
export const GET_JOB_COVERAGE_FULFILLED = 'GET_JOB_COVERAGE_FULFILLED';
