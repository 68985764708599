import React, { Component } from 'react';
import './MainLayout.scss';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { ForesightTopbarContainer } from 'containers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { isDemoEnv } from 'utils/config-util.js';
import { LoadingDimmer } from 'components';
import moment from 'moment';

class MainLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserMenuOpen: false,
    };
  }

  handleScroll = e => {
    if (e.target) {
      if (e.target.dataset) {
        e.target.dataset.lastScrolled = new Date().getTime();
      }
      if (e.target.classList) {
        if (e.target.classList.contains('show-scrollbar') === false) {
          e.target.classList.add('show-scrollbar');
        }
      }
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);

    this.scrollIntervalID = setInterval(() => {
      const arrElems = document.getElementsByClassName('show-scrollbar');
      const arr = [];
      if (arrElems && arrElems.length > 0) {
        for (let i = 0; i < arrElems.length; i++) {
          arr.push(arrElems[i]);
        }
      }

      arr.forEach(el => {
        const currentTime = new Date().getTime();
        if (el.dataset.lastScrolled && currentTime - el.dataset.lastScrolled > 1000 * 1 * 3) {
          el.classList.remove('show-scrollbar');
        }
      });
    }, 1000 * 1 * 1); // Check DOM If Scrolling Ended remove css class
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.scrollIntervalID);
  }

  handleUserMenuOpen = () => {
    this.setState({
      isUserMenuOpen: true,
    });
  };

  handleUserMenuClose = () => {
    this.setState({
      isUserMenuOpen: false,
    });
  };

  calculateLeftPricingDate = () => {
    const { activeSubscription } = this.props.activeSubscription;
    if (activeSubscription.trial) {
      const milisecondInvoices = activeSubscription.nextInvoiceDate * 1000 - Date.now();
      const duration = moment.duration(milisecondInvoices, 'milliseconds');
      const days = duration.days() + 1;
      return days < 4;
    }
    return false;
  };

  render() {
    let mainLayoutStyle = 'main-layout-page-container';
    if (isDemoEnv()) {
      mainLayoutStyle = 'main-layout-page-container-demo';
    } else if (this.calculateLeftPricingDate()) {
      mainLayoutStyle = 'main-layout-page-container-with-warning';
    }

    if (this.props.hasFullScreenStickyTableHeader) {
      mainLayoutStyle += ' fullscreen-sticky-table-header';
    }

    if (this.props.organization.switchOrganizationFetching) {
      return <LoadingDimmer />;
    }

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.title}</title>
        </Helmet>

        <div className="screen-size-message">
          <span>Foresight is best experienced on wider screens</span>
        </div>

        <ToastContainer
          className="toast-container"
          toastClassName="toast-content"
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          icon={false}
        />

        {!this.props.fullscreen && <ForesightTopbarContainer></ForesightTopbarContainer>}
        <div id="main-layout" className={mainLayoutStyle}>
          {this.props.children}
        </div>
      </>
    );
  }
}

MainLayout.propTypes = {
  title: PropTypes.string,
  hasFullScreenStickyTableHeader: PropTypes.bool,
};

MainLayout.defaultProps = {
  title: '',
  hasFullScreenStickyTableHeader: false,
};

export default MainLayout;
