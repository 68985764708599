import React from 'react';
import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
import {
  ListCardsBody,
  ListCardsCellContainer,
  ListCardsContainer,
  ListCardsHeader,
  ListCardsRowBody,
} from 'custom-components/ListCards/ListCards';
import { AverageRangeGraph } from 'custom-components/RangeValue/AverageRangeGraph';
import routeList, { computePath } from 'routes';
import { millisToMAndSNotFixed } from 'utils/date-util';
import { Badge } from 'custom-components/Badge/Badge';
import { renderNoDataAvailableForTestSuites } from 'components/ComponentPlaceholder';
import { hasArrayElement } from 'utils/array-util';

const columnStyle = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  alignItems: 'center',
  letterSpacing: '-0.01em',
  marginLeft: '20px',
  color: '#788496',
  paddingLeft: '35px',
};

const failedColumnStyle = {
  ...columnStyle,
  minWidth: '160px',
};
const successfulColumnStyle = {
  ...columnStyle,
  minWidth: '160px',
  paddingLeft: '0',
};
const performanceColumnStyle = {
  ...columnStyle,
  minWidth: '160px',
};

const testSuiteColumnStyle = {
  ...columnStyle,
  minWidth: '670px',
};

export const TestsSuitesPageTable = props => {
  const renderTestsSuitesList = () => {
    const {
      testSuitesOverview: { content, testSuitesOverviewFetching, testSuitesOverviewFetched, testSuitesOverviewErrors },
    } = props;

    return (
      <>
        <ListCardsContainer withBorder style={{ width: '100%' }}>
          <ListCardsHeader
            clickedSort={data => props.handleSortData(data)}
            columnsObjectsArray={[
              { text: 'Test Suite', style: { columnStyle: testSuiteColumnStyle } },
              {
                text: 'Performance',
                style: { columnStyle: performanceColumnStyle },
                tooltipText: 'Test Suites Successful Runs',
              },
              {
                text: 'Successful runs',
                sortable: true,
                style: { columnStyle: successfulColumnStyle },
              },
              {
                text: 'Failed runs',
                sortable: true,
                style: { columnStyle: failedColumnStyle },
              },
            ]}
            className="pr-list-header"
          />
          <ListCardsBody
            fetching={testSuitesOverviewFetching}
            fetched={testSuitesOverviewFetched}
            error={testSuitesOverviewErrors}
            infiniteFetching={props.infiniteState}
            tableContent={content}
            emptyContent={renderNoDataAvailableForTestSuites()}
          >
            {hasArrayElement(content) && content.map((el, index) => renderTestsSuitesListItems(index, el))}
          </ListCardsBody>
        </ListCardsContainer>
      </>
    );
  };

  const renderTestsSuitesListItems = (index, item) => {
    return (
      <ListCardsRowBody
        withClickIcon
        onClick={() =>
          props.history.push(
            computePath(routeList.testSuitesDetail.path, {
              workflowId: item.workflowId,
              ciProvider: 'github',
              repoOwner: item.owner,
              repoName: item.repoName,
              testSuiteName: decodeURIComponent(item.name),
              jobName: decodeURIComponent(item.jobName),
            }),
          )
        }
      >
        <ListCardsCellContainer className="tests-suites-list-row-body">
          <div className="tests-suites-cell-container">
            <div className="test-title">
              {item.name}
              {item.hasFlaky && (
                <div style={{ marginLeft: 10 }}>
                  <Badge text="has flaky" badgeColor="FFDDA1" />
                </div>
              )}
            </div>

            <div className="test-information">
              <div className="test-information-cont">
                <i className={DOMAIN_ICON.GITHUB.REPOSITORY} />

                <div className="test-suites-info-title with-dot">{item.repoName}</div>
              </div>
              <div className="test-information-cont">
                <i className={DOMAIN_ICON.GITHUB.WORKFLOW} />
                <div className="test-suites-info-title with-dot">{item.workflowName}</div>
              </div>
              <div className="test-information-cont">
                <i className={DOMAIN_ICON.GITHUB.JOB} />
                <div className="test-suites-info-title">{item.jobName}</div>
              </div>
            </div>
          </div>
        </ListCardsCellContainer>

        <ListCardsCellContainer>
          <div className="tests-suites-performance-cell-container">
            <div className="test-performance-row-cont">
              <label>median:</label>
              <div className="performance-value">{millisToMAndSNotFixed(item.p50)}</div>
            </div>
            <div className="test-performance-row-cont">
              <label>p95:</label>
              <div className="performance-value">{millisToMAndSNotFixed(item.p95)}</div>
            </div>
            <div className="test-performance-row-cont">
              <label>average:</label>
              <div className="performance-value">{millisToMAndSNotFixed(item.averageDuration)}</div>
            </div>
          </div>
        </ListCardsCellContainer>
        <ListCardsCellContainer>
          <div className="tests-suites-successfulruns-cell-container">
            <div className="test-successful-row-cont">
              <label>count:</label>
              <div className="successful-value">{item.successfulCount + '/' + item.totalCount}</div>
            </div>
            <div className="test-successful-row-cont">
              <label>avg:</label>
              <div className="successful-value">{millisToMAndSNotFixed(item.successfulAvgDuration)}</div>
            </div>
            <div className="test-successful-row-cont">
              <AverageRangeGraph
                avg={item.successfulAvgDuration}
                maxValue={item.successfulMaxDuration}
                minValue={item.successfulMinDuration}
                hasTimeValue
              />
            </div>
          </div>
        </ListCardsCellContainer>
        <ListCardsCellContainer>
          <div className="tests-suites-failedruns-cell-container">
            <div className="test-failed-row-cont">
              <label>count:</label>
              <div className="failed-value">{item.failedCount + '/' + item.totalCount}</div>
            </div>
            <div className="test-failed-row-cont">
              <label>avg:</label>
              <div className="failed-value">{millisToMAndSNotFixed(item.failedAvgDuration)}</div>
            </div>
            <div className="test-failed-row-cont">
              <AverageRangeGraph
                avg={item.failedAvgDuration}
                maxValue={item.failedMaxDuration}
                minValue={item.failedMinDuration}
                hasTimeValue
              />
            </div>
          </div>
        </ListCardsCellContainer>
      </ListCardsRowBody>
    );
  };

  return <div id="tests-suites-table-container">{renderTestsSuitesList()}</div>;
};
