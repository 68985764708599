import React, { Component } from 'react';
import { AvgWorkflowDurationChart } from './AvgWorkflowDurationChart/AvgWorkflowDurationChart';
import './CostAverageWorkflowWidget.scss';
import { CostWorkflowRunChart } from './CostWorkflowRunChart/CostWorkflowRunChart';


export class CostAverageWorkflowWidget extends Component {

    render() {
        const { costWorkflowRunData, avgWorkflowDurationData, title } = this.props;
        return (
            <div className='avg-and-total-duration-container'>
                <div className='avg-and-total-duration-header'>
                    {title}
                </div>
                <div className="avg-and-total-duration-chart-cont">
                    <div className='avg-chart'>
                        <CostWorkflowRunChart data={costWorkflowRunData} />

                    </div>
                    <div className='avg-chart'>
                        <AvgWorkflowDurationChart data={avgWorkflowDurationData} />
                    </div>
                </div>
            </div>

        );
    }
}