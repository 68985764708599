import { GET_REPOS, UNWATCH_CI_PROVIDE_REPO, GET_REPOS_WITHOUT_RELOAD, UPDATE_REPO_LIST } from 'store/constants/repos';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

export function getRepos() {
	return {
		type: GET_REPOS,
		payload: axios.get(CONFIG.apiUrl + '/repos', {
			headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
		}),
	};
}

export function getReposWithoutReload() {
	return {
		type: GET_REPOS_WITHOUT_RELOAD,
		payload: axios.get(CONFIG.apiUrl + '/repos', {
			headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
		}),
	};
}

export function updateRepoList() {
	return {
		type: UPDATE_REPO_LIST,
	};
}

export function unwatchRepo(provider, owner, name, projectId) {
	console.log('Params in actions repo', provider, owner, name, projectId);
	return {
		type: UNWATCH_CI_PROVIDE_REPO,
		payload: axios.patch(
			CONFIG.apiUrl + `/repos/${provider}/${owner}/${name}/unwatch`,
			{ projectId: projectId },
			{
				headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
			},
		),
	};
}
