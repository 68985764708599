import * as FullStory from '@fullstory/browser';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// `devMode` envs are not recorded by FullStory!
// If you want to see sessions of a spesific env on FullStory and Heap,
// then just comment out for that env to make it out of dev mode,
// then see the captured session at FullStory.

const isDevMode =
  process.env.REACT_APP_ENV === 'dev' || // localhost
  process.env.REACT_APP_ENV === 'lab' ||
  process.env.REACT_APP_ENV === 'alpha' ||
  process.env.REACT_APP_ENV === 'beta' ||
  process.env.REACT_APP_ENV === 'delta' ||
  process.env.REACT_APP_ENV === 'demo' ||
  process.env.REACT_APP_ENV === 'staging';

let sentryEnvMode = 'foresight-frontend-lab';
switch (process.env.REACT_APP_ENV) {
  case 'dev':
    sentryEnvMode = 'foresight-frontend-dev';
    break;
  case 'staging':
    sentryEnvMode = 'foresight-frontend-staging';
    break;
  case 'prod':
    sentryEnvMode = 'foresight-frontend-prod';
    break;
  default:
    break;
}

export function initFullStory() {
  // console.log("init FS, isDevMode:", isDevMode);

  //FS - thundra: B9XEX
  FullStory.init({
    orgId: 'B9XEX',
    devMode: isDevMode,
  });
}

export function initHeap() {
  // heap - dev: 813148649
  // heap - prod: 612233518

  if (window.heap && window.heap.load) {
    if (isDevMode) {
      // console.log("init HEAP dev isDevMode:", isDevMode);
      window.heap.load('813148649'); // heap dev proj.
    } else {
      // prod
      // console.log("init HEAP prod isDevMode:", isDevMode);
      window.heap.load('612233518'); // heap prod proj.
    }
  }
}

// No CUSTOMER obj as param here!
export function identifyUsers(userData) {
  // We keep window.datalLayer for 'userDataReady' event which is used by Intercom via GTM.
  window.dataLayer = window.dataLayer || [];
  // profile.metadata can be undefined when user invited and login on local environment
  // profile.metedata fetched when working lab, staging, prod
  window.dataLayer.push({
    event: 'userDataReady',
    userId: userData.originalUserId ? userData.originalUserId : userData.userId,
    displayName: userData.fullName || '',
    email: userData.email || '',
    organization: 'ForesightNoOrganization',
    role: userData.role || '',
  });

  // Heap should not be added via GTM, it creates a problem; 'react heap push is not a function'
  // `userId` changes when user changes organization, so it is better to user `originalUserId` for
  // user identification.
  if (window.heap && window.heap.identify && window.heap.addUserProperties) {
    window.heap.identify(`${userData.email}`);

    window.heap.addUserProperties({
      ...userData,
      email: userData.email || 'no email',
      devEnv: process.env.REACT_APP_ENV,
    });
  }

  FullStory.identify(userData.userId, {
    // displayName: userData.fullName || "no name",
    displayName: userData.email || 'no displayName',
    userName: userData.fullName || 'no user fullName',
    email: userData.email || 'no email',
    organization: 'ForesightNoOrganization',
    role: userData.role || 'no role',
    devEnv: process.env.REACT_APP_ENV,
  });

  Sentry.setUser({
    id: userData.originalUserId ? userData.originalUserId : userData.userId,
    email: userData.email || 'no email',
    username: userData.fullName || 'no user fullName',
  });

  console.log('User Identified', userData.originalUserId ? userData.originalUserId : userData.userId);
}

export function initSentry() {
  const disabledSentryEnv =
    process.env.REACT_APP_ENV === 'dev' || // localhost
    process.env.REACT_APP_ENV === 'lab' ||
    process.env.REACT_APP_ENV === 'alpha' ||
    process.env.REACT_APP_ENV === 'beta' ||
    process.env.REACT_APP_ENV === 'delta';

  if (disabledSentryEnv) return; //Not Initialize Sentry

  Sentry.init({
    dsn: 'https://07fd3e8d954c4aed9d333456fb355ab6@o4504196158652416.ingest.sentry.io/4504213686255617',
    integrations: [new BrowserTracing()],
    environment: sentryEnvMode,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    dist: process.env.REACT_APP_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
