import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ForesightTopbar } from 'components';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { getUserAccount } from 'store/actions/user-account';
import { getActiveOrganizationList, switchOrganization } from 'store/actions/organization';
import { getRepos } from 'store/actions/repos';
import { getActiveSubscription } from 'store/actions/billing-active-subscription';
import { getWhitelist } from 'store/actions/whitelist';
import { clearReduxStore } from 'store/actions/redux-store';

function mapStateToProps(store) {
  return {
    userAccount: store.userAccount,
    projects: store.projects,
    organization: store.organization,
    activeSubscription: store.activeSubscription,
    whiteList: store.whiteList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserAccount(onGetUserAccountSuccess, onGetUserAccountFail) {
      dispatchWithExceptionHandler(getUserAccount(), false, onGetUserAccountSuccess, onGetUserAccountFail);
    },
    getActiveOrganizationList(onGetOrganizationListSuccess, onGetOrganizationListFail) {
      dispatchWithExceptionHandler(
        getActiveOrganizationList(),
        false,
        onGetOrganizationListSuccess,
        onGetOrganizationListFail,
      );
    },
    switchOrganization(id, onGetOrganizationListSuccess, onGetOrganizationListFail) {
      dispatchWithExceptionHandler(
        switchOrganization(id),
        false,
        onGetOrganizationListSuccess,
        onGetOrganizationListFail,
      );
    },
    getRepos(onGetReposSuccess, onGetReposError) {
      dispatchWithExceptionHandler(getRepos(), false, onGetReposSuccess, onGetReposError);
    },
    getActiveSubscription(organizationId, onSuccess, onError) {
      dispatchWithExceptionHandler(getActiveSubscription(organizationId), false, onSuccess, onError);
    },
    getWhiteList(organizationId, onSuccess, onError) {
      dispatchWithExceptionHandler(getWhitelist(organizationId), false, onSuccess, onError);
    },
    clearReduxStore() {
      dispatch(clearReduxStore());
    },
  };
}

const ForesightTopbarContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ForesightTopbar));
export default ForesightTopbarContainer;
