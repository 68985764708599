import React, { Component, createRef } from 'react';
import { Button } from 'semantic-ui-react';
import { hasArrayElement } from 'utils/array-util';
import Layout from './HighlightParts/Layout/Layout';
import { LoadingDimmer, NoDataAvailable, SomethingError } from 'components/ComponentPlaceholder';
import { SummaryOfLatest10Workflows } from './HighlightWidgets/WithoutTimeRange/SummaryOfLatest10Workflows/SummaryOfLatest10Workflows';
import { CountOf30DaysWorkflows } from './HighlightWidgets/WithoutTimeRange/CountOf30DaysWorkflows/CountOf30DaysWorkflows';
import { OverallCountStatsOfWorklflows } from './HighlightWidgets/StatusSummary/StatusSummary';
import { AvgAndTotalDurationSummary } from './HighlightWidgets/DurationSummary/AvgAndTotalDurationSummary/AvgAndTotalDurationSummary';
import './SingleWorkflowHighlightsPage.scss';
import ConnectRepositoryWidget from './HighlightWidgets/ConnectRepositoryWidget/ConnectRepositoryWidget';
import { HIGHLIGHTS_DATE_ENUM } from 'utils/highlights-enums';
import routeList from 'routes';
import { decodeRepoName } from 'utils/test-run-detail-util';
import { TotalTimeSpendStepsWidget } from './SingleWorkflowsWidgets/TotalTimeSpendStepsWidget/TotalTimeSpendStepsWidget';
import { ExecutionSuccessCountWidget } from './SingleWorkflowsWidgets/ExecutionSuccessCountWidget/ExecutionSuccessCountWidget';
import { CostAverageWorkflowWidget } from './SingleWorkflowsWidgets/CostAverageWorkflowWidget/CostAverageWorkflowWidget';
import { MainLayoutContainer } from 'containers/MainLayoutContainer';
import ForesightBreadcrumb, { createForesightBreadCrumbDataItem } from 'custom-components/Breadcrumb/Breadcrumb';

const getData = serverObj => {
  if (serverObj?.data?.data) {
    const obj = serverObj?.data?.data;
    if (Object.getPrototypeOf(obj) === Object.prototype) {
      return obj;
    } else if (typeof obj === 'number') {
      return obj;
    }
    return hasArrayElement(serverObj?.data?.data) ? serverObj.data.data : [];
  }
  return [];
};

const checkEmpty = data => {
  if (data) {
    if (typeof data?.data === 'object' && !hasArrayElement(data?.data)) {
      return Object.values(data?.data).every(item => item === 0);
    } else {
      return data?.data?.every(item => item.count === 0);
    }
  }
};

const HighlightWidgetWrapper = props => {
  const { emptyTitle, isCheckServerEmpty, serverObj } = props;
  const { data, fetched, fetching, error } = serverObj;

  if (data?.data?.length !== 0) {
    if (fetched) {
      const isCheckServerEmptyVal = isCheckServerEmpty ? checkEmpty(data) : false;
      return isCheckServerEmptyVal ? (
        <div className="workflow-card-container">
          <div className="title-of-no-data">{emptyTitle}</div>
          <NoDataAvailable msg={'No data available'} />
        </div>
      ) : (
        <div>{props.children}</div>
      );
    } else if (fetching) {
      return (
        <div className="workflow-card-container">
          <LoadingDimmer msg={'Loading...'} />
        </div>
      );
    } else if (error) {
      return (
        <div className="workflow-card-container">
          <SomethingError msg={error} />
        </div>
      );
    } else {
      return (
        <div className="workflow-card-container">
          <LoadingDimmer msg={'Unknown State'} />
        </div>
      );
    }
  } else {
    return (
      <div className="workflow-card-container">
        <div className="title-of-no-data">{emptyTitle}</div>
        <NoDataAvailable msg={'No data available'} />
      </div>
    );
  }
};

class SingleWorkflowHighlightsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeRange: HIGHLIGHTS_DATE_ENUM.ONE_MONTH,
    };

    this.layoutFirstRowRef = createRef();
    this.layoutSecondRowRef = createRef();
    this.layoutThirdRowRef = createRef();
  }

  componentDidMount() {
    console.log('match params', this.props);
    const { ciProvider, repoName, repoOwner, workflowId } = this.props.match.params;

    this.props.getLatestTenWorkflowRuns(ciProvider, repoOwner, decodeRepoName(repoName), workflowId);
    this.props.getCountOf30DaysWorkflowRuns(ciProvider, repoOwner, decodeRepoName(repoName), workflowId);
    this.props.getWorkflowDetail(ciProvider, repoOwner, decodeRepoName(repoName), workflowId);
    this.fetchDateFilterData();
  }

  fetchDateFilterData() {
    const { timeRange } = this.state;
    const { ciProvider, repoName, repoOwner, workflowId } = this.props.match.params;
    this.props.getStatusSummaryOfLatest10WorkflowRuns(
      timeRange,
      ciProvider,
      repoOwner,
      decodeRepoName(repoName),
      workflowId,
    );
    this.props.getExecutionCountLatest10WorkflowRuns(
      timeRange,
      ciProvider,
      repoOwner,
      decodeRepoName(repoName),
      workflowId,
    );
    this.props.getDurationSummaryWorkflowRuns(timeRange, ciProvider, repoOwner, decodeRepoName(repoName), workflowId);
    this.props.getCostTimeWorkflowRuns(timeRange, ciProvider, repoOwner, decodeRepoName(repoName), workflowId);
    this.props.getAvgTimeWorkflowRuns(timeRange, ciProvider, repoOwner, decodeRepoName(repoName), workflowId);
    this.props.getJobsWorkflowRuns(timeRange, ciProvider, repoOwner, decodeRepoName(repoName), workflowId);
  }

  handleTimeRange = timeRange => {
    this.setState({ timeRange: timeRange }, () => {
      this.fetchDateFilterData();
    });
  };

  createBreadCrumbDataItem = (text, data, handleOnClick) => {
    return {
      text: text,
      data: data,
      handleOnClick: handleOnClick,
    };
  };

  renderBreadcrumb = () => {
    const pathData = {};
    const data = [];
    const { repoName, repoOwner } = this.props.match.params;
    const { workflowDetail } = this.props;

    const workflowName = getData(workflowDetail);

    data.push(
      createForesightBreadCrumbDataItem('Highlights', pathData, () => {
        this.props.history.push(routeList.highlights.path);
      }),
    );
    data.push(createForesightBreadCrumbDataItem(decodeRepoName(repoOwner), pathData));
    data.push(createForesightBreadCrumbDataItem(decodeRepoName(repoName), pathData));
    data.push(createForesightBreadCrumbDataItem(workflowName.name));
    return <ForesightBreadcrumb type="link-item" breadcrumbData={data} />;
  };

  redirectProjectsPage = () => {
    this.props.history.push(routeList.projectStart.path);
  };

  render() {
    const {
      latestTenWorkflowRuns,
      countOf30DaysWorkflowRuns,
      statusSummaryOfLatest10WorkflowRuns,
      executionCountLatest10WorkflowRuns,
      durationSummaryWorkflowRuns,
      averageDurationTimeWorkflowRuns,
      costTimeWorklowRuns,
      jobsWorkflowRuns,
      workflowDetail,
      repos: { repoList },
      ciConnect,
    } = this.props;

    const workflowName = getData(workflowDetail);

    const { timeRange: timeRange } = this.state;

    if (repoList.length > 0) {
      return (
        <MainLayoutContainer
          title={routeList.singleWorkflowHighlights.title.replace('${workflowName}', workflowName?.name)}
        >
          <div className="main-header-cont-hg">
            <h2 className="main-header-hg">{workflowName?.name}</h2>
            <div className="breadcrumb-menu-cont">{this.renderBreadcrumb()}</div>
          </div>
          <div className="highlights-page-first-row">
            <div className="summaryOfLatest10Workflows">
              <HighlightWidgetWrapper serverObj={latestTenWorkflowRuns} title="Latest 10 Workflow Runs">
                <SummaryOfLatest10Workflows history={this.props.history} data={getData(latestTenWorkflowRuns)} />
              </HighlightWidgetWrapper>
            </div>
            <div className="countOf30DaysWorkflows">
              <HighlightWidgetWrapper
                isCheckServerEmpty
                serverObj={countOf30DaysWorkflowRuns}
                title="# of Workflow 30 days"
              >
                <CountOf30DaysWorkflows data={getData(countOf30DaysWorkflowRuns)} />
              </HighlightWidgetWrapper>
            </div>
          </div>
          <div className="button-and-text-group-main-container">
            <div className="button-and-text-subgroup-container">
              <div className="workflow-name-btgroup">{workflowName?.name}</div>
              <div className="button-time-groups-container">
                <Button.Group>
                  <Button
                    id="last24hours"
                    onClick={() => this.handleTimeRange(HIGHLIGHTS_DATE_ENUM.ONE_DAY)}
                    active={timeRange === HIGHLIGHTS_DATE_ENUM.ONE_DAY}
                  >
                    24 HOURS
                  </Button>
                  <Button
                    id="sevenDays"
                    onClick={() => this.handleTimeRange(HIGHLIGHTS_DATE_ENUM.ONE_WEEK)}
                    active={timeRange === HIGHLIGHTS_DATE_ENUM.ONE_WEEK}
                  >
                    7 DAYS
                  </Button>
                  <Button
                    id="last30Days"
                    onClick={() => this.handleTimeRange(HIGHLIGHTS_DATE_ENUM.ONE_MONTH)}
                    active={timeRange === HIGHLIGHTS_DATE_ENUM.ONE_MONTH}
                  >
                    30 DAYS
                  </Button>
                </Button.Group>
              </div>
            </div>
          </div>

          <Layout
            layoutFirstRowRef={this.layoutFirstRowRef}
            layoutSecondRowRef={this.layoutSecondRowRef}
            layoutThirdRowRef={this.layoutThirdRowRef}
            widthHeaders
            firstItem={
              <HighlightWidgetWrapper
                isCheckServerEmpty
                serverObj={statusSummaryOfLatest10WorkflowRuns}
                emptyTitle="Status Summary"
              >
                <OverallCountStatsOfWorklflows
                  data={getData(statusSummaryOfLatest10WorkflowRuns)}
                  timeRange={timeRange}
                  title={'Status Summary'}
                />
              </HighlightWidgetWrapper>
            }
            secondItem={
              <HighlightWidgetWrapper
                serverObj={executionCountLatest10WorkflowRuns}
                emptyTitle={'Execution Count / Success Rates'}
              >
                <ExecutionSuccessCountWidget
                  successFailCountOfMostActive10Workflows={getData(executionCountLatest10WorkflowRuns)}
                  successRatesOfMostActive10Workflows={getData(executionCountLatest10WorkflowRuns)}
                  timeRange={timeRange}
                />
              </HighlightWidgetWrapper>
            }
            thirdItem={
              <HighlightWidgetWrapper serverObj={durationSummaryWorkflowRuns} emptyTitle={'Duration Summary'}>
                <AvgAndTotalDurationSummary data={getData(durationSummaryWorkflowRuns)} title="Duration Summary" />
              </HighlightWidgetWrapper>
            }
            fourthItem={
              <HighlightWidgetWrapper serverObj={jobsWorkflowRuns} emptyTitle="Total time spend by steps">
                <TotalTimeSpendStepsWidget
                  avgDurationTimeSeriesWidget={getData(jobsWorkflowRuns)}
                  timeRange={timeRange}
                />
              </HighlightWidgetWrapper>
            }
            fifthItem={
              <HighlightWidgetWrapper serverObj={costTimeWorklowRuns} emptyTitle={'Cost and Avg Workflow Duration'}>
                <CostAverageWorkflowWidget
                  costWorkflowRunData={getData(costTimeWorklowRuns)}
                  avgWorkflowDurationData={getData(averageDurationTimeWorkflowRuns)}
                  timeRange={timeRange}
                />
              </HighlightWidgetWrapper>
            }
          />
        </MainLayoutContainer>
      );
    } else {
      return (
        <MainLayoutContainer>
          <ConnectRepositoryWidget
            ciConnect={ciConnect}
            noProjectIsPresent={repoList.length === 0}
            history={this.props.history}
          />
        </MainLayoutContainer>
      );
    }
  }
}

export default SingleWorkflowHighlightsPage;
