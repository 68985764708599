import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import './CheckboxList.scss';
import { Tooltip } from 'components/Tooltip';
import { hasArrayElement } from 'utils/array-util';
import { LoadingDimmer } from 'components';
import { debounce } from 'lodash';
import { ForesightCheckbox } from 'custom-components/Checkbox/Checkbox';
import { ForesightSmallInput } from 'custom-components/Input/Input';

export default class ForesightCheckboxList extends Component {
  constructor(props) {
    super(props);
    this.checkboxListRef = createRef();
    this.state = {
      newDataList: [],
      parentChecked: false,
      loading: false,
      selectedCount: null,
      filterText: null,
    };
    this.servicesValue = debounce(this.changeSearchState, 400);
  }

  componentDidMount() {
    const { data } = this.props;
    let count = 0;
    const convertData = data.map(item => {
      item.checked = item.checked || false;
      item.checked ? (count += 1) : (count += 0);
      item.name = item.name || item.repo.name;
      return { ...item };
    });
    const isParentChecked = convertData.every(item => item.checked === true);

    this.setState({
      newDataList: convertData,
      parentChecked: isParentChecked,
      selectedCount: count,
    });
  }

  handleCheckboxClick = (idx, data) => {
    const { newDataList } = this.state;
    const filteredData = newDataList.find((item, index) => index === idx);

    filteredData.checked = !filteredData.checked;
    let allChecked = newDataList.every(element => element.checked === true);

    this.setState(prevState => {
      return {
        ...prevState,
        newDataList: [...prevState.newDataList],
        parentChecked: allChecked ? true : false,
      };
    });

    this.props.handleCheckboxOnClick(idx, data);
  };

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (data !== prevProps.data) {
      if (this.state.filterText?.value) {
        let count = 0;
        const convertData = this.state.newDataList.map(item => {
          item.checked = item.checked || false;
          item.checked ? (count += 1) : (count += 0);
          item.name = item.name || item.repo.name;
          return { ...item };
        });
        const isParentChecked = convertData.every(item => item.checked === true);

        this.setState(
          {
            newDataList: convertData,
            parentChecked: isParentChecked,
            selectedCount: count,
          },
          () => this.servicesValue(this.state.filterText),
        );
      } else {
        let count = 0;
        const convertData = data.map(item => {
          item.checked = item.checked || false;
          item.checked ? (count += 1) : (count += 0);
          item.name = item.name || item.repo.name;
          return { ...item };
        });
        const isParentChecked = convertData.every(item => item.checked === true);

        this.setState(
          {
            newDataList: convertData,
            parentChecked: isParentChecked,
            selectedCount: count,
          },
          () => this.servicesValue(this.state.filterText),
        );
      }
    }
  }

  /* A function that is called when the parent checkbox is clicked. It is used to check or uncheck all
 the child checkboxes. */
  handleOwnSelectAllReposToggle = data => {
    const { newDataList } = this.state;
    if (!hasArrayElement(newDataList)) {
      return false;
    }
    newDataList.forEach(element => {
      element.checked = data.checked ? true : false;
    });
    this.setState(prevState => {
      return { ...prevState, newDataList: [...prevState.newDataList], parentChecked: data.checked };
    });
    this.props.handleSelectAllToggle(data, newDataList);
  };
  /* Checking if the data is loading or not. If it is loading, it will show the
  loading icon. */
  renderBranches = () => {
    const { loading, fetched, iconPopupJsx, ellipsisLength = 35 } = this.props;
    const { newDataList } = this.state;
    if (loading || !fetched) {
      return (
        <div className="loading-container">
          <LoadingDimmer />
        </div>
      );
    }

    const branches = newDataList.map((item, idx) => {
      const childName = item.name;
      const itemLenght = childName.length;
      const hasIcon = item.withIconName || null;
      return (
        <div className="checkbox-list-item" key={`${idx}}`}>
          {itemLenght > ellipsisLength ? (
            <Tooltip blackEdition={true} content={childName}>
              <ForesightCheckbox
                checked={item.checked}
                label={childName}
                onChange={(e, data) => this.handleCheckboxClick(idx, data)}
                withIconName={hasIcon || null}
                iconPopupJsx={iconPopupJsx}
              />
            </Tooltip>
          ) : (
            <ForesightCheckbox
              checked={item.checked}
              label={childName}
              onChange={(e, data) => this.handleCheckboxClick(idx, data)}
              withIconName={hasIcon || null}
              iconPopupJsx={iconPopupJsx}
            />
          )}
        </div>
      );
    });
    return branches;
  };

  changeSearchState = data => {
    const { data: realData } = this.props;
    if (data && data.value !== '') {
      const filterDataList = realData.filter(item => {
        const itemWords = item.name.toLowerCase();
        const inputWords = data.value.toLowerCase();
        return itemWords.includes(inputWords);
      });
      const isParentChecked = filterDataList.every(item => item.checked === true);
      this.setState(prevState => {
        return {
          ...prevState,
          newDataList: filterDataList,
          parentChecked: isParentChecked,
          loading: false,
        };
      });
    } else {
      const isParentChecked = realData.every(item => item.checked === true);
      this.setState(prevState => {
        return {
          ...prevState,
          parentChecked: isParentChecked,
          newDataList: realData,
          loading: false,
        };
      });
    }
  };

  /* A debounce function. */
  handleFilterName = data => {
    this.setState({ loading: true, filterText: data });
    this.servicesValue(data);
  };

  render() {
    {
      this.renderBranches();
    }

    const { parentChecked, newDataList, loading, selectedCount } = this.state;
    const {
      parentName,
      searchBarPlaceholder,
      emptyJsx = 'No Data',
      hasSearchBar = true,
      subHeader,
      insideList = false,
      maxHeightList = 200,
    } = this.props;
    return (
      <div className="checkbox-list-component">
        {hasSearchBar && (
          <div className="check-box-filter-input">
            <ForesightSmallInput
              placeholder={searchBarPlaceholder}
              onChange={(e, data) => this.handleFilterName(data)}
            />
          </div>
        )}
        {newDataList.length === 0 && !loading ? (
          <div className="no-checkbox-in-list">{emptyJsx}</div>
        ) : (
          <div>
            <div className="checkbox-list-select-all">
              <ForesightCheckbox
                checked={parentChecked}
                label={
                  parentName +
                  (selectedCount === 0 ? '' : ` (${selectedCount} ${selectedCount > 1 ? 'items' : 'item'} selected)`)
                }
                onChange={(e, data) => this.handleOwnSelectAllReposToggle(data)}
              />
            </div>
            {subHeader && <div className="checklist-header">{subHeader}</div>}
          </div>
        )}
        {loading ? (
          <LoadingDimmer />
        ) : (
          <div
            ref={this.checkboxListRef}
            className="checkbox-list-and-list"
            style={{ marginLeft: insideList ? 25 : 0, maxHeight: maxHeightList }}
          >
            {this.renderBranches()}
          </div>
        )}
      </div>
    );
  }
}
ForesightCheckboxList.propTypes = {
  data: PropTypes.any,
  loading: PropTypes.bool,
  fetched: PropTypes.bool,
  handleCheckboxOnClick: PropTypes.func,
  handleSelectAllToggle: PropTypes.func,
};
