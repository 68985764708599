import { CONFIG } from './config-util';

export const SESSION_URL_KEY = 'REDIRECT_FORCE_URL';

export function setRedirectionForceURL() {
	const rURL = window.location.href;
	if (rURL === `${CONFIG.FORESIGHT_APP_URL}/` || rURL.includes('callback') || rURL.includes('/landing')) {
		//Do Nothing
	} else {
		localStorage.setItem(SESSION_URL_KEY, window.location.pathname + window.location.search);
	}
}

export function checkRedirectionPageNavigatedThenClear() {
	const path = localStorage.getItem(SESSION_URL_KEY);
	if (window.location.href.includes(path)) {
		setTimeout(() => {
			localStorage.removeItem(SESSION_URL_KEY);
		}, 3000);
	}
}

export function getRedirectionForcePath() {
	const path = localStorage.getItem(SESSION_URL_KEY);
	return path;
}
