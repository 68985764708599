import React, { Component } from 'react';
import { WidgetCard } from 'components/HighlightsPage/HighlightParts/WidgetCard';
import { calcDisplayedTime } from 'utils/date-util';
import './TotalDurationTimeSeriesWidget.scss';

export class TotalDurationTimeSeriesWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: [],
        };
    }

    componentDidMount() {
        this.convertHistogramData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.convertHistogramData();
        }
    }

    convertHistogramData() {
        const { data } = this.props;
        const chartData = {};
        data.forEach((el) => {
            let newWorkflowName = el?.repo?.fullName + '/' + el?.name;
            for (const [key, value] of Object.entries(el.durations).sort()) {
                const obj = chartData[key];
                if (obj) {
                    chartData[key] = {
                        ...obj,
                        [newWorkflowName]: value,
                    };
                }
                else {
                    chartData[key] = {
                        [newWorkflowName]: value,
                        time: key,
                    };
                }
            }
        }
        );
        const convertData = Object.values(chartData);

        this.setState({
            chartData: convertData,
        });
    }

    render() {
        const { selectedLineName, sentListName } = this.props;
        const { chartData } = this.state;
        return (
            <div>
                <WidgetCard
                    title="SumDurationOfLongest10Workflows"
                    chartData={chartData}
                    legendValueFormatter={(entry) => calcDisplayedTime(entry.value) + ' min'}
                    timeConfig
                    selectedLineName={selectedLineName}
                    sentListName={sentListName}
                />
            </div>
        );
    }
}