import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { INVITE_BILLING_ADMIN_TO_ORGANIZATION } from '../constants/organization-invite-billing-admin';
import { USER_ROLES } from 'utils/user-roles-enum';

export const inviteBillingAdminToOrganization = email => {
  const reqObj = {
    emails: [email],
    userRole: USER_ROLES.BILLING_ADMIN,
  };
  return {
    type: INVITE_BILLING_ADMIN_TO_ORGANIZATION,
    payload: axios.post(CONFIG.apiUrl + '/organizations/me/invite', reqObj, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
