import React, { Component } from 'react';
import { MainLayoutContainer } from 'containers';
import routeList from 'routes';
import { TestRunDetailHeaderContainer, TestRunSuiteCardListContainer, TestRunTestTabsContainer } from 'containers';
import './TestRunTestCodePage.scss';

class TestRunTestCodePage extends Component {
	render() {
		return (
			<MainLayoutContainer title={routeList.testRunTestCode.title} pageHeader="TestRun Test Code">
				<div className="test-run-testcase-code-container">
					<div className="header">
						<TestRunDetailHeaderContainer />
					</div>
					<div className="content">
						<div className="content-left">
							<TestRunSuiteCardListContainer />
						</div>
						<div className="content-right">
							<TestRunTestTabsContainer selectedTab="code" />
						</div>
					</div>
				</div>
			</MainLayoutContainer>
		);
	}
}

export default TestRunTestCodePage;
