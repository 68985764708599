import {
  GET_WORKFLOW_RUNS_META_FULFILLED,
  GET_WORKFLOW_RUNS_META_PENDING,
  GET_WORKFLOW_RUNS_META_REJECTED,
  SET_WORKFLOW_IDS_SELECTED,
  SET_WORKFLOW_RUN_BRANCHES_SELECTED,
  SET_WORKFLOW_RUN_CONCLUSIONS_SELECTED,
  GET_WORKFLOW_RUNS_FILTER_LIST_FULFILLED,
  GET_WORKFLOW_RUNS_FILTER_LIST_PENDING,
  GET_WORKFLOW_RUNS_FILTER_LIST_REJECTED,
  SET_WORKFLOW_RUN_EVENTS_SELECTED,
  SET_WORKFLOW_RUN_HIDE_FORK_SELECTED,
  GET_WORKFLOW_RUNS_LABELS_PENDING,
  GET_WORKFLOW_RUNS_LABELS_FULFILLED,
  GET_WORKFLOW_RUNS_LABELS_REJECTED,
  SET_WORKFLOW_RUN_LABELS_SELECTED,
} from 'store/constants/workflow-runs-meta';

const workflowRunsMetaInitialState = {
  metaId: '',
  filterId: '',
  fetching: false,
  fetched: false,
  error: null,
  workflowRunsMeta: [],
  workflowLabelsData: [],
  workflowLabelsDataFetching: false,
  workflowLabelsDataFetched: false,
  workflowLabelsDataError: null,
  workflowRunFilters: {},
  selectedWorkflowIds: [],
  selectedWorkflowRunConclusions: [],
  selectedWorkflowRunBranches: [],
  selectedWorkflowRunEvents: [],
  selectedWorkflowRunLabels: [],
  selectedWorkflowRunHideFork: false,
};

const TAGS = {
  meta: 'meta',
  filter: 'filter',
};

const generateId = (tag, action) => {
  const { ciProvider, repoOwner, repoName } = action.meta || {
    ciProvider: undefined,
    repoOwner: undefined,
    repoName: undefined,
  };
  return `${tag}-${ciProvider}-${repoOwner}-${repoName}`;
};

const getSelectedWorkflowIds = (state, action) => {
  if (state.metaId === generateId(TAGS.meta, action)) {
    return state.selectedWorkflowIds;
  }
  return [];
};

const getSelectedWorkflowRunConclusions = (state, action) => {
  const newFilterId = generateId(TAGS.filter, action);
  if (state.filterId === newFilterId) {
    return state.selectedWorkflowRunConclusions;
  }
  return [];
};

const getSelectedWorkflowRunBranches = (state, action) => {
  if (state.filterId === generateId(TAGS.filter, action)) {
    return state.selectedWorkflowRunBranches;
  }
  return [];
};

const getSelectedWorkflowRunEvents = (state, action) => {
  const newFilterId = generateId(TAGS.filter, action);
  if (state.filterId === newFilterId) {
    return state.selectedWorkflowRunEvents;
  }
  return [];
};

export default function workflowRunsMeta(state = workflowRunsMetaInitialState, action) {
  switch (action.type) {
    case GET_WORKFLOW_RUNS_META_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case GET_WORKFLOW_RUNS_META_FULFILLED:
      return {
        ...state,
        metaId: generateId(TAGS.meta, action),
        fetching: false,
        fetched: true,
        error: false,
        workflowRunsMeta: action.payload.data,
        selectedWorkflowIds: getSelectedWorkflowIds(state, action), //[] also means All, No Need fill action.payload.data.map(el => el.id),
      };
    case GET_WORKFLOW_RUNS_META_REJECTED:
      return {
        ...state,
        metaId: generateId(TAGS.meta, action),
        fetching: false,
        fetched: false,
        error: true,
        workflowRunsMeta: [],
        selectedWorkflowIds: [],
      };
    case SET_WORKFLOW_IDS_SELECTED:
      return {
        ...state,
        selectedWorkflowIds: action.payload.data,
      };
    case SET_WORKFLOW_RUN_CONCLUSIONS_SELECTED:
      return {
        ...state,
        selectedWorkflowRunConclusions: action.payload.data,
      };
    case SET_WORKFLOW_RUN_BRANCHES_SELECTED:
      return {
        ...state,
        selectedWorkflowRunBranches: action.payload.data,
      };
    case SET_WORKFLOW_RUN_EVENTS_SELECTED:
      return {
        ...state,
        selectedWorkflowRunEvents: action.payload.data,
      };
    case SET_WORKFLOW_RUN_LABELS_SELECTED:
      return {
        ...state,
        selectedWorkflowRunLabels: action.payload.data,
      };
    case SET_WORKFLOW_RUN_HIDE_FORK_SELECTED:
      return {
        ...state,
        selectedWorkflowRunConclusions: [],
        selectedWorkflowRunBranches: [],
        selectedWorkflowRunEvents: [],
        selectedWorkflowRunHideFork: action.payload.data,
      };
    case GET_WORKFLOW_RUNS_FILTER_LIST_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case GET_WORKFLOW_RUNS_FILTER_LIST_FULFILLED:
      return {
        ...state,
        filterId: generateId(TAGS.filter, action),
        fetching: false,
        fetched: true,
        error: false,
        workflowRunFilters: { ...action.payload.data },
        selectedWorkflowRunConclusions: getSelectedWorkflowRunConclusions(state, action),
        selectedWorkflowRunBranches: getSelectedWorkflowRunBranches(state, action),
        selectedWorkflowRunEvents: getSelectedWorkflowRunEvents(state, action),
      };
    case GET_WORKFLOW_RUNS_FILTER_LIST_REJECTED:
      return {
        ...state,
        filterId: generateId(TAGS.filter, action),
        fetching: false,
        fetched: false,
        error: true,
        workflowRunFilters: {},
        selectedWorkflowRunBranches: [],
        selectedWorkflowRunConclusions: [],
        selectedWorkflowRunEvents: [],
      };
    case GET_WORKFLOW_RUNS_LABELS_PENDING:
      return {
        ...state,
        workflowLabelsDataFetching: true,
        workflowLabelsDataFetched: false,
        workflowLabelsDataError: null,
      };
    case GET_WORKFLOW_RUNS_LABELS_FULFILLED:
      return {
        ...state,
        filterId: generateId(TAGS.filter, action),
        workflowLabelsDataFetching: false,
        workflowLabelsDataFetched: true,
        workflowLabelsDataError: false,
        workflowLabelsData: action.payload.data,
      };
    case GET_WORKFLOW_RUNS_LABELS_REJECTED:
      return {
        ...state,
        filterId: generateId(TAGS.filter, action),
        workflowLabelsDataFetching: false,
        workflowLabelsDataFetched: false,
        workflowLabelsDataError: true,
        workflowLabelsData: [],
        selectedWorkflowRunLabels: [],
      };

    default:
      return state;
  }
}
