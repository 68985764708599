import { UI_ICON } from 'assets/font-icons/IconManager';
import { Tooltip } from 'components/Tooltip';
import { BlueButton, GrayBorderButton, RedButton, TextButton } from 'custom-components/Button/Button';
import { ForesightInput } from 'custom-components/Input/Input';
import React from 'react';
import { toast } from 'react-toastify';
import { hasArrayElement } from 'utils/array-util';
import { ToastConfig } from 'utils/toast-util';
import './EditLabelPanel.scss';

const MAX_ORGANIZATION_LABEL_COUNT = 10;
const MAX_ORGANIZATION_LABEL_SCROLL_DISPLAY_COUNT = 5;
const MAX_LABEL_CHAR_COUNT = 25;
export class EditLabelPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newLabelName: '',
      editLabelName: '',
      createLabelMode: !hasArrayElement(this.getOrganizationLabels(props)),
      editLabelId: null,
      deleteLabelId: null,
      createLabelErrorMsg: '',
      updateLabelErrorMsg: '',
      loading: false,
    };

    this.createLabelBtnRef = React.createRef();
    this.updateLabelBtnRef = React.createRef();
  }

  //Utils
  getOrganizationLabels = props => {
    const { organizationWorkflowsLabels } = props;
    const { organizationLabels } = organizationWorkflowsLabels;
    return organizationLabels;
  };

  //Rendering ......
  renderActionButtons = () => {
    const { createButtonOnClick } = this.props;

    return (
      <div className="action-buttons-container">
        <BlueButton primary onClick={createButtonOnClick}>
          OK
        </BlueButton>
      </div>
    );
  };

  getInputErrorMessage = message => {
    return (
      <span className="error-input-message">
        <i className={UI_ICON.ALERT.ERROR} />
        {message}
      </span>
    );
  };

  renderCreateLabel = () => {
    const { createLabelMode, loading, newLabelName, createLabelErrorMsg } = this.state;
    if (createLabelMode) {
      return (
        <div className="label-creation-part">
          <div className="left-part">
            <ForesightInput
              ref={this.createLabelBtnRef}
              placeholder="Label name"
              labelText="Label name"
              required
              onChange={e => {
                this.setState({ newLabelName: e.target.value });
              }}
            />
            {createLabelErrorMsg && this.getInputErrorMessage(createLabelErrorMsg)}
          </div>
          <div className="right-part">
            <div className="action-buttons-container">
              <TextButton
                style={{ height: 32 }}
                onClick={() => {
                  this.setState({
                    newLabelName: '',
                    createLabelMode: false,
                    createLabelErrorMsg: '',
                  });
                }}
              >
                Cancel
              </TextButton>
              <BlueButton
                primary
                disabled={newLabelName.length === 0}
                onClick={() => {
                  const organizationLabels = this.getOrganizationLabels(this.props) || [];
                  if (organizationLabels.some(el => el.name === newLabelName)) {
                    this.setState({ createLabelErrorMsg: 'Label Exist' });
                    return false;
                  }

                  if (typeof newLabelName === 'string' && newLabelName.length > MAX_LABEL_CHAR_COUNT) {
                    this.setState({ createLabelErrorMsg: 'Max 25 Characters' });
                    return false;
                  }

                  const {
                    organizationId,
                    createOrganizationWorkflowLabels,
                    getOrganizationLabels,
                    getOrganizationWorkflowIdLabelMap,
                  } = this.props;
                  this.setState({ loading: true, createLabelErrorMsg: '' });
                  createOrganizationWorkflowLabels(
                    organizationId,
                    newLabelName,
                    [],
                    () => {
                      getOrganizationLabels(organizationId);
                      getOrganizationWorkflowIdLabelMap(organizationId);
                      this.setState({ createLabelMode: false, loading: false });
                    },
                    () => {
                      this.setState({ loading: false });
                      toast.error('Label Creation Problem', ToastConfig);
                    },
                  );
                }}
                loading={loading}
              >
                Create
              </BlueButton>
            </div>
          </div>
        </div>
      );
    }
    return '';
  };

  renderLabelUpdateMode = el => {
    const { loading, editLabelName, updateLabelErrorMsg } = this.state;
    const {
      organizationId,
      updateOrganizationWorkflowLabel,
      getOrganizationLabels,
      getOrganizationWorkflowIdLabelMap,
    } = this.props;
    return (
      <div className="label-update-part">
        <div className="left-part">
          <ForesightInput
            style={{ height: 32 }}
            ref={this.updateLabelBtnRef}
            defaultValue={el.name}
            required
            onChange={e => {
              this.setState({ editLabelName: e.target.value });
            }}
          />
          {updateLabelErrorMsg && this.getInputErrorMessage(updateLabelErrorMsg)}
        </div>
        <div className="right-part">
          <TextButton
            style={{ height: 32 }}
            onClick={() => {
              this.setState({
                newLabelName: '',
                editLabelId: null,
                updateLabelErrorMsg: '',
              });
            }}
          >
            Cancel
          </TextButton>
          <BlueButton
            primary
            disabled={editLabelName === el.name || editLabelName.length === 0}
            onClick={() => {
              const organizationLabels = this.getOrganizationLabels(this.props) || [];
              if (organizationLabels.some(el => el.name === editLabelName)) {
                this.setState({ updateLabelErrorMsg: 'Label Exist' });
                return false;
              }

              if (typeof editLabelName === 'string' && editLabelName.length > MAX_LABEL_CHAR_COUNT) {
                this.setState({ updateLabelErrorMsg: 'Max 25 Characters' });
                return false;
              }

              this.setState({ loading: true, updateLabelErrorMsg: '' });
              updateOrganizationWorkflowLabel(
                organizationId,
                el.id,
                editLabelName,
                () => {
                  this.setState({ loading: false, editLabelId: null });
                  getOrganizationLabels(organizationId);
                  getOrganizationWorkflowIdLabelMap(organizationId);
                },
                () => {},
              );
            }}
            loading={loading}
          >
            Update
          </BlueButton>
        </div>
      </div>
    );
  };

  renderLabelDeleteMode = () => {
    const { loading } = this.state;
    return (
      <div className="label-delete-part">
        <div className="left-part">
          <span>Are you sure to delete label ?</span>
        </div>
        <div className="right-part">
          <TextButton
            style={{ height: 32 }}
            onClick={() => {
              this.setState({ deleteLabelId: null });
            }}
          >
            Cancel
          </TextButton>
          <RedButton
            onClick={() => {
              const {
                deleteOrganizationWorkflowLabels,
                getOrganizationLabels,
                getOrganizationWorkflowIdLabelMap,
                organizationId,
              } = this.props;

              const { deleteLabelId } = this.state;
              if (deleteLabelId) {
                this.setState({ loading: true });
                deleteOrganizationWorkflowLabels(
                  organizationId,
                  deleteLabelId,
                  () => {
                    this.setState({ loading: false, deleteLabelId: null });
                    getOrganizationLabels(organizationId);
                    getOrganizationWorkflowIdLabelMap(organizationId);
                  },
                  () => {
                    toast.error('Something problem label deletion', ToastConfig);
                  },
                );
              }
            }}
            loading={loading}
          >
            Delete
          </RedButton>
        </div>
      </div>
    );
  };

  renderLabelDefaultMode = el => {
    return (
      <div key={el.id} className="label-item">
        <div className="workflow-label">
          <span className="workflow-label-text">{el.name}</span>
        </div>

        <Tooltip blackEdition content={<div>Edit</div>}>
          <i
            onClick={() => {
              this.setState({
                editLabelId: el.id,
                newLabelName: el.name,
                deleteLabelId: null,
                createLabelMode: false,
              });
            }}
            className={UI_ICON.EDIT}
          />
        </Tooltip>

        <Tooltip blackEdition content={<div>Delete</div>}>
          <i
            onClick={() => {
              this.setState({ deleteLabelId: el.id, editLabelId: null, createLabelMode: false });
            }}
            className={UI_ICON.DELETE}
          />
        </Tooltip>
      </div>
    );
  };

  renderLabels = () => {
    const { editLabelId, deleteLabelId } = this.state;
    const organizationLabels = this.getOrganizationLabels(this.props);

    if (hasArrayElement(organizationLabels)) {
      return organizationLabels.map(el => {
        if (editLabelId === el.id) {
          return this.renderLabelUpdateMode(el);
        } else if (deleteLabelId === el.id) {
          return this.renderLabelDeleteMode(el);
        } else {
          return this.renderLabelDefaultMode(el);
        }
      });
    }
    return 'No Label';
  };

  render() {
    const organizationLabels = this.getOrganizationLabels(this.props);
    const hasScroll =
      hasArrayElement(organizationLabels) && organizationLabels.length > MAX_ORGANIZATION_LABEL_SCROLL_DISPLAY_COUNT
        ? true
        : false;
    const labelContainerClass = hasScroll ? 'label-container hasScroll' : 'label-container';

    return (
      <div className="apply-wfs-modal-container">
        <div className="apply-wfs-modal-header">
          <div className="title">Label Settings</div>
          <GrayBorderButton
            style={{ width: 156, height: 32, borderColor: 'transparent' }}
            onClick={() => {
              const isReachMaxLabel =
                hasArrayElement(organizationLabels) && organizationLabels.length === MAX_ORGANIZATION_LABEL_COUNT;
              if (isReachMaxLabel) {
                toast.error('You reached max label', ToastConfig);
              } else {
                this.setState({ createLabelMode: true });
              }
            }}
          >
            Create a new label
          </GrayBorderButton>
        </div>

        <div className="wf-edit-label-panel">
          {this.renderCreateLabel()}
          <div className="label-title-container">
            <span className="label-title">Label</span>
          </div>
          <div className={labelContainerClass}>{this.renderLabels()}</div>
          {this.renderActionButtons()}
        </div>
      </div>
    );
  }
}
