import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ThemeButton } from 'components';
import { changeToDarkTheme, changeToLightTheme } from 'store/actions/theme';

function mapStateToProps(store) {
	return {
		theme: store.theme,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		changeToDarkTheme() {
			dispatch(changeToDarkTheme());
		},
		changeToLightTheme() {
			dispatch(changeToLightTheme());
		},
	};
}

const ThemeButtonContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ThemeButton));
export default ThemeButtonContainer;


