import React, { Component } from 'react';
import './TestRunTestHistoryChart.scss';
import { getTestCaseHistoryDetail } from 'utils/test-detail-util';
import moment from 'moment';
import { BarChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar, Cell } from 'recharts';
import { calcDisplayedTime, timeDiffAsMillis } from 'utils/date-util';
import routeList, { computePath } from 'routes';
import { LoadingDimmer, NoChartAvailable, SomethingError } from 'components';
import { hasArrayElement } from 'utils/array-util';

const renderLegend = () => {
  return (
    <div className="test-history-chart-legend">
      <div className="legend-duration-part">
        <div className="execTime">
          <div className="title">EXECUTION TIME</div>
        </div>
      </div>
    </div>
  );
};

const renderTooltip = props => {
  const { payload } = props;

  let execTime = '-';

  if (payload && payload.length > 0) {
    const data = payload[0].payload;
    if (data) {
      execTime = data.execTime;
    }
  }

  return (
    <div className="test-history-chart-tooltip">
      <div className="title">Duration:</div>
      <div className="value">{calcDisplayedTime(execTime)}</div>
    </div>
  );
};

class TestRunTestHistoryChart extends Component {
  dateFormatter(time) {
    const xAxisTimeFormat = 'MM/DD HH:mm';
    const dateFormatterString = moment(time).local().format(xAxisTimeFormat);
    return dateFormatterString;
  }

  handleTestExecTimeClick = data => {
    if (data && data.payload) {
      const { testRunId, transactionId } = data.payload;
      const { ciProvider, repoOwner, repoName } = this.props.match.params;
      const testRunTestPerformancePath = computePath(routeList.testRunTestPerformance.path, {
        testRunId: testRunId,
        transactionId: transactionId,
        ciProvider: ciProvider,
        repoOwner: repoOwner,
        repoName: repoName,
      });
      this.props.history.push(testRunTestPerformancePath + '#auto-focus');
    }
  };

  getStrokeWidth = entry => {
    const { transactionId: eTransactionId, testRunId: eTestRunId } = entry;
    const { testRunId, transactionId } = this.props.match.params;
    if (eTransactionId === transactionId && testRunId === eTestRunId) {
      return 0; // TODO if Masking used then DELETE Stroke
    }
    return 0;
  };

  getStrokeColor = entry => {
    const { status } = entry;
    if (status === 'SUCCESSFUL') {
      return 'green';
    }
    return 'red';
  };

  getFillColor = entry => {
    const { status } = entry;
    if (status === 'SUCCESSFUL') {
      return '#2DCD8A';
    }
    return '#FF5630';
  };

  getMask = entry => {
    const { transactionId: eTransactionId, testRunId: eTestRunId } = entry;
    const { testRunId, transactionId } = this.props.match.params;
    if (eTransactionId === transactionId && testRunId === eTestRunId) {
      return 'url(#mask-stripe)';
    }
    return '';
  };

  handleResetSelection() {}

  render() {
    const { testDetailMap } = this.props.testDetails;
    const { transactionId } = this.props.match.params;
    const testHistoryObj = getTestCaseHistoryDetail(testDetailMap, transactionId);
    if (testHistoryObj) {
      const { fetched, fetching, error, testHistory } = testHistoryObj;
      if (fetching) {
        return (
          <div className="history-loading-msg-container">
            <LoadingDimmer msg="Loading" />
          </div>
        );
      } else if (error) {
        return (
          <div className="error-history-msg-container">
            <SomethingError msg={error} />
          </div>
        );
      } else if (fetched) {
        if (!hasArrayElement(testHistory.content)) {
          return (
            <div className="no-history-data-msg-container">
              <NoChartAvailable msg="No Data Available" />
            </div>
          );
        }

        let dataCopy = [...testHistory.content];
        let data = [];
        dataCopy
          .sort((a, b) => {
            return a.startTime - b.startTime;
          })
          .forEach((el, index) => {
            if (index < 20) {
              let dObj = { ...el };
              dObj.time = this.dateFormatter(el.startTime);
              dObj.execTime = timeDiffAsMillis(el.endTime, el.startTime);
              data.push(dObj);
            }
          });

        return (
          <ResponsiveContainer height={300}>
            <BarChart
              cursor="pointer"
              width={800}
              height={300}
              data={data}
              margin={{ top: 25, right: 20, bottom: 20, left: 20 }}
            >
              <pattern
                id="pattern-stripe"
                width="8"
                height="8"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(45)"
              >
                <rect width="4" height="8" transform="translate(0,0)" fill="white"></rect>
              </pattern>
              <mask id="mask-stripe">
                <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
              </mask>

              <CartesianGrid horizontal={true} vertical={false} stroke="#2A3B50" />
              <XAxis
                dataKey="time"
                tick={{
                  fill: '#96A0B0',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '10px',
                  lineHeight: '16px',
                }}
              ></XAxis>
              <YAxis
                axisLine={false}
                tickFormatter={tick => {
                  return calcDisplayedTime(tick);
                }}
                tick={{
                  fill: '#96A0B0',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '10px',
                  lineHeight: '16px',
                }}
                label={{
                  dx: -30,
                  value: 'DURATION (Second)',
                  angle: -90,
                  position: 'insideMiddle',
                  textAnchor: 'middle',
                  fill: '#96A0B0',
                  offset: 10,
                }}
              />

              <Tooltip cursor={{ fill: 'transparent' }} content={renderTooltip} />
              <Legend content={renderLegend} />
              <Bar onClick={this.handleTestExecTimeClick} name="ExecTime" barSize={12} dataKey="execTime" stackId="a">
                {data.map((entry, index) => (
                  <Cell
                    key={index}
                    stroke={this.getStrokeColor(entry)}
                    strokeWidth={this.getStrokeWidth(entry)}
                    fill={this.getFillColor(entry)}
                    mask={this.getMask(entry)}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        );
      }
    }

    return (
      <div className="history-loading-msg-container">
        <LoadingDimmer msg="Loading" />
      </div>
    );
  }
}

export default TestRunTestHistoryChart;
