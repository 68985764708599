import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import {
  GET_ORGANIZATION_LABELS,
  GET_ORGANIZATION_WORKFLOW_LABELS,
  CREATE_ORGANIZATION_WORKFLOW_LABELS,
  UPDATE_ORGANIZATION_WORKFLOW_LABELS,
  ATTACH_ORGANIZATION_WORKFLOW_LABELS,
  DETACH_ORGANIZATION_WORKFLOW_LABELS,
} from 'store/constants/organization-workflows-labels';

export const getOrganizationLabels = organizationId => {
  return {
    meta: { organizationId: organizationId },
    type: GET_ORGANIZATION_LABELS,
    payload: axios.get(CONFIG.apiUrl + `/organization/${organizationId}/workflows/label`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};

export const getOrganizationWorkflowIdLabelMap = organizationId => {
  return {
    meta: { organizationId: organizationId },
    type: GET_ORGANIZATION_WORKFLOW_LABELS,
    payload: axios.get(CONFIG.apiUrl + `/organization/${organizationId}/workflows/label/map`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};

export function createOrganizationWorkflowLabels(organizationId, labelName, workflowIds) {
  return {
    meta: { organizationId: organizationId },
    type: CREATE_ORGANIZATION_WORKFLOW_LABELS,
    payload: axios.post(
      CONFIG.apiUrl + `/organization/${organizationId}/workflows/label`,
      { name: labelName, workflowIds: workflowIds },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function updateOrganizationWorkflowLabel(organizationId, labelId, labelName) {
  return {
    meta: { organizationId: organizationId },
    type: UPDATE_ORGANIZATION_WORKFLOW_LABELS,
    payload: axios.patch(
      CONFIG.apiUrl + `/organization/${organizationId}/workflows/label/${labelId}`,
      { name: labelName },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function deleteOrganizationWorkflowLabels(organizationId, labelId) {
  return {
    meta: { organizationId: organizationId },
    type: ATTACH_ORGANIZATION_WORKFLOW_LABELS,
    payload: axios.delete(CONFIG.apiUrl + `/organization/${organizationId}/workflows/label/${labelId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function attachOrganizationWorkflowLabels(organizationId, labelId, workflowIds) {
  return {
    meta: { organizationId: organizationId },
    type: ATTACH_ORGANIZATION_WORKFLOW_LABELS,
    payload: axios.patch(
      CONFIG.apiUrl + `/organization/${organizationId}/workflows/label/attach`,
      { labelId: labelId, workflowIds: workflowIds },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function detachOrganizationWorkflowLabels(organizationId, labelId, workflowIds) {
  return {
    meta: { organizationId: organizationId },
    type: DETACH_ORGANIZATION_WORKFLOW_LABELS,
    payload: axios.patch(
      CONFIG.apiUrl + `/organization/${organizationId}/workflows/label/detach`,
      { labelId: labelId, workflowIds: workflowIds },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}
