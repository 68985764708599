import React from 'react';
import { Tooltip } from 'components/Tooltip';
import './OverviewTitle.scss';

export const OverviewTitleContainer = ({ ...props }) => {
  return (
    <div className="overview-title-container">
      <Tooltip blackEdition content={props.statusTooltipContent}>
        {props.status}
      </Tooltip>
      <Tooltip blackEdition position="top center" content={props.titleTooltipContent}>
        <span className="overview-title-main">{props.title}</span>
      </Tooltip>
      {props.extraStatus && (
        <Tooltip blackEdition content={props.extraStatusTooltipContent}>
          {props.extraStatus}
        </Tooltip>
      )}
    </div>
  );
};
