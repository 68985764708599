import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import './GitHubLogViewer.scss';
import { hasArrayElement } from 'utils/array-util';

const logLineStyle = {
  '\x1b[0m': { color: '#fff' },
  '\x1b[30m': { color: 'black' },
  '\x1b[31m': { color: 'red' },
  '\x1b[32m': { color: 'green' },
  '\x1b[33m': { color: 'orange' },
  '\x1b[34m': { color: 'blue' },
  '\x1b[35m': { color: 'magenta' },
  '\x1b[36m': { color: 'cyan' },
  '\x1b[37m': { color: 'white' },

  '\x1b[40m': { backgroundColor: 'black' },
  '\x1b[41m': { backgroundColor: 'red' },
  '\x1b[42m': { backgroundColor: 'green' },
  '\x1b[43m': { backgroundColor: 'orange' },
  '\x1b[44m': { backgroundColor: 'blue' },
  '\x1b[45m': { backgroundColor: 'magenta' },
  '\x1b[46m': { backgroundColor: 'cyan' },
  '\x1b[47m': { backgroundColor: 'white' },
};

export class GitHubLogViewer extends Component {
  constructor(props) {
    super(props);
    this.state = { foldableLogs: [], url: props.url, pageNo: 1, pageSize: props.pageSize, fetching: false };
  }

  calcColoring = logLine => {
    const matches = logLine.match(/\x1b\[\d+m/g);
    if (hasArrayElement(matches)) {
      return logLine
        .replace(/\x1b\[\d+m/g, '$!$')
        .split('$!$')
        .map((el, index) => {
          const styleCode = matches[index - 1];
          return (
            <span key={index} style={logLineStyle[styleCode]}>
              {el}
            </span>
          );
        });
    } else {
      return logLine;
    }
  };

  generateLogScrollableContainerId = () => {
    const { logKey } = this.props;
    return 'github-log-scrollable-' + logKey;
  };

  componentDidMount() {
    const { logTxt } = this.props;
    const foldableLogs = this.calcFoldings(logTxt);
    this.setState({ foldableLogs: foldableLogs, pageNo: 1, fetching: false });
    document.getElementById(this.generateLogScrollableContainerId()).addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById(this.generateLogScrollableContainerId());
    this.fetchWhenScrollAccessToBottom(wrappedElement);
  };

  fetchWhenScrollAccessToBottom = el => {
    const { pageNo, fetching } = this.state;
    if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
      if (!fetching) {
        const newPageNo = pageNo + 1;
        this.setState({ pageNo: newPageNo, fetching: true }, () => {
          setTimeout(() => {
            this.setState({ fetching: false });
          }, 200);
        });
      }
    }
  };

  calcFoldings = logTxt => {
    let foldingStartIndex = -1;
    const foldableLogs = [];

    if (logTxt) {
      const stackArr = logTxt.split(/(?:\r\n|\r|\n)/);
      const sampleDate = '2022-04-21T13:48:19.7133964Z '; //This is used only date clippling calculation in Log files
      let lineNo = 1;
      stackArr.forEach(stackItem => {
        if (stackItem.length > sampleDate.length) {
          const el = stackItem.substring(sampleDate.length, stackItem.length);
          if (el.includes('##[group]')) {
            foldingStartIndex = lineNo;
            foldableLogs.push({
              lineNo: lineNo,
              type: '##[group]',
              logLine: el.replace('##[group]', ''),
              isOpen: false,
              display: true,
            });
          } else if (el.includes('##[endgroup]')) {
            lineNo -= 1;
            foldingStartIndex = -1;
          } else {
            if (foldingStartIndex > -1) {
              foldableLogs.push({
                lineNo: lineNo,
                type: 'normal',
                parentIndex: foldingStartIndex,
                logLine: this.calcColoring(el),
                display: false,
              });
            } else {
              foldableLogs.push({
                lineNo: lineNo,
                type: 'normal',
                parentIndex: -1,
                logLine: this.calcColoring(el),
                display: true,
              });
            }
          }
          lineNo += 1;
        }
      });
    }

    return foldableLogs;
  };

  handleFoldable = (e, lineNo) => {
    e.preventDefault();
    const foldableLogs = [...this.state.foldableLogs];
    const findItem = foldableLogs.find(el => el.lineNo === lineNo);
    if (findItem) {
      findItem.isOpen = !findItem.isOpen;
      foldableLogs.forEach(el => {
        if (el.parentIndex === lineNo) {
          el.display = findItem.isOpen;
        }
      });
    }

    this.setState({ foldableLogs: foldableLogs });
  };

  generatePaginationOnLogs = (foldableLogs, pageSize, pageNo) => {
    const totalCount = pageSize * pageNo;
    if (totalCount < foldableLogs.length) {
      return foldableLogs.slice(0, totalCount);
    } else {
      return foldableLogs;
    }
  };

  render() {
    const { foldableLogs, pageNo } = this.state;
    const { pageSize } = this.props;
    const paginatedFoldableLogs = this.generatePaginationOnLogs([...foldableLogs], pageSize, pageNo);
    const stackList = paginatedFoldableLogs.map(({ lineNo, logLine, type, isOpen, display }, index) => {
      const logStackRowClass = display ? 'log-stack-row' : 'display-none';
      const foldableIconClass = isOpen ? faCaretDown : faCaretRight;

      return (
        <div className={logStackRowClass} key={`${index}=>${logLine}`}>
          <div className="line-number">
            <span>{lineNo}</span>
          </div>
          <div className="log-content-line">
            {type === '##[group]' && (
              <FontAwesomeIcon onClick={e => this.handleFoldable(e, lineNo)} icon={foldableIconClass} />
            )}
            <span>{logLine}</span>
          </div>
        </div>
      );
    });

    return (
      <div className="log-stack-flex-container">
        <div id={this.generateLogScrollableContainerId()} className="log-stack-row-list">
          {stackList}
        </div>
      </div>
    );
  }
}
