import React, { Component } from 'react';
import { LoadingDimmer, NoDataAvailable, SomethingError } from 'components/ComponentPlaceholder';
import './RepositoryTabs.scss';
import routeList, { computePath } from 'routes';
import { ForesightTabs } from 'custom-components/Tabs/Tabs';

const BUTTON_TEXT_ARR = ['Pull Requests', 'Workflow Runs', 'Test Runs'];
class RepositoryTabs extends Component {
  constructor(props) {
    super(props);
    let selectedTab = BUTTON_TEXT_ARR[0];
    if (this.props.match.path === routeList.repositoryWorkflowRuns.path) selectedTab = 'Workflow Runs';
    else if (this.props.match.path === routeList.repositoryTestRuns.path) selectedTab = 'Test Runs';

    this.state = {
      activeTab: selectedTab,
    };
  }

  handleClick = type => {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    if (type === BUTTON_TEXT_ARR[0]) {
      const prsPath = computePath(routeList.repositoryPRs.path, {
        ciProvider,
        repoOwner,
        repoName,
      });
      this.props.history.push(prsPath);
      this.setState({
        activeTab: type,
      });
    } else if (type === BUTTON_TEXT_ARR[1]) {
      const workflowRunsPath = computePath(routeList.repositoryWorkflowRuns.path, {
        ciProvider,
        repoOwner,
        repoName,
      });
      this.props.history.push(workflowRunsPath);
      this.setState({
        activeTab: type,
      });
    } else {
      this.props.history.push(
        computePath(routeList.repositoryTestRuns.path, {
          ciProvider,
          repoOwner,
          repoName,
        }),
      );
      this.setState({
        activeTab: type,
      });
    }
  };

  renderTabButtons = () => {
    return (
      <div style={{ marginBottom: 30 }}>
        <ForesightTabs
          buttonTextArr={BUTTON_TEXT_ARR}
          onClick={(e, data) => this.handleClick(data.name)}
          active={this.state.activeTab}
        />
      </div>
    );
  };

  render() {
    const { fetching, fetched, error } = { fetching: false, fetched: true, error: false };

    if (fetching) {
      return (
        <div className="logs-loading-msg-container">
          <LoadingDimmer msg="Loading" />
        </div>
      );
    } else if (error) {
      return (
        <div className="error-logs-msg-container">
          <SomethingError msg={error} />
        </div>
      );
    } else if (fetched) {
      return this.renderTabButtons();
    } else {
      return (
        <div className="no-logs-data-msg-container">
          <NoDataAvailable msg="No Errors Found" />
        </div>
      );
    }
  }
}

export default RepositoryTabs;
