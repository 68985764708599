import { RepositoryPRWorkflowRunsTabContainer } from 'containers';
import React from 'react';

export class PRWorkflowRuns extends React.Component {
  render() {
    return (
      <div>
        <RepositoryPRWorkflowRunsTabContainer />
      </div>
    );
  }
}
