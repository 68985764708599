import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
import { Tooltip } from 'components/Tooltip';
import React, { Component } from 'react';
import routeList, { computePath } from 'routes';
import { Button } from 'semantic-ui-react';
import { millisToMinutes, millisToSeconds } from 'utils/date-util';
import './RepositoryWorkflowCardListPanel.scss';

export class RepositoryWorkflowCardListPanel extends Component {
  onSeeWorkflowHighlightsClick = () => {
    const { repo, workflow } = this.props.workflowRuns.workflowRunOverview;
    let calcRoute = computePath(routeList.singleWorkflowHighlights.path, {
      ciProvider: repo.provider,
      repoOwner: repo.owner,
      repoName: repo.name,
      workflowId: workflow.id,
    });

    this.props.history.push(calcRoute);
  };

  renderTestOverallOverviewData = () => {
    const { workflowRunOverview } = this.props.workflowRuns;
    const { successfulCount, failedCount, ignoredCount, abortedCount } = workflowRunOverview.testSummary;
    return (
      <div className={'test-overall-overview-container'}>
        <div className="second-row">
          <div className="summary">
            <div className="success-test-summary">
              <Tooltip blackEdition={true} content="Successful">
                <i className={'icon successful ' + DOMAIN_ICON.GITHUB.TEST_STATUS.PASSED} />
              </Tooltip>

              <span className="success-test-count">{successfulCount}</span>
            </div>

            <div className="failed-test-summary">
              <Tooltip blackEdition={true} content="Failed">
                <i className={'icon failed ' + DOMAIN_ICON.GITHUB.TEST_STATUS.FAILED} />
              </Tooltip>
              <span className="success-test-count">{failedCount}</span>
            </div>
          </div>
        </div>
        <div className="third-row">
          <span className="tag">{ignoredCount} Skipped</span>
          <span className="tag">{abortedCount} Cancelled</span>
        </div>
      </div>
    );
  };

  renderTestOverallOverviewEmpty = () => {
    return (
      <div className="test-overall-overview-container">
        <div className="first-row">
          <div className="title">Identify failed, slow and unreliable tests</div>
        </div>
        <div className="second-row">
          <div className="empty-info-container">
            <div className="empty-info">
              Integrate test kit action and see change impact analysis of your workflow run
            </div>

            <Button
              primary
              className="integrate-now-btn"
              content="Integrate Now"
              onClick={e => {
                e.preventDefault;
                this.props.history.push(routeList.startIntegrationGitHubTestRuns.path);
              }}
            />
            <div className="doc-info-container">
              Already integrated ?
              <a href="https://docs.runforesight.com/how-to/troubleshooting" target="_blank" rel="noreferrer">
                Click here to troubleshoot{' '}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { workflowRunOverview } = this.props.workflowRuns;
    console.log('workflowRunOverview', workflowRunOverview);
    return (
      <div className="card-list-panel-container">
        <div className="panel-box-container">
          <div className="panel-box">
            <div className="title">Workflow</div>
            <div className="content">
              <Tooltip blackEdition={true} content={workflowRunOverview.workflow?.name}>
                <div className="tooltipContext">{workflowRunOverview.workflow?.name}</div>
              </Tooltip>
            </div>
            <div onClick={this.onSeeWorkflowHighlightsClick} className="link-title">
              See workflow highlights
            </div>
          </div>
        </div>
        <div className="panel-box-container">
          <div className="panel-box">
            <div className="flex-container">
              <div className="flex">
                <div className="title">Execution Time</div>
                <div className="content">
                  {millisToMinutes(workflowRunOverview?.duration)} <span>min</span>{' '}
                  {millisToSeconds(workflowRunOverview?.duration)} <span>sec</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="panel-box-container">
          <div className="panel-box">
            <div className="flex-container">
              <div className="flex">
                <div className="title">Tests</div>
                <div className="content">
                  {workflowRunOverview?.testSummary
                    ? this.renderTestOverallOverviewData()
                    : this.renderTestOverallOverviewEmpty()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
