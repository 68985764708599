import { UI_ICON } from 'assets/font-icons/IconManager';
import React from 'react';
import './InfoPanel.scss';

export const InfoPanelText = ({ children, ...props }) => {
  return (
    <div className="info-panel-text" {...props}>
      {children}
    </div>
  );
};

export const InfoPanelWarningIcon = () => {
  return <i className={UI_ICON.ALERT.WARNING + ' warning'} />;
};

export const InfoPanelInfoIcon = () => {
  return <i className={UI_ICON.CROSS.CIRCLE + ' info'} />;
};

export const InfoPanelTitle = ({ children, ...props }) => {
  return (
    <div className="info-panel-title" {...props}>
      {children}
    </div>
  );
};

export const InfoPanelTitleContainer = ({ children, ...props }) => {
  return (
    <div className="info-panel-title-container" {...props}>
      {children}
    </div>
  );
};

export const InfoPanel = ({ children, ...props }) => {
  //Extra Border Color Config
  let borderColor = '#ff9214';
  if (props.type === 'info') {
    borderColor = 'transparent';
  }

  return (
    <div style={{ borderColor: borderColor }} className="info-panel" {...props}>
      {children}
    </div>
  );
};
