/* TIMELINE WORKFLOW RUN PAGE */
export const GET_WORKFLOW_TIMELINE = 'GET_WORKFLOW_TIMELINE';
export const GET_WORKFLOW_TIMELINE_PENDING = 'GET_WORKFLOW_TIMELINE_PENDING';
export const GET_WORKFLOW_TIMELINE_FULFILLED = 'GET_WORKFLOW_TIMELINE_FULFILLED';
export const GET_WORKFLOW_TIMELINE_REJECTED = 'GET_WORKFLOW_TIMELINE_REJECTED';

export const GET_HAVE_TEST_RUNS = 'GET_HAVE_TEST_RUNS';
export const GET_HAVE_TEST_RUNS_PENDING = 'GET_HAVE_TEST_RUNS_PENDING';
export const GET_HAVE_TEST_RUNS_FULFILLED = 'GET_HAVE_TEST_RUNS_FULFILLED';
export const GET_HAVE_TEST_RUNS_REJECTED = 'GET_HAVE_TEST_RUNS_REJECTED';

export const GET_MOST_FAILED_TEST = 'GET_MOST_FAILED_TEST';
export const GET_MOST_FAILED_TEST_PENDING = 'GET_MOST_FAILED_TEST_PENDING';
export const GET_MOST_FAILED_TEST_FULFILLED = 'GET_MOST_FAILED_TEST_FULFILLED';
export const GET_MOST_FAILED_TEST_REJECTED = 'GET_HAVE_TEST_RUNS_REJECTED';

export const GET_WORKFLOW_HISTORY = 'GET_WORKFLOW_HISTORY';
export const GET_WORKFLOW_HISTORY_PENDING = 'GET_WORKFLOW_HISTORY_PENDING';
export const GET_WORKFLOW_HISTORY_FULFILLED = 'GET_WORKFLOW_HISTORY_FULFILLED';
export const GET_WORKFLOW_HISTORY_REJECTED = 'GET_WORKFLOW_HISTORY_REJECTED';

/* TIMELINE JOB PAGE */
export const GET_JOB_TEST_COUNTS = 'GET_JOB_TEST_COUNTS';
export const GET_JOB_TEST_COUNTS_PENDING = 'GET_JOB_TEST_COUNTS_PENDING';
export const GET_JOB_TEST_COUNTS_FULFILLED = 'GET_JOB_TEST_COUNTS_FULFILLED';
export const GET_JOB_TEST_COUNTS_REJECTED = 'GET_JOB_TEST_COUNTS_REJECTED';

export const GET_JOB_CPU_METRICS = 'GET_JOB_CPU_METRICS';
export const GET_JOB_CPU_METRICS_PENDING = 'GET_JOB_CPU_METRICS_PENDING';
export const GET_JOB_CPU_METRICS_FULFILLED = 'GET_JOB_CPU_METRICS_FULFILLED';
export const GET_JOB_CPU_METRICS_REJECTED = 'GET_JOB_CPU_METRICS_REJECTED';

export const GET_JOB_MEMORY_METRICS = 'GET_JOB_MEMORY_METRICS';
export const GET_JOB_MEMORY_METRICS_PENDING = 'GET_JOB_MEMORY_METRICS_PENDING';
export const GET_JOB_MEMORY_METRICS_FULFILLED = 'GET_JOB_MEMORY_METRICS_FULFILLED';
export const GET_JOB_MEMORY_METRICS_REJECTED = 'GET_JOB_MEMORY_METRICS_REJECTED';

export const GET_JOB_HISTORY = 'GET_JOB_HISTORY';
export const GET_JOB_HISTORY_PENDING = 'GET_JOB_HISTORY_PENDING';
export const GET_JOB_HISTORY_FULFILLED = 'GET_JOB_HISTORY_FULFILLED';
export const GET_JOB_HISTORY_REJECTED = 'GET_JOB_MEMORY_METRICS_REJECTED';

/* TIMELINE STEP PAGE */
export const GET_STEP_HISTORY = 'GET_STEP_HISTORY';
export const GET_STEP_HISTORY_PENDING = 'GET_STEP_HISTORY_PENDING';
export const GET_STEP_HISTORY_FULFILLED = 'GET_STEP_HISTORY_FULFILLED';
export const GET_STEP_HISTORY_REJECTED = 'GET_STEP_MEMORY_METRICS_REJECTED';

export const GET_STEP_CPU_METRICS = 'GET_STEP_CPU_METRICS';
export const GET_STEP_CPU_METRICS_PENDING = 'GET_STEP_CPU_METRICS_PENDING';
export const GET_STEP_CPU_METRICS_FULFILLED = 'GET_STEP_CPU_METRICS_FULFILLED';
export const GET_STEP_CPU_METRICS_REJECTED = 'GET_STEP_CPU_METRICS_REJECTED';

export const GET_STEP_MEMORY_METRICS = 'GET_STEP_MEMORY_METRICS';
export const GET_STEP_MEMORY_METRICS_PENDING = 'GET_STEP_MEMORY_METRICS_PENDING';
export const GET_STEP_MEMORY_METRICS_FULFILLED = 'GET_STEP_MEMORY_METRICS_FULFILLED';
export const GET_STEP_MEMORY_METRICS_REJECTED = 'GET_STEP_MEMORY_METRICS_REJECTED';

export const GET_STEP_JOB_HISTORY = 'GET_STEP_JOB_HISTORY';
export const GET_STEP_JOB_HISTORY_PENDING = 'GET_STEP_JOB_HISTORY_PENDING';
export const GET_STEP_JOB_HISTORY_FULFILLED = 'GET_STEP_JOB_HISTORY_FULFILLED';
export const GET_STEP_JOB_HISTORY_REJECTED = 'GET_STEP_JOB_HISTORY_REJECTED';

/* TIMELINE STEP TAB PAGE */

export const GET_STEP_PROCESSES = 'GET_STEP_PROCESSES';
export const GET_STEP_PROCESSES_PENDING = 'GET_STEP_PROCESSES_PENDING';
export const GET_STEP_PROCESSES_FULFILLED = 'GET_STEP_PROCESSES_FULFILLED';
export const GET_STEP_PROCESSES_REJECTED = 'GET_STEP_PROCESSES_REJECTED';

export const GET_STEP_TAB_CPU_METRICS = 'GET_STEP_TAB_CPU_METRICS';
export const GET_STEP_TAB_CPU_METRICS_PENDING = 'GET_STEP_TAB_CPU_METRICS_PENDING';
export const GET_STEP_TAB_CPU_METRICS_FULFILLED = 'GET_STEP_TAB_CPU_METRICS_FULFILLED';
export const GET_STEP_TAB_CPU_METRICS_REJECTED = 'GET_STEP_CPU_METRICS_REJECTED';

export const GET_STEP_TAB_MEMORY_METRICS = 'GET_STEP_TAB_MEMORY_METRICS';
export const GET_STEP_TAB_MEMORY_METRICS_PENDING = 'GET_STEP_TAB_MEMORY_METRICS_PENDING';
export const GET_STEP_TAB_MEMORY_METRICS_FULFILLED = 'GET_STEP_TAB_MEMORY_METRICS_FULFILLED';
export const GET_STEP_TAB_MEMORY_METRICS_REJECTED = 'GET_STEP_MEMORY_METRICS_REJECTED';

export const GET_STEP_TAB_DISK_METRICS = 'GET_STEP_TAB_DISK_METRICS';
export const GET_STEP_TAB_DISK_METRICS_PENDING = 'GET_STEP_TAB_DISK_METRICS_PENDING';
export const GET_STEP_TAB_DISK_METRICS_FULFILLED = 'GET_STEP_TAB_DISK_METRICS_FULFILLED';
export const GET_STEP_TAB_DISK_METRICS_REJECTED = 'GET_STEP_DISK_METRICS_REJECTED';

export const GET_STEP_TAB_NETWORK_METRICS = 'GET_STEP_TAB_NETWORK_METRICS';
export const GET_STEP_TAB_NETWORK_METRICS_PENDING = 'GET_STEP_TAB_NETWORK_METRICS_PENDING';
export const GET_STEP_TAB_NETWORK_METRICS_FULFILLED = 'GET_STEP_TAB_NETWORK_METRICS_FULFILLED';
export const GET_STEP_TAB_NETWORK_METRICS_REJECTED = 'GET_STEP_DISK_METRICS_REJECTED';

/* TIMELINE JOB TAB PAGE */

export const GET_FAILED_TEST_CASE = 'GET_FAILED_TEST_CASE';
export const GET_FAILED_TEST_CASE_PENDING = 'GET_FAILED_TEST_CASE_PENDING';
export const GET_FAILED_TEST_CASE_FULFILLED = 'GET_FAILED_TEST_CASE_FULFILLED';
export const GET_FAILED_TEST_CASE_REJECTED = 'GET_FAILED_TEST_CASE_REJECTED';

export const GET_JOB_PROCESSES = 'GET_JOB_PROCESSES';
export const GET_JOB_PROCESSES_PENDING = 'GET_JOB_PROCESSES_PENDING';
export const GET_JOB_PROCESSES_FULFILLED = 'GET_JOB_PROCESSES_FULFILLED';
export const GET_JOB_PROCESSES_REJECTED = 'GET_JOB_PROCESSES_REJECTED';

export const GET_JOB_TAB_CPU_METRICS = 'GET_JOB_TAB_CPU_METRICS';
export const GET_JOB_TAB_CPU_METRICS_PENDING = 'GET_JOB_TAB_CPU_METRICS_PENDING';
export const GET_JOB_TAB_CPU_METRICS_FULFILLED = 'GET_JOB_TAB_CPU_METRICS_FULFILLED';
export const GET_JOB_TAB_CPU_METRICS_REJECTED = 'GET_JOB_CPU_METRICS_REJECTED';

export const GET_JOB_TAB_MEMORY_METRICS = 'GET_JOB_TAB_MEMORY_METRICS';
export const GET_JOB_TAB_MEMORY_METRICS_PENDING = 'GET_JOB_TAB_MEMORY_METRICS_PENDING';
export const GET_JOB_TAB_MEMORY_METRICS_FULFILLED = 'GET_JOB_TAB_MEMORY_METRICS_FULFILLED';
export const GET_JOB_TAB_MEMORY_METRICS_REJECTED = 'GET_JOB_MEMORY_METRICS_REJECTED';

export const GET_JOB_TAB_DISK_METRICS = 'GET_JOB_TAB_DISK_METRICS';
export const GET_JOB_TAB_DISK_METRICS_PENDING = 'GET_JOB_TAB_DISK_METRICS_PENDING';
export const GET_JOB_TAB_DISK_METRICS_FULFILLED = 'GET_JOB_TAB_DISK_METRICS_FULFILLED';
export const GET_JOB_TAB_DISK_METRICS_REJECTED = 'GET_JOB_DISK_METRICS_REJECTED';

export const GET_JOB_TAB_NETWORK_METRICS = 'GET_JOB_TAB_NETWORK_METRICS';
export const GET_JOB_TAB_NETWORK_METRICS_PENDING = 'GET_JOB_TAB_NETWORK_METRICS_PENDING';
export const GET_JOB_TAB_NETWORK_METRICS_FULFILLED = 'GET_JOB_TAB_NETWORK_METRICS_FULFILLED';
export const GET_JOB_TAB_NETWORK_METRICS_REJECTED = 'GET_JOB_DISK_METRICS_REJECTED';
