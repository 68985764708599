import React, { Component } from 'react';
import './ThemeButton.scss';

class ThemeButton extends Component {
  componentDidMount() {
    const { theme } = this.props.theme;
    document.documentElement.setAttribute('data-theme', theme);
  }

  handleTheme = () => {
    const { theme } = this.props.theme;
    if (theme === 'light') {
      this.props.changeToDarkTheme();
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      this.props.changeToLightTheme();
      document.documentElement.setAttribute('data-theme', 'light');
    }
  };

  render() {
    const msg = this.props.theme ? this.props.theme.theme : '';
    return (
      <button style={{ width: 60 }} onClick={this.handleTheme}>
        {msg}
      </button>
    );
  }
}

export default ThemeButton;
