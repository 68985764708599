import { GET_PULL_REQUESTS_LIST } from 'store/constants/pull-requests';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

export function getPullRequests(
  ciProvider,
  repoOwner,
  repoName,
  authors,
  branches,
  labels,
  states,
  hideForkBranch,
  pageNo,
  pageSize,
) {
  const data = {};
  let apiPostfix = `/repos/${ciProvider}/${repoOwner}/${repoName}/pull-requests`;

  if (
    pageNo !== undefined &&
    pageSize !== undefined &&
    authors !== undefined &&
    branches !== undefined &&
    labels !== undefined &&
    states !== undefined &&
    hideForkBranch !== undefined
  ) {
    data['page'] = pageNo;
    data['size'] = pageSize;
    data['authors'] = authors;
    data['branches'] = branches;
    data['labels'] = labels;
    data['states'] = states;
    data['hideForkBranch'] = hideForkBranch;
  }

  return {
    type: GET_PULL_REQUESTS_LIST,
    payload: axios.post(CONFIG.apiUrl + apiPostfix, data, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
