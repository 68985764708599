import { Tooltip } from 'components/Tooltip';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import './TenHighestTotalDurationWorkflows.scss';
import { TotalDurationListWidget } from './TotalDurationListWidget/TotalDurationListWidget';
import { TotalDurationTimeSeriesWidget } from './TotalDurationTimeSeriesWidget/TotalDurationTimeSeriesWidget';

const infoText =
  'Combined total duration of all your workflows, including all statuses. You can see where you are spending the most in your workflows.';
export class TenHighestTotalDurationWorkflows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLine: null,
      sentListName: null,
    };
  }

  handleSelectedLine = val => {
    this.setState({ selectedLine: val });
  };

  handleSentListName = val => {
    this.setState({ sentListName: val });
  };

  render() {
    const { sumDurationOfLongest10Workflows, title, handlerId } = this.props;
    const { selectedLine, sentListName } = this.state;
    return (
      <div className="total-duration-container">
        <div className="total-duration-header">
          {title}&nbsp;
          <Tooltip hoverable blackEdition content={infoText}>
            <Icon style={{ height: '20px' }} name="info circle" />
          </Tooltip>
        </div>

        <div className="total-duration-chart-cont">
          <div className="total-chart">
            <TotalDurationTimeSeriesWidget
              data={sumDurationOfLongest10Workflows}
              selectedLineName={this.handleSelectedLine}
              sentListName={sentListName}
            />
          </div>
          <div className="total-chart">
            <TotalDurationListWidget
              data={sumDurationOfLongest10Workflows}
              handlerId={handlerId}
              handleName={selectedLine}
              handleSentListName={this.handleSentListName}
            />
          </div>
        </div>
      </div>
    );
  }
}
