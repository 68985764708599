import React, { Component } from 'react';
import './RepositoryWorkflowCard.scss';
import { LineChartViewer } from 'components/LineChartViewer';
import { hasArrayElement } from 'utils/array-util';
import { NoChartAvailable } from '../ComponentPlaceholder';

export default class RepositoryWorkflowCard extends Component {
  renderWithContent = () => {
  	const { chartData, chartConfig, title, subTitle, dayCount } = this.props;

  	return (
  		<div className="workflow-card-container">
  			<div className="header">
  				<div className="title">{title}</div>
  				<div className="period">Last {dayCount} days</div>
  			</div>

  			<div className="sub-header">{subTitle}</div>

  			<div className="chart">
  				<LineChartViewer
  					syncId="syncLine"
  					data={chartData}
  					dataProps={chartConfig.dataProps}
  					yAxisProps={chartConfig.yAxisProps}
  					xAxisProps={chartConfig.xAxisProps}
  					tooltipProps={chartConfig.tooltipProps}
  					legendProps={chartConfig.legendProps}
  					size={chartConfig.sizeProps}
  				/>
  			</div>
  		</div>
  	);
  };

  renderWithoutContent = () => {
  	return (
  		<div className="workflow-card-container">
  			<div className="headings">
  				<span className="title">{this.props.title}</span>
  				<span className="sub-header">{this.props.subTitle}</span>
  			</div>

  			<NoChartAvailable msg={'No data available'} />
  		</div>
  	);
  };

  render() {
  	const { chartData } = this.props;

  	if (hasArrayElement(chartData)) {
  		return this.renderWithContent();
  	} else {
  		return this.renderWithoutContent();
  	}
  }
}
