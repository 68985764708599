import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
import { Tooltip } from 'components/Tooltip';
import { ForesightTabs } from 'custom-components/Tabs/Tabs';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import routeList, { computePath } from 'routes';
import { millisToMAndSNotFixed } from 'utils/date-util';
import StepBarChart from '../../BarChart/StepBarChart';
import { MiniCard } from '../../MiniCard/MiniCard';
import { HaveTestRunsTab } from './HaveTestRuns/HaveTestRunsTab';
import { MostFailedTab } from './MostFailed/MostFailedTab';
import './WorkflowSummary.scss';

const BUTTON_TEXT_ARR = ['Have test runs', 'Failed test'];

class WorkflowSummary extends Component {
  constructor(props) {
    super(props);
    this.state = { activeTabName: BUTTON_TEXT_ARR[0] };
  }

  handleProcessSortChange = (e, data) => {
    this.setState({ activeTabName: data });
  };

  handleJobFocus = item => {
    const { ciProvider, repoOwner, repoName, workflowId } = this.props.matchParams;
    const focusPath = computePath(routeList.workflowRunTimelineJob.path, {
      ciProvider,
      repoOwner,
      repoName,
      workflowId,
      jobId: item.jobId,
    });
    this.props.history.push(focusPath);
  };

  handleTestFocus = item => {
    console.log('Test Focus', item);
    const { ciProvider, repoOwner, repoName } = this.props.matchParams;
    const focusPath = computePath(routeList.testRunOverview.path, {
      ciProvider,
      repoOwner,
      repoName,
      testRunId: item.testRunId,
    });
    this.props.history.push(focusPath);
  };

  render() {
    const { workflowHistoryContent, haveTestRunContent, mostFailedTest } = this.props;
    const { name, workflow, duration, testSummary } = this.props.workflowRunOverview;
    const { ciProvider, repoOwner, repoName } = this.props.matchParams;

    const workflowHighlightPath = computePath(routeList.singleWorkflowHighlights.path, {
      ciProvider: ciProvider,
      repoOwner: repoOwner,
      repoName: repoName,
      workflowId: workflow.id,
    });

    return (
      <>
        <div className="wf-run-summary-title">Workflow Summary</div>
        <div className="wf-run-summary-container">
          <MiniCard title="Workflow">
            <Tooltip blackEdition content={name}>
              <div className="mini-card-sub-info">{name}</div>
            </Tooltip>
            <Link to={workflowHighlightPath}>
              <div className="mini-card-link">See workflow highlight</div>
            </Link>
          </MiniCard>
          <MiniCard title="WORKFLOW HISTORY">
            <div className="mini-card-sub-info" style={{ marginBottom: 12 }}>
              {millisToMAndSNotFixed(duration)}
              <div className="mini-card-title-not-uppercase">Latest Execution Time</div>
            </div>
            <StepBarChart data={workflowHistoryContent || []} />
            <div className="mini-card-title-not-uppercase">Workflow Execution History</div>
          </MiniCard>
          <MiniCard title="TEST">
            <div className="wf-run-test-summary-info-cont">
              <div className="wf-run-test-summary-info">
                <i className={`${DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.CHECK} test-run-check`} />
                <div className="wf-run-test-count">{testSummary?.successfulCount}</div>
              </div>
              <div className="wf-run-test-summary-info">
                <i className={`${DOMAIN_ICON.GITHUB.TEST_STATUS.FAILED} test-run-failed`} />
                <div className="wf-run-test-count">{testSummary?.failedCount}</div>
              </div>
            </div>
            <div className="wf-run-test-summary-sub-info-cont">
              <div className="sub-info-cont">{testSummary?.ignoredCount} Skipped</div>
              <div className="sub-info-cont">{testSummary?.abortedCount} Cancelled</div>
            </div>

            <ForesightTabs
              style={{ marginTop: 8 }}
              withLine={false}
              id="repositories-project-card"
              onClick={(e, data) => {
                this.handleProcessSortChange(e, data.name);
              }}
              buttonTextArr={BUTTON_TEXT_ARR}
              active={this.state.activeTabName}
            />
            {this.state.activeTabName === BUTTON_TEXT_ARR[0] ? (
              <HaveTestRunsTab onClick={this.handleJobFocus} data={haveTestRunContent} />
            ) : (
              <MostFailedTab onClick={this.handleTestFocus} data={mostFailedTest} />
            )}
          </MiniCard>
        </div>
      </>
    );
  }
}

export default WorkflowSummary;
