import React, { Component } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { beautifyJSONInStr } from 'utils/string-util';
import { LazyLogViewer } from './LazyLogViewer/LazyLogViewer';
import { LogViewer } from './LogViewer/LogViewer';
import { copyToClipBoard } from 'utils/common-util';
import './ErrorStackViewer.scss';

class ErrorStackViewer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			logViewerType: {},
		};
	}

  renderErrorRows = stack => {
  	// console.log("renderErrorRows; stack: ", stack);

  	if (stack === '' || stack === undefined) {
  		return null;
  	}

  	//This Code Detect JSON in String and Format JSON.stringify(parsed, null, 4)
  	const modifiedStack = beautifyJSONInStr(stack);
  	const stackArr = modifiedStack.split(/(?:\r\n|\r|\n)/);
  	const stackList = stackArr.map((errLine, index) => {
  		if (errLine === '') {
  			return null;
  		}

  		console.log(`${index}=> ${errLine}`);
  		return (
  			<div className="error-stack-row" key={`${index}=>${errLine}`}>
  				<div className="line-number">
  					<span>{index + 1}</span>
  				</div>
  				<div className="error-content-line">{this.renderErrorContent(errLine)}</div>
  			</div>
  		);
  	});

  	return <div className="error-stack-row-list">{stackList}</div>;
  };

  renderCopyClipboardIcon = errorStack => {
  	return (
  		<Popup
  			content="Copied to clipboard"
  			size="mini"
  			position="top center"
  			on={['click']}
  			trigger={
  				<div className="copy-clipboard-wrapper" onClick={() => copyToClipBoard(errorStack)}>
  					<Icon inverted name="clone outline" />
  				</div>
  			}
  		/>
  	);
  };

  render() {
  	// console.log("ErrorStackViewer; props: ", this.props);

  	const { logViewerType } = this.state;
  	const errorMsg = this.props.stack ? this.props.stack : 'No Error Stack Exist';
  	if (!logViewerType) {
  		return (
  			<div className="error-stack-viewer-wrapper">
  				<div className="clipboard-icon-wrapper">{this.renderCopyClipboardIcon(this.props.stack)}</div>
  				<LogViewer text={errorMsg} />
  			</div>
  		);
  	} else {
  		return (
  			<div className="error-stack-viewer-wrapper">
  				<div className="clipboard-icon-wrapper">{this.renderCopyClipboardIcon(this.props.stack)}</div>
  				<LazyLogViewer text={errorMsg} />
  			</div>
  		);
  	}
  }
}

export default ErrorStackViewer;
