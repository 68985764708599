export const GET_PULL_REQUESTS_META = 'GET_PULL_REQUESTS_META';
export const GET_PULL_REQUESTS_META_PENDING = 'GET_PULL_REQUESTS_META_PENDING';
export const GET_PULL_REQUESTS_META_REJECTED = 'GET_PULL_REQUESTS_META_REJECTED';
export const GET_PULL_REQUESTS_META_FULFILLED = 'GET_PULL_REQUESTS_META_FULFILLED';

export const SET_PULL_REQUESTS_WORKFLOW_RUN_BRANCHES_SELECTED = 'SET_PULL_REQUESTS_WORKFLOW_RUN_BRANCHES_SELECTED';
export const SET_PULL_REQUESTS_LABELS_SELECTED = 'SET_PULL_REQUESTS_LABELS_SELECTED';
export const SET_PULL_REQUESTS_STATES_SELECTED = 'SET_PULL_REQUESTS_STATES_SELECTED';
export const SET_PULL_REQUESTS_AUTHORS_SELECTED = 'SET_PULL_REQUESTS_AUTHORS_SELECTED';

export const SET_PULL_REQUESTS_WORKFLOW_IDS_SELECTED = 'SET_PULL_REQUESTS_WORKFLOW_IDS_SELECTED';
export const SET_PULL_REQUESTS_WORKFLOW_RUN_CONCLUSIONS_SELECTED =
  'SET_PULL_REQUESTS_WORKFLOW_RUN_CONCLUSIONS_SELECTED';

export const GET_PULL_REQUESTS_FILTER_LIST = 'GET_PULL_REQUESTS_FILTER_LIST';
export const GET_PULL_REQUESTS_FILTER_LIST_PENDING = 'GET_PULL_REQUESTS_FILTER_LIST_PENDING';
export const GET_PULL_REQUESTS_FILTER_LIST_REJECTED = 'GET_PULL_REQUESTS_FILTER_LIST_REJECTED';
export const GET_PULL_REQUESTS_FILTER_LIST_FULFILLED = 'GET_PULL_REQUESTS_FILTER_LIST_FULFILLED';

export const SET_PULL_REQUESTS_HIDE_FORK_BRANCH_SELECTED = 'SET_PULL_REQUESTS_HIDE_FORK_BRANCH_SELECTED';
