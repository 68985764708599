import React from 'react';
import './MiniCard.scss';

export const MiniCard = ({ title, children }) => {
  return (
    <div className="mini-card-container">
      {title && <div className="mini-card-title">{title}</div>}
      {children}
    </div>
  );
};
