import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { CANCEL_ACTIVE_SUBSCRIPTION } from 'store/constants/billing-cancel-subscription';

export const cancelSubscription = organizationId => {
  return {
    type: CANCEL_ACTIVE_SUBSCRIPTION,
    payload: axios.patch(
      CONFIG.apiUrl + `/organizations/${organizationId}/subscriptions`,
      {},
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
};
