import {
  GET_ALL_PROJECTS_PENDING,
  GET_ALL_PROJECTS_FULFILLED,
  GET_ALL_PROJECTS_REJECTED,
  CREATE_NEW_PROJECT_PENDING,
  CREATE_NEW_PROJECT_FULFILLED,
  CREATE_NEW_PROJECT_REJECTED,
  SET_PROJECT_PENDING,
  SET_PROJECT_FULFILLED,
  SET_PROJECT_REJECTED,
  GET_PROJECT_BADGE,
  GET_PROJECT_BADGE_PENDING,
  GET_PROJECT_BADGE_FULFILLED,
  GET_PROJECT_BADGE_REJECTED,
  SET_REPO_SELECTED_PROJECT,
  SET_REPO_SELECTED_PROJECT_REJECTED,
  SET_REPO_SELECTED_PROJECT_PENDING,
  SET_REPO_SELECTED_PROJECT_FULFILLED,
  SET_REPO_SELECTED_PROJECT_FLUSHED,
  DELETE_PROJECT_PENDING,
  DELETE_PROJECT_FULFILLED,
  DELETE_PROJECT_REJECTED,
  SET_PROJECT_SELECTION_COMPONENT_STATE,
} from 'store/constants/projects';

const projectsInitialState = {
  fetching: false,
  fetched: false,
  error: null,
  repoSelectedProject: null,
  repoSelectedProjectFetching: false,
  repoSelectedProjectFetched: false,
  repoSelectedProjectError: false,
  projectList: [],
  selectedProject: null,
  projectBadgeFetching: false,
  projectBadgeFetched: false,
  projectBadgeError: null,
  projectDeletionFetching: false,
  projectDeletionFetched: false,
  projectDeletionError: null,
  isProjectSelectionDone: true,
};

export default function projects(state = projectsInitialState, action) {
  switch (action.type) {
    case GET_ALL_PROJECTS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_ALL_PROJECTS_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        projectList: action.payload.data,
      };
    case GET_ALL_PROJECTS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };
    case CREATE_NEW_PROJECT_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case CREATE_NEW_PROJECT_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        createdProject: action.payload.data,
      };
    case CREATE_NEW_PROJECT_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };
    case SET_PROJECT_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case SET_PROJECT_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
      };
    case SET_PROJECT_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };
    case GET_PROJECT_BADGE:
      return { ...state, projectBadgeFetching: false, projectBadgeFetched: false, projectBadgeError: action.payload };
    case GET_PROJECT_BADGE_PENDING:
      return { ...state, projectBadgeFetching: true, projectBadgeFetched: false, projectBadgeError: null };
    case GET_PROJECT_BADGE_FULFILLED:
      return {
        ...state,
        projectBadgeFetching: false,
        projectBadgeFetched: true,
        projectBadgeError: false,
        projectBadge: action.payload.data,
      };
    case GET_PROJECT_BADGE_REJECTED:
      return { ...state, projectBadgeFetching: false, projectBadgeFetched: false, projectBadgeError: action.payload };
    case SET_REPO_SELECTED_PROJECT:
      return { ...state, repoSelectedProject: action.payload };
    case SET_REPO_SELECTED_PROJECT_REJECTED:
      return {
        ...state,
        repoSelectedProject: action.payload.data,
        repoSelectedProjectFetching: false,
        repoSelectedProjectFetched: false,
        repoSelectedProjectError: true,
      };
    case SET_REPO_SELECTED_PROJECT_PENDING:
      return {
        ...state,
        repoSelectedProject: null,
        repoSelectedProjectFetching: true,
        repoSelectedProjectFetched: false,
        repoSelectedProjectError: false,
      };
    case SET_REPO_SELECTED_PROJECT_FULFILLED:
      return {
        ...state,
        repoSelectedProject: action.payload.data,
        repoSelectedProjectFetching: false,
        repoSelectedProjectFetched: true,
        repoSelectedProjectError: false,
      };
    case SET_REPO_SELECTED_PROJECT_FLUSHED:
      return {
        ...state,
        repoSelectedProject: null,
        repoSelectedProjectFetching: false,
        repoSelectedProjectFetched: false,
        repoSelectedProjectError: false,
      };
    case DELETE_PROJECT_PENDING:
      return {
        ...state,
        projectDeletionFetching: true,
        projectDeletionFetched: false,
        projectDeletionError: false,
      };
    case DELETE_PROJECT_FULFILLED:
      return {
        ...state,
        projectList: action.payload.data,
        projectDeletionFetching: false,
        projectDeletionFetched: true,
        projectDeletionError: false,
      };
    case DELETE_PROJECT_REJECTED:
      return {
        ...state,
        projectDeletionFetching: false,
        projectDeletionFetched: false,
        projectDeletionError: true,
      };
    case SET_PROJECT_SELECTION_COMPONENT_STATE:
      return {
        ...state,
        isProjectSelectionDone: action.payload.data,
      };
    default:
      return state;
  }
}
