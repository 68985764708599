import {
  UPDATE_CI_PROVIDER_REPOS,
  GET_CI_PROVIDER_REPOS,
  FLUSH_CI_PROVIDER_REPOS,
  CREATE_NEW_PROJECT_WITH_SELECTED_REPO,
} from 'store/constants/ci-provider-repos';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

export function updateCIProviderRepos(provider, projectId, newRepoList) {
  const data = {
    projectId,
    repos: newRepoList,
  };

  return {
    type: UPDATE_CI_PROVIDER_REPOS,
    payload: axios.put(CONFIG.apiUrl + `/repos/${provider}`, data, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getCIProviderRepos(provider, projectId) {
  if (projectId) {
    return {
      type: GET_CI_PROVIDER_REPOS,
      payload: axios.get(CONFIG.apiUrl + `/repos/${provider}?projectId=${projectId}`, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      }),
    };
  } else {
    return {
      type: GET_CI_PROVIDER_REPOS,
      payload: axios.get(CONFIG.apiUrl + `/repos/${provider}`, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      }),
    };
  }
}

export function flushCIProviderRepos() {
  return {
    type: FLUSH_CI_PROVIDER_REPOS,
  };
}

export function createNewProjectWithSelectedRepo(provider, projectName, newRepoList) {
  const data = {
    projectName,
    repos: newRepoList,
  };

  return {
    type: CREATE_NEW_PROJECT_WITH_SELECTED_REPO,
    payload: axios.post(CONFIG.apiUrl + `/repos/${provider}`, data, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
