import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
import React from 'react';

export const getPullRequestMergableStateIcons = (state, style) => {
  switch (state) {
    case PULL_REQUEST_MERGABLE_STATE_ENUMS.CLEAN:
      return <i className={`${DOMAIN_ICON.GITHUB.TEST_STATUS.PASSED} ${style}`} />;
    case PULL_REQUEST_MERGABLE_STATE_ENUMS.BLOCKED:
      return <i className={`${DOMAIN_ICON.GITHUB.TEST_STATUS.FAILED} ${style}`} />;
    default:
      return '';
  }
};

export const PULL_REQUEST_MERGABLE_STATE_ENUMS = {
  CLEAN: 'clean',
  BLOCKED: 'blocked',
};

export const getPullRequestMergableStateEnumToText = state => {
  switch (state) {
    case PULL_REQUEST_MERGABLE_STATE_ENUMS.CLEAN:
      return 'Clean';
    case PULL_REQUEST_MERGABLE_STATE_ENUMS.BLOCKED:
      return 'Blocked';
    default:
      return 'Unknown';
  }
};
