import {
  GET_PR_SUMMARY_STATUS_FULFILLED,
  GET_PR_SUMMARY_STATUS_PENDING,
  GET_PR_SUMMARY_STATUS_REJECTED,
  GET_PR_SUMMARY_TEST_RUN_LIST_FULFILLED,
  GET_PR_SUMMARY_TEST_RUN_LIST_PENDING,
  GET_PR_SUMMARY_TEST_RUN_LIST_REJECTED,
  GET_PR_SUMMARY_WORKFLOW_LIST_FULFILLED,
  GET_PR_SUMMARY_WORKFLOW_LIST_PENDING,
  GET_PR_SUMMARY_WORKFLOW_LIST_REJECTED,
} from 'store/constants/pull-request-summary';

const initialState = {
  pullRequestSummaryStatus: [],
  pullRequestSummaryFetching: false,
  pullRequestSummaryFetched: false,
  pullRequestSummaryError: null,

  pullRequestSummaryTestRunList: [],
  pullRequestSummaryTestRunListFetching: false,
  pullRequestSummaryTestRunListFetched: false,
  pullRequestSummaryTestRunListError: null,

  pullRequestSummaryWorkflowList: [],
  pullRequestSummaryWorkflowListFetching: false,
  pullRequestSummaryWorkflowListFetched: false,
  pullRequestSummaryWorkflowListError: null,
};

export default function pullRequestSummary(state = initialState, action) {
  switch (action.type) {
    case GET_PR_SUMMARY_STATUS_PENDING:
      return {
        ...state,
        pullRequestSummaryFetching: true,
        pullRequestSummaryFetched: false,
        pullRequestSummaryError: null,
      };
    case GET_PR_SUMMARY_STATUS_FULFILLED:
      return {
        ...state,
        pullRequestSummaryFetching: false,
        pullRequestSummaryFetched: true,
        pullRequestSummaryError: null,
        pullRequestSummaryStatus: action.payload.data,
      };
    case GET_PR_SUMMARY_STATUS_REJECTED:
      return {
        ...state,
        pullRequestSummaryFetching: false,
        pullRequestSummaryFetched: false,
        pullRequestSummaryError: action.payload,
      };
    /* ****************************************************** */
    case GET_PR_SUMMARY_WORKFLOW_LIST_PENDING:
      return {
        ...state,
        pullRequestSummaryTestRunListFetching: true,
        pullRequestSummaryTestRunListFetched: false,
        error: null,
      };
    case GET_PR_SUMMARY_WORKFLOW_LIST_FULFILLED:
      return {
        ...state,
        pullRequestSummaryTestRunListFetching: false,
        fetched: true,
        error: null,
        pullRequestSummaryWorkflowList: action.payload.data,
      };
    case GET_PR_SUMMARY_WORKFLOW_LIST_REJECTED:
      return { ...state, pullRequestSummaryTestRunListFetching: false, fetched: false, error: action.payload };
    /* ****************************************************** */
    case GET_PR_SUMMARY_TEST_RUN_LIST_PENDING:
      return {
        ...state,
        pullRequestSummaryTestRunListFetching: true,
        pullRequestSummaryTestRunListFetched: false,
        pullRequestSummaryTestRunListError: null,
      };
    case GET_PR_SUMMARY_TEST_RUN_LIST_FULFILLED:
      return {
        ...state,
        pullRequestSummaryTestRunListFetching: false,
        pullRequestSummaryTestRunListFetched: true,
        pullRequestSummaryTestRunListError: null,
        pullRequestSummaryTestRunList: action.payload.data,
      };
    case GET_PR_SUMMARY_TEST_RUN_LIST_REJECTED:
      return {
        ...state,
        pullRequestSummaryTestRunListFetching: false,
        pullRequestSummaryTestRunListFetched: false,
        pullRequestSummaryTestRunListError: action.payload,
      };

    default:
      return state;
  }
}
