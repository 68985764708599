import React from 'react';
import { Radio } from 'semantic-ui-react';
import './RadioButton.scss';

export const ForesightToggle = ({ enabled, ...props }) => {
  return (
    <div className="foresight-toggle-container">
      <Radio
        fitted
        toggle
        className={enabled ? 'radio-button-loading-before' : 'radio-button-loading-after'}
        {...props}
      />
    </div>
  );
};
