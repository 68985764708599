export const GET_MY_ORGANIZATION_USER_LIST = 'GET_MY_ORGANIZATION_USER_LIST';
export const GET_MY_ORGANIZATION_USER_LIST_PENDING = 'GET_MY_ORGANIZATION_USER_LIST_PENDING';
export const GET_MY_ORGANIZATION_USER_LIST_FULFILLED = 'GET_MY_ORGANIZATION_USER_LIST_FULFILLED';
export const GET_MY_ORGANIZATION_USER_LIST_REJECTED = 'GET_MY_ORGANIZATION_USER_LIST_REJECTED';
export const GET_USER_ALL_ORGANIZATION_LIST = 'GET_USER_ALL_ORGANIZATION_LIST';
export const GET_USER_ALL_ORGANIZATION_LIST_PENDING = 'GET_USER_ALL_ORGANIZATION_LIST_PENDING';
export const GET_USER_ALL_ORGANIZATION_LIST_FULFILLED = 'GET_USER_ALL_ORGANIZATION_LIST_FULFILLED';
export const GET_USER_ALL_ORGANIZATION_LIST_REJECTED = 'GET_USER_ALL_ORGANIZATION_LIST_REJECTED';
export const GET_USER_ACTIVE_ORGANIZATION_LIST = 'GET_USER_ACTIVE_ORGANIZATION_LIST';
export const GET_USER_ACTIVE_ORGANIZATION_LIST_PENDING = 'GET_USER_ACTIVE_ORGANIZATION_LIST_PENDING';
export const GET_USER_ACTIVE_ORGANIZATION_LIST_FULFILLED = 'GET_USER_ACTIVE_ORGANIZATION_LIST_FULFILLED';
export const GET_USER_ACTIVE_ORGANIZATION_LIST_REJECTED = 'GET_USER_ACTIVE_ORGANIZATION_LIST_REJECTED';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_PENDING = 'UPDATE_ORGANIZATION_PENDING';
export const UPDATE_ORGANIZATION_FULFILLED = 'UPDATE_ORGANIZATION_FULFILLED';
export const UPDATE_ORGANIZATION_REJECTED = 'UPDATE_ORGANIZATION_REJECTED';
export const SWITCH_ORGANIZATION = 'SWITCH_ORGANIZATION';
export const SWITCH_ORGANIZATION_PENDING = 'SWITCH_ORGANIZATION_PENDING';
export const SWITCH_ORGANIZATION_FULFILLED = 'SWITCH_ORGANIZATION_FULFILLED';
export const SWITCH_ORGANIZATION_REJECTED = 'SWITCH_ORGANIZATION_REJECTED';
export const INVITE_USERS_TO_CURRENT_ORGANIZATION = 'INVITE_USERS_TO_CURRENT_ORGANIZATION';
export const INVITE_USERS_TO_CURRENT_ORGANIZATION_PENDING = 'INVITE_USERS_TO_CURRENT_ORGANIZATION_PENDING';
export const INVITE_USERS_TO_CURRENT_ORGANIZATION_FULFILLED = 'INVITE_USERS_TO_CURRENT_ORGANIZATION_FULFILLED';
export const INVITE_USERS_TO_CURRENT_ORGANIZATION_REJECTED = 'INVITE_USERS_TO_CURRENT_ORGANIZATION_REJECTED';
export const CHANGE_USER_ROLE_IN_ORGANIZATION = 'CHANGE_USER_ROLE_IN_ORGANIZATION';
export const CHANGE_USER_ROLE_IN_ORGANIZATION_PENDING = 'CHANGE_USER_ROLE_IN_ORGANIZATION_PENDING';
export const CHANGE_USER_ROLE_IN_ORGANIZATION_FULFILLED = 'CHANGE_USER_ROLE_IN_ORGANIZATION_FULFILLED';
export const CHANGE_USER_ROLE_IN_ORGANIZATION_REJECTED = 'CHANGE_USER_ROLE_IN_ORGANIZATION_REJECTED';
export const REMOVE_USER_FROM_ORGANIZATION = 'REMOVE_USER_FROM_ORGANIZATION';
export const REMOVE_USER_FROM_ORGANIZATION_PENDING = 'REMOVE_USER_FROM_ORGANIZATION_PENDING';
export const REMOVE_USER_FROM_ORGANIZATION_FULFILLED = 'REMOVE_USER_FROM_ORGANIZATION_FULFILLED';
export const REMOVE_USER_FROM_ORGANIZATION_REJECTED = 'REMOVE_USER_FROM_ORGANIZATION_REJECTED';
export const LEAVE_FROM_ORGANIZATION = 'LEAVE_FROM_ORGANIZATION';
export const LEAVE_FROM_ORGANIZATION_PENDING = 'LEAVE_FROM_ORGANIZATION_PENDING';
export const LEAVE_FROM_ORGANIZATION_FULFILLED = 'LEAVE_FROM_ORGANIZATION_FULFILLED';
export const LEAVE_FROM_ORGANIZATION_REJECTED = 'LEAVE_FROM_ORGANIZATION_REJECTED';
