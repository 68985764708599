import React, { Component } from 'react';
import { LoadingDimmer, renderNoDataAvailableForWorkflow } from 'components/ComponentPlaceholder';
import './WorkflowsPage.scss';
import { MainLayoutContainer } from 'containers';
import routeList, { computePath } from 'routes';
import { WorkflowsFiltersSidePanel } from './WorkflowsFilters/WorkflowsFiltersSidePanel';
import { PageHeader, PageHeaderTitle, PageHeaderTitleContainer } from 'custom-components/PageHeader/PageHeader';
import { GrayBorderButton } from 'custom-components/Button/Button';
import { SearchInput } from 'custom-components/SearchInput/SearchInput';
import { Calendar } from 'custom-components/Calendar/Calendar';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { Icon, Popup } from 'semantic-ui-react';
import { WorkflowSummaryIcon } from 'components';
import { millisToMAndS } from 'utils/date-util';
import { Tooltip } from 'components';
import { ALink } from 'custom-components/ALink/ALink';
import { FilterLabelGroup } from 'custom-components/FilterLabelGroup/FilterLabelGroup';
import { formatDateWithTime, calcDisplayedTime } from 'utils/date-util';
import {
  ListCardsContainer,
  ListCardsCellContainer,
  ListCardsHeader,
  ListCardsBody,
  ListCardsRowBody,
} from 'custom-components/ListCards/ListCards';
import { FilterLabel } from 'custom-components/FilterLabel/FilterLabel';
import {
  DualHorizontalItemsContainer,
  DualHorizontalItemsLeftPart,
  DualHorizontalItemsRightPart,
} from 'custom-components/DualHorizontalItems/DualHorizontalItems';
import { getWorkflowStatusContentText } from 'utils/workflowrun-conclusion-status-tooltips';
import { WORKFLOWRUN_STATUS } from 'utils/workflowrun-status';
import { getConclusionToReadableMapping } from 'utils/workflowrun-conclusion-status-tooltips';
import { WORKFLOWRUN_CONCLUSION } from 'utils/workflowrun-conclusion-status';
import { isEqualDeep } from 'utils/common-util';
import { dateCalculatedBefore, dateCalculatedToday, checkIfConclusionIsOtherType } from 'utils/workflow-view-filters';
import { hasArrayElement } from 'utils/array-util';
import ConnectRepositoryWidget from 'components/HighlightsPage/HighlightWidgets/ConnectRepositoryWidget/ConnectRepositoryWidget';

import { isUserInAdminRole } from 'utils/user-role-util.js';
import { DOMAIN_ICON, UI_ICON } from 'assets/font-icons/IconManager';
class WorkflowsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdvancedFilterOpen: false,
      filterText: '',
      filtersObject: {
        repos: [],
        startDate: Date.parse(dateCalculatedBefore()),
        endDate: Date.parse(dateCalculatedToday()),
        statuses: [],
      },
    };
  }

  onWorkflowSearchInputChange = data => {
    this.setState({ filterText: data.value });
  };

  getFlatArray = () => {
    let flattenedArray = [];
    this.state.filtersObject.repos.forEach(repoBranch => {
      repoBranch.branches.forEach(branch => {
        flattenedArray.push(`${repoBranch.fullName}/${branch}`);
      });
    });
    return flattenedArray;
  };

  onGetWorkflowFiltersSuccess = () => {
    const filtersObject = { ...this.state.filtersObject };
    filtersObject.repos = [];
    this.props.workflowViewFilters?.workflowViewFilters?.repoBranches?.forEach(repoBranch => {
      if (repoBranch.branches.some(branchAgg => branchAgg === repoBranch.defaultBranch)) {
        filtersObject.repos.push({
          branches: [repoBranch.defaultBranch],
          repoId: repoBranch.id,
          fullName: repoBranch.fullName,
        });
      }
    });
    this.setState({ filtersObject: filtersObject });
  };

  componentDidMount() {
    this.props.getHasConnectedRepoWatch();
    this.props.getWorkflowFilters(false, this.onGetWorkflowFiltersSuccess);
    this.props.getWorkflowRuns({ ...this.state.filtersObject, filterText: this.state.filterText });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqualDeep(prevState.filtersObject, this.state.filtersObject)) {
      this.props.getWorkflowRuns({ ...this.state.filtersObject, filterText: this.state.filterText });
    }
  }

  renderWorkflowRuns = (workflowRuns, repo) => {
    return workflowRuns.map((workflowRun, index = 0) => {
      let color = workflowRuns.length - 1 == index ? null : '#788496';
      return (
        <Tooltip
          blackEdition
          id="hover-status"
          key={workflowRun.id}
          content={workflowRun && repo && this.renderCiCdHeaderForWorkflow(workflowRun, repo)}
          position="top left"
          hoverable
          pinned
        >
          <div className="workflow">
            <WorkflowSummaryIcon
              styleClass="latest-workflows"
              workflowRun={workflowRun}
              overrideColor={color}
              key={workflowRun.id}
              history={this.props.history}
              ciProvider={repo?.provider?.toLowerCase()}
              repoOwner={repo?.owner}
              repoName={repo?.name}
            />
          </div>
        </Tooltip>
      );
    });
  };

  renderCiCdHeaderForWorkflow = (workflowRun, repo) => {
    return (
      <>
        <div className="status item">
          <DualHorizontalItemsContainer>
            <DualHorizontalItemsLeftPart className="status-icon">
              <WorkflowSummaryIcon
                workflowRun={workflowRun}
                history={this.props.history}
                ciProvider={repo.provider?.toLowerCase()}
                repoOwner={repo.owner}
                repoName={repo.name}
              />
            </DualHorizontalItemsLeftPart>
            <DualHorizontalItemsRightPart>
              <div className="status-text ci-cd-header-texts">
                {getWorkflowStatusContentText(WORKFLOWRUN_STATUS.COMPLETED, workflowRun.conclusion)}
              </div>
            </DualHorizontalItemsRightPart>
          </DualHorizontalItemsContainer>
          <DualHorizontalItemsContainer />
        </div>
        <div className="execution-date item">
          <DualHorizontalItemsContainer>
            <DualHorizontalItemsLeftPart>
              <>
                <Tooltip content="Date">
                  <i className={DOMAIN_ICON.GITHUB.EXEC_DATE + ' tooltip-icons-scale'} />
                </Tooltip>
              </>
            </DualHorizontalItemsLeftPart>
            <DualHorizontalItemsRightPart>
              <div className="exec-time-text ci-cd-header-texts">{formatDateWithTime(workflowRun.createdAt)}</div>
            </DualHorizontalItemsRightPart>
          </DualHorizontalItemsContainer>
        </div>
        <div className="execution-time item">
          <DualHorizontalItemsContainer>
            <DualHorizontalItemsLeftPart>
              <Tooltip content="Execution duration">
                <i className="icon-elapsed-time tooltip-icons-scale" />
              </Tooltip>
            </DualHorizontalItemsLeftPart>
            <DualHorizontalItemsRightPart>
              <div className="exec-time-text ci-cd-header-texts">{calcDisplayedTime(workflowRun.duration)}</div>
            </DualHorizontalItemsRightPart>
          </DualHorizontalItemsContainer>
        </div>
      </>
    );
  };

  handleSearchNameWithListRender = () => {
    this.props.getWorkflowRuns({ ...this.state.filtersObject, filterText: this.state.filterText });
  };

  handleClearWithListRender = () => {
    this.setState({ filterText: '' }, () =>
      this.props.getWorkflowRuns({ ...this.state.filtersObject, filterText: this.state.filterText }),
    );
  };

  renderListContent = data => {
    const filtersObject = { ...this.state.filtersObject };
    const statusFilterExists = hasArrayElement(filtersObject.statuses);
    return data.map(datum => {
      return (
        <>
          <ListCardsRowBody className="workflow-view-list-cards-row-body">
            <ListCardsCellContainer className="cell-container workflow-and-repo-wrapper">
              <div className="workflow-and-repo">
                <div className="workflow-name">{datum.name}</div>
                <div className="repo-section flex-row">
                  {datum.repo && <i className={`${DOMAIN_ICON.GITHUB.REPOSITORY} icon-postion`} />}
                  <div className="repo-text">{datum.repo?.name}</div>
                </div>
              </div>
            </ListCardsCellContainer>

            <ListCardsCellContainer className="cell-container exec-count-wrapper">
              <div className="common-text-style">{datum.executionCount}</div>
            </ListCardsCellContainer>
            <ListCardsCellContainer className="cell-container average-duration-wrapper">
              <div className="common-text-style">{millisToMAndS(datum.averageDuration)}</div>
            </ListCardsCellContainer>
            <ListCardsCellContainer className="cell-container stats-wrapper">
              <div className="flex-row">
                {statusFilterExists && filtersObject.statuses.includes(WORKFLOWRUN_CONCLUSION.FAILURE) && (
                  <div className="stats-cell flex-row">
                    <i className={DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS + ' stats-icons'} />
                    <div className="common-text-style">{datum.failCount}</div>
                  </div>
                )}
                {!statusFilterExists && (
                  <div className="stats-cell flex-row">
                    <i className={DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.TIMEOUT + ' stats-icons'} />
                    <div className="common-text-style">{datum.failCount}</div>
                  </div>
                )}

                {statusFilterExists && filtersObject.statuses.includes(WORKFLOWRUN_CONCLUSION.SUCCESS) && (
                  <div className="stats-cell flex-row">
                    <i className={DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.SUCCESS + ' stats-icons inter-element'} />
                    <div className="common-text-style">{datum.successCount}</div>
                  </div>
                )}
                {!statusFilterExists && (
                  <div className="stats-cell flex-row">
                    <i className={DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.SUCCESS + ' stats-icons inter-element'} />
                    <div className="common-text-style">{datum.successCount}</div>
                  </div>
                )}

                {statusFilterExists && checkIfConclusionIsOtherType(this.state.filtersObject.statuses) && (
                  <div className="stats-cell flex-row">
                    <i className={UI_ICON.MORE.HORIZONTAL_IN_CIRCLE + ' stats-icons inter-element'} />
                    <div className="common-text-style">{datum.otherCount}</div>
                  </div>
                )}
                {!statusFilterExists && (
                  <div className="stats-cell flex-row">
                    <i className={UI_ICON.MORE.HORIZONTAL_IN_CIRCLE + ' stats-icons inter-element'} />
                    <div className="common-text-style">{datum.otherCount}</div>
                  </div>
                )}
              </div>
            </ListCardsCellContainer>
            <ListCardsCellContainer className="cell-container last-runs-wrapper">
              <div className="flex-row">{this.renderWorkflowRuns(datum.workflowRuns, datum.repo)}</div>
            </ListCardsCellContainer>
            <ListCardsCellContainer className="cell-container average-duration-wrapper">
              <Popup
                on="click"
                content={this.renderMoreIconTooltip(datum.id, datum.repo)}
                basic
                hideOnScroll={true}
                position="bottom left"
                id="organization-dropdown-parent"
                trigger={
                  <div
                    className="more-icon-wrapper"
                    onClick={event => {
                      event.stopPropagation();
                    }}
                  >
                    <i className="icon-more-vertical"></i>
                  </div>
                }
              ></Popup>
            </ListCardsCellContainer>
          </ListCardsRowBody>
        </>
      );
    });
  };

  renderMoreIconTooltip = (id, repo) => {
    return (
      <div className="organization-action-dropdown">
        <div
          className="item item-first"
          onClick={() => {
            window.open(
              computePath(routeList.repositoryWorkflowRuns.path, {
                ciProvider: repo.provider.toLowerCase(),
                repoOwner: repo.owner,
                repoName: repo.name,
                tabName: 'workflow-runs',
              }) +
                '#' +
                id,
            );
          }}
        >
          See workflow runs
        </div>
        <div className="item-delimiter" />
        <div
          className="item item-last"
          onClick={() =>
            window.open(
              computePath(routeList.singleWorkflowHighlights.path, {
                ciProvider: repo.provider.toLowerCase(),
                repoOwner: repo.owner,
                repoName: repo.name,
                workflowId: id,
              }),
            )
          }
        >
          See highlights
        </div>
      </div>
    );
  };

  renderPageContent = () => {
    const { fetched, fetching, workflowView, error } = this.props.workflowView;
    const {
      hasConnectedRepoWacthed: { hasWatchedRepoFetching },
    } = this.props;

    if (hasArrayElement(workflowView)) {
      return (
        <ListCardsContainer className="list-cards-wf-main-container-parent" style={{ width: '100%' }}>
          <ListCardsHeader
            columnsObjectsArray={[
              {
                text: 'Workflow',
                style: {
                  width: '55%',
                },
              },
              { text: '# of exec.' },
              {
                text: 'Avg. Duration',
              },
              {
                text: 'WF Performance',
              },
              {
                text: 'Latest 5 runs',
              },
            ]}
            className="pr-list-header"
          ></ListCardsHeader>
          <ListCardsBody
            fetched={fetched}
            fetching={fetching || hasWatchedRepoFetching}
            error={error}
            emptyContent={renderNoDataAvailableForWorkflow()}
            colSpan={5}
            tableContent={workflowView}
          >
            {this.renderListContent(workflowView)}
          </ListCardsBody>
        </ListCardsContainer>
      );
    } else {
      return <LoadingDimmer msg={'Unknown State'} />;
    }
  };

  rangeChangeHandle = ({ value }) => {
    const filtersObject = { ...this.state.filtersObject };

    // If two date ranges selected properly, then
    // set filters object date ranges. If they are the same day
    // Then startDate would be the first millisecond and
    // endDate would be the last millisecond of that day
    if (value?.length === 2) {
      filtersObject.startDate = Date.parse(value[0]);
      filtersObject.endDate = Date.parse(value[1]);
      const oneDayMinusOneMillisecInEpoch = 86_399_999;
      if (filtersObject.startDate == filtersObject.endDate) {
        filtersObject.endDate = filtersObject.endDate + oneDayMinusOneMillisecInEpoch;
      }
      // If dates had cleared, we also clear our date ranges
    } else if (Object.is(value, null)) {
      filtersObject.startDate = null;
      filtersObject.endDate = null;
    }

    // Then if the range is valid like two different dates
    // Or norhing at all, update the filters object
    if (value?.length === 2 || value === null) {
      this.setState({ filtersObject: filtersObject });
    }
  };

  handleFilterChange = filters => {
    const filtersObject = { ...this.state.filtersObject };
    filtersObject.repos = [];
    filtersObject.statuses = [];
    filters?.allPossibleBranchFilters?.map(branchObj => {
      if (branchObj.branches.some(branch => branch.checked === true)) {
        filtersObject.repos.push({
          fullName: branchObj.fullName,
          branches: branchObj.branches.filter(branch => branch.checked === true).map(agg => agg.name),
          repoId: branchObj.id,
        });
      }
    });
    filters?.conclusions?.map(conclusionObj => {
      if (conclusionObj.checked) {
        filtersObject.statuses.push(conclusionObj.name);
      }
    });

    this.setState({ isAdvancedFilterOpen: false, filtersObject: filtersObject });
  };

  clearFilters = () => {
    let filtersObject = { ...this.state.filtersObject };
    filtersObject = {
      repos: [],
      startDate: null,
      endDate: null,
      statuses: [],
    };

    this.setState({ filtersObject: filtersObject });
  };

  getWorkflowFiltersHandle = hideBranch => {
    this.props.getWorkflowFilters(hideBranch);
  };

  render() {
    let { fetched, fetching } = this.props.workflowViewFilters;
    const filtersObject = { ...this.state.filtersObject };
    const statusFilterExists = hasArrayElement(filtersObject.statuses);
    const { userAccount, hasConnectedRepoWacthed, ciConnect } = this.props;

    if (!hasConnectedRepoWacthed) {
      return (
        <MainLayoutContainer>
          <ConnectRepositoryWidget
            ciConnect={ciConnect}
            noProjectIsPresent={!hasConnectedRepoWacthed}
            history={this.props.history}
            userAccount={userAccount}
          />
        </MainLayoutContainer>
      );
    }

    return (
      <MainLayoutContainer title={routeList.workflows.title} className="wf-view-main-container">
        {this.state.isAdvancedFilterOpen && (
          <WorkflowsFiltersSidePanel
            filtersObject={this.state.filtersObject}
            workflowViewFilters={this.props.workflowViewFilters}
            isOpen={this.state.isAdvancedFilterOpen}
            applyFilter={this.handleFilterChange}
            isClearFilter={true}
            closeDetailModal={() => this.setState({ isAdvancedFilterOpen: false })}
            getWorkflowFilters={this.getWorkflowFiltersHandle}
          />
        )}
        <div className="org-name">{userAccount.userAccount.organization?.organizationName}</div>
        <PageHeader style={{ paddingTop: 14, paddingBottom: 16 }}>
          <PageHeaderTitleContainer>
            <PageHeaderTitle>Workflows</PageHeaderTitle>

            <div className="filter-group">
              {fetched && (
                <>
                  {(hasArrayElement(filtersObject.repos) || statusFilterExists) && (
                    <FilterLabelGroup clearFilterHandle={this.clearFilters}>
                      {hasArrayElement(filtersObject.repos) && hasArrayElement(this.getFlatArray()) && (
                        <FilterLabel
                          filterArray={this.getFlatArray()}
                          labelTitle="Branches"
                          filterLabelClick={() => this.setState({ isAdvancedFilterOpen: true })}
                          fitContent
                        />
                      )}
                      {statusFilterExists && (
                        <FilterLabel
                          filterArray={filtersObject.statuses}
                          labelTitle="Statuses"
                          filterLabelClick={() => this.setState({ isAdvancedFilterOpen: true })}
                          textMapperFunc={getConclusionToReadableMapping}
                        />
                      )}
                    </FilterLabelGroup>
                  )}
                  <GrayBorderButton
                    primary
                    id="workflows-filter-btn"
                    onClick={() => {
                      this.setState({ isAdvancedFilterOpen: true });
                    }}
                  >
                    <Icon className="icon-filter-icon" />
                    Advanced Filter
                  </GrayBorderButton>
                </>
              )}
              {fetching && <LoadingDimmer id="test" msg={'Loading...'} style={{ width: 20, height: 20 }} />}
              {isUserInAdminRole(userAccount.userAccount.role) && (
                <ALink
                  href={computePath(routeList.teamOrganizationWithTab.path, { tabName: 'workflows' })}
                  id="workflow-settings-link"
                >
                  <div className="settings-icon-wrapper">
                    <i className={UI_ICON.SETTINGS.LIGHT + ' scale'}></i>
                  </div>
                </ALink>
              )}
            </div>
          </PageHeaderTitleContainer>
        </PageHeader>
        <div className="workflow-search-section-wrapper">
          <div className="workflow-search-section">
            <SearchInput
              searchValue={this.state.filterText}
              onChangeInputText={this.onWorkflowSearchInputChange}
              inputPlaceholder={'Search for a workflow'}
              onClickSearchButton={this.handleSearchNameWithListRender}
              onKeyPress={this.handleSearchNameWithListRender}
              clearSearchInput={this.handleClearWithListRender}
              buttonLoading={false}
            ></SearchInput>
          </div>

          <div>
            <Calendar
              onChange={(event, data) => this.rangeChangeHandle(data)}
              type="range"
              pointing="right"
              format="DD/MM/YYYY"
              value={
                this.state.filtersObject.startDate == null && this.state.filtersObject.endDate == null
                  ? null
                  : [new Date(this.state.filtersObject.startDate), new Date(this.state.filtersObject.endDate)]
              }
            />
          </div>
        </div>
        {this.renderPageContent()}
      </MainLayoutContainer>
    );
  }
}

export default WorkflowsPage;
