import { GET_SOURCE_COVERAGE } from 'store/constants/source-coverage';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

export function getSourceCoverage(workflowRunId, fullFilePath, jobId) {
  return {
    meta: { workflowRunId, fullFilePath, jobId },
    type: GET_SOURCE_COVERAGE,
    payload: axios.post(
      CONFIG.apiUrl + '/workflow-runs/coverage-result/details',
      { workflowRunId: workflowRunId, jobId: jobId, filename: fullFilePath },
      { headers: { Authorization: 'Bearer ' + auth.getAccessToken() } },
    ),
  };
}
