import {
  GET_TEST_VIEW_BRANCH_FILTER_PENDING,
  GET_TEST_VIEW_BRANCH_FILTER_FULFILLED,
  GET_TEST_VIEW_BRANCH_FILTER_REJECTED,
  GET_TEST_VIEW_STATUS_FILTER_PENDING,
  GET_TEST_VIEW_STATUS_FILTER_FULFILLED,
  GET_TEST_VIEW_STATUS_FILTER_REJECTED,
} from 'store/constants/test-view-filters';

const initialState = {
  testViewBranches: [],
  fetchingBranchFilters: false,
  fetchedBranchFilters: false,
  errorBranchFilters: null,
  testViewStatuses: [],
  fetchingStatusFilters: false,
  fetchedStatusFilters: false,
  errorStatusFilters: null,
};

export default function testViewFilters(state = initialState, action) {
  switch (action.type) {
    case GET_TEST_VIEW_BRANCH_FILTER_PENDING:
      return { ...state, fetchingBranchFilters: true, fetchedBranchFilters: false, errorBranchFilters: null };
    case GET_TEST_VIEW_BRANCH_FILTER_FULFILLED:
      return {
        ...state,
        fetchingBranchFilters: false,
        fetchedBranchFilters: true,
        errorBranchFilters: null,
        testViewBranches: action.payload.data,
      };
    case GET_TEST_VIEW_BRANCH_FILTER_REJECTED:
      return {
        ...state,
        fetchingBranchFilters: false,
        fetched: false,
        errorBranchFilters: action.payload,
      };

    case GET_TEST_VIEW_STATUS_FILTER_PENDING:
      return { ...state, fetchingStatusFilters: true, fetchedStatusFilters: false, errorStatusFilters: null };
    case GET_TEST_VIEW_STATUS_FILTER_FULFILLED:
      return {
        ...state,
        fetchingStatusFilters: false,
        fetchedStatusFilters: true,
        errorStatusFilters: null,
        testViewStatuses: action.payload.data,
      };
    case GET_TEST_VIEW_STATUS_FILTER_REJECTED:
      return {
        ...state,
        fetchingStatusFilters: false,
        fetchedStatusFilters: false,
        errorStatusFilters: action.payload,
      };

    default:
      return state;
  }
}
