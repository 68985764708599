import {
	CREATE_CI_PROVIDER_INTEGRATION,
	GET_CI_PROVIDER_INTEGRATIONS,
	DELETE_CI_PROVIDER_INTEGRATION
} from 'store/constants/ci-provider-connect';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

export function createCIProviderIntegration(integrationId, provider) {
	return {
		type: CREATE_CI_PROVIDER_INTEGRATION,
		payload: axios.post(
			CONFIG.apiUrl + `/ci-providers/${provider}/integration`,
			{ integrationId },
			{
				headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
			},
		),
	};
}

export function getCIProviderIntegrations() {
	return {
		type: GET_CI_PROVIDER_INTEGRATIONS,
		payload: axios.get(CONFIG.apiUrl + '/ci-providers/integrations', {
			headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
		}),
	};
}

export function deleteCIProviderIntegration(provider) {
	return {
		type: DELETE_CI_PROVIDER_INTEGRATION,
		payload: axios.delete(CONFIG.apiUrl + `/ci-providers/${provider}/integration`, {
			headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
		}),
	};
}
