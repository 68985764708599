import moment from 'moment';
import React, { Component } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { hasArrayElement } from 'utils/array-util';
import { computeDateFormatFromInterval, roundData } from 'utils/computeStartDateFromInterval';
import { ENUM_FORMAT_DATE } from 'utils/date-util';
import { toFixed } from 'utils/math';
import './LineChartViewer.scss';
export class LineChartViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: new Array(this.props.dataProps.length).fill(0.8),
    };
  }

  handleMouseEnter = e => {
    const { selectedLineName } = this.props;
    const newOpacity = {};
    this.props.dataProps.forEach(type => {
      newOpacity[type.dataKey] = e.name === type.dataKey ? 0.8 : 0.1;
    });
    this.props.yAxisProps.domain = [];

    this.setState({
      opacity: newOpacity,
    });
    if (selectedLineName) {
      selectedLineName(e.name);
    }
  };

  handleMouseLeave = () => {
    const { selectedLineName } = this.props;
    const newOpacity = {};
    this.props.dataProps.forEach(type => {
      newOpacity[type.dataKey] = 0.8;
    });
    this.props.yAxisProps.domain = [];

    this.setState({
      opacity: newOpacity,
    });
    if (selectedLineName) {
      selectedLineName(null);
    }
  };

  handleSentListName = val => {
    const newOpacity = {};
    if (val) {
      this.props.dataProps.forEach(type => {
        newOpacity[type.dataKey] = val === type.dataKey ? 0.8 : 0.1;
      });
      this.setState({
        opacity: newOpacity,
      });
    } else {
      this.props.dataProps.forEach(type => {
        newOpacity[type.dataKey] = 0.8;
      });
      this.setState({
        opacity: newOpacity,
      });
    }
  };

  renderLines = () => {
    return this.props.dataProps.map(prop => {
      return (
        <Line
          strokeWidth={3}
          connectNulls={true}
          dot={false}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          key={prop.dataKey}
          dataKey={prop.dataKey}
          name={prop.name}
          stroke={prop.color}
          fillOpacity={this.state.opacity[prop.dataKey]}
          strokeOpacity={this.state.opacity[prop.dataKey]}
          /* We create a gradient fill for the line chart. */
          fill={'url(#' + prop.dataKey.replace(/\s+/g, '') + ')'}
          animationDuration={800}
          animationEasing={'ease-in-out'}
        />
      );
    });
  };

  convertChartDataName = dataKey => {
    /* Legend and Tooltip Text are replacing the camel case with space. */
    let newEntryName = dataKey.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    newEntryName = newEntryName.charAt(0).toUpperCase() + newEntryName.slice(1);
    return newEntryName;
  };

  renderLegend = props => {
    const { payload } = props;

    return (
      <div className="line-chart-legend-container">
        {payload.map((entry, index) => {
          let newLegendName = this.convertChartDataName(entry.value);
          return (
            <div
              key={'item-' + index}
              name={entry.value}
              className="legend"
              onMouseEnter={() => this.handleMouseEnter({ name: entry.value })}
              onMouseLeave={() => this.handleMouseLeave({ name: entry.value })}
            >
              <div className="legend-icon" style={{ backgroundColor: entry.color }} />
              <div className="legend-title">{newLegendName}</div>
            </div>
          );
        })}
      </div>
    );
  };

  renderTooltip = props => {
    const { payload } = props;

    let listItemsDOM = '';
    if (hasArrayElement(payload)) {
      listItemsDOM = payload
        .filter(entry => entry.value != 0)
        .map((entry, index) => {
          let legendValue = toFixed(entry.value, 0);
          if (this.props.legendValueFormatter) {
            legendValue = this.props.legendValueFormatter(entry);
          }
          return (
            <li key={'item-' + index}>
              <div className="li-item" style={{ maxWidth: '300px' }}>
                <div className="li-item-left">
                  <div className="legend-icon" style={{ backgroundColor: entry.color }} />
                </div>
                <div className="li-item-right">
                  <div className="legend-name">{entry.name}</div>
                  <div className="legend-value">{legendValue}</div>
                </div>
              </div>
            </li>
          );
        });
    }

    return (
      <div className="highlight-line-chart-tooltip-container" style={{ height: 'auto' }}>
        {hasArrayElement(payload) && (
          <div className="tooltip-header">
            {this.props.useOwnXTimeFormatter
              ? this.props.xAxisProps.tickFormatter(payload[0].payload.time)
              : this.xAxisTimeFormatter(payload[0].payload.time)}
          </div>
        )}
        <ul>{listItemsDOM}</ul>
      </div>
    );
  };

  xAxisTimeFormatter = val => {
    const { data } = this.props;
    const formattedData = roundData(data, 2);
    const times = formattedData.map(el => el.time).sort();
    const startDate = moment(times[0], ENUM_FORMAT_DATE.allTime);
    const endDate = moment(times[times.length - 1], ENUM_FORMAT_DATE.allTime);

    const dateFormatterString = moment(val, ENUM_FORMAT_DATE.allTime)
      .local()
      .format(computeDateFormatFromInterval(startDate, endDate));
    return dateFormatterString;
  };

  componentDidUpdate(prevProps) {
    const { sentListName } = this.props;
    if (prevProps.sentListName !== this.props.sentListName) {
      {
        this.handleSentListName(sentListName);
      }
    }
  }

  render() {
    const {
      data,
      size,
      xAxisProps,
      yAxisProps,
      onClick,
      margin,
      refLineValue,
      refLineLabel,
      hasLegend,
      chartDomain,
      yAxisPropsUseInt = false,
      useOwnXTimeFormatter = false,
    } = this.props;
    const styleObj = { width: '100%', height: size.height };

    if (hasArrayElement(data)) {
      const formattedData = roundData(data, 2);

      return (
        <div style={styleObj}>
          <ResponsiveContainer>
            <LineChart margin={margin} width={size.width} height={size.height} data={formattedData} onClick={onClick}>
              <XAxis
                axisLine={false}
                dataKey={xAxisProps.dataKey}
                tickFormatter={useOwnXTimeFormatter ? xAxisProps.tickFormatter : this.xAxisTimeFormatter}
              />
              <YAxis
                axisLine={false}
                domain={chartDomain || yAxisProps.domain}
                tickFormatter={yAxisPropsUseInt ? yAxisProps.tickAreaFormatter : yAxisProps.tickFormatter}
                width={65}
              />
              {refLineValue && (
                <ReferenceLine y={refLineValue} label={refLineLabel} stroke="#788496" strokeDasharray="3 3" />
              )}
              <CartesianGrid horizontal={true} vertical={false} stroke="#2A3B50" />
              <Tooltip
                content={this.renderTooltip}
                wrapperStyle={{ zIndex: 1 }}
                allowEscapeViewBox={{ x: false, y: true }}
                position={{ x: 'auto', y: -155 }}
                offset={50}
              />
              {hasLegend && <Legend verticalAlign={'top'} iconType="square" content={this.renderLegend} />}
              {this.renderLines()}
            </LineChart>
          </ResponsiveContainer>
        </div>
      );
    }

    return <div>Data Not Applicable</div>;
  }
}
