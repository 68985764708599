export const GET_ORGANIZATION_LABELS = 'GET_ORGANIZATION_LABELS';
export const GET_ORGANIZATION_LABELS_PENDING = 'GET_ORGANIZATION_LABELS_PENDING';
export const GET_ORGANIZATION_LABELS_FULFILLED = 'GET_ORGANIZATION_LABELS_FULFILLED';
export const GET_ORGANIZATION_LABELS_REJECTED = 'GET_ORGANIZATION_LABELS_REJECTED';

export const GET_ORGANIZATION_WORKFLOW_LABELS = 'GET_ORGANIZATION_WORKFLOW_LABELS';
export const GET_ORGANIZATION_WORKFLOW_LABELS_PENDING = 'GET_ORGANIZATION_WORKFLOW_LABELS_PENDING';
export const GET_ORGANIZATION_WORKFLOW_LABELS_FULFILLED = 'GET_ORGANIZATION_WORKFLOW_LABELS_FULFILLED';
export const GET_ORGANIZATION_WORKFLOW_LABELS_REJECTED = 'GET_ORGANIZATION_WORKFLOW_LABELS_REJECTED';

export const CREATE_ORGANIZATION_WORKFLOW_LABELS = 'CREATE_ORGANIZATION_WORKFLOW_LABELS';
export const CREATE_ORGANIZATION_WORKFLOW_LABELS_PENDING = 'CREATE_ORGANIZATION_WORKFLOW_LABELS_PENDING';
export const CREATE_ORGANIZATION_WORKFLOW_LABELS_FULFILLED = 'CREATE_ORGANIZATION_WORKFLOW_LABELS_FULFILLED';
export const CREATE_ORGANIZATION_WORKFLOW_LABELS_REJECTED = 'CREATE_ORGANIZATION_WORKFLOW_LABELS_REJECTED';

export const UPDATE_ORGANIZATION_WORKFLOW_LABELS = 'UPDATE_ORGANIZATION_WORKFLOW_LABELS';
export const UPDATE_ORGANIZATION_WORKFLOW_LABELS_PENDING = 'UPDATE_ORGANIZATION_WORKFLOW_LABELS_PENDING';
export const UPDATE_ORGANIZATION_WORKFLOW_LABELS_FULFILLED = 'UPDATE_ORGANIZATION_WORKFLOW_LABELS_FULFILLED';
export const UPDATE_ORGANIZATION_WORKFLOW_LABELS_REJECTED = 'UPDATE_ORGANIZATION_WORKFLOW_LABELS_REJECTED';

export const ATTACH_ORGANIZATION_WORKFLOW_LABELS = 'ATTACH_ORGANIZATION_WORKFLOW_LABELS';
export const ATTACH_ORGANIZATION_WORKFLOW_LABELS_PENDING = 'ATTACH_ORGANIZATION_WORKFLOW_LABELS_PENDING';
export const ATTACH_ORGANIZATION_WORKFLOW_LABELS_FULFILLED = 'ATTACH_ORGANIZATION_WORKFLOW_LABELS_FULFILLED';
export const ATTACH_ORGANIZATION_WORKFLOW_LABELS_REJECTED = 'ATTACH_ORGANIZATION_WORKFLOW_LABELS_REJECTED';

export const DETACH_ORGANIZATION_WORKFLOW_LABELS = 'DETACH_ORGANIZATION_WORKFLOW_LABELS';
export const DETACH_ORGANIZATION_WORKFLOW_LABELS_PENDING = 'DETACH_ORGANIZATION_WORKFLOW_LABELS_PENDING';
export const DETACH_ORGANIZATION_WORKFLOW_LABELS_FULFILLED = 'DETACH_ORGANIZATION_WORKFLOW_LABELS_FULFILLED';
export const DETACH_ORGANIZATION_WORKFLOW_LABELS_REJECTED = 'DETACH_ORGANIZATION_WORKFLOW_LABELS_REJECTED';
