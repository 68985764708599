import { CHANGE_THEME_LIGHT, CHANGE_THEME_DARK } from 'store/constants/theme';

export function changeToLightTheme() {
	return {
		type: CHANGE_THEME_LIGHT,
		payload: {},
	};
}

export function changeToDarkTheme() {
	return {
		type: CHANGE_THEME_DARK,
		payload: {},
	};
}
