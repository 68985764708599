import { UI_ICON } from 'assets/font-icons/IconManager';
import { LoadingDimmer } from 'components/ComponentPlaceholder';
import { ForesightCheckbox } from 'custom-components/Checkbox/Checkbox';
import ForesightCheckboxList from 'custom-components/CheckboxList/CheckboxList';
import { ForesightDropdown } from 'custom-components/Dropdown/Dropdown';
import React from 'react';
import { Segment, TransitionablePortal, Button } from 'semantic-ui-react';
import './HighlightFiltersSidePanel.scss';

/* Setting the initial state of the component. */
const allObject = { key: 'all', value: 'all', text: 'All' };

const initialState = {
  project: { id: 'all', value: 'all' },
  repository: { id: 'all', value: 'all' },
  branch: [],
  hideForkBranch: false,
  // checkSaveFilter: false, //TODO: phase two feature
  filterData: {},
};

const mobileStyle = {
  margin: '0',
  padding: '0',
  width: '%50',
  height: '100vh',
  zIndex: 1000,
  right: '0',
  position: 'fixed',
  top: '0%',
  backgroundColor: '#252B1F',
};

const webStyle = {
  margin: '0',
  padding: '0',
  width: '30%',
  height: '100vh',
  zIndex: 1000,
  left: '70%',
  position: 'fixed',
  top: '0%',
  backgroundColor: '#252B1F',
};

export class HighlightFiltersSidePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  /**
   * This function is called when the component is mounted. It calls the getHighlightFilterProjects()
   * and getHighlightFilterRepositories() functions from the props
   */
  componentDidMount() {
    this.props.getHighlightFilterProjects();
    this.props.getHighlightFilterRepositories('all');
  }

  /* Setting the state of the repository and branch to null. */
  handleProject = (event, data) => {
    const text = event?.target?.textContent;
    this.setState(prevState => {
      return {
        ...prevState,
        project: { id: data.value, text: text },
        repository: { id: 'all', value: 'all', text: 'All' },
        branch: [],
      };
    });
    this.props.getHighlightFilterRepositories(data.value);
  };

  /* Setting the state of the repository and branch to null. */
  handleRepositories = (event, data) => {
    const { project, hideForkBranch } = this.state;
    const dataItem = data.options.find(item => item.value === data.value);
    const findRepoOwner = data.options.find(item => item.text === dataItem.text);
    this.setState(prevState => {
      return {
        ...prevState,
        repository: { id: data.value, text: dataItem.text, repoOwner: findRepoOwner.owner },
        branch: [],
      };
    });
    if (project.id !== 'all') {
      this.props.getHighlightFilterBranches(project.id, `${findRepoOwner.owner}/${findRepoOwner.text}`, hideForkBranch);
    }
  };

  /* Rendering the dropdown for the projects. */
  renderProjects = () => {
    const { project } = this.state;
    const { projects, projectsFetching, projectsError, projectsFetched } = this.props.highlightsFilter;
    const projectsData = projects.map(item => {
      return { key: item.id, value: item.id, text: item.projectName };
    });
    projectsData.unshift(allObject);
    if (projectsFetching) {
      return <LoadingDimmer />;
    } else if (projectsError) {
      return <div>Error</div>;
    } else if (projectsFetched) {
      return (
        <div className="select-projects-container">
          <ForesightDropdown
            name="projects"
            labelText="Projects"
            loading={projectsFetching}
            placeholder="Select Projects"
            value={project.id}
            fluid
            search
            selection
            options={projectsData}
            onChange={(e, data) => this.handleProject(e, data)}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  /* Rendering the repositories dropdown. */
  renderRepositories = () => {
    const { project, repository } = this.state;
    const { repositories, repositoriesFetching, repositoriesError, repositoriesFetched } = this.props.highlightsFilter;

    if (repositoriesError) {
      return <div>Something Went Wrong</div>;
    }
    if (repositoriesFetching) {
      return <LoadingDimmer />;
    } else if (repositoriesFetched) {
      const repositoriesData = repositories.map(item => {
        const repoItem = item?.repo;
        return { key: repoItem.id, value: repoItem.id, text: repoItem.name, owner: repoItem.owner };
      });
      repositoriesData.unshift(allObject);
      return (
        <div className="select-repo-container">
          <label>Repositories</label>
          <ForesightDropdown
            name="repo"
            placeholder="Select Repo"
            fluid
            value={repository.id}
            search
            selection
            disabled={project.id === 'all'}
            options={repositoriesData}
            onChange={(e, data) => this.handleRepositories(e, data)}
          />
        </div>
      );
    }
  };

  /* A function that handles the checkbox click event. */
  handleCheckboxClick = (event, data) => {
    switch (data.checked) {
      case true:
        this.setState(prevState => {
          return {
            ...prevState,
            branch: [...prevState.branch, data.label],
          };
        });
        break;
      case false:
        this.setState(prevState => {
          return {
            ...prevState,
            branch: prevState.branch.filter(item => item !== data.label),
          };
        });
        break;
      default:
        break;
    }
  };

  /* A function that is called when the user clicks on the "Select All" checkbox. */
  handleSelectAllReposToggle = (data, filteredData) => {
    const allBranches = filteredData.map(item => item.branchName);
    switch (data.checked) {
      case true:
        this.setState(prevState => {
          return {
            ...prevState,
            branch: allBranches,
          };
        });
        break;
      case false:
        this.setState(prevState => {
          return {
            ...prevState,
            branch: [],
          };
        });
        break;
      default:
        break;
    }
  };

  getEmptyBranchList = () => {
    return (
      <div style={{ fontSize: 14, borderBottom: '1px solid grey', padding: '5px 10px', borderRadius: '50px' }}>
        Branch Not Found
      </div>
    );
  };

  /* Rendering the branches of a repository. */
  renderBranches = () => {
    const { project, repository, branch, hideForkBranch } = this.state;
    const { branches, branchesFetching, branchesError, branchesFetched } = this.props.highlightsFilter;
    const convertBranchName = branches.map(item => ({ ...item, name: item.branchName }));
    const newBranches =
      branch.length > 0
        ? branches.map(item => {
            if (branch.includes(item.branchName)) {
              return { ...item, name: item.branchName, checked: true };
            } else {
              return { ...item, name: item.branchName };
            }
          })
        : convertBranchName;

    if (repository.id !== 'all' && project.id !== 'all') {
      if (branchesFetching) {
        return (
          <div>
            <LoadingDimmer />
          </div>
        );
      } else if (branchesError) {
        return <div>Error</div>;
      } else if (branchesFetched) {
        return (
          <div className="select-branch-container">
            <label>Branches</label>
            <ForesightCheckbox
              className="highlight-filter-toggle"
              label={'Hide forks'}
              checked={hideForkBranch}
              onChange={(e, data) => this.handleForkBranchToggle(data)}
            />
            <div className="check-box-tree-list-cont">
              <ForesightCheckboxList
                insideList
                data={newBranches}
                loading={false}
                maxHeightList={window.screen.height > 900 ? '400px' : '250px'}
                searchBarPlaceholder="Search Branch"
                parentName={repository.text}
                fetched
                emptyJsx={this.getEmptyBranchList()}
                handleCheckboxOnClick={this.handleCheckboxClick}
                handleSelectAllToggle={this.handleSelectAllReposToggle}
              />
            </div>
          </div>
        );
      } else {
        return null;
      }
    }
  };

  /* A function that is called when the checkbox is toggled. It sets the state of the component to the
opposite of what it was before. */
  handleForkBranchToggle = event => {
    const { project, repository } = this.state;
    this.setState(
      prevState => {
        return {
          ...prevState,
          branch: [],
          hideForkBranch: event.checked,
        };
      },
      () =>
        this.props.getHighlightFilterBranches(project.id, `${repository.repoOwner}/${repository.text}`, event.checked),
    );
  };

  /* Setting the state of the checkSaveFilter to the value of the event.checked. */
  handleSaveFilterCheckbox = event => {
    this.setState(prevState => {
      return {
        ...prevState,
        checkSaveFilter: event.checked,
      };
    });
  };

  /* Setting the state of the filterData object. */
  handleOnChange = data => {
    this.setState(prevState => {
      return {
        ...prevState,
        filterData: { ...prevState.filterData, [data.name]: data.value },
      };
    });
  };

  /**
   * If the isClearFilter prop is different from the previous isClearFilter prop, and the current
   * isClearFilter prop is true, then reset the state to the initial state
   * @param prevProps - The previous props.
   */
  componentDidUpdate(prevProps) {
    if (this.props.isClearFilter !== prevProps.isClearFilter) {
      if (this.props.isClearFilter) {
        this.setState(prevState => {
          return {
            ...prevState,
            ...initialState,
          };
        });
      }
    }
  }

  render() {
    return (
      <>
        {this.props.isOpen && (
          <div
            style={{
              width: '100%',
              height: '100%',
              background: 'rgba(106, 118, 137, 0.6)',
              zIndex: '999',
              top: 0,
              left: 0,
              position: 'absolute',
            }}
          />
        )}

        <TransitionablePortal
          closeOnDocumentClick={false}
          closeOnEscape={false}
          open={this.props.isOpen}
          isClearFilter={this.props.isClearFilter}
          transition={{ animation: 'slide left', duration: 300 }}
        >
          <Segment style={window.screen.width < 480 ? mobileStyle : webStyle}>
            <div className="highlight-filters-container">
              <div className="highlight-filters-title">
                <span className="title-text">Filter your highlights</span>
                <span
                  className="title-icon"
                  onClick={() => {
                    this.props.closeDetailModal();
                  }}
                >
                  <i className={UI_ICON.CROSS.REPO_MODAL_CLOSE} />
                </span>
              </div>
              <div className="highlight-filters-content-container">
                <div className="highlight-filters-content">
                  {this.renderProjects()}
                  {this.renderRepositories()}
                  {this.renderBranches()}
                </div>

                {/*TODO: Phase Two Feature/*}
                 <div className="main-branch-container">
                  <div className="main-branch-toggle-wrapper">
                    <Checkbox
                      className="highlight-filter-toggle"
                      toggle
                      checked={hasMasterBranch}
                      onChange={(e, data) => this.handleMasterBranchToggle(data)}
                    />
                    <div className="highlight-filter-toggle-text">Show only master/main branch</div>
                  </div>
                  {hasMasterBranch && (
                    <div className="toggle-input">
                      <label>
                        Master/main branch name <span>*</span>
                      </label>
                      <Input
                        name="master"
                        value={filterData?.master}
                        onChange={(e, data) => this.handleOnChange(data)}
                        placeholder="Master Branch Name"
                        className="master-branch-input"
                      />
                    </div>
                  )}
                </div>
                <div className="save-filter-container">
                  <div className="save-filter-wrapper">
                    <Checkbox
                      className="highlight-filter-checkbox"
                      checked={checkSaveFilter}
                      onChange={(e, data) => this.handleSaveFilterCheckbox(data)}
                    />
                    <div className="highlight-filter-checkbox-text">Save Filter</div>
                  </div>
                  {checkSaveFilter && (
                    <div className="checkbox-input">
                      <label>
                        Name <span>*</span>
                      </label>
                      <Input
                        name="name"
                        value={filterData?.name}
                        onChange={(e, data) => this.handleOnChange(data)}
                        placeholder="Filter Name"
                        className="save-filter-input"
                      />
                    </div>
                  )}
                </div> */}
              </div>
              <div className="filter-footer-container">
                <hr className="footer-divider"></hr>
                <div className="highlight-filters-actions">
                  <Button
                    secondary
                    onClick={() => {
                      this.props.closeDetailModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    primary
                    onClick={() => {
                      this.props.applyFilter(this.state);
                    }}
                  >
                    Apply Filter
                  </Button>
                </div>
              </div>
            </div>
          </Segment>
        </TransitionablePortal>
      </>
    );
  }
}
