import { StatusPieWidget } from 'components/HighlightsPage/HighlightParts/StatusPieWidget/StatusPieWidget';
import { Tooltip } from 'components/Tooltip';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import './StatusSummary.scss';

const statusInfo = () => {
  return (
    <div className="info-status-code">
      This is the number of total runs including all statuses ( &nbsp;
      <code>completed,</code>
      <code>action_required,</code>
      <code>cancelled,</code>
      <code>failure,</code>
      <code>neutral,</code>
      <code>skipped,</code>
      <code>stale,</code>
      <code>success,</code>
      <code>timed_out,</code>
      <code>in_progress,</code>
      <code>queued,</code>,<code>requested,</code>,<code>waiting,</code> ) in the time period you selected.
    </div>
  );
};

function StatusSummary(props) {
  const { data, title } = props;
  const { totalCount, successCount, failCount, successRate, failRate } = data;

  return (
    <div className="overall-count-of-stats-container">
      <div className="header">
        <div className="title">{title}</div>
      </div>
      <div className="cards-container">
        <div className="highlights-count-of-stats-card">
          <div className="info-part">
            <div className="sub-title">
              Total Runs&nbsp;
              <Tooltip blackEdition content={statusInfo()}>
                <Icon style={{ height: '20px' }} name="info circle" />
              </Tooltip>
            </div>
            <div className="sub-value">{totalCount}</div>
          </div>
          <div className="chart-part"></div>
        </div>
        <div className="highlights-count-of-stats-card">
          <div className="info-part">
            <div className="sub-title">Successful Runs</div>
            <div className="sub-value">{successCount}</div>
          </div>
          <div className="chart-part">
            <StatusPieWidget data={[{ value: successRate }, { value: 100 - successRate }]} />
          </div>
        </div>
        <div className="highlights-count-of-stats-card">
          <div className="info-part">
            <div className="sub-title">Failed Runs</div>
            <div className="sub-value">{failCount}</div>
          </div>
          <div className="chart-part">
            <StatusPieWidget data={[{ value: failRate }, { value: 100 - failRate }]} type={'failed'} />
          </div>
        </div>
      </div>
    </div>
  );
}

const OverallCountStatsOfWorklflows = React.memo(StatusSummary);

export { OverallCountStatsOfWorklflows };
