import { UI_ICON } from 'assets/font-icons/IconManager';
import { Tooltip } from 'components';
import React from 'react';
import './ListCardItems.scss';

export const ListCardFlexRowItem = ({ iconTooltip, icon, desc, descTooltip, secondary }) => {
  const iconJSX = iconTooltip ? (
    <Tooltip blackEdition content={<div>{iconTooltip}</div>}>
      <div className="icon-container">{icon}</div>
    </Tooltip>
  ) : (
    <div className="icon-container">{icon}</div>
  );

  const descClassName = secondary ? 'desc-container secondary' : 'desc-container';
  const descJSX = descTooltip ? (
    <Tooltip blackEdition content={<div>{descTooltip}</div>}>
      <span className={descClassName}>{desc}</span>
    </Tooltip>
  ) : (
    <span className={descClassName}>{desc}</span>
  );

  return (
    <div className="list-card-flex-row-item">
      {iconJSX}
      {descJSX}
    </div>
  );
};

export const ListCardMainTextItem = ({ text }) => {
  return <div className="list-card-main-text-item">{text}</div>;
};

export const ListCardInfoContainer = ({ children }) => {
  return <div className="list-card-main-info-container">{children}</div>;
};

export const ListCardHeaderFilterLabel = ({ text, onCloseClick }) => {
  return (
    <div className="list-card-header-filter-label">
      {text}
      <i onClick={onCloseClick} className={`${UI_ICON.CROSS.REPO_MODAL_CLOSE} icon`} />
    </div>
  );
};
