import {
  GET_TEST_VIEW_CHART_PENDING,
  GET_TEST_VIEW_CHART_FULFILLED,
  GET_TEST_VIEW_CHART_REJECTED,
} from 'store/constants/test-view-chart';

const initialState = {
  testViewChart: [],
  fetching: false,
  fetched: false,
  error: null,
};

export default function testViewChart(state = initialState, action) {
  switch (action.type) {
    case GET_TEST_VIEW_CHART_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_TEST_VIEW_CHART_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        testViewChart: action.payload.data,
      };
    case GET_TEST_VIEW_CHART_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
