import { connect } from 'react-redux';
import { RepositoryWorkflowRunsFilter } from 'components';
import { withRouter } from 'react-router';
import {
  setSelectedPullRequest_WorkflowRuns_WorkflowIds,
  setSelectedPullRequest_WorkflowRuns_Conclusions,
  setSelectedPullRequest_WorkflowRuns_Branches,
  setSelectedPullRequest_WorkflowRuns_Events,
  setSelectedPullRequest_WorkflowRuns_HideForkBranches,
  getPullRequest_WorkflowRuns_WorkflowRunFilters,
} from 'store/actions/pull-request-workflow-runs-filter';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    workflowRunsMeta: store.pullRequestWorkflowRunsMeta,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedWorkflowIds(ids, prId) {
      dispatch(setSelectedPullRequest_WorkflowRuns_WorkflowIds(ids, prId));
    },
    setSelectedConclusions(conclusions, prId) {
      dispatch(setSelectedPullRequest_WorkflowRuns_Conclusions(conclusions, prId));
    },
    setSelectedBranches(branches, prId) {
      dispatch(setSelectedPullRequest_WorkflowRuns_Branches(branches, prId));
    },
    setSelectedEvents(events, prId) {
      dispatch(setSelectedPullRequest_WorkflowRuns_Events(events, prId));
    },
    setSelectedHideFork(hideFork, prId) {
      dispatch(setSelectedPullRequest_WorkflowRuns_HideForkBranches(hideFork, prId));
    },
    getWorkflowRunFilters(ciProvider, repoOwner, repoName, prId, hideForkBranches, onSuccess, onError) {
      dispatchWithExceptionHandler(
        getPullRequest_WorkflowRuns_WorkflowRunFilters(ciProvider, repoOwner, repoName, prId, hideForkBranches),
        false, //  Organization Switch caused Error
        onSuccess,
        onError,
      );
    },
  };
}

const RepositoryPRWorkflowRunsFilterContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepositoryWorkflowRunsFilter),
);
export default RepositoryPRWorkflowRunsFilterContainer;
