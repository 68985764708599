import React, { Component } from 'react';
import { Tooltip } from 'components/Tooltip';
import { hasArrayElement } from 'utils/array-util';
import { LoadingDimmer } from 'components';
import PropTypes from 'prop-types';
import './CheckboxTreeList.scss';
import { debounce } from 'lodash';
import { ForesightCheckbox } from 'custom-components/Checkbox/Checkbox';
import { ForesightInput } from 'custom-components/Input/Input';

export default class CheckboxTreeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newDataList: [],
      copyDataList: [],
      parentChecked: false,
      loading: false,
      filterText: null,
    };
    this.servicesValue = debounce(this.changeSearchState, 700);
  }

  componentDidMount() {
    const { data } = this.props;
    const convertData = data.map(item => {
      item.checked = item.checked || false;
      item.name = item.name || item.repo.name;
      return { ...item };
    });
    const isParentChecked = convertData.every(item => item.checked === true);

    this.setState({ newDataList: convertData, copyDataList: convertData, parentChecked: isParentChecked });
  }

  handleCheckboxClick = (idx, data) => {
    const { newDataList } = this.state;
    const filteredData = newDataList.find((item, index) => index === idx);

    filteredData.checked = !filteredData.checked;
    let allChecked = newDataList.every(element => element.checked === true);

    this.setState(prevState => {
      return {
        ...prevState,
        newDataList: [...prevState.newDataList],
        parentChecked: allChecked ? true : false,
      };
    });

    this.props.handleCheckboxOnClick(idx, data);
  };

  handleInterminate = () => {
    const { newDataList } = this.state;
    const allChecked = newDataList.every(element => element.checked === true);
    if (allChecked) {
      return false;
    } else {
      return newDataList.some(element => element.checked === true);
    }
  };

  /* A function that is called when the parent checkbox is clicked. It is used to check or uncheck all
 the child checkboxes. */
  handleOwnSelectAllReposToggle = data => {
    const { newDataList } = this.state;
    if (!hasArrayElement(newDataList)) {
      return false;
    }
    newDataList.forEach(element => {
      element.checked = data.checked ? true : false;
    });
    this.setState(prevState => {
      return { ...prevState, newDataList: [...prevState.newDataList], parentChecked: data.checked };
    });
    this.props.handleSelectAllReposToggle(data);
  };

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data !== data) {
      const convertData = data.map(item => {
        item.checked = item.checked || false;
        item.name = item.name || item.repo.name;
        return { ...item };
      });
      const isParentChecked = convertData.every(item => item.checked === true);

      this.setState({ newDataList: convertData, copyDataList: convertData, parentChecked: isParentChecked }, () =>
        this.changeSearchState(this.state.filterText),
      );
    }
  }

  /* Checking if the data is loading or not. If it is loading, it will show the
  loading icon. */
  renderBranches = () => {
    const { loading, fetched, iconPopupJsx, ellipsisLength = 35 } = this.props;
    const { newDataList } = this.state;
    if (loading || !fetched) {
      return (
        <div className="loading-container">
          <LoadingDimmer />
        </div>
      );
    }

    const branches = newDataList.map((item, idx) => {
      const childName = item.name;
      const itemLenght = childName.length;
      const hasIcon = item.withIconName || null;
      return (
        <div className="repo-owner-repo-item" key={`${idx}}`}>
          {itemLenght > ellipsisLength ? (
            <Tooltip blackEdition={true} content={childName}>
              <ForesightCheckbox
                checked={item.checked}
                label={childName}
                onChange={(e, data) => this.handleCheckboxClick(idx, data)}
                withIconName={hasIcon || null}
                iconPopupJsx={iconPopupJsx}
              />
            </Tooltip>
          ) : (
            <ForesightCheckbox
              checked={item.checked}
              label={childName}
              onChange={(e, data) => this.handleCheckboxClick(idx, data)}
              withIconName={hasIcon || null}
              iconPopupJsx={iconPopupJsx}
            />
          )}
        </div>
      );
    });
    return branches;
  };

  changeSearchState = data => {
    const { copyDataList } = this.state;
    if (data) {
      const filterDataList = copyDataList.filter(item => {
        const itemWords = item.name.toLowerCase();
        const inputWords = data.value.toLowerCase();
        return itemWords.includes(inputWords);
      });
      this.setState(prevState => {
        return {
          ...prevState,
          newDataList: filterDataList,
          loading: false,
        };
      });
    }
  };

  /* A debounce function. */
  handleFilterName = data => {
    this.setState({ loading: true, filterText: data });
    this.servicesValue(data);
  };

  render() {
    const { parentChecked, newDataList, loading } = this.state;
    const { parentName, searchBarPlaceholder, emptyJsx = 'No Data' } = this.props;

    return (
      <div className="checkbox-tree-data-list">
        <div className="check-box-filter-input">
          <ForesightInput
            style={{ height: 32, marginBottom: 20 }}
            placeholder={searchBarPlaceholder}
            onChange={(e, data) => this.handleFilterName(data)}
          />
        </div>
        {newDataList.length === 0 && !loading ? (
          <div className="no-repo-in-list">{emptyJsx}</div>
        ) : (
          <div className="repo-owner-select-all">
            <ForesightCheckbox
              indeterminate={this.handleInterminate()}
              checked={parentChecked}
              label={parentName}
              onChange={(e, data) => this.handleOwnSelectAllReposToggle(data)}
            />
          </div>
        )}
        {loading ? <LoadingDimmer /> : <div className="repo-owner-repo-list">{this.renderBranches()}</div>}
      </div>
    );
  }
}
CheckboxTreeList.propTypes = {
  data: [PropTypes.arrayOf, PropTypes.instanceOf, PropTypes.objectOf, PropTypes.func],
  loading: PropTypes.bool,
  fetched: PropTypes.bool,
  handleCheckboxOnClick: PropTypes.func,
  handleSelectAllReposToggle: PropTypes.func,
};
