import { Tooltip } from 'components/Tooltip';
import React from 'react';
import './ReviewersComponent.scss';
import { Image } from 'semantic-ui-react';

export const renderRevieversTooltip = requestedReviewers => {
  return requestedReviewers.map(reviewer => (
    <div className="requsted-reviewers-tooltip-conent" key={reviewer.login + reviewer.avatarUrl}>
      <div className="avatar-wrapper">
        <Image className="avatar-image" src={reviewer.avatarUrl}></Image>
      </div>
      <div className="text-wrapper">{reviewer.login}</div>
    </div>
  ));
};

export const getReviewersDetail = (requestedReviewers, withText) => {
  if (requestedReviewers && requestedReviewers.length == 1) {
    return (
      <>
        <span className="strong-text">{' ' + requestedReviewers[0]?.login}</span>
      </>
    );
  } else if (requestedReviewers && requestedReviewers.length > 1) {
    return (
      <Tooltip blackEdition content={renderRevieversTooltip(requestedReviewers)}>
        <div className="reviewers-count">
          <span className="reviewers-count-text">{requestedReviewers.length}</span>
        </div>
      </Tooltip>
    );
  } else if (withText === true) {
    return <>N / A</>;
  } else {
    return;
  }
};

export const ReviewersComponent = ({ requestedReviewers, withText }) => {
  return <>{getReviewersDetail(requestedReviewers, withText)}</>;
};
