import React from 'react';
import { BlueButton } from 'custom-components/Button/Button';
import { PageSubHeader, PageSubHeaderText, PageSubHeaderTitle } from 'custom-components/PageSubHeader/PageSubHeader';
import ForesightPureCheckboxList from './page-components/WorkflowList/PureCheckboxList';
import { Accordion, Button, Icon, Popup } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import './TeamOrganizationWorkflows.scss';
import { LoadingDimmer, SomethingError } from 'components/ComponentPlaceholder';
import { isEmpty } from 'utils/object-util';
import { CustomModal } from 'components/CustomModal';
import {
  InfoPanel,
  InfoPanelInfoIcon,
  InfoPanelText,
  InfoPanelTitle,
  InfoPanelTitleContainer,
} from 'custom-components/InfoPanel/InfoPanel';
import { CreateLabelPanel } from './page-components/CreateLabelPanel/CreateLabelPanel';
import { EditLabelPanel } from './page-components/EditLabelPanel/EditLabelPanel';
import { Tooltip } from 'components/Tooltip';
import { hasArrayElement } from 'utils/array-util';
import { UI_ICON } from 'assets/font-icons/IconManager';

const MAX_LABEL_CHAR_COUNT = 25;

class TeamOrganizationWorkflows extends React.Component {
  constructor(props) {
    super(props);
    this.timerID = -1;
    this.state = {
      changesMap: {},
      collapsibleMap: {},
      savingInProgress: false,
      applyModalIsOpen: false,
      createLabelModalIsOpen: false,
      createLabelModalLoading: false,
      editLabelModalIsOpen: false,
      isAddLabelDropdownOpen: false,
      isAssignLabelDropdownOpen: false,
      newLabelName: '',
      createAndSetLabelErrorMsg: '',
    };
  }

  componentDidMount() {
    this.fetchWorkflowSettings(this.props);
    this.fetchWorkflowStatusPollingStart(this.props);
    this.fetchLabelData(this.props);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  //Util Parts ...............................................
  getOrganizationId = props => {
    const { userAccount } = props.userAccount;
    return userAccount.organization.id;
  };

  generateChangeMapId = (id, idx) => {
    return `${id}/${idx}`;
  };

  getRepos = () => {
    const { organizationWorkflowsSettings } = this.props.organizationWorkflowsSettings;
    const repos = [];
    for (const key in organizationWorkflowsSettings) {
      repos.push(organizationWorkflowsSettings[key]);
    }
    return repos;
  };

  getOrganizationLabels = props => {
    const { organizationWorkflowsLabels } = props;
    const { organizationLabels } = organizationWorkflowsLabels;
    return organizationLabels;
  };

  //Fetching Parts ...........................................
  fetchWorkflowSettings = props => {
    const organizationId = this.getOrganizationId(props);
    this.props.getOrganizationWorkflowsSettings(organizationId);
  };

  fetchWorkflowSettingDetails = repoId => {
    this.props.getOrganizationWorkflowsSettingsDetails(repoId);
  };

  fetchWorkflowStatusPollingStart = props => {
    const organizationId = this.getOrganizationId(props);
    this.timerID = setInterval(() => {
      this.props.getOrganizationWorkflowsStatus(
        organizationId,
        () => {
          // console.log('WorkflowsStatus fetched successfully');
        },
        err => {
          // console.log('Repo fetching problem', err);
          // console.log('Repo fetching problem status', err.response?.status);
          if (err?.response?.status === 401) {
            clearTimeout(this.timerID);
          }
        },
      );
    }, 10000);
  };

  fetchLabelData = props => {
    const organizationId = this.getOrganizationId(props);
    this.props.getOrganizationLabels(organizationId);
    this.props.getOrganizationWorkflowIdLabelMap(organizationId);
  };

  //HandleEvent Parts........

  //Status Part .....
  handleRepoClick = repoId => {
    const { collapsibleMap } = this.state;
    const nextStatus = !collapsibleMap[repoId];
    const newCollapsibleMap = { ...collapsibleMap };
    newCollapsibleMap[repoId] = nextStatus;
    this.setState({ collapsibleMap: newCollapsibleMap });
    if (nextStatus) {
      this.fetchWorkflowSettingDetails(repoId);
    }
  };

  handleCheckboxListItemUpdate = (id, idx, data) => {
    const { changesMap } = this.state;
    const changeItemId = this.generateChangeMapId(id, idx);
    if (changesMap[changeItemId]) {
      delete changesMap[changeItemId];
    } else {
      changesMap[changeItemId] = { name: data.label, checked: data.checked };
    }
    this.setState({ changesMap: changesMap });
  };

  handleWorkflowStatusSave = () => {
    const repos = this.getRepos();
    const updates = [];
    const { changesMap } = this.state;

    //After Status Save Loading activated...
    this.setState({ savingInProgress: true, applyModalIsOpen: false });

    //Prepare Update Status Data
    repos.forEach(repo => {
      const { repoId, settings } = repo;
      settings.forEach((el, index) => {
        const id = this.generateChangeMapId(repoId, index);
        const updateItemData = changesMap[id];
        if (updateItemData) {
          updates.push({ ...el, watched: updateItemData.checked });
        }
      });
    });

    //Communicate Server
    this.props.updateOrganizationWorkflowsSettings(
      this.getOrganizationId(this.props),
      updates,
      () => {
        this.setState({ changesMap: {}, savingInProgress: false });
        //toast.success('We are saving your workflow preferences. It may take a while.', ToastConfig);
      },
      err => {
        console.log('updateOrganizationWorkflowsSettings', err);
        this.setState({ savingInProgress: false });
        toast.error('Workflow Settings update error', ToastConfig);
      },
    );
  };

  handleCreateAndAssignLabelToWorkflow = (organizationId, workflowId, newLabelName) => {
    this.props.createOrganizationWorkflowLabels(
      organizationId,
      newLabelName,
      [workflowId],
      () => {
        this.setState({ createLabelModalIsOpen: false, createLabelModalLoading: false });
        this.fetchLabelData(this.props);
      },
      () => {
        toast.error('Label Creation Error', ToastConfig);
      },
    );
  };

  handleAttachLabelToWorkflow = (labelId, workflowId) => {
    this.props.attachOrganizationWorkflowLabels(
      this.getOrganizationId(this.props),
      labelId,
      workflowId,
      () => {
        this.fetchLabelData(this.props);
      },
      () => {
        toast.error('Workflow Label Assign Error', ToastConfig);
      },
    );
  };

  handleDetachLabelFromWorkflow = (labelId, workflowId, onSuccess) => {
    const organizationId = this.getOrganizationId(this.props);
    this.props.detachOrganizationWorkflowLabels(organizationId, labelId, workflowId, onSuccess, () => {
      toast.error('Clear Label Error', ToastConfig);
    });
  };

  //Rendering Parts ........
  renderRepo = repoObj => {
    const { repoId } = repoObj;
    const { collapsibleMap } = this.state;
    const isCollapse = collapsibleMap[repoId];

    let repoName = '';
    if (typeof repoId === 'string') {
      const repoIdParts = repoId.split('/');
      if (repoIdParts.length === 3) repoName = repoIdParts[2];
    }

    return (
      <React.Fragment key={repoId}>
        <Accordion.Title index={0} onClick={() => this.handleRepoClick(repoId)}>
          <Icon className="workflow-settings-accordion-icon" name={isCollapse ? 'angle down' : 'angle right'} />
          <span className="workflow-settings-accordion-title">{repoName}</span>
        </Accordion.Title>
        <Accordion.Content
          active={isCollapse}
          content={<div className="workflow-settings-accordion-context">{this.renderRepoWorkflows(repoObj)}</div>}
        />
      </React.Fragment>
    );
  };

  renderDropdownMenu = (labelId, wfItem) => {
    const { organizationLabels } = this.props.organizationWorkflowsLabels;
    if (hasArrayElement(organizationLabels)) {
      const labelsJSX = organizationLabels.map(el => {
        return (
          <div
            key={el.id}
            className={`item ${labelId === el.id ? 'disabled' : ''}`}
            onClick={() => {
              if (labelId === el.id) return;
              if (labelId) {
                //detach => then => attach
                this.handleDetachLabelFromWorkflow(labelId, [wfItem.id], () => {
                  //toast.error('Remove Success', ToastConfig);
                  this.handleAttachLabelToWorkflow(el.id, [wfItem.id]);
                });
              } else {
                this.handleAttachLabelToWorkflow(el.id, [wfItem.id]);
              }
            }}
          >
            <span className="item-text">{el.name}</span>
          </div>
        );
      });

      return (
        <div className="add-label-action-dropdown">
          {labelsJSX}
          <div className="item-delimiter" />
          {labelId && (
            <>
              <div
                className="item"
                onClick={() =>
                  this.handleDetachLabelFromWorkflow(labelId, [wfItem.id], () => {
                    //toast.error('Remove Success', ToastConfig);
                    this.fetchLabelData(this.props);
                  })
                }
              >
                <span className="item-text">Clear label</span>
              </div>
              <div className="item-delimiter" />
            </>
          )}
          <div
            className="item"
            onClick={() => {
              this.setState({ createLabelModalIsOpen: wfItem.id });
            }}
          >
            <span className="item-text">Create & set a new label</span>
          </div>
        </div>
      );
    }
    return 'No Label';
  };

  renderAddLabelWhichTriggerDropdown = (wfItem, isSelected) => {
    const addLabelExtraCompClass = isSelected ? 'add-label-extra-comp selected' : 'add-label-extra-comp';
    return (
      <Popup
        onMount={() => this.setState({ isAddLabelDropdownOpen: wfItem.id })}
        onUnmount={() => this.setState({ isAddLabelDropdownOpen: false })}
        key={wfItem.id}
        on="click"
        content={this.renderDropdownMenu(null, wfItem)}
        basic
        hideOnScroll={true}
        position="bottom left"
        id="add-label-dropdown-parent"
        trigger={
          <div className={addLabelExtraCompClass}>
            <i className={UI_ICON.ADD.CIRCLE} />
            <span>Set label</span>
          </div>
        }
      ></Popup>
    );
  };

  renderAddLabelWhichTriggerCreateLabelModal = (wfItem, isSelected) => {
    const addLabelExtraCompClass = isSelected ? 'add-label-extra-comp selected' : 'add-label-extra-comp';
    return (
      <div className={addLabelExtraCompClass} onClick={() => this.setState({ createLabelModalIsOpen: wfItem.id })}>
        <i className={UI_ICON.ADD.CIRCLE} />
        <span>Set label</span>
      </div>
    );
  };

  renderExtraCheckboxListItemComp = (idx, wfItem, mouseOver) => {
    const { organizationWorkflowsLabel } = this.props.organizationWorkflowsLabels;
    const labels = organizationWorkflowsLabel[wfItem.id];
    const isWorkflowHasLabel = hasArrayElement(labels);
    const labelsJSX = isWorkflowHasLabel
      ? labels.map(el => {
          return (
            <Popup
              onMount={() => this.setState({ isAssignLabelDropdownOpen: wfItem.id })}
              onUnmount={() => this.setState({ isAssignLabelDropdownOpen: false })}
              key={el.id}
              on="click"
              content={this.renderDropdownMenu(el.id, wfItem)}
              basic
              hideOnScroll={true}
              position="bottom left"
              id="add-label-dropdown-parent"
              trigger={
                <div className="workflow-label">
                  <span className="workflow-label-text">{el.name}</span>
                </div>
              }
            ></Popup>
          );
        })
      : '';

    const { organizationLabels } = this.props.organizationWorkflowsLabels;
    const { isAddLabelDropdownOpen } = this.state;
    const isSelected = wfItem.id === isAddLabelDropdownOpen;
    return (
      <div className="extra-check-list-item-comps-container">
        {labelsJSX}
        {!isWorkflowHasLabel && (mouseOver || isSelected) && wfItem.checked && (
          <div>
            {hasArrayElement(organizationLabels)
              ? this.renderAddLabelWhichTriggerDropdown(wfItem, isSelected)
              : this.renderAddLabelWhichTriggerCreateLabelModal(wfItem, isSelected)}
          </div>
        )}
      </div>
    );
  };

  renderRepoWorkflows = repoWorkflowsObj => {
    const { fetching, error, settings = [], repoId } = repoWorkflowsObj;
    const { changesMap, isAddLabelDropdownOpen, isAssignLabelDropdownOpen } = this.state;

    const workflowWatchedItems = settings.map((el, index) => {
      const item = changesMap[this.generateChangeMapId(repoId, index)];
      return { ...el, checked: item ? item.checked : el.watched };
    });

    return (
      <>
        <ForesightPureCheckboxList
          extraClass="workflow-settings-checks"
          id={repoId}
          onItemUpdate={this.handleCheckboxListItemUpdate}
          data={workflowWatchedItems}
          emptyJsx={<div style={{ marginTop: 10, fontWeight: 500 }}> Workflow Not Found </div>}
          loading={fetching}
          error={error}
          renderExtraComps={this.renderExtraCheckboxListItemComp}
          handleCheckboxOnClick={this.handleBranchCheckboxClickFilter}
          selectedCheckForOperation={isAddLabelDropdownOpen || isAssignLabelDropdownOpen}
        />
      </>
    );
  };

  renderApplyModal = () => {
    const { changesMap } = this.state;
    const changeArr = Object.keys(changesMap) || [];
    const changeJSX = changeArr.map(el => {
      const arr = el.split('/');
      return <li key={el}>{arr[2] + '/' + changesMap[el]?.name}</li>;
    });

    return (
      <CustomModal
        id="apply-wfs-modal"
        isOpen={this.state.applyModalIsOpen}
        onModalClose={() => this.setState({ applyModalIsOpen: false })}
      >
        <div className="apply-wfs-modal-container">
          <div className="apply-wfs-modal-header">
            <i className={UI_ICON.ALERT.WARNING} />
            <div className="title">You are about to change your workflow preferences</div>
          </div>
          <div className="description-detail">
            <ul>
              <li>
                If you are unwatching a workflow, you will lose access to the following workflow's existing and
                forthcoming data. This action is irreversible
              </li>
              <li>
                If you are starting to watch a workflow, you will get their forthcoming data after you confirm your
                preference
              </li>
            </ul>
          </div>
          <div className="description">Workflows will be affected:</div>
          <div className="change-wf-list">
            <ul>{changeJSX}</ul>
          </div>
          <div className="delete-actions">
            <Button
              onClick={() => this.setState({ applyModalIsOpen: false })}
              className="apply-wfs-modal-cancel-button"
            >
              Cancel
            </Button>
            <BlueButton onClick={this.handleWorkflowStatusSave}>Accept</BlueButton>
          </div>
        </div>
      </CustomModal>
    );
  };

  renderCreateLabelModal = () => {
    const { createLabelModalIsOpen, newLabelName, createAndSetLabelErrorMsg } = this.state;
    const isOpen = !!createLabelModalIsOpen;
    const relatedWfId = createLabelModalIsOpen;

    return (
      <CustomModal
        id="apply-wfl-create-modal"
        isOpen={isOpen}
        onModalClose={() => this.setState({ createLabelModalIsOpen: false })}
      >
        <div className="apply-wfs-modal-container">
          <div className="apply-wfs-modal-header">
            <div className="title">Create & set a new label</div>
          </div>
          <CreateLabelPanel
            onInputChange={e => {
              this.setState({ newLabelName: e.target.value });
            }}
            loading={this.state.createLabelModalLoading}
            inputLabel="Label name"
            createButtonText="Create & Set"
            showActionButtons
            errorMessage={createAndSetLabelErrorMsg}
            disabled={newLabelName && newLabelName.length === 0}
            createButtonOnClick={() => {
              const organizationId = this.getOrganizationId(this.props);
              const { newLabelName } = this.state;

              const organizationLabels = this.getOrganizationLabels(this.props) || [];

              if (organizationLabels.some(el => el.name === newLabelName)) {
                this.setState({ createAndSetLabelErrorMsg: 'Label Exist' });
                return false;
              }

              if (typeof newLabelName === 'string' && newLabelName.length > MAX_LABEL_CHAR_COUNT) {
                this.setState({ createAndSetLabelErrorMsg: 'Max 25 Characters' });
                return false;
              }

              const { organizationWorkflowsLabel } = this.props.organizationWorkflowsLabels;
              const labels = organizationWorkflowsLabel[relatedWfId];
              this.setState({ createLabelModalLoading: true, createAndSetLabelErrorMsg: '' });
              if (hasArrayElement(labels)) {
                this.handleDetachLabelFromWorkflow(labels[0].id, [relatedWfId], () => {
                  this.handleCreateAndAssignLabelToWorkflow(organizationId, relatedWfId, newLabelName);
                });
              } else {
                this.handleCreateAndAssignLabelToWorkflow(organizationId, relatedWfId, newLabelName);
              }
            }}
            cancelButtonOnClick={() => this.setState({ createLabelModalIsOpen: false, createAndSetLabelErrorMsg: '' })}
          />
        </div>
      </CustomModal>
    );
  };

  renderEditLabelModal = () => {
    return (
      <CustomModal
        id="apply-wfl-edit-modal"
        isOpen={this.state.editLabelModalIsOpen}
        onModalClose={() => this.setState({ editLabelModalIsOpen: false })}
      >
        <EditLabelPanel
          organizationId={this.getOrganizationId(this.props)}
          createButtonText="OK"
          createButtonOnClick={() => this.setState({ editLabelModalIsOpen: false })}
          {...this.props}
        />
      </CustomModal>
    );
  };

  render() {
    console.log('TeamOrganizationWorkflows props=', this.props);
    const { savingInProgress, changesMap } = this.state;
    const { fetching, error, status: backendInProgressStatus } = this.props.organizationWorkflowsSettings;
    const repos = this.getRepos();

    const pageSubHeader = (
      <PageSubHeader>
        <PageSubHeaderTitle>Workflows</PageSubHeaderTitle>
        <PageSubHeaderText>
          You can set your workflow watching preferences across all your organization. Once you unwatch a workflow, you
          will lose all your access to existing and forthcoming data from that workflow.{' '}
        </PageSubHeaderText>
      </PageSubHeader>
    );

    const infoHeader = (
      <InfoPanel type="info">
        <InfoPanelTitleContainer>
          <InfoPanelInfoIcon />
          <InfoPanelTitle>Unwatch in progress</InfoPanelTitle>
        </InfoPanelTitleContainer>
        <InfoPanelText>
          You can set your workflow watching preferences across all your organization. Once you unwatch a workflow, you
          will lose all your access to existing and forthcoming data from that workflow.
        </InfoPanelText>
      </InfoPanel>
    );

    if (fetching) {
      return (
        <div className="workflow-settings-container">
          {pageSubHeader} <LoadingDimmer />
        </div>
      );
    } else if (error) {
      return (
        <div className="workflow-settings-container">
          {pageSubHeader} <SomethingError />
        </div>
      );
    }

    return (
      <div className="workflow-settings-container">
        <div>
          {pageSubHeader}
          {backendInProgressStatus && infoHeader}

          <div className="workflow-settings-body">
            <div className="workflow-settings-body-left">
              <div className="workflow-setting-header">
                <div className="workflow-settings-title-container">
                  <div className="workflow-setting-title">Watch & Unwatch Workflows</div>
                </div>
                <div className="workflow-setting-label-container">
                  <div className="icon-label-title-container">
                    <span className="icon-label-title">Labels</span>
                  </div>
                  <Tooltip blackEdition content="Edit Label">
                    <div
                      className="setting-label-icon-container"
                      onClick={() => this.setState({ editLabelModalIsOpen: true })}
                    >
                      <i className="icon-settings-light scale"></i>
                    </div>
                  </Tooltip>
                </div>
              </div>

              <Accordion className="workflow-settings-accordion" inverted>
                {repos.map(el => this.renderRepo(el))}
              </Accordion>
            </div>
            <div></div>
          </div>
        </div>

        <div className="workflow-settings-bottom-bar">
          <BlueButton
            disabled={isEmpty(changesMap)}
            loading={savingInProgress}
            onClick={() => {
              this.setState({ applyModalIsOpen: true });
            }}
          >
            Save changes
          </BlueButton>
        </div>

        {this.renderApplyModal()}
        {this.renderEditLabelModal()}
        {this.renderCreateLabelModal()}
      </div>
    );
  }
}

export default TeamOrganizationWorkflows;
