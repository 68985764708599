import {
	CREATE_NEW_PROJECT,
	SET_PROJECT,
	GET_PROJECT_BADGE,
	SET_REPO_SELECTED_PROJECT,
	SET_REPO_SELECTED_PROJECT_FLUSHED,
	DELETE_PROJECT,
	GET_ALL_PROJECTS,
	SET_PROJECT_SELECTION_COMPONENT_STATE,
} from 'store/constants/projects';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

export function getAllProjects() {
	return {
		type: GET_ALL_PROJECTS,
		payload: axios.get(CONFIG.apiUrl + '/projects', {
			headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
		}),
	};
}

export function createNewProject(projectName) {
	const data = {
		projectName,
		description: '',
	};

	return {
		type: CREATE_NEW_PROJECT,
		payload: axios.post(CONFIG.apiUrl + '/projects', data, {
			headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
		}),
	};
}

export function setProject(projectName, projectId) {
	const data = {
		projectName,
	};

	return {
		type: SET_PROJECT,
		payload: axios.put(CONFIG.apiUrl + `/projects/${projectId}`, data, {
			headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
		}),
	};
}

export function setRepoSelectedProject(projectId) {
	return {
		type: SET_REPO_SELECTED_PROJECT,
		payload: axios.get(CONFIG.apiUrl + `/projects/${projectId}`, {
			headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
		}),
	};
}

export function deleteProject(projectId) {
	return {
		type: DELETE_PROJECT,
		payload: axios.delete(CONFIG.apiUrl + `/projects/${projectId}`, {
			headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
		}),
	};
}

export function setRepoSelectedProjectFlush() {
	return {
		type: SET_REPO_SELECTED_PROJECT_FLUSHED,
	};
}

// For Project Selection Display and Modiy Container
export function setProjectSelectionComponentState(isProjectSelectionDone) {
	return {
		type: SET_PROJECT_SELECTION_COMPONENT_STATE,
		payload: { data: isProjectSelectionDone },
	};
}

export function getProjectBadges(projectId, bagdeType) {
	return {
		type: GET_PROJECT_BADGE,
		payload: axios.get(CONFIG.publicApiUrl + `/badge/${bagdeType}/${projectId}`),
	};
}
