import React from 'react';
import { LoadingDimmer, renderNoDataAvailableForTestSuites, SomethingError } from 'components/ComponentPlaceholder';
import { Tooltip } from 'components/Tooltip';
import './ListCards2.scss';
import { UI_ICON } from 'assets/font-icons/IconManager';
import { hasArrayElement } from 'utils/array-util';
export const ListCards2Container = ({ children }) => {
  return <table className={'list-cards-2-main-container-parent'}>{children}</table>;
};

export class ListCards2Header extends React.Component {
  handleSortClick = data => {
    const { sortData } = this.props;
    this.props.clickedSort({ field: data, order: sortData.order === 'DESC' ? 'ASC' : 'DESC' });
  };

  hasSortableItem = (sortText, order) => {
    const { sortData } = this.props;
    if (sortData.field === sortText?.toLowerCase()) {
      return order;
    }
  };

  render() {
    const { columnsObjectsArray, sortData } = this.props;
    return (
      <thead>
        <tr>
          {columnsObjectsArray &&
            columnsObjectsArray.map((item, index) => {
              return (
                <th colSpan={item.colSpan} key={item + index} style={{ ...item?.style.columnStyle }}>
                  <div className="list-cards-2-header-column-wrapper">
                    <div
                      className={'list-cards-2-header-items'}
                      onClick={item?.sortable ? () => this.handleSortClick(item.text) : () => {}}
                    >
                      <div className="text">{item.text}</div>
                      {item?.sortable && (
                        <div className="sortable-icon-container">
                          <i
                            className={
                              this.hasSortableItem(item.text, sortData.order) === 'ASC'
                                ? `${UI_ICON.SORT.UP} selected`
                                : UI_ICON.SORT.UP
                            }
                          />
                          <i
                            className={
                              this.hasSortableItem(item.text, sortData.order) === 'DESC'
                                ? `${UI_ICON.SORT.DOWN} selected`
                                : UI_ICON.SORT.DOWN
                            }
                          />
                        </div>
                      )}
                    </div>
                    {item.tooltipText && (
                      <div className="header-icon-style">
                        <Tooltip blackEdition content={<>{item.tooltipText}</>}>
                          <i className={UI_ICON.ALERT.INFO_BLUE_CIRCLE + ' column-header-icon'} />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  {item.children && <>{item.children}</>}
                </th>
              );
            })}
        </tr>
      </thead>
    );
  }
}

export const ListCards2Body = ({
  fetched,
  fetching,
  error,
  children,
  colSpan = '4',
  tableContent,
  infiniteFetching,
}) => {
  if (fetched || infiniteFetching) {
    if (!hasArrayElement(tableContent)) {
      return (
        <tbody className="list-cards-body-loading">
          <tr>
            <td colSpan={colSpan}>{renderNoDataAvailableForTestSuites()}</td>
          </tr>
        </tbody>
      );
    } else {
      return (
        <tbody className="list-cards-2-body">
          {children}
          {infiniteFetching && (
            <tr>
              <td colSpan={colSpan}>
                <LoadingDimmer msg="Loading..." />
              </td>
            </tr>
          )}
        </tbody>
      );
    }
  } else if (fetching) {
    return (
      <tbody className="list-cards-2-body-loading">
        <tr>
          <td colSpan={colSpan}>
            <LoadingDimmer msg="Loading..." />
          </td>
        </tr>
      </tbody>
    );
  } else if (error) {
    return (
      <tbody className="list-cards-2-body-loading">
        <tr>
          <td colSpan={colSpan}>
            <SomethingError msg={error} />;
          </td>
        </tr>
      </tbody>
    );
  } else {
    return (
      <tbody className="list-cards-2-body-loading">
        <tr>
          <td colSpan={colSpan}>
            <LoadingDimmer msg="Loading..." />
          </td>
        </tr>
      </tbody>
    );
  }
};

export const ListCards2RowBody = ({ children, withClickIcon, onClick }) => {
  return (
    <tr className="list-cards-2-body-row" onClick={onClick}>
      {children}
      {withClickIcon && (
        <td className="list-cards-2-cell-container">
          <div className="with-click-icon">
            <i className={UI_ICON.CHEVRON.RIGHT} />
          </div>
        </td>
      )}
    </tr>
  );
};

export const ListCards2CellContainer = ({ children }) => {
  return <td className="list-cards-2-cell-container">{children}</td>;
};
