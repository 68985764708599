import React, { Component } from 'react';
import { formatDateFromNow, formatDateWithTime, millisToMinutesAndSeconds } from 'utils/date-util';
import routeList, { computePath } from 'routes';
import { hasArrayElement } from 'utils/array-util';
import { renderNoDataAvailableForWorkflow } from 'components/ComponentPlaceholder';
import { getWorkflowStatusIcon } from 'utils/workflow-status-icon';
import { isEqualDeep } from 'utils/common-util';
import './RepositoryWorkflowRunsList.scss';
import { WORKFLOWRUN_STATUS } from 'utils/workflowrun-status';
import { getWorkflowStatusTooltipContent } from 'utils/workflowrun-conclusion-status-tooltips';
import { ListCardsBody, ListCardsCellContainer, ListCardsRowBody } from 'custom-components/ListCards/ListCards';

import { Tooltip } from 'components';
import { DOMAIN_ICON } from 'assets/font-icons/IconManager';

const DISABLE_MAP = ['ACTION_REQUIRED', 'STALE', 'NEUTRAL'];
class RepositoryRunListItem extends Component {
  isStillRunning = workflowRun => {
    if (workflowRun) {
      return workflowRun.status !== WORKFLOWRUN_STATUS.COMPLETED;
    }
    return false;
  };

  render() {
    const { data } = this.props;

    let workflowItemClassName = 'workflow-run-item-container';
    if (this.isStillRunning(data)) {
      workflowItemClassName += ' still-progress';
    }

    if (hasArrayElement(this.props.data?.failedJobs)) {
      workflowItemClassName += ' failure_extra_step';
    }

    const title = data.displayTitle || data.headCommit?.message;
    //Below msg for testing Long messages..
    //const commitMsg='Add service and repository for SpanOptions entity (#166) * Add service and repository for SpanOptions entity * Change SpanOptions service and repository * Change SpanOptions service and repository * Change data type * Remove default value SpanOptions';
    //const commitMsg='bbb';

    const { ciProvider, repoOwner, repoName } = this.props.matchParams;
    let classPostfix = DISABLE_MAP.includes(data.conclusion) ? 'workflow-run-item-disabled' : workflowItemClassName;
    return (
      <ListCardsRowBody
        className={'workflow-run-list-cards-row-body ' + classPostfix}
        onClick={() =>
          !this.isStillRunning(data) && !DISABLE_MAP.includes(data.conclusion)
            ? this.props.history.push(
                computePath(routeList.workflowRunTimeline.path, {
                  workflowId: data.id,
                  ciProvider: ciProvider,
                  repoOwner: repoOwner,
                  repoName: repoName,
                }),
              )
            : '#'
        }
      >
        <ListCardsCellContainer className="cell-container status">
          <div className="flex-row">
            <Tooltip blackEdition content={<div>{getWorkflowStatusTooltipContent(data)}</div>}>
              <div className="status">{getWorkflowStatusIcon(data)}</div>
            </Tooltip>
            <span className="status-icon-position sub-row">{getWorkflowStatusTooltipContent(data)}</span>
          </div>

          <div className="flex-row">
            <Tooltip blackEdition position="top center" content={<div>Date</div>}>
              <i className={DOMAIN_ICON.GITHUB.EXEC_DATE + ' execution-date-icon-position'} />
            </Tooltip>
            <Tooltip
              blackEdition
              position="bottom left"
              content={<div>Open since {formatDateWithTime(data.createdAt)}</div>}
            >
              <span className="status-column-position sub-row">{formatDateFromNow(data.createdAt)}</span>
            </Tooltip>
          </div>
          <div className="flex-row">
            <Tooltip blackEdition position="top center" content={<div>Execution duration</div>}>
              <i className={DOMAIN_ICON.GITHUB.DURATION + ' elapsed-icon-position'} />
            </Tooltip>
            <span className="status-column-position sub-row">{millisToMinutesAndSeconds(data.duration)}</span>
          </div>
        </ListCardsCellContainer>

        <ListCardsCellContainer className={'cell-container metadata '}>
          <div className="metadata-cell">
            {title && title?.length > 42 ? (
              <Tooltip blackEdition content={title}>
                <div className="title">{title}</div>
              </Tooltip>
            ) : (
              <div className="title">{title}</div>
            )}

            <div className="text author-and-reviewer">{<>{data.name}</>}</div>
            <div className="text">
              {hasArrayElement(data.failedJobs) && (
                <span className="failed-jobs">
                  <span className="failed-workflows-icon">{getWorkflowStatusIcon(data)}</span>
                  {'#'}
                  {data.failedJobs[0].runId} {data.failedJobs[0].name}
                  &nbsp;
                  {data.failedJobs.length > 1 && <span> and {data.failedJobs.length} other jobs</span>}
                  {data.failedJobs.length == 1 && <span>job</span>}
                </span>
              )}
            </div>
          </div>
        </ListCardsCellContainer>
        <ListCardsCellContainer className={'cell-container committer '}>
          <div className="committer-wrapper ">
            <i className={DOMAIN_ICON.GITHUB.HEAD_COMMIT_AUTHOR + ' person-icon-position'} />
            <div className="title">{data.headCommit?.author?.name}</div>
          </div>
        </ListCardsCellContainer>
        <ListCardsCellContainer className={'cell-container commit'}>
          <div className="commit-wrapper">
            <i className="icon-commit commit-icon-position" />
            <div className="commit">{data.headSha ? data?.headSha.slice(0, 5) : 'N/A'}</div>
          </div>
        </ListCardsCellContainer>
      </ListCardsRowBody>
    );
  }
}

export default class RepositoryWorkflowRunsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 0,
      pageSize: 50,
      fetching: false,
    };
    this.searchPath = '';
  }

  callStoregetWorkflowRunFilters = (ciProvider, repoOwner, repoName, hideFork = false) => {
    const { prId } = this.props;

    if (prId) {
      this.props.getWorkflowRunFilters(ciProvider, repoOwner, repoName, prId, hideFork);
    } else {
      this.props.getWorkflowRunFilters(ciProvider, repoOwner, repoName, hideFork);
    }
  };

  componentDidMount() {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    const { selectedWorkflowRunHideFork } = this.props.workflowRunsMeta;
    this.callStoregetWorkflowRunFilters(ciProvider, repoOwner, repoName, selectedWorkflowRunHideFork);

    document.getElementById('workflow-runs-scrollable').addEventListener('scroll', this.trackScrolling);
    const { pageNo, pageSize } = this.state;
    this.fetchData(ciProvider, repoOwner, repoName, pageNo, pageSize, this.props.workflowRunsMeta);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pageSize } = this.state;
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    const { ciProvider: ciProviderNext, repoOwner: repoOwnerNext, repoName: repoNameNext } = nextProps.match.params;

    const {
      selectedWorkflowIds,
      selectedWorkflowRunBranches,
      selectedWorkflowRunConclusions,
      selectedWorkflowRunEvents,
      selectedWorkflowRunLabels,
      selectedWorkflowRunHideFork,
    } = this.props.workflowRunsMeta;
    const {
      selectedWorkflowIds: selectedWorkflowIdsNext,
      fetched: workflowRunsMetaFetched,
      selectedWorkflowRunBranches: nextBranches,
      selectedWorkflowRunConclusions: nextConclusions,
      selectedWorkflowRunEvents: nextEvents,
      selectedWorkflowRunHideFork: nextHideFork,
      selectedWorkflowRunLabels: nextLabels,
    } = nextProps.workflowRunsMeta;

    const repoChangeFlag = ciProvider !== ciProviderNext || repoOwnerNext !== repoOwner || repoNameNext !== repoName;

    const propsChangeFlag =
      (workflowRunsMetaFetched &&
        (!isEqualDeep(selectedWorkflowIds, selectedWorkflowIdsNext) ||
          !isEqualDeep(nextBranches, selectedWorkflowRunBranches) ||
          !isEqualDeep(nextConclusions, selectedWorkflowRunConclusions))) ||
      !isEqualDeep(nextEvents, selectedWorkflowRunEvents) ||
      !isEqualDeep(nextHideFork, selectedWorkflowRunHideFork) ||
      !isEqualDeep(nextLabels, selectedWorkflowRunLabels);

    if (repoChangeFlag || propsChangeFlag) {
      this.setState({ pageNo: 0 }, () => {
        this.fetchData(ciProviderNext, repoOwnerNext, repoNameNext, 0, pageSize, nextProps.workflowRunsMeta);
      });
    }
  }

  fetchData = (ciProvider, repoOwner, repoName, pageNo, pageSize, workflowRunsMeta) => {
    const { prId } = this.props;
    if (prId) {
      this.props.getWorkflowRuns(
        ciProvider,
        repoOwner,
        repoName,
        pageNo,
        pageSize,
        workflowRunsMeta.selectedWorkflowIds,
        workflowRunsMeta.selectedWorkflowRunBranches,
        workflowRunsMeta.selectedWorkflowRunConclusions,
        workflowRunsMeta.selectedWorkflowRunEvents,
        prId,
        workflowRunsMeta.selectedWorkflowRunHideFork,
        () => {
          this.setState({ fetching: false, pageNo: pageNo });
        },
        () => {
          this.setState({ fetching: false });
        },
      );
    } else {
      this.props.getWorkflowRuns(
        ciProvider,
        repoOwner,
        repoName,
        pageNo,
        pageSize,
        workflowRunsMeta.selectedWorkflowIds,
        workflowRunsMeta.selectedWorkflowRunBranches,
        workflowRunsMeta.selectedWorkflowRunConclusions,
        workflowRunsMeta.selectedWorkflowRunEvents,
        workflowRunsMeta.selectedWorkflowRunHideFork,
        () => {
          this.setState({ fetching: false, pageNo: pageNo });
        },
        () => {
          this.setState({ fetching: false });
        },
      );
    }
  };

  checkFetchingElements = (totalElements, pageNo, pageSize) => {
    const size = pageNo * pageSize;
    return size < totalElements;
  };

  fetchWhenScrollAccessToBottom = el => {
    const { workflowRuns } = this.props.workflowRuns;
    const { pageNo, pageSize } = this.state;
    if (!this.checkFetchingElements(workflowRuns.totalElements, pageNo, pageSize)) {
      return;
    }
    if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
      const { fetching } = this.state;
      //console.log('Access Bottom: fetching status:', fetching);
      if (!fetching) {
        //console.log('Fetching ...');
        const { ciProvider, repoOwner, repoName } = this.props.match.params;
        this.setState({ fetching: true });
        const newPageNo = this.state.pageNo + 1;
        this.fetchData(ciProvider, repoOwner, repoName, newPageNo, pageSize, this.props.workflowRunsMeta);
      }
    }
  };

  trackScrolling = () => {
    const wrappedElement = document.getElementById('workflow-runs-scrollable');
    this.fetchWhenScrollAccessToBottom(wrappedElement);
  };

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  renderList = () => {
    const { workflowRuns, fetching, fetched, error } = this.props.workflowRuns;
    const matchParams = this.props.match.params;
    const content = workflowRuns.content;
    return (
      <ListCardsBody
        fetched={fetched}
        fetching={fetching}
        error={error}
        infiniteFetching={this.state.fetching}
        tableContent={content}
        emptyContent={renderNoDataAvailableForWorkflow()}
      >
        {hasArrayElement(content) &&
          content.map((el, index) => (
            <RepositoryRunListItem matchParams={matchParams} key={index} data={el} history={this.props.history} />
          ))}
      </ListCardsBody>
    );
  };

  render() {
    return <>{this.renderList()}</>;
  }
}
