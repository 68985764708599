import React from 'react';
import { BlueButton, GrayBorderButton } from 'custom-components/Button/Button';
import { ForesightInput } from 'custom-components/Input/Input';
import { Component } from 'react';
import './CreateLabelPanel.scss';
import { UI_ICON } from 'assets/font-icons/IconManager';

export class CreateLabelPanel extends Component {
  getErrorMessage = message => {
    return (
      <span className="error-input-message">
        <i className={UI_ICON.ALERT.ERROR} />
        {message}
      </span>
    );
  };

  getActionButtons = () => {
    const { cancelButtonOnClick, createButtonOnClick, loading, createButtonText } = this.props;

    return (
      <div className="action-buttons-container">
        <GrayBorderButton style={{ height: 32 }} onClick={cancelButtonOnClick}>
          Cancel
        </GrayBorderButton>
        <BlueButton primary onClick={createButtonOnClick} loading={loading}>
          {createButtonText}
        </BlueButton>
      </div>
    );
  };

  render() {
    const { onInputChange, errorMessage, placeholder, showActionButtons, inputLabel } = this.props;
    return (
      <div className="wf-create-label-panel">
        <ForesightInput placeholder={placeholder} labelText={inputLabel} required onChange={onInputChange} />
        {errorMessage && this.getErrorMessage(errorMessage)}
        {showActionButtons && this.getActionButtons()}
      </div>
    );
  }
}
