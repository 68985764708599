import React, { Component } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import './CircleLeftDays.scss';

class CircleLeftDays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      circleData: [
        { name: 'left', value: this.props.daysValue },
        { name: 'full', value: 14 - this.props.daysValue },
      ],
    };
  }

  componentDidMount() {
    console.log('Circle Left Days Rendered');
  }

  render() {
    const { circleData } = this.state;
    const { daysValue } = this.props;
    return (
      <div className="cld-main-container">
        <div className="cld-circle-cont">
          <PieChart className="piechart" width={24} height={32} style={{ zIndex: 99999999 }}>
            <Pie
              data={circleData}
              dataKey="value"
              fill="rgba(255, 255, 255, 0.08)"
              stroke="rgba(255, 255, 255, 0.00)"
              innerRadius={6}
              outerRadius={9}
              paddingAngle={0}
            >
              <Cell key={'cell-3'} fill={daysValue < 4 ? '#FF9214' : '#2DCD8A'} style={{ borderRadius: '322px' }} />
            </Pie>
          </PieChart>
        </div>
        <div className="cld-text-cont">
          <div className={`text ${daysValue < 4 ? 'orange' : 'green'}`}>{this.props.daysValue} Days Left</div>
        </div>
      </div>
    );
  }
}

export default CircleLeftDays;
