import { combineReducers } from 'redux';
import userAccount from './user-account';
import ciConnect from './ci-provider-connect';
import ciRepos from './ci-provider-repos';
import changePassword from './password';
import changeUserInformation from './user-info';
import pullRequests from './pull-requests';
import pullRequestsMeta from './pull-requests-filter';
import pullRequestWorkflowRuns from './pull-request-workflow-runs';
import pullRequestWorkflowRunsMeta from './pull-request-workflow-runs-filter';
import { pullRequestTestRuns as pullRequestTestWRuns } from './pull-request-test-runs';
import pullRequestOverview from './pull-request-overview';
import pullRequestCoverages from './pull-request-coverage';
import { testRuns as testWRuns } from './test-runs';
import testRunDetails from './test-run-details';
import testDetails from './test-details';
import workflowRuns from './workflow-runs';
import workflowRunsMeta from './workflow-runs-meta';
import highlights from './highlights';
import workflowStepsLogs from './workflow-steps-logs';
import organization from './organization';
import invitation from './organization';
import singleWorkflowHighlights from './single-workflow-hightlights';
import notificationIntegration from './notification-integration';
import notificationPreferences from './notification-preferences';
import sourceCoverageDetails from './source-coverage';
import workflowRunJobTests from './workflow-jobs-tests';
import workflowMetrics from './workflow-metrics';
import workflowRunJobProcesses from './workflow-jobs-processes';
import { rule, ruleList, workflowNameList } from './rules';
import workflowRunJobHistory from './workflow-jobs-history';
import highlightsFilter from './highlights-filter';

import billingCustomer from './billing-customer';
import upsertCard from './billing-upsert-card';
import activeSubscription from './billing-active-subscription';
import cancelSubscription from './billing-cancel-subscription';
import saveSubscription from './billing-save-subscription';
import billingPlans from './billing-plans';
import billingInvoices from './billing-invoices';
import inviteBillingAdminToOrganization from './organization-invite-billing-admin';
import pullRequestSummary from './pull-request-summary';
import whiteList from './whitelist';
import workflowView from './workflow-view';
import { testSuitesOverview } from './test-suites-overview';
import { testSuitesOverviewDetail } from './test-suites-overview-detail';
import workflowViewFilters from './workflow-view-filters';
import organizationSettings from './organization-settings';
import organizationWorkflowsSettings from './organization-workflows-settings';
import organizationWorkflowsLabels from './organization-workflows-labels';
import projects from './projects';
import repos from './repos';
import theme from './theme';

import testViewChart from './test-view-chart';
import testViewList from './test-view-list';
import testViewFilters from './test-view-filters';
import testViewSummary from './test-view-summary';
import workflowRunTimeline from './workflow-timeline';

import hasConnectedRepoWacthed from './has-connected-repo-watched';

import { CLEAR_REDUX_STORE } from 'store/constants/redux-store';

const runForesightApp = combineReducers({
  userAccount,
  ciConnect,
  ciRepos,
  repos,
  changePassword,
  changeUserInformation,
  testWRuns,
  testDetails,
  testRunDetails,
  workflowRuns,
  workflowRunsMeta,

  pullRequestWorkflowRuns,
  pullRequestWorkflowRunsMeta,
  pullRequestTestWRuns,
  pullRequests,
  pullRequestSummary,
  pullRequestsMeta,
  pullRequestOverview,
  pullRequestCoverages,
  projects,
  theme,
  highlights,
  highlightsFilter,
  workflowStepsLogs,
  workflowRunJobTests,
  organization,
  invitation,
  singleWorkflowHighlights,
  notificationIntegration,
  notificationPreferences,
  sourceCoverageDetails,
  workflowMetrics,
  workflowRunJobProcesses,
  workflowRunJobHistory,

  billingCustomer,
  upsertCard,
  activeSubscription,
  cancelSubscription,
  saveSubscription,
  billingPlans,
  billingInvoices,
  inviteBillingAdminToOrganization,
  rule,
  ruleList,
  workflowNameList,
  whiteList,
  workflowView,
  workflowViewFilters,
  organizationSettings,
  testSuitesOverview,
  testSuitesOverviewDetail,
  testViewFilters,
  testViewChart,
  testViewSummary,
  testViewList,
  organizationWorkflowsSettings,
  organizationWorkflowsLabels,
  hasConnectedRepoWacthed,
  workflowRunTimeline,
});

const runForesightAppRoot = (state, action) => {
  if (action.type === CLEAR_REDUX_STORE) {
    localStorage.removeItem('persist:root'); // this is to clear redux-persist storage
    return runForesightApp(undefined, action);
  }
  return runForesightApp(state, action);
};

export default runForesightAppRoot;
