import React, { Component } from 'react';
import { Button, Image } from 'semantic-ui-react';
import connectRepoButtonIcon from 'assets/images/connect-repo-button.svg';
import emptyState from 'assets/images/empty-state.svg';
import { isSelectedConnectorIsConnectedAlready } from 'utils/ci-connect-util';
import routeList from 'routes';
import { CI_PROVIDER } from 'enums/git-provider';
import './ConnectRepositoryWidget.scss';
import { isUserInInvitedUserRole } from 'utils/user-role-util';

class ConnectRepositoryWidget extends Component {
  constructor(props) {
    super(props);
  }

  onSetSelectedProjectSuccess = () => {
    if (isSelectedConnectorIsConnectedAlready(CI_PROVIDER.GITHUB, this.props.ciConnect)) {
      // startCreateProjectSelectGithubRepos page will handle the redirection
      // if the project has already created
      this.props.history.push(routeList.startCreateProjectSelectGithubRepos.path);
    } else {
      this.props.history.push(routeList.startInstallGitHubAppTrigger.path);
    }
  };

  renderCreateAndConnectRepoButton(buttonType, renderIcon, genericRouteMethod) {
    const { userAccount } = this.props;

    if (isUserInInvitedUserRole(userAccount.userAccount.role)) {
      return <></>;
    }
    return (
      <Button primary className={buttonType} onClick={() => genericRouteMethod()}>
        <div className="connect-button-container-highlights">
          {renderIcon && <Image className="icon" src={connectRepoButtonIcon} fluid />}
          Connect Pipeline
        </div>
      </Button>
    );
  }

  render() {
    const { noProjectIsPresent } = this.props;
    return (
      <div className="body-container-highlights empty-state-container-highlights">
        <div className="empty-state-highlights">
          {noProjectIsPresent && <Image className="empty-image-highlights" src={emptyState}></Image>}
          <span className="body-text-title-highlights">Connect a repository to get started</span>
          <div className="body-text-container-highlights">
            <span className="body-text-highlights">
              Start monitoring your workflows and your tests by <br />
              connecting a repository.
              <a href="https://docs.runforesight.com/" target="_blank" rel="noopener noreferrer">
                {' '}
                Learn how to connect a repository.
              </a>
            </span>
          </div>
          {this.renderCreateAndConnectRepoButton(
            'initial-and-project-connect-repositories-highlights',
            false,
            this.onSetSelectedProjectSuccess,
          )}
        </div>
      </div>
    );
  }
}

export default ConnectRepositoryWidget;
