import {
	UPDATE_CI_PROVIDER_REPOS_PENDING,
	UPDATE_CI_PROVIDER_REPOS_FULFILLED,
	UPDATE_CI_PROVIDER_REPOS_REJECTED,
	GET_CI_PROVIDER_REPOS_PENDING,
	GET_CI_PROVIDER_REPOS_FULFILLED,
	GET_CI_PROVIDER_REPOS_REJECTED,
	FLUSH_CI_PROVIDER_REPOS,
	CREATE_NEW_PROJECT_WITH_SELECTED_REPO_PENDING,
	CREATE_NEW_PROJECT_WITH_SELECTED_REPO_FULFILLED,
	CREATE_NEW_PROJECT_WITH_SELECTED_REPO_REJECTED,
} from 'store/constants/ci-provider-repos';

const initialState = {
	updateCIRepoList: {},
	isUpdateCIRepoListFetching: false,
	isUpdateCIRepoListFetched: false,
	isUpdateCIRepoListError: null,

	ciRepoList: [],
	isCIRepoListFetching: false,
	isCIRepoListFetched: false,
	isCIRepoListError: null,

	createNewProjectWithSelectedRepoFetching: false,
	createNewProjectWithSelectedRepoFetched: false,
	createNewProjectWithSelectedRepoError: false,
};

export default function ciRepos(state = initialState, action) {
	switch (action.type) {
	case UPDATE_CI_PROVIDER_REPOS_PENDING:
		return {
			...state,
			isUpdateCIRepoListFetching: true,
			isUpdateCIRepoListFetched: false,
			isUpdateCIRepoListError: null,
		};
	case UPDATE_CI_PROVIDER_REPOS_FULFILLED:
		console.log('UPDATE_CI_PROVIDER_REPOS_FULFILLED, reducer; action: ', action);
		return {
			...state,
			updateCIRepoList: action.payload.data,
			isUpdateCIRepoListFetching: false,
			isUpdateCIRepoListFetched: true,
			isUpdateCIRepoListError: null,
		};
	case UPDATE_CI_PROVIDER_REPOS_REJECTED:
		return {
			...state,
			isUpdateCIRepoListFetching: false,
			isUpdateCIRepoListFetched: false,
			isUpdateCIRepoListError: action.payload,
		};

	case FLUSH_CI_PROVIDER_REPOS:
		return {
			...state,
			ciRepoList: [],
			isCIRepoListFetching: false,
			isCIRepoListFetched: false,
			isCIRepoListError: null,
		};
	case GET_CI_PROVIDER_REPOS_PENDING:
		return {
			...state,
			isCIRepoListFetching: true,
			isCIRepoListFetched: false,
			isCIRepoListError: null,
		};
	case GET_CI_PROVIDER_REPOS_FULFILLED:
		console.log('GET_CI_PROVIDER_REPOS_FULFILLED, reducer; action: ', action);
		return {
			...state,
			ciRepoList: action.payload.data,
			isCIRepoListFetching: false,
			isCIRepoListFetched: true,
			isCIRepoListError: null,
		};
	case GET_CI_PROVIDER_REPOS_REJECTED:
		return {
			...state,
			isCIRepoListFetching: false,
			isCIRepoListFetched: false,
			isCIRepoListError: action.payload,
		};
	case CREATE_NEW_PROJECT_WITH_SELECTED_REPO_PENDING:
		return {
			...state,
			createNewProjectWithSelectedRepoFetching: true,
			createNewProjectWithSelectedRepoFetched: false,
			createNewProjectWithSelectedRepoError: false,
		};
	case CREATE_NEW_PROJECT_WITH_SELECTED_REPO_FULFILLED:
		return {
			...state,
			createNewProjectWithSelectedRepoFetching: false,
			createNewProjectWithSelectedRepoFetched: true,
			createNewProjectWithSelectedRepoError: false,
		};
	case CREATE_NEW_PROJECT_WITH_SELECTED_REPO_REJECTED:
		return {
			...state,
			createNewProjectWithSelectedRepoFetching: false,
			createNewProjectWithSelectedRepoFetched: false,
			createNewProjectWithSelectedRepoError: action.payload,
		};
	default:
		return state;
	}
}
