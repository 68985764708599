import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import './TestCaseNameFilter.scss';

export class TestCaseNameFilter extends Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.timeout = 0;
		this.state = { filter: props.filter, typed: false };
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.filter !== nextProps.filter) {
			this.setState({ filter: nextProps.filter });
		}
	}

	handleChange(e) {
		const filter = e.target.value + '';
		if (this.timeout) {
			//console.debug(`timeout cleared without run ${this.timeout} -filter:${this.filter}`)
			clearTimeout(this.timeout);
			this.timeout = 0;
		}

		this.setState({ filter: filter, typed: true });
		this.timeout = setTimeout(() => {
			//console.debug(`timeout run ${this.timeout} -filter:${this.filter}`)
			this.setState({ typed: false }, this.props.handleChange(filter));
		}, 500);

		//console.debug(`timeout created ${this.timeout}`)
	}

	render() {
		return (
			<div className="name-filter-container">
				<Input
					fluid={true}
					onChange={this.handleChange}
					placeholder="Search"
					value={this.state.filter}
				/>
			</div>
		);
	}
}
