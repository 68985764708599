import React, { Component } from 'react';
import { hasArrayElement } from 'utils/array-util';
import { calcDisplayedTime } from 'utils/date-util';
import './JobsWidget.scss';

export class JobsWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedJobIdx: 0,
        };
    }

    renderJobList = () => {
        const { workflowRuns, handlerJobs } = this.props;

        if (!hasArrayElement(workflowRuns)) {
            return null;
        }

        const jobListDom = workflowRuns.map((job, idx) => {
            const isSelected = idx === this.state.selectedJobIdx;
            let itemClass = 'job-item';
            if (isSelected) {
                itemClass = itemClass + ' selected';
            }
            return (
                <div
                    className={itemClass}
                    key={`${job.name + job.idx}`}
                    onClick={() => {
                        handlerJobs(idx);
                        this.setState({ selectedJobIdx: idx });
                    }
                    }
                >
                    <span className="job-name">{job.name} </span>
                    <span className="job-duration">{calcDisplayedTime(job.totalDuration)} min</span>
                </div >
            );
        });
        return (
            <>
                <div className='workflow-jobs-widget-title'>Jobs ({workflowRuns.length})</div>
                <div className="jobs-list-wrapper">{jobListDom}</div>
            </>
        );
    };

    render() {
        return (
            <div>
                {this.renderJobList()}
            </div>
        );
    }
}

