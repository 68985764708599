import React, { Component } from 'react';
import './FrameworkSelectionRadioButtons.scss';
import { Radio } from 'semantic-ui-react';
import {
	CI_PARSER_FRAMEWORK,
	CI_PARSER_FRAMEWORK_YML_NAME,
	CI_PARSER_FRAMEWORK_HUMAN_READABLE,
} from 'enums/thundra-cli-ci-parser-frameworks';
import PropTypes from 'prop-types';

class FrameworkSelectionRadioButtons extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedFramework: CI_PARSER_FRAMEWORK.JEST,
		};
	}

	render() {
		return (
			<>
				<div className="select-test-framework">
					<Radio
						checked={this.state.selectedFramework === CI_PARSER_FRAMEWORK.JEST}
						fitted
						label={CI_PARSER_FRAMEWORK_HUMAN_READABLE.JEST}
						className="radio-button"
						onClick={() => {
							this.setState({ selectedFramework: CI_PARSER_FRAMEWORK.JEST });
							this.props.onSelectedFrameworkTextChange(CI_PARSER_FRAMEWORK_YML_NAME.JEST);
						}}
					/>
					<Radio
						checked={this.state.selectedFramework === CI_PARSER_FRAMEWORK.JUNIT}
						fitted
						label={CI_PARSER_FRAMEWORK_HUMAN_READABLE.JUNIT}
						className="radio-button"
						onClick={() => {
							this.setState({ selectedFramework: CI_PARSER_FRAMEWORK.JUNIT });
							this.props.onSelectedFrameworkTextChange(CI_PARSER_FRAMEWORK_YML_NAME.JUNIT);
						}}
					/>
					<Radio
						checked={this.state.selectedFramework === CI_PARSER_FRAMEWORK.PYTHON}
						fitted
						label={CI_PARSER_FRAMEWORK_HUMAN_READABLE.PYTHON}
						className="radio-button"
						onClick={() => {
							this.setState({ selectedFramework: CI_PARSER_FRAMEWORK.PYTHON });
							this.props.onSelectedFrameworkTextChange(CI_PARSER_FRAMEWORK_YML_NAME.PYTHON);
						}}
					/>
					<Radio
						checked={this.state.selectedFramework === CI_PARSER_FRAMEWORK.TESTNG}
						fitted
						label={CI_PARSER_FRAMEWORK_HUMAN_READABLE.TESTNG}
						className="radio-button"
						onClick={() => {
							this.setState({ selectedFramework: CI_PARSER_FRAMEWORK.TESTNG });
							this.props.onSelectedFrameworkTextChange(CI_PARSER_FRAMEWORK_YML_NAME.TESTNG);
						}}
					/>
					<Radio
						checked={this.state.selectedFramework === CI_PARSER_FRAMEWORK.TRX}
						fitted
						label={CI_PARSER_FRAMEWORK_HUMAN_READABLE.TRX}
						className="radio-button"
						onClick={() => {
							this.setState({ selectedFramework: CI_PARSER_FRAMEWORK.TRX });
							this.props.onSelectedFrameworkTextChange(CI_PARSER_FRAMEWORK_YML_NAME.TRX);
						}}
					/>
					<Radio
						checked={this.state.selectedFramework === CI_PARSER_FRAMEWORK.XUNIT2}
						fitted
						label={CI_PARSER_FRAMEWORK_HUMAN_READABLE.XUNIT2}
						className="radio-button"
						onClick={() => {
							this.setState({ selectedFramework: CI_PARSER_FRAMEWORK.XUNIT2 });
							this.props.onSelectedFrameworkTextChange(CI_PARSER_FRAMEWORK_YML_NAME.XUNIT2);
						}}
					/>
				</div>
			</>
		);
	}
}
FrameworkSelectionRadioButtons.propTypes = {
	onSelectedFrameworkTextChange: PropTypes.func.isRequired,
	selectedFrameworkText: PropTypes.string.isRequired,
};
export default FrameworkSelectionRadioButtons;
