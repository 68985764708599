import {
  GET_WORKFLOW_RUNS_META,
  SET_WORKFLOW_IDS_SELECTED,
  SET_WORKFLOW_RUN_BRANCHES_SELECTED,
  SET_WORKFLOW_RUN_CONCLUSIONS_SELECTED,
  GET_WORKFLOW_RUNS_FILTER_LIST,
  SET_WORKFLOW_RUN_EVENTS_SELECTED,
  SET_WORKFLOW_RUN_HIDE_FORK_SELECTED,
  GET_WORKFLOW_RUNS_LABELS,
  SET_WORKFLOW_RUN_LABELS_SELECTED,
} from 'store/constants/workflow-runs-meta';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

export function getWorkflowRunsMeta(ciProvider, repoOwner, repoName) {
  return {
    meta: { ciProvider: ciProvider, repoOwner: repoOwner, repoName: repoName },
    type: GET_WORKFLOW_RUNS_META,
    payload: axios.post(
      CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/workflows`,
      {},
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function setSelectedWorkflowIds(workflowIds) {
  return {
    type: SET_WORKFLOW_IDS_SELECTED,
    payload: { data: workflowIds },
  };
}

export function getWorkflowRunFilters(ciProvider, repoOwner, repoName, hideForkBranch) {
  return {
    meta: { ciProvider: ciProvider, repoOwner: repoOwner, repoName: repoName },
    type: GET_WORKFLOW_RUNS_FILTER_LIST,
    payload: axios.post(
      CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/workflow-runs/filters`,
      { hideForkBranch: hideForkBranch },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export const getOrganizationLabelsForWorkflow = organizationId => {
  return {
    meta: { organizationId: organizationId },
    type: GET_WORKFLOW_RUNS_LABELS,
    payload: axios.get(CONFIG.apiUrl + `/organization/${organizationId}/workflows/label`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};

export function setSelectedLabels(labels) {
  return {
    type: SET_WORKFLOW_RUN_LABELS_SELECTED,
    payload: { data: labels },
  };
}

export function setSelectedConclusions(conclusion) {
  return {
    type: SET_WORKFLOW_RUN_CONCLUSIONS_SELECTED,
    payload: { data: conclusion },
  };
}

export function setSelectedBranches(branch) {
  return {
    type: SET_WORKFLOW_RUN_BRANCHES_SELECTED,
    payload: { data: branch },
  };
}

export function setSelectedEvents(event) {
  return {
    type: SET_WORKFLOW_RUN_EVENTS_SELECTED,
    payload: { data: event },
  };
}

export function setSelectedHideFork(hideFork) {
  return {
    type: SET_WORKFLOW_RUN_HIDE_FORK_SELECTED,
    payload: { data: hideFork },
  };
}
