import React, { useState } from 'react';
import { BlueButton } from 'custom-components/Button/Button';
import { ForesightInput } from 'custom-components/Input/Input';
import './SearchInput.scss';

export const SearchInput = ({
  searchValue,
  onClickSearchButton,
  inputPlaceholder,
  buttonText = 'Search',
  onChangeInputText,
  searchValueLengthForButton = 2,
  buttonLoading = false,
  buttonDisabled = false,
  clearSearchInput,
  onKeyPress,
}) => {
  const [fetchedData, setFethedData] = useState(false);

  const onClickSearchHandle = () => {
    setFethedData(true);
    onClickSearchButton();
  };

  const handleClearSearchInput = data => {
    setFethedData(false);
    clearSearchInput(data);
  };

  const handleEnterPress = data => {
    if (data.code === 'Enter') {
      setFethedData(true);
      onKeyPress(data);
    }
  };

  const renderButtons = () => {
    if (fetchedData) {
      return (
        <>
          {searchValue?.length > searchValueLengthForButton && (
            <BlueButton loading={buttonLoading} disabled={buttonDisabled} onClick={() => onClickSearchHandle()}>
              {buttonText}
            </BlueButton>
          )}

          {!buttonLoading && (
            <>
              <div className="vertical-line" />
              <div className="clear-button" onClick={() => handleClearSearchInput({ clear: true })}>
                Clear search
              </div>
            </>
          )}
        </>
      );
    } else if (searchValue?.length > searchValueLengthForButton) {
      return (
        <>
          <BlueButton loading={buttonLoading} onClick={() => onClickSearchHandle()}>
            {buttonText}
          </BlueButton>
        </>
      );
    }
  };

  return (
    <div className="search-component-container">
      <div className="input-section-search">
        <ForesightInput
          className="tests-suites-search-input"
          value={searchValue}
          placeholder={inputPlaceholder}
          onChange={(event, data) => onChangeInputText(data)}
          onKeyPress={searchValue?.length > searchValueLengthForButton ? data => handleEnterPress(data) : null}
        />
      </div>
      <div className="button-section">{renderButtons()}</div>
    </div>
  );
};
