import React, { Component } from 'react';
import { WidgetCard } from 'components/HighlightsPage/HighlightParts/WidgetCard';
import { Tooltip } from 'components/Tooltip';
import { Icon } from 'semantic-ui-react';
import './MemoryMetrics.scss';

export class MemoryMetrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
    };
  }

  componentDidMount() {
    this.convertHistogramData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workflowMetrics.memoryMetrics.data !== this.props.workflowMetrics.memoryMetrics.data) {
      this.convertHistogramData();
    }
  }

  convertHistogramData() {
    const { metrics } = this.props.workflowMetrics.memoryMetrics.data;

    const chartData = {};
    if (metrics) {
      for (const [key] of Object.entries(metrics).sort()) {
        let obj = metrics[key];
        delete obj['memoryUsageTotal'];
        if (key) {
          chartData[key] = {
            ...obj,
            time: key,
          };
        }
      }

      const convertData = Object.values(chartData);

      this.setState({
        chartData: convertData,
      });
    } else {
      this.setState({
        chartData: [],
      });
    }
  }

  memoryContentGenerator = () => {
    return (
      <div className="memory-content-container">
        <div className="memory-content-header">
          <h4 className="memory-content-header-text">Memory Metrics</h4>
        </div>
        <div className="memory-content-body">
          <p className="memory-info-text">Memory usage (used and free) in MB</p>
          <div className="y-axis-and-graph-cont">
            <div className="y-axis-text">Memory (Mb)</div>
            <div className="memory-info-graph" />
          </div>
          <span> Time</span>
        </div>
      </div>
    );
  };

  render() {
    const { chartData } = this.state;
    const { fetching, error } = this.props.workflowMetrics.memoryMetrics;

    return (
      <div>
        <div className="memory-metrics-container">
          <div className="header-metrics-container">
            <div className="header-metrics-content ">
              <h4 className="header-metrics">Memory Metrics</h4>
              <Tooltip hoverable blackEdition content={this.memoryContentGenerator()}>
                <Icon style={{ height: '20px' }} name="info circle" />
              </Tooltip>
            </div>
          </div>
          <div className="memory-chart-container">
            <WidgetCard
              loading={fetching}
              error={error}
              chartData={chartData}
              type="Area"
              timeConfig
              hasLegend
              legendValueFormatter={entry => entry.value + ' mb'}
            />
          </div>
        </div>
      </div>
    );
  }
}
