import React, { Component } from 'react';
import './TestRunTestErrorViewer.scss';
import { ErrorStackViewer } from '../ErrorStackViewer';
import { LoadingDimmer, NoDataAvailable, SomethingError } from '../ComponentPlaceholder';
import { getTestCaseOverviewDetail } from '../../utils/test-detail-util';

class TestRunTestErrorViewer extends Component {
  getTestOverviewData = props => {
  	const { transactionId } = props.match.params;
  	const { testDetailMap } = props.testDetails;
  	return getTestCaseOverviewDetail(testDetailMap, transactionId);
  };

  render() {
  	const testOverviewObj = this.getTestOverviewData(this.props);
  	const { fetching, fetched, error, testOverview } = testOverviewObj;

  	if (fetching) {
  		return (
  			<div className="logs-loading-msg-container">
  				<LoadingDimmer msg="Loading" />
  			</div>
  		);
  	} else if (error) {
  		return (
  			<div className="error-logs-msg-container">
  				<SomethingError msg={error} />
  			</div>
  		);
  	} else if (fetched) {
  		const { errorType, errorStack, errorMessage } = testOverview;
  		return (
  			<div className={'error-viewer-container'}>
  				<div className="field-type">
            Type: <span className="value">{errorType}</span>
  				</div>
  				<div className="field-type">
            Message: <span className="value">{errorMessage}</span>
  				</div>
  				<ErrorStackViewer stack={errorStack} />
  			</div>
  		);
  	} else {
  		return (
  			<div className="no-logs-data-msg-container">
  				<NoDataAvailable msg="No Errors Found" />
  			</div>
  		);
  	}
  }
}

export default TestRunTestErrorViewer;
