import auth from 'auth/auth-factory';
import axios from 'axios';
import {
  GET_PULL_REQUESTS_META,
  SET_PULL_REQUESTS_AUTHORS_SELECTED,
  SET_PULL_REQUESTS_HIDE_FORK_BRANCH_SELECTED,
  SET_PULL_REQUESTS_LABELS_SELECTED,
  SET_PULL_REQUESTS_STATES_SELECTED,
  SET_PULL_REQUESTS_WORKFLOW_IDS_SELECTED,
  SET_PULL_REQUESTS_WORKFLOW_RUN_BRANCHES_SELECTED,
  SET_PULL_REQUESTS_WORKFLOW_RUN_CONCLUSIONS_SELECTED,
} from 'store/constants/pull-requests-filter';
import { CONFIG } from 'utils/config-util';

export function setSelectedPullRequests_WorkflowIds(workflowIds) {
  return {
    type: SET_PULL_REQUESTS_WORKFLOW_IDS_SELECTED,
    payload: { data: workflowIds },
  };
}

export function setSelectedPullRequests_States(states) {
  return {
    type: SET_PULL_REQUESTS_STATES_SELECTED,
    payload: { data: states },
  };
}

export function setSelectedPullRequests_Authors(authors) {
  return {
    type: SET_PULL_REQUESTS_AUTHORS_SELECTED,
    payload: { data: authors },
  };
}
export function setSelectedPullRequests_Labels(labels) {
  return {
    type: SET_PULL_REQUESTS_LABELS_SELECTED,
    payload: { data: labels },
  };
}

export function getPullRequests_Filters(ciProvider, repoOwner, repoName, hideForkBranch = false) {
  return {
    type: GET_PULL_REQUESTS_META,
    payload: axios.post(
      CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/pull-requests/filters`,
      {
        hideForkBranch: hideForkBranch,
      },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function setSelectedPullRequests_Conclusions(conclusion) {
  return {
    type: SET_PULL_REQUESTS_WORKFLOW_RUN_CONCLUSIONS_SELECTED,
    payload: { data: conclusion },
  };
}

export function setSelectedPullRequests_Branches(branch) {
  return {
    type: SET_PULL_REQUESTS_WORKFLOW_RUN_BRANCHES_SELECTED,
    payload: { data: branch },
  };
}

export function setSelectedPullRequests_HideFork(hideForkBranch) {
  return {
    type: SET_PULL_REQUESTS_HIDE_FORK_BRANCH_SELECTED,
    payload: { data: hideForkBranch },
  };
}
