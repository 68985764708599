import {
  GET_PR_COVERAGE_FULFILLED,
  GET_PR_COVERAGE_PENDING,
  GET_PR_COVERAGE_REJECTED,
} from '../constants/pull-request-coverage';

const initialState = {
  pullRequestCoverages: {},
  fetching: false,
  fetched: false,
  error: null,
};

export default function pullRequestCoverages(state = initialState, action) {
  switch (action.type) {
    case GET_PR_COVERAGE_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_PR_COVERAGE_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        pullRequestCoverages: action.payload.data,
      };
    case GET_PR_COVERAGE_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
