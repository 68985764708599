import {
  GET_HIGHLIGHT_FILTER_PROJECTS_PENDING,
  GET_HIGHLIGHT_FILTER_PROJECTS_FULFILLED,
  GET_HIGHLIGHT_FILTER_PROJECTS_REJECTED,
  GET_HIGHLIGHT_FILTER_REPOSITORIES_PENDING,
  GET_HIGHLIGHT_FILTER_REPOSITORIES_FULFILLED,
  GET_HIGHLIGHT_FILTER_REPOSITORIES_REJECTED,
  GET_HIGHLIGHT_FILTER_BRANCHES_PENDING,
  GET_HIGHLIGHT_FILTER_BRANCHES_FULFILLED,
  GET_HIGHLIGHT_FILTER_BRANCHES_REJECTED,
} from 'store/constants/highlights-filter';

const highlightsFilterInitalState = {
  projects: [],
  projectsFetching: false,
  projectsFetched: false,
  projectsError: false,

  repositories: [],
  repositoriesFetching: false,
  repositoriesFetched: false,
  repositoriesError: false,

  branches: [],
  branchesFetching: false,
  branchesFetched: false,
  branchesError: false,
};

export default function highlightsFilter(state = highlightsFilterInitalState, action) {
  switch (action.type) {
    case GET_HIGHLIGHT_FILTER_PROJECTS_PENDING:
      return { ...state, projectsFetching: true, projectsFetched: false, projectsError: null };
    case GET_HIGHLIGHT_FILTER_PROJECTS_FULFILLED:
      return {
        ...state,
        projectsFetching: false,
        projectsFetched: true,
        projectsError: false,
        projects: action.payload.data,
      };
    case GET_HIGHLIGHT_FILTER_PROJECTS_REJECTED:
      return {
        ...state,
        projectsFetching: false,
        projectsFetched: false,
        projectsError: action.payload,
      };

    case GET_HIGHLIGHT_FILTER_REPOSITORIES_PENDING:
      return { ...state, repositoriesFetching: true, repositoriesFetched: false, repositoriesError: null };
    case GET_HIGHLIGHT_FILTER_REPOSITORIES_FULFILLED:
      return {
        ...state,
        repositoriesFetching: false,
        repositoriesFetched: true,
        repositoriesError: false,
        repositories: action.payload.data,
      };
    case GET_HIGHLIGHT_FILTER_REPOSITORIES_REJECTED:
      return {
        ...state,
        repositoriesFetching: false,
        repositoriesFetched: false,
        repositoriesError: action.payload,
      };

    case GET_HIGHLIGHT_FILTER_BRANCHES_PENDING:
      return { ...state, branchesFetching: true, branchesFetched: false, branchesError: null };
    case GET_HIGHLIGHT_FILTER_BRANCHES_FULFILLED:
      return {
        ...state,
        branchesFetching: false,
        branchesFetched: true,
        branchesError: false,
        branches: action.payload.data,
      };
    case GET_HIGHLIGHT_FILTER_BRANCHES_REJECTED:
      return {
        ...state,
        branchesFetching: false,
        branchesFetched: false,
        branchesError: action.payload,
      };

    default:
      return state;
  }
}
