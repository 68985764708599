import { UI_ICON } from 'assets/font-icons/IconManager';
import React, { Component } from 'react';
import './ExtraWarning.scss';

export class ExtraWarning extends Component {
  render() {
    const { title, content, learnMoreURL, onClick } = this.props;
    return (
      <div className="extra-warning-container">
        <div className="content-part">
          <div className="icon-part">
            <i className={UI_ICON.CROSS.CIRCLE} />
          </div>
          <div className="text-part">
            <div className="text-part-row-1">{title}</div>
            <div className="text-part-row-2">{content}</div>
            <div className="text-part-row-3">
              <div className="learn-more-part">
                <a href={learnMoreURL} target="_blank" rel="noreferrer">
                  Learn More
                </a>
              </div>
              <div
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClick();
                }}
                className="got-it-part"
              >
                Got It!
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
