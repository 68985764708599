import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { MainLayout } from 'layouts';

function mapStateToProps(store, props) {
  return {
    organization: store.organization,
    activeSubscription: store.activeSubscription,
    ...props,
  };
}

function mapDispatchToProps() {
  return {};
}

const MainLayoutContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout));
export default MainLayoutContainer;
