import React, { Component } from 'react';
import './TestRunDetailHeader.scss';
import { getTestRunOverviewObj } from 'utils/test-run-detail-util';
import routeList, { computePath } from 'routes';
import { getTestCaseOverviewDetail } from 'utils/test-detail-util';
import { formatDateWithTime } from 'utils/date-util';
import { Popup } from 'semantic-ui-react';
import ForesightBreadcrumb from 'custom-components/Breadcrumb/Breadcrumb';
import { DOMAIN_ICON } from 'assets/font-icons/IconManager';

class TestRunDetailHeader extends Component {
  redirectProjectsPage = () => {
    this.props.history.push(routeList.projectStart.path);
  };

  redirectRepositoryPage = () => {
    this.props.history.push(routeList.repositories.path);
  };

  redirectTestRunsPage = pathData => {
    const testRunsPath = computePath(routeList.repositoryTestRuns.path, pathData);
    this.props.history.push(testRunsPath);
  };

  redirectTestRunOverviewPage = pathData => {
    const testRunOverviewPath = computePath(routeList.testRunOverview.path, pathData);
    this.props.history.push(testRunOverviewPath);
  };

  redirectTestSuiteOverviewPage = pathData => {
    const testRunSuitePath = computePath(routeList.testRunTestSuite.path, pathData);
    this.props.history.push(testRunSuitePath);
  };

  redirectTestPerformancePage = pathData => {
    const testRunPerformancePath = computePath(routeList.testRunTestPerformance.path, pathData);
    this.props.history.push(testRunPerformancePath);
  };

  redirectTestLogsPage = pathData => {
    const testRunLogsPath = computePath(routeList.testRunTestLogs.path, pathData);
    this.props.history.push(testRunLogsPath);
  };

  getTestOverviewData = props => {
    const { transactionId } = props.match.params;
    const { testDetailMap } = props.testDetails;
    return getTestCaseOverviewDetail(testDetailMap, transactionId);
  };

  createBreadCrumbDataItem = (text, data, handleOnClick) => {
    return {
      text: text,
      data: data,
      handleOnClick: handleOnClick,
    };
  };

  getTestPathData = props => {
    const { transactionId, ciProvider, repoOwner, repoName } = props.match.params;
    const { testDetailMap } = props.testDetails;
    const testOverviewObj = getTestCaseOverviewDetail(testDetailMap, transactionId);
    if (testOverviewObj && testOverviewObj.fetched) {
      const { testRunId, suiteName } = testOverviewObj.testOverview;
      return {
        testRunId: testRunId,
        testSuiteName: suiteName,
        transactionId: transactionId,
        ciProvider: ciProvider,
        repoOwner: repoOwner,
        repoName: repoName,
      };
    }
    return {};
  };

  renderBreadcrumb = () => {
    const { path } = this.props.match;
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    const data = [];
    data.push(this.createBreadCrumbDataItem('Repositories', {}, this.redirectRepositoryPage));
    data.push(
      this.createBreadCrumbDataItem(
        repoName,
        {
          ciProvider: ciProvider,
          repoOwner: repoOwner,
          repoName: repoName,
        },
        this.redirectTestRunsPage,
      ),
    );

    if (path === routeList.testRunOverview.path) {
      const { testRunId } = this.props.match.params;
      const pathData = {
        testRunId: testRunId,
        ciProvider: ciProvider,
        repoOwner: repoOwner,
        repoName: repoName,
      };
      data.push(this.createBreadCrumbDataItem('Test Run Overview', pathData, undefined));
    }

    if (path === routeList.testRunTestSuite.path) {
      const { testRunId, testSuiteName } = this.props.match.params;
      const pathData = {
        testRunId: testRunId,
        testSuiteName: testSuiteName,
        ciProvider: ciProvider,
        repoOwner: repoOwner,
        repoName: repoName,
      };
      data.push(this.createBreadCrumbDataItem('Test Run Overview', pathData, this.redirectTestRunOverviewPage));
      data.push(this.createBreadCrumbDataItem('Test Suite', pathData, undefined));
    }

    //Below Code are Test Details Path
    if (path === routeList.testRunTestPerformance.path) {
      const pathData = this.getTestPathData(this.props);
      data.push(this.createBreadCrumbDataItem('Test Run Overview', pathData, this.redirectTestRunOverviewPage));
      data.push(this.createBreadCrumbDataItem('Test Suite', pathData, this.redirectTestSuiteOverviewPage));
      data.push(this.createBreadCrumbDataItem('Test', pathData, undefined));
    }

    if (path === routeList.testRunTestErrors.path) {
      const pathData = this.getTestPathData(this.props);
      data.push(this.createBreadCrumbDataItem('Test Run Overview', pathData, this.redirectTestRunOverviewPage));
      data.push(this.createBreadCrumbDataItem('Test Suite', pathData, this.redirectTestSuiteOverviewPage));
      data.push(this.createBreadCrumbDataItem('Test', pathData, undefined));
    }

    if (path === routeList.testRunTestLogs.path) {
      const pathData = this.getTestPathData(this.props);
      data.push(this.createBreadCrumbDataItem('Test Run Overview', pathData, this.redirectTestRunOverviewPage));
      data.push(this.createBreadCrumbDataItem('Test Suite', pathData, this.redirectTestSuiteOverviewPage));
      data.push(this.createBreadCrumbDataItem('Test', pathData, undefined));
    }

    if (path === routeList.testRunTestScreenshots.path) {
      const pathData = this.getTestPathData(this.props);
      data.push(this.createBreadCrumbDataItem('Test Run Overview', pathData, this.redirectTestRunOverviewPage));
      data.push(this.createBreadCrumbDataItem('Test Suite', pathData, this.redirectTestSuiteOverviewPage));
      data.push(this.createBreadCrumbDataItem('Test', pathData, undefined));
    }

    return <ForesightBreadcrumb type="link-item" breadcrumbData={data} />;
  };

  getTestRunItemStatusIcon = status => {
    switch (status) {
      case 'Running':
        return <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.RUNNING} onClick={this.props.forceFetch} />;
      case 'Successful':
        return <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.SUCCESS_IN_CIRCLE} onClick={this.props.forceFetch} />;
      case 'Aborted':
        return <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.ABORTED} onClick={this.props.forceFetch} />;
      case 'Failed':
        return <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.FAILED} onClick={this.props.forceFetch} />;
      default:
        return;
    }
  };

  render() {
    const { testRunId } = this.props.match.params;
    const { testRunDetailMap } = this.props.testRunDetails;

    const testRunOverviewObj = getTestRunOverviewObj(testRunDetailMap, testRunId);
    const overview = testRunOverviewObj.overview || {};
    const { displayTitle, commitMessage, repo, status, startTime } = overview;
    const title = displayTitle || commitMessage;
    return (
      <>
        {this.renderBreadcrumb(repo)}
        <div className="test-run-suite-header-container">
          <div className="title">
            {this.getTestRunItemStatusIcon(status)}
            <Popup
              className="test-run-header-popup"
              position="top center"
              content={title}
              trigger={<span className="commit-msg">{title}</span>}
            />
          </div>
          <div className="start-time">{formatDateWithTime(startTime)}</div>
        </div>
      </>
    );
  }
}

export default TestRunDetailHeader;
