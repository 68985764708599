import React from 'react';
import './AverageRangeGraph.scss';
import { Tooltip } from 'components';
import { millisToMAndSNotFixed } from 'utils/date-util';

const styleObject = { zeroValue: { left: 0, marginLeft: 12 }, fullValue: { left: 100, marginRight: 12 } };

export const AverageRangeGraph = ({
  avg,
  maxValue,
  minValue,
  hasTimeValue = false,
  seeMinMaxText = false,
  extent = 'normal',
}) => {
  const calculatePercentage = (avg, min, max) => {
    if (max - min != 0) {
      const unitOfPercentage = (max - min) / 100;
      const ratio = (avg - min) / unitOfPercentage;
      return ratio;
    } else {
      return 0;
    }
  };

  const calculateStyle = () => {
    const calculatedPercentageValue = calculatePercentage(avg, minValue, maxValue);
    if (calculatedPercentageValue <= 0) {
      return styleObject.zeroValue;
    } else if (calculatedPercentageValue >= 100) {
      return styleObject.fullValue;
    } else if (calculatedPercentageValue >= 50) {
      return { left: `${calculatedPercentageValue}%`, marginLeft: '-12px' };
    } else {
      return { left: `${calculatedPercentageValue}%` };
    }
  };

  if (typeof avg === 'number' && typeof maxValue === 'number' && typeof minValue === 'number') {
    return (
      <div className={extent === 'normal' ? 'ranged-value-main-container' : 'ranged-value-main-container-long'}>
        {seeMinMaxText ? (
          <>
            <div className="first-circle">
              {' '}
              <span>{hasTimeValue ? millisToMAndSNotFixed(avg) : avg}</span>
            </div>
            <div className="second-circle">
              <span>{hasTimeValue ? millisToMAndSNotFixed(avg) : avg}</span>{' '}
            </div>
          </>
        ) : (
          <>
            <Tooltip
              blackEdition={true}
              position="top center"
              content={hasTimeValue ? millisToMAndSNotFixed(minValue) : minValue}
            >
              <div className="first-circle" />
            </Tooltip>
            <Tooltip
              blackEdition={true}
              position="top center"
              content={hasTimeValue ? millisToMAndSNotFixed(maxValue) : maxValue}
            >
              <div className="second-circle" />
            </Tooltip>
          </>
        )}

        <div className="ranged-value-line" />
        <Tooltip blackEdition={true} position="top center" content={hasTimeValue ? millisToMAndSNotFixed(avg) : avg}>
          <div className="triangle" style={calculateStyle()} />
        </Tooltip>
      </div>
    );
  } else {
    return <div>Something went wrong</div>;
  }
};
