import React, { Component } from 'react';
import { CustomModal } from 'components';
import { copyToClipBoard } from 'utils/common-util';
import { BadgeTypeEnum } from 'enums';
import { Form, Popup, Dropdown, Container, Button } from 'semantic-ui-react';
import { CONFIG } from 'utils/config-util';

import './BadgeModal.scss';
import { UI_ICON } from 'assets/font-icons/IconManager';

export default class BadgeModal extends Component {
  getBadgeTypes = () => {
    return Object.keys(BadgeTypeEnum).map(item => {
      return {
        key: BadgeTypeEnum[item].value,
        value: BadgeTypeEnum[item].value,
        text: BadgeTypeEnum[item].text,
      };
    });
  };

  getBadgePublicURL = () => {
    const { repositoryId, badgeType } = this.props;
    return `${CONFIG.publicApiUrl}/badge/${badgeType.value}?repoId=${repositoryId}`;
  };

  getBadgeMDSource = () => {
    return `[![Foresight Docs](${this.getBadgePublicURL()})](https://docs.runforesight.com/)`;
  };

  getBadgeModalComponents = () => {
    const { badgeType, onModalClose, onBadgeModalDropdownChange } = this.props;

    return (
      badgeType && (
        <Form>
          <div className="badge-modal-title">Ta da! Here your badges</div>
          <Form.Group className="badges-form-field">
            <Form.Field>
              <label>Badge Type</label>
              <Dropdown
                icon={UI_ICON.CHEVRON.DOWN}
                className="icon"
                selection
                options={this.getBadgeTypes()}
                value={badgeType && badgeType.value}
                onChange={onBadgeModalDropdownChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group className="badges-form-field">
            <Form.Field>
              <label>Preview</label>
              <img alt="preview-thundra-badge" className="badge-svg" src={this.getBadgePublicURL()} />
            </Form.Field>
          </Form.Group>
          <Form.Group className="badges-form-field">
            <Form.Field>
              <label>Markdown Code</label>
              <Container>
                <Popup
                  trigger={
                    <div className="embed-code-container" onClick={() => copyToClipBoard(this.getBadgeMDSource())}>
                      <div>{this.getBadgeMDSource()}</div>
                      <i className={UI_ICON.COPY_CODE} />
                    </div>
                  }
                  content={'Copied!'}
                  on="click"
                  position="bottom center"
                  id="copy-popup-id"
                />
              </Container>
            </Form.Field>
          </Form.Group>
          <div className="modal-actions">
            <Button onClick={onModalClose} primary className="close-modal">
              OK
            </Button>
          </div>
        </Form>
      )
    );
  };

  render() {
    const { open, onModalClose } = this.props;
    return (
      <CustomModal dimmer={false} onModalClose={onModalClose} isOpen={open} size="tiny">
        {this.getBadgeModalComponents()}
      </CustomModal>
    );
  }
}
