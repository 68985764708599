// Generator Steps Bar Chart for component
// Language: javascript
import React, { memo } from 'react';
import _ from 'lodash';
import './StepBarChart.scss';
import { Tooltip } from 'components/Tooltip';
import { millisToMAndS, formatDateWithoutMili } from 'utils/date-util';
import { hasArrayElement } from 'utils/array-util';

const MIN_DURATION = 0;

const StepBarChart = ({ data }) => {
  const calculateStepDuration = duration => {
    const durationArray = data.map(item => item.duration / 1000);
    const maxNumber = _.max(durationArray);
    if (duration && duration > 0) {
      let calculatedStepDuration = ((32 / maxNumber) * duration) / 1000;
      return calculatedStepDuration === '1' ? '2' : calculatedStepDuration + 3;
    }
    return MIN_DURATION;
  };

  const calculateDurationTooltip = (duration, createdAt) => {
    return (
      <div>
        <div>{formatDateWithoutMili(createdAt)}</div>
        <div style={{ textAlign: 'left', fontWeight: 400, fontSize: 10, color: '#DCDFE5' }}>
          {millisToMAndS(duration)}
        </div>
      </div>
    );
  };

  if (!hasArrayElement(data)) return <div>No Data</div>;

  const modifiedData = data.length > 10 ? [...data.slice(0, 10)] : [...data];

  return (
    <div className="bar-container">
      {modifiedData.map((step, index) => {
        return (
          <Tooltip
            blackEdition
            key={index}
            content={calculateDurationTooltip(step.duration, step.createdAt || step.completedAt)}
          >
            {calculateStepDuration(step.duration) === 0 ? (
              <div className="bar-column EMPTY" />
            ) : (
              <div
                key={index}
                className={`bar-column ${step.conclusion}`}
                style={{ height: calculateStepDuration(step.duration) || '0' + 'px' }}
              ></div>
            )}
          </Tooltip>
        );
      })}
    </div>
  );
};

export default memo(StepBarChart);
