import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { MenuProjects } from 'components';

function mapStateToProps() {
	return {};
}

function mapDispatchToProps() {
	return {};
}

const MenuProjectsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuProjects));
export default MenuProjectsContainer;
