import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
import React from 'react';

export const getPullRequestIcons = ({ state, draft, merged }, style) => {
  const { DRAFT: DRAFT_ICON, MERGED: MERGED_ICON, OPEN: OPEN_ICON, CLOSED: CLOSED_ICON } = DOMAIN_ICON.GITHUB.PR_STATUS;
  switch (state) {
    case PULL_REQUEST_STATUSES_ENUMS.OPEN:
      if (draft) return <i className={DRAFT_ICON + ' ' + style} />;
      else return <i className={OPEN_ICON + ' ' + style} />;
    case PULL_REQUEST_STATUSES_ENUMS.CLOSED:
      if (merged) return <i className={MERGED_ICON + ' ' + style} />;
      else return <i className={CLOSED_ICON + ' ' + style} />;
    case 'date':
      return <i className={DOMAIN_ICON.GITHUB.DATE + ' ' + style} />;
    case 'last-commit':
      return <i className={DOMAIN_ICON.GITHUB.COMMIT + ' ' + style} />;

    default:
      return <span> Not implemented {state} status icon </span>;
  }
};

export const PULL_REQUEST_STATUSES_ENUMS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  MERGED: 'MERGED',
  DRAFT: 'DRAFT',
};

export const getPullRequestStatusEnumToText = ({ state, draft, merged }) => {
  switch (state) {
    case PULL_REQUEST_STATUSES_ENUMS.OPEN:
      if (draft) return 'Draft';
      return 'Open';
    case PULL_REQUEST_STATUSES_ENUMS.CLOSED:
      if (merged) return 'Merged';
      return 'Closed';
    default:
      return state;
  }
};
