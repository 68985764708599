export const GET_ORGANIZATION_WORKFLOW_SETTINGS = 'GET_ORGANIZATION_WORKFLOW_SETTINGS';
export const GET_ORGANIZATION_WORKFLOW_SETTINGS_PENDING = 'GET_ORGANIZATION_WORKFLOW_SETTINGS_PENDING';
export const GET_ORGANIZATION_WORKFLOW_SETTINGS_FULFILLED = 'GET_ORGANIZATION_WORKFLOW_SETTINGS_FULFILLED';
export const GET_ORGANIZATION_WORKFLOW_SETTINGS_REJECTED = 'GET_ORGANIZATION_WORKFLOW_SETTINGS_REJECTED';

export const GET_ORGANIZATION_WORKFLOW_STATUS = 'GET_ORGANIZATION_WORKFLOW_STATUS';
export const GET_ORGANIZATION_WORKFLOW_STATUS_PENDING = 'GET_ORGANIZATION_WORKFLOW_STATUS_PENDING';
export const GET_ORGANIZATION_WORKFLOW_STATUS_FULFILLED = 'GET_ORGANIZATION_WORKFLOW_STATUS_FULFILLED';
export const GET_ORGANIZATION_WORKFLOW_STATUS_REJECTED = 'GET_ORGANIZATION_WORKFLOW_STATUS_REJECTED';

export const GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS = 'GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS';
export const GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS_PENDING = 'GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS_PENDING';
export const GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS_FULFILLED =
  'GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS_FULFILLED';
export const GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS_REJECTED = 'GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS_REJECTED';

export const UPDATE_ORGANIZATION_WORKFLOW_SETTINGS = 'UPDATE_ORGANIZATION_WORKFLOW_SETTINGS';
export const UPDATE_ORGANIZATION_WORKFLOW_SETTINGS_PENDING = 'UPDATE_ORGANIZATION_WORKFLOW_SETTINGS_PENDING';
export const UPDATE_ORGANIZATION_WORKFLOW_SETTINGS_FULFILLED = 'UPDATE_ORGANIZATION_WORKFLOW_SETTINGS_FULFILLED';
export const UPDATE_ORGANIZATION_WORKFLOW_SETTINGS_REJECTED = 'UPDATE_ORGANIZATION_WORKFLOW_SETTINGS_REJECTED';
