import React from 'react';
import './FilterLabelGroup.scss';

export const FilterLabelGroup = ({ clearFilterHandle, clearFilterText = 'Clear All Filters', children }) => {
  return (
    <div className="filter-label-group-container">
      <div className="filter-clear-btn" onClick={() => clearFilterHandle()}>
        {clearFilterText}
      </div>
      <span className="vertical-line" />
      {children}
    </div>
  );
};
