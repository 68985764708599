import React from 'react';
import { Calendar } from 'custom-components/Calendar/Calendar';
import './TestViewDateFilter.scss';

const dateCalculatedBefore = new Date();
dateCalculatedBefore.setDate(dateCalculatedBefore.getDate() - 30);

const dateCalculatedToday = new Date();
dateCalculatedToday.setDate(dateCalculatedToday.getDate());

export const defaultRange = {
  startDate: dateCalculatedBefore,
  endDate: dateCalculatedToday,
};

export function TestViewDateFilter(props) {
  return (
    <div className="test-views-calendar">
      <Calendar
        onChange={(event, data) => props.onChange(data)}
        type="range"
        pointing="right"
        value={[defaultRange.startDate, defaultRange.endDate]}
      />
    </div>
  );
}
