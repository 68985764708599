import { CHANGE_THEME_LIGHT, CHANGE_THEME_DARK } from 'store/constants/theme';

const themeInitialState = {
	theme: 'dark',
};

export default function changeUserInformation(state = themeInitialState, action) {
	switch (action.type) {
	case CHANGE_THEME_LIGHT:
		return {
			...state,
			theme: 'light',
		};
	case CHANGE_THEME_DARK:
		return {
			...state,
			theme: 'dark',
		};
	default:
		return state;
	}
}
