import {
  GET_INTEGRATION_PREFERENCES_PENDING,
  GET_INTEGRATION_PREFERENCES_FULFILLED,
  GET_INTEGRATION_PREFERENCES_REJECTED,
} from 'store/constants/notification-preferences';

const initialState = {
  notificationPreferences: {},
  fetching: false,
  fetched: false,
  error: null,
};

export default function notificationPreferences(state = initialState, action) {
  switch (action.type) {
    // Batch Create and Update
    case GET_INTEGRATION_PREFERENCES_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case GET_INTEGRATION_PREFERENCES_FULFILLED:
      return {
        ...state,
        notificationPreferences: action.payload.data,
        fetching: false,
        fetched: true,
        error: null,
      };
    case GET_INTEGRATION_PREFERENCES_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
