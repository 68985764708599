import React from 'react';
import './TestSuitesSidePanel.scss';
import { LoadingDimmer } from 'components/ComponentPlaceholder';
import { ForesightCheckbox } from 'custom-components/Checkbox/Checkbox';
import { Segment, TransitionablePortal } from 'semantic-ui-react';
import AccordionCheckboxTreeList from 'components/WorkflowsPage/WorkflowsFilters/CheckboxTreeList/AccordionCheckboxTreeList';
import _ from 'lodash';
import { ForesightSmallInput } from 'custom-components/Input/Input';
import { TextButton, BlueButton } from 'custom-components/Button/Button';
import { ForesightToggle } from 'custom-components/RadioButton/RadioButton';
import { hasArrayElement } from 'utils/array-util';
import { UI_ICON } from 'assets/font-icons/IconManager';

const initialState = {
  branch: [],
  showForkBranch: true,
  selectOnlyDefaultBranches: true,
  indeterminate: false,
  searchText: '',
};

const mobileStyle = {
  margin: '0',
  padding: '0',
  width: '%50',
  height: '100vh',
  zIndex: 1000,
  right: '0',
  position: 'fixed',
  top: '0%',
  backgroundColor: '#252B1F',
};

const webStyle = {
  margin: '0',
  padding: '0',
  width: '30%',
  height: '100vh',
  zIndex: 1000,
  left: '70%',
  position: 'fixed',
  top: '0%',
  backgroundColor: '#252B1F',
};

export class TestSuitesSidePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState, searchText: this.props.searchText || '' };
  }

  fetchData = () => {
    const { showForkBranch } = this.state;

    this.props.getTestSuiteOverviewListByFilters(
      {
        showForkBranch,
      },
      () => this.handleInitialFilter(),
    );
  };

  handleInitialFilter = () => {
    const { selectOnlyDefaultBranches } = this.state;
    const { setTestOverviewFilter } = this.props;
    const { filterContent } = this.props.testSuitesFilter;
    const transformedData = [];

    for (const item of filterContent) {
      const transformedItem = { repoName: item.name, repoId: item.id };
      transformedItem.branches = item.branches.map(element => {
        return {
          name: element,
          checked: selectOnlyDefaultBranches ? item.defaultBranch === element : false,
          visible: true,
        };
      });
      transformedData.push(transformedItem);
    }
    setTestOverviewFilter(transformedData);
  };

  /* A function that handles the checkbox click event. */
  handleCheckboxClick = (event, data) => {
    const { setTestOverviewFilter } = this.props;
    const { selectedFilterObject } = this.props.testSuitesFilter;
    const transformedData = [];

    for (const item of selectedFilterObject) {
      const transformedItem = { repoName: item.repoName, repoId: item.repoId };
      transformedItem.branches = item.branches.map(element => {
        return {
          name: element.name,
          checked: element.name === data.label ? this.controlCheckedState(data.label, data.checked) : element.checked,
          visible: element.visible,
        };
      });
      transformedData.push(transformedItem);
    }

    setTestOverviewFilter(transformedData);
  };

  /* A function that is called when the user clicks on the "Select All" checkbox. */
  handleSelectAllReposToggle = data => {
    const { setTestOverviewFilter } = this.props;
    const { selectedFilterObject } = this.props.testSuitesFilter;
    const transformedData = [];

    for (const item of selectedFilterObject) {
      const transformedItem = { repoName: item.repoName, repoId: item.repoId };
      transformedItem.branches = item.branches.map(element => {
        return {
          name: element.name,
          checked: data.checked
            ? item.repoName === data.label && element.visible
              ? true
              : element.checked
            : item.repoName === data.label && element.visible
            ? false
            : element.checked,
          visible: element.visible,
        };
      });
      transformedData.push(transformedItem);
    }
    this.setState({ indeterminate: true });
    setTestOverviewFilter(transformedData);
  };

  getEmptyBranchList = () => {
    return (
      <div style={{ fontSize: 14, borderBottom: '1px solid grey', padding: '5px 10px', borderRadius: '5px' }}>
        Branch Not Found
      </div>
    );
  };

  handleMainBranches = data => {
    this.setState(
      {
        selectOnlyDefaultBranches: data.checked,
        indeterminate: false,
      },
      () => this.handleInitialFilter(),
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { selectedFilterObject } = this.props.testSuitesFilter;
    if (
      !_.isEqual(selectedFilterObject, nextProps.testSuitesFilter.selectedFilterObject) ||
      this.props.isOpen !== nextProps.isOpen ||
      nextState.showForkBranch !== this.state.showForkBranch ||
      this.state.selectOnlyDefaultBranches !== nextState.selectOnlyDefaultBranches ||
      nextState.searchText !== this.state.searchText ||
      nextState.indeterminate !== this.state.indeterminate
    ) {
      return true;
    } else {
      return false;
    }
  }

  controlCheckedState = (elementName, checkedState) => {
    const { selectedFilterObject } = this.props.testSuitesFilter;
    const mainBranch = selectedFilterObject.flatMap(item => item.branches.find(item => item.name === elementName));
    if (this.state.selectOnlyDefaultBranches === true) {
      this.setState({ indeterminate: true });
    }
    const cleanArr = mainBranch.filter(Boolean);
    return cleanArr[0].name === elementName ? (cleanArr[0].checked = checkedState) : cleanArr[0].checked;
  };

  /* Rendering the branches of a repository. */
  renderBranches = () => {
    const { selectOnlyDefaultBranches, searchText } = this.state;
    const {
      selectedFilterObject,
      testSuitesOverviewFilterFetching,
      testSuitesOverviewFilterError,
      testSuitesOverviewFilterFetched,
    } = this.props.testSuitesFilter;

    if (testSuitesOverviewFilterFetching) {
      return (
        <div>
          <LoadingDimmer />
        </div>
      );
    } else if (testSuitesOverviewFilterError) {
      return <div>Error</div>;
    } else if (testSuitesOverviewFilterFetched) {
      return (
        <div className="select-branch-container">
          <label>REPOSITORIES</label>
          <ForesightCheckbox
            className="test-suites-filter-toggle"
            label={'Show only main branches'}
            indeterminate={this.state.indeterminate}
            checked={selectOnlyDefaultBranches}
            onChange={(e, data) => this.handleMainBranches(data)}
            disabled={!hasArrayElement(selectedFilterObject) || searchText !== ''}
          />
          <div>
            <ForesightSmallInput
              placeholder="Search for Branches"
              value={searchText}
              disabled={!hasArrayElement(selectedFilterObject)}
              onChange={(e, data) => this.handleOnChangeSearchText(data)}
            />
          </div>
          <div className="check-box-tree-list-cont">
            {selectedFilterObject.map(item => {
              return (
                <AccordionCheckboxTreeList
                  key={_.uniqueId()}
                  data={item.branches}
                  loading={false}
                  parentName={item.repoName || ''}
                  fetched
                  emptyJsx={this.getEmptyBranchList()}
                  handleCheckboxOnClick={this.handleCheckboxClick}
                  handleSelectAllReposToggle={this.handleSelectAllReposToggle}
                />
              );
            })}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  /* A function that is called when the checkbox is toggled. It sets the state of the component to the
opposite of what it was before. */
  handleForkBranchToggle = event => {
    this.setState(
      {
        branch: [],
        showForkBranch: event.checked,
      },
      () => this.fetchData(),
    );
  };

  /* Setting the state of the checkSaveFilter to the value of the event.checked. */
  handleSaveFilterCheckbox = event => {
    this.setState({
      checkSaveFilter: event.checked,
    });
  };

  /* Setting the state of the filterData object. */
  handleOnChangeSearchText = data => {
    const { setTestOverviewFilter } = this.props;
    const { selectedFilterObject } = this.props.testSuitesFilter;
    const transformedData = [];

    for (const item of selectedFilterObject) {
      const transformedItem = { repoName: item.repoName, repoId: item.repoId };
      transformedItem.branches = item.branches.map(element => {
        return {
          name: element.name,
          checked: element.checked,
          visible: data.value === '' ? true : element.name.includes(data.value),
        };
      });
      transformedData.push(transformedItem);
    }

    setTestOverviewFilter(transformedData);
    this.setState({
      searchText: data.value,
    });
  };

  /**
   * If the isClearFilter prop is different from the previous isClearFilter prop, and the current
   * isClearFilter prop is true, then reset the state to the initial state
   * @param prevProps - The previous props.
   */
  componentDidUpdate(prevProps) {
    if (this.props.isClearFilter !== prevProps.isClearFilter) {
      if (this.props.isClearFilter) {
        this.setState(prevState => {
          return {
            ...prevState,
            ...initialState,
          };
        });
      }
    }
  }

  render() {
    const { showForkBranch } = this.state;
    return (
      <>
        {this.props.isOpen && (
          <div
            style={{
              width: '100%',
              height: '100%',
              background: 'rgba(106, 118, 137, 0.6)',
              zIndex: '999',
              top: 0,
              left: 0,
              position: 'absolute',
            }}
          />
        )}

        <TransitionablePortal
          closeOnDocumentClick={false}
          closeOnEscape={false}
          open={this.props.isOpen}
          isClearFilter={this.props.isClearFilter}
          transition={{ animation: 'slide left', duration: 300 }}
        >
          <Segment style={window.screen.width < 480 ? mobileStyle : webStyle}>
            <div className="test-suites-filters-container">
              <div className="test-suites-filters-title">
                <span className="title-text">Filter your test suites</span>
                <span
                  className="title-icon"
                  onClick={() => {
                    this.props.closeDetailModal();
                  }}
                >
                  <i className={UI_ICON.CROSS.REPO_MODAL_CLOSE} />
                </span>
              </div>
              <div className="test-suites-filters-content-container">
                <div className="horizontal-line-test-filter" />
                <ForesightToggle
                  checked={showForkBranch}
                  fitted
                  className="radio-button"
                  label="Show Forks"
                  onChange={(e, data) => this.handleForkBranchToggle(data)}
                />
                <div className="test-suites-filters-content">{this.renderBranches()}</div>
              </div>
              <div className="filter-footer-container">
                <hr className="footer-divider"></hr>
                <div className="test-suites-filters-actions">
                  <TextButton
                    secondary
                    onClick={() => {
                      this.props.closeDetailModal();
                    }}
                  >
                    Cancel
                  </TextButton>
                  <BlueButton
                    primary
                    onClick={() => {
                      this.props.applyFilter(this.state);
                    }}
                  >
                    Apply Filter
                  </BlueButton>
                </div>
              </div>
            </div>
          </Segment>
        </TransitionablePortal>
      </>
    );
  }
}
