import { connect } from 'react-redux';
import { TestRunDetailHeader } from 'components';
import { withRouter } from 'react-router';

function mapStateToProps(store, props) {
	return {
		testRunDetails: store.testRunDetails,
		testDetails: store.testDetails,
		projects: store.projects,
		forceFetch: props.forceFetch,
	};
}

function mapDispatchToProps() {
	return {};
}

const TestRunDetailHeaderContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(TestRunDetailHeader));
export default TestRunDetailHeaderContainer;
