import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
import { Badge } from 'custom-components/Badge/Badge';
import {
  ListCardFlexRowItem,
  ListCardHeaderFilterLabel,
  ListCardInfoContainer,
  ListCardMainTextItem,
} from 'custom-components/ListCards2/ListCardItems/ListCardItems';
import {
  ListCards2Body,
  ListCards2CellContainer,
  ListCards2Container,
  ListCards2Header,
  ListCards2RowBody,
} from 'custom-components/ListCards2/ListCards2';
import React from 'react';
import routeList, { computePath } from 'routes';
import { hasArrayElement } from 'utils/array-util';
import { formatDate, formatDateWithTime, millisToMAndSNotFixed } from 'utils/date-util';
import { getTestCaseStatusIcon } from 'utils/testcase-status-icon';

const columnStyle = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  alignItems: 'center',
  letterSpacing: '-0.01em',
  marginLeft: '20px',
  color: '#788496',
};

const statusColumnStyle = {
  ...columnStyle,
  minWidth: '120px',
};

const executionColumnStyle = {
  ...columnStyle,
  minWidth: '760px',
};

const durationColumnStyle = {
  ...columnStyle,
  minWidth: '176px',
};

const commitColumnStyle = {
  ...columnStyle,
  minWidth: '160px',
};

const arrowColumnStyle = {
  ...columnStyle,
  minWidth: '24px',
};
const SORTABLE_MAP = { Duration: 'duration' };

export class TestViewTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infiniteFetching: false,
    };
  }

  componentDidMount() {
    document.getElementById('main-layout').addEventListener('scroll', this.trackScrolling);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.infiniteFetching &&
      this.props.testViewList?.fetching === true &&
      nextProps.testViewList?.fetching === false
    ) {
      this.setState({ infiniteFetching: false });
    }
  }

  componentWillUnmount() {
    document.getElementById('main-layout').removeEventListener('scroll', this.trackScrolling);
  }

  handleSortData = data => {
    if (data.field) {
      const sortField = SORTABLE_MAP[data.field.replace(/\s/g, '')];
      const sortData = {
        field: sortField,
        order: data.order,
      };
      this.props.handleSortChange(sortData);
    }
  };

  checkFetchingElements = lastPage => {
    return lastPage;
  };

  trackScrolling = () => {
    const wrappedElement = document.getElementById('main-layout');
    this.fetchWhenScrollAccessToBottom(wrappedElement);
  };

  fetchWhenScrollAccessToBottom = el => {
    if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
      const { fetching, testViewList } = this.props.testViewList;
      const { handlePageChange, pageNo } = this.props;
      if (!fetching && pageNo < testViewList.totalPages) {
        this.setState({ infiniteFetching: true });
        handlePageChange(pageNo + 1);
      }
    }
  };

  renderTestViewListItem = (index, item) => {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;

    return (
      <ListCards2RowBody
        key={index}
        withClickIcon
        onClick={() =>
          this.props.history.push(
            computePath(routeList.testRunTestPerformance.path, {
              ciProvider: ciProvider,
              repoOwner: repoOwner,
              repoName: repoName,
              testRunId: item.testRunId,
              transactionId: item.transactionId,
            }),
          )
        }
      >
        <ListCards2CellContainer>
          <ListCardFlexRowItem
            icon={getTestCaseStatusIcon(item.status, true)}
            iconTooltip={'Status'}
            desc={item.status.toLowerCase().capitalize()}
          />

          <ListCardFlexRowItem
            icon={<i className={DOMAIN_ICON.GITHUB.BRANCHES} />}
            iconTooltip={'Branch'}
            desc={item.branch}
            descTooltip={item.branch}
          />
        </ListCards2CellContainer>
        <ListCards2CellContainer>
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <ListCardMainTextItem text={formatDateWithTime(item.startTime)} />
            {item.flaky && <Badge text="flaky" badgeColor="FFDDA1" />}
          </div>
          <ListCardInfoContainer>
            <ListCardFlexRowItem
              secondary
              icon={<i className={DOMAIN_ICON.GITHUB.REPOSITORY} />}
              iconTooltip={'Repo'}
              desc={item.repoName}
              descTooltip={item.repoName}
            />

            <ListCardFlexRowItem
              secondary
              icon={<i className="icon-workflows" />}
              iconTooltip={'Worklow'}
              desc={item.workflowName}
              descTooltip={item.workflowName}
            />
            <ListCardFlexRowItem
              secondary
              icon={<i className={DOMAIN_ICON.GITHUB.JOB} />}
              iconTooltip={'Job'}
              desc={item.jobName}
              descTooltip={item.jobName}
            />
          </ListCardInfoContainer>
        </ListCards2CellContainer>
        <ListCards2CellContainer>
          <ListCardFlexRowItem
            icon={<i className="icon-elapsed-time" />}
            iconTooltip={'Duration'}
            desc={millisToMAndSNotFixed(item.duration)}
          />
        </ListCards2CellContainer>
        <ListCards2CellContainer>
          <ListCardFlexRowItem
            icon={<i className="icon-commit" />}
            iconTooltip={'Commit Id'}
            desc={item.commitHash?.slice(0, 7)}
          />
        </ListCards2CellContainer>
      </ListCards2RowBody>
    );
  };

  renderTestsViewList = () => {
    const { infiniteFetching } = this.state;
    const { sortData, selectedRange } = this.props;
    const { fetching, fetched, error, testViewList } = this.props.testViewList;
    const { content } = testViewList;

    return (
      <>
        <ListCards2Container>
          <ListCards2Header
            sortData={sortData}
            clickedSort={data => this.handleSortData(data)}
            columnsObjectsArray={[
              {
                text: 'Status',
                style: { columnStyle: statusColumnStyle },
              },
              {
                children: (
                  <div
                    className="list-cards-2-header-column-wrapper"
                    style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  >
                    <span style={{ ...columnStyle }}>Execution</span>
                    {selectedRange && (
                      <ListCardHeaderFilterLabel
                        text={formatDate(selectedRange.startTime)}
                        style={{ width: 45, fontSize: 10 }}
                        onCloseClick={() => {
                          this.props.handleChartItemSelect(-1, null);
                        }}
                      />
                    )}
                  </div>
                ),
                style: { columnStyle: executionColumnStyle },
              },
              {
                text: 'Duration',
                sortable: true,
                style: { columnStyle: durationColumnStyle },
              },
              {
                text: 'Commit',
                style: { columnStyle: commitColumnStyle },
              },
              {
                text: '',
                style: { columnStyle: arrowColumnStyle },
              },
            ]}
            className="pr-list-header"
          />
          <ListCards2Body
            fetching={fetching}
            fetched={fetched}
            error={error}
            infiniteFetching={infiniteFetching}
            tableContent={content}
          >
            {hasArrayElement(content) && content.map((el, index) => this.renderTestViewListItem(index, el))}
          </ListCards2Body>
        </ListCards2Container>
      </>
    );
  };

  renderListSection = () => {
    return <>{this.renderTestsViewList()}</>;
  };

  render() {
    return <div>{this.renderListSection()}</div>;
  }
}
