export const GET_STEP_LOG_URL = 'GET_STEP_LOG_URL';
export const GET_STEP_LOG_URL_PENDING = 'GET_STEP_LOG_URL_PENDING';
export const GET_STEP_LOG_URL_REJECTED = 'GET_STEP_LOG_URL_REJECTED';
export const GET_STEP_LOG_URL_FULFILLED = 'GET_STEP_LOG_URL_FULFILLED';

export const GET_STEP_LOG_FILE = 'GET_STEP_LOG_FILE';
export const GET_STEP_LOG_FILE_PENDING = 'GET_STEP_LOG_FILE_PENDING';
export const GET_STEP_LOG_FILE_REJECTED = 'GET_STEP_LOG_FILE_REJECTED';
export const GET_STEP_LOG_FILE_FULFILLED = 'GET_STEP_LOG_FILE_FULFILLED';

export const SIGNED_STEP_LOG_FILE_TOO_LONG = 'SIGNED_STEP_LOG_FILE_TOO_LONG';
