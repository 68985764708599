import { RepositoryPRWorkflowRunsTab } from 'components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getPullRequest_WorkflowRunsMeta } from 'store/actions/pull-request-workflow-runs-filter';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    workflowRunsMeta: store.pullRequestWorkflowRunsMeta,
  };
}

function mapDispatchToProps() {
  return {
    getWorkflowRunsMeta(ciProvider, repoOwner, repoName, prId) {
      dispatchWithExceptionHandler(getPullRequest_WorkflowRunsMeta(ciProvider, repoOwner, repoName, prId), true);
    },
  };
}

const RepositoryPRWorkflowRunsTabContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepositoryPRWorkflowRunsTab),
);
export default RepositoryPRWorkflowRunsTabContainer;
