import React, { Component } from 'react';
import { GitHubLogViewer } from 'components/GitHubLogViewer/GitHubLogViewer';
import { Tooltip } from 'components/Tooltip';
import { Button } from 'semantic-ui-react';
import { hasArrayElement } from 'utils/array-util';
import { millisToMAndS } from 'utils/date-util';
import { getWorkflowStatusIcon } from 'utils/workflow-status-icon';
import { WORKFLOWRUN_CONCLUSION } from 'utils/workflowrun-conclusion-status';
import { getWorkflowStatusTooltipContent } from 'utils/workflowrun-conclusion-status-tooltips';
import GeneratorStepsBarChart from './GeneratorStepsBarChart';
import './RepositoryWorkflowJobStepList.scss';
import { toast } from 'react-toastify';
import { ToastConfig } from 'utils/toast-util';
import { BlueButton } from 'custom-components/Button/Button';
import { UI_ICON } from 'assets/font-icons/IconManager';

const FILE_MAX_LOG_SIZE = 5000000; //5MB
const PAGE_LOG_SIZE = 1000;
export class RepositoryWorkflowJobStepList extends Component {
  generateStepKey = (jobId, stepNumber) => {
    const { workflowId } = this.props.match.params;
    return `${workflowId}/job/${jobId}/step/${stepNumber}`;
  };

  handleStepLogDisplay = (e, stepKey) => {
    e.preventDefault();
    const { stepLogOpenStatusMap } = this.props;
    const { stepLogs } = this.props.workflowStepsLogs;

    if (stepLogs[stepKey] && stepLogs[stepKey].logFileFetching) {
      toast.warn('Log File Loading continue...', ToastConfig);
    }

    if (stepLogs[stepKey] && stepLogs[stepKey].logFileFetched) {
      //LogFileFetched Open/Close
      const cloneOpenStatusMap = { ...stepLogOpenStatusMap };
      cloneOpenStatusMap[stepKey] = !cloneOpenStatusMap[stepKey];
      this.props.openStepDetails(cloneOpenStatusMap);
    } else if (stepLogs[stepKey] && stepLogs[stepKey].logFileTooLong && stepLogOpenStatusMap[stepKey]) {
      //LogFile TooLong Close
      const cloneOpenStatusMap = { ...stepLogOpenStatusMap };
      cloneOpenStatusMap[stepKey] = false;
      this.props.openStepDetails(cloneOpenStatusMap);
    } else {
      this.props.getStepSignedUrl(
        stepKey,
        resp => {
          //Sample Complex Big Size Log
          // const respData = {
          //   preSignedUrl:
          //     'https://foresight-workflow-run-log-prod.s3-accelerate.amazonaws.com/29133805/3584153808/1/9800699194/7.txt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221209T065226Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIAZFBUE7OASMSGAQE2/20221209/us-west-2/s3/aws4_request&X-Amz-Signature=d8b31b6c7d3dc275deadb760fdaf7ac60b9c386be363e4ef13a1227a1e073590',
          //   contentLength: 14016143,
          // };
          // console.log(resp.value.data);
          const respData = resp.value.data;
          if (respData?.preSignedUrl && respData?.preSignedUrl.length > 0) {
            if (respData.contentLength > FILE_MAX_LOG_SIZE) {
              this.props.signedStepLogFileTooBig(stepKey, respData.preSignedUrl);
              const { stepLogOpenStatusMap } = this.props;
              const cloneOpenStatusMap = { ...stepLogOpenStatusMap };
              cloneOpenStatusMap[stepKey] = true;
              this.props.openStepDetails(cloneOpenStatusMap);
            } else {
              this.props.getStepLogFile(stepKey, respData.preSignedUrl, false, () => {
                const { stepLogOpenStatusMap } = this.props;
                const cloneOpenStatusMap = { ...stepLogOpenStatusMap };
                cloneOpenStatusMap[stepKey] = true;
                this.props.openStepDetails(cloneOpenStatusMap);
              });
            }
          }
        },
        err => {
          console.log(err);
        },
      );
    }
  };

  renderHistory = step => {
    const { workflowRunOverview } = this.props.workflowRuns;
    const { jobHistory } = this.props.workflowRunJobHistory;
    const jobList = workflowRunOverview.jobs;
    if (!hasArrayElement(jobList)) {
      return null;
    }

    const selectedJob = jobList[this.props.selectedJobIdx];
    if (
      Object.keys(jobHistory).length > 0 &&
      jobHistory[workflowRunOverview.id + '_' + selectedJob.name]?.historyMap !== undefined
    ) {
      const data =
        jobHistory[workflowRunOverview.id + '_' + selectedJob.name].historyMap[step.name + '_' + step.number]
          ?.workflowStepDtoList;
      return <GeneratorStepsBarChart data={data || []} />;
    }
  };

  renderTooLongDownloadIcon = () => {
    return (
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1320_16853)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.61509 0.00100708H0V2.61609V38.501H5.25V5.37661L38.5661 5.25101V0.00100708H2.61509ZM23.6415 10.4613V28.5554L17.6454 22.5593L13.9471 26.2576L26.2566 38.5671L38.5661 26.2576L34.8678 22.5593L28.8717 28.5554V10.4613H23.6415ZM26.2566 56.001L56 26.2576L52.3017 22.5593L26.2566 48.6044L17.6454 39.9932L13.9471 43.6915L26.2566 56.001Z"
            fill="#5C697C"
          />
        </g>
        <defs>
          <clipPath id="clip0_1320_16853">
            <rect width="56" height="56" fill="white" transform="translate(0 0.00100708)" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  renderStepLogDetail = (stepLogObj, stepKey) => {
    if (stepLogObj.logFileTooLong) {
      return (
        <div className="too-long-file-container">
          <div className="row-icon">{this.renderTooLongDownloadIcon()}</div>
          <div className="row-header">The log size is too large</div>
          <div className="row-desc">
            The size of the log you are trying to see is too large. Please download if you want to see the logs.{' '}
          </div>
          <div className="row-btn">
            <BlueButton
              onClick={e => {
                e.preventDefault();
                window.open(stepLogObj?.logURL?.preSignedUrl, '_blank');
              }}
            >
              Download log
            </BlueButton>
          </div>
        </div>
      );
    }
    return <GitHubLogViewer logKey={stepKey} pageSize={PAGE_LOG_SIZE} logTxt={stepLogObj.logFile} />;
  };

  renderSelectedJobSteps = () => {
    const { workflowRunOverview } = this.props.workflowRuns;
    const jobList = workflowRunOverview.jobs;
    if (!hasArrayElement(jobList)) {
      return null;
    }

    const selectedJob = jobList[this.props.selectedJobIdx];

    const selectedJobStepList = selectedJob.steps;
    if (!hasArrayElement(selectedJobStepList)) {
      return null;
    }

    const stepListDom = selectedJobStepList.map((step, idx) => {
      const { stepLogOpenStatusMap } = this.props;
      const { stepLogs } = this.props.workflowStepsLogs;
      const stepKey = this.generateStepKey(selectedJob.id, step.number);
      const stepIsOpen = stepLogOpenStatusMap[stepKey];
      let stepOpenIconClassName = UI_ICON.CHEVRON.RIGHT;
      if (stepIsOpen) stepOpenIconClassName = UI_ICON.CHEVRON.DOWN;

      let stepleftIconContext = isClickableJobs(step.conclusion) ? <i className={stepOpenIconClassName} /> : '';
      if (stepLogs[stepKey] && (stepLogs[stepKey].logFileFetching || stepLogs[stepKey].logURLFetching)) {
        stepleftIconContext = <Button size="mini" style={{ backgroundColor: 'transparent' }} loading />;
      }

      function isClickableJobs(conclusion) {
        return WORKFLOWRUN_CONCLUSION.SKIPPED !== conclusion;
      }

      let stepItemClassName = 'step-item';
      if (!isClickableJobs(step.conclusion)) {
        stepItemClassName = stepItemClassName + ' not-clickable';
      } else if (stepIsOpen) {
        stepItemClassName = stepItemClassName + ' opened';
      }

      return (
        <>
          <div
            onClick={e => {
              isClickableJobs(step.conclusion) ? this.handleStepLogDisplay(e, stepKey) : '';
            }}
            className={stepItemClassName}
            key={`${idx}-${step.startedAt}`}
          >
            <div className="left-side">
              <div className="left-folding-part">{stepleftIconContext}</div>
              <Tooltip content={getWorkflowStatusTooltipContent(step)}>
                <div className="step-item-icon">{getWorkflowStatusIcon(step, '#788496')}</div>
              </Tooltip>
              <span className="step-name">{step.name}</span>
            </div>
            <div className="right-side">
              {this.renderHistory(step)}
              <span className="step-duration">{`${millisToMAndS(step.duration)}`}</span>
            </div>
          </div>
          <div className="step-item-detail">{stepIsOpen && this.renderStepLogDetail(stepLogs[stepKey], stepKey)}</div>
        </>
      );
    });

    return (
      <div>
        <div className="workflow-job-steps-header-wrapper">
          <div className="job-steps-header-wrapper">
            <div className="step-item">
              <div className="left-side">
                <div className="left-folding-part"></div>
                <div className="step-item-icon"></div>
                <span className="step-name">STEP NAME</span>
              </div>
              <div className="right-side">
                STEP HISTORY
                <span className="step-duration">LAST EXC.</span>
              </div>
            </div>
          </div>
        </div>

        <div className="workflow-job-steps-wrapper">
          <div className="job-steps-wrapper">{stepListDom}</div>
        </div>
      </div>
    );
  };

  isJobSuccessful = job => {
    if (job.conclusion === WORKFLOWRUN_CONCLUSION.SUCCESS) {
      return true;
    } else {
      // FAILURE
      return false;
    }
  };

  render() {
    return this.renderSelectedJobSteps();
  }
}
