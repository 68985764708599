import { USER_ROLES_ENUM, USER_ROLES } from './user-roles-enum';

export function isUserInInvitedUserRole(userRole) {
  return USER_ROLES_ENUM[userRole] === USER_ROLES_ENUM.USER;
}

export function isUserInInvitedBillingAdminRole(userRole) {
  const result = userRole === USER_ROLES.BILLING_ADMIN;
  //console.log(result, userRole, 'BILLING_ADMIN');
  return result;
}

export function isUserInAdminRole(userRole) {
  const result = userRole === USER_ROLES.ADMIN;
  //console.log(result, userRole, 'ADMIN');
  return result;
}
