import React, { Component } from 'react';
import { Button, Select } from 'semantic-ui-react';
import { ProjectSelectionCreationInputWithActions } from 'components';
import './ProjectSelectionDisplayAndModify.scss';
import { cloneArray } from 'utils/common-util';
import { UI_ICON } from 'assets/font-icons/IconManager';

class ProjectSelectionDisplayAndModify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProjectSelectedOrCreated: false,
      isCreateNewProjectCardActive: false,
      isSelectProjectCardActive: false,
      isCreateProjectFailed: false,
      newProjectName: '',
      isMouseOnProjectSelectDiv: false,
      projectName: '',
      isSelectProjectDropdownActive: false,
      isProjectSelectedByDropdown: false,
      createProjectCompleted: false,
    };
  }

  onGetCIProviderReposSuccess = () => {
    const { ciRepoList } = this.props.ciRepos;
    this.setState({
      newRepoList: cloneArray(ciRepoList),
    });

    if (this.props.postRedirectAction) {
      this.props.postRedirectAction();
    }
  };
  onGetCIProviderReposFail = () => {
    console.log('onGetCIProviderReposFail');
  };

  componentDidMount() {
    this.setSelectedProjectProperties();
    this.props.getAllProjects();
    this.props.getCIProviderIntegrations();
  }

  onMouseEnter = () => {
    this.setState({
      isMouseOnProjectSelectDiv: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      isMouseOnProjectSelectDiv: false,
    });
  };

  setSelectedProjectProperties = () => {
    const { projects, repos } = this.props;
    if (projects.repoSelectedProject) {
      this.setState({
        projectName: projects.repoSelectedProject.projectName,
        isProjectSelectedOrCreated: true,
      });
    } else if (repos.repoList.length === 1) {
      this.setState({
        projectName: repos.repoList[0].projectName,
        isProjectSelectedOrCreated: true,
      });
    } else if (repos.repoList.length > 1) {
      this.setProjectSelectionComponentState(false);
      this.setState({
        isSelectProjectCardActive: true,
      });
    }
  };

  handleFormChange = value => {
    this.setState({
      newProjectName: value,
    });
  };

  getCreateNonFirstProjectComponent = () => {
    return <div className="create-non-first-project-container">{this.getCreateFirstProjectComponent(false)}</div>;
  };

  handleSelectBoxChange = (e, selected) => {
    this.setState({
      isProjectSelectedByDropdown: true,
      newProjectName: selected.value,
    });
  };

  getProjectList = () => {
    const { projects } = this.props;
    let { projectList } = projects;
    const selectedProjectId = projects.repoSelectedProject ? projects.repoSelectedProject.id : undefined;
    if (selectedProjectId) {
      const selectedProjectIndexInRepoList = projectList.findIndex(x => x.id === selectedProjectId);
      // Get selected element to first place in array.
      if (selectedProjectIndexInRepoList && projectList.length > 0) {
        projectList.unshift(projects.repoSelectedProject);
        // + 1 for unShifted array.
        projectList.splice(selectedProjectIndexInRepoList + 1, 1);
      }
    }

    return projectList.map(project => {
      return {
        key: project.id,
        value: project.projectName,
        text: project.projectName,
      };
    });
  };

  setSelectProjectDropdownVisibility = () => {
    this.setState({
      isSelectProjectDropdownActive: true,
    });
  };

  doneSelectProjectByDropdown = () => {
    const { newProjectName } = this.state;
    const { projects, setRepoSelectedProject } = this.props;

    const newProjectId = projects.projectList.find(p => p.projectName === newProjectName).id;
    setRepoSelectedProject(newProjectId, this.onSetRepoSelectedProject);

    this.props.getCIProviderReposByProjectId(newProjectId);
  };

  onSetRepoSelectedProject = () => {
    const { newProjectName } = this.state;
    this.setProjectSelectionComponentState(true);

    this.setState({
      isSelectProjectDropdownActive: false,
      isProjectSelectedByDropdown: false,
      isProjectSelectedOrCreated: true,
      isMouseOnProjectSelectDiv: false,
      isSelectProjectCardActive: false,
      projectName: newProjectName,
    });
    if (this.props.postRedirectAction) {
      this.props.postRedirectAction();
    }
  };

  cancelSelectProjectByDropdown = () => {
    const { isProjectSelectedOrCreated } = this.state;

    this.setProjectSelectionComponentState(true);

    this.setState({
      isSelectProjectCardActive: isProjectSelectedOrCreated ? false : true,
      isSelectProjectDropdownActive: false,
      isMouseOnProjectSelectDiv: false,
      isCreateNewProjectCardActive: false,
      newProjectName: '',
    });
  };

  getSelectProjectComponent = () => {
    const { isMouseOnProjectSelectDiv, isSelectProjectDropdownActive, newProjectName, isProjectSelectedByDropdown } =
      this.state;

    const onSelectClose = () => {
      this.setState({
        isProjectSelectedByDropdown: true,
      });
    };

    const onSelectOpen = () => {
      this.setState({
        isProjectSelectedByDropdown: false,
      });
    };

    if (isSelectProjectDropdownActive) {
      return (
        <div className="select-project-dropdown-container">
          <Select
            placeholder="Select a project"
            className="project-list-dropdown"
            size="mini"
            value={newProjectName}
            defaultOpen={true}
            onChange={this.handleSelectBoxChange}
            options={this.getProjectList()}
            onClose={onSelectClose}
            onOpen={onSelectOpen}
          />
          {isProjectSelectedByDropdown && (
            <div className="connect-repositories-page-button-wrapper">
              <Button
                className="cancel-button"
                onClick={this.cancelSelectProjectByDropdown}
                content="Cancel"
                loading={this.props.projects.repoSelectedProjectFetching}
              />
              <Button
                primary
                className="create-project-button large-button"
                onClick={this.doneSelectProjectByDropdown}
                content="Select"
                disabled={newProjectName.length === 0}
                loading={this.props.projects.repoSelectedProjectFetching}
              />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div
          onClick={this.setSelectProjectDropdownVisibility}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          className="select-project-card"
        >
          <span>Select a project</span>
          {isMouseOnProjectSelectDiv && (
            <div className="project-actions-container">
              <div onClick={this.openCreateProjectCard} className="action create">
                + Create Project
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  setProjectSelectionComponentState = isProjectSelectionDone => {
    this.props.setProjectSelectionComponentState(isProjectSelectionDone);
  };

  openCreateProjectCard = () => {
    this.setProjectSelectionComponentState(false);
    this.setState({
      newProjectName: '',
      isCreateNewProjectCardActive: true,
      createProjectCompleted: true,
    });
  };

  getCreateProjectFinishComponent = () => {
    const { isMouseOnProjectSelectDiv, projectName } = this.state;
    const { projects } = this.props;

    return (
      <div
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className="created-project-name-card-container"
      >
        <div className="created-project-name-card">
          <div className="created-project-name">{projectName}</div>
          {isMouseOnProjectSelectDiv && (
            <div className="project-actions-container">
              <div onClick={this.openCreateProjectCard} className="action create">
                + Create Project
              </div>
              <div className="action">
                <Button
                  onClick={this.setSelectProjectCardActive}
                  disabled={projects.projectList.length < 2}
                  className="medium-button change"
                >
                  Change
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  setSelectProjectCardActive = () => {
    const { projects } = this.props;
    const { newProjectName } = this.state;
    let projectName = '';

    this.setProjectSelectionComponentState(false);

    if (newProjectName !== '') {
      projectName = newProjectName;
    } else {
      projectName = projects.repoSelectedProject ? projects.repoSelectedProject.projectName : '';
    }

    this.setState({
      newProjectName: projectName,
      isSelectProjectCardActive: true,
      isSelectProjectDropdownActive: true,
    });
  };

  onSetRepoSelectedProjectAfterCreate = () => {
    const { newProjectName } = this.state;

    this.setState({
      isProjectSelectedOrCreated: true,
      isCreateNewProjectCardActive: false,
      isSelectProjectDropdownActive: false,
      isSelectProjectCardActive: false,
      projectName: newProjectName,
      newProjectName: '',
    });

    if (this.props.postRedirectAction) {
      this.props.postRedirectAction();
    }
  };

  onCreateNewProjectSuccess = () => {
    const { projects, setRepoSelectedProject, getCIProviderReposByProjectId } = this.props;
    this.props.getAllProjects();
    setRepoSelectedProject(projects.createdProject.id, this.onSetRepoSelectedProjectAfterCreate);
    getCIProviderReposByProjectId(projects.createdProject.id);
    this.setProjectSelectionComponentState(true);

    this.setState({
      isMouseOnProjectSelectDiv: false,
      createProjectCompleted: false,
    });
  };

  onCreateNewProjectFailed = () => {
    this.setState({
      isCreateProjectFailed: true,
    });
  };

  createNewProject = () => {
    this.setState({
      isCreateProjectFailed: false,
    });

    //Validate project name is not empty
    const { newProjectName } = this.state;
    if (typeof newProjectName === 'string' && newProjectName.length > 0) {
      this.props.createNewProject(newProjectName, this.onCreateNewProjectSuccess, this.onCreateNewProjectFailed);
    } else {
      this.setState({
        isCreateProjectFailed: true,
      });
    }
  };

  getInputErrorMessage = () => {
    let message = 'Your project must have a name';

    return (
      <div className="error-input-message">
        <i className={UI_ICON.ALERT.ERROR} />
        {message}
      </div>
    );
  };

  getCreateFirstProjectComponent = isFirstProjectCreation => {
    const { isCreateProjectFailed, createProjectCompleted } = this.state;
    return (
      <ProjectSelectionCreationInputWithActions
        onInputChange={e => this.handleFormChange(e.target.value)}
        inputClassName={`first-project-input-in-box ${isCreateProjectFailed ? 'error-input' : ''} ${
          isFirstProjectCreation ? 'first' : ''
        }`}
        inputHasError={isCreateProjectFailed}
        errorMessage={this.getInputErrorMessage()}
        inputLabelClassName="first-project-input-label"
        inputTitle="Name"
        isInputRequired
        placeholder="Your project name"
        showActionButtons={createProjectCompleted}
        cancelButtonOnClick={this.cancelCreateProject}
        createButtonOnClick={this.createNewProject}
        createButtonText="Create"
        transparentDesign
        loading={this.props.projects.fetching}
      />
    );
  };

  cancelCreateProject = () => {
    const { projects } = this.props;
    let projectName = projects.repoSelectedProject ? projects.repoSelectedProject.projectName : '';

    this.setState({
      projectName: projectName,
      newProjectName: '',
      isCreateProjectFailed: false,
      isCreateNewProjectCardActive: false,
      isSelectProjectDropdownActive: false,
    });

    this.setProjectSelectionComponentState(true);
  };

  getProjectSelectionElements = () => {
    const { isProjectSelectedOrCreated, isCreateNewProjectCardActive, isSelectProjectCardActive } = this.state;

    if (!isProjectSelectedOrCreated && !isSelectProjectCardActive) {
      return this.getCreateFirstProjectComponent(true);
    } else if (isProjectSelectedOrCreated && !isCreateNewProjectCardActive && !isSelectProjectCardActive) {
      return this.getCreateProjectFinishComponent();
    } else if (isCreateNewProjectCardActive) {
      return this.getCreateNonFirstProjectComponent();
    } else if (isSelectProjectCardActive) {
      return this.getSelectProjectComponent();
    }
  };

  render() {
    return <div className="project-selection-container">{this.getProjectSelectionElements()}</div>;
  }
}

export default ProjectSelectionDisplayAndModify;
