import { GET_PR_COVERAGE, GET_PR_COVERAGE_RESULT } from 'store/constants/pull-request-coverage';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

export function getPullRequestCoverages(ciProvider, repoOwner, repoName, prId) {
  console.log(
    'API is ',
    CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/pull-requests/${prId}/coverages`,
  );
  return {
    type: GET_PR_COVERAGE,
    payload: axios.get(
      CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/pull-requests/${prId}/coverages`,
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function getPullRequestWorkflowRunLatestJobCoverageResult(workflowRunId, jobId) {
  console.log('API is ', CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/jobs/${jobId}/coverage-result`);
  return {
    type: GET_PR_COVERAGE_RESULT,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/jobs/${jobId}/coverage-result`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
