import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { GET_ORGANIZATION_SETTINGS, UPDATE_ORGANIZATION_SETTINGS } from 'store/constants/organization-settings';

export const getOrganizationSettings = organizationId => {
  return {
    type: GET_ORGANIZATION_SETTINGS,
    payload: axios.get(CONFIG.apiUrl + `/organizations/${organizationId}/settings`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};

export function updateOrganizationSettings(organizationId, settings) {
  return {
    type: UPDATE_ORGANIZATION_SETTINGS,
    payload: axios.patch(
      CONFIG.apiUrl + `/organizations/${organizationId}/settings`,
      { ...settings },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}
