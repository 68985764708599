import React, { Component } from 'react';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { SomethingError, LoadingDimmer, NoChartAvailable } from 'components';
import { computeDateFormatFromInterval } from 'utils/computeStartDateFromInterval';
import moment from 'moment';
import './TestViewChart.scss';
import { ToggleButton } from 'custom-components/Button/Button';
import { ForesightDropdown } from 'custom-components/Dropdown/Dropdown';
import { Icon } from 'semantic-ui-react';
import { Tooltip as ForesightTooltip } from 'components';
import { hasArrayElement } from 'utils/array-util';

const DROPDOWN_OPTIONS = [
  { key: 'all', text: 'all', value: 'all' },
  { key: 'p95', text: 'p95', value: 'p95' },
  { key: 'p99', text: 'p99', value: 'p99' },
  { key: 'avg', text: 'avg', value: 'avg' },
];

export class TestViewChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      durationMode: 'all',
      isExecutionActive: true,
      isDurationActive: true,
    };
  }

  renderTooltip = ({ payload, label }) => {
    let listItemsDOM = '';
    if (hasArrayElement(payload)) {
      listItemsDOM = payload
        .filter(entry => entry.value != 0)
        .map((el, index) => {
          let color = '#FFFFFF';
          let iconClass = 'legend-icon-duration';
          let value = '';
          if (el.dataKey === 'succesfullCount') {
            color = '#238636';
            value = el.value;
            iconClass = 'legend-icon-count';
          } else if (el.dataKey === 'failureCount') {
            color = '#F85149';
            value = el.value;
            iconClass = 'legend-icon-count';
          } else if (el.dataKey === 'otherCount') {
            color = '#8E8D92';
            value = el.value;
            iconClass = 'legend-icon-count';
          } else if (el.dataKey === 'avgDuration') {
            color = '#3459EF';
            value = el.value + 'ms';
            iconClass = 'legend-icon-duration';
          } else if (el.dataKey === 'd95Duration') {
            color = '#ffaa22';
            value = el.value + 'ms';
            iconClass = 'legend-icon-duration';
          } else if (el.dataKey === 'd99Duration') {
            color = '#22aabb';
            value = el.value + 'ms';
            iconClass = 'legend-icon-duration';
          }

          return (
            <li key={'item-' + index}>
              <div className="li-item" style={{ maxWidth: '300px' }}>
                <div className="li-item-left">
                  <div className={iconClass} style={{ backgroundColor: color }} />
                </div>
                <div className="li-item-right">
                  <div className="legend-name">{el.name}</div>
                  <div className="legend-value">{value}</div>
                </div>
              </div>
            </li>
          );
        });
    }

    return (
      <div className="test-view-chart-tooltip-container" style={{ height: 'auto' }}>
        {hasArrayElement(payload) && <div className="tooltip-header">{label}</div>}
        <ul>{listItemsDOM}</ul>
      </div>
    );
  };

  renderLegend = () => {
    return (
      <div className="duration-count-chart-legend">
        <div className="legend-count-part">
          <div className="successful">
            <div className="pathSuccessful"></div>
            <div className="titleSuccessful">Successful</div>
          </div>{' '}
          <div className="failure">
            <div className="pathFailure"></div>
            <div className="titleFailure">Failure</div>
          </div>
          <div className="other">
            <div className="pathOther"></div>
            <div className="titleOther">Other</div>
          </div>
        </div>

        <div className="legend-duration-part">
          <div className="p99">
            <div className="path99"></div>
            <div className="title99">p99</div>
          </div>

          <div className="p95">
            <div className="path95"></div>
            <div className="title95">p95</div>
          </div>
          <div className="pAvg">
            <div className="pathAvg"></div>
            <div className="titleAvg">Avg</div>
          </div>
        </div>
      </div>
    );
  };

  dateFormatter = time => {
    const { startDate, endDate } = this.props.range;
    const xAxisTimeFormat = computeDateFormatFromInterval(moment(startDate), moment(endDate));
    const dateFormatterString = moment(time).local().format(xAxisTimeFormat);
    return dateFormatterString;
  };

  getOpacity = entry => {
    const { selectedRange } = this.props;
    if (!selectedRange) {
      //Not Selected 0
      return 1;
    }

    if (selectedRange?.startTime === entry?.startTime) {
      return 1;
    }
    return 0.2;
  };

  renderChart = data => {
    const { durationMode, isDurationActive, isExecutionActive } = this.state;

    const flag_d99 = durationMode === 'p99' || durationMode === 'all';
    const flag_d95 = durationMode === 'p95' || durationMode === 'all';
    const flag_avg = durationMode === 'avg' || durationMode === 'all';

    return (
      <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer>
          <ComposedChart
            width={1320}
            height={308}
            data={data}
            onClick={e => {
              const index = e.activeTooltipIndex;
              this.props.handleChartItemSelect(index, data[index]);
            }}
            margin={{
              top: 25,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid horizontal={true} vertical={false} stroke="#2A3B50" />
            <XAxis
              dataKey="time"
              tick={{
                fill: '#788496',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '11x',
                lineHeight: '16px',
              }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              axisLine={false}
              tick={{
                fill: '#788496',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '11x',
                lineHeight: '16px',
              }}
              tickFormatter={val => val + 'ms'}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              axisLine={false}
              tick={{
                fill: '#788496',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '11x',
                lineHeight: '16px',
              }}
            />
            <Tooltip content={this.renderTooltip} />
            <Legend content={this.renderLegend} />

            {isExecutionActive && (
              <Bar yAxisId="left" name="Successful" barSize={72} dataKey="succesfullCount" stackId="a" fill="#238636">
                {data.map((entry, index) => (
                  <Cell key={index} fillOpacity={this.getOpacity(entry)} />
                ))}
              </Bar>
            )}
            {isExecutionActive && (
              <Bar yAxisId="left" name="Failure" barSize={72} dataKey="failureCount" stackId="a" fill="#F85149">
                {data.map((entry, index) => (
                  <Cell key={index} fillOpacity={this.getOpacity(entry)} strokeOpacity={this.getOpacity(entry)} />
                ))}
              </Bar>
            )}
            {isExecutionActive && (
              <Bar yAxisId="left" name="Other" barSize={72} dataKey="otherCount" stackId="a" fill="#8E8D92">
                {data.map((entry, index) => (
                  <Cell key={index} fillOpacity={this.getOpacity(entry)} strokeOpacity={this.getOpacity(entry)} />
                ))}
              </Bar>
            )}

            {isDurationActive && flag_avg && (
              <Line yAxisId="right" type="monotone" name="Avg" dataKey="avgDuration" stroke="#3459EF" strokeWidth={6} />
            )}
            {isDurationActive && flag_d95 && (
              <Line yAxisId="right" type="monotone" name="p95" dataKey="d95Duration" stroke="#ffaa22" strokeWidth={6} />
            )}
            {isDurationActive && flag_d99 && (
              <Line yAxisId="right" type="monotone" name="p99" dataKey="d99Duration" stroke="#22aabb" strokeWidth={6} />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  };

  render() {
    const { fetched, fetching, testViewChart } = this.props.testViewChart;
    const { durationMode } = this.state;

    if (fetching) {
      return (
        <>
          <LoadingDimmer />
        </>
      );
    } else if (fetched) {
      let dataCopy = [...testViewChart];
      let data = [];
      dataCopy.forEach(el => {
        let dObj = { ...el };
        dObj.time = this.dateFormatter(el.startTime);
        dObj.d99Duration = parseInt(el.p99Duration); //formatDuration(el.p99Duration);
        dObj.d95Duration = parseInt(el.p95Duration); //formatDuration(el.p95Duration);
        dObj.avgDuration = parseInt(el.avgDuration); //formatDuration(el.avgDuration);
        data.push(dObj);
      });

      const { isExecutionActive, isDurationActive } = this.state;

      const returnVDOM = (
        <div className="test-view-duration-and-count-chart-container">
          <div className="duration-count-chart-title-container">
            <div className="duration-count-chart-title-left">
              <div className="duration-count-chart-title">Execution And Duration</div>
              <ForesightTooltip
                blackEdition={true}
                content="Shows the distribution of duration and the execution count of test."
              >
                <span style={{ marginLeft: '3px', alignSelf: 'flex-start', display: 'flex' }}>
                  <Icon className="extra-info-tooltip-icon" name="info circle" />
                </span>
              </ForesightTooltip>
            </div>
            <div className="duration-count-chart-title-right">
              <ToggleButton
                active={isExecutionActive}
                onClick={() => this.setState({ isExecutionActive: !isExecutionActive })}
              >
                EXECUTION
              </ToggleButton>
              <ToggleButton
                active={isDurationActive}
                onClick={() => this.setState({ isDurationActive: !isDurationActive })}
              >
                DURATION
              </ToggleButton>
              <div className="test-view-duration-execution-chart-dropdown-container">
                <ForesightDropdown
                  disabled={!isDurationActive}
                  style={{ width: 70, height: 20 }}
                  options={DROPDOWN_OPTIONS}
                  value={durationMode}
                  searchable={false}
                  onChange={(e, data) => this.setState({ durationMode: data.value })}
                />
              </div>
            </div>
          </div>
          {this.renderChart(data)}
        </div>
      );

      if (data.length > 0) {
        return returnVDOM;
      } else {
        return (
          <div className="duration-and-count-chart-container">
            <NoChartAvailable />
          </div>
        );
      }
    } else {
      return (
        <div className="duration-and-count-chart-container">
          <SomethingError />
        </div>
      );
    }
  }
}
