import React from 'react';
import { Tooltip } from 'components';
import './FilterLabel.scss';

export const FilterLabel = ({ filterArray, labelTitle, filterLabelClick, textMapperFunc = false }) => {
  const renderTooltipValues = data => {
    const newArr = [];
    data.forEach((item, index) => {
      if (index < 11) {
        newArr.push(item);
      } else if (index === 11) {
        newArr.push('...more');
      } else {
        console.log('Too long');
      }
    });
    return newArr.map(item => {
      return (
        <>
          <li className="filter-label-tooltip-list-item">{textMapperFunc ? textMapperFunc(item) : item}</li>
        </>
      );
    });
  };
  return (
    <Tooltip blackEdition content={renderTooltipValues(filterArray) || 'All'}>
      <div className="filter-label-component-container" onClick={() => filterLabelClick(labelTitle)}>
        <div className="filter-show-wrapper">{labelTitle}</div>
        <div className="number-circle">{filterArray?.length}</div>
      </div>
    </Tooltip>
  );
};
