import {
  GET_PULL_REQUEST_TEST_RUNS_LIST,
  GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS,
  SET_PULL_REQUEST_TEST_RUNS_BRANCHES_SELECTED,
  SET_PULL_REQUEST_TEST_RUNS_SELECTED_HIDE_FORK,
  SET_PULL_REQUEST_TEST_RUNS_STATUSES_SELECTED,
  SET_PULL_REQUEST_TEST_RUNS_TAGS_SELECTED,
} from 'store/constants/pull-request-test-runs';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';
import { decodeRepoName } from 'utils/test-run-detail-util';

export function getPullRequestTestRunsListByFilters(ciProvider, repoOwner, name, prId, isFirstPage, hideForkBranch) {
  const repoName = decodeRepoName(name);
  return {
    meta: { isFirstPage: isFirstPage, ciProvider: ciProvider, repoOwner: repoOwner, repoName: repoName },
    type: GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS,
    payload: axios.post(
      CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/testruns/filters`,
      { hideForkBranch: hideForkBranch, pullRequestNumber: parseInt(prId + '') },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function getPullRequestTestRunsList(
  provider,
  owner,
  name,
  afterKey,
  offset,
  filter,
  prId,
  isFirstPage,
  hideForkBranch,
) {
  const repoName = decodeRepoName(name); //Pipeline%20Demo => Pipeline Demo
  return {
    meta: { isFirstPage, ciProvider: provider, repoOwner: owner, repoName: repoName },
    type: GET_PULL_REQUEST_TEST_RUNS_LIST,
    payload: axios.post(
      CONFIG.apiUrl + `/repos/${provider}/${owner}/${repoName}/testruns`,
      {
        size: offset,
        afterKey: afterKey,
        pullRequestNumber: parseInt(prId + ''),
        branches: filter ? filter.selectedTestRunsBranches : [],
        statuses: filter ? filter.selectedTestRunsStatuses : [],
        userTags: filter ? filter.selectedTestRunsTags : [],
        hideForkBranch: hideForkBranch || false,
      },
      { headers: { Authorization: 'Bearer ' + auth.getAccessToken() } },
    ),
  };
}

export function setSelectedPullRequestTestRunsHideFork(hideFork, prId) {
  return {
    type: SET_PULL_REQUEST_TEST_RUNS_SELECTED_HIDE_FORK,
    payload: { data: hideFork, prId: prId },
  };
}

export function setSelectedPullRequestTestRunsBranches(branches, prId) {
  return {
    type: SET_PULL_REQUEST_TEST_RUNS_BRANCHES_SELECTED,
    payload: { data: branches, prId: prId },
  };
}

export function setSelectedPullRequestTestRunsStatuses(statuses, prId) {
  return {
    type: SET_PULL_REQUEST_TEST_RUNS_STATUSES_SELECTED,
    payload: { data: statuses, prId: prId },
  };
}
export function setSelectedPullRequestTestRunsTags(tags) {
  return {
    type: SET_PULL_REQUEST_TEST_RUNS_TAGS_SELECTED,
    payload: { data: tags },
  };
}
