import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';
import {
  GET_FAILED_TEST_CASE,
  GET_HAVE_TEST_RUNS,
  GET_JOB_CPU_METRICS,
  GET_JOB_HISTORY,
  GET_JOB_MEMORY_METRICS,
  GET_JOB_PROCESSES,
  GET_JOB_TAB_CPU_METRICS,
  GET_JOB_TAB_DISK_METRICS,
  GET_JOB_TAB_MEMORY_METRICS,
  GET_JOB_TAB_NETWORK_METRICS,
  GET_JOB_TEST_COUNTS,
  GET_MOST_FAILED_TEST,
  GET_STEP_CPU_METRICS,
  GET_STEP_HISTORY,
  GET_STEP_MEMORY_METRICS,
  GET_STEP_PROCESSES,
  GET_STEP_TAB_CPU_METRICS,
  GET_STEP_TAB_DISK_METRICS,
  GET_STEP_TAB_MEMORY_METRICS,
  GET_STEP_TAB_NETWORK_METRICS,
  GET_WORKFLOW_HISTORY,
  GET_WORKFLOW_TIMELINE,
} from 'store/constants/workflow-timeline';

export function getWorkflowTimeline(workflowRunId) {
  return {
    type: GET_WORKFLOW_TIMELINE,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getHaveTestRuns(workflowRunId) {
  return {
    type: GET_HAVE_TEST_RUNS,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/tests/jobs`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getMostFailedTest(workflowRunId) {
  return {
    type: GET_MOST_FAILED_TEST,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/tests/failed`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getWorkflowHistory(workflowId, params) {
  const { provider, owner, repoName } = params;
  return {
    type: GET_WORKFLOW_HISTORY,
    payload: axios.get(CONFIG.apiUrl + `/repos/${provider}/${owner}/${repoName}/workflows/${workflowId}/runs/latest`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

/* TIME LINE JOB PAGE */
export function getJobTestCount(workflowRunId, jobId) {
  return {
    type: GET_JOB_TEST_COUNTS,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/jobs/${jobId}/tests/summary`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getJobCpuMetrics(workflowRunId, jobId) {
  return {
    type: GET_JOB_CPU_METRICS,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/jobs/${jobId}/metrics/cpu/average`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getJobMemoryMetrics(workflowRunId, jobId) {
  return {
    type: GET_JOB_MEMORY_METRICS,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/jobs/${jobId}/metrics/memory/average`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

// params: startTime, endTime
export function getJobHistory(workflowId, params) {
  return {
    type: GET_JOB_HISTORY,
    payload: axios.post(
      CONFIG.apiUrl +
        `/repos/${params.ciProvider}/${params.repoOwner}/${params.repoName}/workflows/${workflowId}/jobs/history`,
      { jobName: params.jobName },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

/* TIMELINE STEP PAGE */
// params: startTime, endTime
export function getStepHistory(workflowId, stepNumber, params) {
  return {
    type: GET_STEP_HISTORY,
    payload: axios.post(CONFIG.apiUrl + `/workflows/${workflowId}/step/${stepNumber}/history`, params, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getStepCpuMetrics(workflowRunId, jobId, startTime, endTime) {
  return {
    type: GET_STEP_CPU_METRICS,
    payload: axios.get(
      CONFIG.apiUrl +
        `/workflow-runs/${workflowRunId}/jobs/${jobId}/metrics/cpu/average?startTime=${startTime}&endTime=${endTime}`,
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function getStepMemoryMetrics(workflowRunId, jobId, startTime, endTime) {
  return {
    type: GET_STEP_MEMORY_METRICS,
    payload: axios.get(
      CONFIG.apiUrl +
        `/workflow-runs/${workflowRunId}/jobs/${jobId}/metrics/memory/average?startTime=${startTime}&endTime=${endTime}`,
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

/* TIMELINE JOB TAB PAGE */

export function getFailedTestCase(workflowRunId, jobId) {
  return {
    type: GET_FAILED_TEST_CASE,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/jobs/${jobId}/tests/failed`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getJobProcesses(workflowRunId, jobId, sortName) {
  return {
    type: GET_JOB_PROCESSES,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/jobs/${jobId}/spans?sort=${sortName}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getJobTabCpuMetrics(workflowRunId, jobId) {
  return {
    type: GET_JOB_TAB_CPU_METRICS,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/jobs/${jobId}/metrics/cpu`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getJobTabMemoryMetrics(workflowRunId, jobId) {
  return {
    type: GET_JOB_TAB_MEMORY_METRICS,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/jobs/${jobId}/metrics/memory`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getJobTabDiskMetrics(workflowRunId, jobId) {
  return {
    type: GET_JOB_TAB_DISK_METRICS,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/jobs/${jobId}/metrics/disk`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getJobTabNetworkMetrics(workflowRunId, jobId) {
  return {
    type: GET_JOB_TAB_NETWORK_METRICS,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/jobs/${jobId}/metrics/network`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
/* TIMELINE STEP TAB PAGE */
export function getStepProcesses(workflowRunId, jobId, sortName, startTime, endTime) {
  return {
    type: GET_STEP_PROCESSES,
    payload: axios.get(
      CONFIG.apiUrl +
        `/workflow-runs/${workflowRunId}/jobs/${jobId}/spans?sort=${sortName}&startTime=${startTime}&endTime=${endTime}`,
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function getStepTabCpuMetrics(workflowRunId, jobId, startTime, endTime) {
  return {
    type: GET_STEP_TAB_CPU_METRICS,
    payload: axios.get(
      CONFIG.apiUrl +
        `/workflow-runs/${workflowRunId}/jobs/${jobId}/metrics/cpu?startTime=${startTime}&endTime=${endTime}`,
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function getStepTabMemoryMetrics(workflowRunId, jobId, startTime, endTime) {
  return {
    type: GET_STEP_TAB_MEMORY_METRICS,
    payload: axios.get(
      CONFIG.apiUrl +
        `/workflow-runs/${workflowRunId}/jobs/${jobId}/metrics/memory?startTime=${startTime}&endTime=${endTime}`,
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function getStepTabDiskMetrics(workflowRunId, jobId, startTime, endTime) {
  return {
    type: GET_STEP_TAB_DISK_METRICS,
    payload: axios.get(
      CONFIG.apiUrl +
        `/workflow-runs/${workflowRunId}/jobs/${jobId}/metrics/disk?startTime=${startTime}&endTime=${endTime}`,
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function getStepTabNetworkMetrics(workflowRunId, jobId, startTime, endTime) {
  return {
    type: GET_STEP_TAB_NETWORK_METRICS,
    payload: axios.get(
      CONFIG.apiUrl +
        `/workflow-runs/${workflowRunId}/jobs/${jobId}/metrics/network?startTime=${startTime}&endTime=${endTime}`,
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}
