import Auth0Lock from 'auth0-lock';
import { AUTH_CONFIG } from './auth0-variables';
import { CONFIG } from 'utils/config-util';

let configurationBaseUrl = 'https://cdn.us.auth0.com';

if (CONFIG.AUTH0_CDN_URL) {
  configurationBaseUrl = CONFIG.AUTH0_CDN_URL;
}

const query = new URLSearchParams(window.location.search);

let lock = new Auth0Lock(AUTH_CONFIG.clientId, AUTH_CONFIG.domain, {
  oidcConformant: true,
  autoclose: true,
  container: 'login-container',
  avatar: null,
  defaultDatabaseConnection: AUTH_CONFIG.defaultDatabaseConnection,
  allowedConnections: AUTH_CONFIG.allowedConnectionNames,
  socialButtonStyle: 'big',
  allowForgotPassword: true,
  configurationBaseUrl: configurationBaseUrl,
  prefill: {
    // this is to prefill signup form email field when url is like;
    // https://app.runforesight.com/signup?email=sample@runforesight.com
    email: query.get('email'),
  },
  additionalSignUpFields: [
    {
      // These utm params are added to pass to hubspot if users come to signup page from marketing links.
      name: 'utmSource',
      type: 'hidden',
      validator: function () {
        return true;
      },
      value: query.get('utm_source') || 'no_source',
    },
    {
      name: 'utmMedium',
      type: 'hidden',
      validator: function () {
        return true;
      },
      value: query.get('utm_medium') || 'no_medium',
    },
    {
      name: 'utmCampaign',
      type: 'hidden',
      validator: function () {
        return true;
      },
      value: query.get('utm_campaign') || 'no_campaign',
    },
    {
      name: 'utmContent',
      type: 'hidden',
      validator: function () {
        return true;
      },
      value: query.get('utm_content') || 'no_content',
    },
  ],
  auth: {
    redirectUrl: AUTH_CONFIG.callbackUrl,
    responseType: 'token id_token',
    audience: AUTH_CONFIG.apiUrl,
    params: {
      scope: 'openid profile name email',
    },
    sso: true,
  },
  languageDictionary: {
    signUpTerms:
      'By clicking Agree & Sign up, you agree to the Thundra <a href="https://www.runforesight.com/termsofservices" rel="noopener noreferrer" target="_blank">Terms of Service.</a>',
    error: {
      signUp: {
        ER_DUP_ENTRY: 'This email is already in use',
      },
    },
    emailInputPlaceholder: 'Enter email',
    passwordInputPlaceholder: 'Enter password',
    forgotPasswordAction: 'Forgot your password?',
    signUpSubmitLabel: 'AGREE & SIGN UP',
    loginWithLabel: 'Log in with %s',
  },
});

export default lock;
