import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

import './Tooltip.scss';

export default class Tooltip extends Component {
  render() {
    const { position, content, blackEdition = false, ...restProps } = this.props;

    return (
      <>
        {blackEdition ? (
          <Popup
            hideOnScroll
            className="foresight-tooltip-black-edition"
            position={position ? position : 'bottom center'}
            trigger={this.props.children}
            inverted
            {...restProps}
          >
            <Popup.Content className="foresight-tooltip-content-black-edition">{content}</Popup.Content>
          </Popup>
        ) : (
          <Popup
            hideOnScroll
            className="foresight-tooltip"
            position={position ? position : 'bottom center'}
            trigger={this.props.children}
            basic
            {...restProps}
          >
            <Popup.Content className="foresight-tooltip-content">{content}</Popup.Content>
          </Popup>
        )}
      </>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
};
