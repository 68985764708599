import {
  GET_ORGANIZATION_SETTINGS_FULFILLED,
  GET_ORGANIZATION_SETTINGS_PENDING,
  GET_ORGANIZATION_SETTINGS_REJECTED,
  UPDATE_ORGANIZATION_SETTINGS_FULFILLED,
  UPDATE_ORGANIZATION_SETTINGS_PENDING,
  UPDATE_ORGANIZATION_SETTINGS_REJECTED,
} from '../constants/organization-settings';

const initialState = {
  organizationSettings: null,
  fetching: false,
  fetched: false,
  error: null,
};

export default function organizationSettings(state = initialState, action) {
  switch (action.type) {
    case GET_ORGANIZATION_SETTINGS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_ORGANIZATION_SETTINGS_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        organizationSettings: action.payload.data,
      };
    case GET_ORGANIZATION_SETTINGS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    case UPDATE_ORGANIZATION_SETTINGS_PENDING:
      return { ...state, organizationSettings: true, fetching: true, fetched: false, error: null };
    case UPDATE_ORGANIZATION_SETTINGS_FULFILLED:
      return {
        fetching: false,
        fetched: true,
        error: null,
        organizationSettings: action.payload.data,
      };
    case UPDATE_ORGANIZATION_SETTINGS_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
