import {
  GET_TEST_VIEW_SUMMARY_PENDING,
  GET_TEST_VIEW_SUMMARY_FULFILLED,
  GET_TEST_VIEW_SUMMARY_REJECTED,
} from 'store/constants/test-view-summary';

const initialState = {
  testViewSummary: [],
  fetching: false,
  fetched: false,
  error: null,
};

export default function testViewSummary(state = initialState, action) {
  switch (action.type) {
    case GET_TEST_VIEW_SUMMARY_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_TEST_VIEW_SUMMARY_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        testViewSummary: action.payload.data?.content,
      };
    case GET_TEST_VIEW_SUMMARY_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
