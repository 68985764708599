import reduxWebsocket from '@giantmachines/redux-websocket';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import reducer from './reducers';

//Persist Local Storage
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const reduxWebsocketMiddleware = reduxWebsocket({
  reconnectOnClose: true,
});

const StoreLogLevel = {
  NONE: 'NONE',
  ACTIONS_TYPE: 'ACTIONS_TYPE',
  ACTIONS: 'ACTIONS',
  ACTIONS_INCLUDE: 'ACTIONS_INCLUDE',
};

const IncludeActions = ['GET_WHITELIST_FULFILLED', 'GET_WHITELIST_PENDING', 'GET_WHITELIST_REJECTED'];

let storeConsoleLogLevel = StoreLogLevel.NONE; // Make it true if you want to see action logs @console!
let middleware;

switch (storeConsoleLogLevel) {
  case StoreLogLevel.ACTIONS_TYPE:
    middleware = applyMiddleware(
      promise(),
      thunk,
      reduxWebsocketMiddleware,
      createLogger({
        level: {
          prevState: false,
          nextState: false,
          action: false,
          error: false,
        },
      }),
    );
    break;
  case StoreLogLevel.ACTIONS:
    middleware = applyMiddleware(
      promise(),
      thunk,
      reduxWebsocketMiddleware,
      createLogger({
        level: {
          prevState: false,
          nextState: false,
          action: 'info',
          error: false,
        },
      }),
    );
    break;
  case StoreLogLevel.ACTIONS_INCLUDE:
    middleware = applyMiddleware(
      promise(),
      thunk,
      reduxWebsocketMiddleware,
      createLogger({
        level: {
          prevState: false,
          nextState: false,
          action: action => {
            if (IncludeActions.includes(action.type)) {
              return 'info';
            }
            return false;
          },
          error: false,
        },
      }),
    );

    break;
  case StoreLogLevel.NONE:
  default:
    middleware = applyMiddleware(promise(), thunk, reduxWebsocketMiddleware);
    break;
}

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, reducer);
const composeEnhancers =
  // eslint-disable-next-line no-undef
  process.env.NODE_ENV === 'production' || !window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const enhancer = composeEnhancers(middleware);
const store = createStore(persistedReducer, enhancer);
export default store;
export const persistor = persistStore(store);
