import React, { Component } from 'react';
import handBulb from 'assets/images/hand-bulb.svg';
import { Image } from 'semantic-ui-react';
import identitySlowFlaky from 'assets/images/identitySlowFlaky.svg';
import benefitRiskLevel from 'assets/images/benefitRiskLevel.svg';
import './GitHubActionsBenefits.scss';
import { UI_ICON } from 'assets/font-icons/IconManager';

const benefitContents = [
  {
    title: 'Know where you should write tests',
    content:
      'Foresight provides code level visibility at the PR level at every workflow run and makes it easy to write tests for the gaps.',
    icon: benefitRiskLevel,
  },
  {
    title: 'Identify slow and unreliable tests',
    content: 'See failed tests, visualize performance of your tests and see their logs.',
  },
  {
    title: 'Take control of your workflow performance',
    content: 'Visualize metrics such as CPU Load, Memory usage, Network and Disk I/O.',
    icon: identitySlowFlaky,
  },
  {
    title: 'Speed up your builds',
    content: 'Understand what happens on your workflow runs at Kernel level with process traces.',
  },
];

export class GitHubActionsBenefits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBenefitContainerOpen: false,
    };
  }

  benefitsContainerOnClick = () => {
    const { isBenefitContainerOpen } = this.state;
    this.setState({
      isBenefitContainerOpen: !isBenefitContainerOpen,
    });
  };

  getBenefitsContent = (title, content, icon) => {
    return (
      <div className="benefit-item-container-with-icon">
        <div className="benefit-item-container">
          <div className="benefit-item-title">{title}</div>
          <div className="benefit-item-content">{content}</div>
        </div>
        {icon && <Image src={icon} fluid />}
      </div>
    );
  };

  render() {
    const { isBenefitContainerOpen } = this.state;

    return (
      <div className="benefits-container" onClick={this.benefitsContainerOnClick}>
        <div className="content-container">
          <Image className="hand-bulb-icon" src={handBulb} fluid />
          <div className="header-content-container">
            <div className="header">Benefits</div>
            <div className="content">
              Learn what you'll achieve after you integrate Foresight's Test and Workflow Kits
            </div>
          </div>
          <i className={`${isBenefitContainerOpen ? UI_ICON.CHEVRON.LEFT : UI_ICON.CHEVRON.RIGHT} right-button`} />
        </div>
        {isBenefitContainerOpen &&
          benefitContents.map(benefit => {
            return this.getBenefitsContent(benefit.title, benefit.content, benefit.icon);
          })}
      </div>
    );
  }
}
