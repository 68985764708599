// eslint-disable-next-line
String.prototype.capitalize = function () {
  var target = this;
  return target.charAt(0).toUpperCase() + target.slice(1);
};

// eslint-disable-next-line
String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.split(search).join(replacement);
};

export function getTagTextAccordingToType(value) {
  if (typeof value === 'string') return `"${value}"`;
  else return `${value}`;
}

//This function Beautify unformatted JSON Objects in Text (Logic)
//1.Iterate on Text
//2.Find valid JSON Objects {}
//3.Extract Object and Parse It.
//4.Format JSON.stringify(parsed, null, 4) and embed string again
export function beautifyJSONInStr(text) {
  //First Empty Characters added if JSON start directly in String to detect first { // brace
  //{} cause recursive lock and cause locking...
  const str = ` ${text}`.replaceAll('{}', '{ }');

  let currIndex = 0;
  let endingPos = 0;
  let formattedText = '';

  while (str[currIndex]) {
    let openClose = getOpenCloseBraceIndex(str, currIndex, '{', '}');

    if (openClose) {
      let objStr = str.substr(openClose[0], openClose[1] - openClose[0] + 1);
      try {
        const parsed = JSON.parse(objStr);
        if (parsed) {
          const otherPart = str.substring(endingPos, openClose[0]);
          endingPos = openClose[0] + objStr.length;
          formattedText += otherPart + '\n' + JSON.stringify(parsed, null, 4) + '\n';
        }
        currIndex = openClose[1];
      } catch (err) {
        currIndex++;
      }
    } else currIndex++;
  }

  //Added End part
  formattedText += str.substring(endingPos, str.length);
  return formattedText.trim();
}

function getOpenCloseBraceIndex(str, indexStart, openBrace, closeBrace) {
  const openIndex = str.substr(indexStart).indexOf(openBrace) + indexStart;
  if (!openIndex) return null;

  let openCount = 1;
  let closeCount = 0;
  let currIndex = openIndex + 1;

  while (str[currIndex]) {
    if (str[currIndex] === openBrace) {
      openCount++;
      currIndex++;
      continue;
    } else if (str[currIndex] === closeBrace) {
      closeCount++;
      if (openCount === closeCount) {
        return [openIndex, currIndex];
      }
    }
    currIndex++;
  }
  return null;
}
