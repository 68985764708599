import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import { InfoTooltip, InfoTooltipContent, Tooltip } from 'components';
import { formatDateShortMonthNoYearWithTime } from 'utils/date-util';
import { LoadingDimmer } from 'components';
import routeList, { computePath } from 'routes';
import ThundraTraceChart from 'thundra-trace-chart';
import { ExtraWarning } from '../ExtraWarning/ExtraWarning';
import { IntegrationInstruction } from '../IntegrationInstruction/IntegrationInstruction';
import workflowEmptyConnect from 'assets/images/workflow-empty-connect.svg';
import { PROCESS_SPAN_STATUS } from 'utils/process-span-status';

import './RepositoryWorkflowJobProcesses.scss';
import { hasArrayElement } from 'utils/array-util';
import { ToggleTab } from 'custom-components/ToggleTab/ToggleTab';

export class RepositoryWorkflowJobProcesses extends Component {
  getProcessTableRows = () => {
    return this.props.workflowJobProcesses.data.map(process => {
      return (
        <Table.Row key="1" className="table-row">
          <Table.Cell />
          <Table.Cell className="date-row-container">
            <Tooltip blackEdition content={process.exitCode === 0 ? 'Success' : 'Failure'}>
              <div className={`status-line ${process.exitCode === 0 ? 'successed' : 'failed'}`} />
            </Tooltip>
            <div>{formatDateShortMonthNoYearWithTime(process.startTime)}</div>
          </Table.Cell>
          <Table.Cell>
            <Tooltip blackEdition content={process.name}>
              <div className="text-ellipsis">{process.name}</div>
            </Tooltip>
          </Table.Cell>
          <Table.Cell>{process.userId}</Table.Cell>
          <Table.Cell>{process.id}</Table.Cell>
          <Table.Cell>{process.parentId}</Table.Cell>
          <Table.Cell>{process.duration}</Table.Cell>
          <Table.Cell>
            <Tooltip blackEdition content={`${process.fileName} ${process.args.join(' ')}`}>
              <div className="text-ellipsis">{`${process.fileName} ${process.args.join(' ')}`}</div>
            </Tooltip>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  integrateNowOnClick = () => {
    this.props.history.push(computePath(routeList.startIntegrationGitHubTestRuns.path));
  };

  getSpansData = () => {
    const { workflowJobProcesses } = this.props;
    const spanDataArr = [];
    const data = hasArrayElement(workflowJobProcesses?.data) ? workflowJobProcesses.data : [];
    data.forEach(span => {
      spanDataArr.push({
        traceId: span.traceId,
        parentId: span.parentSpanId,
        id: span.spanId,
        serviceName: span.name,
        timestamp: span.startTime,
        duration: span.duration,
        attributes: span.attributes,
        color: '#4367FD',
        criticalPathColor: '#ffffff',
        markerColor: span.status.code === PROCESS_SPAN_STATUS.ERROR ? '#F85149' : '#238636',
      });
    });

    return spanDataArr;
  };

  getSpanAttributeValue = attribute => {
    if (attribute.key === 'process.exitCode') {
      console.log('process.exit', attribute);
    }

    if (attribute.stringValue) {
      return attribute.stringValue;
    } else if (attribute.longValue || attribute.longValue === 0) {
      return attribute.longValue;
    } else if (attribute.doubleValue || attribute.doubleValue === 0) {
      return attribute.doubleValue;
    } else if (attribute.boolValue || attribute.boolValue === 0) {
      return attribute.boolValue;
    } else if (attribute.objectValue) {
      return JSON.stringify(attribute.objectValue);
    }
  };

  getSpanDetails = tracesArr => {
    const traceDetailObj = {};
    tracesArr.map(trace => {
      traceDetailObj[`${trace.id}`] = (
        <div>
          {trace.attributes.map(att => {
            return (
              <div key={att.key}>
                {att.key}: {this.getSpanAttributeValue(att)}
              </div>
            );
          })}
        </div>
      );
    });

    return traceDetailObj;
  };

  // prepareSpansData = spanData => {
  //   return spanData.map(span => {
  //     span.errorCode = span.attributes.find(item => item.key === 'process.exitCode')?.longValue;

  //     return span;
  //   });
  // };

  getInfoText = () => {
    return (
      <InfoTooltipContent
        title="Critical path"
        desc="Critical path indicates the time spend by the process itself not their child processes."
      />
    );
  };

  render() {
    const { workflowJobProcesses, handleProcessSortChange, selectedProcessSort } = this.props;

    if (this.props.workflowJobProcesses.fetching) {
      return (
        <>
          <div className="process-trace-chart-info">
            <div className="critical-container">
              <div className="critical-line" />
              <div className="critical-line-text">Critical path</div>
              <InfoTooltip content={this.getInfoText()} />
            </div>
            <div className="sort-container">
              <ToggleTab
                title="Sort by"
                active={selectedProcessSort}
                onChange={handleProcessSortChange}
                buttons={[
                  { id: 'duration', text: 'Duration' },
                  { id: 'failure', text: 'Failure' },
                ]}
              />
            </div>
          </div>
          <div className="processes-tab-container">
            <LoadingDimmer />
          </div>
        </>
      );
    }

    const spansData = this.getSpansData();
    return workflowJobProcesses && workflowJobProcesses.fetched && workflowJobProcesses.data.length > 0 ? (
      <>
        <div className="process-trace-chart-info">
          <div className="critical-container">
            <div className="critical-line" />
            <div className="critical-line-text">Critical path</div>
            <InfoTooltip content={this.getInfoText()} />
          </div>
          <div className="sort-container">
            <ToggleTab
              title="Sort by"
              active={selectedProcessSort}
              onChange={handleProcessSortChange}
              buttons={[
                { id: 'duration', text: 'Duration' },
                { id: 'failure', text: 'Failure' },
              ]}
            />
          </div>
        </div>
        <div className="process-trace-chart-container">
          <ThundraTraceChart
            traceId={spansData[0].traceId}
            traceSummary={spansData}
            spanDetails={this.getSpanDetails(spansData)}
            closeAllSpansAtFirst={true}
            showSpanDetailTitle={false}
            showMiniTrace={false}
            showHeader={false}
            serviceNameColumnTitle="Service Name"
            spanInfoColumnTitle="Operation Name"
            showDuration={false}
          />
        </div>
      </>
    ) : (
      <>
        {!localStorage.getItem('processes-tab-got-extra-warning') && (
          <ExtraWarning
            title="Can't see your process trace?"
            content="If you have added Foresight's Workflow Kit action to your CI pipeline and can’t see your data below, check our docs to troubleshoot."
            learnMoreURL="https://docs.runforesight.com/how-to/troubleshooting"
            onClick={() => {
              localStorage.setItem('processes-tab-got-extra-warning', 'true');
              this.forceUpdate();
            }}
          />
        )}
        {
          <IntegrationInstruction
            onButtonClick={this.integrateNowOnClick}
            imageSrc={workflowEmptyConnect}
            title="Debug and optimize your pipeline"
            content="Integrate Foresight’s Workflow Kit and monitor the resource metrics and process activities of your GitHub Action workflow runs. "
            contentURL="https://docs.runforesight.com/features/workflow-runs"
            troubleshootUrl="https://docs.runforesight.com/how-to/troubleshooting"
          />
        }
      </>
    );
  }
}
