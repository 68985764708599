import { connect } from 'react-redux';
import { ProfilePage } from 'components';
import { getUserAccount } from 'store/actions/user-account';
import { updateUserProfile } from 'store/actions/user-info';
import { changeCurrentUserPassword } from 'store/actions/password';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { withRouter } from 'react-router';

function mapStateToProps(store) {
	return {
		userAccount: store.userAccount,
		userInformation: {}, // store.changeUserInformation,
		passwordInformation: {}, // store.changePassword,
	};
}

function mapDispatchToProps() {
	return {
		getUserAccount(onGetUserAccountSuccess, onGetUserAccountFail) {
			dispatchWithExceptionHandler(getUserAccount(), false, onGetUserAccountSuccess, onGetUserAccountFail);
		},
		updateUserProfile(profileInfo, onUpdateUserProfileSuccess, onUpdateUserProfileFail) {
			dispatchWithExceptionHandler(
				updateUserProfile(profileInfo),
				false,
				onUpdateUserProfileSuccess,
				onUpdateUserProfileFail,
			);
		},
		changeUserPassword(userEmail, onChangeUserPasswordSuccess, onChangeUserPasswordFail) {
			dispatchWithExceptionHandler(
				changeCurrentUserPassword(userEmail),
				false,
				onChangeUserPasswordSuccess,
				onChangeUserPasswordFail,
			);
		},
	};
}

const ProfilePageContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilePage));
export default ProfilePageContainer;
