import React, { Component } from 'react';
import './RepositoryPullRequestsFilter.scss';
import { Menu, Accordion, Icon } from 'semantic-ui-react';
import { LoadingDimmer, SomethingError } from 'components/ComponentPlaceholder';
import ForesightCheckboxList from 'custom-components/CheckboxList/CheckboxList';
import { ForesightCheckbox } from 'custom-components/Checkbox/Checkbox';
import { isEqualDeep } from 'utils/common-util';

const ACTIVATE_SCROLL_THRESHOLD = 7;

const spanStyle = {
  background: '#242c37',
  padding: '4px 8px',
  borderRadius: '50%',
  marginLeft: '7px',
  color: '#ffffff',
};
export default class RepositoryPullRequestsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStateFilterGroupCollapsed: true,
      isBranchFilterGroupCollapsed: true,
      isAuthorFilterCollapsed: true,
      isLabelsFilterCollapsed: true,
      filterListBranch: [],
    };
  }

  /* The above code is handling the checkbox click event for the workflow filter. */
  handlePRStatesCheckboxClick = (id, data) => {
    const {
      pullRequestsMeta: { states },
      selectedPullRequests_States,
    } = this.props.pullRequestsMeta;
    const copyOfIds = [...selectedPullRequests_States];
    let findItem = states.find(item => item === data.label.toUpperCase());
    if (data.checked) {
      copyOfIds.push(findItem);
      this.props.setSelectedPullRequests_States(copyOfIds);
    } else {
      const index = copyOfIds.filter(item => item !== findItem);
      this.props.setSelectedPullRequests_States(index);
    }
  };

  /* A function that is called when the select all checkbox is clicked. It is used to filter the
  workflows. */
  handlePRStatesCheckboxClickAllFilter = (data, filteredData) => {
    const newFilterStateData = filteredData.map(item => item.name.toUpperCase());
    if (data.checked) {
      this.props.setSelectedPullRequests_States(newFilterStateData);
    } else {
      this.props.setSelectedPullRequests_States([]);
    }
  };

  renderStatesFilterList = () => {
    const {
      pullRequestsMeta: { states },
      selectedPullRequests_States,
      fetching,
      fetched,
    } = this.props.pullRequestsMeta;

    let filterStateItems =
      fetched && states
        ? states?.map(el => {
            const camelCaseElement = el.charAt(0).toUpperCase() + el.slice(1).toLowerCase();
            return { checked: selectedPullRequests_States.includes(el), name: camelCaseElement };
          })
        : [];

    return (
      <ForesightCheckboxList
        searchBarPlaceholder="Search for a state"
        ellipsisLength={15}
        emptyJsx={<div style={{ marginTop: 10, fontWeight: 500 }}> State Not Found </div>}
        parentName="Select All"
        data={filterStateItems}
        loading={fetching}
        fetched={fetched}
        subHeader="State Names"
        handleCheckboxOnClick={this.handlePRStatesCheckboxClick}
        handleSelectAllToggle={this.handlePRStatesCheckboxClickAllFilter}
      />
    );
  };

  /* A function that is called when a checkbox is clicked. It is used to filter the branches. */
  handleBranchCheckboxClickFilter = (event, data) => {
    const { selectedPullRequests_WorkflowRunBranches } = this.props.pullRequestsMeta;
    if (data.checked) {
      this.setState(
        prevState => {
          return {
            ...prevState,
            filterListBranch: [...selectedPullRequests_WorkflowRunBranches, data.label],
          };
        },
        () => this.props.setSelectedPullRequests_Branches(this.state.filterListBranch),
      );
    } else {
      this.setState(
        prevState => {
          return {
            ...prevState,
            filterListBranch: selectedPullRequests_WorkflowRunBranches.filter(item => item !== data.label),
          };
        },
        () => this.props.setSelectedPullRequests_Branches(this.state.filterListBranch),
      );
    }
  };

  handleBranchCheckboxClickAllFilter = (data, filteredData) => {
    if (data.checked) {
      const newFilterBranchData = filteredData.map(item => item.name);
      this.setState(
        prevState => {
          return {
            ...prevState,
            filterListBranch: newFilterBranchData,
          };
        },
        () => this.props.setSelectedPullRequests_Branches(this.state.filterListBranch),
      );
    } else {
      this.setState(
        prevState => {
          return {
            ...prevState,
            filterListBranch: [],
          };
        },
        () => this.props.setSelectedPullRequests_Branches(this.state.filterListBranch),
      );
    }
  };

  getBranchFilterForm = () => {
    const { fetching, fetched, selectedPullRequests_WorkflowRunBranches, pullRequestsMeta } =
      this.props.pullRequestsMeta;
    if (fetched && Object.values({ pullRequestsMeta }).length > 0) {
      let componentData = pullRequestsMeta.branches || [];
      let filterBranchItems = componentData.map(item => {
        return { name: item, checked: selectedPullRequests_WorkflowRunBranches.includes(item) };
      });
      return (
        filterBranchItems &&
        !fetching && (
          <>
            <ForesightCheckboxList
              searchBarPlaceholder="Search for a branch"
              parentName="Select All"
              data={filterBranchItems}
              emptyJsx={<div style={{ marginTop: 10, fontWeight: 500 }}> Branch Not Found </div>}
              loading={fetching}
              fetched={fetched}
              subHeader="Branch Names"
              handleCheckboxOnClick={this.handleBranchCheckboxClickFilter}
              handleSelectAllToggle={this.handleBranchCheckboxClickAllFilter}
            />
          </>
        )
      );
    }
  };

  handleAuthorCheckboxClickFilter = (id, data) => {
    const { selectedPullRequests_Authors } = this.props.pullRequestsMeta;
    let statusArr = [...selectedPullRequests_Authors];
    if (data.checked) {
      statusArr.push(data?.label);

      this.props.setSelectedPullRequests_Authors(statusArr);
    } else {
      const filterArr = statusArr.filter(item => item !== data?.label);
      this.props.setSelectedPullRequests_Authors(filterArr);
    }
  };

  handleAuthorCheckboxClickAllFilter = (data, filteredData) => {
    if (data.checked) {
      const listAuthor = filteredData.map(item => item.name);
      this.props.setSelectedPullRequests_Authors(listAuthor);
    } else {
      this.props.setSelectedPullRequests_Authors([]);
    }
  };

  getFilterAuthorsCheckList = () => {
    const {
      fetching,
      fetched,
      pullRequestsMeta: { authors },
      selectedPullRequests_Authors,
    } = this.props.pullRequestsMeta;

    if (authors) {
      let filterStatusItems = authors.map(item => {
        return {
          name: item,
          checked: selectedPullRequests_Authors.includes(item),
        };
      });

      return (
        <>
          <ForesightCheckboxList
            searchBarPlaceholder="Search for an author"
            parentName="Select All"
            data={filterStatusItems}
            loading={fetching}
            fetched={fetched}
            emptyJsx={<div style={{ marginTop: 10, fontWeight: 500 }}> Author Not Found </div>}
            subHeader="AUTHORS NAMES"
            handleCheckboxOnClick={this.handleAuthorCheckboxClickFilter}
            handleSelectAllToggle={this.handleAuthorCheckboxClickAllFilter}
          />
        </>
      );
    }
  };

  getAuthorsFilterForm = () => {
    return (
      <>
        <div className="branches-box">{this.getFilterAuthorsCheckList()}</div>
        {this.props.pullRequestsMeta?.workflowRunFilters?.conclusions?.length > ACTIVATE_SCROLL_THRESHOLD && (
          <div className="scroll-shadow" />
        )}
      </>
    );
  };

  handleLabelCheckboxClickFilter = (id, data) => {
    const { selectedPullRequests_Labels } = this.props.pullRequestsMeta;
    let statusArr = [...selectedPullRequests_Labels];
    if (data.checked) {
      statusArr.push(data?.label);

      this.props.setSelectedPullRequests_Labels(statusArr);
    } else {
      const filterArr = statusArr.filter(item => item !== data?.label);
      this.props.setSelectedPullRequests_Labels(filterArr);
    }
  };

  handleLabelCheckboxClickAllFilter = (data, filteredData) => {
    if (data.checked) {
      const listLabels = filteredData.map(item => item.name);
      this.props.setSelectedPullRequests_Labels(listLabels);
    } else {
      this.props.setSelectedPullRequests_Labels([]);
    }
  };

  getFilterLabelsCheckList = () => {
    const {
      fetching,
      fetched,
      pullRequestsMeta: { labels },
      selectedPullRequests_Labels,
    } = this.props.pullRequestsMeta;

    if (labels) {
      let filterStatusItems = labels.map(item => {
        return {
          name: item,
          checked: selectedPullRequests_Labels.includes(item),
        };
      });

      return (
        <>
          <ForesightCheckboxList
            searchBarPlaceholder="Search for a Label"
            parentName="Select All"
            data={filterStatusItems}
            loading={fetching}
            fetched={fetched}
            emptyJsx={<div style={{ marginTop: 10, fontWeight: 500 }}> Label Not Found </div>}
            subHeader="LABEL NAMES"
            handleCheckboxOnClick={this.handleLabelCheckboxClickFilter}
            handleSelectAllToggle={this.handleLabelCheckboxClickAllFilter}
          />
        </>
      );
    }
  };

  handleClick = property => {
    switch (property) {
      case 'STATE': {
        this.setState({
          isStateFilterGroupCollapsed: !this.state.isStateFilterGroupCollapsed,
        });
        break;
      }
      case 'BRANCH': {
        this.setState({
          isBranchFilterGroupCollapsed: !this.state.isBranchFilterGroupCollapsed,
        });
        break;
      }
      case 'AUTHOR': {
        this.setState({
          isAuthorFilterCollapsed: !this.state.isAuthorFilterCollapsed,
        });
        break;
      }
      case 'LABEL': {
        this.setState({
          isLabelsFilterCollapsed: !this.state.isLabelsFilterCollapsed,
        });
        break;
      }
      default:
        break;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    const { ciProvider: ciProviderNext, repoOwner: repoOwnerNext, repoName: repoNameNext } = nextProps.match.params;

    const { selectedPullRequests_HideFork } = this.props.pullRequestsMeta;

    const { selectedPullRequests_HideFork: nextHideFork } = nextProps.pullRequestsMeta;

    if (ciProvider !== ciProviderNext || repoOwnerNext !== repoOwner || repoNameNext !== repoName) {
      this.props.getPullRequests_Filters(ciProviderNext, repoOwnerNext, repoNameNext, selectedPullRequests_HideFork);
    }

    if (!isEqualDeep(selectedPullRequests_HideFork, nextHideFork)) {
      this.props.getPullRequests_Filters(ciProviderNext, repoOwnerNext, repoNameNext, nextHideFork);
    }
  }

  render() {
    const { fetching, fetched, error } = this.props.pullRequestsMeta;
    const {
      isStateFilterGroupCollapsed,
      isBranchFilterGroupCollapsed,
      isAuthorFilterCollapsed,
      isLabelsFilterCollapsed,
    } = this.state;

    const {
      selectedPullRequests_WorkflowRunBranches,
      selectedPullRequests_Authors,
      selectedPullRequests_Labels,
      selectedPullRequests_States,
      selectedPullRequests_HideFork,
    } = this.props.pullRequestsMeta;

    if (fetched) {
      return (
        <div className="workflow-runs-filter-container">
          <div className="workflow-runs-filter-head-container">
            <div className="filter-by-title">Filter By</div>
            <div className="filter-by-checkbox">
              <ForesightCheckbox
                checked={selectedPullRequests_HideFork}
                label={'Hide forks'}
                onChange={(e, data) => this.props.setSelectedPullRequests_HideFork(data.checked)}
              />
            </div>
          </div>
          <div className="workflow-filters">
            <Accordion as={Menu} vertical>
              <Menu.Item className="border-box">
                <Accordion.Title index={0} onClick={() => this.handleClick('BRANCH')}>
                  BRANCH
                  {selectedPullRequests_WorkflowRunBranches?.length > 0 && (
                    <span style={spanStyle}>{selectedPullRequests_WorkflowRunBranches.length}</span>
                  )}
                  <Icon name={!isBranchFilterGroupCollapsed ? 'angle up' : 'angle down'} />
                </Accordion.Title>
                <Accordion.Content active={!isBranchFilterGroupCollapsed} content={this.getBranchFilterForm()} />
              </Menu.Item>
              <Menu.Item id="workflows-filter-group" className="border-box">
                <Accordion.Title index={1} onClick={() => this.handleClick('STATE')}>
                  STATES{' '}
                  {selectedPullRequests_States?.length > 0 && (
                    <span style={spanStyle}>{selectedPullRequests_States.length}</span>
                  )}
                  <Icon name={!isStateFilterGroupCollapsed ? 'angle up' : 'angle down'} />
                </Accordion.Title>
                <Accordion.Content active={!isStateFilterGroupCollapsed} content={this.renderStatesFilterList()} />
              </Menu.Item>
              <Menu.Item className="border-box">
                <Accordion.Title index={2} onClick={() => this.handleClick('AUTHOR')}>
                  AUTHORS
                  {selectedPullRequests_Authors?.length > 0 && (
                    <span style={spanStyle}>{selectedPullRequests_Authors.length}</span>
                  )}
                  <Icon name={!isAuthorFilterCollapsed ? 'angle up' : 'angle down'} />
                </Accordion.Title>
                <Accordion.Content
                  className="accordion-content-container"
                  active={!isAuthorFilterCollapsed}
                  content={this.getAuthorsFilterForm()}
                />
              </Menu.Item>
              <Menu.Item className="border-box">
                <Accordion.Title index={2} onClick={() => this.handleClick('LABEL')}>
                  LABELS
                  {selectedPullRequests_Labels?.length > 0 && (
                    <span style={spanStyle}>{selectedPullRequests_Labels.length}</span>
                  )}
                  <Icon name={!isLabelsFilterCollapsed ? 'angle up' : 'angle down'} />
                </Accordion.Title>
                <Accordion.Content
                  className="accordion-content-container"
                  active={!isLabelsFilterCollapsed}
                  content={this.getFilterLabelsCheckList()}
                />
              </Menu.Item>
            </Accordion>
          </div>
        </div>
      );
    } else if (fetching) {
      return (
        <div className="workflow-runs-filter-container">
          <div className="workflow-runs-filter-head-container">
            <div className="filter-by-title">Filter By</div>
            <div className="filter-by-checkbox">
              <ForesightCheckbox
                checked={selectedPullRequests_HideFork}
                label={'Hide forks'}
                onChange={(e, data) => this.props.setSelectedPullRequests_HideFork(data.checked)}
              />
            </div>
          </div>
          <div className="workflow-filters" style={{ marginTop: 50 }}>
            <LoadingDimmer msg={'Loading...'} />
          </div>
        </div>
      );
    } else if (error) {
      return (
        <div className="workflow-runs-filter-container">
          <div className="filter-by-title">Filter By</div>
          <div className="workflow-filters">
            <SomethingError msg={error} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="workflow-runs-filter-container">
          <div className="filter-by-title">Filter By</div>
          <div className="workflow-filters">
            <LoadingDimmer msg={'Unknown State'} />
          </div>
        </div>
      );
    }
  }
}
