import {
  //   GET_WORKFLOW_VIEW,
  GET_WORKFLOW_VIEW_PENDING,
  GET_WORKFLOW_VIEW_REJECTED,
  GET_WORKFLOW_VIEW_FULFILLED,
} from 'store/constants/workflow-view';

const workflowViewInitialState = {
  workflowView: {},
  fetching: false,
  error: false,
  fetched: false,
  testData: true,
  isProjectSelectionDone: true,
};

export default function workflowView(state = workflowViewInitialState, action) {
  switch (action.type) {
    case GET_WORKFLOW_VIEW_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_WORKFLOW_VIEW_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: true,
        workflowView: {},
      };
    case GET_WORKFLOW_VIEW_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        workflowView: action.payload.data,
      };
    default:
      return state;
  }
}
