import React, { Component } from 'react';
import './VerticalDelimiterGroup.scss';

export default class VerticalDelimiterGroup extends Component {
	render() {
		return (
			<>
				<div className="circle-delimiter" />
				<div className={`vertical-line ${this.props.verticalLineSize}`} />
				<div className="circle-delimiter" />
			</>
		);
	}
}
