import { RepositoryPRWorkflowRunsFilterContainer, RepositoryPRWorkflowRunsListContainer } from 'containers';
import React, { Component } from 'react';
import routeList, { computePath } from 'routes';
import { Button } from 'semantic-ui-react';
import { hasArrayElement } from 'utils/array-util';
import './RepositoryPRWorkflowRunsTab.scss';
import { ListCardsContainer, ListCardsHeader } from 'custom-components/ListCards/ListCards';

let columnStyle = {
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '20px',
  alignItems: 'center',
  letterSpacing: '-0.01em',
  color: '#788496',
};
export default class RepositoryPRWorkflowRunsTab extends Component {
  componentDidMount() {
    const { ciProvider, repoOwner, repoName, prId } = this.props.match.params;
    if (prId) {
      this.props.getWorkflowRunsMeta(ciProvider, repoOwner, repoName, prId);
    } else {
      this.props.getWorkflowRunsMeta(ciProvider, repoOwner, repoName);
    }
  }

  renderContentWithData = () => {
    const { prId } = this.props.match.params;
    return (
      <div className="workflow-runs">
        <div className="left-part">
          <RepositoryPRWorkflowRunsFilterContainer prId={prId} />
        </div>
        <div id="workflow-runs-scrollable" className="right-part">
          <ListCardsContainer style={{ width: '100%' }} withBorder>
            <ListCardsHeader
              columnsObjectsArray={[
                { text: 'Status', style: { columnStyle } },
                { text: 'Workflow Run', style: { columnStyle } },
                {
                  text: 'Triggered By',
                  style: { columnStyle },
                },
                {
                  text: 'Commit',
                  style: { columnStyle },
                },
              ]}
              className="workflowrun-list-header"
            />
            <RepositoryPRWorkflowRunsListContainer prId={prId} />
          </ListCardsContainer>
        </div>
      </div>
    );
  };

  handleGetStartedRedirection = e => {
    e.preventDefault();
    this.props.history.push(computePath(routeList.startCreateProjectSelectGithubRepos.path));
  };

  renderContentWithoutData = () => {
    return (
      <div className="workflow-no-runs">
        <div className="msg-container">
          <div className="main-msg">You haven’t monitored a workflow run yet</div>
          <div className="sub-msg">To start monitoring your test runs, integrate Foresight agent</div>
          <div className="actions">
            <Button onClick={this.handleGetStartedRedirection} className="get-started-btn">
              Get started
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderContent = () => {
    const { workflowRunsMeta, fetched } = this.props.workflowRunsMeta;
    if (fetched && !hasArrayElement(workflowRunsMeta)) {
      return this.renderContentWithoutData();
    } else {
      return this.renderContentWithData();
    }
  };

  render() {
    return <div className="repository-pr-workflow-runs-tab-container">{this.renderContent()}</div>;
  }
}
