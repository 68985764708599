import { NoDataAvailable } from 'components/ComponentPlaceholder';
import { PageSubHeader, PageSubHeaderText, PageSubHeaderTitle } from 'custom-components/PageSubHeader/PageSubHeader';
import React from 'react';
import { hasArrayElement } from 'utils/array-util';
import './ManageCommitters.scss';
class ManageCommitters extends React.Component {
  render() {
    const { activeSubscription } = this.props.activeSubscription;
    const committerJSX = hasArrayElement(activeSubscription.activeCommitters) ? (
      activeSubscription.activeCommitters.map((committer, index) => {
        return (
          <div className="committer-container" key={index}>
            <div className="committer-img-container">
              <img className="avatar" src={committer.avatarUrl} alt={committer.login} />
            </div>
            <div className="committer-username">{committer.login}</div>
          </div>
        );
      })
    ) : (
      <div style={{ marginTop: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {' '}
        <NoDataAvailable msg="There is no committers"></NoDataAvailable>
      </div>
    );

    const activeCommiterCount = hasArrayElement(activeSubscription.activeCommitters)
      ? activeSubscription.activeCommitters.length
      : 0;

    return (
      <div className="manage-committers-container">
        <PageSubHeader style={{ paddingTop: 0, paddingBottom: 0 }}>
          <PageSubHeaderTitle>Active Committers ({activeCommiterCount})</PageSubHeaderTitle>
          <PageSubHeaderText>
            The list of active committers of your private repositories which are watched by Foresight.{' '}
          </PageSubHeaderText>
        </PageSubHeader>

        <div className="manage-committer-list">{committerJSX}</div>
      </div>
    );
  }
}

export default ManageCommitters;
