import React from 'react';
import { Button } from 'semantic-ui-react';
import './ToggleTab.scss';

export function ToggleTab(props) {
  const handleButtonClick = (e, id) => {
    e.preventDefault();
    props.onChange(id);
  };

  return (
    <div className="toggle-tab-container">
      <div className="toggle-tab-title">{props.title}</div>
      <Button.Group className="toggle-tab">
        {props.buttons.map(el => (
          <Button
            onClick={e => {
              handleButtonClick(e, el.id);
            }}
            active={el.id === props.active}
            className="toggle-tab-button"
            key={el.id}
          >
            {el.text}
          </Button>
        ))}
      </Button.Group>
    </div>
  );
}
