import { DOMAIN_ICON, UI_ICON } from 'assets/font-icons/IconManager';
import { Tooltip } from 'components/Tooltip';
import React, { useEffect } from 'react';
import { MiniCard } from '../../MiniCard/MiniCard';
import { BlueButton } from 'custom-components/Button/Button';
import './JobSummary.scss';
import { calculatePercentage } from 'components/RepositoryWorkflowRunTimelinePage/util/Util';
import { LoadingDimmer } from 'components/ComponentPlaceholder';
import StepBarChart from '../../BarChart/StepBarChart';
import { millisToMAndSNotFixed } from 'utils/date-util';

export const JobSummary = ({ workflowRunId, jobId, jobSummaryProps, onClickDetails }) => {
  const {
    workflowRunTimeline: {
      workflowRunTimelineTraceChart: {
        jobs,
        workflow: { id },
      },
      jobHistoryContent,
      jobTestCountContent,
      jobCpuMetricsContent,
      jobMemoryMetricsContent,
      jobHistoryContentFetching,
    },
  } = jobSummaryProps;

  const foundJob = jobs.find(item => item.id === jobId);

  useEffect(() => {
    const { getJobTestCount, getJobCpuMetrics, getJobMemoryMetrics, getJobHistory } = jobSummaryProps;
    const { repoName, ciProvider, repoOwner } = jobSummaryProps.match.params;

    getJobTestCount(workflowRunId, jobId);
    getJobCpuMetrics(workflowRunId, jobId);
    getJobMemoryMetrics(workflowRunId, jobId);
    getJobHistory(id, { jobName: foundJob.name, repoName, ciProvider, repoOwner });
  }, [jobId]);

  return (
    <>
      <div className="wf-run-summary-title">Job Summary</div>
      <div className="wf-run-summary-container">
        <div className="wf-run-job-summary-name-cont">
          <div className="icon-cont">
            <i className={`${DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS[foundJob.conclusion]}`} />
          </div>
          <Tooltip blackEdition content={foundJob.name}>
            <div className="text-cont">{foundJob.name}</div>
          </Tooltip>
        </div>

        <MiniCard title="JOB HISTORY">
          <div className="mini-card-sub-info" style={{ marginBottom: 12 }}>
            {millisToMAndSNotFixed(foundJob?.duration)}
            <div className="mini-card-title-not-uppercase">Latest Execution Time</div>
          </div>
          {jobHistoryContentFetching ? (
            <LoadingDimmer />
          ) : (
            <>
              <StepBarChart data={jobHistoryContent || []} />
              <div className="mini-card-title-not-uppercase">Job Execution History</div>
            </>
          )}
        </MiniCard>
        <MiniCard title={'TESTS'}>
          <div className="wf-run-job-test-summary-info-cont">
            <div className="wf-run-job-test-summary-info">
              <i className={`${DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.CHECK} test-run-check`} />
              <div className="wf-run-job-test-count">{jobTestCountContent.successfulCount || 0}</div>
            </div>
            <div className="wf-run-job-test-summary-info">
              <i className={`${DOMAIN_ICON.GITHUB.TEST_STATUS.FAILED} test-run-failed`} />
              <div className="wf-run-job-test-count">{jobTestCountContent.failedCount || 0}</div>
            </div>
          </div>
          <BlueButton
            onClick={() => {
              onClickDetails();
            }}
            className="wf-run-job-sm-btn"
          >
            See more <i className={UI_ICON.REDIRECTION.DEFAULT} />
          </BlueButton>
        </MiniCard>
        <MiniCard title={'METRICS'}>
          <div className="job-metric-container">
            <div className="memory-usage-active-job">
              <div className="value">
                {jobCpuMetricsContent.cpuLoadTotal
                  ? `${calculatePercentage(
                      jobCpuMetricsContent.cpuLoadSystem,
                      jobCpuMetricsContent.cpuLoadUser,
                      jobCpuMetricsContent.cpuLoadTotal,
                    )}
                %`
                  : 'No data'}
              </div>
              <div className="sub-text">CPU Load System</div>
            </div>
            <div className="memory-usage-active-job">
              <div className="value">
                {jobMemoryMetricsContent.memoryUsageTotal
                  ? `${jobMemoryMetricsContent.memoryUsageActive} mb (
                  ${calculatePercentage(
                    jobMemoryMetricsContent.memoryUsageActive,
                    jobMemoryMetricsContent.memoryUsageAvailable,
                    jobMemoryMetricsContent.memoryUsageTotal,
                  )}
                  %)`
                  : 'No data'}
              </div>

              <div className="sub-text">Active Memory Usage</div>
            </div>
          </div>
          <div className="job-process">
            <div className="mini-card-title">PROCESS</div>

            <div
              onClick={() => {
                onClickDetails('processes');
              }}
              className="link-text"
            >
              View process
            </div>
          </div>
        </MiniCard>
      </div>
    </>
  );
};
