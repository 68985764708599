import React, { Component } from 'react';
import './GitHubAppInstallmentTriggerPage.scss';
import { PopupPageDesignCloseIconHeaderContainer, UserInvitationModalContainer } from 'containers';
import { Button, Image } from 'semantic-ui-react';
import githubVerified from 'assets/images/github-verified.svg';
import { CONFIG } from 'utils/config-util';
import routeList from 'routes';
import { ToastContainer } from 'react-toastify';
import { PageHeader, PageHeaderTitle } from 'custom-components/PageHeader/PageHeader';
import { UI_ICON } from 'assets/font-icons/IconManager';

class GitHubAppInstallmentTriggerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserInvitationModalOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.ciConnect.ciProviderList.length > 0) {
      if (this.props.projects.projectList.length > 0) {
        this.props.history.replace(routeList.startGitHubExistedProjectRepoSelectionPage.path);
      } else {
        this.props.history.replace(routeList.startCreateProjectSelectGithubRepos.path);
      }
    }
  }

  onUserInviteModalClose = () => {
    this.setState({
      isUserInvitationModalOpen: false,
    });
  };

  render() {
    const { isUserInvitationModalOpen } = this.state;
    return (
      <>
        <PopupPageDesignCloseIconHeaderContainer />
        <div className="page-container">
          <div className="github-app-installation-container">
            <PageHeader>
              <PageHeaderTitle>Install Foresight’s GitHub app</PageHeaderTitle>
            </PageHeader>

            <div className="install-foresight-github-app-explanation">
              <div className="foresight-info-container">
                <div className="foresight-info">
                  To start using Foresight, you need to install the Foresight’s GitHub app. After installing the app you
                  will have:
                </div>
                <div className="step">
                  <div className="section-dot" />
                  <div className="text">
                    Highlights - Workflow analytics is to identify issues and enhance organization-wide CI visibility
                  </div>
                </div>
                <div className="step">
                  <div className="section-dot" />
                  <div className="text">See your workflow runs all in one place</div>
                </div>
                <div className="foresight-info two">
                  After installing the GitHub application, you will be able to integrate Foresight’s Test Kit and
                  Workflow Kit to make it simple to
                </div>
                <div className="step">
                  <div className="section-dot" />
                  <div className="text">See your test analytics,</div>
                </div>
                <div className="step">
                  <div className="section-dot" />
                  <div className="text">Display workflow telemetry data</div>
                </div>
                <div className="step">
                  <div className="section-dot" />
                  <div className="text">Determine how much of the changes are covered by the tests</div>
                </div>
              </div>
              <div className="github-info">
                <div className="github-approvement-explanation">
                  <Image className="github-verified-icon" src={githubVerified} fluid />
                  <div className="text-group">
                    GitHub has verified that the
                    <div className="first-indentationed-line">Foresight meets official requirements</div>
                  </div>
                </div>

                <a
                  className="foresight-data-policy"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.runforesight.com/security"
                >
                  You can learn more about Foresight's data policy.
                </a>
              </div>
            </div>
            <div className="not-admin-info-container">
              <div className="title">
                <i className={UI_ICON.ALERT.INFO_BLUE_CIRCLE} />
                IF YOU ARE NOT AN ADMIN IN YOUR GITHUB ORGANIZATION
              </div>
              <div className="content">
                You can request from your organization admin to install Foresight’s GitHub app. You can{' '}
                <a className="user-invitation-link" onClick={() => this.setState({ isUserInvitationModalOpen: true })}>
                  invite your admin
                </a>{' '}
                to your Foresight organization and request installation.
              </div>
            </div>
            <div className="installation-steps">
              <Button
                onClick={() => (window.location.href = CONFIG.FORESIGHT_GITHUB_APP)}
                primary
                className="install-button"
              >
                Install
              </Button>
            </div>
          </div>
        </div>
        {isUserInvitationModalOpen && (
          <UserInvitationModalContainer
            isUserInvitationModalOpen={isUserInvitationModalOpen}
            onUserInviteModalClose={this.onUserInviteModalClose}
          />
        )}

        <ToastContainer
          className="toast-container"
          toastClassName="toast-content"
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          icon={false}
        />
      </>
    );
  }
}

export default GitHubAppInstallmentTriggerPage;
