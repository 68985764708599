/* eslint-disable */

import pathToRegexp from 'path-to-regexp';
import { isDemoEnv } from 'utils/config-util';

const routeList = {
  prPage: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/pull-request/:prId',
    title: '${prName} | ${tabName} | Pull Requests | ${repoName} | Foresight',
  },

  prOverview: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/pull-request/:prId/:tabName',
    title: '${prName} | ${tabName} | Pull Requests | ${repoName} | Foresight',
  },

  workflowRunOverview: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/workflow-run/:workflowId',
    title: '${commitName} | ${workflowRunName} | Foresight',
  },

  workflowRunOverviewWithJobId: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/workflow-run/:workflowId/:jobId/:tabName',
    title: '${commitName} | ${workflowRunName} | Foresight',
  },

  workflowRunTimeline: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/workflowrun/:workflowId',
    title: '${commitName} | ${workflowRunName} | Foresight',
  },

  workflowRunTimelineJob: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/workflowrun/:workflowId/:jobId',
    title: '${commitName} | ${workflowRunName} | Foresight',
  },

  workflowRunTimelineStep: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/workflowrun/:workflowId/:jobId/:stepId',
    title: '${commitName} | ${workflowRunName} | Foresight',
  },

  testRunOverview: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/testrun/:testRunId',
    title: '${testRunName} | ${repoName} | Foresight',
  },

  testRunTestSuite: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/testrun/:testRunId/testsuite/:testSuiteName',
    title: '${testSuiteName} | ${repoName} | Foresight',
  },
  testRunTestPerformance: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/testrun/:testRunId/test/:transactionId',
    title: '${testName} | ${repoName} | Foresight',
  },
  testRunTestErrors: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/testrun/:testRunId/test/:transactionId/errors',
    title: '${testName} | ${repoName} | Foresight',
  },
  testRunTestLogs: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/testrun/:testRunId/test/:transactionId/logs',
    title: '${testName} | ${repoName} | Foresight',
  },
  testRunTestScreenshots: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/testrun/:testRunId/test/:transactionId/screenshots',
    title: '${testName} | ${repoName} | Foresight',
  },
  testRunTestSnapshots: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/testrun/:testRunId/test/:transactionId/snapshots',
    title: '${testName} | ${repoName} | Foresight',
  },
  testRunTestCode: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/testrun/:testRunId/test/:transactionId/code',
    title: '${testName} | ${repoName} | Foresight',
  },
  settingsProfile: {
    path: '/settings/profile',
    title: 'Settings | Profile | Foresight',
    tabTitle: 'Profile',
  },
  teamOrganization: {
    path: '/organization',
    title: 'Organization | Foresight',
    tabTitle: 'Organization',
  },
  teamOrganizationWithTab: {
    path: '/organization/:tabName',
    title: 'Organization | Foresight',
    tabTitle: 'Organization',
  },

  organizationList: {
    path: '/organization/list',
    title: 'Organization List | Foresight',
    tabTitle: 'Organization List',
  },
  playground: {
    path: '/playground',
  },
  repositories: {
    path: '/repositories',
    title: 'Repositories | Foresight',
  },
  repositoryWorkflowRuns: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/workflow-runs',
    title: 'Workflow Runs | ${repoName} | Foresight',
  },
  repositoryTestRuns: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/test-runs',
    title: 'Test Runs | ${repoName} | Foresight',
  },
  repositoryPRs: {
    path: '/repositories/:ciProvider/:repoOwner/:repoName/pull-requests',
    title: 'Pull Requests | ${repoName} | Foresight',
  },
  startInstallGitHubAppTrigger: {
    path: '/start/install-github-app-trigger',
    title: 'Install GitHub App | Foresight',
  },

  startCreateProjectSelectGithubRepos: {
    path: '/start/create-project-select-github-repos',
    title: 'Create Project and Select Repositories | Foresight',
  },

  startIntegrationGitHubTestRuns: {
    path: '/start/integrate-github-test-runs',
    title: 'Integrate GitHub Test Runs | Foresight',
  },

  //GitHub InstallationID may added as query param to this route.
  startGitHubExistedProjectRepoSelectionPage: {
    path: '/start/github-existed-project-repo-selection-page',
    title: 'Existed Project Select GitHub Repositories Page | Foresight',
  },
  integrations: {
    path: '/integrations',
    title: 'CI/CD Integrations | Foresight',
  },
  changePlan: {
    path: '/change-plan',
    title: 'Change Plan | Foresight',
  },
  workflows: {
    path: '/workflows',
    title: 'Workflows | Foresight',
  },
  testSuites: {
    path: '/test-suites',
    title: 'Tests | Foresight',
  },

  testSuitesDetail: {
    path: '/test-suites/repos/:ciProvider/:repoOwner/:repoName/:workflowId/:jobName/:testSuiteName',
    title: 'Tests | Foresight',
  },

  testViews: {
    path: '/test-case/:ciProvider/:repoOwner/:repoName/:workflowId/:jobName/:testSuiteName/:testCaseName',
    title: '${testName} | ${suiteName} | Test View | ${repoName} | Foresight',
  },

  highlights: {
    path: '/highlights',
    title: 'Highlights | Foresight',
  },
  singleWorkflowHighlights: {
    path: '/highlights/:ciProvider/:repoOwner/:repoName/workflow/:workflowId',
    title: '${workflowName} | Highlight | Foresight',
  },
  rules: {
    path: '/rules',
    title: 'Rules | Foresight',
  },

  landing: {
    path: '/landing',
  },

  callback: {
    path: '/callback',
  },
  loading: {
    path: '/loading',
  },
  errorWithCode: {
    path: '/error/:errorCode',
  },
  error: {
    path: '/error',
  },
  signup: {
    path: '/signup',
  },
  login: {
    path: '/login',
  },
};

// This function is used to compute path strings which has dynamic part like `:functionName`.
// Given a pathString you need provide an object, params, which has a key for each dynamic part in the path.
export function computePath(pathString, params) {
  const parsedPathString = pathToRegexp.parse(pathString);
  let constructedPath = '';

  for (let i = 0; i < parsedPathString.length; i++) {
    let parsedPathPiece = parsedPathString[i];

    if (typeof parsedPathPiece == 'string') {
      constructedPath = constructedPath + parsedPathPiece;
    } else {
      let pathVarFromParams = params[parsedPathPiece.name];
      constructedPath = constructedPath + '/' + encodeURIComponent(pathVarFromParams);
    }
  }

  // console.log("computePath; parsedPathString, constructedPath: ", parsedPathString, constructedPath);
  return constructedPath;
}

// Compute which tab is selected for function details from the url
// export function computeFunctionDetailsTab(url) {
export function computeSelectedTabFromUrl(url) {
  const tabStringArr = url.split('/');
  const tabString = tabStringArr[tabStringArr.length - 1];

  return tabString;
}

// This function computes the selected sidebar based on the first part of the path
// defined inrouteList above.
export function computeSelectedMenuFromSidebar(url) {
  const urlStringArr = url.split('/');
  const sidebarString = urlStringArr[1]; // because 0 is '/' character

  return sidebarString;
}

export function shouldAddIsNewUserToUrl(path, isNewUser) {
  // `isNewUser` query param is used for marketing tracking.
  let redirectionURL = path;
  const isNewUserTimer = localStorage.getItem('isNewUserTimer');
  console.log('shouldAddIsNewUserToUrl; path, isNewUser, isNewUserTimer: ', path, isNewUser, isNewUserTimer);
  if (isNewUser || (isNewUserTimer && isNewUserTimer !== null)) {
    console.log('add isNewUser query param!');
    if (path.includes('?')) {
      redirectionURL = path + '&isNewUser=true';
    } else {
      redirectionURL = path + '?isNewUser=true';
    }
    // For Foresight we do not have any onboarding stage.
    // So in order to keep `isNewUser` param on the url,
    // we needed an external logic.
    localStorage.setItem('isNewUserTimer', true);
  }

  return redirectionURL;
}

export const getInitialRouteForRootPath = () => {
  if (isDemoEnv()) {
    //For Demo and Public Domains Redirect To Highlight Page
    return routeList.highlights.path;
  }
  return routeList.repositories.path;
};

export default routeList;
