import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
import { LoadingDimmer, renderNoDataAvailableForChangeImpact, SomethingError } from 'components/ComponentPlaceholder';
import { SourceFilesCoverageDetailsDimmer } from 'components/RepositoryWorkflowRunOverviewPage/ImpactAnalysis/SourceFilesCoverageDetails';
import { Tooltip } from 'components/Tooltip';
import { SourceFilesCoverageDetailsContainer } from 'containers';
import { ForesightAccordionWithAnimation } from 'custom-components/Accordion/Accordion';
import {
  DualHorizontalItemsContainer,
  DualHorizontalItemsLeftPart,
  DualHorizontalItemsRightPart,
} from 'custom-components/DualHorizontalItems/DualHorizontalItems';
import { ForesightTable } from 'custom-components/Table/Table';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { hasArrayElement } from 'utils/array-util';
import { calcDisplayedTime, formatDateFromNow, formatDateWithTime } from 'utils/date-util';
import { getWorkflowStatusIcon } from 'utils/workflow-status-icon';
import { getWorkflowStatusTooltipContent } from 'utils/workflowrun-conclusion-status-tooltips';
import './PRChangeImpactAnalysis.scss';

export class PRChangeImpactAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDetailOpen: false,
      workflowId: null,
      jobId: null,
      files: [],
    };
  }

  componentDidMount() {
    const { ciProvider, repoOwner, repoName, prId } = this.props.match.params;
    this.props.getPullRequestCoverages(ciProvider, repoOwner, repoName, prId);
  }

  handleRowClick = (workflowRunId, jobId) => {
    this.props.getPullRequestWorkflowRunLatestJobCoverageResult(
      workflowRunId,
      jobId,
      response => {
        const { fileCoverageResults: files } = response.value.data;
        this.setState({
          isDetailOpen: true,
          workflowId: workflowRunId,
          jobId,
          files,
        });
      },
      error => {
        console.log(error);
      },
    );
  };

  renderJobNameAndStatus = job => {
    return (
      <>
        <DualHorizontalItemsContainer>
          <DualHorizontalItemsLeftPart>
            <Tooltip blackEdition content={getWorkflowStatusTooltipContent(job)}>
              <div style={{ marginLeft: 2 }}>{getWorkflowStatusIcon(job)}</div>
            </Tooltip>
          </DualHorizontalItemsLeftPart>
          <DualHorizontalItemsRightPart style={{ fontSize: 14, fontWeight: 500 }}>
            {job.jobName}
          </DualHorizontalItemsRightPart>
        </DualHorizontalItemsContainer>
      </>
    );
  };

  calcJobChangeImpactAnalysis = job => {
    const { patchUncoveredLineCount, patchCoveredLineCount, coverageRate } = job;
    const totalLineCount = patchUncoveredLineCount + patchCoveredLineCount;
    return `${patchUncoveredLineCount}/${totalLineCount} - ${(100 - coverageRate).toFixed(0)}% is not tested`;
  };

  renderJobItems = (workflowRunId, jobItems) => {
    const jobItemJSX = jobItems.map((job, index) => {
      return (
        <Table.Row onClick={() => this.handleRowClick(workflowRunId, job.jobId)} key={index} className="table-row">
          <Table.Cell>{this.renderJobNameAndStatus(job)}</Table.Cell>
          <Table.Cell style={{ fontWeight: 500 }}>{calcDisplayedTime(job.duration)}</Table.Cell>
          <Table.Cell style={{ fontWeight: 500 }}>{this.calcJobChangeImpactAnalysis(job)}</Table.Cell>
          <Table.Cell>
            {/* <div className="table-cell-right-icon-container">
              <i className={UI_ICON.CHEVRON.RIGHT} />
            </div> */}
          </Table.Cell>
        </Table.Row>
      );
    });
    return jobItemJSX;
  };

  renderWorkflowItemDate = date => {
    return (
      <>
        <DualHorizontalItemsContainer>
          <DualHorizontalItemsLeftPart>
            <i className={DOMAIN_ICON.GITHUB.EXEC_DATE} />
          </DualHorizontalItemsLeftPart>
          <DualHorizontalItemsRightPart>
            <div className="foresight-change-impact-list-row-date">{date}</div>
          </DualHorizontalItemsRightPart>
        </DualHorizontalItemsContainer>
      </>
    );
  };

  renderWorklowItems = workflowItems => {
    const workflowItemsJSX = workflowItems.map((item, index) => {
      return (
        <ForesightAccordionWithAnimation
          key={index}
          header={
            <div className="foresight-change-impact-list-row">
              <div className="foresight-change-impact-list-row-title">{item.workflowName}</div>
              <div className="foresight-change-impact-list-row-date-container">
                <Tooltip
                  blackEdition
                  content={
                    <div>
                      <div>Open Since</div>
                      <div>{formatDateWithTime(item.createdAt)}</div>
                    </div>
                  }
                >
                  <div>{this.renderWorkflowItemDate(formatDateFromNow(item.createdAt))}</div>
                </Tooltip>
              </div>
            </div>
          }
          content={
            <ForesightTable basic="very">
              <Table.Header fullWidth>
                <Table.Row>
                  <Table.HeaderCell width={4}>Job</Table.HeaderCell>
                  <Table.HeaderCell width={6}>Duration</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Change Impact Analysis</Table.HeaderCell>
                  <Table.HeaderCell width={124}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{this.renderJobItems(item.workflowRunId, item.jobCoverages || [])}</Table.Body>
            </ForesightTable>
          }
        />
      );
    });
    return workflowItemsJSX;
  };

  render() {
    const { workflowId, jobId, files } = this.state;

    const { pullRequestCoverages, fetching, error, fetched } = this.props.pullRequestCoverages || {
      pullRequestCoverages: [],
      fetching: false,
      error: null,
      fetched: false,
    };
    if (fetched) {
      const { workflowCoverages } = pullRequestCoverages;
      if (!hasArrayElement(workflowCoverages)) {
        return renderNoDataAvailableForChangeImpact();
      } else {
        return (
          <div className="foresight-change-impact-list">
            <div className="foresight-change-impact-list-title">Workflow</div>
            {this.renderWorklowItems(workflowCoverages)}
            <SourceFilesCoverageDetailsDimmer
              isOpen={this.state.isDetailOpen}
              closeDetailModal={() => this.setState({ isDetailOpen: false })}
            />
            <SourceFilesCoverageDetailsContainer
              isOpen={this.state.isDetailOpen}
              closeDetailModal={() => this.setState({ isDetailOpen: false })}
              metaData={{
                workflowId,
                jobId,
                files,
              }}
            />
          </div>
        );
      }
    } else if (fetching) {
      return <LoadingDimmer />;
    } else if (error) {
      return <SomethingError />;
    } else {
      return <LoadingDimmer />;
    }
  }
}
