import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import PropTypes from 'prop-types';
import './ForesightCircle.scss';
import { toFixed } from 'utils/math';

const CIRCLE_SIZE = {
  small: { inner: 14, outer: 20, fontSize: 14, height: 42 },
  middleSmall: { inner: 20, outer: 26, fontSize: 16, height: 56 },
  medium: { inner: 62, outer: 72, fontSize: 18, height: 144 },
  big: { inner: 82, outer: 92, fontSize: 24, height: 185 },
};

export const ForesightCircle = ({ size, textPosition, subText, data, seeText = true }) => {
  const valueCheckFunc = chartValue => {
    const controlVal = toFixed(chartValue, 0);
    return controlVal;
  };

  return (
    <div>
      <div className="foresight-circle-container ">
        {typeof data[0]?.value === 'number' ? (
          <>
            <PieChart className="piechart" width={CIRCLE_SIZE[size].height} height={CIRCLE_SIZE[size].height}>
              <Pie
                data={data}
                fill="#8824d8"
                stroke="rgba(255, 255, 255, 0.00)"
                innerRadius={CIRCLE_SIZE[size].inner}
                outerRadius={CIRCLE_SIZE[size].outer}
                paddingAngle={0}
                dataKey="value"
                isAnimationActive
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              {textPosition === 'center' && seeText && (
                <text
                  fontSize={CIRCLE_SIZE[size].fontSize}
                  fontWeight="700"
                  fill="#DCDFE5"
                  textAnchor="middle"
                  dominantBaseline="middle"
                >
                  {valueCheckFunc(data[0]?.value)}%
                </text>
              )}
            </PieChart>
            {textPosition === 'right' && seeText && (
              <div
                className={
                  size === 'small' ? 'foresight-circle-small-text-container' : 'foresight-circle-text-container'
                }
              >
                <div className="foresight-circle-text"> {valueCheckFunc(data[0].value)}%</div>
                {subText.length > 0 && <div className="foresight-circle-sub-text"> {subText}</div>}
              </div>
            )}
          </>
        ) : (
          <div
            className={size === 'small' ? 'foresight-circle-small-text-container' : 'foresight-circle-text-container'}
          >
            <div className="foresight-circle-text" style={{ height: 35, marginTop: 5 }}>
              {valueCheckFunc(data[0]?.value)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ForesightCircle.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
  size: PropTypes.string.isRequired,
  textPosition: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
};

ForesightCircle.defaultProps = {
  data: [],
  size: 'medium',
  textPosition: 'center',
  subText: '',
};
