import React, { Component } from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import _ from 'lodash';
import './Breadcrumb.scss';

export const createForesightBreadCrumbDataItem = (text, data, handleOnClick) => {
  return {
    text: text,
    data: data,
    handleOnClick: handleOnClick,
  };
};

export default class ForesightBreadcrumb extends Component {
  render() {
    const { type } = this.props;
    const data = this.props.breadcrumbData || [];

    // TODO: Fix here. Link item property should belong to breadcrumb data item. Because every breadcrumb item is not clikcable.
    // TODO: Than remove link item check.
    if (type === 'link-item') {
      return (
        <Breadcrumb className="foresight-menu-breadcrumb">
          {data.map((item, index) => {
            return (
              <React.Fragment key={_.uniqueId('linkItemId')}>
                {item.handleOnClick ? (
                  <Breadcrumb.Section onClick={() => item.handleOnClick(item.data)}>{item.text}</Breadcrumb.Section>
                ) : (
                  <Breadcrumb.Section>{item.text}</Breadcrumb.Section>
                )}
                {index < data.length - 1 && <Breadcrumb.Divider />}
              </React.Fragment>
            );
          })}
        </Breadcrumb>
      );
    } else {
      return (
        <Breadcrumb className="foresight-menu-breadcrumb">
          {data.map((item, index) => {
            return (
              <React.Fragment key={_.uniqueId('brandCrumbId')}>
                <Breadcrumb.Section key={_.uniqueId()}>{item}</Breadcrumb.Section>
                {index < data.length - 1 && <Breadcrumb.Divider />}
              </React.Fragment>
            );
          })}
        </Breadcrumb>
      );
    }
  }
}
