import {
  InfoPanel,
  InfoPanelText,
  InfoPanelTitle,
  InfoPanelTitleContainer,
  InfoPanelWarningIcon,
} from 'custom-components/InfoPanel/InfoPanel';
import React from 'react';
import './IncreasePlanMsg.scss';

class IncreasePlanMsg extends React.Component {
  render() {
    const { activeSubscription } = this.props.activeSubscription;
    let activeCommitterLimit = 20;
    let activeCommiterCount = 0;
    let billingPlanWarningInfo = false;
    if (activeSubscription) {
      activeCommiterCount = activeSubscription.activeCommitter?.lenght;
      activeCommitterLimit = activeSubscription.activeCommitterLimit;
      if (!activeSubscription.trial && activeCommiterCount - activeCommitterLimit > 0) {
        billingPlanWarningInfo = true;
      }
    }

    if (billingPlanWarningInfo) {
      return (
        <InfoPanel>
          <InfoPanelTitleContainer>
            <InfoPanelWarningIcon />
            <InfoPanelTitle>You have exceeded your committer limit</InfoPanelTitle>
          </InfoPanelTitleContainer>
          <InfoPanelText>
            The committer limit of your plan is {activeCommitterLimit}. However, you had {activeCommiterCount}{' '}
            committers this month. To avoid losing access to your account, please update your plan.
          </InfoPanelText>
        </InfoPanel>
      );
    } else {
      return null;
    }
  }
}

export default IncreasePlanMsg;
