export function getTestCaseDetail(testDetailMap, transactionId, type) {
	const testDetail = testDetailMap ? testDetailMap[transactionId] : undefined;
	if (testDetail) {
		return testDetail[type];
	}
	return {};
}

export function getTestCaseOverviewDetail(testDetailMap, transactionId) {
	return getTestCaseDetail(testDetailMap, transactionId, 'testOverviewObj');
}

export function getTestCaseHistoryDetail(testDetailMap, transactionId) {
	return getTestCaseDetail(testDetailMap, transactionId, 'testHistoryObj');
}

export function getTestCaseLogsDetail(testDetailMap, transactionId) {
	return getTestCaseDetail(testDetailMap, transactionId, 'testLogsObj');
}

export function getTestCaseScreenshotsDetail(testDetailMap, transactionId) {
	return getTestCaseDetail(testDetailMap, transactionId, 'testScreenshotsObj');
}

