import { connect } from 'react-redux';
import { TestRunSuiteCardList } from 'components';
import { withRouter } from 'react-router';
import { setTestRunClientFilter } from 'store/actions/test-run-details';

function mapStateToProps(store) {
	return {
		testRunDetails: store.testRunDetails,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setTestRunClientFilter(testRunId, filters) {
			dispatch(setTestRunClientFilter(testRunId, filters));
		},
	};
}

const TestRunSuiteCardListContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(TestRunSuiteCardList));
export default TestRunSuiteCardListContainer;
