import React, { Component } from 'react';
import './ProjectSelectionCreationInputWithActions.scss';
import PropTypes from 'prop-types';
import { ForesightInput } from 'custom-components/Input/Input';
import { BlueButton, GrayBorderButton } from 'custom-components/Button/Button';

export default class ProjectSelectionCreationInputWithActions extends Component {
  constructor(props) {
    super(props);
  }

  getErrorMessage = () => {
    const { errorMessage } = this.props;
    return errorMessage;
  };

  getActionButtons = () => {
    const { cancelButtonOnClick, createButtonOnClick, loading, createButtonText } = this.props;

    return (
      <div className="action-buttons-container">
        <GrayBorderButton style={{ height: 32 }} onClick={cancelButtonOnClick}>
          Cancel
        </GrayBorderButton>
        <BlueButton primary onClick={createButtonOnClick} loading={loading}>
          {createButtonText}
        </BlueButton>
      </div>
    );
  };

  render() {
    const { onInputChange, placeholder, inputHasError, showActionButtons } = this.props;
    return (
      <>
        <ForesightInput placeholder={placeholder} labelText={'Name'} required onChange={onInputChange} />
        {inputHasError && this.getErrorMessage()}
        {showActionButtons && this.getActionButtons()}
      </>
    );
  }
}

ProjectSelectionCreationInputWithActions.propTypes = {
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  inputHasError: PropTypes.bool,
  showActionButtons: PropTypes.bool,
  loading: PropTypes.bool,
  cancelButtonOnClick: PropTypes.func,
  createButtonOnClick: PropTypes.func,
  createButtonText: PropTypes.string,
};
