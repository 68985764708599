import { CI_PROVIDER } from 'enums/git-provider';

export const getCiProviderConfigLink = integration => {
  if (!integration) {
    return '';
  } else if (integration && integration.provider === CI_PROVIDER.GITHUB) {
    return `https://github.com/settings/installations/${integration.integrationId}`;
  }
};

export const getCiProviderPullRequestLink = workflowRunOverview => {
  if (!workflowRunOverview) {
    return '';
  } else if (workflowRunOverview.provider === CI_PROVIDER.GITHUB) {
    const pullRequests = workflowRunOverview.pullRequests || [];
    let apiUrl = pullRequests[0]?.url || '';
    apiUrl = apiUrl?.replace('api.github.com/repos', 'github.com');
    apiUrl = apiUrl?.replace('pulls', 'pull');
    return apiUrl ? apiUrl : '/';
  }
};

export const getCiProviderBranchLink = workflowRunOverview => {
  if (!workflowRunOverview) {
    return '';
  } else if (workflowRunOverview.provider === CI_PROVIDER.GITHUB) {
    return `https://github.com/${workflowRunOverview.repo.fullName}/tree/${workflowRunOverview.headBranch}`;
  }
};

export const getCiProviderCommitLink = workflowRunOverview => {
  if (!workflowRunOverview) {
    return '';
  } else if (workflowRunOverview.provider === CI_PROVIDER.GITHUB) {
    return `https://github.com/${workflowRunOverview.repo.fullName}/commit/${workflowRunOverview.headCommit?.id}`;
  }
};

export const getCiProviderRepositoryLink = workflowRunOverview => {
  if (!workflowRunOverview) {
    return '';
  } else if (workflowRunOverview.provider === CI_PROVIDER.GITHUB) {
    return `https://github.com/${workflowRunOverview.repo.fullName}`;
  }
};
export const getCiProviderAllWorkflowsLink = workflowRunOverview => {
  if (!workflowRunOverview) {
    return '';
  } else if (workflowRunOverview.provider === CI_PROVIDER.GITHUB) {
    return `https://github.com/${workflowRunOverview.repo.fullName}/actions`;
  }
};
export const getCiProviderWorkflowLink = workflowRunOverview => {
  if (!workflowRunOverview) {
    return '';
  } else if (workflowRunOverview.provider === CI_PROVIDER.GITHUB) {
    return `https://github.com/${workflowRunOverview.repo.fullName}/actions/runs/${workflowRunOverview.runId}`;
  }
};
export const getCiProviderWorkflowLinkToError = (workflowRunOverview, selectedJob) => {
  if (!workflowRunOverview) {
    return '';
  } else if (workflowRunOverview.provider === CI_PROVIDER.GITHUB) {
    return `https://github.com/${workflowRunOverview.repo.fullName}/runs/${selectedJob.id}?check_suite_focus=true`;
  }
};
