import React, { Component } from 'react';
import './TestsSuitesPage.scss';
import { MainLayoutContainer } from 'containers';
import routeList from 'routes';
import { Calendar } from 'custom-components/Calendar/Calendar';
import { GrayBorderButton } from 'custom-components/Button/Button';
import { TestSuitesSidePanel } from './TestSuitesSidePanel/TestSuitesSidePanel';
import { FilterLabel } from 'custom-components/FilterLabel/FilterLabel';
import { FilterLabelGroup } from 'custom-components/FilterLabelGroup/FilterLabelGroup';
import { TestsSuitesPageHeader } from './page-components/PageHeader/TestsSuitesPageHeader';
import { TestsSuitesPageTable } from './page-components/Table/TestsSuitesPageTable';
import { TestsSuitesBreadcrumb } from './page-components/breadcrumb/TestsSuitesBreadcrumb';
import { SearchInputFilter } from './page-components/filter/SearchInputFilter';
import ConnectRepositoryWidget from 'components/HighlightsPage/HighlightWidgets/ConnectRepositoryWidget/ConnectRepositoryWidget';

const SORTABLE_MAP = {
  Failedruns: 'failedCount',
  Successfulruns: 'successfulCount',
};

const dateCalculatedBefore = new Date();
dateCalculatedBefore.setDate(dateCalculatedBefore.getDate() - 30);

const dateCalculatedToday = new Date();
dateCalculatedToday.setDate(dateCalculatedToday.getDate());

const pageItemSize = 100;
class TestsSuitesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      sortFetching: false,
      sort: {
        field: 'failedCount',
        order: 'DESC',
      },
      range: {},
      page: 0,
      searchName: '',
      searchText: '',
      isFilterOpen: false,
      sidePanelFilterObj: { branches: [] },
      isClearFilterClicked: false,
    };
  }

  componentDidMount() {
    document.getElementById('tests-suites-table-container').addEventListener('scroll', this.trackScrolling);
    this.props.getHasConnectedRepoWatch();
    this.props.getTestSuiteOverviewListByFilters({ showForkBranch: true }, this.handleInitialFilter);
  }

  fetchData = (output = null) => {
    const { hideFork } = this.props.testSuitesOverview;
    const { sort, range, searchName, page, sidePanelFilterObj } = this.state;
    this.props.getTestSuitesOverviewList(
      page,
      pageItemSize,
      {
        sort: sort,
        range: range,
        searchName: searchName,
        branches: output || sidePanelFilterObj.branches,
      },
      hideFork,
    );
  };

  handleInitialFilter = () => {
    const { setTestOverviewFilter } = this.props;
    const { filterContent } = this.props.testSuitesOverview;
    const transformedData = [];
    let output = [];
    for (const item of filterContent) {
      const transformedItem = { repoName: item.name, repoId: item.id };
      transformedItem.branches = item.branches.map(element => {
        return {
          name: element,
          checked: item.defaultBranch === element,
          visible: true,
        };
      });
      transformedData.push(transformedItem);
    }
    if (transformedData?.length > 0) {
      output = transformedData.map(item => {
        return {
          repoId: item.repoId,
          repoName: item.repoName,
          branches: item.branches.filter(branch => branch.checked).map(branchItem => branchItem.name),
        };
      });
    }
    const sendToBackendObj = output.filter(item => item.branches.length > 0);

    this.setState({ sidePanelFilterObj: { branches: sendToBackendObj } }, () => {
      this.fetchData(sendToBackendObj);
    });
    setTestOverviewFilter(transformedData);
  };

  handleFilterChange = sidePanelStates => {
    const { selectedFilterObject } = this.props.testSuitesOverview;
    console.log('Test Suites Page handle');
    let output = [];
    const transformedData = [];

    for (const item of selectedFilterObject) {
      if (item?.branches.length > 0) {
        transformedData.push(item);
      }
    }
    if (transformedData?.length > 0) {
      output = transformedData.map(item => {
        return {
          repoId: item.repoId,
          repoName: item.repoName,
          branches: item.branches.filter(branch => branch.checked).map(branchItem => branchItem.name),
        };
      });
    }
    const sendToBackendObj = output.filter(item => item.branches.length > 0);

    this.setState(
      {
        sidePanelFilterObj: { branches: sendToBackendObj },
        isFilterOpen: false,
        searchText: sidePanelStates.searchText,
      },
      () => {
        this.fetchData(sendToBackendObj);
      },
    );
  };

  checkFetchingElements = lastPage => {
    return lastPage;
  };

  trackScrolling = () => {
    const wrappedElement = document.getElementById('tests-suites-table-container');
    this.fetchWhenScrollAccessToBottom(wrappedElement);
  };

  fetchWhenScrollAccessToBottom = el => {
    const { hideFork, page, lastPage } = this.props.testSuitesOverview;
    const { sort, range, searchName, sidePanelFilterObj } = this.state;

    if (this.checkFetchingElements(lastPage)) {
      return;
    }
    if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
      const { fetching } = this.state;
      if (!fetching) {
        this.setState({ fetching: true }, () => {
          this.props.getTestSuitesOverviewList(
            page + 1,
            pageItemSize,
            {
              sort: sort,
              range: range,
              searchName: searchName,
              branches: sidePanelFilterObj.branches || [],
            },
            hideFork,
            () => {
              this.setState({ fetching: false });
            },
            () => {
              this.setState({ fetching: false });
            },
          );
        });
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.range !== this.state.range) {
      this.fetchData();
    }
  }

  handleSortData = data => {
    if (data.field) {
      const sortField = SORTABLE_MAP[data.field.replace(/\s/g, '')];
      this.setState(
        {
          sort: {
            field: sortField,
            order: data.order,
          },
        },
        () => this.fetchData(),
      );
    }
  };

  rangeChangeHandle = ({ value }) => {
    if (value?.length === 2) {
      let endDateCalculated = value[1].setHours(26, 59);
      const rangeObj = { startDate: value[0], endDate: new Date(endDateCalculated) };
      this.setState({ range: rangeObj });
    } else if (Object.is(value, null)) {
      this.setState({ range: {} });
    }
  };

  searchInputHandle = data => {
    this.setState({ searchName: data.value });
  };

  handleClearFilter = () => {
    this.setState({ sidePanelFilterObj: { branches: [] }, isFilterOpen: false }, () => {
      this.fetchData();
    });
  };

  renderFilterButton = () => {
    const { isFilterOpen } = this.state;
    return (
      <GrayBorderButton
        onClick={() => {
          this.setState({
            isFilterOpen: !isFilterOpen,
            isClearFilterClicked: false,
          });
        }}
      >
        <i className="icon-filter-icon" />
        Advanced Filter
      </GrayBorderButton>
    );
  };

  handleSearchNameWithListRender = data => {
    if (data?.clear) {
      this.setState(
        {
          searchName: '',
        },
        () => this.fetchData(),
      );
    } else if (data.code === 'Enter') {
      this.fetchData();
    }
  };

  convertTooltipData = data => {
    const transformedData = [];

    data.forEach(item => item.branches.forEach(element => transformedData.push(`${item.repoName}/${element}`)));
    return transformedData;
  };

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  render() {
    const { searchName, isFilterOpen, isClearFilterClicked, sidePanelFilterObj, searchText } = this.state;
    const {
      testSuitesOverview: { testSuitesOverviewFetching },
      hasConnectedRepoWacthed,
      ciConnect,
      userAccount,
    } = this.props;

    if (!hasConnectedRepoWacthed) {
      return (
        <MainLayoutContainer>
          <ConnectRepositoryWidget
            ciConnect={ciConnect}
            noProjectIsPresent={!hasConnectedRepoWacthed}
            history={this.props.history}
            userAccount={userAccount}
          />
          <div id="tests-suites-table-container"></div>
        </MainLayoutContainer>
      );
    }
    return (
      <MainLayoutContainer title={routeList.testSuites.title}>
        <TestSuitesSidePanel
          getTestSuiteOverviewListByFilters={this.props.getTestSuiteOverviewListByFilters}
          testSuitesFilter={this.props.testSuitesOverview}
          isOpen={isFilterOpen}
          applyFilter={this.handleFilterChange}
          isClearFilter={isClearFilterClicked}
          closeDetailModal={() => this.setState({ isFilterOpen: false })}
          setTestOverviewFilter={this.props.setTestOverviewFilter}
          searchText={searchText}
        />
        <div className="tests-suites-page-wrapper">
          <div className="tests-suites-header-container">
            <TestsSuitesBreadcrumb {...this.props} />
            <div className="header-first-section">
              <TestsSuitesPageHeader />

              <div className="advanced-filter-btn">
                {sidePanelFilterObj?.branches.length > 0 && (
                  <FilterLabelGroup clearFilterHandle={() => this.handleClearFilter()}>
                    <FilterLabel
                      filterArray={this.convertTooltipData(sidePanelFilterObj?.branches) || ['All']}
                      labelTitle="Branches"
                      filterLabelClick={() => {
                        this.setState({
                          isFilterOpen: !isFilterOpen,
                          isClearFilterClicked: false,
                        });
                      }}
                    />
                  </FilterLabelGroup>
                )}
                {this.renderFilterButton()}
              </div>
            </div>
            <div className="header-second-section">
              <div className="test-suites-search-input">
                <SearchInputFilter
                  searchValue={searchName}
                  onClickSearchButton={() => this.handleSearchNameWithListRender({ code: 'Enter' })}
                  onChangeInputText={data => this.searchInputHandle(data)}
                  buttonLoading={testSuitesOverviewFetching}
                  buttonDisabled={testSuitesOverviewFetching}
                  clearSearchInput={data => this.handleSearchNameWithListRender(data)}
                  onKeyPress={data => this.handleSearchNameWithListRender(data)}
                />
              </div>
              <div className="test-suites-calendar">
                <Calendar
                  onChange={(event, data) => this.rangeChangeHandle(data)}
                  format="DD/MM/YYYY"
                  type="range"
                  pointing="right"
                  value={[dateCalculatedBefore, dateCalculatedToday]}
                />
              </div>
            </div>
          </div>

          <TestsSuitesPageTable
            handleSortData={data => this.handleSortData(data)}
            infiniteState={this.state.fetching}
            {...this.props}
          />
        </div>
      </MainLayoutContainer>
    );
  }
}

export default TestsSuitesPage;
