import { InfoTooltip, InfoTooltipContent } from 'components';
import React from 'react';
import { Button } from 'semantic-ui-react';
import './Tabs.scss';

export const ForesightTabs = ({ buttonTextArr, onClick, active = '', withLine = true, ...props }) => {
  const getInfoText = ({ title, desc }) => {
    return <InfoTooltipContent title={title} desc={desc} />;
  };

  return (
    <div className="foresight-tabs-main-container">
      <Button.Group {...props} className="foresight-tabs-container">
        {buttonTextArr.map(item => {
          const info = props.infoMap ? props.infoMap[item] : null;
          return (
            <Button
              key={item}
              primary
              onClick={onClick}
              name={item}
              active={active.toLowerCase() === item.toLowerCase()}
            >
              {item}
              {info && (
                <span className="tab-info-container">
                  <InfoTooltip content={getInfoText(info)} />
                </span>
              )}
            </Button>
          );
        })}
      </Button.Group>
      {withLine && <div className="long-divider" />}
    </div>
  );
};
