import React, { Component } from 'react';
import './PopupPageDesignCloseIconHeader.scss';
import routeList from 'routes';
import { UI_ICON } from 'assets/font-icons/IconManager';

export default class PopupPageDesignCloseIconHeader extends Component {
  closeModal = () => {
    this.props.history.push(routeList.repositories.path);
  };

  render() {
    return (
      <div className="cross-popup-icon-container">
        <i onClick={() => this.closeModal()} className={UI_ICON.CROSS.REPO_MODAL_CLOSE} />
      </div>
    );
  }
}
