import {
	GET_TEST_RUN_DETAIL_OVERVIEW_FULFILLED,
	GET_TEST_RUN_DETAIL_OVERVIEW_PENDING,
	GET_TEST_RUN_DETAIL_OVERVIEW_REJECTED,
	GET_TEST_RUN_DETAIL_TEST_SUIT_FULFILLED,
	GET_TEST_RUN_DETAIL_TEST_SUIT_PENDING,
	GET_TEST_RUN_DETAIL_TEST_SUIT_REJECTED,
	SET_TEST_RUN_DETAIL_CLIENT_FILTER,
} from 'store/constants/test-run-details';

const initialState = {
	testRunDetailMap: {},
};

export default function testRunDetails(state = initialState, action) {
	const { testRunId } = action.meta || { testRunId: undefined };
	switch (action.type) {
	case GET_TEST_RUN_DETAIL_TEST_SUIT_PENDING:
		return setTestSuitesObj(state, testRunId, {
			fetchingTime: new Date().getTime(),
			fetching: true,
			fetched: false,
			error: null,
			errorTime: -1,
			suites: [],
		});
	case GET_TEST_RUN_DETAIL_TEST_SUIT_FULFILLED:
		return setTestSuitesObj(state, testRunId, {
			fetching: false,
			fetched: true,
			error: null,
			errorTime: -1,
			suites: action.payload.data,
		});
	case GET_TEST_RUN_DETAIL_TEST_SUIT_REJECTED:
		return setTestSuitesObj(state, testRunId, {
			fetching: false,
			fetched: false,
			error: action.payload,
			errorTime: new Date().getTime(),
			suites: [],
		});

	case GET_TEST_RUN_DETAIL_OVERVIEW_PENDING:
		return setTestOverviewObj(state, testRunId, {
			fetching: true,
			fetched: false,
			error: null,
			errorTime: -1,
			overview: {},
		});
	case GET_TEST_RUN_DETAIL_OVERVIEW_FULFILLED:
		return setTestOverviewObj(state, testRunId, {
			fetching: false,
			fetched: true,
			error: null,
			errorTime: -1,
			overview: action.payload.data,
		});
	case GET_TEST_RUN_DETAIL_OVERVIEW_REJECTED:
		return setTestOverviewObj(state, testRunId, {
			fetching: false,
			fetched: false,
			error: action.payload,
			errorTime: new Date().getTime(),
			overview: {},
		});
	case SET_TEST_RUN_DETAIL_CLIENT_FILTER:
		return setClientFilterObj(state, testRunId, {
			clientFilters: action.payload.data,
		});

	default:
		return state;
	}
}

function setTestSuitesObj(state, testRunId, suitesObj) {
	const testRunDetail = getTestRunDetail(state, testRunId);
	testRunDetail.testSuitesObj = suitesObj;
	return { testRunDetailMap: state.testRunDetailMap };
}

function setTestOverviewObj(state, testRunId, overviewObj) {
	const testRunDetail = getTestRunDetail(state, testRunId);
	testRunDetail.testOverviewObj = overviewObj;
	return { testRunDetailMap: state.testRunDetailMap };
}

function setClientFilterObj(state, testRunId, clientFilterObj) {
	const testRunDetail = getTestRunDetail(state, testRunId);
	testRunDetail.clientFilterObj = clientFilterObj;
	return { testRunDetailMap: state.testRunDetailMap };
}

function getTestRunDetail(state, testRunId) {
	const { testRunDetailMap } = state;

	//Check Is TestRun Exist
	if (!testRunDetailMap[testRunId]) {
		const testRunDetail = {
			testRunId: testRunId,
			testSuitesObj: {
				fetching: false,
				fetched: false,
				error: null,
				errorTime: -1,
				suites: [],
			},
			testOverviewObj: {
				fetching: false,
				fetched: false,
				error: null,
				errorTime: -1,
				overview: {},
			},
			clientFilterObj: {
				clientFilters: {},
			},
		};
		testRunDetailMap[testRunId] = testRunDetail;
	}
	return testRunDetailMap[testRunId];
}
