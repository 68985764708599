import { LoadingDimmer, NoDataAvailable, SomethingError } from 'components/index';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { hasArrayElement } from 'utils/array-util';
import HeatMapComponent from './HeatMapComponent';
import { Tooltip } from 'components/Tooltip';
import './HeatMapWidget.scss';

const arrMap = {
  one: '1',
  two: '2',
  three: '3',
  five: '5',
  fifteen: '15',
  thirty: '30',
};

const infoText =
  'You can detect the frequency of your workflow runs. For instance, if there is an outlier day where you ran workflows less than the usual, you may investigate if there is any roadblock about your releases.';

export default class HeatMapWidget extends Component {
  formatTimeNumber = text => {
    if (arrMap[`${text}`]) return arrMap[`${text}`];
    return text;
  };

  formatTimeRange = timeRange => {
    if (typeof timeRange !== 'string') return '';
    return timeRange
      .split('_')
      .map(el => this.formatTimeNumber(el.toLowerCase()))
      .join(' ');
  };

  renderWithContent = () => {
    const { title, chartData, range, width, startDate, endDate } = this.props;

    return (
      <div className="dashboard-widget-card-container-heatmap">
        <div className="header">
          <div className="title">
            {title}&nbsp;
            <Tooltip blackEdition content={infoText}>
              <Icon style={{ height: '20px' }} name="info circle" />
            </Tooltip>
          </div>
        </div>

        <div className="chart-for-heatmap">
          <HeatMapComponent value={chartData} range={range} width={width} startDate={startDate} endDate={endDate} />
        </div>
      </div>
    );
  };

  renderWithoutContent = () => {
    const { title, error, loading, unknown } = this.props;

    let contentJSX = <NoDataAvailable msg={'No data available'} />;
    if (error) {
      contentJSX = <SomethingError msg={error} />;
    } else if (loading) {
      contentJSX = <LoadingDimmer msg={'Loading...'} />;
    } else if (unknown) {
      contentJSX = <LoadingDimmer msg={'Unknown State...'} />;
    }

    return (
      <div className="dashboard-widget-card-container">
        <div className="title">
          {title}&nbsp;
          <Tooltip blackEdition content={infoText}>
            <Icon style={{ height: '20px' }} name="info circle" />
          </Tooltip>
        </div>
        <div className="other-contents">{contentJSX}</div>
      </div>
    );
  };

  render() {
    const { chartData } = this.props;

    if (hasArrayElement(chartData)) {
      return this.renderWithContent();
    } else {
      return this.renderWithoutContent();
    }
  }
}
