import {
  GET_WORKFLOW_RUN_OVERVIEW,
  GET_WORKFLOW_RUNS_LIST,
  GET_WORKFLOW_RUN_COVERAGE,
} from 'store/constants/workflow-runs';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

export function getWorkflowRuns(
  ciProvider,
  repoOwner,
  repoName,
  pageNo,
  pageSize,
  workflowIds,
  branches,
  conclusions,
  events,
  hideFork,
) {
  const data = {}; //Fetch All Data..
  console.log('API is ', CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/workflow-runs`);
  if (pageNo !== undefined && pageSize !== undefined && workflowIds !== undefined) {
    data['page'] = pageNo;
    data['size'] = pageSize;
    data['workflowIds'] = workflowIds;
    data['branches'] = branches;
    data['conclusions'] = conclusions;
    data['events'] = events;
    data['hideForkBranch'] = hideFork;
  }
  return {
    type: GET_WORKFLOW_RUNS_LIST,
    payload: axios.post(CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/workflow-runs`, data, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getWorkflowRunOverview(workflowRunId) {
  return {
    type: GET_WORKFLOW_RUN_OVERVIEW,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getWorkflowRunCoverage(workflowRunId, jobId) {
  return {
    type: GET_WORKFLOW_RUN_COVERAGE,
    payload: axios.get(CONFIG.apiUrl + `/workflow-runs/${workflowRunId}/jobs/${jobId}/coverage-result/`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
