import React from 'react';
import './ALink.scss';

export const ALink = ({ children, target = '_blank', ...props }) => {
  return (
    <a {...props} className="ALink" target={target} rel="noopener noreferrer">
      {children}
    </a>
  );
};

export const ButtonLink = ({ children, target = '_blank', ...props }) => {
  return (
    <a {...props} className="ButtonLink" target={target} rel="noopener noreferrer">
      {children}
    </a>
  );
};
