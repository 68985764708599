import {
  GET_TEST_VIEW_LIST_FULFILLED,
  GET_TEST_VIEW_LIST_PENDING,
  GET_TEST_VIEW_LIST_REJECTED,
} from 'store/constants/test-view-list';

const initialState = {
  testViewList: {},
  fetching: false,
  fetched: false,
  error: null,
};

export default function testViewList(state = initialState, action) {
  switch (action.type) {
    case GET_TEST_VIEW_LIST_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_TEST_VIEW_LIST_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        testViewList:
          action.payload.data.pageNumber === 0
            ? action.payload.data
            : { ...action.payload.data, content: [...state.testViewList.content, ...action.payload.data.content] },
      };
    case GET_TEST_VIEW_LIST_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
