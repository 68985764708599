import React, { Component } from 'react';
import './WorkflowSummaryIcon.scss';
import { getWorkflowStatusIcon } from 'utils/workflow-status-icon';
import routeList, { computePath } from 'routes';
import { WORKFLOWRUN_STATUS } from 'utils/workflowrun-status';
import { Link } from 'react-router-dom';

class WorkflowSummaryIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workflowColorDisabled: true,
    };
  }

  render() {
    let style = this.props.styleClass;
    const { workflowRun, ciProvider, repoOwner, repoName } = this.props;
    let link = computePath(routeList.workflowRunTimeline.path, {
      workflowId: workflowRun.id,
      ciProvider: ciProvider,
      repoOwner: repoOwner,
      repoName: repoName,
    });

    if (this.props.workflowRun.status === WORKFLOWRUN_STATUS.QUEUED) {
      style = style + ' still-progress';
      link = '#';
    }

    return (
      <Link className="link-wrapper-hover" to={link}>
        <div
          key={this.props.workflowRun.id}
          onMouseOver={() =>
            this.setState({
              workflowColorDisabled: false,
            })
          }
          onMouseLeave={() =>
            this.setState({
              workflowColorDisabled: true,
            })
          }
          className={style}
        >
          {this.state.workflowColorDisabled && getWorkflowStatusIcon(this.props.workflowRun, this.props.overrideColor)}
          {!this.state.workflowColorDisabled && getWorkflowStatusIcon(this.props.workflowRun)}
        </div>
      </Link>
    );
  }
}

export default WorkflowSummaryIcon;
