import React, { Component } from 'react';
import {
  OverviewInfoCell,
  OverviewInfoContainer,
  OverviewInfoLinkCell,
  OverviewInfoRow,
  OverviewInfoValue,
  OverviewInfoValueWithoutTooltip,
} from 'custom-components/Overview/OverviewInfo';
import { formatDateShortMonthNoYearWithTime } from 'utils/date-util';
import { ReviewersComponent } from 'custom-components/ReviewersComponent/ReviewersComponent';
import { DOMAIN_ICON } from 'assets/font-icons/IconManager';

export class PROverviewInfo extends Component {
  renderPROverviewInfo = () => {
    let creationDate = '';
    let prOwner = '';
    let prNo = '';
    let reviewers = '';
    const { fetched, pullRequestOverview } = this.props.pullRequestOverview;
    if (fetched) {
      creationDate = formatDateShortMonthNoYearWithTime(pullRequestOverview.summary.createdAt);
      prOwner = pullRequestOverview.summary.user.login;
      prNo = '#' + pullRequestOverview.summary.number;
      reviewers = pullRequestOverview.summary.requestedReviewers.map(el => el.login).join(',');
    }

    return (
      <OverviewInfoContainer>
        <OverviewInfoRow>
          <OverviewInfoCell>
            <OverviewInfoValue
              subTitle="Date"
              styleClassName="date"
              iconName={DOMAIN_ICON.GITHUB.EXEC_DATE}
              tooltipContent={creationDate}
              value={creationDate}
            />
          </OverviewInfoCell>
          <OverviewInfoCell>
            <OverviewInfoValue
              subTitle="By"
              iconName={DOMAIN_ICON.GITHUB.PR_OWNER}
              tooltipContent={prOwner}
              value={prOwner}
            />
          </OverviewInfoCell>
          <OverviewInfoLinkCell styleClassName="cursor" to={{ pathname: '#' }}>
            <OverviewInfoValue
              subTitle="Pull Request Number"
              iconName={DOMAIN_ICON.GITHUB.PULL_REQUEST}
              tooltipContent={prNo}
              value={prNo}
            />
          </OverviewInfoLinkCell>
          <OverviewInfoCell>
            <OverviewInfoValueWithoutTooltip
              subTitle="Reviewers"
              iconName={DOMAIN_ICON.GITHUB.PR_REVIEWERS}
              tooltipContent={reviewers}
              value={
                <ReviewersComponent
                  requestedReviewers={pullRequestOverview.summary?.requestedReviewers}
                  withText={true}
                />
              }
            />
          </OverviewInfoCell>
        </OverviewInfoRow>
      </OverviewInfoContainer>
    );
  };

  render() {
    return this.renderPROverviewInfo();
  }
}
