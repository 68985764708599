import history from 'history.js';

import lock from './lock-factory';
import { ROLE_ADMIN } from 'auth/constants';
import { AUTH_CONFIG } from './auth0-variables';
import { CONFIG, getForesightAppUrl } from 'utils/config-util.js';
import routeList, { shouldAddIsNewUserToUrl } from 'routes';
import { updateLastLoginDate } from 'store/actions/user-info';

export default class Auth {
  constructor() {
    this.handleAuthentication();
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.userHasScopes = this.userHasScopes.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.isAdmin = this.isAdmin.bind(this);
    this.requestedScopes = 'openid profile name email';
  }

  handleAuthentication() {
    // Add a callback for Lock's `authenticated` event
    lock.on('authenticated', this.setSession.bind(this));
    // Add a callback for Lock's `authorization_error` event
    lock.on('authorization_error', () => {});
  }

  checkProfileExists = () => {
    return localStorage.getItem('profile') != null;
  };

  getCurrentUser() {
    if ('true' === CONFIG.DISABLE_AUTH) {
      return { fullname: 'anonymous', anonymous: true };
    }

    return JSON.parse(localStorage.getItem('profile'));
  }

  setSession(authResult) {
    lock.hide();

    if ('true' === CONFIG.DISABLE_AUTH) {
      return;
    }
    const namespace = AUTH_CONFIG.auth0CustomDataNamespace;
    const idTokenPayload = authResult.idTokenPayload;
    if (authResult && authResult.accessToken && idTokenPayload && idTokenPayload[namespace]) {
      // Set the time that the access token will expire at
      let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());

      const userData = idTokenPayload[namespace];

      const isNewUser = userData.isNewUser;

      // If there is a value on the `scope` param from the authResult,
      // use it to set scopes in the session for the user. Otherwise
      // use the scopes as requested. If no scopes were requested,
      // set it to nothing
      const scopes = authResult.scope || this.requestedScopes || '';

      localStorage.setItem('accessToken', authResult.accessToken);
      localStorage.setItem('idToken', authResult.idToken);
      localStorage.setItem('expires_at', expiresAt);
      localStorage.setItem('scopes', JSON.stringify(scopes));
      updateLastLoginDate();
      this.setProfile(userData, isNewUser);
    }
  }

  setProfile(userData, isNewUser) {
    localStorage.setItem('profile', JSON.stringify(userData));

    let redirectionURL = shouldAddIsNewUserToUrl(routeList.repositories.path, isNewUser);

    const installationId = localStorage.getItem('github_installation_id');
    if (installationId) {
      // console.log("IIDDD: ", installationId);
      redirectionURL = shouldAddIsNewUserToUrl(
        `${routeList.startGitHubExistedProjectRepoSelectionPage.path}?github_installation_id=${installationId}`,
        isNewUser,
      );
    }
    history.replace(redirectionURL);
  }

  clearLocalStorage() {
    localStorage.removeItem('persist:root'); // this is to clear redux-persist storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('profile');
    localStorage.removeItem('idToken');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('scopes');
    localStorage.removeItem('user');
    localStorage.removeItem('isNewUserTimer');

    // here do not use localStorage.clear(), , because it creates problem for url redirection flow.
  }

  logout(withError) {
    if ('true' === CONFIG.DISABLE_AUTH) {
      window.location.href = getForesightAppUrl();
      return;
    }
    // Clear access token and ID token from local storage
    this.clearLocalStorage();
    //This localStorage prop not added clearLocalStorage because clearLocalStorage calls different function
    //not only logout. When auth.isAuthenticated call reset onboarding-signupsource-token
    //which is add when LandingPage first and removed by auth.isAuthenticated
    localStorage.removeItem('onboarding-signupsource-token');
    localStorage.removeItem('github_installation_id');

    // navigate to the landing route
    if (withError) {
      // console.log("logout; withError: ", withError)
      lock.logout({
        returnTo: AUTH_CONFIG.logoutReturnToError,
      });
    } else {
      lock.logout({
        returnTo: AUTH_CONFIG.logoutReturnTo,
      });
    }
  }

  getAccessToken() {
    if ('true' === CONFIG.DISABLE_AUTH) {
      return null;
    }
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      /* eslint-disable no-console */
      console.log('ERROR: no token found!');
      /* eslint-enable no-console */
      history.replace('/callback');
    }
    return accessToken;
  }

  getIdToken() {
    if ('true' === CONFIG.DISABLE_AUTH) {
      return null;
    }
    const idToken = localStorage.getItem('idToken');
    if (!idToken) {
      /* eslint-disable no-console */
      console.log('ERROR: no id token found!');
      /* eslint-enable no-console */
      history.replace('/callback');
    }
    return idToken;
  }

  refreshTokenOrForceLogout = reloadPath => {
    lock.checkSession({}, (err, authResult) => {
      console.log('refreshTokenOrForceLogout; err, authResult: ', err, authResult);

      if (err) {
        this.logout();
      } else {
        this.setSession(authResult);
        // We needed to add page reload becuase page was stucking at loading state.
        window.location.replace(reloadPath);
      }
    });
  };

  isAuthenticated() {
    console.log('authjs, isAuthenticated');
    if ('true' === CONFIG.DISABLE_AUTH) {
      return true;
    }

    const idToken = localStorage.getItem('idToken');
    const profile = localStorage.getItem('profile');

    if (!!idToken && !!profile) {
      // console.log('idToken&&profile exists; idToken, profile: ', idToken, JSON.parse(profile));

      lock.checkSession({}, (err, authResult) => {
        // console.log('idToken&&profile exists, lock.checkSesssion(); err, authResult: ', err, authResult);

        if (err) {
          this.logout(false);
          return false;
        } else {
          // If emails of the valid sessions are different, should we logout? It is better we shouldn't.
          // This prevents 2 different accounts log in to the different apps at the same time.
          // App's session is always set to the most recent auth0 session.

          // console.log('FORESIGHT NOT-ERR-ELSE; authResult, currentUser: ', authResult, this.getCurrentUser());

          const currentUser = this.getCurrentUser();
          const currentUserEmail = currentUser.email;
          const sessionUserEmail = authResult.idTokenPayload.email;

          if (currentUserEmail !== sessionUserEmail) {
            // console.log('DIFFERENT USERS; current at localStorage vs auth0 session!!');
            this.clearLocalStorage();
            this.setSession(authResult);
            window.location.reload();
          }
        }
      });

      return true;
    } else {
      // If there is no idToken set on the localStorage, then check for an existent session.
      // If there is an existent session, then call setSession method with authResult obj.
      lock.checkSession({}, (err, authResult) => {
        console.log('authjs, isAuthenticated, lock.checkSesssion(); err, authResult: ', err, authResult);

        if (err) {
          this.clearLocalStorage();
          return false;
        } else {
          // If authentication via SSO was successful, then call setSession
          // console.log("authentication via SSO was successful, then call setSession");
          this.setSession(authResult);
          return true;
        }
      });

      // NOTE: Lock does parseHash automatically by default(autoParseHash) => https://auth0.com/docs/libraries/lock/lock-configuration#autoparsehash-boolean-
      // So that we do not need to call a parseHash method like webAuth below;
      // https://github.com/auth0-samples/auth0-sso-sample/blob/master/app1.com/app.js

      // NOTE: Auth0 SSO has problems with chrome incognito.
      // https://community.auth0.com/t/sso-doesnt-work-when-incognito-iframe/45604/8

      // NOTE: Social logins do not work with localhost, `consent required` error is fired
      // https://auth0.com/docs/authorization/user-consent-and-third-party-applications#skip-consent-for-first-party-applications
      // https://auth0.com/docs/authorization/configure-silent-authentication
      // https://auth0.com/docs/libraries/lock/lock-api-reference#checksession-
      // https://auth0.com/docs/compliance/gdpr/gdpr-track-consent-with-lock
    }
  }

  isAdmin(roles) {
    return roles.includes(ROLE_ADMIN);
  }

  userHasScopes(scopes) {
    const grantedScopes = JSON.parse(localStorage.getItem('scopes')).split(' ');
    return scopes.every(scope => grantedScopes.includes(scope));
  }
}
