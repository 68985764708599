import React, { Component } from 'react';
import './GitHubAppExistionProjectRepoSelectionChangePage.scss';
import {
  ProjectSelectionDisplayAndModifyContainer,
  PopupPageDesignCloseIconHeaderContainer,
  RepositorySelectableListContainer,
} from 'containers';
import routeList from 'routes';
import { CONFIG } from 'utils/config-util';
import { getCiProviderConfigLink } from 'utils/git-provider-config-links';
import { hasArrayElement } from 'utils/array-util';
import { SESSION_URL_KEY } from 'utils/redirection-force-util';
import { BlueButton, GrayBorderButton } from 'custom-components/Button/Button';
import { ToastConfig } from 'utils/toast-util';
import { toast } from 'react-toastify';

class GitHubAppExistionProjectRepoSelectionChangePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newRepoList: [],
    };
  }

  componentDidMount() {
    const { projects, repos, setRepoSelectedProject } = this.props;
    const { repoSelectedProject } = projects;

    // Look for 'github_installation_id' when redirected from GitHub app.
    let installationId = new URLSearchParams(this.props.location.search).get('github_installation_id');
    installationId = installationId === null ? localStorage.getItem('github_installation_id') : installationId; //Extra Check If installationId URL Clear and  is null then lookat LocalStorage
    console.log('GitHubAppExistionProjectRepoSelectionChangePage' + installationId);
    if (installationId) {
      this.props.createCIProviderIntegration(
        installationId,
        'github',
        this.onCreateSourceProviderIntegrationSuccess,
        this.onCreateSourceProviderIntegrationFail,
      );

      localStorage.removeItem('github_installation_id');
      localStorage.removeItem(SESSION_URL_KEY);
    } else {
      if (repos.repoList.length === 1) {
        setRepoSelectedProject(repos.repoList[0].id);

        this.getCIProviderReposByProjectId(repos.repoList[0].id);
      } else {
        this.getCIProviderReposByProjectId(repoSelectedProject?.id);
      }
    }
  }

  getCIProviderReposByProjectId = projectId => {
    this.props.getCIProviderRepos('github', projectId, this.onGetCIProviderReposSuccess, this.onGetCIProviderReposFail);
  };

  handleProjectsFetchedSuccess = () => {
    const { projects } = this.props;
    console.log('Projects Fetched Succedded');

    // If user created projects created before..
    if (projects.projectList.length > 0) {
      // This is to clean 'installationId' from query params.
      this.props.history.replace({
        pathname: routeList.startGitHubExistedProjectRepoSelectionPage.path,
        search: '',
      });
    } else {
      this.props.history.replace({
        pathname: routeList.startCreateProjectSelectGithubRepos.path,
        search: '',
      });
    }
  };

  handleProjectsFetchedFailed = () => {
    console.log('Projects Fetched Failed');
    this.props.history.replace({
      pathname: routeList.startCreateProjectSelectGithubRepos.path,
      search: '',
    });
  };

  onCreateSourceProviderIntegrationSuccess = () => {
    console.log('onCreateSourceProviderIntegrationSuccess');
    this.getCIProviderReposByProjectId();

    //Extra Project Checked added
    const { projects } = this.props;
    if (projects.fetched) {
      console.log('GitHubAppExistionProjectRepoSelectionChangePage projects fetched');
      this.handleProjectsFetchedSuccess();
    } else {
      console.log('GitHubAppExistionProjectRepoSelectionChangePage projects not fetched yet');
      this.props.getAllProjects(this.handleProjectsFetchedSuccess, this.handleProjectsFetchedFailed);
    }
  };

  onCreateSourceProviderIntegrationFail = () => {
    console.log('onCreateSourceProviderIntegrationFail; props: ', this.props);
  };

  closeModal = () => {
    this.props.history.push(routeList.repositories.path);
  };

  onGetCIProviderReposSuccess = () => {
    const {
      ciRepos: { ciRepoList, isCIRepoListFetched },
    } = this.props;

    if (isCIRepoListFetched) {
      const copyArr = ciRepoList.map(item => {
        return {
          ...item,
          checked: item.watched,
        };
      });
      this.setState({ newRepoList: copyArr });
    }
  };
  onGetCIProviderReposFail = () => {
    console.log('onGetCIProviderReposFail');
  };

  handleSelectAllReposToggle = data => {
    const isAllReposSelected = data.checked;
    const { newRepoList } = this.state;
    let modifiedRepoList = [];
    if (isAllReposSelected) {
      // watch all repos
      modifiedRepoList = newRepoList.map(repo => {
        return {
          ...repo,
          checked: true,
        };
      });
    } else {
      // unwatch all repos
      modifiedRepoList = newRepoList.map(repo => {
        return {
          ...repo,
          checked: false,
        };
      });
    }

    this.setState({
      newRepoList: modifiedRepoList,
    });
  };

  handleCheckboxClick = (idx, data) => {
    const { newRepoList } = this.state;
    const foundItem = newRepoList.findIndex(item => item.repo.name === data.label);
    newRepoList[foundItem].checked = data.checked;

    this.setState({ newRepoList: [...newRepoList] });
  };

  cancelCreateProject = () => {
    this.closeModal();
  };

  renderFooterActionButtons = () => {
    const { isUpdateCIRepoListFetching } = this.props.ciRepos;
    const { newRepoList } = this.state;
    const { repoSelectedProject } = this.props.projects;

    return (
      <>
        <GrayBorderButton style={{ height: '40px' }} onClick={this.cancelCreateProject}>
          Cancel
        </GrayBorderButton>
        <BlueButton
          onClick={this.onCreateNewProjectWithSelectedRepo}
          loading={isUpdateCIRepoListFetching}
          disabled={
            isUpdateCIRepoListFetching ||
            !newRepoList.some(item => item.checked === true) ||
            repoSelectedProject?.id === undefined
          }
        >
          Start Watching
        </BlueButton>
      </>
    );
  };

  isSourceProviderExist = () => {
    const { ciProviderList } = this.props.ciConnect;
    return hasArrayElement(ciProviderList);
  };

  getCiProviderConfigLink = () => {
    const { ciProviderList } = this.props.ciConnect;

    let installationURL = CONFIG.FORESIGHT_GITHUB_APP;
    if (this.isSourceProviderExist()) {
      installationURL = getCiProviderConfigLink(ciProviderList[0]);
    }

    return installationURL;
  };

  onCreateNewProjectWithSelectedRepo = () => {
    const { newRepoList } = this.state;
    const { repoSelectedProject } = this.props.projects;

    const newRepoListForBackend = newRepoList.map(item => {
      return {
        ...item,
        watched: item.checked || false,
      };
    });

    this.props.updateCIProviderRepos(
      'github',
      repoSelectedProject.id,
      newRepoListForBackend,
      () => {
        console.log('updateCIProviderRepos success; props: ', this.props);
        this.props.history.push(routeList.startIntegrationGitHubTestRuns.path);
      },
      () => {
        console.log('updateCIProviderRepos error; props: ', this.props);
        toast.error('Watch Repo Update Project problem', ToastConfig);
      },
    );
  };

  render() {
    const { newRepoList } = this.state;
    const { ciRepoList } = this.props.ciRepos;
    const { ciProviderList } = this.props.ciConnect;
    const { isProjectSelectionDone, repoSelectedProject } = this.props.projects;
    return (
      <div className="existed-project-repo-selection-page-container">
        <PopupPageDesignCloseIconHeaderContainer />
        <div className="page-body-container">
          <div className="page-body">
            <div className="page-title">Select repositories</div>
            <div className="project-title">Project</div>
            <ProjectSelectionDisplayAndModifyContainer
              getCIProviderReposByProjectId={this.getCIProviderReposByProjectId}
            />
            <div className="repository-title-container">
              <div className="repository-title-elements title">Repositories</div>
            </div>
            <div className="repository-desc-title">
              This is the list of GitHub repositories you gave permission. If you can't find the repository you are
              looking for, please{' '}
              <a
                className="ci-config-link"
                href={this.getCiProviderConfigLink()}
                target="_blank"
                rel="noopener noreferrer"
              >
                check your GitHub permissions.
              </a>
            </div>
            {isProjectSelectionDone && repoSelectedProject !== null && (
              <RepositorySelectableListContainer
                prevRepoList={ciRepoList}
                newRepoList={newRepoList}
                ciProviderList={ciProviderList}
                handleCheckboxClick={this.handleCheckboxClick}
                handleSelectAllReposToggle={this.handleSelectAllReposToggle}
              />
            )}
          </div>
          <div className="section-divider" />
          <div className="footer-actions">{this.renderFooterActionButtons()}</div>
        </div>
      </div>
    );
  }
}

export default GitHubAppExistionProjectRepoSelectionChangePage;
