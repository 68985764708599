import Loadable from 'react-loadable';
import React from 'react';
import { ErrorPage, LoadingPage } from 'components';

const Loading = props => {
	if (props.error) {
		return <ErrorPage />;
	} else if (props.pastDelay) {
		return <LoadingPage />;
	} else {
		return null;
	}
};

const AsyncRepositoryWorkflowPageContainer = Loadable({
	loader: () => import('./RepositoryWorkflowPageContainer'),
	loading: Loading,
	delay: 300,
});

export { AsyncRepositoryWorkflowPageContainer };
