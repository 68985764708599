import {
  GET_JOB_TEST_SUMMARY_PENDING,
  GET_JOB_TEST_SUMMARY_REJECTED,
  GET_JOB_TEST_SUMMARY_FULFILLED,
  GET_JOB_FAILED_TESTS_PENDING,
  GET_JOB_FAILED_TESTS_REJECTED,
  GET_JOB_FAILED_TESTS_FULFILLED,
  GET_JOB_COVERAGE_PENDING,
  GET_JOB_COVERAGE_REJECTED,
  GET_JOB_COVERAGE_FULFILLED,
} from 'store/constants/workflow-jobs-tests';

const workflowJobTestsInitial = {
  testingSummary: {
    fetching: false,
    fetched: false,
    error: null,
    data: {},
  },
  failedTests: {
    fetching: false,
    fetched: false,
    error: null,
    data: [],
  },
  testingCoverage: {
    fetching: false,
    fetched: false,
    error: null,
    data: [],
  },
};

export default function workflowJobTests(state = workflowJobTestsInitial, action) {
  switch (action.type) {
    // Get job test summary
    case GET_JOB_TEST_SUMMARY_PENDING:
      return {
        ...state,
        testingSummary: {
          fetching: true,
          fetched: false,
          error: false,
          data: {},
        },
      };
    case GET_JOB_TEST_SUMMARY_FULFILLED:
      return {
        ...state,
        testingSummary: {
          fetching: false,
          fetched: true,
          error: false,
          data: action.payload.data,
        },
      };
    case GET_JOB_TEST_SUMMARY_REJECTED:
      return {
        ...state,
        testingSummary: {
          fetching: false,
          fetched: false,
          error: true,
          data: {},
        },
      };

    // Failed Tests

    case GET_JOB_FAILED_TESTS_PENDING:
      return {
        ...state,
        failedTests: {
          fetching: true,
          fetched: false,
          error: false,
          data: [],
        },
      };
    case GET_JOB_FAILED_TESTS_FULFILLED:
      return {
        ...state,
        failedTests: {
          fetching: false,
          fetched: true,
          error: false,
          data: action.payload.data,
        },
      };
    case GET_JOB_FAILED_TESTS_REJECTED:
      return {
        ...state,
        failedTests: {
          fetching: false,
          fetched: false,
          error: true,
          data: {},
        },
      };

    // Coverage
    case GET_JOB_COVERAGE_PENDING:
      return {
        ...state,
        testingCoverage: {
          fetching: true,
          fetched: false,
          error: false,
          data: [],
        },
      };
    case GET_JOB_COVERAGE_FULFILLED:
      return {
        ...state,
        testingCoverage: {
          fetching: false,
          fetched: true,
          error: false,
          data: action.payload.data,
        },
      };
    case GET_JOB_COVERAGE_REJECTED:
      return {
        ...state,
        testingCoverage: {
          fetching: false,
          fetched: false,
          error: true,
          data: [],
        },
      };

    default:
      return state;
  }
}
