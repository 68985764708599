import { Tooltip } from 'components/Tooltip';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { millisToMAndS } from 'utils/date-util';
import './AvgAndTotalDurationSummary.scss';


const totalInfoText = 'Combined total duration of all your workflows, including all statuses. You can assess your efforts on your workflows by looking at the total duration.';
const avgInfoText = 'Average duration is calculated among the successful workflow runs. Statuses other than successful runs may take less or longer than the average and may be an outlier. Therefore they are omitted.';
export class AvgAndTotalDurationSummary extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data, title } = this.props;

        return (
            <div className='avg-total-duration-container'>
                <div className="header">
                    <div className="title">{title}</div>
                </div>
                <div className='cards-container'>
                    <div className='sub-avg-total-duration-card' >
                        <div className='info-part'>
                            <div className='sub-title'>Total Duration&nbsp;<Tooltip hoverable blackEdition content={totalInfoText}>
                                <Icon style={{ height: '20px' }} name="info circle" />
                            </Tooltip></div>
                            <div className='sub-value-container'>
                                <div className='sub-value'>{millisToMAndS(data.totalDuration)}</div>
                            </div>
                        </div>
                    </div>
                    <div className='sub-avg-total-duration-card' >
                        <div className='info-part'>
                            <div className='sub-title'>Average Duration&nbsp;<Tooltip hoverable blackEdition content={avgInfoText}>
                                <Icon style={{ height: '20px' }} name="info circle" />
                            </Tooltip></div>
                            <div className='sub-value'>{millisToMAndS(data.averageDuration)}</div>
                        </div>
                    </div>
                </div >
            </div >
        );

    }
}