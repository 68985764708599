import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';
import {
  GET_PR_SUMMARY_STATUS,
  GET_PR_SUMMARY_TEST_RUN_LIST,
  GET_PR_SUMMARY_WORKFLOW_LIST,
} from 'store/constants/pull-request-summary';

export function getPullRequestSummaryStatus(ciProvider, repoOwner, repoName, prId) {
  return {
    type: GET_PR_SUMMARY_STATUS,
    payload: axios.get(CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/pull-requests/${prId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getPullRequestSummaryWorkflowList(ciProvider, repoOwner, repoName, prId) {
  return {
    type: GET_PR_SUMMARY_WORKFLOW_LIST,
    payload: axios.get(
      CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/pull-requests/${prId}/workflows`,
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function getPullRequestSummaryTestRunList(ciProvider, repoOwner, repoName, prId) {
  return {
    type: GET_PR_SUMMARY_TEST_RUN_LIST,
    payload: axios.get(CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/pull-requests/${prId}/tests`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
