export const GET_TEST_SUITES_OVERVIEW_DETAIL_LIST = 'GET_TEST_SUITES_OVERVIEW_DETAIL_LIST';
export const GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_PENDING = 'GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_PENDING';
export const GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_REJECTED = 'GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_REJECTED';
export const GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_FULFILLED = 'GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_FULFILLED';

export const GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY = 'GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY';
export const GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY_PENDING = 'GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY_PENDING';
export const GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY_REJECTED = 'GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY_REJECTED';
export const GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY_FULFILLED = 'GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY_FULFILLED';

export const GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS = 'GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS';
export const GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS_PENDING =
  'GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS_PENDING';
export const GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS_REJECTED =
  'GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS_REJECTED';
export const GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS_FULFILLED =
  'GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS_FULFILLED';

export const SET_TEST_SUITES_OVERVIEW_DETAIL_SELECTED_HIDE_FORK = 'SET_TEST_SUITES_OVERVIEW_DETAIL_SELECTED_HIDE_FORK';

export const SET_TEST_SUITES_OVERVIEW_DETAIL_FILTER_OBJECT = 'SET_TEST_SUITES_OVERVIEW_DETAIL_FILTER_OBJECT';
