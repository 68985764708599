import React, { Component } from 'react';
import './TestRunTestLogViewer.scss';
import { getTestCaseLogsDetail } from 'utils/test-detail-util';
import { formatTime } from 'utils/date-util';
import { hasArrayElement } from 'utils/array-util';
import { ErrorStackViewer, LoadingDimmer, NoDataAvailable, SomethingError } from 'components';

class TestRunTestLogViewer extends Component {
	render() {
		const { testDetailMap } = this.props.testDetails;
		const { transactionId } = this.props.match.params;
		const testLogsObj = getTestCaseLogsDetail(testDetailMap, transactionId);
		if (testLogsObj) {
			const { fetched, fetching, error, testLogs } = testLogsObj;
			let logMsg = '';
			if (hasArrayElement(testLogs)) {
				testLogs.forEach(el => {
					const { logMessage } = el;
					if (typeof logMessage === 'string' && logMessage.length > 0) {
						const lastOne = logMessage.substr(logMessage.length - 1);
						if (lastOne !== '\n') el.modifiedLogMessage = logMessage + '\n';
						else el.modifiedLogMessage = logMessage;
					} else {
						el.modifiedLogMessage = logMessage;
					}
				});

				logMsg = testLogs
					.map(el => {
						const { logLevel, modifiedLogMessage, logTime } = el;
						return `${logLevel} - ${formatTime(logTime)} - ${modifiedLogMessage}`;
					})
					.join('');
			}

			if (fetching) {
				return (
					<div className="logs-loading-msg-container">
						<LoadingDimmer msg="Loading" />
					</div>
				);
			} else if (error) {
				return (
					<div className="error-logs-msg-container">
						<SomethingError msg={error} />
					</div>
				);
			} else if (fetched) {
				if (logMsg) {
					return (
						<>
							<ErrorStackViewer stack={logMsg} />
						</>
					);
				} else {
					return (
						<div className="no-logs-data-msg-container">
							<NoDataAvailable msg="No Logs Found" />
						</div>
					);
				}
			}
		}

		return <div>Table Logs</div>;
	}
}

export default TestRunTestLogViewer;
