import React, { Component } from 'react';
import { formatDateFromNow, formatDateWithTime, millisToMinutesAndSeconds } from 'utils/date-util';
import routeList, { computePath } from 'routes';
import { hasArrayElement } from 'utils/array-util';
import { renderNoDataAvailableForTestRuns } from 'components/ComponentPlaceholder';
import { Tooltip } from 'components';
import { Link } from 'react-router-dom';
import './TestRunsInfiniteTable.scss';
import { ForesightAccordionWithAnimation } from 'custom-components/Accordion/Accordion';
import { ListCardsBody, ListCardsContainer, ListCardsHeader } from 'custom-components/ListCards/ListCards';
import { ForesightToggle } from 'custom-components/RadioButton/RadioButton';
import { isEqualDeep } from 'utils/common-util';
import { DOMAIN_ICON, UI_ICON } from 'assets/font-icons/IconManager';

export const remoteRowCount = 10;

const ellipsisTitleLength = 45;

let columnStyle = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  alignItems: 'center',
  letterSpacing: '-0.01em',
  color: '#788496',
};

class TestRunsInfiniteTableItem extends Component {
  isStillRunning = testrunStatus => {
    return testrunStatus === 'Running';
  };
  testHeaderContent = () => {
    const {
      data,
      data: { testRunSummary },
    } = this.props;
    const everyTestPassed = testRunSummary.failedCount === 0;
    const title = data.displayTitle || data['workflow'].name;

    return (
      <>
        {data.id ? (
          <div key={data.id} className="test-run-accordion-main-section-container">
            <div className="test-run-accordion-section-container">
              <div className="test-run-accordion-first-section-header-main-container">
                <div className="test-run-accordion-header-container">
                  <div className="test-run-accordion-header-icon">
                    <Tooltip blackEdition content="Created Date">
                      <i className={DOMAIN_ICON.GITHUB.DATE} />
                    </Tooltip>
                  </div>
                  <Tooltip
                    blackEdition
                    position="bottom left"
                    content={<div>Open since {formatDateWithTime(data.createdAt)}</div>}
                  >
                    <div className="test-run-accordion-header-text">{formatDateFromNow(data.createdAt)} </div>
                  </Tooltip>
                </div>
                <div className="test-run-accordion-header-container">
                  <div className="test-run-accordion-header-icon">
                    <Tooltip blackEdition content="Workflow Duration (mm:ss)">
                      <i className="icon-elapsed-time" />
                    </Tooltip>
                  </div>
                  <div className="test-run-accordion-header-text">{millisToMinutesAndSeconds(data.duration)}</div>
                </div>
                <div className="test-run-accordion-header-container">
                  <div className="test-run-accordion-header-icon">
                    <Tooltip blackEdition content="Commit">
                      <i className={DOMAIN_ICON.GITHUB.COMMIT} />
                    </Tooltip>
                  </div>
                  <div className="test-run-accordion-header-text">{data?.headCommit?.id.slice(0, 6)}</div>
                </div>
              </div>
              <div className="test-run-accordion-second-section-header-main-container">
                <div className="test-run-accordion-header-container">
                  {title.length > 62 ? (
                    <Tooltip blackEdition content={title}>
                      <div className="test-run-accordion-header-main-text">{title}</div>
                    </Tooltip>
                  ) : (
                    <div className="test-run-accordion-header-main-text">{title}</div>
                  )}
                </div>
                <div className="test-run-accordion-header-container">
                  <div className="test-run-accordion-header-icon">
                    <Tooltip blackEdition content="Workflow Name">
                      <i className="icon-workflows" />
                    </Tooltip>
                  </div>
                  <div className="test-run-accordion-header-text">{data['workflow'].name}</div>
                </div>
                <div className="test-run-accordion-header-container">
                  <div className="accordion-content-test-left-part-items">
                    <div className="test-run-accordion-content-icon">
                      <Tooltip blackEdition content="Total Failed">
                        <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.FAILED} />
                      </Tooltip>
                    </div>
                    <div className="test-run-accordion-content-value">{testRunSummary.failedCount}</div>
                  </div>
                  <div className="accordion-content-test-left-part-items">
                    <div className="test-run-accordion-content-icon">
                      <Tooltip blackEdition content="Total Passed">
                        <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.CHECK} />
                      </Tooltip>
                    </div>
                    <div className="test-run-accordion-content-value">{testRunSummary.successfulCount}</div>
                  </div>

                  <div className="accordion-content-test-left-part-items">
                    <div className="test-run-accordion-content-icon">
                      <Tooltip blackEdition content="Total Skipped">
                        <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.SKIPPED} />
                      </Tooltip>
                    </div>
                    <div className="test-run-accordion-content-value">{testRunSummary.ignoredCount}</div>
                  </div>
                  <div className="accordion-content-test-left-part-items">
                    <div className="test-run-accordion-content-icon">
                      <Tooltip blackEdition content="Total Aborted">
                        <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.ABORTED} />
                      </Tooltip>
                    </div>
                    <div className="test-run-accordion-content-value">{testRunSummary.abortedCount}</div>
                  </div>
                  <div className="accordion-content-test-left-part-items">
                    <div className="test-run-accordion-content-icon">
                      <Tooltip blackEdition content="Total Duration">
                        <i className="icon-elapsed-time" />
                      </Tooltip>
                    </div>
                    <div className="test-run-accordion-content-value">
                      {millisToMinutesAndSeconds(testRunSummary.totalDuration)}
                    </div>
                  </div>
                </div>

                <div className="test-run-accordion-header-container">
                  <div className="test-run-accordion-header-footer-text">
                    {everyTestPassed ? (
                      <>
                        All test runs are <span className="successful-span">successful</span>
                      </>
                    ) : (
                      <>
                        Some test runs are <span className="failed-span">failed</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>N/A</div>
        )}
      </>
    );
  };

  testRunTagsGenerator = (tagsData, hasTwoItem) => {
    if (hasArrayElement(tagsData) && hasTwoItem) {
      return tagsData.map((item, index) => {
        if (index < 2) {
          if ((item.key + item.value).length < 18) {
            return (
              <div key={item.key + item.value} className="test-run-content-tags">
                {item.key}:{item.value}
              </div>
            );
          } else {
            return (
              <Tooltip key={item.key + item.value} blackEdition content={`${item.key}: ${item.value}`}>
                <div className="test-run-content-tags">
                  {item.key}:{item.value}
                </div>
              </Tooltip>
            );
          }
        }
      });
    } else if (hasArrayElement(tagsData) && !hasTwoItem) {
      return tagsData.map((item, index) => {
        if (index >= 2) {
          if ((item.key + item.value).length < 40) {
            return (
              <div key={item.key + item.value} className="test-run-content-tags">
                {item.key}:{item.value}
              </div>
            );
          } else {
            return (
              <Tooltip key={item.key + item.value} blackEdition content={`${item.key}: ${item.value}`}>
                <div className="test-run-content-tags">
                  {item.key}:{item.value}
                </div>
              </Tooltip>
            );
          }
        }
      });
    }
  };

  renderTestContent = () => {
    const {
      data: { testRunList },
    } = this.props;
    // const title = data.displayTitle || data.commitMessage;
    const { ciProvider, repoOwner, repoName } = this.props.matchParams;
    return testRunList.map(item => {
      return (
        <Link
          key={item.testRunId}
          to={
            !this.isStillRunning(item.status)
              ? computePath(routeList.testRunOverview.path, {
                  ciProvider: ciProvider,
                  repoOwner: repoOwner,
                  repoName: repoName,
                  testRunId: item.testRunId,
                })
              : '#'
          }
        >
          <div className="accordion-test-content-main-container">
            <div className="accordion-content-test-first-part">
              <div className="test-run-accordion-content-icon">
                <Tooltip blackEdition content={item.failedCount === 0 ? 'Success' : 'Failed'}>
                  <i
                    className={
                      item.failedCount === 0
                        ? DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.SUCCESS
                        : DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.TIMEOUT
                    }
                  />
                </Tooltip>
              </div>
              {item?.jobName?.length > ellipsisTitleLength ? (
                <Tooltip blackEdition content={item.jobName}>
                  <div className="test-run-accordion-content-value-and-tags-container">
                    <div className="test-run-accordion-content-value">{item.jobName}</div>
                    <div className="tags-container-for-test-run">{this.testRunTagsGenerator(item.userTags)}</div>
                  </div>
                </Tooltip>
              ) : (
                <div className="test-run-accordion-content-value-and-tags-container">
                  <div className="test-run-accordion-content-value">{item.jobName}</div>
                  <div className="tags-container-for-test-run">
                    {this.testRunTagsGenerator(item.userTags, true)}
                    {item?.userTags && item?.userTags.length >= 3 && (
                      <Tooltip
                        blackEdition
                        hoverable
                        position="top center"
                        content={
                          <div
                            className="content-tags-test-run-container"
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            {this.testRunTagsGenerator(item.userTags, false)}
                          </div>
                        }
                      >
                        <div className="show-more-icon-tags">
                          <i className={UI_ICON.MORE.HORIZONTAL} />
                          Show more tags
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="accordion-content-test-left-part">
              <div className="accordion-content-test-left-part-items">
                <div className="test-run-accordion-content-icon">
                  <Tooltip blackEdition content="Test Status Failed">
                    <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.FAILED} />
                  </Tooltip>
                </div>
                <div className="test-run-accordion-content-value">{item.failedCount}</div>
              </div>
              <div className="accordion-content-test-left-part-items">
                <div className="test-run-accordion-content-icon">
                  <Tooltip blackEdition content="Test Status Passed">
                    <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.CHECK} />
                  </Tooltip>
                </div>
                <div className="test-run-accordion-content-value">{item.successfulCount}</div>
              </div>
              <div className="accordion-content-test-left-part-items">
                <div className="test-run-accordion-content-icon">
                  <Tooltip blackEdition content="Skipped">
                    <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.SKIPPED} />
                  </Tooltip>
                </div>
                <div className="test-run-accordion-content-value">{item.ignoredCount}</div>
              </div>
              <div className="accordion-content-test-left-part-items">
                <div className="test-run-accordion-content-icon">
                  <Tooltip blackEdition content="Aborted">
                    <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.ABORTED} />
                  </Tooltip>
                </div>
                <div className="test-run-accordion-content-value">{item.abortedCount}</div>
              </div>
              <div className="accordion-content-test-left-part-items">
                <div className="test-run-accordion-content-icon">
                  <Tooltip blackEdition content="Duration">
                    <i className="icon-elapsed-time" />
                  </Tooltip>
                </div>
                <div className="test-run-accordion-content-value">{millisToMinutesAndSeconds(item.duration)}</div>
              </div>
            </div>
          </div>
        </Link>
      );
    });
  };

  render() {
    const { allOpen } = this.props;
    return (
      <ForesightAccordionWithAnimation
        header={this.testHeaderContent()}
        content={this.renderTestContent()}
        isOpened={allOpen}
        contentScroll={false}
      />
    );
  }
}

export default class TestRunsInfiniteTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showIntegrationMethods: false,
      afterKey: null,
      size: remoteRowCount,
      fetching: false,
      allCollapseOpen: true,
      loadingToggle: false,
    };
  }

  componentDidMount() {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    const { hideForkBranch, selectedTestRunsBranches, selectedTestRunsStatuses, selectedTestRunsTags } =
      this.props.testRuns;

    const isFirstPage = true;
    document.getElementById('testruns-scrollable').addEventListener('scroll', this.trackScrolling);
    const { size } = this.state;
    this.fetchData(
      ciProvider,
      repoOwner,
      repoName,
      null,
      size,
      {
        selectedTestRunsBranches,
        selectedTestRunsStatuses,
        selectedTestRunsTags,
      },
      isFirstPage,
      hideForkBranch,
      this.getTestRunListSuccess,
    );
  }

  fetchData = (ciProvider, repoOwner, repoName, afterKey, size, filter, isFirstPage = true, hideForkBranch = false) => {
    if (this.props.prId) {
      //This Request For PullRequest TestRuns
      this.props.getTestRunPRList(
        ciProvider,
        repoOwner,
        repoName,
        afterKey,
        size,
        filter,
        this.props.prId,
        isFirstPage,
        hideForkBranch,
        () => {
          this.setState({ fetching: false });
        },
        () => {
          this.setState({ fetching: false });
        },
      );
    } else {
      //This Request For General TestRuns

      this.props.getTestRunList(
        ciProvider,
        repoOwner,
        repoName,
        afterKey,
        size,
        filter,
        isFirstPage,
        hideForkBranch,
        () => {
          this.setState({ fetching: false });
        },
        () => {
          this.setState({ fetching: false });
        },
      );
    }
  };

  checkFetchingElements = afterKey => {
    return afterKey !== null;
  };

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('testruns-scrollable');
    this.fetchWhenScrollAccessToBottom(wrappedElement);
  };

  fetchWhenScrollAccessToBottom = el => {
    const { afterKey, hideForkBranch, selectedTestRunsBranches, selectedTestRunsStatuses, selectedTestRunsTags } =
      this.props.testRuns;
    const { size } = this.state;
    if (!this.checkFetchingElements(afterKey)) {
      return;
    }
    if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
      const { fetching } = this.state;
      if (!fetching) {
        const { ciProvider, repoOwner, repoName } = this.props.match.params;
        this.setState({ fetching: true });

        this.fetchData(
          ciProvider,
          repoOwner,
          repoName,
          afterKey,
          size,
          { selectedTestRunsBranches, selectedTestRunsStatuses, selectedTestRunsTags },
          false,
          hideForkBranch,
        );
      }
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    const { ciProvider: ciProviderNext, repoOwner: repoOwnerNext, repoName: repoNameNext } = nextProps.match.params;

    const { selectedTestRunsBranches, selectedTestRunsStatuses, selectedWorkflowRunHideFork, selectedTestRunsTags } =
      this.props.testRuns;
    const {
      selectedTestRunsBranches: nextBranches,
      testRunsFilterFetched: testRunsFilterFetched,
      selectedTestRunsStatuses: nextStatuses,
      selectedWorkflowRunHideFork: nextHideFork,
      selectedTestRunsTags: nextTags,
    } = nextProps.testRuns;

    const repoChangeFlag = ciProvider !== ciProviderNext || repoOwnerNext !== repoOwner || repoNameNext !== repoName;

    const propsChangeFlag =
      testRunsFilterFetched &&
      (!isEqualDeep(nextBranches, selectedTestRunsBranches) ||
        !isEqualDeep(nextStatuses, selectedTestRunsStatuses) ||
        !isEqualDeep(nextHideFork, selectedWorkflowRunHideFork) ||
        !isEqualDeep(nextTags, selectedTestRunsTags));

    if (repoChangeFlag || propsChangeFlag) {
      this.fetchData(
        ciProviderNext,
        repoOwnerNext,
        repoNameNext,
        null,
        remoteRowCount,
        {
          selectedTestRunsBranches: nextBranches,
          selectedTestRunsStatuses: nextStatuses,
          selectedTestRunsTags: nextTags,
        },
        nextHideFork,
      );
    }
  }

  handleAllToggle = () => {
    const { allCollapseOpen } = this.state;

    this.setState({ allCollapseOpen: !allCollapseOpen });
  };

  toggleRender = () => {
    const { allCollapseOpen } = this.state;

    return (
      <ForesightToggle
        enabled={allCollapseOpen}
        checked={allCollapseOpen === true}
        fitted
        className="radio-button"
        onClick={() => this.handleAllToggle()}
      />
    );
  };

  renderList = () => {
    const { content, testRunsFetching, testRunsFetched, error } = this.props.testRuns;
    const { allCollapseOpen } = this.state;

    if (hasArrayElement(content)) {
      return (
        <ListCardsContainer style={{ width: '100%' }}>
          <ListCardsHeader
            columnsObjectsArray={[
              { text: 'Status', style: { columnStyle } },
              { text: 'Workflow Run', style: { columnStyle } },
              {
                text: 'Expand all',
                style: { columnStyle },
                children: this.toggleRender(),
              },
            ]}
            className="pr-list-header"
          />
          <ListCardsBody
            fetched={testRunsFetched}
            fetching={testRunsFetching}
            infiniteFetching={this.state.fetching}
            error={error}
            tableContent={content}
          >
            {content.map(el => (
              <TestRunsInfiniteTableItem
                matchParams={this.props.match.params}
                key={el.id}
                data={el}
                allOpen={allCollapseOpen}
                history={this.props.history}
              />
            ))}
          </ListCardsBody>
        </ListCardsContainer>
      );
    } else {
      return <div className="testruns-empty-container">{renderNoDataAvailableForTestRuns()}</div>;
    }
  };

  render() {
    return <div className="testruns-list-container">{this.renderList()}</div>;
  }
}
