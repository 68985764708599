import React from 'react';
import { SearchInput } from 'custom-components/SearchInput/SearchInput';

export const SearchInputFilter = props => {
  return (
    <SearchInput
      searchValue={props.searchValue}
      inputPlaceholder="Search for a test view"
      onClickSearchButton={() => props.onClickSearchButton()}
      onChangeInputText={data => props.onChangeInputText(data)}
      buttonLoading={props.buttonLoading}
      buttonDisabled={props.buttonDisabled}
      clearSearchInput={data => props.clearSearchInput(data)}
      onKeyPress={data => props.onKeyPress(data)}
    />
  );
};
