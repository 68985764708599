import React, { Component } from 'react';
import { WidgetCard } from 'components/HighlightsPage/HighlightParts/WidgetCard';
import './CPUMetrics.scss';
import { Tooltip } from 'components/Tooltip';
import { Icon } from 'semantic-ui-react';

export class CPUMetrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
    };
  }

  componentDidMount() {
    this.convertHistogramData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workflowMetrics.cpuMetrics.data !== this.props.workflowMetrics.cpuMetrics.data) {
      this.convertHistogramData();
    }
  }

  convertHistogramData() {
    const { metrics } = this.props.workflowMetrics.cpuMetrics.data;

    const chartData = {};
    if (metrics) {
      for (const [key] of Object.entries(metrics).sort()) {
        let obj = metrics[key];
        delete obj['cpuLoadTotal'];
        if (key) {
          chartData[key] = {
            ...obj,
            time: key,
          };
        }
      }

      const convertData = Object.values(chartData);

      this.setState({
        chartData: convertData,
      });
    } else {
      this.setState({
        chartData: [],
      });
    }
  }

  cpuContentGenerator = () => {
    return (
      <div className="cpu-content-container">
        <div className="cpu-content-header">
          <h4 className="cpu-content-header-text">CPU Metrics</h4>
        </div>
        <div className="cpu-content-body">
          <p className="cpu-info-text">CPU Load (user and system) in percentage</p>
          <div className="y-axis-and-graph-cont">
            <div className="y-axis-text">CPU Load %100</div>
            <div className="cpu-info-graph" />
          </div>
          <span> Time</span>
        </div>
      </div>
    );
  };

  render() {
    const { fetching, error } = this.props.workflowMetrics.cpuMetrics;
    const { chartData } = this.state;
    return (
      <div>
        <div className="cpu-metrics-container">
          <div className="header-metrics-container">
            <div className="header-metrics-content">
              <h4 className="header-metrics">CPU Metrics</h4>
              <Tooltip hoverable blackEdition content={this.cpuContentGenerator()}>
                <Icon style={{ height: '20px' }} name="info circle" />
              </Tooltip>
            </div>
          </div>
          <div className="cpu-chart-container">
            <WidgetCard
              error={error}
              loading={fetching}
              chartData={chartData}
              chartDomain={[0, 100]}
              type="Area"
              timeConfig
              hasLegend
              legendValueFormatter={entry => entry.value + ' %'}
            />
          </div>
        </div>
      </div>
    );
  }
}
