import React, { Component } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './CoverageAnalysisCodeEditor.scss';

export class CoverageAnalysisCodeEditor extends Component {
  render() {
    const { data, language } = this.props;
    const impactData = data;

    return (
      <div className="code-parts-coverage-container">
        <SyntaxHighlighter
          language={language}
          style={a11yDark}
          showLineNumbers={true}
          startingLineNumber={impactData[0].lineNumber}
          wrapLines={true}
          wrapLongLines={false}
          lineProps={lineNumber => {
            const lineObj = impactData.find(el => el.lineNumber === lineNumber);
            let style = { display: 'block', backgroundColor: '#0E1216' };
            if (lineObj?.covered === true) {
              style.backgroundColor = '#172d20';
            } else if (lineObj?.covered === false) {
              style.backgroundColor = '#3d2020';
            }
            return { style };
          }}
        >
          {impactData.map(el => el.lineContent).join('\n')}
        </SyntaxHighlighter>
      </div>
    );
  }
}
