import { connect } from 'react-redux';
import { TestRunOverviewSummaryCard } from 'components';
import { withRouter } from 'react-router';

function mapStateToProps(store) {
	return {
		testRunDetails: store.testRunDetails,
		testDetails: store.testDetails,
	};
}

function mapDispatchToProps() {
	return {};
}

const TestRunOverviewSummaryCardContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TestRunOverviewSummaryCard),
);
export default TestRunOverviewSummaryCardContainer;
