import React, { Component } from 'react';
import './ProviderCardPage.scss';
import { getCiProviderSelectionTexts } from 'utils/git-provider-human-readable-properties';
import { renderLogo } from 'utils/git-provider-logos';

class ProviderCardPage extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="provider-container">
				<div className="logo-container">
					<div className="provider-logo"> {renderLogo(this.props.ciProviderEnum)} </div>
				</div>

				<div className="provider-text">{getCiProviderSelectionTexts(this.props.ciProviderEnum)} </div>
			</div>
		);
	}
}

export default ProviderCardPage;
