import { connect } from 'react-redux';
import { TeamOrganizationBilling } from 'components';
import { withRouter } from 'react-router';
import { getBillingCustomer } from 'store/actions/billing-customer';
import { getPlans } from 'store/actions/billing-plans';
import { getActiveSubscription } from 'store/actions/billing-active-subscription';
import { getInvoices } from 'store/actions/billing-invoices';
import { saveSubscription } from 'store/actions/billing-save-subscription';
import { cancelSubscription } from 'store/actions/billing-cancel-subscription';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { upsertCard } from 'store/actions/billing-upsert-card';
import { getMyOrganizationUserList } from 'store/actions/organization';
import { inviteBillingAdminToOrganization } from 'store/actions/organization-invite-billing-admin';
import { getWhitelist } from 'store/actions/whitelist';

function mapStateToProps(store) {
  return {
    organization: store.organization,
    userAccount: store.userAccount,
    upsertCardResult: store.upsertCard,
    billingPlans: store.billingPlans,
    billingInvoices: store.billingInvoices,
    billingCustomer: store.billingCustomer,
    activeSubscription: store.activeSubscription,
    saveSubscriptionResult: store.saveSubscription,
    cancelSubscriptionResult: store.cancelSubscription,
    inviteBillingAdminResult: store.inviteBillingAdminToOrganization,
    whiteList: store.whiteList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getBillingCustomer(organizationId) {
      dispatch(getBillingCustomer(organizationId));
    },
    getPlans() {
      dispatch(getPlans());
    },
    getActiveSubscription(organizationId) {
      dispatch(getActiveSubscription(organizationId));
    },
    getInvoices(organizationId) {
      dispatch(getInvoices(organizationId));
    },
    saveSubscription(subscriptionInfo, onSuccess, onError) {
      dispatchWithExceptionHandler(saveSubscription(subscriptionInfo), false, onSuccess, onError);
    },
    cancelSubscription(organizationId, onSuccess, onError) {
      dispatchWithExceptionHandler(cancelSubscription(organizationId), false, onSuccess, onError);
    },
    upsertCard(organizationId, stripeToken, onSuccess, onError) {
      dispatchWithExceptionHandler(upsertCard(organizationId, stripeToken), false, onSuccess, onError);
    },
    getMyOrganizationUserList(onGetUserListSuccess, onGetUserListFail) {
      dispatchWithExceptionHandler(getMyOrganizationUserList(), false, onGetUserListSuccess, onGetUserListFail);
    },
    inviteBillingAdmin(email, onSuccess, onError) {
      dispatchWithExceptionHandler(inviteBillingAdminToOrganization(email), false, onSuccess, onError);
    },
    getWhiteList(organizationId, onSuccess, onError) {
      dispatchWithExceptionHandler(getWhitelist(organizationId), false, onSuccess, onError);
    },
  };
}

const TeamOrganizationBillingContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamOrganizationBilling),
);
export default TeamOrganizationBillingContainer;
