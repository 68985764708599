import { connect } from 'react-redux';
import { TestRunsInfiniteTable } from 'components';
import { getTestRunsList } from 'store/actions/test-runs';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { withRouter } from 'react-router';

function mapStateToProps(store) {
  return {
    testRuns: store.testWRuns,
    testRunDetails: store.testRunDetails,
    userAccount: store.userAccount,
  };
}

function mapDispatchToProps() {
  return {
    getTestRunList(
      provider,
      owner,
      name,
      afterKey,
      offset,
      filter,
      isFirstPage = false,
      hideForkBranch,
      onGetTestRunsListSuccess,
    ) {
      dispatchWithExceptionHandler(
        getTestRunsList(provider, owner, name, afterKey, offset, filter, isFirstPage, hideForkBranch),
        true,
        onGetTestRunsListSuccess,
      );
    },
  };
}

const TestRunsInfiniteTableContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(TestRunsInfiniteTable));
export default TestRunsInfiniteTableContainer;
