import { MainLayoutContainer, PRChangeImpactAnalysisContainer } from 'containers';
import { PRSummaryContainer } from 'containers/PRSummaryContainer';
import ForesightBreadcrumb, { createForesightBreadCrumbDataItem } from 'custom-components/Breadcrumb/Breadcrumb';
import { OverviewCompContainer } from 'custom-components/Overview/Overview';
import { ForesightTabs } from 'custom-components/Tabs/Tabs';
import React from 'react';
import routeList, { computePath } from 'routes';
import { definePullRequestToPageTitle } from 'utils/page-title-util';
import { PROverviewInfo } from './OverviewInfo/PROverviewInfo';
import { PROverviewTitle } from './OverviewTitle/PROverviewTitle';
import { PRTestRuns } from './PRTestRuns/PRTestRuns';
import { PRWorkflowRuns } from './PRWorkflowRuns/PRWorkflowRuns';
import './RepositoryPRPage.scss';

class RepositoryPRPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.prId !== this.props.match.params.prId) {
      this.fetchData();
    }
  }

  fetchData = () => {
    const { ciProvider, repoOwner, repoName, prId } = this.props.match.params;
    this.props.getPullRequestOverview(ciProvider, repoOwner, repoName, prId);
  };

  //==== Tab Functions ====//

  encodeTabNameForURL = tabName => {
    const encoded = tabName.toLowerCase().replaceAll(' ', '-');
    return encoded;
  };

  decodeTabNameForRead = tabName => {
    if (typeof tabName === 'string') {
      const decoded = tabName
        .split('-')
        .map(el => el.capitalize())
        .join(' ');
      return decoded;
    } else {
      return tabName;
    }
  };

  handleActiveTab = name => {
    const { ciProvider, repoOwner, repoName, prId } = this.props.match.params;
    const computedPath = computePath(routeList.prOverview.path, {
      ciProvider: ciProvider,
      repoOwner: repoOwner,
      repoName: repoName,
      prId: prId,
      tabName: this.encodeTabNameForURL(name),
    });
    this.props.history.push(computedPath);
  };

  getActiveTab = () => {
    const { tabName } = this.props.match.params;
    return tabName ? this.decodeTabNameForRead(tabName) : 'Summary';
  };

  renderTabs = () => {
    const BUTTON_TEXT_ARR = ['Summary', 'Workflow Runs', 'Test Runs', 'Change Impact Analysis'];
    return (
      <ForesightTabs
        buttonTextArr={BUTTON_TEXT_ARR}
        onClick={(e, data) => this.handleActiveTab(data.name)}
        active={this.getActiveTab()}
      />
    );
  };

  renderActiveTabPanel = () => {
    const activeTab = this.getActiveTab();
    switch (activeTab) {
      case 'Summary':
        return <PRSummaryContainer {...this.props} />;
      case 'Workflow Runs':
        return <PRWorkflowRuns {...this.props} />;
      case 'Test Runs':
        return <PRTestRuns {...this.props} />;
      case 'Change Impact Analysis':
        return <PRChangeImpactAnalysisContainer />;
      default:
        return <PRSummaryContainer {...this.props} />;
    }
  };

  //==== Breadcrumb Functions ====//
  renderBreadcrumb = () => {
    const { repoName, ciProvider, repoOwner } = this.props.match.params;

    const pathData = {};
    const data = [];
    data.push(
      createForesightBreadCrumbDataItem('Repositories', pathData, () => {
        this.props.history.push(routeList.repositories.path);
      }),
    );
    data.push(createForesightBreadCrumbDataItem(repoName, pathData));
    data.push(
      createForesightBreadCrumbDataItem('Pull Requests', pathData, () => {
        this.props.history.push(
          computePath(routeList.repositoryPRs.path, {
            ciProvider: ciProvider,
            repoOwner: repoOwner,
            repoName: repoName,
          }),
        );
      }),
    );

    return <ForesightBreadcrumb type="link-item" breadcrumbData={data} />;
  };

  renderOverview = () => {
    return (
      <OverviewCompContainer>
        <PROverviewTitle {...this.props} />
        <PROverviewInfo {...this.props} />
      </OverviewCompContainer>
    );
  };

  readableTabName = tabName => {
    if (tabName) {
      let replace = tabName.split('-');
      const result = replace.map(item => item.capitalize()).join(' ');
      return result;
    } else {
      return 'Summary';
    }
  };

  render() {
    const { repoName, tabName } = this.props.match.params;
    const { pullRequestOverview } = this.props.pullRequestOverview;
    const { summary } = pullRequestOverview;

    return (
      <MainLayoutContainer
        title={definePullRequestToPageTitle(
          routeList.prOverview.title,
          summary?.title,
          this.readableTabName(tabName),
          repoName,
        )}
      >
        {this.renderBreadcrumb()}
        {this.renderOverview()}
        {this.renderTabs()}
        {this.renderActiveTabPanel()}
      </MainLayoutContainer>
    );
  }
}

export default RepositoryPRPage;
