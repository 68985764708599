import React, { Component } from 'react';
import { LoadingDimmer } from 'components';
import { hasArrayElement } from 'utils/array-util';
import { getCiProviderConfigLink } from 'utils/git-provider-config-links';
import './RepositorySelectableList.scss';
import CheckboxTreeList from 'components/HighlightsPage/HighlightFilters/CheckboxTreeList/CheckboxTreeList';
import { BlueButton } from 'custom-components/Button/Button';
import { DOMAIN_ICON } from 'assets/font-icons/IconManager';

export default class RepositorySelectableList extends Component {
  constructor(props) {
    super(props);
  }

  getEmptyRepoList = () => {
    return (
      <div className="no-repo-in-list">
        <i className={DOMAIN_ICON.GITHUB.REPOSITORY} />
        <div className="no-repo-title">No repositories</div>
        <div className="no-repo-content">Give access at least one repository</div>
        <BlueButton
          style={{ marginTop: 15 }}
          onClick={() => window.location.replace(getCiProviderConfigLink(this.props.ciProviderList[0]))}
        >
          Change Repository Access
        </BlueButton>
        <div className="no-repo-footer">
          Already selected?{' '}
          <span>
            <a href="https://docs.runforesight.com/how-to/troubleshooting" target="_blank" rel="noreferrer">
              Click here to troubleshoot{' '}
            </a>
          </span>
        </div>
      </div>
    );
  };

  isWatchRepo = newRepoList => {
    newRepoList.forEach(repo => {
      return repo.watched;
    });
  };

  render() {
    const { isCIRepoListFetching, isCIRepoListFetched } = this.props.ciRepos;
    const { newRepoList } = this.props;
    let repoOwnerName = '-';

    if (isCIRepoListFetching || !isCIRepoListFetched) {
      return (
        <div className="loading-container">
          <LoadingDimmer />
        </div>
      );
    }

    if (hasArrayElement(newRepoList)) {
      repoOwnerName = newRepoList[0].repo.owner;
    } else {
      return this.getEmptyRepoList();
    }

    let convertRepoList = [];

    newRepoList.forEach(item => {
      item.withIconName = item.watched ? 'icon-eye' : null;
      convertRepoList.push(item);
    });

    return (
      <div className="available-repo-list">
        <CheckboxTreeList
          data={convertRepoList}
          loading={false}
          parentName={repoOwnerName}
          fetched
          handleCheckboxOnClick={this.props.handleCheckboxClick}
          handleSelectAllReposToggle={this.props.handleSelectAllReposToggle}
          withIconName={() => (this.isWatchRepo(newRepoList) ? 'icon-eye' : null)}
          iconPopupJsx={'Already Watching'}
          ellipsisLength={50}
          emptyJsx={this.getEmptyRepoList()}
          searchBarPlaceholder="Search Repositories"
        />
      </div>
    );
  }
}
