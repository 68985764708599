export const USER_ROLES_ENUM = {
  ADMIN: 'Admin',
  ACCOUNT_OWNER: 'Admin',
  USER: 'User',
  BILLING_ADMIN: 'Billing Admin',
};

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  ACCOUNT_OWNER: 'ACCOUNT_OWNER',
  USER: 'USER',
  BILLING_ADMIN: 'BILLING_ADMIN',
};
