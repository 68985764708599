export const GET_PULL_REQUEST_WORKFLOW_RUNS_META = 'GET_PULL_REQUEST_WORKFLOW_RUNS_META';
export const GET_PULL_REQUEST_WORKFLOW_RUNS_META_PENDING = 'GET_PULL_REQUEST_WORKFLOW_RUNS_META_PENDING';
export const GET_PULL_REQUEST_WORKFLOW_RUNS_META_REJECTED = 'GET_PULL_REQUEST_WORKFLOW_RUNS_META_REJECTED';
export const GET_PULL_REQUEST_WORKFLOW_RUNS_META_FULFILLED = 'GET_PULL_REQUEST_WORKFLOW_RUNS_META_FULFILLED';

export const SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_IDS_SELECTED =
  'SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_IDS_SELECTED';
export const SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_BRANCHES_SELECTED =
  'SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_BRANCHES_SELECTED';
export const SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_CONCLUSIONS_SELECTED =
  'SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_CONCLUSIONS_SELECTED';

export const SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_EVENTS_SELECTED =
  'SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_EVENTS_SELECTED';

export const GET_PULL_REQUEST_WORKFLOW_RUNS_FILTER_LIST = 'GET_PULL_REQUEST_WORKFLOW_RUNS_FILTER_LIST';
export const GET_PULL_REQUEST_WORKFLOW_RUNS_FILTER_LIST_PENDING = 'GET_PULL_REQUEST_WORKFLOW_RUNS_FILTER_LIST_PENDING';
export const GET_PULL_REQUEST_WORKFLOW_RUNS_FILTER_LIST_REJECTED =
  'GET_PULL_REQUEST_WORKFLOW_RUNS_FILTER_LIST_REJECTED';
export const GET_PULL_REQUEST_WORKFLOW_RUNS_FILTER_LIST_FULFILLED =
  'GET_PULL_REQUEST_WORKFLOW_RUNS_FILTER_LIST_FULFILLED';

export const SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_HIDE_FORK_BRANCHES_SELECTED =
  'SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_HIDE_FORK_BRANCHES_SELECTED';
