import React, { Component } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { getStringColor } from 'utils/color-util';

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="#96A0B0" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`$${value}`}
    </text>
  );
};
class PieChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      opacity: 0.8,
    };
  }

  componentDidMount() {
    let newData = [];
    const { pieChartData } = this.props;
    if (pieChartData.length > 0) {
      pieChartData.forEach(el => {
        newData.push({
          name: el?.repo?.fullName + '/' + el?.name,
          value: Number(el.totalCost.toFixed(2)),
        });
      });
      this.setState({
        data: newData,
      });
    }
  }

  handleMouseEnter = e => {
    const { selectedPie } = this.props;
    const newOpacity = {};
    this.props.pieChartData.forEach(type => {
      let newWorkflowName = type?.repo?.fullName + '/' + type?.name;
      newOpacity[newWorkflowName] = e.name === newWorkflowName ? 0.8 : 0.2;
    });
    this.setState({
      opacity: newOpacity,
    });
    if (selectedPie) {
      selectedPie(e.name);
    }
  };

  handleMouseLeave = () => {
    const { selectedPie } = this.props;
    const newOpacity = {};
    this.props.pieChartData.forEach(type => {
      let newWorkflowName = type?.repo?.fullName + '/' + type?.name;
      newOpacity[newWorkflowName] = 0.8;
    });
    this.setState({
      opacity: newOpacity,
    });
    if (selectedPie) {
      selectedPie(null);
    }
  };

  handleSentListName = val => {
    const newOpacity = {};
    if (val) {
      this.props.pieChartData.forEach(type => {
        let newWorkflowName = type?.repo?.fullName + '/' + type?.name;

        newOpacity[newWorkflowName] = val === newWorkflowName ? 0.8 : 0.1;
      });
      this.setState({
        opacity: newOpacity,
      });
    } else {
      this.props.pieChartData.forEach(type => {
        let newWorkflowName = type?.repo?.fullName + '/' + type?.name;
        newOpacity[newWorkflowName] = 0.8;
      });
      this.setState({
        opacity: newOpacity,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { sentListName } = this.props;
    if (prevProps.sentListName !== this.props.sentListName) {
      {
        this.handleSentListName(sentListName);
      }
    }
  }

  render() {
    const { tooltipContent = null, outerRadius = 90 } = this.props;
    const { data } = this.state;
    return (
      <ResponsiveContainer width="90%" height={400}>
        <PieChart width={181} height={181}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            stroke="#333"
            strokeWidth={1}
            label={renderCustomizedLabel}
            labelLine={true}
            outerRadius={outerRadius}
            fill="#8884d8"
            dataKey="value"
            isAnimationActive={false}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fillOpacity={this.state.opacity[entry?.name]}
                strokeOpacity={this.state.opacity[entry?.name]}
                fill={getStringColor(entry.name)}
              />
            ))}
          </Pie>
          <Tooltip
            content={tooltipContent}
            contentStyle={{ background: '#161B22', borderColor: '#161B22' }}
            itemStyle={{ color: '#DCDFE5' }}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

export default PieChartComponent;
