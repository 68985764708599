import React, { Component } from 'react';
import routeList from 'routes';

import './error.scss';
import { MainLayoutContainer } from 'containers';

function svgMap() {
  return (
    <div className="svg-map-container">
      <svg width="234" height="193" viewBox="0 0 234 193" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79 -2.36112e-05L1.43373e-05 29L0 193L79 163L79 -2.36112e-05Z"
          fill="#EBECF0"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M79 163L157 192L157 29L79 -3.05176e-05L79 163Z" fill="#C1C7D0" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M234 -2.3786e-05L157 29L157 192L234 164L234 -2.3786e-05Z"
          fill="#EBECF0"
        />
        <path
          d="M193.393 60.5303C193.03 59.3477 193.044 58.0362 193.433 56.5958C193.818 55.143 194.617 53.795 195.829 52.5517C197.042 51.3084 198.619 50.3891 200.561 49.7937C202.366 49.2403 204.062 49.0879 205.65 49.3365C207.234 49.5727 208.576 50.1489 209.674 51.0652C210.784 51.9777 211.545 53.1062 211.958 54.4507C212.282 55.5089 212.348 56.5031 212.157 57.4334C211.978 58.3599 211.679 59.2073 211.261 59.9756C210.852 60.7276 210.091 62.023 208.98 63.8619C208.675 64.3775 208.438 64.8248 208.268 65.2038C208.106 65.5664 208.002 65.8913 207.954 66.1785C207.902 66.4532 207.877 66.7264 207.878 66.9983C207.888 67.2539 207.919 67.7077 207.969 68.3597C208.129 69.7269 207.587 70.6013 206.342 70.983C205.695 71.1815 205.082 71.1378 204.504 70.8519C203.939 70.5622 203.528 70.0003 203.272 69.1662C202.952 68.1205 202.837 67.1684 202.928 66.3097C203.015 65.4387 203.238 64.6348 203.598 63.898C203.954 63.1488 204.464 62.2365 205.128 61.1612C205.711 60.2198 206.124 59.5214 206.366 59.0659C206.616 58.5942 206.793 58.1043 206.895 57.596C207.011 57.084 206.99 56.5728 206.834 56.0624C206.529 55.0664 205.897 54.3406 204.941 53.8849C203.996 53.4254 202.939 53.375 201.769 53.7338C200.399 54.1536 199.498 54.8113 199.064 55.7069C198.627 56.5901 198.39 57.7726 198.354 59.2545C198.362 60.791 197.775 61.7405 196.592 62.1031C195.895 62.3168 195.229 62.2553 194.595 61.9187C193.969 61.5657 193.569 61.103 193.393 60.5303ZM208.78 78.2026C208.021 78.4355 207.28 78.3969 206.559 78.0869C205.846 77.7607 205.354 77.1557 205.083 76.2718C204.842 75.4875 204.914 74.7438 205.298 74.0405C205.681 73.3373 206.272 72.8636 207.068 72.6193C207.853 72.3789 208.594 72.4442 209.294 72.8154C209.993 73.1866 210.463 73.7644 210.704 74.5486C210.971 75.4201 210.901 76.1906 210.493 76.8603C210.086 77.53 209.514 77.9775 208.78 78.2026Z"
          fill="#1E1E20"
        />
        <path
          d="M5.30933 90.4599C17.4928 74.7319 28.7174 76.3573 38.9832 95.3362C58.6378 133.909 91.9904 102.353 103.576 80.4202C115.161 58.4873 133.947 57.2741 146.532 95.3362C159.117 133.398 193.674 155.826 207.542 121.554C210.238 114.893 207.542 100.415 207.542 95.3362C207.542 91.9504 207.542 88.5943 207.542 85.2677"
          stroke="#1E1E20"
          strokeWidth="5.6"
          strokeLinecap="round"
          strokeDasharray="4.2 9.8"
        />
      </svg>
    </div>
  );
}

class ErrorPage extends Component {
  handleGoBackClick = () => {
    const { errorCode } = this.props.match.params;
    if (errorCode && errorCode === 'forcelogout') {
      // TODO: handle forcelogout and error cases?
      this.props.history.replace(routeList.landing.path);
    } else {
      this.props.history.replace(routeList.repositories.path);
    }
  };

  renderServerErrorLandingPage = () => {
    return (
      <div className="error-page-wrapper">
        <div className="error-page-content">
          <div className="error-pic">{svgMap()}</div>
          <div className="error-title">
            <span>Oh no, wrong path!</span>
          </div>
          <div className="error-subtitle">
            <div>Looks like we are having an issue.</div>
          </div>
          <div className="error-go-back-button" onClick={this.handleGoBackClick}>
            Go Back
          </div>
        </div>
      </div>
    );
  };

  renderServerErrorInternalPage = () => {
    return <MainLayoutContainer title={''}>{this.renderServerErrorLandingPage()}</MainLayoutContainer>;
  };

  render() {
    const { errorCode } = this.props.match.params;
    if (errorCode && errorCode === 'forcelogout') {
      return this.renderServerErrorLandingPage();
    } else {
      return this.renderServerErrorInternalPage();
    }
  }
}

export default ErrorPage;
