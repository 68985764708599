import { UPDATE_USER_PROFILE, CHANGE_CURRENT_USER_INFORMATION, UPDATE_USER_LAST_LOGIN_DATE } from 'store/constants/user-info';
import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';

export function updateUserProfile(profileInfo) {
	console.log('action, updateUserProfile; profileInfo: ', profileInfo);

	return {
		type: UPDATE_USER_PROFILE,
		payload: axios.patch(CONFIG.apiUrl + '/users/me/profile', profileInfo, {
			headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
		}),
	};
}

export function changeCurrentUserInformation(newInformation) {
	return {
		type: CHANGE_CURRENT_USER_INFORMATION,
		payload: axios.put(CONFIG.apiUrl + '/users/me', newInformation, {
			headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
		}),
	};
}

export function updateLastLoginDate() {
	return {
		type: UPDATE_USER_LAST_LOGIN_DATE,
		payload: axios.patch(CONFIG.apiUrl + '/users/me/last-login', {}, {
			headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
		}),
	};
}