import React from 'react';
import './DualHorizontalItems.scss';

export const DualHorizontalItemsContainer = ({ children, ...props }) => {
  return (
    <div className="dual-horizontal-items-main-container-parent" {...props}>
      {children}
    </div>
  );
};

export const DualHorizontalItemsLeftPart = ({ children, ...props }) => {
  return (
    <div className="dual-horizontal-items-left-part" {...props}>
      {children}
    </div>
  );
};

export const DualHorizontalItemsRightPart = ({ children, ...props }) => {
  return (
    <div className="dual-horizontal-items-right-part" {...props}>
      {children}
    </div>
  );
};
