export const GET_TEST_SUITES_OVERVIEW_LIST = 'GET_TEST_SUITES_OVERVIEW_LIST';
export const GET_TEST_SUITES_OVERVIEW_LIST_PENDING = 'GET_TEST_SUITES_OVERVIEW_LIST_PENDING';
export const GET_TEST_SUITES_OVERVIEW_LIST_REJECTED = 'GET_TEST_SUITES_OVERVIEW_LIST_REJECTED';
export const GET_TEST_SUITES_OVERVIEW_LIST_FULFILLED = 'GET_TEST_SUITES_OVERVIEW_LIST_FULFILLED';

export const GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS = 'GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS';
export const GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS_PENDING = 'GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS_PENDING';
export const GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS_REJECTED = 'GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS_REJECTED';
export const GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS_FULFILLED = 'GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS_FULFILLED';

export const SET_TEST_SUITES_OVERVIEW_SELECTED_HIDE_FORK = 'SET_TEST_SUITES_OVERVIEW_SELECTED_HIDE_FORK';

export const SET_TEST_SUITES_OVERVIEW_FILTER_OBJECT = 'SET_TEST_SUITES_OVERVIEW_FILTER_OBJECT';
