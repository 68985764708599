import { CustomModal } from 'components/CustomModal';
import { ALink } from 'custom-components/ALink/ALink';
import { BlueButton, TextButton } from 'custom-components/Button/Button';
import { ForesightInput } from 'custom-components/Input/Input';
import { PageSubHeader, PageSubHeaderText, PageSubHeaderTitle } from 'custom-components/PageSubHeader/PageSubHeader';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { hasArrayElement } from 'utils/array-util';
import { checkEmailValidation } from 'utils/common-util';
import { isUserInInvitedBillingAdminRole } from 'utils/user-role-util';
import { USER_ROLES } from 'utils/user-roles-enum';
import './BillingUser.scss';

class BillingUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      modalIsOpen: false,
      isValid: true,
    };
  }

  getBillingAdminUsers() {
    let billingAdminUsersName = '';
    if (hasArrayElement(this.props.organization.myOrganizationUserList)) {
      const billingAdminUsers = this.props.organization.myOrganizationUserList
        .filter(user => user.userId && user.role === USER_ROLES.BILLING_ADMIN)
        .map(user => user.fullname);
      billingAdminUsers.forEach((el, index) => {
        if (index < billingAdminUsers.length - 1) billingAdminUsersName += el + ', ';
        else billingAdminUsersName += el;
      });
    }
    return billingAdminUsersName;
  }

  handleInputOnChange = value => {
    this.setState({
      emailAddress: value,
    });
  };

  isEmailValid = email => {
    if (email !== '') {
      return checkEmailValidation(email);
    } else {
      return false;
    }
  };

  handleInviteUser = () => {
    const { emailAddress } = this.state;
    if (this.isEmailValid(emailAddress)) {
      const { inviteBillingAdmin } = this.props;
      inviteBillingAdmin(emailAddress, this.onInviteUserSuccess, this.onInviteUserFail);
    } else {
      this.setState({ isValid: false });
      toast.error('Please enter valid email address');
    }
  };

  onInviteUserModalOpen = e => {
    e.preventDefault();
    this.setState({ modalIsOpen: true, emailAddress: '' });
  };

  onInviteUserModalClose = () => {
    this.setState({ modalIsOpen: false });
  };

  onInviteUserSuccess = () => {
    toast.success('Invitation has been sent successfully');
    this.onInviteUserModalClose();
    this.props.getMyOrganizationUserList();
  };

  onInviteUserFail = err => {
    toast.error('Invitation has been failed ' + err.response.data);
    this.onInviteUserModalClose();
  };

  getEmailAddressListInputs = () => {
    const { emailAddress } = this.state;

    return (
      <div className="billing-user-input-container">
        <ForesightInput
          placeholder="Enter email address"
          key="emailAdress"
          value={emailAddress}
          onChange={e => this.handleInputOnChange(e.target.value)}
          autoFocus
          hasError={!this.state.isValid}
        />
      </div>
    );
  };

  render() {
    const { modalIsOpen } = this.state;
    const { userAccount } = this.props.userAccount;
    const isBillingAdmin = isUserInInvitedBillingAdminRole(userAccount.role);

    if (isBillingAdmin) {
      return null;
    }

    return (
      <div className="billing-user-main-container">
        <PageSubHeader>
          <PageSubHeaderTitle>Billing admin</PageSubHeaderTitle>
          <PageSubHeaderText>
            {' '}
            Billing admins {this.getBillingAdminUsers() ? `(${this.getBillingAdminUsers()})` : ''} can manage payment
            method and plan for this organization.
          </PageSubHeaderText>
        </PageSubHeader>

        <ALink
          style={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
          }}
          onClick={this.onInviteUserModalOpen}
        >
          + Invite Billing Admin
        </ALink>
        <CustomModal
          className="invite-modal"
          dimmer={false}
          isOpen={modalIsOpen}
          size="tiny"
          onModalClose={() => this.cancelInvitationModal(false)}
        >
          <div className="billing-user-custom-modal-container">
            <PageSubHeader style={{ paddingTop: '0px' }}>
              <PageSubHeaderTitle>Invite Billing Admin</PageSubHeaderTitle>
              <PageSubHeaderText>
                Billing admin can manage payment method and plan for this organization.
              </PageSubHeaderText>
            </PageSubHeader>

            {this.getEmailAddressListInputs()}
            <div className="billing-user-custom-modal-footer">
              <TextButton onClick={this.onInviteUserModalClose}>Cancel</TextButton>
              <BlueButton
                loading={this.props.inviteBillingAdminResult?.fetching}
                disabled={this.props.inviteBillingAdminResult?.fetching}
                onClick={this.handleInviteUser}
              >
                Invite
              </BlueButton>
            </div>
          </div>
        </CustomModal>
      </div>
    );
  }
}

export default BillingUser;
