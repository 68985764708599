import whitelistPricingSVG from 'assets/images/whitelist-pricing.svg';
import { ALink } from 'custom-components/ALink/ALink';
import React from 'react';
import { Image } from 'semantic-ui-react';
import { isInPricingWhiteList } from 'utils/common-util';
import { BillingUser } from './BillingUser';
import { CancelPlan } from './CancelPlan';
import { Invoices } from './Invoices';
import { Plan } from './Plan';
import './TeamOrganizationBilling.scss';

class TeamOrganizationBilling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationId: '',
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.getOrganizationId(prevProps) !== this.getOrganizationId(this.props)) {
      this.fetchData();
    }
  }

  getOrganizationId = props => {
    if (props.userAccount) {
      const { userAccount } = props.userAccount;
      return userAccount.organization?.id;
    }
    return null;
  };

  fetchBillingData = organizationId => {
    this.props.getBillingCustomer(organizationId);
    this.props.getPlans();
    this.props.getActiveSubscription(organizationId);
    this.props.getInvoices(organizationId);
  };

  fetchData = () => {
    const organizationId = this.getOrganizationId(this.props);
    if (organizationId) {
      this.props.getWhiteList(
        organizationId,
        data => {
          // success
          if (!isInPricingWhiteList(data?.value?.data)) {
            this.fetchBillingData(organizationId);
          }
        },
        err => {
          //err
          console.log(err);
          this.fetchBillingData(organizationId);
        },
      );
    }
  };

  onCancelSubscriptionSucceded = () => {
    this.fetchData();
  };

  renderBilling = () => {
    const newProps = { ...this.props, organizationId: this.getOrganizationId(this.props) };
    const cancelProps = { ...newProps, onCancelSubscriptionSucceded: this.onCancelSubscriptionSucceded };

    return (
      <div className="main-pricing-container-wrapper">
        <Plan {...newProps} />
        <BillingUser {...newProps} />
        <Invoices {...newProps} />
        <CancelPlan {...cancelProps} />
      </div>
    );
  };

  renderWhitelistedBilling = () => {
    return (
      <div className="main-pricing-container-wrapper">
        <div className="whitelist-pricing-container">
          <div className="whitelist-pricing-center-container">
            <div className="img-container">
              <Image src={whitelistPricingSVG} />
            </div>
            <div className="content-container">
              <div className="header">You are on the Custom Plan</div>
              <div className="text-container">
                <div className="text">
                  You are using Foresight with a custom billing plan. Your account manager is more than happy to send
                  your plan details and invoice.{' '}
                </div>
                <div className="text-container">
                  <div className="text">
                    Please feel free to reach out to us at
                    <span>&nbsp;</span>
                    <ALink href="support@runforesight.com"> support@runforesight.com</ALink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (isInPricingWhiteList(this.props.whiteList?.whiteList)) {
      return this.renderWhitelistedBilling();
    } else {
      return this.renderBilling();
    }
  }
}

export default TeamOrganizationBilling;
