import React, { Component } from 'react';
import { ExecutionCountWidget } from './ExecutionCountWidget/ExecutionCountWidget';
import { SuccessRatesWidget } from './SuccessRatesWidget/SuccessRatesWidget';
import './ExecutionSuccessCountWidget.scss';
export class ExecutionSuccessCountWidget extends Component {

    render() {
        const {
            successFailCountOfMostActive10Workflows,
            successRatesOfMostActive10Workflows,
            timeRange,
        } = this.props;
        return (
            <div className='ten-most-active-workflows'>
                <div className='section-charts-container'>
                    <div className='most10active-chart'>
                        <ExecutionCountWidget data={successFailCountOfMostActive10Workflows} timeRange={timeRange} />
                    </div>
                    <div className='most10active-chart'>
                        <SuccessRatesWidget data={successRatesOfMostActive10Workflows} />
                    </div>
                </div>
            </div>
        );
    }


}