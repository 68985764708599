import {
  GET_TEST_RUN_DETAIL_TEST_SUIT,
  GET_TEST_RUN_DETAIL_OVERVIEW,
  SET_TEST_RUN_DETAIL_CLIENT_FILTER,
} from 'store/constants/test-run-details';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

export function getTestRunDetailTestSuits(testRunId) {
  return {
    meta: { testRunId: testRunId },
    type: GET_TEST_RUN_DETAIL_TEST_SUIT,
    payload: axios.get(CONFIG.apiUrl + `/test-suites/${testRunId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function getTestRunOverview(provider, owner, repoName, testRunId) {
  return {
    meta: { testRunId: testRunId },
    type: GET_TEST_RUN_DETAIL_OVERVIEW,
    payload: axios.get(CONFIG.apiUrl + `/repos/${provider}/${owner}/${repoName}/testruns/${testRunId}`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}

export function setTestRunClientFilter(testRunId, data) {
  return {
    meta: { testRunId: testRunId },
    type: SET_TEST_RUN_DETAIL_CLIENT_FILTER,
    payload: { data: data },
  };
}
