export const DATE_TABLE_FORMAT = 'YYYY/MM/DD HH:mm:ss.SSS';
export const DATE_DISPLAYER_FORMAT = 'YYYY/MM/DD HH:mm';
export const GLOBAL_TIME_DISPLAYER_FORMAT = 'lll';
export const DATE_PICKER_FORMAT = 'yyyy/MM/dd HH:mm';

export const HOUR_TIME_LIMIT_TYPE = 4;
export const DAY_TIME_LIMIT_TYPE = 4;
export const WEEK_TIME_LIMIT_TYPE = 4;
export const MONTH_TIME_LIMIT_TYPE = 1;

export const HOUR_TIME_LIMIT_DATE_FORMAT = 'HH:mm';
export const DAY_TIME_LIMIT_DATE_FORMAT = 'HH:mm';
export const WEEK_TIME_LIMIT_DATE_FORMAT = 'dd, DD/MM';
export const MONTH_TIME_LIMIT_DATE_FORMAT = 'dd, DD/MM';
export const TWO_MONTHS_TIME_LIMIT_DATE_FORMAT = 'dd, DD/MM';


export const computeDateFormatFromInterval = (startDate, endDate) => {
	if (endDate.diff(startDate, 'days') < 2) {
		return DAY_TIME_LIMIT_DATE_FORMAT;
	} else {
		return WEEK_TIME_LIMIT_DATE_FORMAT;
	}
};


export function roundData(data, decimal = 0) {
	let formattedData = [];
	const finderIndex = data.findIndex(el => el.time === 'total');
	if (finderIndex > -1) {
		data.splice(finderIndex, 1);
	}

	let precision = Math.pow(10, decimal);
	for (let i = 0; i < data.length; i++) {
		let keyNames = Object.keys(data[i]);
		formattedData[i] = {};
		for (let j = 0; j < keyNames.length; j++) {
			if (keyNames[j] === 'time') {
				formattedData[i][keyNames[j]] = data[i][keyNames[j]];
				continue;
			}
			if (data[i][keyNames[j]] === 'Infinity' || data[i][keyNames[j]] === '-Infinity') {
				formattedData[i][keyNames[j]] = 0;
				continue;
			}
			formattedData[i][keyNames[j]] = Math.round(data[i][keyNames[j]] * precision) / precision;
		}
	}
	return formattedData;
}