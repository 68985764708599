import { UI_ICON } from 'assets/font-icons/IconManager';
import React, { Component } from 'react';
import './GlobalNotification.scss';

const minDaysControlValue = 4;
/* This class renders a global notification */
class GlobalNotification extends Component {
  render() {
    const { linkText, onClick, leftDays = 14 } = this.props;
    return (
      <>
        {leftDays < minDaysControlValue && (
          <div className="glb-not-container">
            <div className="glb-not-first-content">
              <i className={UI_ICON.ALERT.WARNING + ' warning-icon'} />
              <div className="first-content-text">
                Your trial is ending. If you have more than 20 committers, you need to{' '}
                <span className="second-content-text" onClick={onClick}>
                  {linkText}
                </span>{' '}
                to continue
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default GlobalNotification;
