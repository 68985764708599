import React, { Component } from 'react';
import { MainLayoutContainer, OrganizationListContainer } from 'containers';
import routeList from 'routes';
import './OrganizationListPage.scss';

class OrganizationListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <MainLayoutContainer title={routeList.organizationList.title}>
        <div className="team-organization-page-wrapper">
          <OrganizationListContainer />
        </div>
      </MainLayoutContainer>
    );
  }
}

export default OrganizationListPage;
