import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { UserInvitationModal } from 'components';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { inviteUsers } from 'store/actions/organization';

function mapStateToProps(store, props) {
  return {
    organization: store.organization,
    onUserInviteModalClose: props.onUserInviteModalClose,
    isUserInvitationModalOpen: props.isUserInvitationModalOpen,
  };
}

function mapDispatchToProps() {
  return {
    inviteUsers(emailAddressList, onInviteUserSuccess, onInviteUserFailed) {
      dispatchWithExceptionHandler(inviteUsers(emailAddressList), false, onInviteUserSuccess, onInviteUserFailed);
    },
  };
}

const UserInvitationModalContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(UserInvitationModal));
export default UserInvitationModalContainer;
