import { connect } from 'react-redux';
import { RepositoryPRTestRunsTab } from 'components';
import { withRouter } from 'react-router';

function mapStateToProps(store) {
  return {
    testRuns: store.pullRequestTestWRuns,
    projects: store.projects,
    repos: store.repos,
  };
}

function mapDispatchToProps() {
  return {};
}

const RepositoryPRTestRunsTabContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepositoryPRTestRunsTab),
);
export default RepositoryPRTestRunsTabContainer;
