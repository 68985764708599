import {
  GET_PR_OVERVIEW_FULFILLED,
  GET_PR_OVERVIEW_PENDING,
  GET_PR_OVERVIEW_REJECTED,
} from '../constants/pull-request-overview';

const initialState = {
  pullRequestOverview: {},
  fetching: false,
  fetched: false,
  error: null,
};

export default function pullRequestOverview(state = initialState, action) {
  switch (action.type) {
    case GET_PR_OVERVIEW_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_PR_OVERVIEW_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        pullRequestOverview: action.payload.data,
      };
    case GET_PR_OVERVIEW_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
