import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
import { LoadingDimmer, NoDataAvailable, SomethingError } from 'components/ComponentPlaceholder';
import { AverageRangeGraph } from 'custom-components/RangeValue/AverageRangeGraph';
import React from 'react';
import { hasArrayElement } from 'utils/array-util';
import { millisToMAndSNotFixed } from 'utils/date-util';
import { decodeJobName, decodeRepoName, decodeSuiteName } from 'utils/test-run-detail-util';
import './TestViewSummary.scss';

export function TestViewSummary(props) {
  const renderRepoWorkflowAndJobName = () => {
    const { repoName, workflowId, jobName, testSuiteName } = props.match.params;

    return (
      <div className="meta-summary-container">
        <div className="title">Test Suite</div>
        <div className="test-suite-name">{decodeSuiteName(testSuiteName)}</div>
        <div className="info-container">
          <div className="test-information-cont">
            <i className={DOMAIN_ICON.GITHUB.REPOSITORY} />
            <div className="test-suites-info-title with-dot">{decodeRepoName(repoName)}</div>
          </div>
          <div className="test-information-cont">
            <i className="icon-workflows" />
            <div className="test-suites-info-title with-dot">{workflowId}</div>
          </div>
          <div className="test-information-cont">
            <i className={DOMAIN_ICON.GITHUB.JOB} />
            <div className="test-suites-info-title">{decodeJobName(jobName)}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderTestViewExecutionTime = () => {
    const { testViewSummary, fetching, fetched, error } = props.testViewSummary;
    if (fetched) {
      if (hasArrayElement(testViewSummary)) {
        const { p50: medianExecTime, p95: p95ExecTime, averageDuration: avgExecTime } = testViewSummary[0];

        return (
          <div className="execution-summary-container">
            <div className="title">Execution Time</div>
            <div className="info-container">
              <div className="info-part">
                <div className="data-part">{millisToMAndSNotFixed(medianExecTime)}</div>
                <div className="title-part">median</div>
              </div>
              <div className="info-part">
                <div className="data-part">{millisToMAndSNotFixed(p95ExecTime)}</div>
                <div className="title-part">p95</div>
              </div>
              <div className="info-part">
                <div className="data-part">{millisToMAndSNotFixed(avgExecTime)}</div>
                <div className="title-part">avg</div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="execution-summary-container">
            <NoDataAvailable msg={'No data available'} />
          </div>
        );
      }
    } else if (fetching) {
      return (
        <div className="execution-summary-container">
          <LoadingDimmer />
        </div>
      );
    } else if (error) {
      return (
        <div className="execution-summary-container">
          <SomethingError msg={error} />
        </div>
      );
    } else {
      return (
        <div className="execution-summary-container">
          <SomethingError msg={'Unknown State'} />
        </div>
      );
    }
  };

  const renderSuccessFailItem = ({ title, totalCount, count, avgDuration, minDuration, maxDuration }) => {
    return (
      <div className="test-view-status-column">
        <div className="title">{title}</div>
        <div className="info">
          <div className="list-sub-info">
            <div className="label-head">
              count:
              <span>
                {count} / {totalCount}
              </span>
            </div>
            <div className="label-head">
              avg: <span>{millisToMAndSNotFixed(avgDuration)}</span>
            </div>
            <div className="label-head">
              <AverageRangeGraph
                hasTimeValue
                extent="long"
                seeMinMaxText
                avg={avgDuration}
                maxValue={maxDuration}
                minValue={minDuration}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTestViewSuccessFail = () => {
    const { testViewSummary, fetching, fetched, error } = props.testViewSummary;

    if (fetching) {
      return (
        <div className="success_fail_summary-container">
          <LoadingDimmer />
        </div>
      );
    } else if (fetched) {
      if (hasArrayElement(testViewSummary)) {
        const content = testViewSummary[0];
        return (
          <div className="success_fail_summary-container">
            {renderSuccessFailItem({
              title: 'Successful Runs',
              totalCount: content.totalCount,
              count: content.successfulCount,
              avgDuration: content.successfulAvgDuration,
              minDuration: content.successfulMaxDuration,
              maxDuration: content.successfulMinDuration,
            })}
            {renderSuccessFailItem({
              title: 'Failed Runs',
              totalCount: content.totalCount,
              count: content.failedCount,
              avgDuration: content.failedAvgDuration,
              minDuration: content.failedMinDuration,
              maxDuration: content.failedMaxDuration,
            })}
          </div>
        );
      } else {
        return (
          <div className="success_fail_summary-container">
            <NoDataAvailable msg={'No data available'} />
          </div>
        );
      }
    } else if (error) {
      return (
        <div className="success_fail_summary-container">
          <SomethingError msg={error} />
        </div>
      );
    } else {
      return (
        <div className="success_fail_summary-container">
          <SomethingError msg={'Unknown State'} />
        </div>
      );
    }
  };

  return (
    <div className="test-view-summary-container">
      {renderRepoWorkflowAndJobName()}
      {renderTestViewExecutionTime()}
      {renderTestViewSuccessFail()}
    </div>
  );
}
