import React, { Component } from 'react';
import { MainLayoutContainer } from 'containers';
import routeList from 'routes';
import { TestRunDetailHeaderContainer, TestRunOverviewCardContainer, TestRunSuiteCardListContainer } from 'containers';
import './TestRunOverviewPage.scss';
import {
  getTestRunOverviewObj,
  getTestRunSuitesObj,
  isSuitesForceFetchNeeded,
  TESTRUN_OVERVIEW_PAGE_TYPE,
} from 'utils/test-run-detail-util';
import { defineTestRunOverviewPageTitle } from 'utils/page-title-util';
import { dispatchWithCacheCheck } from 'utils/dispatch-with-cache-check-handler';

class TestRunOverviewPage extends Component {
  getTestRunSuitesData = props => {
    const { testRunId } = props.match.params;
    const { testRunDetailMap } = props.testRunDetails;
    return getTestRunSuitesObj(testRunDetailMap, testRunId);
  };

  getTestRunOverviewData = props => {
    const { testRunId } = props.match.params;
    const { testRunDetailMap } = props.testRunDetails;
    return getTestRunOverviewObj(testRunDetailMap, testRunId);
  };

  componentDidMount() {
    this.fetchData(this.props);
  }

  forceFetch = () => {
    const { testRunId } = this.props.match.params;
    this.props.getTestRunDetailTestSuits(testRunId);
  };

  fetchData = props => {
    const { testRunId, ciProvider, repoOwner, repoName } = props.match.params;
    const testSuiteObj = this.getTestRunSuitesData(props);
    const testRunOverviewObj = this.getTestRunOverviewData(props);
    const forceSuiteObj = isSuitesForceFetchNeeded(testSuiteObj, testRunOverviewObj);

    dispatchWithCacheCheck(testSuiteObj, () => props.getTestRunDetailTestSuits(testRunId), forceSuiteObj);
    dispatchWithCacheCheck(testRunOverviewObj, () =>
      props.getTestRunDetailOverview(ciProvider, repoOwner, repoName, testRunId),
    );
  };

  render() {
    return (
      <MainLayoutContainer title={defineTestRunOverviewPageTitle(this.props, routeList.testRunOverview.title)}>
        <div className="test-run-overview-container">
          <div className="header">
            <TestRunDetailHeaderContainer forceFetch={this.forceFetch} />
          </div>
          <div className="content">
            <div className="content-left">
              <TestRunSuiteCardListContainer />
            </div>
            <div className="content-right">
              <TestRunOverviewCardContainer pageType={TESTRUN_OVERVIEW_PAGE_TYPE.TEST_RUN} />
            </div>
          </div>
        </div>
      </MainLayoutContainer>
    );
  }
}

export default TestRunOverviewPage;
