import { LoadingDimmer } from 'components';
import { Tooltip } from 'components/Tooltip';
import { ForesightCheckbox } from 'custom-components/Checkbox/Checkbox';
import React, { Component } from 'react';
import './PureCheckboxList.scss';

class ForesightPureCheckboxListItem extends Component {
  constructor(props) {
    super(props);
    this.state = { cardOver: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedCheckForOperation, item } = nextProps;
    const isSelected = selectedCheckForOperation === item.id;

    const { cardOver } = this.state;
    if (cardOver && isSelected) {
      //Dropdown Open over Checkbox but not MouseLeave out working
      this.setState({ cardOver: false });
    }
  }

  render() {
    const { idx, item, id, iconPopupJsx, onItemUpdate, renderExtraComps } = this.props;
    const { cardOver } = this.state;

    const childName = item.name;
    const hasIcon = item.withIconName || null;
    return (
      <div
        className="checkbox-list-item"
        key={`${idx}}`}
        onMouseEnter={() => this.setState({ cardOver: true })}
        onMouseLeave={() => this.setState({ cardOver: false })}
      >
        <Tooltip mouseEnterDelay={500} mouseLeaveDelay={500} blackEdition={true} content={childName}>
          <ForesightCheckbox
            className="fullwidth"
            checked={item.checked}
            label={childName}
            onChange={(e, data) => onItemUpdate(id, idx, data)}
            withIconName={hasIcon || null}
            iconPopupJsx={iconPopupJsx}
          />
        </Tooltip>
        {renderExtraComps && renderExtraComps(idx, item, cardOver)}
      </div>
    );
  }
}

export default class ForesightPureCheckboxList extends Component {
  renderCheckboxItems = () => {
    const { data, iconPopupJsx, id, onItemUpdate, renderExtraComps, selectedCheckForOperation } = this.props;

    const items = data.map((item, idx) => (
      <ForesightPureCheckboxListItem
        key={id}
        id={id}
        item={item}
        idx={idx}
        iconPopupJsx={iconPopupJsx}
        onItemUpdate={onItemUpdate}
        renderExtraComps={renderExtraComps}
        selectedCheckForOperation={selectedCheckForOperation}
      />
    ));
    return items;
  };

  render() {
    const { emptyJsx = 'No Data', data, loading = false, extraClass } = this.props;
    const combinedClass = 'pure-checkbox-list-and-list ' + extraClass;
    if (loading) {
      return (
        <div className={combinedClass}>
          <LoadingDimmer />
        </div>
      );
    } else if (data.length === 0) {
      return (
        <div className={combinedClass}>
          <div className="no-checkbox-in-list">{emptyJsx}</div>
        </div>
      );
    } else {
      return <div className={combinedClass}>{this.renderCheckboxItems()}</div>;
    }
  }
}
