export const GET_WORKFLOW_RUNS_LIST = 'GET_WORKFLOW_RUNS_LIST';
export const GET_WORKFLOW_RUNS_LIST_PENDING = 'GET_WORKFLOW_RUNS_LIST_PENDING';
export const GET_WORKFLOW_RUNS_LIST_REJECTED = 'GET_WORKFLOW_RUNS_LIST_REJECTED';
export const GET_WORKFLOW_RUNS_LIST_FULFILLED = 'GET_WORKFLOW_RUNS_LIST_FULFILLED';

export const GET_WORKFLOW_RUN_OVERVIEW = 'GET_WORKFLOW_RUN_OVERVIEW';
export const GET_WORKFLOW_RUN_OVERVIEW_PENDING = 'GET_WORKFLOW_RUN_OVERVIEW_PENDING';
export const GET_WORKFLOW_RUN_OVERVIEW_REJECTED = 'GET_WORKFLOW_RUN_OVERVIEW_REJECTED';
export const GET_WORKFLOW_RUN_OVERVIEW_FULFILLED = 'GET_WORKFLOW_RUN_OVERVIEW_FULFILLED';


export const GET_WORKFLOW_RUN_COVERAGE = 'GET_WORKFLOW_RUN_COVERAGE';
export const GET_WORKFLOW_RUN_COVERAGE_PENDING = 'GET_WORKFLOW_RUN_COVERAGE_PENDING';
export const GET_WORKFLOW_RUN_COVERAGE_REJECTED = 'GET_WORKFLOW_RUN_COVERAGE_REJECTED';
export const GET_WORKFLOW_RUN_COVERAGE_FULFILLED = 'GET_WORKFLOW_RUN_COVERAGE_FULFILLED';
