import React, { Component } from 'react';
import { Modal, TransitionablePortal } from 'semantic-ui-react';

import './CustomModal.scss';

export default class CustomModal extends Component {
  render() {
    const { isOpen, onModalClose, children, size, id, className } = this.props;
    return (
      <TransitionablePortal open={isOpen} transition={{ animation: 'fade down', duration: 500 }}>
        <Modal
          closeOnEscape
          closeOnDimmerClick={false}
          className={className}
          onClose={onModalClose}
          size={size}
          open={true}
          id={id}
        >
          {children}
        </Modal>
      </TransitionablePortal>
    );
  }
}
