import { Badge } from 'custom-components/Badge/Badge';
import {
  PageHeader,
  PageHeaderTitle,
  PageHeaderTitleContainer,
  PageHeaderTitleContainerSection,
} from 'custom-components/PageHeader/PageHeader';
import React from 'react';
import { FLAKY_COLORS, whichIsSeverity } from 'utils/test-case-flaky-status';

export function TestViewPageHeader(props) {
  const { testCaseName } = props.match.params;
  const percentage = props.testViewSummary?.testViewSummary[0]?.flakyPercentage || null;

  return (
    <PageHeader style={{ width: '50%', padding: '14px 0px 16px 0px' }}>
      <PageHeaderTitleContainer>
        <PageHeaderTitleContainerSection>
          <PageHeaderTitle style={{ marginRight: 8 }}> {decodeURIComponent(testCaseName)} </PageHeaderTitle>
          {percentage && (
            <Badge
              text={`serverity: ${whichIsSeverity(percentage)}`}
              badgeColor={FLAKY_COLORS[whichIsSeverity(percentage)]}
            />
          )}
        </PageHeaderTitleContainerSection>
      </PageHeaderTitleContainer>
    </PageHeader>
  );
}
