import React, { Component } from 'react';
import {
  RepositoryTabsContainer,
  RepositoryWorkflowRunsFilterContainer,
  RepositoryWorkflowsRunsListContainer,
} from 'containers';
import { ListCardsContainer, ListCardsHeader } from 'custom-components/ListCards/ListCards';
import { MainLayoutContainer } from 'containers';
import './RepositoryWorkflowPage.scss';
import routeList, { computePath } from 'routes';
import { Button, Popup } from 'semantic-ui-react';
import { CustomModal } from 'components/CustomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { hasArrayElement } from 'utils/array-util';
import { defineRepoNameToPageTitle } from 'utils/page-title-util';
import ForesightBreadcrumb, { createForesightBreadCrumbDataItem } from 'custom-components/Breadcrumb/Breadcrumb';
import { renderNoDataAvailableForWorkflow } from 'components/ComponentPlaceholder';

class RepositoryWorkflowPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStopWatchingPopupOpen: false,
      isStopWatchingButtonOpen: false,
    };
  }

  createBreadCrumbDataItem = (text, data, handleOnClick) => {
    return {
      text: text,
      data: data,
      handleOnClick: handleOnClick,
    };
  };

  onSuccessGetWorkfowRunsMeta = () => {
    if (window.location.hash.includes('#')) {
      this.props.setSelectedWorkflowIds([window.location.hash.substring(1)]);
      this.props.history.push(window.location.href.split('#')[0]);
    }
  };

  componentDidMount() {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    const {
      organization: { id },
    } = this.props.userAccount.userAccount;
    this.props.getWorkflowRunsMeta(ciProvider, repoOwner, repoName, this.onSuccessGetWorkfowRunsMeta);
    this.props.getOrganizationLabelsForWorkflow(id);
  }

  renderStopWatchingModal = () => {
    const { isStopWatchingPopupOpen } = this.state;
    const { repoName } = this.props.match.params;
    return (
      <CustomModal
        className="stop-watching-modal"
        isModalOpen={isStopWatchingPopupOpen}
        size="mini"
        onModalClose={() => {}}
      >
        <div className="title">Stop Watching {repoName}</div>
        <div className="description">
          You will lose your workflow runs and test runs after you stop watching your repository. You need to disconnect
          the Foresight agent if you have integrated your tests.
        </div>
        <div className="description">Do you want to proceed?</div>
        <div className="delete-actions">
          <Button
            onClick={() => {
              this.setState({ isStopWatchingPopupOpen: false });
            }}
            className="cancel-button"
          >
            Cancel
          </Button>
          <Button
            //TODO Integrate with Serkan Korkut PR
            onClick={() => {
              this.setState({ isStopWatchingPopupOpen: false });
            }}
            primary
          >
            Stop watching
          </Button>
        </div>
      </CustomModal>
    );
  };

  renderContentWithData = () => {
    let columnStyle = {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '20px',
      alignItems: 'center',
      letterSpacing: '-0.01em',
      color: '#788496',
    };
    return (
      <div className="workflow-runs">
        <div className="left-part">
          <RepositoryWorkflowRunsFilterContainer />
        </div>
        <div id="workflow-runs-scrollable" className="right-part">
          <ListCardsContainer style={{ width: '100%' }} withBorder>
            <ListCardsHeader
              columnsObjectsArray={[
                { text: 'Status', style: { columnStyle } },
                { text: 'Workflow Run', style: { columnStyle } },
                {
                  text: 'Triggered By',
                  style: { columnStyle },
                },
                {
                  text: 'Commit',
                  style: { columnStyle },
                },
              ]}
              className="workflowrun-list-header"
            />
            <RepositoryWorkflowsRunsListContainer />
          </ListCardsContainer>
        </div>
      </div>
    );
  };

  handleGetStartedRedirection = e => {
    e.preventDefault();
    this.props.history.push(computePath(routeList.startCreateProjectSelectGithubRepos.path));
  };

  renderContent = () => {
    const { workflowRunsMeta, fetched } = this.props.workflowRunsMeta;
    if (fetched && !hasArrayElement(workflowRunsMeta)) {
      return renderNoDataAvailableForWorkflow();
    } else {
      return this.renderContentWithData();
    }
  };

  //==== Breadcrumb Functions ====//
  renderBreadcrumb = () => {
    const { repoName } = this.props.match.params;

    const pathData = {};
    const data = [];
    data.push(
      createForesightBreadCrumbDataItem('Repositories', pathData, () => {
        this.props.history.push(routeList.repositories.path);
      }),
    );
    data.push(createForesightBreadCrumbDataItem(repoName, pathData));
    data.push(createForesightBreadCrumbDataItem('Workflow runs', pathData));

    return <ForesightBreadcrumb type="link-item" breadcrumbData={data} />;
  };

  render() {
    const { repoName } = this.props.match.params;
    const { isStopWatchingButtonOpen } = this.state;
    const flagStopWatching = false; //TODO This part hide because of ProjectId parameters needed but this page not known projectId

    return (
      <MainLayoutContainer title={defineRepoNameToPageTitle(this.props, routeList.repositoryWorkflowRuns.title)}>
        <div className="repository-workflow-page-container">
          {this.renderStopWatchingModal()}
          {this.renderBreadcrumb()}
          <div className="repository-title-container">
            <div className="title">{repoName}</div>
            {flagStopWatching && (
              <Popup
                onClick={() => this.setState({ isStopWatchingPopupOpen: true })}
                className="repository-settings-popup"
                content={<div className="stop-watching-btn">Stop watching</div>}
                position="bottom left"
                on="click"
                onOpen={() => this.setState({ isStopWatchingButtonOpen: true })}
                onClose={() => this.setState({ isStopWatchingButtonOpen: false })}
                inverted
                basic
                trigger={
                  <div className={`setting ${isStopWatchingButtonOpen && 'pressed'}`}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </div>
                }
              />
            )}
          </div>
          <RepositoryTabsContainer />
          {this.renderContent()}
        </div>
      </MainLayoutContainer>
    );
  }
}

export default RepositoryWorkflowPage;
