import React, { Component } from 'react';
import auth from 'auth/auth-factory';
import { Form, Grid, Icon } from 'semantic-ui-react';

import './ProfilePage.scss';
import { ForesightInput } from 'custom-components/Input/Input';
import { BlueButton, TextButton } from 'custom-components/Button/Button';
import { PageHeader, PageHeaderTitle, PageHeaderTitleContainer } from 'custom-components/PageHeader/PageHeader';
import { PageSubHeader, PageSubHeaderText, PageSubHeaderTitle } from 'custom-components/PageSubHeader/PageSubHeader';
import { ALink } from 'custom-components/ALink/ALink';
class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.checkIfProfileExistsOnLocalStorageOrAnonymous();
    const { userAccount } = this.props.userAccount;

    this.state = {
      fullname: userAccount?.fullName || '',
      email: userAccount?.email || '',
      isFormEdited: false,
      isResetPasswordInProgress: false,
    };
  }

  componentDidMount() {
    this.fetchUserAccount();
  }

  fetchUserAccount = () => {
    this.props.getUserAccount(this.onGetUserAccountSuccess, () =>
      console.log('ProfilePage, CDM, getUserAccount error'),
    );
  };

  onGetUserAccountSuccess = () => {
    console.log('ProfilePage, onGetUserAccountSuccess; props: ', this.props);
    const { userAccount } = this.props.userAccount.userAccount;

    this.setState({
      email: userAccount.email,
      fullname: userAccount.fullName,
    });
  };

  checkIfProfileExistsOnLocalStorageOrAnonymous = () => {
    const profile = auth.getCurrentUser();
    if (profile === null || profile === undefined) {
      auth.logout(false);
    }

    if (profile.anonymous) {
      console.error('Anonymous user cannot reach profile page');
      auth.logout(true);
    }
  };

  handleTemporaryShowMessage = (isPositive, header, content) => {
    this.setState({
      showMessage: true,
      messageHeader: header,
      messageContent: content,
      isMessagePositive: isPositive,
    });

    setTimeout(() => {
      this.setState({
        showMessage: false,
        messageHeader: '',
        messageContent: '',
      });
    }, 5000);
  };

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      isFormEdited: true,
    });
  };

  handleSaveProfileInfoChanges = () => {
    console.log('handleSaveProfileInfoChanges; props, state: ', this.props, this.state);

    this.props.updateUserProfile(
      {
        fullname: this.state.fullname,
      },
      this.onProfileInfoChangeSuccess,
      this.onProfileInfoChangeFail,
    );
  };

  onProfileInfoChangeSuccess = () => {
    console.log('profile change success');
    this.fetchUserAccount();

    this.setState({
      isFormEdited: false,
    });

    this.handleTemporaryShowMessage(true, 'Profile updated', 'User information is changed successfully.');
  };

  onProfileInfoChangeFail = () => {
    console.log('profile change fail');
    this.handleDiscardProfileInfoChanges();
    // eslint-disable-next-line
    this.handleTemporaryShowMessage(false, "Couldn't update the profile", 'Error while changing user information');
  };

  handleDiscardProfileInfoChanges = () => {
    // TODO: stop reading user info/profile bits from profile @localstorage. Instead use userAccount prop.
    // when profile info changes are discarded, load them back from loaclStorage to states.
    const profile = auth.getCurrentUser();
    this.checkIfProfileExistsOnLocalStorageOrAnonymous();
    console.log('discard clicked; profile: ', profile);

    const { fullName } = this.props.userAccount.userAccount;

    this.setState({
      fullname: fullName || '',
      email: profile.email,
      isFormEdited: false,
    });
  };

  handleResetPasswordChange = () => {
    console.log('reset password clicked');
    if (!this.state.isResetPasswordInProgress) {
      this.setState({
        isResetPasswordInProgress: true,
      });

      const profile = auth.getCurrentUser();
      this.checkIfProfileExistsOnLocalStorageOrAnonymous();

      this.props.changeUserPassword(profile.email, this.onResetPasswordChangeSuccess, this.onResetPasswordChangeFail);
    }
  };

  onResetPasswordChangeSuccess = () => {
    console.log('reset pass success');

    this.setState({
      isResetPasswordInProgress: false,
    });

    this.handleTemporaryShowMessage(
      true,
      'Password reset request',
      'A reset password link has been sent to the email address below. Follow the link to select a new password.',
    );
  };

  onResetPasswordChangeFail = () => {
    console.log('reset pass fail');

    this.setState({
      isResetPasswordInProgress: false,
    });

    this.handleTemporaryShowMessage(
      false,
      // eslint-disable-next-line
      "Couldn't send change password email",
      'Error while sending the password change email',
    );
  };

  renderMessage = (isPositive, messageHeader, messageContent) => {
    return (
      <Grid.Row className="message-box-row" centered columns={3}>
        {isPositive ? (
          <Grid.Column className="message-box-wrapper">
            <Icon name="info circle" />
            {messageHeader}
            <div className="message-content">{messageContent}</div>
          </Grid.Column>
        ) : (
          <></>
        )}
      </Grid.Row>
    );
  };

  isActionButtonsDisabled = () => {
    return !this.state.isFormEdited || this.props.userInformation.fetching || this.state.fullname === '';
  };

  render() {
    console.log('ProfilePage; props: ', this.props);

    return (
      <div className="profile-page-wrapper">
        <PageHeader>
          <PageHeaderTitleContainer>
            <PageHeaderTitle>Profile settings </PageHeaderTitle>
          </PageHeaderTitleContainer>
        </PageHeader>

        {this.state.showMessage &&
          this.renderMessage(this.state.isMessagePositive, this.state.messageHeader, this.state.messageContent)}

        <PageSubHeader style={{ paddingBottom: 0 }}>
          <PageSubHeaderTitle>Profile details</PageSubHeaderTitle>
        </PageSubHeader>

        <Form className="profile-page-form">
          <Form.Group className="email-name-form-group">
            <Form.Field>
              <ForesightInput
                placeholder="Name Surname"
                name="fullname"
                labelText={'Name'}
                value={this.state.fullname}
                onChange={this.handleInputChange}
              />
            </Form.Field>

            <Form.Field disabled>
              <ForesightInput labelText={'E-mail'} placeholder="E-mail" name="email" value={this.state.email} />
            </Form.Field>
          </Form.Group>

          <div className="profile-page-button-wrapper">
            <BlueButton
              onClick={this.handleSaveProfileInfoChanges}
              content="Save"
              disabled={this.isActionButtonsDisabled()}
              loading={this.props.userInformation.fetching}
            />

            <TextButton
              onClick={this.handleDiscardProfileInfoChanges}
              content="Discard changes"
              disabled={this.isActionButtonsDisabled()}
            />
          </div>
        </Form>

        <PageSubHeader style={{ paddingBottom: 0 }}>
          <PageSubHeaderTitle>Change Password</PageSubHeaderTitle>
          <PageSubHeaderText>
            If you need to change your password you can{' '}
            <ALink onClick={this.handleResetPasswordChange}>reset your password</ALink>
          </PageSubHeaderText>
        </PageSubHeader>
      </div>
    );
  }
}

export default ProfilePage;
