import React from 'react';
import { Tooltip } from 'components';
import './OverviewInfo.scss';
import { Link } from 'react-router-dom';

export const OverviewInfoValue = ({ ...props }) => {
  const generateName = props.styleClassName ? `value-sub-title ${props.styleClassName}` : 'value-sub-title';
  return (
    <>
      <span className={generateName}>{props.subTitle}</span>
      <span className="value">
        <i className={props.iconName} />
        <Tooltip blackEdition content={props.tooltipContent}>
          <span>{props.value}</span>
        </Tooltip>
      </span>
    </>
  );
};

export const OverviewInfoValueWithoutTooltip = ({ ...props }) => {
  const generateName = props.styleClassName ? `value-sub-title ${props.styleClassName}` : 'value-sub-title';
  return (
    <>
      <span className={generateName}>{props.subTitle}</span>
      <span className="value">
        <i className={props.iconName} />
        <span>{props.value}</span>
      </span>
    </>
  );
};

export const OverviewInfoCell = ({ children, ...props }) => {
  return (
    <div className="info-cell" {...props}>
      {children}
    </div>
  );
};

export const OverviewInfoLinkCell = ({ children, ...props }) => {
  const generateName = props.styleClassName ? `info-cell  ${props.styleClassName}` : 'info-cell ';
  return (
    <Link className={generateName} {...props} target="_blank">
      {children}
    </Link>
  );
};

export const OverviewInfoRow = ({ children, ...props }) => {
  return (
    <div className="info-row" {...props}>
      {children}
    </div>
  );
};

export const OverviewInfoContainer = ({ children, ...props }) => {
  return (
    <div className="overview-info-wrapper" {...props}>
      {children}
    </div>
  );
};
