import React, { Component } from 'react';
import './TestRunSummaryIcon.scss';
import { getTestRunStatusIcon } from 'utils/testrun-status-icon';
import { TESTRUN_STATUS } from 'utils/testrun-status';

export default class TestRunSummaryIcon extends Component {
	constructor(props) {
		super(props);

		this.state = {
			testRunColorDisabled: true,
		};
	}

	render() {
		let style = this.props.styleClass;
		let { onClick } = this.props;
		if (this.props.testRun.status === TESTRUN_STATUS.RUNNING) {
			style = style + ' still-progress';
			onClick = () => {};
		}
		return (
			<div
				key={this.props.testRun.id}
				onClick={onClick}
				onMouseOver={() =>
					this.setState({
						workflowColorDisabled: false,
					})
				}
				onMouseLeave={() =>
					this.setState({
						workflowColorDisabled: true,
					})
				}
				className={style}
			>
				{this.state.testRunColorDisabled && getTestRunStatusIcon(this.props.testRun, this.props.overrideColor)}
				{!this.state.testRunColorDisabled && getTestRunStatusIcon(this.props.testRun)}
			</div>
		);
	}
}
