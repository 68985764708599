import avatarOwnerLogo from 'assets/images/avatar-owner.svg';
import { LoadingDimmer } from 'components/ComponentPlaceholder';
import { TeamOrganizationBillingContainer, UserInvitationModalContainer } from 'containers';
import React from 'react';
import { toast } from 'react-toastify';
import { Image, Label, Popup, Table } from 'semantic-ui-react';
import { INVITED_USER_STATUS_ENUM } from 'utils/invited-user-status-enum';
import { USER_ROLES_ENUM } from 'utils/user-roles-enum';
import './TeamOrganization.scss';
import successCircle from 'assets/images/success-circle.png';
import { Route, Switch } from 'react-router-dom';
import { isUserInInvitedBillingAdminRole } from 'utils/user-role-util';
import {
  PageSubHeader,
  PageSubHeaderTitle,
  PageSubHeaderTitleContainer,
} from 'custom-components/PageSubHeader/PageSubHeader';
import { ForesightInput } from 'custom-components/Input/Input';
import { BlueButton } from 'custom-components/Button/Button';
import { ForesightTable } from 'custom-components/Table/Table';
import { ForesightTabs } from 'custom-components/Tabs/Tabs';
import {
  PageHeader,
  PageHeaderText,
  PageHeaderTitle,
  PageHeaderTitleContainer,
} from 'custom-components/PageHeader/PageHeader';
import { ForesightCheckbox } from 'custom-components/Checkbox/Checkbox';
import { ToastConfig } from 'utils/toast-util';
import { TeamOrganizationWorkflowsContainer } from 'containers/TeamOrganizationWorkflowsContainer';
import { UI_ICON } from 'assets/font-icons/IconManager';

const BUTTON_TEXT_ARR = ['Organization', 'Users', 'Billing', 'Workflows'];
class TeamOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationName: '',
      isUserInvitationModalOpen: false,
      prCommentEnabled: false,
      checkrunEnabled: false,
      isPopupOpenId: [],
      activeSection: 'ORGANIZATION',
    };
  }

  getOrganizationId = props => {
    if (props.userAccount) {
      const { userAccount } = props.userAccount;
      return userAccount.organization?.id;
    }
    return null;
  };

  isPrCommentEnabled = props => {
    return props.organizationSettings?.organizationSettings?.organizationFeature?.prCommentEnabled;
  };

  isCheckRunEnabled = props => {
    return props.organizationSettings?.organizationSettings?.organizationFeature?.checkrunEnabled;
  };

  componentDidMount() {
    const { tabName } = this.props.match.params;
    const uppercaseTabName = tabName.toUpperCase();
    this.setState({
      activeSection: uppercaseTabName,
      prCommentEnabled: this.isPrCommentEnabled(this.props),
      checkrunEnabled: this.isCheckRunEnabled(this.props),
    });
    const { userAccount } = this.props;
    if (!isUserInInvitedBillingAdminRole(userAccount.role)) this.props.getMyOrganizationUserList();
    this.props.getOrganizationSettings(this.getOrganizationId(this.props));
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params?.tabName !== prevProps.match.params?.tabName) {
      const uppercaseTabName = this.props.match.params?.tabName.toUpperCase();
      this.setState({ activeSection: uppercaseTabName });
    }
    if (
      this.isPrCommentEnabled(prevProps) !== this.isPrCommentEnabled(this.props) ||
      this.isCheckRunEnabled(prevProps) !== this.isCheckRunEnabled(this.props)
    ) {
      this.setState({
        prCommentEnabled: this.isPrCommentEnabled(this.props),
        checkrunEnabled: this.isCheckRunEnabled(this.props),
      });
    }
  }

  onInputChange = (e, data) => {
    this.setState({
      organizationName: data.value,
    });
  };

  getUsersTabPane = () => {
    const { loading } = this.props;
    const { isUserInvitationModalOpen } = this.state;

    return (
      <>
        <div className="table-header-wrapper">
          <PageSubHeader>
            <PageSubHeaderTitleContainer>
              <PageSubHeaderTitle>All users</PageSubHeaderTitle>
              <BlueButton primary onClick={this.inviteButtonOnClick} loading={loading}>
                + Invite users
              </BlueButton>
            </PageSubHeaderTitleContainer>
          </PageSubHeader>

          {isUserInvitationModalOpen && (
            <UserInvitationModalContainer
              isUserInvitationModalOpen={isUserInvitationModalOpen}
              onUserInviteModalClose={this.onUserInviteModalClose}
            />
          )}
        </div>
        {this.getUsersTable()}
      </>
    );
  };

  getBillingTabPane = () => {
    return (
      <>
        <TeamOrganizationBillingContainer />
      </>
    );
  };

  getWorkflowsTabPane = () => {
    return (
      <>
        <TeamOrganizationWorkflowsContainer />
      </>
    );
  };

  inviteButtonOnClick = () => {
    this.setState({
      isUserInvitationModalOpen: true,
    });
  };

  onUserInviteModalClose = isSuccess => {
    this.setState({
      isUserInvitationModalOpen: false,
    });

    if (isSuccess) {
      this.props.getMyOrganizationUserList();
    }
  };

  getUserNameCell = (name, invitedUser, accountOwner) => {
    const userNames = name ? name.split(' ') : undefined;

    return (
      <div className="user-table-fullname-cell">
        {userNames ? (
          <div className="user-letter-avatar">{`${userNames[0][0]}${userNames[1] ? userNames[1][0] : ''}`}</div>
        ) : (
          <i className={`${UI_ICON.PROFILE.AVATAR} avatar-icon`} />
        )}
        <div className="text-cell">
          {userNames ? name : 'No name'}
          {accountOwner && (
            <Popup
              className="owner-popup right"
              trigger={<Image className="avatar-owner-img" src={avatarOwnerLogo} fluid />}
              hideOnScroll={true}
              content="Owner"
              position="right center"
            />
          )}
        </div>
        {invitedUser && <Label className="user-invited-tag">Pending Invitation</Label>}
      </div>
    );
  };

  getUsersTable = () => {
    const { organization } = this.props;

    if (organization.fetching) {
      return (
        <div style={{ marginTop: 10 }}>
          <LoadingDimmer msg={'Loading...'} />
        </div>
      );
    }

    return (
      <ForesightTable basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Name</Table.HeaderCell>
            <Table.HeaderCell width={4}>Mail</Table.HeaderCell>
            <Table.HeaderCell width={3}>Role</Table.HeaderCell>
            <Table.HeaderCell width={1} />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {organization.myOrganizationUserList &&
            organization.myOrganizationUserList.map(user => {
              return (
                <Table.Row key={user.id}>
                  <Table.Cell>
                    {this.getUserNameCell(
                      user.fullname,
                      INVITED_USER_STATUS_ENUM['PENDING'] === user.status,
                      user.accountOwner,
                    )}
                  </Table.Cell>
                  <Table.Cell>{user.email}</Table.Cell>
                  <Table.Cell>{USER_ROLES_ENUM[user.role]}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {!user.accountOwner && (
                      <Popup
                        id="team-organization-dropdown-parent"
                        trigger={
                          <div className="icons-wrapper">
                            <i className={UI_ICON.MORE.VERTICAL} />
                            <i className={UI_ICON.MORE.VERTICAL_WHITE} />
                          </div>
                        }
                        onOpen={() => this.setState({ isPopupOpenId: { [user.id]: true } })}
                        onClose={() => this.setState({ isPopupOpenId: [] })}
                        open={this.state.isPopupOpenId[user.id] ? this.state.isPopupOpenId[user.id] : false}
                        basic
                        hideOnScroll={true}
                        on="click"
                        content={this.renderOrganizationActionsPopup(user)}
                        position="bottom left"
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </ForesightTable>
    );
  };

  renderOrganizationActionsPopup = user => {
    if (INVITED_USER_STATUS_ENUM['PENDING'] === user.status) {
      return (
        <div id="team-organization-dropdown-id" className="team-organization-dropdown">
          <div onClick={() => this.resendUserInvitationEmail(user.id)} className="item">
            Resend
          </div>
        </div>
      );
    }

    const menuItemsJSX = [];

    if (USER_ROLES_ENUM[user.role] === USER_ROLES_ENUM.ADMIN) {
      menuItemsJSX.push(
        <div onClick={() => this.changeUserRole(user)} className="item">
          Set as User
        </div>,
      );
    } else if (USER_ROLES_ENUM[user.role] === USER_ROLES_ENUM.USER) {
      menuItemsJSX.push(
        <div onClick={() => this.changeUserRole(user)} className="item">
          Set as Admin
        </div>,
      );
    }

    menuItemsJSX.push(<div className="item-delimiter" />);

    menuItemsJSX.push(
      <div onClick={() => this.removeUserFromOrganization(user.userId)} className="item item-last">
        Remove from this organization
      </div>,
    );

    return (
      <div id="team-organization-dropdown-id" className="team-organization-dropdown">
        {menuItemsJSX}
      </div>
    );
  };

  changeUserRole = user => {
    let role;
    if (USER_ROLES_ENUM[user.role] === USER_ROLES_ENUM.USER) {
      role = Object.keys(USER_ROLES_ENUM).find(key => USER_ROLES_ENUM[key] === USER_ROLES_ENUM.ADMIN);
    }
    if (USER_ROLES_ENUM[user.role] === USER_ROLES_ENUM.ADMIN) {
      role = Object.keys(USER_ROLES_ENUM).find(key => USER_ROLES_ENUM[key] === USER_ROLES_ENUM.USER);
    }

    this.props.changeUserRoleInOrganization(user.userId, role, this.refreshUserList);

    this.setState({ isPopupOpenId: [] });

    toast.success(
      <div className="toast-image-wrapper">
        <Image src={successCircle} />
        The role has changed successfully
      </div>,
    );
  };

  removeUserFromOrganization = userId => {
    this.props.removeUserFromOrganization(userId, this.refreshUserList);
  };

  resendUserInvitationEmail = invitationId => {
    this.props.resendUserInvitation(invitationId, this.onResendUserSuccessful);

    this.setState({ isPopupOpenId: [] });
  };

  onResendUserSuccessful = () => {
    toast.success(
      <div className="toast-image-wrapper">
        <Image src={successCircle} />
        Invitations have been sent successfully
      </div>,
    );
  };

  refreshUserList = () => {
    this.props.getMyOrganizationUserList();
  };

  saveButtonOnClick = () => {
    const { organizationName } = this.state;
    this.props.updateOrganizationName(organizationName, this.onUpdateOrganizationNameSuccess);
  };

  savePreferenceButtonOnClick = e => {
    e.preventDefault();
    const organizationId = this.getOrganizationId(this.props);
    const { prCommentEnabled, checkrunEnabled } = this.state;
    this.props.updateOrganizationSettings(organizationId, {
      organizationFeature: {
        ...this.props.organizationSettings?.organizationSettings?.organizationFeature,
        prCommentEnabled: prCommentEnabled,
        checkrunEnabled: checkrunEnabled,
        organizationId: organizationId,
      },
    });
  };

  onUpdateOrganizationNameSuccess = () => {
    this.props.getAllOrganizationList();
    this.props.getActiveOrganizationList();
    this.props.getUserAccount();
    toast.success('You successfully updated orgnization name.', ToastConfig);
  };

  renderAboutOrganization = () => {
    const { organization, organizationSettings, userAccount } = this.props;
    const { organizationName, prCommentEnabled, checkrunEnabled } = this.state;

    const { fetching: featureSettingsFetching } = organizationSettings;

    return (
      <div className="about-organization-wrapper">
        <PageSubHeader style={{ paddingBottom: '12px' }}>
          <PageSubHeaderTitle>About organization</PageSubHeaderTitle>
        </PageSubHeader>
        <div className="input-cont-team-org">
          <ForesightInput
            value={organizationName}
            onChange={this.onInputChange}
            placeholder={userAccount.userAccount.organization?.organizationName}
            labelText="Name"
          />
        </div>
        <div className="action-wrapper">
          <BlueButton
            disabled={!organizationName || organizationName.trim().length === 0}
            onClick={this.saveButtonOnClick}
            loading={organization.settingsFetching}
            className="submit-button"
          >
            Save changes
          </BlueButton>
        </div>
        <PageSubHeader style={{ paddingBottom: '12px' }}>
          <PageSubHeaderTitle>Pull requests comments</PageSubHeaderTitle>
        </PageSubHeader>
        <div className="input-cont-team-org">
          <ForesightCheckbox
            checked={prCommentEnabled || false}
            label={'Enable pull request comments'}
            disabled={featureSettingsFetching}
            onChange={(e, data) => {
              this.setState({ prCommentEnabled: data.checked });
            }}
          />
          <ForesightCheckbox
            checked={checkrunEnabled || false}
            label={'Enable check result'}
            disabled={featureSettingsFetching}
            onChange={(e, data) => {
              this.setState({ checkrunEnabled: data.checked });
            }}
          />
        </div>
        <div className="action-wrapper">
          <BlueButton
            onClick={this.savePreferenceButtonOnClick}
            loading={featureSettingsFetching}
            disabled={
              prCommentEnabled === this.isPrCommentEnabled(this.props) &&
              checkrunEnabled === this.isCheckRunEnabled(this.props)
            }
            className="submit-button"
          >
            Save preference
          </BlueButton>
        </div>
      </div>
    );
  };

  handleRoute = name => {
    switch (name.toUpperCase()) {
      case 'ORGANIZATION':
        this.setState({ activeSection: name });
        this.props.history.push('/organization/organization');
        break;
      case 'USERS':
        this.setState({ activeSection: name });
        this.props.history.push('/organization/users');
        break;
      case 'BILLING':
        this.setState({ activeSection: name });
        this.props.history.push('/organization/billing');
        break;
      case 'WORKFLOWS':
        this.setState({ activeSection: name });
        this.props.history.push('/organization/workflows');
        break;
      default:
        break;
    }
  };

  getOrganizationTabPane = () => {
    return <div className="organization-tab-pane">{this.renderAboutOrganization()}</div>;
  };

  render() {
    const { userAccount } = this.props;
    const { activeSection } = this.state;

    let tabJSX = (
      <>
        <ForesightTabs
          buttonTextArr={BUTTON_TEXT_ARR}
          onClick={(e, data) => this.handleRoute(data.name)}
          active={activeSection}
        />
      </>
    );
    const isBillingAdmin = isUserInInvitedBillingAdminRole(userAccount.userAccount.role);
    if (isBillingAdmin) {
      tabJSX = (
        <ForesightTabs
          buttonTextArr={['Billing']}
          onClick={(e, data) => this.handleRoute(data.name)}
          active={activeSection}
        />
      );
    }

    return (
      <div className="team-organization-lists-wrapper">
        <PageHeader>
          <PageHeaderTitleContainer>
            <PageHeaderTitle>
              {`${userAccount.userAccount.organization?.organizationName} Organization Settings`}{' '}
            </PageHeaderTitle>
          </PageHeaderTitleContainer>

          <PageHeaderText>
            Manage your organization, and control which information other users see and may access.
          </PageHeaderText>
        </PageHeader>

        <div className="btn-grp-container">{tabJSX}</div>
        <Switch>
          {!isBillingAdmin && (
            <Route path={'/organization/organization'}>
              {() => <div className="team-organization-list-tabs">{this.getOrganizationTabPane()}</div>}
            </Route>
          )}
          {!isBillingAdmin && (
            <Route path={'/organization/users'}>
              {() => <div className="team-organization-list-tabs">{this.getUsersTabPane()}</div>}
            </Route>
          )}
          <Route path={'/organization/billing'}>
            {() => <div className="team-organization-list-tabs">{this.getBillingTabPane()}</div>}
          </Route>
          <Route path={'/organization/workflows'}>
            {() => <div className="team-organization-list-tabs">{this.getWorkflowsTabPane()}</div>}
          </Route>
        </Switch>
      </div>
    );
  }
}

export default TeamOrganization;
