import React from 'react';
import { CI_PROVIDER, NOTIFICATION_INTEGRATION } from 'enums/git-provider';
import './util.scss';
import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
export const renderLogo = (ciProvider, className) => {
  switch (ciProvider) {
    case CI_PROVIDER.GITHUB:
      return <i className={`${className} ${DOMAIN_ICON.LOGOS.GITHUB_LOGO}`} />;
    case NOTIFICATION_INTEGRATION.SLACK:
      return <i className={`${className} ${DOMAIN_ICON.LOGOS.SLACK_LOGO}`} />;
    default:
      return <div className={className}></div>;
  }
};
