import React, { Component } from 'react';
import { MainLayoutContainer } from 'containers';
import routeList from 'routes';
import { LoadingPage } from 'components';
import { Popup } from 'semantic-ui-react';
import { RepositoryTabsContainer, TestRunsFilterContainer, TestRunsInfiniteTableContainer } from 'containers';
import './RepositoriesTestRunsPage.scss';
import { isDemoEnv } from 'utils/config-util';
import { defineRepoNameToPageTitle } from 'utils/page-title-util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { decodeRepoName } from 'utils/test-run-detail-util';
import ForesightBreadcrumb, { createForesightBreadCrumbDataItem } from 'custom-components/Breadcrumb/Breadcrumb';

export default class RepositoriesTestRunsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStopWatchingPopupOpen: false,
      isStopWatchingButtonOpen: false,
    };
  }

  renderBreadcrumb = () => {
    const pathData = {};
    const data = [];
    const { repoName } = this.props.match.params;

    data.push(
      createForesightBreadCrumbDataItem('Repositories', pathData, () => {
        this.props.history.push(routeList.repositories.path);
      }),
    );
    data.push(createForesightBreadCrumbDataItem(decodeRepoName(repoName), pathData));
    data.push(createForesightBreadCrumbDataItem('Test runs', pathData));

    return <ForesightBreadcrumb type="link-item" breadcrumbData={data} />;
  };

  redirectProjectsPage = () => {
    this.props.history.push(routeList.projectStart.path);
  };

  render() {
    console.log('TestRunsPage, render; props: ', this.props);
    const { repoName } = this.props.match.params;
    const { repos, projects } = this.props;
    const { isStopWatchingButtonOpen } = this.state;
    const flagStopWatching = false; //TODO This part hide because of ProjectId parameters needed but this page not known projectId

    return !this.props.match.params.repoName && !isDemoEnv() ? (
      <LoadingPage />
    ) : (
      <MainLayoutContainer title={defineRepoNameToPageTitle(this.props, routeList.repositoryTestRuns.title)}>
        <div className="testruns-page-wrapper">
          <div className="header">{this.renderBreadcrumb()}</div>
          <div className="repository-title-container">
            <div className="title">{decodeRepoName(repoName)}</div>
            {flagStopWatching && (
              <Popup
                onClick={() => this.setState({ isStopWatchingPopupOpen: true })}
                className="repository-settings-popup"
                content={<div className="stop-watching-btn">Stop watching</div>}
                position="bottom left"
                on="click"
                onOpen={() => this.setState({ isStopWatchingButtonOpen: true })}
                onClose={() => this.setState({ isStopWatchingButtonOpen: false })}
                inverted
                basic
                trigger={
                  <div className={`setting ${isStopWatchingButtonOpen && 'pressed'}`}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </div>
                }
              />
            )}
          </div>
          <RepositoryTabsContainer />
          <div className="content">
            <div className="content-left" style={{ height: `${window.screen.height - 300}px` }}>
              <TestRunsFilterContainer />
            </div>
            <div className="content-right">
              <div id="testruns-scrollable">
                <TestRunsInfiniteTableContainer repos={repos} projects={projects} />
              </div>
            </div>
          </div>
        </div>
      </MainLayoutContainer>
    );
  }
}
