import React, { Component } from 'react';
import routeList from 'routes';
import './MenuProjects.scss';

export default class MenuProjects extends Component {
  handleProjectClick = () => {
  	this.props.history.push(routeList.projectStart.path);
  };

  render() {
  	return (
  	// TODO: In future cycle this button will be dropdown
  	// <Dropdown className="menu-dropdown" item text="Projects">
  	//   <Dropdown.Menu>
  	//     <Dropdown.Item>Thundra API</Dropdown.Item>
  	//   </Dropdown.Menu>
  	// </Dropdown>

  		<div className="project-header" onClick={this.handleProjectClick}>
  			<div className="project-header-container">
  				<span id="projects-text">Projects</span>
  			</div>
  		</div>
  	);
  }
}
