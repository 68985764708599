import auth from 'auth/auth-factory';
import axios from 'axios';
import {
  GET_PULL_REQUEST_WORKFLOW_RUNS_FILTER_LIST,
  GET_PULL_REQUEST_WORKFLOW_RUNS_META,
  SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_IDS_SELECTED,
  SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_BRANCHES_SELECTED,
  SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_CONCLUSIONS_SELECTED,
  SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_EVENTS_SELECTED,
  SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_HIDE_FORK_BRANCHES_SELECTED,
} from 'store/constants/pull-request-workflow-runs-filter';
import { CONFIG } from 'utils/config-util';

export function getPullRequest_WorkflowRunsMeta(ciProvider, repoOwner, repoName, prId) {
  return {
    type: GET_PULL_REQUEST_WORKFLOW_RUNS_META,
    meta: { ciProvider: ciProvider, repoOwner: repoOwner, repoName: repoName },
    payload: axios.post(
      CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/workflows`,
      { pullRequestNumber: parseInt(prId + '') },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function setSelectedPullRequest_WorkflowRuns_WorkflowIds(workflowIds, prId) {
  return {
    type: SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_IDS_SELECTED,
    payload: { data: workflowIds, prId: prId },
  };
}

export function getPullRequest_WorkflowRuns_WorkflowRunFilters(
  ciProvider,
  repoOwner,
  repoName,
  prId,
  hideForkBranches,
) {
  return {
    type: GET_PULL_REQUEST_WORKFLOW_RUNS_FILTER_LIST,
    meta: { ciProvider: ciProvider, repoOwner: repoOwner, repoName: repoName, hideForkBranches },
    payload: axios.post(
      CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/workflow-runs/filters`,
      { pullRequestNumber: parseInt(prId + ''), hideForkBranches },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function setSelectedPullRequest_WorkflowRuns_Conclusions(conclusion, prId) {
  return {
    type: SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_CONCLUSIONS_SELECTED,
    payload: { data: conclusion, prId: prId },
  };
}

export function setSelectedPullRequest_WorkflowRuns_Branches(branch, prId) {
  return {
    type: SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_BRANCHES_SELECTED,
    payload: { data: branch, prId: prId },
  };
}

export function setSelectedPullRequest_WorkflowRuns_Events(events, prId) {
  return {
    type: SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_EVENTS_SELECTED,
    payload: { data: events, prId: prId },
  };
}
export function setSelectedPullRequest_WorkflowRuns_HideForkBranches(hideFork, prId) {
  return {
    type: SET_PULL_REQUEST_WORKFLOW_RUNS_WORKFLOW_RUN_HIDE_FORK_BRANCHES_SELECTED,
    payload: { data: hideFork, prId: prId },
  };
}
