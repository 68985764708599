import React, { Component } from 'react';
import { MainLayoutContainer } from 'containers';
import routeList from 'routes';
import { ProfilePageContainer } from 'containers';
import './SettingsProfilePage.scss';

class SettingsProfilePage extends Component {
	render() {
		return (
			<MainLayoutContainer title={routeList.settingsProfile.title}>
				<div className="settings-profile-page-wrapper">
					<ProfilePageContainer />
				</div>
			</MainLayoutContainer>
		);
	}
}

export default SettingsProfilePage;
