import React from 'react';

export const browserStackIcon = () => {
	return (
		<svg width="16" height="16" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M64 32C64 49.6736 49.6736 64 32 64C14.327 64 0 49.6736 0 32C0 14.327 14.327 0 32 0C49.6736 0 64 14.327 64 32Z"
					fill="#ECB360"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M55.4667 28.8C55.4667 44.7057 43.0493 57.6 27.7333 57.6C12.4167 57.6 0 44.7057 0 28.8C0 12.8943 12.4167 0 27.7333 0C43.0493 0 55.4667 12.8943 55.4667 28.8Z"
					fill="#D76835"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M57.6 25.5997C57.6 39.7384 46.616 51.2 33.0658 51.2C19.5173 51.2 8.53333 39.7384 8.53333 25.5997C8.53333 11.4616 19.5173 0 33.0658 0C46.616 0 57.6 11.4616 57.6 25.5997Z"
					fill="#D33A41"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M55.4667 27.7336C55.4667 40.6933 45.4375 51.2 33.0664 51.2C20.6952 51.2 10.6667 40.6933 10.6667 27.7336C10.6667 14.7728 20.6952 4.26666 33.0664 4.26666C45.4375 4.26666 55.4667 14.7728 55.4667 27.7336Z"
					fill="#B6CB46"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M53.3333 29.8664C53.3333 41.6485 43.7818 51.2 31.9997 51.2C20.2182 51.2 10.6667 41.6485 10.6667 29.8664C10.6667 18.0842 20.2182 8.53333 31.9997 8.53333C43.7818 8.53333 53.3333 18.0842 53.3333 29.8664Z"
					fill="#66AD4A"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M44.8 25.6C44.8 35.0256 37.159 42.6667 27.7333 42.6667C18.3071 42.6667 10.6667 35.0256 10.6667 25.6C10.6667 16.1743 18.3071 8.53333 27.7333 8.53333C37.159 8.53333 44.8 16.1743 44.8 25.6Z"
					fill="#AED7DC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M46.9333 23.4667C46.9333 31.7139 40.2477 38.4 31.9997 38.4C23.7517 38.4 17.0667 31.7139 17.0667 23.4667C17.0667 15.2189 23.7517 8.53333 31.9997 8.53333C40.2477 8.53333 46.9333 15.2189 46.9333 23.4667Z"
					fill="#5BB1CF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M46.9333 24.533C46.9333 32.1914 41.2028 38.4 34.1333 38.4C27.0639 38.4 21.3333 32.1914 21.3333 24.533C21.3333 16.8753 27.0639 10.6667 34.1333 10.6667C41.2028 10.6667 46.9333 16.8753 46.9333 24.533Z"
					fill="#25A8C3"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M31.9997 38.4C26.1092 38.4 21.3333 33.1466 21.3333 26.6664C21.3333 20.1861 26.1092 14.9333 31.9997 14.9333C37.8908 14.9333 42.6667 20.1861 42.6667 26.6664C42.6667 33.1466 37.8908 38.4 31.9997 38.4Z"
					fill="#1B1A18"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M37.8202 23.2834C36.8054 24.981 34.8103 25.9628 33.3632 25.4748C31.916 24.9874 31.5658 23.2152 32.5798 21.517C33.5946 19.8188 35.5897 18.8376 37.0368 19.325C38.484 19.8124 38.8342 21.5846 37.8202 23.2834Z"
					fill="#FFFFFE"
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="64" height="64" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
