import { GET_USER_ACCOUNT } from 'store/constants/user-account';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';

export function getUserAccount() {
  // This endpoint is different than Foresight's getUserAccount.
  return {
    type: GET_USER_ACCOUNT,
    payload: axios.get(CONFIG.apiUrl + '/accounts/me', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
