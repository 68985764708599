import React, { Component } from 'react';
import './TenMostActiveWorkflows.scss';
import { ExecutionCountWidget } from './ExecutionCountWidget/ExecutionCountWidget';
import { SuccessRatesWidget } from './SuccessRatesWidget/SuccessRatesWidget';
import { ActiveListWidget } from './ActiveListWidget/ActiveListWidget';
export class TenMostActiveWorkflows extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLine: null,
            sentListName: null,
        };
    }

    handleSelectedLine = (val) => {
        this.setState({ selectedLine: val });
    };

    handleSentListName = (val) => {
        this.setState({ sentListName: val });
    };

    render() {
        const {
            title,
            handlerId,
            withList = false,
            successFailCountOfMostActive10Workflows,
        } = this.props;
        const { selectedLine, sentListName } = this.state;
        return (
            <div className='ten-most-active-workflows'>
                <div className='most-active-workflows-header'>
                    {title}
                </div>
                <div className='section-charts-container'>
                    <div className='most10active-chart'>
                        <ExecutionCountWidget
                            data={successFailCountOfMostActive10Workflows}
                            selectedBar={this.handleSelectedLine}
                            sentListName={sentListName}
                        />
                    </div>
                    <div className='most10active-chart'>
                        <SuccessRatesWidget
                            data={successFailCountOfMostActive10Workflows}
                            selectedBar={this.handleSelectedLine}
                            sentListName={sentListName}
                        />
                    </div>
                </div>
                {withList &&
                    <div className='section-list-container'>
                        <ActiveListWidget
                            data={successFailCountOfMostActive10Workflows}
                            handlerId={handlerId}
                            handleName={selectedLine}
                            handleSentListName={this.handleSentListName}
                        />
                    </div>
                }
            </div>
        );
    }
}