import React, { Component } from 'react';
import './GitHubAppFirstProjectCreationAndRepoSelectionPage.scss';
import { Button } from 'semantic-ui-react';
import { ProjectSelectionCreationInputWithActions } from 'components';
import { RepositorySelectableListContainer, PopupPageDesignCloseIconHeaderContainer } from 'containers';
import { LoadingDimmer } from 'components';
import routeList from 'routes';
import { CONFIG } from 'utils/config-util';
import { cloneArray } from 'utils/common-util';
import { getCiProviderConfigLink } from 'utils/git-provider-config-links';
import { hasArrayElement } from 'utils/array-util';
import { SESSION_URL_KEY } from 'utils/redirection-force-util';
import { ALink } from 'custom-components/ALink/ALink';
import { UI_ICON } from 'assets/font-icons/IconManager';

class GitHubAppFirstProjectCreationAndRepoSelectionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newRepoList: [],
      isCreateProjectFailed: false,
      newProjectName: '',
    };
  }

  componentDidMount() {
    this.props.getCIProviderIntegrations();
    this.props.getAllProjects(this.onGetAllProjectsSuccess);

    // Look for 'github_installation_id' when redirected from GitHub app.
    const installationId = new URLSearchParams(this.props.location.search).get('installation_id');
    if (installationId) {
      this.props.createCIProviderIntegration(
        installationId,
        'github',
        this.onCreateSourceProviderIntegrationSuccess,
        this.onCreateSourceProviderIntegrationFail,
      );

      localStorage.removeItem('github_installation_id');
      localStorage.removeItem(SESSION_URL_KEY);
    } else {
      this.getCIProviderReposByUser();
    }
  }

  getCIProviderReposByUser = () => {
    this.props.getCIProviderRepos('github', null, this.onGetCIProviderReposSuccess, this.onGetCIProviderReposFail);
  };

  onGetAllProjectsSuccess = () => {
    const { projects } = this.props;

    // If user created projects before, cant come to this page anymore.
    if (projects.projectList.length > 0) {
      // TODO: if exists add github installation id as query param
      this.props.history.replace({
        pathname: routeList.startGitHubExistedProjectRepoSelectionPage.path,
        search: window.location.search,
      });
    }
  };

  onGetCIProviderIntegrationsSuccess = () => {
    console.log('onGetCIProviderIntegrationsSuccess');

    this.getCIProviderReposByUser();
  };

  onCreateSourceProviderIntegrationSuccess = () => {
    console.log('onCreateSourceProviderIntegrationSuccess');
    this.getCIProviderReposByUser();

    // This is to clean 'installationId' from query params.
    this.props.history.replace({
      pathname: routeList.startCreateProjectSelectGithubRepos.path,
      search: '',
    });
  };

  onCreateSourceProviderIntegrationFail = () => {
    console.log('onCreateSourceProviderIntegrationFail; props: ', this.props);
  };

  onGetCIProviderReposSuccess = () => {
    const { ciRepoList } = this.props.ciRepos;
    this.setState({
      newRepoList: cloneArray(ciRepoList),
    });
  };
  onGetCIProviderReposFail = () => {
    console.log('onGetCIProviderReposFail');
  };

  handleCheckboxClick = (idx, data) => {
    const { newRepoList } = this.state;
    const foundItem = newRepoList.findIndex(item => item.name === data.label);
    newRepoList[foundItem].checked = data.checked;

    this.setState({ newRepoList: [...newRepoList] });
  };

  cancelCreateProject = () => {
    this.setState({
      newProjectName: '',
    });
    this.closeModal();
  };

  renderFooterActionButtons = () => {
    const { createNewProjectWithSelectedRepoFetching } = this.props.ciRepos;
    const { newRepoList } = this.state;

    return (
      <>
        <Button
          onClick={this.cancelCreateProject}
          loading={createNewProjectWithSelectedRepoFetching}
          className="cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={this.onCreateNewProjectWithSelectedRepo}
          loading={createNewProjectWithSelectedRepoFetching}
          disabled={createNewProjectWithSelectedRepoFetching || !newRepoList.some(item => item.checked === true)}
          className="submit-button"
        >
          Create project
        </Button>
      </>
    );
  };

  closeModal = () => {
    this.props.history.push(routeList.repositories.path);
  };

  onCreateNewProjectWithSelectedRepo = () => {
    const { newRepoList, newProjectName } = this.state;

    if (typeof newProjectName === 'string' && newProjectName.length > 0) {
      this.setState({
        isCreateProjectFailed: false,
      });

      const selectedRepoList = newRepoList
        .filter(repo => repo.checked === true)
        .map(repo => {
          return {
            repo: {
              fullName: repo.repo.fullName,
              name: repo.repo.name,
              owner: repo.repo.owner,
              publiclyAccessible: repo.repo.publiclyAccessible,
              defaultBranch: repo.repo.defaultBranch,
            },
          };
        });

      this.props.createNewProjectWithSelectedRepo(
        'github',
        newProjectName,
        selectedRepoList,
        this.onCreateNewProjectSuccess,
        this.onCreateNewProjectFailed,
      );
    } else {
      this.setState({
        isCreateProjectFailed: true,
      });
    }
  };

  onCreateNewProjectSuccess = () => {
    this.props.history.push(routeList.startIntegrationGitHubTestRuns.path);
  };

  onCreateNewProjectFailed = () => {
    this.setState({
      isCreateProjectFailed: true,
    });
  };

  handleNewProjectNameChange = value => {
    this.setState({
      newProjectName: value,
    });
  };

  isSourceProviderExist = () => {
    const { ciProviderList } = this.props.ciConnect;
    return hasArrayElement(ciProviderList);
  };

  getCiProviderConfigLink = () => {
    const { ciProviderList } = this.props.ciConnect;

    let installationURL = CONFIG.FORESIGHT_GITHUB_APP;
    if (this.isSourceProviderExist()) {
      installationURL = getCiProviderConfigLink(ciProviderList[0]);
    }

    return installationURL;
  };

  handleSelectAllReposToggle = data => {
    // console.log('handleSelectAllReposToggle; data: ', data);
    const isAllReposSelected = data.checked;
    const { newRepoList } = this.state;
    let modifiedRepoList = [];
    if (isAllReposSelected) {
      // watch all repos
      modifiedRepoList = newRepoList.map(repo => {
        return {
          ...repo,
          checked: true,
        };
      });
    } else {
      // unwatch all repos
      modifiedRepoList = newRepoList.map(repo => {
        return {
          ...repo,
          checked: false,
        };
      });
    }

    this.setState({
      newRepoList: modifiedRepoList,
    });
  };

  getInputErrorMessage = () => {
    let message = 'Your project must have a name';

    return (
      <span className="error-input-message">
        <i className={UI_ICON.ALERT.ERROR} />
        {message}
      </span>
    );
  };

  render() {
    const { isCreateProjectFailed, newRepoList } = this.state;
    const { ciRepoList } = this.props.ciRepos;
    const { ciProviderList } = this.props.ciConnect;
    const { projects } = this.props;

    if (projects.fetching) {
      return <LoadingDimmer />;
    }

    return (
      <div className="project-repo-selection-page-container">
        <PopupPageDesignCloseIconHeaderContainer />
        <div className="page-body-container">
          <div className="pos-block">
            <div className="page-body">
              <div className="body-part left">
                <div className="title">Create your first project</div>
                <div className="desc">
                  Foresight project is the group of repositories you selected. You need to have at least one project to
                  get started
                </div>
                <ProjectSelectionCreationInputWithActions
                  onInputChange={e => this.handleNewProjectNameChange(e.target.value)}
                  inputHasError={isCreateProjectFailed}
                  errorMessage={this.getInputErrorMessage()}
                  placeholder="Your project name"
                />
              </div>
              <div className="body-part right repositories-list">
                <div className="top-container">
                  <div className="title">Select repositories</div>
                  <div className="desc">
                    This is the list of GitHub repositories you gave permission. If you can't find the repository you
                    are looking for, please{' '}
                    <ALink href={this.getCiProviderConfigLink()} target="_blank" rel="noopener noreferrer">
                      check your GitHub permissions.
                    </ALink>
                  </div>
                </div>
                <div className="bottom-container">
                  <RepositorySelectableListContainer
                    ciProviderList={ciProviderList}
                    prevRepoList={ciRepoList}
                    newRepoList={newRepoList}
                    handleCheckboxClick={this.handleCheckboxClick}
                    handleSelectAllReposToggle={this.handleSelectAllReposToggle}
                  />
                </div>
              </div>
            </div>
            <div className="section-divider" />
            <div className="footer-actions">{this.renderFooterActionButtons()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default GitHubAppFirstProjectCreationAndRepoSelectionPage;
