import React, { Component } from 'react';
import { MainLayoutContainer } from 'containers';
import './DetailLayout.scss';
import PropTypes from 'prop-types';

class DetailLayout extends Component {
  render() {
    return (
      <MainLayoutContainer title={this.props.title}>
        <div className="top-header-bar">{this.props.headerComponent}</div>
        <div className="detail-layout-content">{this.props.children}</div>
      </MainLayoutContainer>
    );
  }
}

DetailLayout.propTypes = {
  headerComponent: PropTypes.element.isRequired,
  title: PropTypes.string,
};

export default DetailLayout;
