import React from 'react';
import { LazyLog } from 'react-lazylog';
import './LazyLogViewer.scss';

export class LazyLogViewer extends React.Component {
	render() {
		return (
			<LazyLog
				// rowHeight={18}
				extraLines={1}
				enableSearch={true}
				selectableLines={true}
				lineClassName="log-line"
				highlightLineClassName="log-line-highlighted"
				{...this.props}
			/>
		);
	}
}
