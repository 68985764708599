import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/font-icons/style.css';
import * as serviceWorker from './serviceWorker';

//Styles
import 'react-toastify/dist/ReactToastify.css'; //Toast Default Style
import 'assets/toast-styles/foresight.css'; //Foresight Override Toast Style
import 'semantic-ui-css/semantic.min.css';

import './assets/styles/index.scss';
import './app.scss';

//Fonts

import App from './App';
//import * as serviceWorker from './serviceWorker';

import { PersistGate } from 'redux-persist/lib/integration/react';
import store, { persistor } from './store/store';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { initFullStory, initHeap, initSentry } from './monitoringSetup';

import history from './history.js';
import ErrorBoundary from 'ErrorBoundary';

// Call FS and Heap init script.
initFullStory();
initHeap();
initSentry();

const workWithMockServices = false;
if (process.env.REACT_APP_ENV === 'dev' && workWithMockServices) {
  const { worker } = require('./mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}

// This is to eliminate console.logs from prod builds!
// https://github.com/facebook/create-react-app/issues/1491
if (process.env.REACT_APP_ENV === 'prod') {
  console.log = () => {};
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
if (process.env.REACT_APP_ENV === 'dev' && workWithMockServices) {
  serviceWorker.unregister();
}
