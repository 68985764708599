import React from 'react';
import './PageSubHeader.scss';

export const PageSubHeaderText = ({ children, ...props }) => {
  return (
    <div className="page-sub-header-text" {...props}>
      {children}
    </div>
  );
};

export const PageSubHeaderTitle = ({ children, ...props }) => {
  return (
    <div className="page-sub-header-title" {...props}>
      {children}
    </div>
  );
};

export const PageSubHeaderTitleContainer = ({ children, ...props }) => {
  return (
    <div className="page-sub-header-title-container" {...props}>
      {children}
    </div>
  );
};

export const PageSubHeaderTitleActionContainer = ({ children, ...props }) => {
  return (
    <div className="page-sub-header-title-action-container" {...props}>
      {children}
    </div>
  );
};

export const PageSubHeader = ({ children, ...props }) => {
  return (
    <div className="page-sub-header" {...props}>
      {children}
    </div>
  );
};
