import React from 'react';
import _ from 'lodash';
import { hasArrayElement } from 'utils/array-util';
import './HaveTestRunsTab.scss';

export const HaveTestRunsTab = ({ onClick, data }) => {
  if (!hasArrayElement(data)) {
    return <div className="have-test-run-text">Does not have test runs</div>;
  } else {
    return (
      <div className="have-test-run-container">
        {data.map(item => {
          return (
            <div className="have-test-run-text" onClick={() => onClick(item)} key={_.uniqueId()}>
              {item.jobName}
            </div>
          );
        })}
      </div>
    );
  }
};
