import React, { Component } from 'react';
import routeList, { computePath } from 'routes';
import { getTestCaseOverviewDetail } from 'utils/test-detail-util';
import { LoadingDimmer, NoDataAvailable, SomethingError } from '../ComponentPlaceholder';
import './TestRunTestTabs.scss';

class TestRunTestTabs extends Component {
  handleClick = type => {
    const { testRunId, transactionId, ciProvider, repoOwner, repoName } = this.props.match.params;
    let computedPath = '';
    switch (type) {
      case 'errors':
        computedPath = computePath(routeList.testRunTestErrors.path, {
          testRunId: testRunId,
          transactionId: transactionId,
          ciProvider: ciProvider,
          repoOwner: repoOwner,
          repoName: repoName,
        });
        break;

      case 'logs':
        computedPath = computePath(routeList.testRunTestLogs.path, {
          testRunId: testRunId,
          transactionId: transactionId,
          ciProvider: ciProvider,
          repoOwner: repoOwner,
          repoName: repoName,
        });
        break;

      case 'screenshots':
        computedPath = computePath(routeList.testRunTestScreenshots.path, {
          testRunId: testRunId,
          transactionId: transactionId,
          ciProvider: ciProvider,
          repoOwner: repoOwner,
          repoName: repoName,
        });
        break;

      case 'snapshots':
        computedPath = computePath(routeList.testRunTestSnapshots.path, {
          testRunId: testRunId,
          transactionId: transactionId,
          ciProvider: ciProvider,
          repoOwner: repoOwner,
          repoName: repoName,
        });
        break;

      case 'code':
        computedPath = computePath(routeList.testRunTestCode.path, {
          testRunId: testRunId,
          transactionId: transactionId,
          ciProvider: ciProvider,
          repoOwner: repoOwner,
          repoName: repoName,
        });
        break;

      default:
        computedPath = computePath(routeList.testRunTestPerformance.path, {
          testRunId: testRunId,
          transactionId: transactionId,
          ciProvider: ciProvider,
          repoOwner: repoOwner,
          repoName: repoName,
        });
        break;
    }

    this.props.history.push(computedPath);
  };

  getTestCaseTabButtonClassName = checkBtnId => {
    const { selectedTab } = this.props;
    if (checkBtnId === selectedTab) {
      return 'tb-btn selected-btn';
    }
    return 'tb-btn normal-btn';
  };

  getTestOverviewData = props => {
    const { transactionId } = props.match.params;
    const { testDetailMap } = props.testDetails;
    return getTestCaseOverviewDetail(testDetailMap, transactionId);
  };

  renderTabButtons = status => {
    return (
      <div className="test-run-test-tabs-container">
        {status === 'FAILED' && (
          <div className={this.getTestCaseTabButtonClassName('errors')} onClick={() => this.handleClick('errors')}>
            Errors
          </div>
        )}
        <div
          className={this.getTestCaseTabButtonClassName('performance')}
          onClick={() => this.handleClick('performance')}
        >
          Performance
        </div>
        <div className={this.getTestCaseTabButtonClassName('logs')} onClick={() => this.handleClick('logs')}>
          Logs
        </div>
        {/* <div
  				className={this.getTestCaseTabButtonClassName('screenshots')}
  				onClick={() => this.handleClick('screenshots')}
  			>
          Screenshots
  			</div> */}
      </div>
    );
  };

  render() {
    const testOverviewObj = this.getTestOverviewData(this.props);
    const { fetching, fetched, error, testOverview } = testOverviewObj;

    if (fetching) {
      return (
        <div className="logs-loading-msg-container">
          <LoadingDimmer msg="Loading" />
        </div>
      );
    } else if (error) {
      return (
        <div className="error-logs-msg-container">
          <SomethingError msg={error} />
        </div>
      );
    } else if (fetched) {
      return this.renderTabButtons(testOverview.status);
    } else {
      return (
        <div className="no-logs-data-msg-container">
          <NoDataAvailable msg="No Errors Found" />
        </div>
      );
    }
  }
}

export default TestRunTestTabs;
