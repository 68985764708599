import { connect } from 'react-redux';
import { ProjectSelectionDisplayAndModify } from 'components';
import { withRouter } from 'react-router';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { getCIProviderIntegrations } from 'store/actions/ci-provider-connect';
import {
	createNewProject,
	setRepoSelectedProject,
	getAllProjects,
	setProjectSelectionComponentState,
} from 'store/actions/projects';
import { getCIProviderRepos } from 'store/actions/ci-provider-repos';

function mapStateToProps(store, props) {
	return {
		projects: store.projects,
		repos: store.repos,
		getCIProviderReposByProjectId: props.getCIProviderReposByProjectId,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getCIProviderIntegrations(onGetSourceProviderIntegrationsSuccess, onGetSourceProviderIntegrationsFail) {
			dispatchWithExceptionHandler(
				getCIProviderIntegrations(),
				false,
				onGetSourceProviderIntegrationsSuccess,
				onGetSourceProviderIntegrationsFail,
			);
		},
		createNewProject(projectName, onCreateNewProjectSuccess, onCreateNewProjectFail) {
			dispatchWithExceptionHandler(
				createNewProject(projectName),
				false,
				onCreateNewProjectSuccess,
				onCreateNewProjectFail,
			);
		},
		setRepoSelectedProject(projectId, onSetRepoSelectedProjectSuccess, onSetRepoSelectedProjectFail) {
			dispatchWithExceptionHandler(
				setRepoSelectedProject(projectId),
				false,
				onSetRepoSelectedProjectSuccess,
				onSetRepoSelectedProjectFail,
			);
		},
		getAllProjects(onGetAllProjectsSuccess, onGetAllProjectsFail) {
			dispatchWithExceptionHandler(getAllProjects(), false, onGetAllProjectsSuccess, onGetAllProjectsFail);
		},
		getCIProviderRepos(provider, projectId, onGetCIProviderReposSuccess, onGetCIProviderReposFail) {
			dispatchWithExceptionHandler(
				getCIProviderRepos(provider, projectId),
				false,
				onGetCIProviderReposSuccess,
				onGetCIProviderReposFail,
			);
		},
		// if project selection or creation is not completed yet, set false value to store.Otherwise set true.
		setProjectSelectionComponentState(isSelectionOrCreationCompleted) {
			dispatch(setProjectSelectionComponentState(isSelectionOrCreationCompleted));
		},
	};
}

const ProjectSelectionDisplayAndModifyContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ProjectSelectionDisplayAndModify),
);
export default ProjectSelectionDisplayAndModifyContainer;
