import moment from 'moment';
import React, { Component } from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { computeDateFormatFromInterval, roundData } from 'utils/computeStartDateFromInterval';
import { ENUM_FORMAT_DATE } from 'utils/date-util';
import { HIGHLIGHTS_DATE_ENUM } from 'utils/highlights-enums';
import './ExecutionCountWidget.scss';

const xAxisBarChartConfig = {
  xAxisProps: {
    dataKey: 'time',
  },
};

const screenSize = window.screen.width > 480 ? 520 : 290;
export class ExecutionCountWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
    };
  }

  componentDidMount() {
    this.convertHistogramData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.convertHistogramData();
    }
  }

  xAxisTimeFormatter = val => {
    const {
      data: { executionInfos },
      timeRange,
    } = this.props;
    const formattedData = roundData(Object.entries(executionInfos), 2);
    const times = formattedData.map(el => el.time).sort();
    const startDate = moment(times[0], ENUM_FORMAT_DATE.allTime);
    const endDate = moment(times[times.length - 1], ENUM_FORMAT_DATE.allTime);

    const dateFormatterString = moment(val, 'YYYY-MM-DD HH:mm:ss.sss Z')
      .local()
      .format(
        timeRange !== HIGHLIGHTS_DATE_ENUM.ONE_DAY
          ? computeDateFormatFromInterval(startDate, endDate)
          : ENUM_FORMAT_DATE.hours,
      );
    return dateFormatterString;
  };

  convertHistogramData() {
    const {
      data: { executionInfos },
    } = this.props;
    const chartData = {};

    for (const [key, value] of Object.entries(executionInfos).sort()) {
      const obj = chartData[key];
      if (obj) {
        chartData[key] = {
          ...obj,
          Success: value.successCount,
          Fail: value.failCount,
          Other: value.otherRunCount,
          time: key,
        };
      } else {
        chartData[key] = {
          Success: value.successCount,
          Fail: value.failureCount,
          Other: value.otherRunCount,
          time: key,
        };
      }
    }
    const convertData = Object.values(chartData);
    this.setState({
      chartData: convertData,
    });
  }

  customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ background: '#161B22', borderColor: '#161B22', borderRadius: '3px', padding: '20px' }}>
          <p style={{ color: '#dcdfe5', fontSize: 12 }}>{moment(label).format('DD MMM')}</p>
          <p style={{ color: '#2abf81', fontSize: 12 }}>{`${payload[0]?.dataKey} : ${payload[0]?.value}`}</p>
          <p style={{ color: '#f26c6c', fontSize: 12 }}>{`${payload[1]?.dataKey} : ${payload[1]?.value}`}</p>
          <p style={{ color: '#5c6d75', fontSize: 12 }}>{`${payload[2]?.dataKey} : ${payload[2]?.value}`}</p>
        </div>
      );
    }

    return null;
  };

  render() {
    const { chartData } = this.state;

    return (
      <div className="execution-count-chart">
        <div className="chart-title"> Daily/Hourly Execution Count</div>

        <BarChart
          width={screenSize}
          height={232}
          data={chartData}
          margin={{
            top: 3,
            right: 3,
            left: -38,
            bottom: 3,
          }}
        >
          <CartesianGrid horizontal={true} vertical={false} stroke="#2A3B50" />
          <XAxis
            axisLine={false}
            dataKey={xAxisBarChartConfig.xAxisProps.dataKey}
            tickFormatter={this.xAxisTimeFormatter}
          />
          <YAxis
            axisLine={false}
            tick={{
              fill: '#788496',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '11x',
              lineHeight: '16px',
            }}
            width={70}
          />
          <Tooltip content={this.customTooltip} cursor={{ fill: 'rgba(255, 255, 255, 0.12)' }} />
          <Bar name="successful runs" dataKey="Success" stackId="a" fill="#27B177" />
          <Bar name="failed runs" dataKey="Fail" stackId="a" fill="#EF2622" />
          <Bar name="otherRuns" dataKey="Other" stackId="a" fill="#5c6d75" />
        </BarChart>
      </div>
    );
  }
}
