import { GET_WHITELIST_FULFILLED, GET_WHITELIST_PENDING, GET_WHITELIST_REJECTED } from '../constants/whitelist';

const initialState = {
  whiteList: [],
  fetching: false,
  fetched: false,
  error: null,
};

export default function whiteList(state = initialState, action) {
  switch (action.type) {
    case GET_WHITELIST_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_WHITELIST_FULFILLED:
      console.log(`${action.type}, reducer; action: `, action);
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        whiteList: action.payload.data,
      };
    case GET_WHITELIST_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
