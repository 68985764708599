import React, { Component } from 'react';
import { clipTestSuiteName, getTestRunSuitesObj, getTestSuite } from 'utils/test-run-detail-util';
import { hasArrayElement } from 'utils/array-util';
import './TestRunOverviewSortingCard.scss';
import { calcDisplayedTime, timeDiffAsMillis } from 'utils/date-util';
import routeList, { computePath } from 'routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { DOMAIN_ICON, UI_ICON } from 'assets/font-icons/IconManager';

class TestRunOverviewSortingCard extends Component {
  handleTestSuiteClick = suiteName => {
    const { testRunId, ciProvider, repoOwner, repoName } = this.props.match.params;
    const testRunSuitePath = computePath(routeList.testRunTestSuite.path, {
      testRunId: testRunId,
      testSuiteName: suiteName,
      ciProvider: ciProvider,
      repoOwner: repoOwner,
      repoName: repoName,
    });
    this.props.history.push(testRunSuitePath + '#auto-focus');
  };

  handleTestCaseClick = (transactionId, status) => {
    const { testRunId, ciProvider, repoOwner, repoName } = this.props.match.params;

    const testErrorOrPerfPath = status === 'FAILED' ? routeList.testRunTestErrors : routeList.testRunTestPerformance;
    const testComputedPath = computePath(testErrorOrPerfPath.path, {
      testRunId: testRunId,
      transactionId: transactionId,
      ciProvider: ciProvider,
      repoOwner: repoOwner,
      repoName: repoName,
    });

    this.props.history.push(testComputedPath + '#auto-focus');
  };

  renderStatus = status => {
    let iDOM = '';
    switch (status) {
      case 'SUCCESSFUL':
        iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.PASSED + ' successfull'} />;
        break;

      case 'FAILED':
        iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.FAILED + ' failed'} />;
        break;

      case 'ABORTED':
        iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.ABORTED + ' aborted'} />;
        break;

      case 'SKIPPED':
        iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.SKIPPED + ' skipped'} />;
        break;
      default:
        break;
    }
    return <div>{iDOM}</div>;
  };

  renderItem = ({ index, handleOnClick, status, name, valueType, value, suiteName }) => {
    let vDOM = value;
    if (valueType === 'duration') {
      vDOM = (
        <div className="duration">
          <i className="icon-elapsed-time" />
          <span>{value}</span>
        </div>
      );
    }

    if (valueType === 'duration&suite') {
      vDOM = (
        <>
          <div className="duration">
            <i className="icon-elapsed-time" />
            <span>{value}</span>
            <FontAwesomeIcon icon={faCircle} />
            <span
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.handleTestSuiteClick(suiteName);
              }}
              className="suite-name-in-testcase"
            >
              {clipTestSuiteName(suiteName)}
            </span>
          </div>
        </>
      );
    }

    if (valueType === 'errorCount') {
      vDOM = (
        <div className="error-count">
          <span>{value}</span>
        </div>
      );
    }

    return (
      <div className="item" key={index} onClick={handleOnClick}>
        <div className="left-part">
          <div className="status">{this.renderStatus(status)}</div>
          <div className="item-title">
            <div className="name">{name}</div>
            <div className="value">{vDOM}</div>
          </div>
        </div>
        <div className="right-part">
          <i className={UI_ICON.CHEVRON.RIGHT} />
        </div>
      </div>
    );
  };

  //==================================================
  // TEST RUN
  //===================================================
  renderMostErroneousSuites = () => {
    const { testRunId } = this.props.match.params;
    const { testRunDetailMap } = this.props.testRunDetails;
    const testSuitesObj = getTestRunSuitesObj(testRunDetailMap, testRunId);
    const testSuites = testSuitesObj.suites;
    let suiteVDOM = (
      <div className="all-test-passed-container">
        <div className="centered-container">
          <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.ALL_PASSED} />
          <span>All test suits successful</span>
        </div>
      </div>
    );

    if (hasArrayElement(testSuites)) {
      const sortedTestSuites = testSuites
        .filter(el => {
          const { tags } = el;
          const errorCount = tags['test.suite.failed.count'] || 0;
          return errorCount > 0;
        })
        .map(el => {
          const { tags } = el;
          return { error: tags['test.suite.failed.count'], name: tags['test.suite'] };
        })
        .sort((a, b) => {
          return b.error - a.error;
        })
        .slice(0, 5);

      if (hasArrayElement(sortedTestSuites)) {
        suiteVDOM = sortedTestSuites.map(el => {
          const { name, error } = el;
          const itemProp = {
            name: name,
            handleOnClick: () => this.handleTestSuiteClick(name),
            status: 'FAILED',
            valueType: 'errorCount',
            value: `${error} errors`,
          };

          return <>{this.renderItem(itemProp)}</>;
        });
      }
    }

    return <div className="list-items">{suiteVDOM}</div>;
  };

  renderMostSlowestSuites = () => {
    const { testRunId } = this.props.match.params;
    const { testRunDetailMap } = this.props.testRunDetails;
    const testSuitesObj = getTestRunSuitesObj(testRunDetailMap, testRunId);
    const testSuites = testSuitesObj.suites;
    let suiteVDOM = '';

    if (hasArrayElement(testSuites)) {
      const sortedTestSuites = testSuites
        .map(el => {
          const { tags, startTime, endTime } = el;
          return {
            execTime: timeDiffAsMillis(endTime, startTime),
            name: tags['test.suite'],
            error: tags['test.suite.failed.count'],
          };
        })
        .sort((a, b) => {
          return b.execTime - a.execTime;
        })
        .slice(0, 5);

      suiteVDOM = sortedTestSuites.map(el => {
        const { name, execTime, error } = el;
        const itemProp = {
          name: name,
          handleOnClick: () => this.handleTestSuiteClick(name),
          status: error > 0 ? 'FAILED' : 'SUCCESSFUL',
          value: `${calcDisplayedTime(execTime)}`,
          valueType: 'duration',
        };

        return <>{this.renderItem(itemProp)}</>;
      });
    }

    return <div className="list-items">{suiteVDOM}</div>;
  };

  renderMostSlowestTests = () => {
    const { testRunId } = this.props.match.params;
    const { testRunDetailMap } = this.props.testRunDetails;
    const testSuitesObj = getTestRunSuitesObj(testRunDetailMap, testRunId);
    const testSuites = testSuitesObj.suites;

    let combinedCases = [];
    if (hasArrayElement(testSuites)) {
      const testCases = testSuites.map(el => {
        if (el && hasArrayElement(el.cases)) {
          const arrCases = [];
          el.cases.forEach(tCase =>
            arrCases.push({
              ...tCase,
              suiteName: el.tags ? el.tags['test.suite'] : 'xx',
            }),
          );
          return arrCases;
        } else {
          return [];
        }
      });

      combinedCases = [...combinedCases, ...testCases];
    }

    const cases = combinedCases.flat();
    let caseVDOM = (
      <div className="all-test-passed-container">
        <div className="centered-container">
          <span>No Test exist</span>
        </div>
      </div>
    );

    if (hasArrayElement(cases)) {
      const sortedCases = cases
        .filter(e => e !== undefined)
        .map(el => {
          const { testName, endTime, startTime, transactionId, status, suiteName } = el;
          return {
            execTime: timeDiffAsMillis(endTime, startTime),
            name: testName,
            transactionId: transactionId,
            status: status,
            suiteName: suiteName,
          };
        })
        .sort((a, b) => {
          return b.execTime - a.execTime;
        })
        .slice(0, 5);

      caseVDOM = sortedCases.map(el => {
        const { name, execTime, transactionId, status, suiteName } = el;
        const itemProp = {
          name: name,
          suiteName: suiteName,
          handleOnClick: () => this.handleTestCaseClick(transactionId, status),
          status: status,
          valueType: 'duration&suite',
          value: `${calcDisplayedTime(execTime)}`,
        };

        return <>{this.renderItem(itemProp)}</>;
      });
    }

    return <div className="list-items">{caseVDOM}</div>;
  };

  //==================================================
  // TEST SUIT
  //===================================================

  renderMostErroneousTestsInSuit = () => {
    const { testRunId, testSuiteName } = this.props.match.params;
    const { testRunDetailMap } = this.props.testRunDetails;
    const testSuite = getTestSuite(testRunDetailMap, testRunId, testSuiteName);
    const cases = (testSuite && testSuite.cases) || [];
    let testVDOM = (
      <div className="all-test-passed-container">
        <div className="centered-container">
          <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.ALL_PASSED} />
          <span>All tests successful</span>
        </div>
      </div>
    );

    if (hasArrayElement(cases)) {
      const sortedTestCases = cases
        .map(el => {
          const { status, testName, endTime, startTime, transactionId } = el;
          return {
            status: status,
            isError: status === 'FAILED',
            name: testName,
            execTime: timeDiffAsMillis(endTime, startTime),
            transactionId: transactionId,
          };
        })
        .filter(el => el.isError)
        .sort((a, b) => {
          return b.execTime - a.execTime;
        })
        .slice(0, 5);

      if (hasArrayElement(sortedTestCases)) {
        testVDOM = sortedTestCases.map(el => {
          const { name, execTime, transactionId, status } = el;
          const itemProp = {
            name: name,
            handleOnClick: () => this.handleTestCaseClick(transactionId, status),
            status: status,
            valueType: 'errorCount',
            value: `${calcDisplayedTime(execTime)}`,
          };

          return <>{this.renderItem(itemProp)}</>;
        });
      }
    }

    return <div className="list-items">{testVDOM}</div>;
  };

  renderMostSlowestTestsInSuit = () => {
    const { testRunId, testSuiteName } = this.props.match.params;
    const { testRunDetailMap } = this.props.testRunDetails;
    const testSuite = getTestSuite(testRunDetailMap, testRunId, testSuiteName);
    const cases = (testSuite && testSuite.cases) || [];
    let testVDOM = '';

    if (hasArrayElement(cases)) {
      const sortedTestCases = cases
        .map(el => {
          const { testName, endTime, startTime, transactionId, status } = el;
          return {
            name: testName,
            execTime: timeDiffAsMillis(endTime, startTime),
            transactionId: transactionId,
            status: status,
          };
        })
        .sort((a, b) => {
          return b.execTime - a.execTime;
        })
        .slice(0, 5);

      testVDOM = sortedTestCases.map(el => {
        const { name, execTime, transactionId, status } = el;
        const itemProp = {
          name: name,
          handleOnClick: () => this.handleTestCaseClick(transactionId, status),
          status: status,
          valueType: 'duration',
          value: `${calcDisplayedTime(execTime)}`,
        };

        return <>{this.renderItem(itemProp)}</>;
      });
    }

    return <div className="list-items"> {testVDOM}</div>;
  };

  render() {
    const { title, type } = this.props;
    let vDOM = '';

    //Run Details
    if (type === 'MostErroneousSuites') {
      vDOM = this.renderMostErroneousSuites();
    }

    if (type === 'MostSlowestSuites') {
      vDOM = this.renderMostSlowestSuites();
    }

    if (type === 'MostSlowestTests') {
      vDOM = this.renderMostSlowestTests();
    }

    //Suit Details
    if (type === 'MostErroneousTestsInSuit') {
      vDOM = this.renderMostErroneousTestsInSuit();
    }

    if (type === 'MostSlowestTestsInSuit') {
      vDOM = this.renderMostSlowestTestsInSuit();
    }

    return (
      <div className="overview-sorting-card-container">
        <div className="title">{title}</div>
        <div className="content">{vDOM}</div>
      </div>
    );
  }
}

export default TestRunOverviewSortingCard;
