import HeatMapGridComponent from 'components/HighlightsPage/HighlightParts/HeatMapGridComponent/HeatMapGridComponent';
import { LoadingDimmer, NoDataAvailable, SomethingError } from 'components/index';
import { Tooltip } from 'components/Tooltip';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { hasArrayElement } from 'utils/array-util';
import './TenMostErroneousWorkflows.scss';

const arrMap = {
    one: '1',
    two: '2',
    three: '3',
    five: '5',
    fifteen: '15',
    thirty: '30',
};

const infoText = ' This is the list of workflows executed the most among all your workflows, and grouped by their statuses. You can assess the reliablity of your active workflows. If there is too many erroneus workflows in your active workflows, fixing the errors would save you time. ';

export class TenMostErroneousWorkflows extends Component {
    formatTimeNumber = text => {
        if (arrMap[`${text}`]) return arrMap[`${text}`];
        return text;
    };

    formatTimeRange = timeRange => {
        if (typeof timeRange !== 'string') return '';
        return timeRange
            .split('_')
            .map(el => this.formatTimeNumber(el.toLowerCase()))
            .join(' ');
    };

    renderWithContent = () => {
        const { data, title, timeRange, handlerId } = this.props;

        return (
            <div className="dashboard-grid-heatmap-widget-card-container">
                <div className="header">
                    <div className="title">{title}&nbsp;<Tooltip blackEdition content={infoText}>
                        <Icon style={{ height: '20px' }} name="info circle" />
                    </Tooltip></div>
                </div>

                <div className="chart-heatmap-grid">
                    <HeatMapGridComponent
                        data={data}
                        timeRange={timeRange}
                        handlerId={handlerId}
                    />
                </div>
            </div>
        );
    };

    renderWithoutContent = () => {
        const { title, error, loading, unknown } = this.props;

        let contentJSX = <NoDataAvailable msg={'No data available'} />;
        if (error) {
            contentJSX = <SomethingError msg={error} />;
        } else if (loading) {
            contentJSX = <LoadingDimmer msg={'Loading...'} />;
        } else if (unknown) {
            contentJSX = <LoadingDimmer msg={'Unknown State...'} />;
        }

        return (
            <div className="dashboard-grid-heatmap-widget-card-container">
                <div className="dashboard-widget-card-container">
                    <div className="header">
                        <div className="title">{title}&nbsp;<Tooltip blackEdition content={infoText}>
                            <Icon style={{ height: '20px' }} name="info circle" />
                        </Tooltip></div>

                    </div>
                    <div className="other-contents">{contentJSX}</div>
                </div>
            </div>
        );
    };

    render() {
        const { data } = this.props;

        if (hasArrayElement(data)) {
            return this.renderWithContent();
        } else {
            return this.renderWithoutContent();
        }
    }
}