/* eslint-disable quotes */
import warning from 'assets/icons/warning.svg';
import successCircle from 'assets/images/success-circle.png';
import { CustomModal } from 'components/CustomModal';
import { Tooltip } from 'components/Tooltip';
import { ForesightCheckbox } from 'custom-components/Checkbox/Checkbox';
import { ForesightInput } from 'custom-components/Input/Input';
import { PageSubHeader, PageSubHeaderText, PageSubHeaderTitle } from 'custom-components/PageSubHeader/PageSubHeader';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Button, Icon, Image } from 'semantic-ui-react';
import { checkURLisValid } from 'utils/common-util';
import { renderLogo } from 'utils/git-provider-logos';
import { generateUUID } from 'utils/object-util';
import { isUserInInvitedUserRole } from 'utils/user-role-util';
import './NotificationIntegration.scss';

class NotificationIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
      notificationInputArr: [''],
      notValidURLs: [],
      hasErrors: false,
      isUpdate: false,
      sendResultOnlyFailedWorkflowRun: this.getFailureCheckValue(props),
      sendResultOnlyDefaultBranchWorkflowRun: this.getDefaultBranchCheckValue(props),
    };
  }

  getFailureCheckValue(props) {
    return props?.notificationPreferences?.notificationPreferences?.sendOnlyFailure || false;
  }

  getDefaultBranchCheckValue(props) {
    return props?.notificationPreferences?.notificationPreferences?.sendOnlyDefaultBranch || false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const current_sendOnlyFailure = this.getFailureCheckValue(this.props);
    const next_sendOnlyFailure = this.getFailureCheckValue(nextProps);

    const current_sendOnlyDefaultBranch = this.getDefaultBranchCheckValue(this.props);
    const next_sendOnlyDefaultBranch = this.getDefaultBranchCheckValue(nextProps);

    if (
      current_sendOnlyFailure !== next_sendOnlyFailure ||
      current_sendOnlyDefaultBranch !== next_sendOnlyDefaultBranch
    ) {
      this.setState({
        sendResultOnlyFailedWorkflowRun: next_sendOnlyFailure,
        sendResultOnlyDefaultBranchWorkflowRun: next_sendOnlyDefaultBranch,
      });
    }
  }

  componentDidMount() {
    this.props.getListWebHook();
    this.props.getSlackIntegrationLimit();
  }

  handleInputOnChange = (value, key) => {
    const { notificationInputArr } = this.state;

    notificationInputArr[key] = value;

    this.setState({
      notificationInputArr,
    });
  };

  /* This function is checking if the URL is valid. */
  isURLValid = url => {
    const { notValidURLs, hasErrors } = this.state;
    if (hasErrors) {
      if (notValidURLs.length === 0) {
        return true;
      }
    } else {
      if (notValidURLs.length === 0 || url.length === 0) {
        return true;
      }
    }

    return notValidURLs.find(x => x === url) === undefined;
  };

  /* Adding input for notification. */
  addInputForNotification = () => {
    const { userAccount } = this.props;
    const { notificationInputArr } = this.state;
    let inputJsx = [];

    for (let itemIndex in notificationInputArr) {
      inputJsx.push(
        <div className="description">
          <span className="label-for-input">Webhook URL</span>
          <div className="slack-input-container">
            <ForesightInput
              placeholder="e.g. https://hooks.slack.com/services/T00000000/B000000/XXXXXXXXX"
              hasError={!this.isURLValid(notificationInputArr[itemIndex])}
              key={itemIndex}
              value={notificationInputArr[itemIndex]}
              onChange={e => this.handleInputOnChange(e.target.value, itemIndex)}
              // onKeyDown={this.onPressEnterOnInput}
              autoFocus
            />
            {notificationInputArr.length > 1 && (
              <Tooltip style={{ zIndex: 2001 }} blackEdition position="top center" content={<div>Remove</div>}>
                <Icon
                  name="minus"
                  className="minus-slack-list-icon"
                  onClick={() => this.removeSlackURLInput(itemIndex)}
                  disabled={isUserInInvitedUserRole(userAccount.userAccount.role)}
                />
              </Tooltip>
            )}
          </div>
        </div>,
      );
    }

    return <>{inputJsx.map(item => item)}</>;
  };

  /* Removing the slack URL input. */
  removeSlackURLInput = index => {
    const { notificationInputArr } = this.state;

    notificationInputArr.splice(index, 1);

    this.setState({
      notificationInputArr,
    });
  };

  /* Adding an empty string to the notificationInputArr array. */
  inputArrayNotificationHandle = () => {
    const { notificationInputArr } = this.state;

    notificationInputArr.push('');

    this.setState({
      notificationInputArr,
    });
  };

  checkURLValidationFromList = urlList => {
    let notValidURLAddress = [];

    urlList.forEach(url => {
      if (!checkURLisValid(url)) {
        notValidURLAddress.push(url);
      }
    });
    if (notValidURLAddress.length > 0) {
      this.setState({ hasErrors: true });
    }

    this.setState({
      notValidURLs: notValidURLAddress,
    });

    return notValidURLAddress;
  };

  /* This is a function that is called when the user clicks on the create button. */
  createSlackIntegration = () => {
    const { notificationInputArr } = this.state;

    let notValidURL = this.checkURLValidationFromList(notificationInputArr);

    if (notValidURL.length === 0) {
      const data = notificationInputArr.map(item => {
        return {
          config: '',
          enabled: true,
          name: '',
          notificationTargetType: 'SLACK',
          url: item,
        };
      });
      this.props.createBatchWebHook(
        this.getSlackIntegrationObj(data),
        () => this.successCreateSlackIntegration('success'),
        () => this.successCreateSlackIntegration('fail'),
      );
    }
  };

  getSlackIntegrationObj = integrations => {
    const { sendResultOnlyFailedWorkflowRun, sendResultOnlyDefaultBranchWorkflowRun } = this.state;

    return {
      notificationPreference: {
        sendOnlyDefaultBranch: sendResultOnlyDefaultBranchWorkflowRun,
        sendOnlyFailure: sendResultOnlyFailedWorkflowRun,
      },
      notificationIntegrationList: integrations,
    };
  };

  /* Deleting the slack integration. */
  deleteSlackIntegration = () => {
    this.props.createBatchWebHook(
      this.getSlackIntegrationObj([]),
      () => this.handleSuccessOrFailDeleted('success'),
      () => this.handleSuccessOrFailDeleted('fail'),
    );
  };

  /* This is a function that is called when the user clicks on the create button. */
  successCreateSlackIntegration = type => {
    switch (type) {
      case 'success':
        this.props.getListWebHook();
        this.setState({ notificationInputArr: [''] });
        toast.success(
          <div className="toast-image-wrapper">
            <Image src={successCircle} />
            Slack Integration Connected.
          </div>,
        );
        break;
      case 'fail':
        toast.warn(
          <div className="toast-image-wrapper">
            <Image src={''} />
            Something went wrong.
          </div>,
        );
        break;
      default:
        break;
    }

    this.setState({ isNotificationIntegrationConnected: false });
  };

  /* This is a function that is called when the user clicks on the delete button. */
  handleSuccessOrFailDeleted = type => {
    switch (type) {
      case 'success':
        this.props.getListWebHook();
        toast.success(
          <div className="toast-image-wrapper">
            <Image src={successCircle} />
            Slack Integration has been deleted.
          </div>,
        );
        break;
      case 'fail':
        toast.warn(
          <div className="toast-image-wrapper">
            <Image src={warning} />
            Something went wrong.
          </div>,
        );
        break;
      default:
        break;
    }

    this.setState({ isDeleteModalOpen: false });
  };

  /* A function that returns a modal. */
  getModalForSlackIntegration = () => {
    const { userAccount } = this.props;
    const { isCreateBatchWebhookFetching, slackIntegrationLimit } = this.props.notificationIntegration;
    const {
      isNotificationIntegrationConnected,
      isUpdate,
      notificationInputArr,
      sendResultOnlyFailedWorkflowRun,
      sendResultOnlyDefaultBranchWorkflowRun,
    } = this.state;

    const hasPermission = !isUserInInvitedUserRole(userAccount.userAccount.role);

    return (
      <CustomModal
        className="stop-repo-watching-modal"
        isOpen={isNotificationIntegrationConnected}
        onModalClose={() => this.setState({ isStopWatchingModalOpen: false })}
      >
        <div className="title-section">
          <div className="title">{isUpdate ? "Edit Slack's Webhook URLs" : "Add Slack's Webhook URLs"}</div>
        </div>

        <div className="description">
          {isUpdate
            ? 'You can edit or remove your Slack Webhook URLs'
            : 'Creating an Incoming Webhook gives you a unique URL to which you send a JSON payload with the message text and some options. You can add multiple Webhook URL’s. Learn more about Incoming Webhooks'}
        </div>
        <div className="webhook-preferences">
          <ForesightCheckbox
            className="fullwidth"
            checked={sendResultOnlyFailedWorkflowRun}
            label={'Send only failed workflow run results'}
            onChange={(e, data) => {
              this.setState({ sendResultOnlyFailedWorkflowRun: data.checked });
            }}
            withIconName={null}
            iconPopupJsx={null}
          />
          <ForesightCheckbox
            className="fullwidth"
            checked={sendResultOnlyDefaultBranchWorkflowRun}
            label={'Send only workflow run results of default branch'}
            onChange={(e, data) => {
              this.setState({ sendResultOnlyDefaultBranchWorkflowRun: data.checked });
            }}
            withIconName={null}
            iconPopupJsx={null}
          />
        </div>
        {this.addInputForNotification()}
        {hasPermission && (
          <div>
            {slackIntegrationLimit?.data >= notificationInputArr.length ? (
              <a onClick={() => this.inputArrayNotificationHandle()}>+ Add a Webhook URL</a>
            ) : (
              <p className="max-limit-text">You reach the max limit {slackIntegrationLimit?.data}</p>
            )}
          </div>
        )}

        <div className="delete-actions">
          <Button
            onClick={() =>
              this.setState({
                isNotificationIntegrationConnected: false,
                notificationInputArr: [''],
                hasErrors: false,
                sendResultOnlyFailedWorkflowRun: this.getFailureCheckValue(this.props),
                sendResultOnlyDefaultBranchWorkflowRun: this.getDefaultBranchCheckValue(this.props),
              })
            }
            className="cancel-button"
          >
            Cancel
          </Button>
          {hasPermission && (
            <Button primary onClick={this.createSlackIntegration} loading={isCreateBatchWebhookFetching}>
              {isUpdate ? 'Save' : 'Add'}
            </Button>
          )}
        </div>
      </CustomModal>
    );
  };

  /* Creating a modal that will be displayed when the user clicks the delete button. */
  deleteSlackModal = () => {
    const { isCreateBatchWebhookFetching } = this.props.notificationIntegration;

    const { isDeleteModalOpen } = this.state;

    return (
      <CustomModal
        className="stop-repo-watching-modal"
        isOpen={isDeleteModalOpen}
        onModalClose={() => this.setState({ isDeleteModalOpen: false })}
      >
        <div className="title-section">
          <div>
            <Image src={warning} style={{ width: 21, marginRight: 11 }} />
          </div>

          <div className="title">Delete Slack Integration</div>
        </div>

        <div className="description">You won't get the notifications after you delete Slack integration.</div>

        <div className="delete-actions">
          <Button onClick={() => this.setState({ isDeleteModalOpen: false })} className="cancel-button">
            Cancel
          </Button>
          <Button primary onClick={this.deleteSlackIntegration} loading={isCreateBatchWebhookFetching}>
            Delete
          </Button>
        </div>
      </CustomModal>
    );
  };

  openUpdateScreen = () => {
    const { listWebhook } = this.props.notificationIntegration;
    const { notificationInputArr } = this.state;
    /* Filtering out empty strings from the array. */
    let filterArr = notificationInputArr.filter(item => item);
    listWebhook.forEach(item => filterArr.push(item.url));
    this.setState({ notificationInputArr: filterArr, isNotificationIntegrationConnected: true, isUpdate: true });
  };

  render() {
    const { userAccount } = this.props;
    const { listWebhook, isListWebhookFetching } = this.props.notificationIntegration;
    const hasSlackIntegration = listWebhook.length > 0;

    return (
      <div className="slack-integration-main-container">
        {this.getModalForSlackIntegration()}
        {this.deleteSlackModal()}

        <PageSubHeader>
          <PageSubHeaderTitle>Messaging</PageSubHeaderTitle>
          <PageSubHeaderText>
            If you'd like to automatically notify your team, use Foresight's messaging integrations
          </PageSubHeaderText>
        </PageSubHeader>

        <div key={generateUUID()} className="integration">
          <div className="ci-logo">{renderLogo('slack')}</div>
          <div className="ci-body">
            <div className="ci-provider-title">Slack</div>
            <div className="ci-link-wrapper">
              <a
                className={`${!hasSlackIntegration && 'disabled'} notification-edit-link`}
                rel="noopener noreferrer"
                onClick={this.openUpdateScreen}
              >
                Edit your configuration
              </a>
            </div>
          </div>

          {!isUserInInvitedUserRole(userAccount.userAccount.role) && (
            <div className="slack-notification-button-container">
              {hasSlackIntegration ? (
                <Button
                  onClick={() => this.setState({ isDeleteModalOpen: true, isUpdate: false })}
                  className="cancel-button"
                  loading={isListWebhookFetching}
                  disabled={isListWebhookFetching}
                >
                  Delete
                </Button>
              ) : (
                <Button
                  onClick={() => this.setState({ isNotificationIntegrationConnected: true })}
                  className="ci-status-button"
                  primary
                  loading={isListWebhookFetching}
                  disabled={isListWebhookFetching}
                >
                  Connect
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default NotificationIntegration;
