import {
  GET_STEP_LOG_URL_PENDING,
  GET_STEP_LOG_URL_REJECTED,
  GET_STEP_LOG_URL_FULFILLED,
  GET_STEP_LOG_FILE_PENDING,
  GET_STEP_LOG_FILE_REJECTED,
  GET_STEP_LOG_FILE_FULFILLED,
  SIGNED_STEP_LOG_FILE_TOO_LONG,
} from 'store/constants/workflow-steps-logs';

const workflowStepsLogInitialState = {
  stepLogs: {},
};

export default function workflowStepsLogs(state = workflowStepsLogInitialState, action) {
  const { stepId } = action.meta || { stepId: undefined };
  let stepObj = null;
  switch (action.type) {
    case GET_STEP_LOG_URL_PENDING:
      stepObj = getStepObj(state.stepLogs, stepId);
      stepObj.logURLFetching = true;
      stepObj.logURLError = false;
      stepObj.logURLFetched = false;
      return { ...state };
    case GET_STEP_LOG_URL_REJECTED:
      stepObj = getStepObj(state.stepLogs, stepId);
      stepObj.logURLFetching = false;
      stepObj.logURLError = true;
      stepObj.logURLFetched = false;
      return { ...state };
    case GET_STEP_LOG_URL_FULFILLED:
      stepObj = getStepObj(state.stepLogs, stepId);
      stepObj.logURLFetching = false;
      stepObj.logURLError = false;
      stepObj.logURLFetched = true;
      stepObj.logURL = action.payload.data;
      return { ...state };
    case GET_STEP_LOG_FILE_PENDING:
      stepObj = getStepObj(state.stepLogs, stepId);
      stepObj.logFileFetching = true;
      stepObj.logFileError = false;
      stepObj.logFileFetched = false;
      return { ...state };
    case GET_STEP_LOG_FILE_REJECTED:
      stepObj = getStepObj(state.stepLogs, stepId);
      stepObj.logFileFetching = false;
      stepObj.logFileError = true;
      stepObj.logFileFetched = false;
      return { ...state };
    case GET_STEP_LOG_FILE_FULFILLED:
      stepObj = getStepObj(state.stepLogs, stepId);
      stepObj.logFileFetching = false;
      stepObj.logFileError = false;
      stepObj.logFileFetched = true;
      stepObj.logFile = action.payload.data;
      return { ...state };
    case SIGNED_STEP_LOG_FILE_TOO_LONG:
      stepObj = getStepObj(state.stepLogs, stepId);
      stepObj.logFileFetching = false;
      stepObj.logFileError = false;
      stepObj.logFileTooLong = true;
      stepObj.logFileFetched = false;
      stepObj.logFile = action.payload.data;
      return { ...state };
    default:
      return state;
  }
}

const getStepObj = (stepLogs, stepId) => {
  const stepObj = stepLogs[stepId];
  if (stepObj !== undefined) return stepObj;
  else {
    stepLogs[stepId] = {
      logUrlFetching: false,
      logUrlError: false,
      logUrlFetched: false,
      logFileFetching: false,
      logFileError: false,
      logFileFetched: false,
      logFileTooLong: false,
      signedUrl: null,
      logFile: null,
    };
    return stepLogs[stepId];
  }
};
