import { hasArrayElement } from './array-util';

export const TESTRUN_OVERVIEW_PAGE_TYPE = {
  TEST_RUN: 'TEST_RUN',
  TEST_SUITE: 'TEST_SUITE',
  TEST_CASE: 'TEST_CASE',
};

export const decodeSomething = name => {
  const decodedName = decodeURIComponent(name + '');
  return decodedName;
};

export const decodeJobName = jobName => decodeSomething(jobName);
export const decodeSuiteName = suiteName => decodeSomething(suiteName);
export const decodeRepoName = repoName => decodeSomething(repoName);

export function clipTestSuiteName(suiteName) {
  if (typeof suiteName === 'string') {
    if (suiteName.indexOf('.js') > -1) {
      //NodeJS,JS
      return decodeSuiteName(suiteName.replace('.js', ''));
    } else if (suiteName.indexOf('.ts') > -1) {
      //NodeJS,JS
      return decodeSuiteName(suiteName.replace('.ts', ''));
    } else if (suiteName.indexOf('.jsx') > -1) {
      //NodeJS,JS
      return decodeSuiteName(suiteName.replace('.jsx', ''));
    } else if (suiteName.indexOf('.py') > -1) {
      //NodeJS,JS
      return decodeSuiteName(suiteName.replace('.py', ''));
    } else {
      //Java
      const pos = suiteName.indexOf('[');
      if (pos > -1) {
        suiteName = suiteName.substring(0, pos);
      }
      const nameParts = suiteName.split('.');
      if (hasArrayElement(nameParts)) {
        return decodeSuiteName(nameParts[nameParts.length - 1]);
      }
    }
  }
  return decodeSuiteName(suiteName);
}

export function getTestRunSuitesObj(testRunDetailMap, testRunId) {
  const testRunDetail = testRunDetailMap[testRunId];
  if (testRunDetail && testRunDetail.testSuitesObj) {
    return testRunDetail.testSuitesObj;
  }
  return [];
}

export function getTestRunOverviewObj(testRunDetailMap, testRunId) {
  const testRunDetail = testRunDetailMap[testRunId];
  if (testRunDetail && testRunDetail.testOverviewObj) {
    return testRunDetail.testOverviewObj;
  }
  return {};
}

export function getTestRunClientFilterObj(testRunDetailMap, testRunId) {
  const testRunDetail = testRunDetailMap[testRunId];
  if (testRunDetail && testRunDetail.clientFilterObj) {
    return testRunDetail.clientFilterObj;
  }
  return {};
}

export function getTestSuite(testRunDetailMap, testRunId, testSuitName) {
  const testRunDetail = testRunDetailMap[testRunId];
  if (testRunDetail && testRunDetail.testSuitesObj) {
    const { suites } = testRunDetail.testSuitesObj;
    if (hasArrayElement(suites)) {
      const suite = suites.find(suite => {
        const { tags } = suite;
        const name = tags['test.suite'];
        return name === decodeSuiteName(testSuitName);
      });
      return suite;
    }
  }
  return [];
}

export const isSuitesForceFetchNeeded = (testSuiteObj, testRunOverviewObj) => {
  const testRunOverviewTotalCount =
    testRunOverviewObj?.fetched && testRunOverviewObj?.overview?.totalCount
      ? testRunOverviewObj?.overview?.totalCount
      : -1;

  if (testRunOverviewTotalCount < 0) {
    console.log('isSuitesForceFetchNeeded TestRunOverviewObj not Ready', testRunOverviewObj);
    return true;
  }

  if (testSuiteObj?.fetched) {
    const testSuites = testSuiteObj.suites || [];
    let sumOfTestCases = 0;
    testSuites.forEach(el => {
      const cases = el.cases || [];
      sumOfTestCases += cases.length;
    });
    console.log('isSuitesForceFetchNeeded Overview And SuiteObj Compare', sumOfTestCases, testRunOverviewTotalCount);
    return sumOfTestCases !== testRunOverviewTotalCount;
  } else {
    console.log('isSuitesForceFetchNeeded SuiteObj not Ready');
    return true;
  }
};
