import { MainLayoutContainer } from 'containers';
import { BlueButton, TextButton } from 'custom-components/Button/Button';
import {
  PageHeader,
  PageHeaderBackButton,
  PageHeaderBackButtonTitleContainer,
  PageHeaderTitle,
  PageHeaderTitleContainer,
} from 'custom-components/PageHeader/PageHeader';
import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import routeList from 'routes';
import { CONFIG } from 'utils/config-util';
import { ToastConfig } from 'utils/toast-util';
import { CardModal } from './CardModal';
import './ChangePricingPlanPage.scss';
import { IncreasePlanMsg } from './IncreasePlanMsg';
import { ManageCommitters } from './ManageCommitters';
import { ManagePlan } from './ManagePlan';
import { StandardPlanTitleAndRedirection } from './StandartPlanTitleAndRedirection';

class ChangePricingPlanPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      stripe: null,
      committers: this.props.activeSubscription?.activeSubscription?.activeCommitterLimit,
    };
  }

  getOrganizationId = props => {
    const { userAccount } = props.userAccount;
    return userAccount.organization.id;
  };

  componentDidMount() {
    //Fetch
    this.props.getPlans();
    this.props.getActiveSubscription(this.getOrganizationId(this.props), () => {
      const { activeCommitterLimit } = this.props.activeSubscription.activeSubscription;
      this.setState({ committers: activeCommitterLimit });
    });

    // Load stripe.js
    const stripeScript = document.createElement('script');

    stripeScript.src = 'https://js.stripe.com/v3/';
    stripeScript.async = true;

    stripeScript.onload = () => {
      this.setState({
        stripe: window.Stripe(CONFIG.STRIPE_API_KEY),
      });
    };

    document.body && document.body.appendChild(stripeScript);
  }

  handleCardModalOpen = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleCardModalClose = () => {
    console.log('handle card modal close');
    this.setState({
      isModalOpen: false,
    });
  };

  handleChangeCommitters = event => {
    this.setState({ committers: event.target.value });
  };

  renderStripeCardForm = () => {
    const { stripe, isModalOpen } = this.state;
    const organizationId = this.getOrganizationId(this.props);

    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <CardModal
            stripe={stripe}
            open={isModalOpen}
            onUpsertCardSource={(stripeToken, onSuccess, onError) =>
              this.props.upsertCard(
                organizationId,
                stripeToken,
                () => {
                  this.props.getBillingCustomer(organizationId);
                  onSuccess();
                },
                onError,
              )
            }
            upsertCardResult={this.props.upsertCardResult}
            onClose={this.handleCardModalClose}
          />
        </Elements>
      </StripeProvider>
    );
  };

  refreshSubscriptionInfo = () => {
    this.props.getActiveSubscription(this.getOrganizationId(this.props));
  };

  refreshCardInfo = () => {
    this.props.getBillingCustomer(this.getOrganizationId(this.props));
  };

  handleCancel = () => {
    this.props.history.push(`${routeList.teamOrganization.path}/billing`);
  };

  handleConfirm = () => {
    const { committers } = this.state;
    const { billingCustomer } = this.props.billingCustomer;
    const { billingPlans } = this.props.billingPlans;

    const planId = billingPlans[0].id;
    const organizationId = this.getOrganizationId(this.props);
    const committerLimit = parseInt(committers);

    if (!billingCustomer.sourceExists) {
      toast.error('Please add a card to continue', ToastConfig);
      return;
    }

    this.props.saveSubscription(
      { organizationId: organizationId, planId: planId, committerLimit: committerLimit },
      () => {
        this.refreshSubscriptionInfo();
        this.refreshCardInfo();
        this.props.history.push(`${routeList.teamOrganization.path}/billing`);
        toast.success('You successfully subscribed.', ToastConfig);
      },
      error => {
        toast.error(error?.response?.data + ' Subscription failed. Contact us! ', ToastConfig);
      },
    );
  };

  calculateHowManyExtraPaidUser = (activeCommiterCount, activeCommitterLimit) => {
    return activeCommiterCount - activeCommitterLimit;
  };

  calculateIsUserNeedToPay = (activeCommiterCount, activeCommitterLimit) => {
    return this.calculateHowManyExtraPaidUser(activeCommiterCount, activeCommitterLimit) > 0;
  };

  renderChangePlan() {
    const { activeSubscription } = this.props.activeSubscription;
    let freeCommitterLimit = 20;
    let activeCommiterLimit = 0;
    if (activeSubscription) {
      freeCommitterLimit = activeSubscription.freeCommitterLimit;
      activeCommiterLimit = activeSubscription.activeCommitterLimit;
    }

    return (
      <div className="change-pricing-plan-page-container">
        <div className="change-pricing-plan-container">
          <PageHeader>
            <PageHeaderTitleContainer>
              <PageHeaderBackButtonTitleContainer>
                <PageHeaderBackButton
                  onClick={() => this.props.history.push(`${routeList.teamOrganization.path}/billing`)}
                />
                <PageHeaderTitle>Change Plan </PageHeaderTitle>
              </PageHeaderBackButtonTitleContainer>
            </PageHeaderTitleContainer>
          </PageHeader>

          <IncreasePlanMsg {...this.props} />
          <StandardPlanTitleAndRedirection {...this.props} />
          <div className="manage-part-container">
            <ManageCommitters {...this.props} />
            <ManagePlan
              handleChangePaymentMethod={this.handleCardModalOpen}
              handleChangeCommitters={this.handleChangeCommitters}
              committers={this.state.committers}
              freeCommitterLimit={freeCommitterLimit}
              {...this.props}
            />
          </div>
          <div className="actions-container">
            <TextButton onClick={this.handleCancel} className="cancel-button">
              Cancel
            </TextButton>
            <BlueButton
              disabled={this.state.committers === activeCommiterLimit}
              loading={this.props.saveSubscriptionResult?.fetching}
              onClick={this.handleConfirm}
              className="submit-button"
            >
              Change & Confirm purchase
            </BlueButton>
          </div>
          {this.renderStripeCardForm()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <MainLayoutContainer title={'Change Pricing Plan'} fullscreen={true}>
        {this.renderChangePlan()}
      </MainLayoutContainer>
    );
  }
}

export default ChangePricingPlanPage;
