import React, { Component, createRef } from 'react';
import { LoadingDimmer, NoDataAvailable, SomethingError } from 'components/ComponentPlaceholder';
import { MainLayoutContainer } from 'containers';
import ForesightBreadcrumb from 'custom-components/Breadcrumb/Breadcrumb';
import { FilterLabel } from 'custom-components/FilterLabel/FilterLabel';
import { PageHeader, PageHeaderTitle, PageHeaderTitleContainer } from 'custom-components/PageHeader/PageHeader';
import _ from 'lodash';
import routeList, { computePath } from 'routes';
import { Button, Icon } from 'semantic-ui-react';
import { hasArrayElement } from 'utils/array-util';
import { isDemoEnv, isPublicDomain } from 'utils/config-util';
import { HIGHLIGHTS_DATE_ENUM } from 'utils/highlights-enums';
import { HighlightFiltersSidePanel } from './HighlightFilters/HighlightFiltersSidePanel';
import Layout from './HighlightParts/Layout/Layout';
import './HighlightsPage.scss';
import ConnectRepositoryWidget from './HighlightWidgets/ConnectRepositoryWidget/ConnectRepositoryWidget';
import { CostSummary } from './HighlightWidgets/CostSummary/CostSummary';
import { AvgAndTotalDurationSummary } from './HighlightWidgets/DurationSummary/AvgAndTotalDurationSummary/AvgAndTotalDurationSummary';
import { OverallCountStatsOfWorklflows } from './HighlightWidgets/StatusSummary/StatusSummary';
import { TenCostlyWorkflows } from './HighlightWidgets/TenCostlyWorkflows/TenCostlyWorkflows';
import { TenHighestTotalDurationWorkflows } from './HighlightWidgets/TenHighestTotalDurationWorkflows/TenHighestTotalDurationWorkflows';
import { TenHightestAvgDurationWorkflows } from './HighlightWidgets/TenHightestAvgDurationWorkflows/TenHightestAvgDurationWorkflows';
import { TenMostActiveWorkflows } from './HighlightWidgets/TenMostActiveWorkflows/TenMostActiveWorkflows';
import { TenMostErroneousWorkflows } from './HighlightWidgets/TenMostErroneousWorkflows/TenMostErroneousWorkflows';
import { CountOf30DaysWorkflows } from './HighlightWidgets/WithoutTimeRange/CountOf30DaysWorkflows/CountOf30DaysWorkflows';
import { SummaryOfLatest10Workflows } from './HighlightWidgets/WithoutTimeRange/SummaryOfLatest10Workflows/SummaryOfLatest10Workflows';
import { FilterLabelGroup } from 'custom-components/FilterLabelGroup/FilterLabelGroup';

const getData = serverObj => {
  if (serverObj?.data?.data) {
    const obj = serverObj?.data?.data;
    if (Object.getPrototypeOf(obj) === Object.prototype) {
      return obj;
    } else if (typeof obj === 'number') {
      return obj;
    }
    return hasArrayElement(serverObj?.data?.data) ? serverObj.data.data : [];
  }
  return [];
};

const checkEmpty = data => {
  if (data) {
    if (typeof data?.data === 'object' && !hasArrayElement(data?.data)) {
      return Object.values(data?.data).every(item => item === 0);
    } else {
      return data?.data?.every(item => item.count === 0);
    }
  }
};

const HighlightWidgetWrapper = props => {
  const { emptyTitle, isCheckServerEmpty, serverObj } = props;
  const { data, fetched, fetching, error } = serverObj;

  if (data?.data?.length !== 0) {
    if (fetched) {
      const isCheckServerEmptyVal = isCheckServerEmpty ? checkEmpty(data) : false;
      return isCheckServerEmptyVal ? (
        <div className="workflow-card-container">
          <div className="title-of-no-data">{emptyTitle}</div>
          <NoDataAvailable msg={'No data available'} />
        </div>
      ) : (
        <div>{props.children}</div>
      );
    } else if (fetching) {
      return (
        <div className="workflow-card-container">
          <LoadingDimmer msg={'Loading...'} />
        </div>
      );
    } else if (error) {
      return (
        <div className="workflow-card-container">
          <SomethingError msg={error} />
        </div>
      );
    } else {
      return (
        <div className="workflow-card-container">
          <LoadingDimmer msg={'Unknown State'} />
        </div>
      );
    }
  } else {
    return (
      <div className="workflow-card-container">
        <div className="title-of-no-data">{emptyTitle}</div>
        <NoDataAvailable msg={'No data available'} />
      </div>
    );
  }
};

class HighlightsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeRange: HIGHLIGHTS_DATE_ENUM.ONE_MONTH,
      activeSection: 'STATUS',
      isFilterOpen: false,
      isClearFilterClicked: false,
      filterObj: {},
      readFilterObj: null,
    };

    this.layoutFirstRowRef = createRef();
    this.layoutSecondRowRef = createRef();
    this.layoutThirdRowRef = createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', _.debounce(this.handleScroll, 50), true);
    this.props.getRepos();
    this.props.getSummaryOfLatest10Workflows();
    this.props.getCountOf30DaysWorkflows();
    this.fetchDateFilterData();
  }

  handleScroll = event => {
    if (event.target.className === 'highlights-page-row-container show-scrollbar') {
      const scrollValue = event.target.scrollTop + 300;
      const firstSection = this.layoutFirstRowRef?.current?.offsetTop;
      const secondSection = this.layoutSecondRowRef?.current?.offsetTop;
      const thirdSection = this.layoutThirdRowRef?.current?.offsetTop;

      if (scrollValue < firstSection) {
        this.setState({
          activeSection: 'STATUS',
        });
      } else if (secondSection <= scrollValue && scrollValue < thirdSection) {
        this.setState({
          activeSection: 'COST',
        });
      } else if (thirdSection <= scrollValue) {
        this.setState({
          activeSection: 'DURATION',
        });
      } else {
        this.setState({
          activeSection: 'STATUS',
        });
      }
    }
  };

  fetchDateFilterData() {
    const { timeRange, filterObj } = this.state;
    this.props.getOverallCountStatsOfWorkflows(timeRange, filterObj);
    this.props.getCountOfMostErroneous10Workflows(timeRange, filterObj);
    this.props.getSuccessFailCountOfMostActive10Workflows(timeRange, filterObj);
    this.props.getCostOfMostCostly10Workflows(timeRange, filterObj);
    this.props.getAvgDurationOfLongest10Workflows(timeRange, filterObj);
    this.props.getSumDurationOfLongest10Workflows(timeRange, filterObj);
    this.props.getAverageAndTotalDurationWorkflows(timeRange, filterObj);
    this.props.getTotalCostSummary(timeRange, filterObj);
  }

  fetchDataWithFilterObj(isClear = false) {
    const { filterObj } = this.state;
    this.props.getSummaryOfLatest10Workflows(filterObj);
    this.props.getCountOf30DaysWorkflows(filterObj);
    this.fetchDateFilterData(isClear);
  }

  handleTimeRange = timeRange => {
    this.setState({ timeRange: timeRange }, () => {
      this.fetchDateFilterData();
    });
  };

  handleFilterChange = filterObj => {
    const branchObject = filterObj.branch.map(item => ({ branchName: item }));
    const checkProjectId = filterObj.project.id === 'all' ? '' : filterObj.project.id;
    const checkRepoId = filterObj.repository.id === 'all' ? null : { repoId: filterObj?.repository?.id };
    const newFilterObject = {
      projectId: checkProjectId || '',
      repos: checkRepoId ? [checkRepoId] : [],
      branches: branchObject || [],
    };
    this.setState({ filterObj: newFilterObject, readFilterObj: filterObj, isFilterOpen: false }, () => {
      this.fetchDataWithFilterObj();
    });
  };

  handleScrollToCategory = event => {
    const { layoutFirstRowRef, layoutSecondRowRef, layoutThirdRowRef } = this;
    const layoutFirstRow = layoutFirstRowRef.current;
    const layoutSecondRow = layoutSecondRowRef.current;
    const layoutThirdRow = layoutThirdRowRef.current;
    switch (event.target.id) {
      case 'status':
        layoutFirstRow.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'cost':
        layoutSecondRow.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'duration':
        layoutThirdRow.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        console.log('Clicked Error');
    }
  };

  handleWorkflowId = (event, obj) => {
    event.preventDefault();
    const splitData = obj.workflowName.split('/');
    const repoOwner = splitData[0];
    const repoName = splitData[1];

    this.props.history.push(
      computePath(routeList.singleWorkflowHighlights.path, {
        ciProvider: 'github',
        repoOwner: repoOwner,
        repoName: repoName,
        workflowId: obj.workflowId,
      }),
    );
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  getPublicDomainName = () => {
    const host = window.location.host;
    if (typeof host === 'string') {
      const domainName = window.location.host.split('.app.runforesight')[0];
      return domainName.capitalize();
    }
    return '';
  };

  renderBreadcrumb = () => {
    if (isDemoEnv() && isPublicDomain()) {
      return <ForesightBreadcrumb breadcrumbData={[`${this.getPublicDomainName()}`]} />;
    } else {
      const {
        userAccount: {
          organization: { organizationName },
        },
      } = this.props.userAccount;
      const data = [organizationName];
      return <ForesightBreadcrumb breadcrumbData={data} />;
    }
  };

  renderFilterButton = () => {
    const { isFilterOpen } = this.state;
    return (
      <Button
        primary
        className="highlights-filter-btn"
        onClick={() => {
          this.setState(prevState => {
            return { ...prevState, isFilterOpen: !isFilterOpen, isClearFilterClicked: false };
          });
        }}
      >
        <Icon name="icon-filter-icon" />
        Filter
      </Button>
    );
  };

  renderBranchTooltip = data => {
    const reduceData = data.map((item, index) => {
      const newArr = [];
      if (index < 11) {
        newArr.push(item);
      } else if (index === 11) {
        newArr.push('...more');
      }
      return newArr;
    });
    return reduceData.map(item => {
      return (
        <>
          <li className="branch-tooltip-list-item">{item}</li>
        </>
      );
    });
  };

  clearFilterHandle = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        filterObj: {},
        readFilterObj: null,
        isClearFilterClicked: true,
      };
    }, this.fetchDataWithFilterObj);
  };

  render() {
    const {
      summaryOfLatest10Workflows,
      countOf30DaysWorkflows,
      countOfMostErroneous10Workflows,
      overallCountStatsOfWorkflows,
      successFailCountOfMostActive10Workflows,
      totalCostSummary,
      costOfMostCostly10Workflows,
      avgDurationOfLongest10Workflows,
      averageAndTotalDurationSummaryWorkflows,
      sumDurationOfLongest10Workflows,
      repos: { repoList, isRepoListFetching },
      ciConnect,
      userAccount,
    } = this.props;

    const { timeRange: timeRange, activeSection, readFilterObj, isClearFilterClicked, isFilterOpen } = this.state;
    if (isRepoListFetching) {
      return <LoadingDimmer />;
    }
    if (repoList.length > 0) {
      return (
        <MainLayoutContainer title={routeList.highlights.title}>
          <HighlightFiltersSidePanel
            getHighlightFilterProjects={this.props.getHighlightFilterProjects}
            getHighlightFilterRepositories={this.props.getHighlightFilterRepositories}
            getHighlightFilterBranches={this.props.getHighlightFilterBranches}
            highlightsFilter={this.props.highlightsFilter}
            isOpen={isFilterOpen}
            applyFilter={this.handleFilterChange}
            isClearFilter={isClearFilterClicked}
            closeDetailModal={() => this.setState({ isFilterOpen: false })}
          />

          <div className="main-header-cont-hg">
            <div className="main-header-sub-wrapper">
              <PageHeader style={{ padding: '24px 0px 12px 0px' }}>
                <PageHeaderTitleContainer>
                  <PageHeaderTitle>Highlights</PageHeaderTitle>
                </PageHeaderTitleContainer>
              </PageHeader>
            </div>
            <div className="breadcrumb-menu-cont">
              {this.renderBreadcrumb()}
              <div className="highlights-filter-container">
                {readFilterObj && (
                  <FilterLabelGroup clearFilterHandle={() => this.clearFilterHandle()}>
                    {readFilterObj?.project && (
                      <FilterLabel
                        filterArray={[readFilterObj?.project.text] || ['All']}
                        labelTitle="Projects"
                        filterLabelClick={() => {
                          this.setState(prevState => {
                            return { ...prevState, isFilterOpen: !isFilterOpen, isClearFilterClicked: false };
                          });
                        }}
                      />
                    )}
                    {readFilterObj?.repository && (
                      <FilterLabel
                        filterArray={[readFilterObj?.repository.text] || ['All']}
                        labelTitle="Repository"
                        filterLabelClick={() => {
                          this.setState(prevState => {
                            return { ...prevState, isFilterOpen: !isFilterOpen, isClearFilterClicked: false };
                          });
                        }}
                      />
                    )}
                    {readFilterObj?.branch.length > 0 && (
                      <FilterLabel
                        filterArray={readFilterObj?.branch}
                        labelTitle="Branch"
                        filterLabelClick={() => {
                          this.setState(prevState => {
                            return { ...prevState, isFilterOpen: !isFilterOpen, isClearFilterClicked: false };
                          });
                        }}
                      />
                    )}
                  </FilterLabelGroup>
                )}
                {this.renderFilterButton()}
              </div>
            </div>
          </div>
          <div className="highlights-page-row-container">
            <div className="highlights-page-first-row">
              <div className="summaryOfLatest10Workflows">
                <HighlightWidgetWrapper serverObj={summaryOfLatest10Workflows} title="Latest 10 Workflow Runs">
                  <SummaryOfLatest10Workflows history={this.props.history} data={getData(summaryOfLatest10Workflows)} />
                </HighlightWidgetWrapper>
              </div>
              <div className="countOf30DaysWorkflows">
                <HighlightWidgetWrapper
                  isCheckServerEmpty
                  serverObj={countOf30DaysWorkflows}
                  title="# of Workflow 30 days"
                >
                  <CountOf30DaysWorkflows data={getData(countOf30DaysWorkflows)} />
                </HighlightWidgetWrapper>
              </div>
            </div>
            <div className="button-group-main-container">
              <div className="button-subgroup-container">
                <div className="button-scroll-groups-container">
                  <Button.Group>
                    <Button
                      onClick={this.handleScrollToCategory}
                      id="status"
                      className={`${activeSection === 'STATUS' ? 'active' : ''}`}
                    >
                      Status
                    </Button>
                    <Button
                      onClick={this.handleScrollToCategory}
                      id="cost"
                      className={`${activeSection === 'COST' ? 'active' : ''}`}
                    >
                      Cost
                    </Button>
                    <Button
                      onClick={this.handleScrollToCategory}
                      id="duration"
                      className={`${activeSection === 'DURATION' ? 'active' : ''}`}
                    >
                      Duration
                    </Button>
                  </Button.Group>
                </div>
                <div className="button-time-groups-container">
                  <Button.Group>
                    <Button
                      id="last24hours"
                      onClick={() => this.handleTimeRange(HIGHLIGHTS_DATE_ENUM.ONE_DAY)}
                      active={timeRange === HIGHLIGHTS_DATE_ENUM.ONE_DAY}
                    >
                      24 HOURS
                    </Button>
                    <Button
                      id="sevenDays"
                      onClick={() => this.handleTimeRange(HIGHLIGHTS_DATE_ENUM.ONE_WEEK)}
                      active={timeRange === HIGHLIGHTS_DATE_ENUM.ONE_WEEK}
                    >
                      7 DAYS
                    </Button>
                    <Button
                      id="last30Days"
                      onClick={() => this.handleTimeRange(HIGHLIGHTS_DATE_ENUM.ONE_MONTH)}
                      active={timeRange === HIGHLIGHTS_DATE_ENUM.ONE_MONTH}
                    >
                      30 DAYS
                    </Button>
                  </Button.Group>
                </div>
              </div>
            </div>
            <Layout
              layoutFirstRowRef={this.layoutFirstRowRef}
              layoutSecondRowRef={this.layoutSecondRowRef}
              layoutThirdRowRef={this.layoutThirdRowRef}
              withRef
              firstItem={
                <HighlightWidgetWrapper
                  isCheckServerEmpty
                  serverObj={overallCountStatsOfWorkflows}
                  emptyTitle="SUMMARY"
                >
                  <OverallCountStatsOfWorklflows
                    data={getData(overallCountStatsOfWorkflows)}
                    timeRange={timeRange}
                    title="SUMMARY"
                  />
                </HighlightWidgetWrapper>
              }
              secondItem={
                <HighlightWidgetWrapper
                  serverObj={countOfMostErroneous10Workflows}
                  emptyTitle={'10 MOST ERRONEOUS WORKFLOWS'}
                >
                  <TenMostErroneousWorkflows
                    data={getData(countOfMostErroneous10Workflows)}
                    title={'10 MOST ERRONEOUS WORKFLOWS'}
                    handlerId={(e, val) => this.handleWorkflowId(e, val)}
                    timeRange={timeRange}
                  />
                </HighlightWidgetWrapper>
              }
              thirdItem={
                <HighlightWidgetWrapper
                  serverObj={successFailCountOfMostActive10Workflows}
                  emptyTitle={'10 MOST ACTIVE WORKFLOWS'}
                >
                  <TenMostActiveWorkflows
                    successFailCountOfMostActive10Workflows={getData(successFailCountOfMostActive10Workflows)}
                    handlerId={(e, val) => this.handleWorkflowId(e, val)}
                    withList
                    title={'10 MOST ACTIVE WORKFLOWS'}
                  />
                </HighlightWidgetWrapper>
              }
              fourthItem={
                <>
                  <HighlightWidgetWrapper serverObj={totalCostSummary} emptyTitle={'SUMMARY'}>
                    <CostSummary totalCostSummary={getData(totalCostSummary)} title={'SUMMARY'} />
                  </HighlightWidgetWrapper>
                </>
              }
              fifthItem={
                <>
                  <HighlightWidgetWrapper
                    serverObj={costOfMostCostly10Workflows}
                    emptyTitle={'10 MOST COSTLY WORKFLOWS'}
                  >
                    <TenCostlyWorkflows
                      costOfMostCostly10Workflows={getData(costOfMostCostly10Workflows)}
                      handlerId={(e, val) => this.handleWorkflowId(e, val)}
                      title={'10 MOST COSTLY WORKFLOWS'}
                    />
                  </HighlightWidgetWrapper>
                </>
              }
              sixthItem={
                <HighlightWidgetWrapper serverObj={averageAndTotalDurationSummaryWorkflows} emptyTitle={'SUMMARY'}>
                  <AvgAndTotalDurationSummary
                    data={getData(averageAndTotalDurationSummaryWorkflows)}
                    title={'SUMMARY'}
                  />
                </HighlightWidgetWrapper>
              }
              seventhItem={
                <HighlightWidgetWrapper
                  serverObj={avgDurationOfLongest10Workflows}
                  emptyTitle={'TOP 10 HIGHEST AVG. WORKFLOW RUN DURATION'}
                >
                  <TenHightestAvgDurationWorkflows
                    avgDurationTimeSeriesWidget={getData(avgDurationOfLongest10Workflows)}
                    handlerId={(e, val) => this.handleWorkflowId(e, val)}
                    title={'TOP 10 HIGHEST AVG. WORKFLOW RUN DURATION'}
                  />
                </HighlightWidgetWrapper>
              }
              eigthItem={
                <HighlightWidgetWrapper
                  serverObj={sumDurationOfLongest10Workflows}
                  emptyTitle={'TOP 10 HIGHEST TOTAL WORKFLOW RUN DURATION'}
                >
                  <TenHighestTotalDurationWorkflows
                    sumDurationOfLongest10Workflows={getData(sumDurationOfLongest10Workflows)}
                    handlerId={(e, val) => this.handleWorkflowId(e, val)}
                    title={'TOP 10 HIGHEST TOTAL WORKFLOW RUN DURATION'}
                  />
                </HighlightWidgetWrapper>
              }
            />
          </div>
        </MainLayoutContainer>
      );
    } else {
      return (
        <MainLayoutContainer>
          <ConnectRepositoryWidget
            ciConnect={ciConnect}
            noProjectIsPresent={repoList.length === 0}
            history={this.props.history}
            userAccount={userAccount}
          />
        </MainLayoutContainer>
      );
    }
  }
}

export default HighlightsPage;
