import connectRepoButtonIcon from 'assets/images/connect-repo-button.svg';
import emptyState from 'assets/images/empty-state.svg';
import { BadgeModal, CustomModal, LoadingDimmer, MoreIconSvg, RepositoriesProjectCard } from 'components';
import { MainLayoutContainer } from 'containers';
import { CI_PROVIDER } from 'enums/git-provider';
import React, { Component } from 'react';
import routeList from 'routes';
import { Button, Image, Popup } from 'semantic-ui-react';
import { isSelectedConnectorIsConnectedAlready } from 'utils/ci-connect-util';
import { copyToClipBoard } from 'utils/common-util';
import { isDemoEnv } from 'utils/config-util.js';
import { getRedirectionForcePath, SESSION_URL_KEY } from 'utils/redirection-force-util';
import { isUserInInvitedBillingAdminRole, isUserInInvitedUserRole } from 'utils/user-role-util.js';
import './RepositoriesPage.scss';
import * as Sentry from '@sentry/react';

import { ALink } from 'custom-components/ALink/ALink';
import { BlueButton, TextButton } from 'custom-components/Button/Button';
import { ForesightInput } from 'custom-components/Input/Input';
import {
  PageHeader,
  PageHeaderTitle,
  PageHeaderTitleContainer,
  PageHeaderTitleContainerSection,
} from 'custom-components/PageHeader/PageHeader';
import {
  PageSubHeader,
  PageSubHeaderTitle,
  PageSubHeaderTitleContainer,
} from 'custom-components/PageSubHeader/PageSubHeader';
import { UI_ICON } from 'assets/font-icons/IconManager';

class RepositoriesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteProjectModalOpen: false,
      selectedForesightProjectIdForModal: undefined,
      selectedForesightRepositoryIdForModal: undefined,
      badgeModalType: undefined,
      isProjectKeysModalOpen: false,
      isChangeProjectNameModalOpen: false,
      projectPreferencesNewProjectName: '',
      isProjectPreferencesUpdateProjectNameFailed: false,
      isBadgeModalOpen: false,
      filteredProjectList: [],
      filterValue: '',
    };
    this.reposIntervalId = null;
  }

  componentDidMount() {
    const { repoList } = this.props.repos;

    //IsNewUser Redirection for labeling isNewUser=true
    if (localStorage.getItem('isNewUserTimer') && repoList.length > 0) {
      this.props.history.replace(routeList.repositories.path + '?isNewUser=true');
      setTimeout(() => {
        localStorage.removeItem('isNewUserTimer');
      }, 60000);
    }

    //URL Force Redirection
    const urlRedirection = getRedirectionForcePath();
    if (urlRedirection && repoList.length === 0) {
      console.log('History Push' + urlRedirection);
      localStorage.removeItem(SESSION_URL_KEY);
      this.props.history.push(urlRedirection);
    }

    //Fetch Repositories and CIProvider Integrations
    this.props.getRepos(
      this.onGetReposSuccess,
      () => {},
      err => {
        if (err?.response?.status === 401) {
          Sentry.captureMessage('Repo Fetching 401 RepositoriesPage componentDidMount');
        }
      },
    );
    this.props.getCIProviderIntegrations();
    this.setState({ filteredProjectList: repoList });

    //Subscription Interval RepoReload Without Fetching
    this.reposIntervalId = setInterval(
      () =>
        this.props.getReposWithoutReload(
          () => {
            // console.log('Repo fetched successfully');
          },
          err => {
            // console.log('Repo fetching problem', err);
            // console.log('Repo fetching problem status', err.response?.status);
            if (err?.response?.status === 401) {
              clearTimeout(this.reposIntervalId);
              Sentry.captureMessage('Repo Fetching 401 RepositoriesPage reposIntervalId');
            }
          },
        ),
      10000,
    );
  }

  getOrganizationId = props => {
    if (props.userAccount) {
      const { userAccount } = props.userAccount;
      return userAccount.organization?.id;
    }
    return null;
  };

  componentDidUpdate(prevProps) {
    const isNavigatedBilling = this.checkBillingAdminAndNavigate();
    if (isNavigatedBilling) return; //Force Direct to Navigate Billing

    //OrganizationId Change Then Refresh Repo Fetch
    if (this.getOrganizationId(prevProps) !== this.getOrganizationId(this.props)) {
      const { repoList } = this.props.repos;
      this.props.getRepos(this.onGetReposSuccess);
      this.props.getCIProviderIntegrations();
      this.setState({ filteredProjectList: repoList });
    }

    if (prevProps.repos.repoList !== this.props.repos.repoList) {
      this.onGetReposSuccess();
    }

    if (this.props.repos.isRepoListFetching !== prevProps.repos.isRepoListFetching) {
      this.setState({
        filterValue: '',
      });
    }
  }

  checkBillingAdminAndNavigate = () => {
    //If User Billing Admin navigates to Billing Page ...
    const { userAccount } = this.props.userAccount;
    const isCurrentBillingAdmin = isUserInInvitedBillingAdminRole(userAccount.role);
    if (isCurrentBillingAdmin) {
      console.log('RepositoriesPage: Billing Admin Navigated to Billing Page');
      this.props.history.replace(routeList.teamOrganization.path + '/billing');
      return true;
    }
    return false;
  };

  onGetReposSuccess = () => {
    const { filterValue } = this.state;
    this.filterRepositories(filterValue);
  };

  componentWillUnmount() {
    clearTimeout(this.reposIntervalId);
  }

  setProjectAndRoute = () => {
    this.props.setRepoSelectedProject(this.state.selectedForesightProjectIdForModal, this.onSetSelectedProjectSuccess);
  };

  renderRepoListArea = () => {
    const { filteredProjectList } = this.state;

    return filteredProjectList.length > 0
      ? this.renderRepoBoxes()
      : this.renderExplanationAndConnectRepoButton(this.setProjectAndRoute, true);
  };

  renderExplanationAndConnectRepoButton(genericRouteMethod, noProjectIsPresent) {
    let containerStyle = 'body-container';

    if (noProjectIsPresent) {
      containerStyle += ' empty-state';
    }
    return (
      !isDemoEnv() && (
        <div className={containerStyle}>
          {noProjectIsPresent && <Image className="empty-image" src={emptyState}></Image>}
          <span className="body-text-title">Connect a repository to get started</span>
          <div className="body-text-container">
            <span className="body-text">
              Start monitoring your workflows and your tests by <br />
              connecting a repository.
              <ALink href="https://docs.runforesight.com/"> Learn how to connect a repository.</ALink>
            </span>
          </div>
          {this.renderCreateAndConnectRepoButton(false, genericRouteMethod)}
        </div>
      )
    );
  }

  flushSelectedProjectAndRoute = () => {
    this.props.setRepoSelectedProjectFlush();
    if (isSelectedConnectorIsConnectedAlready(CI_PROVIDER.GITHUB, this.props.ciConnect)) {
      // startCreateProjectSelectGithubRepos page will handle the redirection
      // if the project has already created
      this.props.history.push(routeList.startCreateProjectSelectGithubRepos.path);
    } else {
      this.props.history.push(routeList.startInstallGitHubAppTrigger.path);
    }
  };

  renderCreateAndConnectRepoButton(renderIcon, genericRouteMethod) {
    const { userAccount } = this.props;

    if (isUserInInvitedUserRole(userAccount.userAccount.role)) {
      return <></>;
    }

    return (
      <BlueButton style={{ marginTop: 20 }} primary onClick={() => genericRouteMethod()}>
        {renderIcon && <Image className="icon" src={connectRepoButtonIcon} fluid />}
        Connect Pipeline
      </BlueButton>
    );
  }

  openProjectKeysModal = () => {
    this.setState({ isProjectKeysModalOpen: true });
  };

  renderProjectSettingsDropdown() {
    const { userAccount } = this.props;

    return (
      <div id="dropdown-id" className="project-settings-dropdown">
        {!isUserInInvitedUserRole(userAccount.userAccount.role) && (
          <>
            <div className="workflows title">
              <span>WORKFLOWS</span>
            </div>
            <div
              className="display-connect-repos item"
              onClick={() => {
                this.props.setRepoSelectedProject(
                  this.state.selectedForesightProjectIdForModal,
                  this.onSetSelectedProjectSuccess,
                );
              }}
            >
              Connect / Select repositories
            </div>
          </>
        )}
        <div className="test-monitoring title">
          <span>TEST MONITORING</span>
        </div>
        <Button
          className="display-test-integration item"
          onClick={() => {
            this.props.setRepoSelectedProject(
              this.state.selectedForesightProjectIdForModal,
              this.onTestSelectedSetSelectedProjectSuccess,
            );
          }}
        >
          Integrate your tests
        </Button>
        {!isUserInInvitedUserRole(userAccount.userAccount.role) && (
          <>
            <div onClick={this.onChangeProjectNameModalOpen} className="display-preferences item">
              Change your project name
            </div>
            <div className="display-delete-project item" onClick={this.openDeleteProjectModal}>
              Delete Project
            </div>
          </>
        )}
      </div>
    );
  }

  onTestSelectedSetSelectedProjectSuccess = () => {
    this.props.history.push(routeList.startIntegrationGitHubTestRuns.path);
  };

  renderRepoBoxes = () => {
    const { userAccount } = this.props;
    const { filteredProjectList } = this.state;

    return filteredProjectList.map(foresightProject => {
      if (!foresightProject.dashboardRepos && isDemoEnv()) {
        return <></>;
      }

      return (
        <div key={foresightProject.key} className="project">
          <div className="project-body">
            <PageSubHeader style={{ padding: '0px 0px 24px 0px' }}>
              <PageSubHeaderTitleContainer style={{ justifyContent: 'flex-start' }}>
                <PageSubHeaderTitle>{foresightProject.projectName}</PageSubHeaderTitle>
                {!isDemoEnv() && (
                  <Popup
                    id="project-settings-dropdown-parent"
                    trigger={
                      <div
                        onClick={() => this.setSelectedForesightProjectIdForModal(foresightProject.id)}
                        className="settings-container-parent"
                      >
                        <MoreIconSvg />
                      </div>
                    }
                    basic
                    hideOnScroll={true}
                    on="click"
                    content={this.renderProjectSettingsDropdown()}
                    position="bottom left"
                  />
                )}
              </PageSubHeaderTitleContainer>
            </PageSubHeader>

            {foresightProject.dashboardRepos === null &&
              this.renderExplanationAndConnectRepoButton(
                () => this.props.setRepoSelectedProject(foresightProject.id, this.onSetSelectedProjectSuccess),
                false,
              )}
            <div className="repo-card-list-container">
              <ul className="repo-card-list">
                {foresightProject.dashboardRepos &&
                  foresightProject.dashboardRepos.map(dashboardRepo => {
                    if (dashboardRepo) {
                      return (
                        <RepositoriesProjectCard
                          key={dashboardRepo.watchedRepo.id}
                          dashboardRepo={dashboardRepo}
                          history={this.props.history}
                          unwatchRepo={this.props.unwatchRepo}
                          onBadgeModalOpen={this.onBadgeModalOpen}
                          foresightProjectId={foresightProject.id}
                          setRepoSelectedProject={this.props.setRepoSelectedProject}
                          onSetSelectedProjectSuccess={this.onSetSelectedProjectSuccess}
                          userRole={userAccount.userAccount.role}
                        />
                      );
                    }
                    return <></>;
                  })}
              </ul>
            </div>
          </div>
        </div>
      );
    });
  };

  onSetSelectedProjectSuccess = () => {
    if (isSelectedConnectorIsConnectedAlready(CI_PROVIDER.GITHUB, this.props.ciConnect)) {
      // startCreateProjectSelectGithubRepos page will handle the redirection
      // if the project has already created
      this.props.history.push(routeList.startCreateProjectSelectGithubRepos.path);
    } else {
      this.props.history.push(routeList.startInstallGitHubAppTrigger.path);
    }
  };

  onDeleteProject = () => {
    const { selectedForesightProjectIdForModal } = this.state;

    this.props.deleteProject(selectedForesightProjectIdForModal, this.onDeleteProjectSuccess);
  };

  onDeleteProjectSuccess = () => {
    this.onDeleteProjectModalClose();
    this.props.getRepos(this.onGetReposSuccess);
  };

  renderDeleteProjectModal = () => {
    const { isDeleteProjectModalOpen } = this.state;
    const { projects } = this.props;

    return (
      <CustomModal
        id={'project-deletion'}
        size="small"
        isOpen={isDeleteProjectModalOpen}
        onModalClose={this.onDeleteProjectModalClose}
      >
        <div className="title-container">
          <i className={UI_ICON.ALERT.WARNING + ' warning-icon'} />
          <div className="title">Delete project</div>
        </div>
        <div className="description">By deleting the project:</div>
        <ul className="description-list">
          <li>You will stop watching the repositories.</li>
          <li>If you have configured your tests or CI pipeline, you need to remove the configurations manually.</li>
        </ul>
        <div className="description">Do you want to proceed?</div>
        <div className="delete-actions">
          <Button
            disabled={projects.projectDeletionFetching}
            loading={projects.projectDeletionFetching}
            onClick={this.onDeleteProjectModalClose}
            className="cancel-button"
          >
            Cancel
          </Button>
          <Button
            disabled={projects.projectDeletionFetching}
            loading={projects.projectDeletionFetching}
            onClick={this.onDeleteProject}
            primary
          >
            Delete
          </Button>
        </div>
      </CustomModal>
    );
  };

  onBadgeModalDropdownChange = (e, data) => {
    let selectedBadge = data.options.find(item => item.value === data.value);
    this.setState({
      badgeModalType: selectedBadge,
    });
  };

  renderBadgeModal = () => {
    const { isBadgeModalOpen, badgeModalType, selectedForesightRepositoryIdForModal } = this.state;

    return (
      <BadgeModal
        repositoryId={selectedForesightRepositoryIdForModal}
        open={isBadgeModalOpen}
        onModalClose={this.onBadgeModalClose}
        badgeType={badgeModalType}
        onBadgeModalDropdownChange={this.onBadgeModalDropdownChange}
      />
    );
  };

  setSelectedForesightProjectIdForModal = foresightProjectId => {
    this.setState({
      selectedForesightProjectIdForModal: foresightProjectId,
    });
  };

  openDeleteProjectModal = () => {
    this.setState({
      isDeleteProjectModalOpen: true,
    });
  };

  onDeleteProjectModalClose = () => {
    this.setState({
      isDeleteProjectModalOpen: false,
    });
  };

  onBadgeModalClose = () => {
    this.setState({
      isBadgeModalOpen: false,
      selectedForesightRepositoryIdForModal: undefined,
      badgeModalType: undefined,
    });
  };

  onBadgeModalOpen = (id, badgeType) => {
    this.setState({
      isBadgeModalOpen: true,
      selectedForesightRepositoryIdForModal: id,
      badgeModalType: badgeType,
    });
  };

  onProjectKeysModalClose = () => {
    this.setState({
      isProjectKeysModalOpen: false,
    });
  };

  onChangeProjectNameModalClose = () => {
    this.setState({
      isChangeProjectNameModalOpen: false,
      projectPreferencesNewProjectName: '',
      isProjectPreferencesUpdateProjectNameFailed: false,
    });
  };

  onChangeProjectNameModalOpen = () => {
    const { selectedForesightProjectIdForModal } = this.state;
    const project = this.props.repos.repoList.find(item => item.id === selectedForesightProjectIdForModal);
    const projectName = project ? project.projectName : '';

    this.setState({
      isChangeProjectNameModalOpen: true,
      projectPreferencesNewProjectName: projectName,
    });
  };

  onProjectPreferencesNewProjectNameChange = e => {
    this.setState({
      projectPreferencesNewProjectName: e.target.value,
      isProjectPreferencesUpdateProjectNameFailed: false,
    });
  };

  setProjectName = () => {
    const { selectedForesightProjectIdForModal } = this.state;

    this.props.setProject(
      this.state.projectPreferencesNewProjectName,
      selectedForesightProjectIdForModal,
      this.onSetProjectSuccess,
      this.onSetProjectFailed,
    );
  };

  onSetProjectSuccess = () => {
    this.onChangeProjectNameModalClose();
    this.props.getRepos(this.onGetReposSuccess);
  };

  onSetProjectFailed = () => {
    this.setState({
      isProjectPreferencesUpdateProjectNameFailed: true,
    });
  };

  renderChangeProjectNameModal = () => {
    const {
      isChangeProjectNameModalOpen,
      projectPreferencesNewProjectName,
      isProjectPreferencesUpdateProjectNameFailed,
    } = this.state;

    return (
      <CustomModal
        id="project-preferences"
        isOpen={isChangeProjectNameModalOpen}
        onModalClose={this.onChangeProjectNameModalClose}
        size="small"
      >
        <div className="title">
          <span>Change your project name</span>
        </div>

        <ForesightInput
          autoFocus
          labelText="Project Name"
          value={projectPreferencesNewProjectName}
          onChange={this.onProjectPreferencesNewProjectNameChange}
          hasError={isProjectPreferencesUpdateProjectNameFailed}
        />
        <span className={`error-message ${isProjectPreferencesUpdateProjectNameFailed ? 'visible' : 'hidden'}`}>
          <i className={UI_ICON.ALERT.ERROR} />
          The project with this name has already been created.
        </span>
        <div className="action-buttons">
          <TextButton
            style={{ height: 34 }}
            className="cancel-button"
            onClick={this.onChangeProjectNameModalClose}
            content="Cancel"
            loading={this.props.projects.fetching}
          />
          <BlueButton
            primary
            className="create-project-button large-button"
            content="Save"
            disabled={projectPreferencesNewProjectName.length === 0}
            loading={this.props.projects.fetching}
            onClick={this.setProjectName}
          />
        </div>
      </CustomModal>
    );
  };

  renderProjectKeysModal = () => {
    const { isProjectKeysModalOpen } = this.state;

    return (
      <CustomModal
        id={'project-keys-modal'}
        isOpen={isProjectKeysModalOpen}
        onModalClose={this.onProjectKeysModalClose}
        size="small"
      >
        <div className="title">
          <span>Get your API Key and Project ID</span>
        </div>
        <div className="subtitle">
          <span>Your API Key</span>
        </div>
        <Popup
          trigger={
            <div
              className="auth-token-section"
              onClick={() => {
                copyToClipBoard(this.props.userAccount?.userAccount?.apiKey);
              }}
            >
              {this.props.userAccount?.userAccount?.apiKey}
              <i className={UI_ICON.COPY_CODE + ' copy'} />
            </div>
          }
          id="copy-popup-id"
          basic
          content={'Copied'}
          position="top right"
          on="click"
        />
        <div className="subtitle">
          <span>Project ID</span>
        </div>
        <Popup
          trigger={
            <div
              className="auth-token-section"
              onClick={() => {
                copyToClipBoard(this.state.selectedForesightProjectIdForModal);
              }}
            >
              {this.state.selectedForesightProjectIdForModal}
              <i className={UI_ICON.COPY_CODE + ' copy'} />
            </div>
          }
          id="copy-popup-id"
          basic
          content={'Copied'}
          position="top right"
          on="click"
        />

        <div className="ok-button">
          <Button primary onClick={this.onProjectKeysModalClose} className="ok-button">
            OK
          </Button>
        </div>
      </CustomModal>
    );
  };

  updateRepositoryCards = () => {
    this.props.updateRepoList();
  };

  onRepoSearchInputChanged = e => {
    const { repoList } = this.props.repos;

    this.setState({
      filterValue: e.target.value,
    });

    if (e.target.value === '') {
      this.setState({ filteredProjectList: repoList, filterValue: '' });
      return;
    }

    this.filterRepositories(e.target.value);
  };

  filterRepositories = value => {
    const { repoList } = this.props.repos;

    const filteredProjectList = [];
    let filteredRepoList = [];

    if (value === '') {
      this.setState({ filteredProjectList: repoList, filterValue: '' });
      return;
    }

    repoList.forEach(rl => {
      const { dashboardRepos } = rl;

      if (dashboardRepos) {
        filteredRepoList = dashboardRepos.filter(repo =>
          repo.watchedRepo.repo.fullName.toLowerCase().includes(value.toLowerCase()),
        );

        if (filteredRepoList.length > 0) {
          filteredProjectList.push({
            ...rl,
            dashboardRepos: filteredRepoList,
          });
        }
      }
    });

    this.setState({ filteredProjectList });
  };

  render() {
    const { isRepoListFetching, hasNewUpdates } = this.props.repos;
    const { isBadgeModalOpen } = this.state;
    if (isRepoListFetching) {
      return <LoadingDimmer />;
    }
    return (
      <MainLayoutContainer title={routeList.repositories.title}>
        {hasNewUpdates && (
          <Button onClick={this.updateRepositoryCards} className="new-updates-bubble">
            <i className={UI_ICON.REFRESH + ' refresh-icon'} />
            New updates
          </Button>
        )}
        <div className="repositories-page">
          <div className="repositories-container">
            <PageHeader>
              <PageHeaderTitleContainer>
                <PageHeaderTitleContainerSection>
                  <PageHeaderTitle> Repositories </PageHeaderTitle>
                </PageHeaderTitleContainerSection>

                <PageHeaderTitleContainerSection>
                  <ForesightInput
                    onChange={this.onRepoSearchInputChanged}
                    placeholder="Search repositories"
                    style={{ height: 32, width: 320 }}
                  />
                </PageHeaderTitleContainerSection>
              </PageHeaderTitleContainer>
            </PageHeader>

            <div className="repositories-body">{this.renderRepoListArea()}</div>
          </div>
        </div>
        {this.renderDeleteProjectModal()}
        {this.renderProjectKeysModal()}
        {this.renderChangeProjectNameModal()}
        {isBadgeModalOpen && this.renderBadgeModal()}
      </MainLayoutContainer>
    );
  }
}

export default RepositoriesPage;
