import { Tooltip } from 'components/Tooltip';
import { capitalize } from 'lodash';
import React, { Component } from 'react';
import routeList, { computePath } from 'routes';
import { Icon } from 'semantic-ui-react';
import { formatDateFromNow } from 'utils/date-util';
import { getWorkflowStatusIcon } from 'utils/workflow-status-icon';
import { getWorkflowStatusTooltipContent } from 'utils/workflowrun-conclusion-status-tooltips';
import { WORKFLOWRUN_STATUS } from 'utils/workflowrun-status';
import './SummaryOfLatest10Workflows.scss';

const infoText =
  'You can go to your latest runs among your watched repositories. This is the easiest way to reach your failed or unusual run';

const DISABLE_MAP = ['ACTION_REQUIRED', 'STALE', 'NEUTRAL', 'UNKNOWN', 'SKIPPED', 'TIMED_OUT', 'STALE'];

export class SummaryOfLatest10Workflows extends Component {
  handleClick = (e, workflowRunId, repo, conclusion) => {
    e.preventDefault();
    if (DISABLE_MAP.includes(conclusion)) {
      return;
    } else {
      this.props.history.push(
        computePath(routeList.workflowRunTimeline.path, {
          workflowId: workflowRunId,
          ciProvider: repo.provider.toLowerCase(),
          repoOwner: repo.owner,
          repoName: repo.name,
        }),
      );
    }
  };

  statusCalculate = el => {
    switch (el.conclusion) {
      case 'SUCCESS':
        return getWorkflowStatusIcon(el, '#ffffff');

      case 'FAILURE':
        return getWorkflowStatusIcon(el, '#ffffff');

      default:
        return getWorkflowStatusIcon(el, '#D29922');
    }
  };

  render() {
    const { data } = this.props;
    const vDOM = data.map((el, index) => {
      let concatWorkflowName = el?.repo?.fullName + '/' + el?.workflow?.name;
      let className = 'summary-card';
      if (el.conclusion === 'FAILURE') className += ' errored';
      if (el.conclusion === 'SUCCESS') className += ' successful';
      el.status = WORKFLOWRUN_STATUS.COMPLETED;
      return (
        <div
          className={`${className} ${DISABLE_MAP.includes(el.conclusion) ? 'disabled' : ''}`}
          key={concatWorkflowName + index}
          onClick={e => this.handleClick(e, el.id, el.repo, el.conclusion)}
        >
          <div className="workflow-status">
            <div>
              <Tooltip blackEdition content={getWorkflowStatusTooltipContent(el)}>
                <div className="status">{this.statusCalculate(el)}</div>
              </Tooltip>
            </div>
            <div className="status-title">{capitalize(el.conclusion)}</div>
          </div>
          <div className="workflow-run-time">{formatDateFromNow(el.createdAt)}</div>
          <Tooltip blackEdition content={concatWorkflowName}>
            <div className="workflow-name">{concatWorkflowName}</div>
          </Tooltip>
        </div>
      );
    });

    return (
      <div className="workflow-latest-20-summary-widget">
        <div className="header">
          <div className="title">
            Latest 10 Workflow Runs&nbsp;
            <Tooltip blackEdition content={infoText}>
              <Icon style={{ height: '20px' }} name="info circle" />
            </Tooltip>
          </div>
        </div>
        <div className="workflow-summary-cards-container">{vDOM}</div>
      </div>
    );
  }
}
