export const CI_PARSER_FRAMEWORK = {
	JEST: 'JEST',
	JUNIT: 'JUNIT',
	PYTHON: 'PYTHON',
	TESTNG: 'TESTNG',
	TRX: 'TRX',
	XUNIT2: 'XUNIT2',
};

export const CI_PARSER_FRAMEWORK_YML_NAME = {
	JEST: 'test-result-jest',
	JUNIT: 'test-result-junit',
	PYTHON: 'test-result-python',
	TRX: 'test-result-trx',
	TESTNG: 'test-result-testng',
	XUNIT2: 'test-result-xunit2',
};

export const CI_PARSER_FRAMEWORK_HUMAN_READABLE = {
	JEST: 'Jest',
	JUNIT: 'JUnit',
	PYTHON: 'Python',
	TRX: 'Trx',
	TESTNG: 'TestNG',
	XUNIT2: 'xUnit 2',
};
