import React from 'react';
import { Table } from 'semantic-ui-react';
import './Table.scss';

export const ForesightTable = ({ children, ...props }) => {
  return (
    <Table className="foresight-table" {...props}>
      {children}
    </Table>
  );
};
