import React, { Component } from 'react';
import { getTestRunSuitesObj, clipTestSuiteName, getTestRunClientFilterObj } from 'utils/test-run-detail-util';
import { hasArrayElement } from 'utils/array-util';
import routeList, { computePath } from 'routes';
import { TestCaseNameFilter } from './filters/TestCaseNameFilter';
import { TestCaseStatusFilter } from './filters/TestCaseStatusFilter';

//TODO After FaCircle Icon remove below
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import { LoadingDimmer, NoDataAvailable, SomethingError } from 'components';
import './TestRunSuiteCardList.scss';
import { calcDisplayedTime, timeDiffAsMillis } from 'utils/date-util';
import { Link } from 'react-router-dom';
import { getTestCaseStatusIcon } from 'utils/testcase-status-icon';
import { DOMAIN_ICON, UI_ICON } from 'assets/font-icons/IconManager';

class TestRunSuiteCardList extends Component {
  constructor(props) {
    super(props);
    this.cache = {};
    this.state = {
      nameFilter: '',
      statusFilter: 'All',
    };
  }

  focusToSelectedElement = () => {
    if (window.location.hash.includes('#auto-focus')) {
      const { testSuiteName, transactionId } = this.props.match.params;
      if (testSuiteName) {
        const id = `suiteCardElement-${testSuiteName}`;
        const element = document.getElementById(id);
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      } else if (transactionId) {
        const id = `suiteCardElement-${transactionId}`;
        const element = document.getElementById(id);
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  };

  updateStateFiltersAccording2Props = props => {
    const { testRunId } = props.match.params;
    const { testRunDetailMap } = props.testRunDetails;
    const clientFilterObj = getTestRunClientFilterObj(testRunDetailMap, testRunId);

    if (clientFilterObj && clientFilterObj.clientFilters) {
      const { nameFilter, statusFilter } = this.state;
      const { nameFilter: pNameFilter, statusFilter: pStatusFilter } = clientFilterObj.clientFilters;
      if (nameFilter !== pNameFilter || statusFilter !== pStatusFilter) {
        this.setState({ nameFilter: pNameFilter, statusFilter: pStatusFilter });
      }
    }
  };

  componentDidMount() {
    this.focusToSelectedElement();
    this.updateStateFiltersAccording2Props(this.props);
    // this.worker = new WebWorker(worker);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateStateFiltersAccording2Props(nextProps);
    const { testRunId: cTestRunId } = this.props.match.params;
    const { testRunId: nTestRunId } = nextProps.match.params;
    if (cTestRunId !== nTestRunId) {
      this.cache = {}; //Clear Cache
    }
  }

  componentDidUpdate() {
    this.focusToSelectedElement();
  }

  getTestSuiteLink = suiteName => {
    const { testRunId, ciProvider, repoOwner, repoName } = this.props.match.params;
    const testRunSuitePath = computePath(routeList.testRunTestSuite.path, {
      testRunId: testRunId,
      testSuiteName: suiteName,
      ciProvider: ciProvider,
      repoOwner: repoOwner,
      repoName: repoName,
    });

    const { path } = this.props.match;
    if (path === routeList.testRunTestSuite.path) {
      return testRunSuitePath;
    } else {
      //If Not in SuitePath SuiteCard focus to first item... for focusing item add #auto-focus
      return testRunSuitePath + '#auto-focus';
    }
  };

  getTestCaseLink = (transactionId, status) => {
    const { testRunId, ciProvider, repoOwner, repoName } = this.props.match.params;

    const testErrorOrPerfPath = status === 'FAILED' ? routeList.testRunTestErrors : routeList.testRunTestPerformance;
    const testComputedPath = computePath(testErrorOrPerfPath.path, {
      testRunId: testRunId,
      transactionId: transactionId,
      ciProvider: ciProvider,
      repoOwner: repoOwner,
      repoName: repoName,
    });

    const { path } = this.props.match;
    if (path === routeList.testRunTestPerformance.path || path === routeList.testRunTestErrors.path) {
      return testComputedPath;
    } else {
      //If Not in TestPerformancePath or Error focus to first item... for focusing item add #auto-focus
      return testComputedPath + '#auto-focus';
    }
  };

  renderTestCase = testcase => {
    const { testName, status, transactionId, startTime, endTime } = testcase;

    let iDOM = getTestCaseStatusIcon(status);
    const { transactionId: selectedTransactionId } = this.props.match.params;
    const selectedClassName =
      selectedTransactionId && selectedTransactionId === transactionId ? 'selected' : 'unselected';

    const id = `suiteCardElement-${transactionId}`;

    const duration = timeDiffAsMillis(endTime, startTime);
    const displayTime = calcDisplayedTime(duration);
    return (
      <Link className="test-suite-card-list-container-wrapper" to={this.getTestCaseLink(transactionId, status)}>
        <div className={`test ${selectedClassName}`} id={id} key={id}>
          <div className="left-part">
            <span className="test-status">{iDOM}</span>
            <span className="test-name">{testName}</span>
          </div>
          <div className="right-part">
            <span className="test-duration">{displayTime}</span>
          </div>
        </div>
      </Link>
    );
  };

  renderTestSuites = suite => {
    const { tags, cases, duration } = suite;
    const suiteName = tags['test.suite'];
    //const totalCount = tags['test.suite.total.count'];
    const failedCount = tags['test.suite.failed.count'];
    const skippedCount = tags['test.suite.skipped.count'];
    const abortedCount = tags['test.suite.aborted.count'];
    const successfulCount = tags['test.suite.successful.count'];

    let casesVDOM = '';
    if (hasArrayElement(cases)) {
      casesVDOM = cases.map(testCase => {
        return <>{this.renderTestCase(testCase)}</>;
      });
    }

    const { testSuiteName } = this.props.match.params;
    const selectedClassName = testSuiteName && testSuiteName === suiteName ? 'selected' : 'unselected';
    const id = `suiteCardElement-${suiteName}`;

    const failedStatus = failedCount > 0;
    const successfulStatus = successfulCount > 0;
    const abortedStatus = abortedCount > 0;
    const skippedStatus = skippedCount > 0;

    const displayTime = calcDisplayedTime(duration);
    return (
      <Link className="test-suite-card-list-container-wrapper" to={this.getTestSuiteLink(suiteName)}>
        <div className={`suite-container ${selectedClassName}`} id={id} key={id}>
          <div className="suite-head">
            <div className="left-part">
              <div className="title">{clipTestSuiteName(suiteName)}</div>
              <div className="stats">
                {failedStatus && (
                  <>
                    <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.FAILED + ' failed'} />
                    <span>{failedCount}</span>
                  </>
                )}
                {successfulStatus && (
                  <>
                    <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.PASSED + ' successfull'} />
                    <span>{successfulCount}</span>
                  </>
                )}
                {abortedStatus && (
                  <>
                    <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.ABORTED + ' aborted'} />
                    <span>{abortedCount}</span>
                  </>
                )}
                {skippedStatus && (
                  <>
                    <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.SKIPPED + ' skipped'} />
                    <span>{skippedCount}</span>
                  </>
                )}
                <div className="split-point">
                  <FontAwesomeIcon icon={faCircle} />
                </div>
                <i className="icon-elapsed-time" />
                <span>{displayTime}</span>
              </div>
            </div>
            <div className="right-part">
              <i className={UI_ICON.CHEVRON.RIGHT} />
            </div>
          </div>
          <div>{casesVDOM}</div>
        </div>
      </Link>
    );
  };

  handleStatusFilterChange = (e, data) => {
    this.setState({ statusFilter: data.value }, () => {
      const { testRunId } = this.props.match.params;
      this.props.setTestRunClientFilter(testRunId, { ...this.state });
    });
  };

  handleNameFilterChange = txtSearch => {
    this.setState({ nameFilter: txtSearch }, () => {
      const { testRunId } = this.props.match.params;
      this.props.setTestRunClientFilter(testRunId, { ...this.state });
    });
  };

  filterByStatusFilter = (testSuites, statusFilter) => {
    const filteredTestSuites = [];
    testSuites.forEach(el => {
      const { tags } = el;
      const cases = el.cases || [];

      const lowerStatusFilter = statusFilter.toLowerCase();
      const upperStatusFilter = statusFilter.toUpperCase();

      //Sample Tags..
      //test.run.id:"d93400a9-d21f-39e9-a8d9-930ed51df401"
      //test.suite.failed.count:"0"
      //test.suite.skipped.count:"0"
      //test.suite:"com.hazelcast.client.listeners.leak.BackupListenerLeakTest"
      //test.suite.total.count:"0"
      //test.suite.aborted.count:"0"
      //test.suite.successful.count:"0"

      const sCount = tags[`test.suite.${lowerStatusFilter}.count`];
      if (parseInt(sCount) > 0) {
        const filteredCases = cases.filter(caseEl => caseEl.status === upperStatusFilter);
        filteredTestSuites.push({ ...el, cases: filteredCases });
      }
    });
    return filteredTestSuites;
  };

  filterByNameFilter = (testSuites, nameFilter) => {
    const filteredTestSuites = [];
    testSuites.forEach(el => {
      const { tags } = el;
      const cases = el.cases || [];

      const lowerNameFilter = nameFilter.toLowerCase();

      //Sample Tags..
      //test.suite:"com.hazelcast.client.listeners.leak.BackupListenerLeakTest"

      //Cases Test
      // testName:"testBackupListenerIsNotRemoved_afterClientRestart"

      const suitFlag = tags['test.suite'].toLowerCase().includes(lowerNameFilter);
      const filteredCases = suitFlag
        ? cases
        : cases.filter(caseEl => {
            if (caseEl && caseEl.testName) {
              return caseEl.testName.toLowerCase().includes(lowerNameFilter);
            }
            return false;
          });
      if (filteredCases.length > 0 || suitFlag) {
        filteredTestSuites.push({ ...el, cases: filteredCases });
      }
    });
    return filteredTestSuites;
  };

  filterTestSuites = (testRunId, testSuites, filters) => {
    //4 Cases
    //No Filter
    //Name And Status Filter
    //Status Filter Only
    //Name Filter Only

    const { statusFilter, nameFilter } = filters;
    if (statusFilter && nameFilter) {
      //Two Filter
      const filteredSuits = this.filterByStatusFilter(testSuites, statusFilter);
      return this.filterByNameFilter(filteredSuits, nameFilter);
    } else if (statusFilter) {
      return this.filterByStatusFilter(testSuites, statusFilter);
    } else if (nameFilter) {
      return this.filterByNameFilter(testSuites, nameFilter);
    } else {
      return testSuites;
    }
  };

  fetchWebWorker = (testRunId, testSuites, filters) => {
    if (this.worker) {
      const data = { testRunId, testSuites, filters };
      this.worker.postMessage(data);

      this.worker.addEventListener('message', event => {
        const { testRunId, testSuites, filters } = event.data;
        const { nameFilter, statusFilter } = filters;
        const uuid = `${testRunId}-${nameFilter}-${statusFilter}`;
        this.cache[uuid] = testSuites;
      });
    }
  };

  filterAndCacheTestSuites = (testSuites, useCaching, useWorker) => {
    const { nameFilter, statusFilter: statusFilterVal } = this.state;
    const statusFilter = statusFilterVal === 'All' ? '' : statusFilterVal;
    const { testRunId } = this.props.match.params;
    const uuid = `${testRunId}-${nameFilter}-${statusFilter}`;

    if (useCaching) {
      if (this.cache[uuid]) {
        return this.cache[uuid];
      } else if (useWorker) {
        this.fetchWebWorker(testRunId, testSuites, { nameFilter, statusFilter });
      } else {
        const result = this.filterTestSuites(testRunId, testSuites, { nameFilter, statusFilter });
        this.cache[uuid] = result;
        return this.cache[uuid];
      }
    } else {
      //Without Caching and Worker
      if (useWorker) {
        this.fetchWebWorker(testRunId, testSuites, { nameFilter, statusFilter });
      } else {
        return this.filterTestSuites(testRunId, testSuites, { nameFilter, statusFilter });
      }
    }
  };

  render() {
    const { testRunId } = this.props.match.params;
    const { testRunDetailMap } = this.props.testRunDetails;
    const testSuitesObj = getTestRunSuitesObj(testRunDetailMap, testRunId);

    const { fetching, error } = testSuitesObj;
    if (fetching) {
      return <LoadingDimmer />;
    }

    if (error) {
      return (
        <div>
          <SomethingError />
        </div>
      );
    }

    const testSuites = testSuitesObj.suites || [];

    let filteredTestSuites = this.filterAndCacheTestSuites(testSuites, false, false);

    let testSuitesVDOM = '';
    const { nameFilter, statusFilter } = this.state;
    if (hasArrayElement(filteredTestSuites)) {
      testSuitesVDOM = filteredTestSuites.map(suite => {
        return <>{this.renderTestSuites(suite)}</>;
      });

      return (
        <div className="test-suite-card-list-container">
          <div className="title">Tests</div>
          <div className="filter-container">
            <TestCaseStatusFilter handleChange={this.handleStatusFilterChange} filter={statusFilter} />
            <TestCaseNameFilter handleChange={this.handleNameFilterChange} filter={nameFilter} />
          </div>
          <div className="suites-container">{testSuitesVDOM}</div>
        </div>
      );
    } else {
      return (
        <div className="test-suite-card-list-container">
          <div className="title">Tests</div>
          <div className="filter-container">
            <TestCaseStatusFilter handleChange={this.handleStatusFilterChange} filter={statusFilter} />
            <TestCaseNameFilter handleChange={this.handleNameFilterChange} filter={nameFilter} />
          </div>
          <div className="no-suite-card-data-msg-container">
            <NoDataAvailable msg="No Test Found" />
          </div>
        </div>
      );
    }
  }
}

export default TestRunSuiteCardList;
