import React, { Component } from 'react';
import routeList, { computePath } from 'routes';
import { ForesightCircle } from 'custom-components/Circle/ForesightCircle';
import { ReviewersComponent } from 'custom-components/ReviewersComponent/ReviewersComponent';
import { isEqualDeep } from 'utils/common-util';
import './RepositoryPullRequestsList.scss';
import { formatDateFromNow, formatDateWithTime } from 'utils/date-util';
import { ListCardsBody, ListCardsCellContainer, ListCardsRowBody } from 'custom-components/ListCards/ListCards';
import { getPullRequestIcons, getPullRequestStatusEnumToText } from 'utils/pull-request-status-icon';
import { Tooltip } from 'components/Tooltip';
import { millisToMAndS } from 'utils/date-util';
import { getPullRequestMergableStateIcons } from 'utils/pull-request-mergable-icon';
import { Image } from 'semantic-ui-react';
import failedStatus from 'assets/images/failed-workflow-status.svg';
import { renderNoDataAvailableForPullRequest } from 'components/ComponentPlaceholder';
import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
import { hasArrayElement } from 'utils/array-util';

class RepositoryPullRequestListItem extends Component {
  statusWithIcon = (iconName, text, prefixText) => {
    return (
      <div className="pr-summary-status-icon">
        <i className={iconName} />
        <div className="pr-summary-icon-text">{prefixText + text}</div>
      </div>
    );
  };

  getLatestCiChecksResult = ciResult => {
    switch (ciResult) {
      case true:
        return (
          <Tooltip blackEdition content={<div>Checks completed</div>}>
            <i className={'ci-conclusion-icon-style ' + DOMAIN_ICON.GITHUB.CI_CHECK.PASSED} />
          </Tooltip>
        );
      case false:
        return (
          <Tooltip blackEdition content={<div>Checks completed</div>}>
            <i className={'ci-conclusion-icon-style ' + DOMAIN_ICON.GITHUB.CI_CHECK.FAILED} />
          </Tooltip>
        );
      default:
        break;
    }
  };

  render() {
    const { data } = this.props;
    const { ciProvider, repoOwner, repoName } = this.props.matchParams;
    let workflowEstimatedCost = data?.workflowEstimatedCost.toFixed(2) || 0;
    let workflowRunDuration = data?.workflowRunDuration;
    let fourItems = data.failedWorkflows && data.failedWorkflows[0] ? 'four-items' : '';
    return (
      data.summary && (
        <ListCardsRowBody
          className="pr-view-list-cards-row-body"
          onClick={() =>
            this.props.history.push(
              computePath(routeList.prPage.path, {
                prId: data.summary?.number,
                workflowId: data.id,
                ciProvider: ciProvider,
                repoOwner: repoOwner,
                repoName: repoName,
              }),
            )
          }
        >
          <ListCardsCellContainer className="cell-container status">
            <div className="sub-row flex-row">
              {getPullRequestIcons(data.summary, 'pr-view-icon')}
              {getPullRequestStatusEnumToText(data.summary)}
            </div>
            <Tooltip
              blackEdition
              position="bottom left"
              content={<div>Open since {formatDateWithTime(data.summary.createdAt)}</div>}
            >
              <div className="sub-row flex-row">
                {getPullRequestIcons({ state: 'date' }, 'pr-view-icon')}
                {formatDateFromNow(data.summary.createdAt)}
              </div>
            </Tooltip>
            <Tooltip blackEdition position="bottom left" content={<div>Pull request id</div>}>
              <div className="sub-row flex-row">
                <i className={DOMAIN_ICON.GITHUB.PULL_REQUEST + ' pr-view-icon'}> </i>
                {'#' + data.summary.number}
              </div>
            </Tooltip>
          </ListCardsCellContainer>

          <ListCardsCellContainer className={'cell-container metadata ' + fourItems}>
            <div className="metadata-cell">
              <div className="title">
                {data?.summary.title.length > 42 ? (
                  <Tooltip blackEdition content={<div>{data.summary.title}</div>}>
                    <div className="title-text">{data.summary.title}</div>
                  </Tooltip>
                ) : (
                  <div className="title-text">{data.summary.title}</div>
                )}

                <span className="mergable-state">{getPullRequestMergableStateIcons(data.summary?.mergeableState)}</span>
              </div>
              <div className="text author-and-reviewer">
                {data.summary.user?.login && (
                  <>
                    Opened by <span className="strong-text">{data.summary.user?.login} </span>
                  </>
                )}
                {data.summary.requestedReviewers.length > 0 && (
                  <>
                    <span className="section-dot"> </span>
                    Review required by{' '}
                  </>
                )}
                <ReviewersComponent requestedReviewers={data.summary.requestedReviewers}></ReviewersComponent>
              </div>
              <div className="text last-committer">
                {data.summary.lastCommitter?.login && (
                  <>
                    Last commit by <span className="strong-text">{data.summary.lastCommitter?.login} </span>
                    <Tooltip blackEdition content={<div>{formatDateWithTime(data.summary.pushedAt)}</div>}>
                      <span className="ellipsis">
                        <span className="ellipsis-text">{formatDateFromNow(data.summary.pushedAt)}</span>
                      </span>
                    </Tooltip>
                  </>
                )}
              </div>

              {data.failedWorkflows && data.failedWorkflows[0] && (
                <div className="text failed-workflows">
                  <span className="failed-workflows">
                    <span className="failed-workflows-icon">
                      <Image src={failedStatus} />
                    </span>
                    {data.failedWorkflows[0].name}
                    &nbsp;
                    {data.failedWorkflows.length > 1 && <span> and {data.failedWorkflows.length} other workflows</span>}
                  </span>
                </div>
              )}
            </div>
          </ListCardsCellContainer>
          <ListCardsCellContainer className="cell-container cost">
            <div className="pr-sum-status-item-header-cont">
              {this.statusWithIcon('icon-icons-summary-cost', workflowEstimatedCost, '$ ')}
            </div>
          </ListCardsCellContainer>
          <ListCardsCellContainer className="cell-container duration">
            <div className="pr-sum-status-item-header-cont">
              {this.statusWithIcon(
                'icon-icons-summary-duration',
                workflowRunDuration ? millisToMAndS(workflowRunDuration) : 'N / A',
                '',
              )}
            </div>
          </ListCardsCellContainer>
          <ListCardsCellContainer className="cell-container coverage">
            <div className="pr-sum-status-item-header-cont">
              <ForesightCircle
                subText={`${data.patchCoveredLineCount}/${data.patchCoveredLineCount + data.patchUncoveredLineCount}`}
                textPosition="right"
                size="small"
                data={[
                  {
                    name: 'Group A',
                    value: parseInt(data.coverageRate),
                    color: data.coverageRate < 50 ? '#FF9214' : '#2DCD8A',
                  },
                  {
                    name: 'Group B',
                    value: parseInt(100 - data.coverageRate),
                    color: '#2d353c',
                  },
                ]}
              />
            </div>
          </ListCardsCellContainer>
        </ListCardsRowBody>
      )
    );
  }
}

export default class RepositoryPullRequestsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 0,
      pageSize: 50,
      fetching: false,
    };
  }

  componentDidMount() {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    this.props.getPullRequests_Filters(ciProvider, repoOwner, repoName);

    document.getElementById('pull-requests-scrollable').addEventListener('scroll', this.trackScrolling);
    const { pageNo, pageSize } = this.state;
    this.fetchData(ciProvider, repoOwner, repoName, pageNo, pageSize, this.props.pullRequestsMeta);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pageSize } = this.state;
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    const { ciProvider: ciProviderNext, repoOwner: repoOwnerNext, repoName: repoNameNext } = nextProps.match.params;

    const {
      selectedPullRequests_Authors,
      selectedPullRequests_WorkflowRunBranches,
      selectedPullRequests_Labels,
      selectedPullRequests_States,
      selectedPullRequests_HideFork,
    } = this.props.pullRequestsMeta;

    const {
      selectedPullRequests_Authors: selectedPullRequests_AuthorsNext,
      fetched: pullRequestsMetaFetched,
      selectedPullRequests_WorkflowRunBranches: nextBranches,
      selectedPullRequests_Labels: nextLabels,
      selectedPullRequests_States: nextStates,
      selectedPullRequests_HideFork: nextHideFork,
    } = nextProps.pullRequestsMeta;

    const repoChangeFlag = ciProvider !== ciProviderNext || repoOwnerNext !== repoOwner || repoNameNext !== repoName;

    const propsChangeFlag =
      (pullRequestsMetaFetched &&
        (!isEqualDeep(selectedPullRequests_Authors, selectedPullRequests_AuthorsNext) ||
          !isEqualDeep(nextBranches, selectedPullRequests_WorkflowRunBranches) ||
          !isEqualDeep(selectedPullRequests_Labels, nextLabels) ||
          !isEqualDeep(selectedPullRequests_States, nextStates))) ||
      !isEqualDeep(selectedPullRequests_HideFork, nextHideFork);

    if (repoChangeFlag || propsChangeFlag) {
      this.setState({ pageNo: 0 }, () => {
        this.fetchData(ciProviderNext, repoOwnerNext, repoNameNext, 0, pageSize, nextProps.pullRequestsMeta);
      });
    }
  }

  fetchData = (ciProvider, repoOwner, repoName, pageNo, pageSize = 50, pullRequestsMeta) => {
    this.props.getPullRequests(
      ciProvider,
      repoOwner,
      repoName,
      pullRequestsMeta.selectedPullRequests_Authors,
      pullRequestsMeta.selectedPullRequests_WorkflowRunBranches,
      pullRequestsMeta.selectedPullRequests_Labels,
      pullRequestsMeta.selectedPullRequests_States,
      pullRequestsMeta.selectedPullRequests_HideFork,
      pageNo,
      pageSize,
      () => {
        this.setState({ fetching: false, pageNo: pageNo });
      },
      () => {
        this.setState({ fetching: false });
      },
    );
  };

  checkFetchingElements = (totalElements, pageNo, pageSize) => {
    const size = pageNo * pageSize;
    return size < totalElements;
  };

  fetchWhenScrollAccessToBottom = el => {
    const { pullRequests } = this.props.pullRequests;
    const { pageNo, pageSize } = this.state;
    if (!this.checkFetchingElements(pullRequests.totalElements, pageNo, pageSize)) {
      return;
    }
    if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
      const { fetching } = this.state;
      //console.log('Access Bottom: fetching status:', fetching);
      if (!fetching) {
        //console.log('Fetching ...');
        const { ciProvider, repoOwner, repoName } = this.props.match.params;
        this.setState({ fetching: true });
        const newPageNo = this.state.pageNo + 1;
        if (newPageNo != 0) {
          this.fetchData(ciProvider, repoOwner, repoName, newPageNo, pageSize, this.props.pullRequestsMeta);
        }
      }
    }
  };

  trackScrolling = () => {
    const wrappedElement = document.getElementById('pull-requests-scrollable');
    this.fetchWhenScrollAccessToBottom(wrappedElement);
  };

  getSelectedWorkflowCriteria = (selectedWorkflowItems, criteriaField) => {
    if (selectedWorkflowItems?.length) {
      return selectedWorkflowItems.includes(criteriaField);
    } else {
      return true;
    }
  };

  checkWorkflowIdsForFilters = (selectedWorkflowIds, workflowId) => {
    if (selectedWorkflowIds?.length) {
      return selectedWorkflowIds.includes(workflowId);
    } else {
      return true;
    }
  };

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  renderList = () => {
    const { pullRequests, fetched, fetching, error } = this.props.pullRequests;
    const matchParams = this.props.match.params;

    return (
      <ListCardsBody
        fetched={fetched}
        fetching={fetching}
        error={error}
        infiniteFetching={this.state.fetching}
        colSpan={5}
        tableContent={pullRequests.content}
        emptyContent={renderNoDataAvailableForPullRequest()}
      >
        {hasArrayElement(pullRequests.content) &&
          pullRequests.content.map((el, index) => (
            <RepositoryPullRequestListItem
              matchParams={matchParams}
              key={index}
              data={el}
              history={this.props.history}
            />
          ))}
      </ListCardsBody>
    );
  };

  render() {
    return <>{this.renderList()}</>;
  }
}
