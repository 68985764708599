import React, { Component } from 'react';
import { calcDisplayedTime, ENUM_FORMAT_DATE, millisToMAndS } from 'utils/date-util';
import './StepsWidget.scss';
import { getStringColor } from 'utils/color-util';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import { computeDateFormatFromInterval, roundData } from 'utils/computeStartDateFromInterval';
import { HIGHLIGHTS_DATE_ENUM } from 'utils/highlights-enums';

const barChartConfig = {
  xAxisProps: {
    dataKey: 'time',
  },
  yAxisProps: {
    tickFormatter: tick => {
      return calcDisplayedTime(tick);
    },
  },
};

const screenSize = window.screen.width > 480 ? 720 : 320;
export class StepsWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
    };
  }

  componentDidMount() {
    this.convertHistogramData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data || prevProps.idx !== this.props.idx) {
      this.convertHistogramData();
    }
  }

  xAxisTimeFormatter = val => {
    const { data, timeRange } = this.props;
    const formattedData = roundData(Object.entries(data[0].steps[0].durationByDate), 2);
    const times = formattedData.map(el => el.time).sort();
    const startDate = moment(times[0], ENUM_FORMAT_DATE.allTime);
    const endDate = moment(times[times.length - 1], ENUM_FORMAT_DATE.allTime);

    const dateFormatterString = moment(val, 'YYYY-MM-DD HH:mm:ss.sss Z')
      .local()
      .format(
        timeRange !== HIGHLIGHTS_DATE_ENUM.ONE_DAY
          ? computeDateFormatFromInterval(startDate, endDate)
          : ENUM_FORMAT_DATE.hours,
      );
    return dateFormatterString;
  };

  convertHistogramData() {
    const { data, idx } = this.props;

    const chartData = {};
    data[idx].steps.forEach(el => {
      for (const [key, value] of Object.entries(el.durationByDate).sort()) {
        const obj = chartData[key];
        if (obj) {
          chartData[key] = {
            ...obj,
            [el.name]: value,
            time: key,
          };
        } else {
          chartData[key] = {
            [el.name]: value,
            time: key,
          };
        }
      }
    });
    const convertData = Object.values(chartData);

    this.setState({
      chartData: convertData,
    });
  }

  generateBar = () => {
    const { data, idx } = this.props;
    const newStepsData = data[idx].steps.map((el, index) => {
      return <Bar key={index} name={el.name} dataKey={el.name} stackId="a" fill={getStringColor(el.name)} />;
    });
    return newStepsData;
  };

  generateTooltipContent = payload => {
    const { data, idx } = this.props;
    const newStepsData = data[idx].steps.map((el, index) => {
      return (
        <>
          <p key={index} style={{ color: `${getStringColor(el.name)}`, fontSize: 12 }}>{`${
            payload[index]?.dataKey
          } : ${calcDisplayedTime(payload[index]?.value)}`}</p>
        </>
      );
    });
    return newStepsData;
  };

  customTooltip = ({ active, payload, label }) => {
    const { timeRange } = this.props;
    if (active && payload && payload.length) {
      return (
        <div style={{ background: '#161B22', borderColor: '#161B22', borderRadius: '3px', padding: '20px' }}>
          <p style={{ color: '#dcdfe5', fontSize: 12 }}>
            {moment(label, 'YYYY-MM-DD HH:mm').format(
              `${timeRange !== HIGHLIGHTS_DATE_ENUM.ONE_DAY ? 'DD MMM' : 'HH:mm'}`,
            )}
          </p>
          {this.generateTooltipContent(payload)}
        </div>
      );
    }

    return null;
  };

  renderStepList = () => {
    const { data, idx } = this.props;
    const newListData = data[idx].steps.map((el, index) => {
      return (
        <tr className="steps-list-widget-row" key={index} style={{ height: '24px' }}>
          <td>
            <div className="steps-id">
              <span className="steps-id-color" style={{ backgroundColor: getStringColor(el.name) }}></span>
            </div>
          </td>
          <td>
            <div className="steps-name">
              <span className="steps-list-cell-text"> {el.name}</span>
            </div>
          </td>
          <td>
            <div className="steps-stat">
              <span className="steps-list-cell-text">
                {' '}
                {el.totalDuration !== 0 ? millisToMAndS(el.totalDuration) : millisToMAndS(el.totalDuration)}
              </span>
            </div>
          </td>
        </tr>
      );
    });
    return newListData;
  };

  render() {
    const { chartData } = this.state;
    return (
      <div>
        <BarChart
          width={screenSize}
          height={232}
          data={chartData}
          margin={{
            top: 3,
            right: 3,
            left: -38,
            bottom: 3,
          }}
        >
          <CartesianGrid horizontal={true} vertical={false} stroke="#2A3B50" />
          <XAxis axisLine={false} dataKey={barChartConfig.xAxisProps.dataKey} tickFormatter={this.xAxisTimeFormatter} />
          <YAxis
            axisLine={false}
            tick={{
              fill: '#788496',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '11x',
              lineHeight: '16px',
            }}
            width={90}
            tickFormatter={barChartConfig.yAxisProps.tickFormatter}
          />
          <Tooltip
            content={this.customTooltip}
            wrapperStyle={{ zIndex: 1, width: 300, wordWrap: 'break-word' }}
            cursor={{ fill: 'rgba(255, 255, 255, 0.12)' }}
            offset={20}
          />
          {this.generateBar()}
        </BarChart>

        <div className="steps-widget-container" id="step-overflow">
          <table className="steps-list-table" cellSpacing="0" cellPadding="0">
            <tbody>
              <tr>
                <th className="header-steps-id"></th>
                <th style={{ textAlign: 'left' }} className="header-steps-name">
                  Steps
                </th>
                <th style={{ textAlign: 'right' }} className="header-steps-name">
                  Total Duration
                </th>
              </tr>
              {this.renderStepList()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
