import React from 'react';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './Input.scss';

export const ForesightInput = ({ hasError, labelText, required, ...props }) => {
  return (
    <>
      {labelText.length > 0 && (
        <div className="label">
          {labelText}
          {required && <span> * </span>}
        </div>
      )}
      <Input {...props} className={`${hasError ? 'foresight-input error-valid' : 'foresight-input'}`} />
    </>
  );
};

export const ForesightSmallInput = ({ hasError, labelText, required, ...props }) => {
  return (
    <>
      {labelText && (
        <div className="label">
          {labelText}
          {required && <span> * </span>}
        </div>
      )}
      <Input {...props} className={`${hasError ? 'foresight-small-input error-valid' : 'foresight-small-input'}`} />
    </>
  );
};

ForesightInput.propTypes = {
  hasError: PropTypes.bool.isRequired,
  labelText: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

ForesightInput.defaultProps = {
  labelText: '',
  hasError: false,
  required: false,
};
