import { UI_ICON } from 'assets/font-icons/IconManager';
import { LoadingDimmer, SomethingError } from 'components/ComponentPlaceholder';
import { ExtraInfoTooltipWrapComponent } from 'components/ExtraInfoTooltip';
import React, { Component } from 'react';
import { Segment, TransitionablePortal } from 'semantic-ui-react';
import { hasArrayElement } from 'utils/array-util';
import { CoverageAnalysisCodeEditor } from './CoverageAnalysisCodeEditor';
import './SourceFilesCoverageDetails.scss';

export class SourceFilesCoverageDetailsDimmer extends Component {
  render() {
    return (
      <>
        <TransitionablePortal
          closeOnDocumentClick={false}
          closeOnEscape={false}
          open={this.props.isOpen}
          transition={{ animation: 'fade', duration: 300 }}
        >
          <Segment
            style={{
              margin: '0',
              padding: '0',
              width: '100vw',
              height: '100vh',
              zIndex: 1000,
              left: '0',
              position: 'fixed',
              top: '0%',
              backgroundColor: 'rgba(106, 118, 137, 0.6)',
            }}
            onClick={() => {
              this.props.closeDetailModal();
            }}
          ></Segment>
        </TransitionablePortal>
      </>
    );
  }
}

export class SourceFilesCoverageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { coveragePartOpen: false };
  }

  calculateCodeParts = data => {
    const codeParts = [new Array()];
    if (hasArrayElement(data)) {
      let codePartsIndex = 0;
      for (let i = 0; i < data.length - 1; i++) {
        if (data[i].lineNumber + 1 === data[i + 1].lineNumber) {
          codeParts[codePartsIndex].push(data[i]);
        } else {
          codeParts[codePartsIndex].push(data[i]);
          codeParts.push(new Array());
          codePartsIndex++;
        }
      }
      codeParts[codePartsIndex].push(data[data.length - 1]);
    }
    return codeParts;
  };

  renderCollapseLeftIcon = () => {
    return (
      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.14643 1.41068L12.4464 6.69639C12.6143 6.86425 12.6143 7.13568 12.4464 7.30354L7.14643 12.5893C6.97857 12.7571 6.70714 12.7571 6.53929 12.5893L5.83929 11.8892C5.66786 11.7178 5.67143 11.4428 5.84643 11.2785L9.31429 7.93211H0.428571C0.192857 7.93211 0 7.73925 0 7.50354V6.50354C0 6.26782 0.192857 6.07497 0.428571 6.07497H9.31429L5.84643 2.72854C5.675 2.56068 5.67143 2.28568 5.83929 2.11782L6.53929 1.41782C6.70714 1.24282 6.97857 1.24282 7.14643 1.41068ZM14.1429 0.571394V13.4285C14.1429 13.6643 14.3357 13.8571 14.5714 13.8571H15.5714C15.8071 13.8571 16 13.6643 16 13.4285V0.571394C16 0.335679 15.8071 0.142822 15.5714 0.142822H14.5714C14.3357 0.142822 14.1429 0.335679 14.1429 0.571394Z"
          fill="#788496"
        />
      </svg>
    );
  };

  renderMoreCodeIcon = () => {
    return (
      <svg width="3" height="17" viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="1.5" cy="1.5" r="1.5" fill="#D9D9D9" />
        <circle cx="1.5" cy="8.5" r="1.5" fill="#D9D9D9" />
        <circle cx="1.5" cy="15.5" r="1.5" fill="#D9D9D9" />
      </svg>
    );
  };

  getLanguage = filename => {
    const fileAbbrNameTemp = filename ? filename.split('/').pop() : '';
    const fileNameParts = fileAbbrNameTemp.split('.');
    if (fileNameParts.length === 2) {
      const fileExt = fileNameParts[1];
      switch (fileExt) {
        case 'py':
          return 'python';
        case 'js':
          return 'javascript';
        default:
          return 'java';
      }
    }
  };

  fileCreateJsx = () => {
    const { fileName } = this.state;
    const { workflowId } = this.props.match.params;
    const { testingCoverage } = this.props.workflowRunJobTests;
    const { fileCoverageResults: files = [] } = testingCoverage.data;
    const selectedFileIndex = files
      .sort((a, b) => {
        if (a.patchUncoveredLineCount > b.patchUncoveredLineCount) return -1;
        else return 1;
      })
      .findIndex(el => el.filename === fileName);

    const fileJSX = files.map((el, index) => {
      const fileAbbrNameTemp = el.filename ? el.filename.split('/').pop() : '';
      const fileAbbrName = this.getLanguage(el.filename) === 'java' ? fileAbbrNameTemp : el.filename;
      const fileRowClassName = index === selectedFileIndex ? 'filename-coverage-row selected' : 'filename-coverage-row';

      return (
        <div
          key={index}
          className={fileRowClassName}
          onClick={e => {
            e.stopPropagation();
            this.setState({ coveragePartOpen: true, fileName: el.filename }, () => {
              const { selectedJobIdx, workflowRuns } = this.props;
              const { workflowRunOverview } = workflowRuns;

              if (workflowRunOverview && workflowRunOverview.jobs && workflowRunOverview.jobs.length > selectedJobIdx) {
                //JobId Used for Test Summary, Failed Tests, and Coverage
                const selectedJobId = workflowRunOverview.jobs[selectedJobIdx].id;
                this.props.getSourceCoverage(workflowId, this.state.fileName, selectedJobId);
              }
            });
          }}
        >
          <span>{fileAbbrName}</span>
          <div className="filename-coverage-row-right-part">
            <span>{el.patchUncoveredLineCount}</span>
            <i className={UI_ICON.CHEVRON.RIGHT} />
          </div>
        </div>
      );
    });

    return fileJSX;
  };

  fileCreateJsx2 = () => {
    const { fileName } = this.state;
    const { workflowRunId: workflowId, jobId, files } = this.props.metaData;
    const selectedFileIndex = files
      .sort((a, b) => {
        if (a.patchUncoveredLineCount > b.patchUncoveredLineCount) return -1;
        else return 1;
      })
      .findIndex(el => el.filename === fileName);

    const fileJSX = files.map((el, index) => {
      const fileAbbrNameTemp = el.filename ? el.filename.split('/').pop() : '';
      const fileAbbrName = this.getLanguage(el.filename) === 'java' ? fileAbbrNameTemp : el.filename;
      const fileRowClassName = index === selectedFileIndex ? 'filename-coverage-row selected' : 'filename-coverage-row';

      return (
        <div
          key={index}
          className={fileRowClassName}
          onClick={e => {
            e.stopPropagation();
            this.setState({ coveragePartOpen: true, fileName: el.filename }, () => {
              this.props.getSourceCoverage(workflowId, this.state.fileName, jobId);
            });
          }}
        >
          <span>{fileAbbrName}</span>
          <div className="filename-coverage-row-right-part">
            <span>{el.patchUncoveredLineCount}</span>
            <i className={UI_ICON.CHEVRON.RIGHT} />
          </div>
        </div>
      );
    });

    return fileJSX;
  };

  render() {
    const { coveragePartOpen, fileName } = this.state;
    const { workflowId } = this.props.match.params;

    let codeCoverageJSX = '';

    if (coveragePartOpen) {
      const { sourceCoverageDetailMap } = this.props.sourceCoverageDetails;
      const sourceCoverageDetail = sourceCoverageDetailMap[workflowId + '' + fileName];
      if (sourceCoverageDetail && sourceCoverageDetail.sourceCoverageObj) {
        const { fetched, fetching, error } = sourceCoverageDetail.sourceCoverageObj;
        if (error) {
          codeCoverageJSX = <SomethingError msg={error} />;
        } else if (fetching) {
          codeCoverageJSX = <LoadingDimmer />;
        } else if (fetched) {
          const { srcCoverage } = sourceCoverageDetail.sourceCoverageObj;
          const files = srcCoverage.fileCoverageResults ? srcCoverage.fileCoverageResults : [];

          const file = files.find(el => el.filename === fileName);
          if (file) {
            const codeParts = this.calculateCodeParts(file.lineAdditions);
            codeCoverageJSX = codeParts.map((cPart, index) => {
              const flagMoreCode = index < codeParts.length - 1;
              return (
                <>
                  <CoverageAnalysisCodeEditor key={index} data={cPart} language={this.getLanguage(file.filename)} />
                  {flagMoreCode && <div className="more-code-placeholder-container">{this.renderMoreCodeIcon()}</div>}
                </>
              );
            });
          }
        }
      }
    }

    const config = coveragePartOpen
      ? { width: 'calc(100% - 208px)', left: '208px' }
      : { width: '640px', left: 'calc(100% - 640px)' };

    return (
      <>
        <TransitionablePortal
          closeOnDocumentClick={false}
          closeOnEscape={false}
          onClose={() => this.setState({ coveragePartOpen: false })}
          open={this.props.isOpen}
          transition={{ animation: 'slide left', duration: 500 }}
        >
          <Segment
            style={{
              margin: '0',
              padding: '0',
              width: config.width,
              height: '100vh',
              zIndex: 1000,
              left: config.left,
              position: 'fixed',
              top: '0%',
              backgroundColor: '#181B1F',
            }}
          >
            <div className="workflow-run-detail-container">
              <div className="workflow-run-detail-title">
                <span className="title-text">Change impact analysis</span>
                <span className="title-icon">
                  <i
                    className={UI_ICON.CROSS.REPO_MODAL_CLOSE}
                    onClick={() => {
                      this.props.closeDetailModal();
                    }}
                  />
                </span>
              </div>

              <div className="coverage-info-container">
                <i className={UI_ICON.ALERT.INFO_BLUE_CIRCLE} />
                <span>Lines not tested after the changes</span>
              </div>

              <div className="workflow-run-detail-body">
                <div className="workflow-run-detail-body-left">
                  <div className="filename-coverage-container">
                    <div className="filename-coverage-row-header">
                      <span>File Name</span>
                      <span>Not tested changed lines</span>
                    </div>
                    {this.props.metaData ? this.fileCreateJsx2() : this.fileCreateJsx()}
                  </div>
                </div>
                {coveragePartOpen && (
                  <div className="workflow-run-detail-body-right">
                    <div className="code-coverage-row-header">
                      <span>Changed Code</span>
                      <div className="code-coverage-row-header-right">
                        <div className="uncovered-icon">+</div>
                        <span>Uncovered</span>
                        <div className="covered-icon">+</div>
                        <span>Covered</span>
                        <ExtraInfoTooltipWrapComponent tooltipText="Close Code">
                          <div
                            onClick={() => this.setState({ coveragePartOpen: false })}
                            className="collapse-right-icon"
                          >
                            {this.renderCollapseLeftIcon()}
                          </div>
                        </ExtraInfoTooltipWrapComponent>
                      </div>
                    </div>
                    <div className="code-coverage-editors-container">{codeCoverageJSX}</div>
                  </div>
                )}
              </div>
            </div>
          </Segment>
        </TransitionablePortal>
      </>
    );
  }
}
