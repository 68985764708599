import React, { Component } from 'react';
import { remoteRowCount } from 'components/TestRunsInfiniteTable';
import { ForesightCheckbox } from 'custom-components/Checkbox/Checkbox';
import ForesightCheckboxList from 'custom-components/CheckboxList/CheckboxList';
import { Accordion, Icon, Menu } from 'semantic-ui-react';
import { isEqualDeep } from 'utils/common-util';
import { TESTRUN_STATUS } from 'utils/testrun-status';
import './TestRunsFilter.scss';
import { isEqual } from 'lodash';

const spanStyle = {
  background: '#242c37',
  padding: '4px 8px',
  borderRadius: '50%',
  marginLeft: '7px',
  color: '#ffffff',
};
export default class TestRunsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStatusFilterGroupCollapsed: false,
      isBranchFilterGroupCollapsed: false,
      isTagsFilterGroupCollapsed: false,
    };
  }

  callStoreTestRunFilterItems = (ciProvider, repoOwner, repoName, isFirst = true, hideFork) => {
    const { prId } = this.props;
    if (prId) {
      this.props.getTestRunsListByFilters(ciProvider, repoOwner, repoName, prId, isFirst, hideFork);
    } else {
      this.props.getTestRunsListByFilters(ciProvider, repoOwner, repoName, isFirst, hideFork);
    }
  };

  callStoreTestRunsList = (
    ciProvider,
    repoOwner,
    repoName,
    afterKey = null,
    remoteRowCount,
    selectedFilters,
    isFirstPage = true,
    hideForkBranch,
  ) => {
    const { prId } = this.props;
    if (prId) {
      this.props.getTestRunsList(
        ciProvider,
        repoOwner,
        repoName,
        afterKey,
        remoteRowCount,
        selectedFilters,
        prId,
        isFirstPage,
        hideForkBranch,
      );
    } else {
      this.props.getTestRunsList(
        ciProvider,
        repoOwner,
        repoName,
        afterKey,
        remoteRowCount,
        selectedFilters,
        isFirstPage,
        hideForkBranch,
      );
    }
  };

  componentDidMount() {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    const { hideForkBranch } = this.props.testRuns;
    this.callStoreTestRunFilterItems(ciProvider, repoOwner, repoName, true, hideForkBranch);
  }

  callStoreSelectedBranches = selectedBranches => {
    const { prId } = this.props;
    if (prId) {
      this.props.setSelectedTestRunsBranches(selectedBranches, prId);
    } else {
      this.props.setSelectedTestRunsBranches(selectedBranches);
    }
  };

  callStoreSelectedStatuses = selectedStatus => {
    const { prId } = this.props;
    if (prId) {
      this.props.setSelectedTestRunsStatuses(selectedStatus, prId);
    } else {
      this.props.setSelectedTestRunsStatuses(selectedStatus);
    }
  };

  callStoreSelectedTags = selectedTags => {
    const { prId } = this.props;
    if (prId) {
      this.props.setSelectedTestRunsTags(selectedTags, prId);
    } else {
      this.props.setSelectedTestRunsTags(selectedTags);
    }
  };

  handleBranchCheckboxClickAllFilter = (data, filteredData) => {
    if (data.checked) {
      const copyOfIds = filteredData.map(item => item.name);
      this.callStoreSelectedBranches(copyOfIds);
    } else {
      this.callStoreSelectedBranches([]);
    }
  };

  handleBranchCheckboxClickFilter = (id, data) => {
    const label = data?.label;
    const { selectedTestRunsBranches } = this.props.testRuns;
    let branchArr = [...selectedTestRunsBranches];
    if (data.checked) {
      branchArr.push(label);
      this.callStoreSelectedBranches(branchArr);
    } else {
      const filterArr = branchArr.filter(item => item !== data.label);
      this.callStoreSelectedBranches(filterArr);
    }
  };

  /* A function that returns a component. */
  getFilterBranches = () => {
    const { filterContent, testRunsFilterFetched, testRunsFilterFetching, selectedTestRunsBranches } =
      this.props.testRuns;
    const branches = filterContent.branches || [];
    let newFilterBranchItems = branches.map(item => {
      return { name: item, checked: selectedTestRunsBranches.includes(item) };
    });

    return (
      <ForesightCheckboxList
        searchBarPlaceholder="Search for a branch"
        parentName="Select All"
        data={newFilterBranchItems}
        emptyJsx={<div style={{ marginTop: 10, fontWeight: 500 }}> Branch Not Found </div>}
        loading={testRunsFilterFetching}
        fetched={testRunsFilterFetched}
        subHeader="BRANCH NAMES"
        handleCheckboxOnClick={(id, data) => this.handleBranchCheckboxClickFilter(id, data)}
        handleSelectAllToggle={this.handleBranchCheckboxClickAllFilter}
      />
    );
  };

  /* Checking if the selected status is in the list of selected statuses. */

  handleStatusCheckboxClickAllFilter = (data, filteredData) => {
    if (data.checked) {
      const copyOfNames = filteredData.map(item => item.name.toUpperCase().replace(/\s/g, '_'));
      this.callStoreSelectedStatuses(copyOfNames);
    } else {
      this.callStoreSelectedStatuses([]);
    }
  };

  handleStatusCheckboxClickFilter = (id, data) => {
    const upperCaseLabel = data?.label.toUpperCase().replace(/\s/g, '_');
    const { selectedTestRunsStatuses } = this.props.testRuns;
    let statusArr = [...selectedTestRunsStatuses];
    if (data.checked) {
      statusArr.push(upperCaseLabel);

      this.callStoreSelectedStatuses(statusArr);
    } else {
      const filterArr = statusArr.filter(item => item !== upperCaseLabel);
      this.callStoreSelectedStatuses(filterArr);
    }
  };

  /* A function that returns a component. */
  getFilterStatus = () => {
    const { filterContent, testRunsFilterFetching, testRunsFilterFetched, selectedTestRunsStatuses } =
      this.props.testRuns;
    const statuses = filterContent?.statuses || [];
    let newFilterStatusItems = statuses.map(item => {
      return { name: TESTRUN_STATUS[item], checked: selectedTestRunsStatuses.includes(item) };
    });

    return (
      <ForesightCheckboxList
        searchBarPlaceholder="Search for a status"
        parentName="Select All"
        data={newFilterStatusItems}
        emptyJsx={<div style={{ marginTop: 10, fontWeight: 500 }}> Status Not Found </div>}
        loading={testRunsFilterFetching}
        fetched={testRunsFilterFetched}
        subHeader="STATUS NAMES"
        handleCheckboxOnClick={(id, data) => this.handleStatusCheckboxClickFilter(id, data)}
        handleSelectAllToggle={this.handleStatusCheckboxClickAllFilter}
      />
    );
  };

  handleTagsCheckboxClickAllFilter = (data, filteredData) => {
    if (data.checked) {
      const filterDataConvertion = filteredData.map(item => {
        const splitStringArr = item?.name.split(':');
        return {
          key: splitStringArr[0],
          value: splitStringArr[1].trim(),
        };
      });
      this.callStoreSelectedTags(filterDataConvertion);
    } else {
      this.callStoreSelectedTags([]);
    }
  };

  handleTagsCheckboxClickFilter = (id, data) => {
    const label = data?.label.split(':');
    const { selectedTestRunsTags } = this.props.testRuns;
    let tagsArr = [...selectedTestRunsTags];
    if (data.checked) {
      tagsArr.push({ key: label[0], value: label[1].trim() });

      this.callStoreSelectedTags(tagsArr);
    } else {
      const filterArr = tagsArr.filter(item => !isEqual(item, { key: label[0], value: label[1].trim() }));
      this.callStoreSelectedTags(filterArr);
    }
  };

  /* A function that returns a component. */
  getFilterTags = () => {
    const { filterContent, testRunsFilterFetching, testRunsFilterFetched, selectedTestRunsTags } = this.props.testRuns;
    const tags = filterContent?.tags || [];
    const selectedNewArr = selectedTestRunsTags.map(item => `${item.key}:${item.value}`);
    let newFilterTagsItems = tags.map(item => {
      return {
        name: `${item.key}: ${item.value}`,
        checked: selectedNewArr.some(selectedItem => selectedItem === `${item.key}:${item.value}`),
      };
    });

    return (
      <ForesightCheckboxList
        searchBarPlaceholder="Search for a tags"
        parentName="Select All"
        data={newFilterTagsItems}
        emptyJsx={<div style={{ marginTop: 10, fontWeight: 500 }}> Tags Not Found </div>}
        loading={testRunsFilterFetching}
        fetched={testRunsFilterFetched}
        subHeader="TAG NAMES"
        handleCheckboxOnClick={(id, data) => this.handleTagsCheckboxClickFilter(id, data)}
        handleSelectAllToggle={this.handleTagsCheckboxClickAllFilter}
      />
    );
  };

  getStatusFilterForm = () => {
    return this.getFilterStatus();
  };

  getBranchFilterForm = () => {
    return this.getFilterBranches();
  };

  getTagsFilterForm = () => {
    return this.getFilterTags();
  };

  getTestRunsList = (status, branch) => {
    const { afterKey } = this.props.testRuns;
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    this.props.getTestRunsListByFilter(ciProvider, repoOwner, repoName, afterKey, remoteRowCount, { status, branch });
  };

  handleClick = property => {
    switch (property) {
      case 'STATUS': {
        this.setState({
          isStatusFilterGroupCollapsed: !this.state.isStatusFilterGroupCollapsed,
        });
        break;
      }
      case 'BRANCH': {
        this.setState({
          isBranchFilterGroupCollapsed: !this.state.isBranchFilterGroupCollapsed,
        });
        break;
      }
      case 'TAGS': {
        this.setState({
          isTagsFilterGroupCollapsed: !this.state.isTagsFilterGroupCollapsed,
        });
        break;
      }
      default:
        break;
    }
  };

  handleHideForkBranches = hideFork => {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;

    const { selectedTestRunsBranches, selectedTestRunsStatuses } = this.props.testRuns;

    this.callStoreTestRunsList(
      ciProvider,
      repoOwner,
      repoName,
      null,
      remoteRowCount,
      {
        selectedTestRunsBranches,
        selectedTestRunsStatuses,
      },
      true,
      hideFork,
    );
    this.props.setSelectedHideFork(hideFork);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    const { ciProvider: ciProviderNext, repoOwner: repoOwnerNext, repoName: repoNameNext } = nextProps.match.params;

    const { hideForkBranch } = this.props.testRuns;

    const { hideForkBranch: nextHideFork } = nextProps.testRuns;

    if (ciProvider !== ciProviderNext || repoOwnerNext !== repoOwner || repoNameNext !== repoName) {
      this.callStoreTestRunFilterItems(ciProviderNext, repoOwnerNext, repoNameNext, true, hideForkBranch);
    }

    if (!isEqualDeep(hideForkBranch, nextHideFork)) {
      this.callStoreTestRunFilterItems(ciProviderNext, repoOwnerNext, repoNameNext, true, nextHideFork);
    }
  }

  render() {
    const { isStatusFilterGroupCollapsed, isBranchFilterGroupCollapsed, isTagsFilterGroupCollapsed } = this.state;
    const { hideForkBranch, selectedTestRunsStatuses, selectedTestRunsBranches, selectedTestRunsTags } =
      this.props.testRuns;

    return (
      <div className="test-run-filter-main-container">
        <div className="workflow-runs-filter-head-container">
          <div className="filter-by-title">Filter By</div>
          <div className="filter-by-checkbox">
            <ForesightCheckbox
              checked={hideForkBranch}
              label={'Hide forks'}
              onChange={(e, data) => this.handleHideForkBranches(data.checked)}
            />
          </div>
        </div>
        <Accordion as={Menu} vertical>
          <Menu.Item>
            <Accordion.Title index={0} onClick={() => this.handleClick('STATUS')}>
              STATUS
              {selectedTestRunsStatuses.length > 0 && <span style={spanStyle}>{selectedTestRunsStatuses.length}</span>}
              <Icon name={isStatusFilterGroupCollapsed ? 'angle up' : 'angle down'} />
            </Accordion.Title>
            <Accordion.Content
              className="accordion-content-container"
              active={isStatusFilterGroupCollapsed}
              content={this.getStatusFilterForm()}
            />
          </Menu.Item>

          <Menu.Item className="border-box">
            <Accordion.Title index={1} onClick={() => this.handleClick('BRANCH')}>
              BRANCH{' '}
              {selectedTestRunsBranches.length > 0 && <span style={spanStyle}>{selectedTestRunsBranches.length}</span>}
              <Icon name={isBranchFilterGroupCollapsed ? 'angle up' : 'angle down'} />
            </Accordion.Title>
            <Accordion.Content active={isBranchFilterGroupCollapsed} content={this.getBranchFilterForm()} />
          </Menu.Item>
          <Menu.Item className="border-box">
            <Accordion.Title index={1} onClick={() => this.handleClick('TAGS')}>
              TAGS {selectedTestRunsTags.length > 0 && <span style={spanStyle}>{selectedTestRunsTags.length}</span>}
              <Icon name={isTagsFilterGroupCollapsed ? 'angle up' : 'angle down'} />
            </Accordion.Title>
            <Accordion.Content active={isTagsFilterGroupCollapsed} content={this.getTagsFilterForm()} />
          </Menu.Item>
        </Accordion>
      </div>
    );
  }
}
