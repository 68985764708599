import { connect } from 'react-redux';
import { OrganizationList } from 'components';
import { withRouter } from 'react-router';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import {
  getAllOrganizationList,
  getActiveOrganizationList,
  switchOrganization,
  leaveOrganization,
} from 'store/actions/organization';
import { acceptUserInvitation } from 'store/actions/invitation';
import { getUserAccount } from 'store/actions/user-account';
import { getActiveSubscription } from 'store/actions/billing-active-subscription';

function mapStateToProps(store) {
  return {
    organization: store.organization,
  };
}

function mapDispatchToProps() {
  return {
    getUserAccount(onGetUserAccountSuccess, onGetUserAccountFail) {
      dispatchWithExceptionHandler(getUserAccount(), false, onGetUserAccountSuccess, onGetUserAccountFail);
    },
    getActiveSubscription(organizationId, onSuccess, onError) {
      dispatchWithExceptionHandler(getActiveSubscription(organizationId), false, onSuccess, onError);
    },
    getAllOrganizationList(onGetAllOrganizationListSuccess, onGetAllOrganizationListFail) {
      dispatchWithExceptionHandler(
        getAllOrganizationList(),
        false,
        onGetAllOrganizationListSuccess,
        onGetAllOrganizationListFail,
      );
    },
    switchOrganization(id, onGetOrganizationListSuccess, onGetOrganizationListFail) {
      dispatchWithExceptionHandler(
        switchOrganization(id),
        false,
        onGetOrganizationListSuccess,
        onGetOrganizationListFail,
      );
    },
    acceptUserInvitation(invitationId, onAcceptInvitationSuccess, onAcceptInvitationFail) {
      dispatchWithExceptionHandler(
        acceptUserInvitation(invitationId),
        false,
        onAcceptInvitationSuccess,
        onAcceptInvitationFail,
      );
    },
    leaveOrganization(organizationId, onLeaveOrganizationSuccess, onLeaveOrganizationFail) {
      dispatchWithExceptionHandler(
        leaveOrganization(organizationId),
        false,
        onLeaveOrganizationSuccess,
        onLeaveOrganizationFail,
      );
    },
    getActiveOrganizationList(onGetOrganizationListSuccess, onGetOrganizationListFail) {
      dispatchWithExceptionHandler(
        getActiveOrganizationList(),
        false,
        onGetOrganizationListSuccess,
        onGetOrganizationListFail,
      );
    },
  };
}

const OrganizationListContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationList));
export default OrganizationListContainer;
