// Generator Steps Bar Chart for component
// Language: javascript
import { Tooltip } from 'components/Tooltip';
import _ from 'lodash';
import React, { memo } from 'react';
import { millisToMAndS } from 'utils/date-util';
const MIN_DURATION = 0;
const GeneratorStepsBarChart = ({ data }) => {
  const calculateStepDuration = duration => {
    const durationArray = data.map(item => item.duration / 1000);
    const maxNumber = _.max(durationArray);
    if (duration && duration > 0) {
      const calculatedStepDuration = ((32 / maxNumber) * duration) / 1000;
      return calculatedStepDuration === '1' ? '2' : calculatedStepDuration;
    }
    return MIN_DURATION;
  };

  return (
    <div className="bar-container">
      {data.map((step, index) => {
        return (
          <Tooltip key={index} content={`${millisToMAndS(step.duration)}`}>
            {calculateStepDuration(step.duration) === 0 ? (
              <div className="bar-column EMPTY" />
            ) : (
              <div
                key={index}
                className={`bar-column ${step.conclusion}`}
                style={{ height: calculateStepDuration(step.duration) || '0' + 'px' }}
              ></div>
            )}
          </Tooltip>
        );
      })}
    </div>
  );
};

export default memo(GeneratorStepsBarChart);
