import React, { Component } from 'react';
import auth from 'auth/auth-factory';
import routeList, { getInitialRouteForRootPath } from 'routes';

import './CallbackPage.scss';

class CallbackPage extends Component {
  componentDidMount() {
    console.log('FORESIGHT, CallbackPage, CDM; props: ', JSON.stringify(this.props));

    const installationId = localStorage.getItem('github_installation_id');
    if (this.props.history) {
      if (auth.isAuthenticated() && installationId) {
        console.log('FORESIGHT, CP, redirect to repo watch page; installationId: ', installationId);
        this.props.history.replace(
          `${routeList.startGitHubExistedProjectRepoSelectionPage.path}?github_installation_id=${installationId}`,
        );
      } else if (auth.isAuthenticated()) {
        console.log('FORESIGHT, CallbackPage, isAuth true, before testRuns!');
        this.props.history.replace(getInitialRouteForRootPath());
      } else {
        console.log('FORESIGHT, callback page render landing page, no redirection');
        this.props.history.replace(routeList.landing.path);
      }
    }
  }

  render() {
    console.log('FORESIGHT, CallbackPage, render; props: ', this.props);

    return (
      <div className="loading-view">
        <div className="loading-bg-artwork">
          <div className="loading-bg-image" />
        </div>
        <div className="loading-real">
          <div className="thundra-logo" />
          <div className="loading-message">PREPARING TO FLY</div>
          <div className="loading-lines">
            <div className="load-base">
              <div className="load-in" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CallbackPage;
