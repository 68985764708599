export const GET_HIGHLIGHT_FILTER_PROJECTS = 'GET_HIGHLIGHT_FILTER_PROJECTS';
export const GET_HIGHLIGHT_FILTER_PROJECTS_PENDING = 'GET_HIGHLIGHT_FILTER_PROJECTS_PENDING';
export const GET_HIGHLIGHT_FILTER_PROJECTS_FULFILLED = 'GET_HIGHLIGHT_FILTER_PROJECTS_FULFILLED';
export const GET_HIGHLIGHT_FILTER_PROJECTS_REJECTED = 'GET_HIGHLIGHT_FILTER_PROJECTS_REJECTED';

export const GET_HIGHLIGHT_FILTER_REPOSITORIES = 'GET_HIGHLIGHT_FILTER_REPOSITORIES';
export const GET_HIGHLIGHT_FILTER_REPOSITORIES_PENDING = 'GET_HIGHLIGHT_FILTER_REPOSITORIES_PENDING';
export const GET_HIGHLIGHT_FILTER_REPOSITORIES_FULFILLED = 'GET_HIGHLIGHT_FILTER_REPOSITORIES_FULFILLED';
export const GET_HIGHLIGHT_FILTER_REPOSITORIES_REJECTED = 'GET_HIGHLIGHT_FILTER_REPOSITORIES_REJECTED';

export const GET_HIGHLIGHT_FILTER_BRANCHES = 'GET_HIGHLIGHT_FILTER_BRANCHES';
export const GET_HIGHLIGHT_FILTER_BRANCHES_PENDING = 'GET_HIGHLIGHT_FILTER_BRANCHES_PENDING';
export const GET_HIGHLIGHT_FILTER_BRANCHES_FULFILLED = 'GET_HIGHLIGHT_FILTER_BRANCHES_FULFILLED';
export const GET_HIGHLIGHT_FILTER_BRANCHES_REJECTED = 'GET_HIGHLIGHT_FILTER_BRANCHES_REJECTED';
