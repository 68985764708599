import {
  GET_LIST_WEBHOOK_INTEGRATION_PENDING,
  GET_LIST_WEBHOOK_INTEGRATION_FULFILLED,
  GET_LIST_WEBHOOK_INTEGRATION_REJECTED,
  GET_WEBHOOK_INTEGRATION_PENDING,
  GET_WEBHOOK_INTEGRATION_FULFILLED,
  GET_WEBHOOK_INTEGRATION_REJECTED,
  DELETE_WEBHOOK_INTEGRATION_PENDING,
  DELETE_WEBHOOK_INTEGRATION_FULFILLED,
  DELETE_WEBHOOK_INTEGRATION_REJECTED,
  CREATE_BATCH_WEBHOOK_INTEGRATION_PENDING,
  CREATE_BATCH_WEBHOOK_INTEGRATION_REJECTED,
  CREATE_BATCH_WEBHOOK_INTEGRATION_FULFILLED,
  GET_SLACK_INTEGRATION_LIMIT_REJECTED,
  GET_SLACK_INTEGRATION_LIMIT_FULFILLED,
  GET_SLACK_INTEGRATION_LIMIT_PENDING,
} from 'store/constants/notification-integration';

const initialState = {
  createBatchWebhook: {},
  isCreateBatchWebhookFetching: false,
  isCreateBatchWebhookFetched: false,
  isCreateBatchWebhookError: null,

  listWebhook: [],
  isListWebhookFetching: false,
  isListWebhookFetched: false,
  isListWebhookError: null,

  getWebhook: {},
  isGetWebhookFetching: false,
  isGetWebhookFetched: false,
  isGetWebhookError: null,

  slackIntegrationLimit: null,
  slackIntegrationLimitFetching: false,
  slackIntegrationLimitFetched: false,
  slackIntegrationLimitError: null,

  isDeleteWebhookFetching: false,
  isDeleteWebhookFetched: false,
  isDeleteWebhookError: false,
};

export default function notificationIntegration(state = initialState, action) {
  switch (action.type) {
    // Batch Create and Update
    case CREATE_BATCH_WEBHOOK_INTEGRATION_PENDING:
      return {
        ...state,
        isCreateBatchWebhookFetching: true,
        isCreateBatchWebhookFetched: false,
        isCreateBatchWebhookError: null,
      };
    case CREATE_BATCH_WEBHOOK_INTEGRATION_FULFILLED:
      return {
        ...state,
        createBatchWebhook: action.payload.data,
        isCreateBatchWebhookFetching: false,
        isCreateBatchWebhookFetched: true,
        isCreateBatchWebhookError: null,
      };
    case CREATE_BATCH_WEBHOOK_INTEGRATION_REJECTED:
      return {
        ...state,
        isCreateBatchWebhookFetching: false,
        isCreateBatchWebhookFetched: false,
        isCreateBatchWebhookError: action.payload,
      };

    /* A reducer for the webhook integration. */
    case GET_LIST_WEBHOOK_INTEGRATION_PENDING:
      return {
        ...state,
        isListWebhookFetching: true,
        isListWebhookFetched: false,
        isListWebhookError: null,
      };
    case GET_LIST_WEBHOOK_INTEGRATION_FULFILLED:
      return {
        ...state,
        listWebhook: action.payload.data,
        isListWebhookFetching: false,
        isListWebhookFetched: true,
        isListWebhookError: null,
      };
    case GET_LIST_WEBHOOK_INTEGRATION_REJECTED:
      return {
        ...state,
        isListWebhookFetching: false,
        isListWebhookFetched: false,
        isListWebhookError: action.payload,
      };
    /* A reducer for the webhook integration. */
    case GET_WEBHOOK_INTEGRATION_PENDING:
      return {
        ...state,
        isGetWebhookFetching: true,
        isGetWebhookFetched: false,
        isGetWebhookError: null,
      };
    case GET_WEBHOOK_INTEGRATION_FULFILLED:
      return {
        ...state,
        getWebhook: action.payload,
        isGetWebhookFetching: false,
        isGetWebhookFetched: true,
        isGetWebhookError: null,
      };
    case GET_WEBHOOK_INTEGRATION_REJECTED:
      return {
        ...state,
        isGetWebhookFetching: false,
        isGetWebhookFetched: true,
        isGetWebhookError: action.payload,
      };

    /* A reducer for the slack integration limit. */
    case GET_SLACK_INTEGRATION_LIMIT_PENDING:
      return {
        ...state,
        slackIntegrationLimitFetching: true,
        slackIntegrationLimitFetched: false,
        slackIntegrationLimitError: null,
      };
    case GET_SLACK_INTEGRATION_LIMIT_FULFILLED:
      return {
        ...state,
        slackIntegrationLimit: action.payload,
        slackIntegrationLimitFetching: false,
        slackIntegrationLimitFetched: true,
        slackIntegrationLimitError: null,
      };
    case GET_SLACK_INTEGRATION_LIMIT_REJECTED:
      return {
        ...state,
        slackIntegrationLimitFetching: false,
        slackIntegrationLimitFetched: false,
        slackIntegrationLimitError: action.payload,
      };

    /* A reducer for the delete webhook integration. */
    case DELETE_WEBHOOK_INTEGRATION_PENDING:
      return {
        ...state,
        isDeleteWebhookFetching: true,
        isDeleteWebhookFetched: false,
        isDeleteWebhookError: false,
      };
    case DELETE_WEBHOOK_INTEGRATION_FULFILLED:
      return {
        ...state,
        getWebhook: [],
        isDeleteWebhookFetching: false,
        isDeleteWebhookFetched: true,
        isDeleteWebhookError: false,
      };
    case DELETE_WEBHOOK_INTEGRATION_REJECTED:
      return {
        ...state,
        isDeleteWebhookFetching: false,
        isDeleteWebhookFetched: false,
        isDeleteWebhookError: action.payload,
      };
    default:
      return state;
  }
}
