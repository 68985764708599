import {
  INVITE_BILLING_ADMIN_TO_ORGANIZATION_FULFILLED,
  INVITE_BILLING_ADMIN_TO_ORGANIZATION_PENDING,
  INVITE_BILLING_ADMIN_TO_ORGANIZATION_REJECTED,
} from 'store/constants/organization-invite-billing-admin';

const createOrganizationInitialState = {
  invitationResult: {},

  fetching: false,
  fetched: false,
  error: null,
};

export default function inviteBillingAdminToOrganization(state = createOrganizationInitialState, action) {
  switch (action.type) {
    case INVITE_BILLING_ADMIN_TO_ORGANIZATION_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case INVITE_BILLING_ADMIN_TO_ORGANIZATION_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        invitationResult: action.payload.data,
      };
    case INVITE_BILLING_ADMIN_TO_ORGANIZATION_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
