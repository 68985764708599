import { LoadingDimmer } from 'components/ComponentPlaceholder';
import { OverviewTitleContainer } from 'custom-components/Overview/OverviewTitle';
import React, { Component } from 'react';
import {
  getPullRequestMergableStateEnumToText,
  getPullRequestMergableStateIcons,
} from 'utils/pull-request-mergable-icon';
import { getPullRequestIcons, getPullRequestStatusEnumToText } from 'utils/pull-request-status-icon';
export class PROverviewTitle extends Component {
  renderPROverviewTitle = () => {
    const { fetched, pullRequestOverview } = this.props.pullRequestOverview;
    if (fetched) {
      const { summary } = pullRequestOverview;
      const statusTooltipContent = getPullRequestStatusEnumToText(summary);
      const statusIcon = getPullRequestIcons(summary, 'pr-view-icon');

      const extraStatusTooltipContent = getPullRequestMergableStateEnumToText(summary?.mergeableState);
      const extraStatusIcon = getPullRequestMergableStateIcons(
        summary?.mergeableState,
        'overview-extra-state-icon-style',
      );

      return (
        <OverviewTitleContainer
          statusTooltipContent={statusTooltipContent}
          status={statusIcon}
          titleTooltipContent={summary?.title}
          title={summary?.title}
          extraStatus={extraStatusIcon}
          extraStatusTooltipContent={extraStatusTooltipContent}
        />
      );
    } else {
      return <LoadingDimmer />;
    }
  };

  render() {
    return this.renderPROverviewTitle();
  }
}
