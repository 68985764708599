import React, { Component } from 'react';
import routeList, { computePath } from 'routes';
import { ExtraWarning } from '../ExtraWarning/ExtraWarning';
import { IntegrationInstruction } from '../IntegrationInstruction/IntegrationInstruction';
import { CPUMetrics } from './CPUMetrics/CPUMetrics';
import { IOMetrics } from './IOMetrics/IOMetrics';
import { MemoryMetrics } from './MemoryMetrics/MemoryMetrics';
import './RepositoryWorkflowJobMetrics.scss';
import workflowEmptyConnect from 'assets/images/workflow-empty-connect.svg';
import { LoadingDimmer } from 'components/ComponentPlaceholder';

export class RepositoryWorkflowJobMetrics extends Component {
  constructor(props) {
    super(props);
  }

  fetchData = () => {
    const { workflowRunId, selectedJobIdx, getCPUMetrics, getMemoryMetrics, getNetworkMetrics, getDiskMetrics } =
      this.props;
    const { workflowRunOverview } = this.props.workflowRuns;

    if (workflowRunOverview && workflowRunOverview.jobs) {
      const selectedJobId = workflowRunOverview?.jobs[selectedJobIdx]?.id;
      getCPUMetrics(workflowRunId, selectedJobId);
      getMemoryMetrics(workflowRunId, selectedJobId);
      getDiskMetrics(workflowRunId, selectedJobId);
      getNetworkMetrics(workflowRunId, selectedJobId);
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedJobIdx !== this.props.selectedJobIdx) {
      this.fetchData();
    }
  }

  integrateNowOnClick = () => {
    this.props.history.push(computePath(routeList.startIntegrationGitHubTestRuns.path));
  };

  emptyChartData = () => {
    return (
      <div className="processes-tab-container">
        {!localStorage.getItem('metrics-tab-got-extra-warning') && (
          <ExtraWarning
            title="Can't see your process trace?"
            content="If you have added Foresight's Workflow Kit action to your CI pipeline and can’t see your data below, check our docs to troubleshoot."
            learnMoreURL="https://docs.runforesight.com/how-to/troubleshooting"
            onClick={() => {
              localStorage.setItem('metrics-tab-got-extra-warning', 'true');
              this.forceUpdate();
            }}
          />
        )}
        <IntegrationInstruction
          onButtonClick={this.integrateNowOnClick}
          imageSrc={workflowEmptyConnect}
          title="Debug and optimize your pipeline"
          content="Integrate Foresight’s Workflow Kit and monitor the resource metrics and process activities of your GitHub Action workflow runs. "
          contentURL="https://docs.runforesight.com/features/workflow-runs"
          troubleshootUrl="https://docs.runforesight.com/how-to/troubleshooting"
        />
      </div>
    );
  };

  hasEmptyAllChartData = () => {
    const { cpuMetrics, memoryMetrics, diskMetrics, networkMetrics } = this.props.workflowMetrics;
    const { data: cpuData } = cpuMetrics;
    const { data: memoryData } = memoryMetrics;
    const { data: diskData } = diskMetrics;
    const { data: networkData } = networkMetrics;
    const hasEmpty =
      Object.keys(cpuData?.metrics || {}).length +
        Object.keys(memoryData?.metrics || {}).length +
        Object.keys(diskData?.metrics || {}).length +
        Object.keys(networkData?.metrics || {}).length ===
      0;
    return hasEmpty;
  };

  render() {
    if (this.props.workflowMetrics.cpuMetrics.fetching) {
      return (
        <div className="processes-tab-container">
          <LoadingDimmer />
        </div>
      );
    }
    return (
      <div className="main-metrics-container">
        {this.hasEmptyAllChartData() ? (
          this.emptyChartData()
        ) : (
          <>
            <CPUMetrics {...this.props} />
            <MemoryMetrics {...this.props} />
            <IOMetrics {...this.props} />
          </>
        )}
      </div>
    );
  }
}
