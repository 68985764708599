import { UPSERT_CARD_FULFILLED, UPSERT_CARD_PENDING, UPSERT_CARD_REJECTED } from '../constants/billing-upsert-card';

const updateOrganizationInitialState = {
  upsertCardResult: {},
  fetching: false,
  fetched: false,
  error: null,
};

export default function upsertCard(state = updateOrganizationInitialState, action) {
  switch (action.type) {
    case UPSERT_CARD_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };
    case UPSERT_CARD_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        upsertCardResult: action.payload.data,
        error: null,
      };
    case UPSERT_CARD_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
