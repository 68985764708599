import {
  GET_PULL_REQUEST_WORKFLOW_RUNS_LIST_FULFILLED,
  GET_PULL_REQUEST_WORKFLOW_RUNS_LIST_PENDING,
  GET_PULL_REQUEST_WORKFLOW_RUNS_LIST_REJECTED,
} from 'store/constants/pull-request-workflow-runs';

const workflowRunsInitialState = {
  fetching: false,
  fetched: false,
  error: null,
  workflowRuns: {},
};

export default function pullRequestWorkflowRuns(state = workflowRunsInitialState, action) {
  switch (action.type) {
    case GET_PULL_REQUEST_WORKFLOW_RUNS_LIST_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_PULL_REQUEST_WORKFLOW_RUNS_LIST_FULFILLED: {
      const workflowRunsNew = { ...action.payload.data };
      if (action.payload.data.pageNumber > 0) {
        workflowRunsNew.content = [...(state.workflowRuns.content || []), ...action.payload.data.content];
      }
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        workflowRuns: workflowRunsNew,
      };
    }
    case GET_PULL_REQUEST_WORKFLOW_RUNS_LIST_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: true,
        workflowRuns: {},
      };

    default:
      return state;
  }
}
