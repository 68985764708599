import React, { Component } from 'react';
import './GitHubTestIntegrationPage.scss';
import { Button, Checkbox, Input, Popup, Container, Dropdown } from 'semantic-ui-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { copyToClipBoard } from 'utils/common-util';
import { GitHubActionsBenefits } from 'components';
import routeList from 'routes';
import {
  PageHeader,
  PageHeaderText,
  PageHeaderTitle,
  PageHeaderTitleContainer,
} from 'custom-components/PageHeader/PageHeader';
import { UI_ICON } from 'assets/font-icons/IconManager';

class GitHubTestIntegrationPage extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line quotes
    document.title = "Integrate Foresight's GitHub Actions - Thundra Foresight";
    this.state = {
      selectedTestConfig: '',
      selectedTestCoverage: '',
      checked: false,
    };
  }

  handleTestConfigChange = (e, data) => {
    this.setState({
      selectedTestConfig: data.value,
    });
  };

  handleTestCoverageChange = (e, data) => {
    this.setState({
      selectedTestCoverage: data.value,
    });
  };

  renderTestFrameworkConfigurations = () => {
    const testConfigOptions = [
      { key: 0, text: 'Please Select', value: '<test_format_optional>-<test_framework_optional>' },
      { key: 1, text: 'TestNG', value: 'TestNG-TestNG' },
      { key: 2, text: 'JUNIT', value: 'JUNIT-JUNIT' },
      { key: 4, text: 'JEST-JUNIT', value: 'JEST-JUNIT' },
      { key: 5, text: 'PYTEST-JUNIT', value: 'PYTEST-JUNIT' },
      { key: 6, text: 'XUNIT2', value: 'XUNIT2-XUNIT2' },
      { key: 7, text: 'XUNIT2-TRX', value: 'XUNIT2-TRX' },
      { key: 8, text: 'GOLANG-JSON', value: 'GOLANG-JSON' },
      { key: 9, text: 'GOLANG-TEXT', value: 'GOLANG-TEXT' },
      { key: 10, text: 'CYPRESS-JUNIT', value: 'CYPRESS-JUNIT' },
      { key: 10, text: 'ELIXIR-JUNIT', value: 'ELIXIR-JUNIT' },
    ];

    const coverageFormatOptions = [
      { key: 0, text: 'Please Select', value: '<coverage_format_optional>' },
      { key: 1, text: 'JACOCO/XML', value: 'JACOCO/XML' },
      { key: 2, text: 'COBERTURA/XML', value: 'COBERTURA/XML' },
      { key: 3, text: 'GOLANG', value: 'GOLANG' },
      { key: 4, text: 'LCOV', value: 'TXT' },
    ];

    return (
      <div className="test-framework-configurations-container">
        <div className="dropdown-label-container">
          <div className="dropdown-label">Test configuration</div>
          <Dropdown
            icon={UI_ICON.CHEVRON.DOWN}
            className="icon"
            onChange={this.handleTestConfigChange}
            placeholder="Please Select"
            options={testConfigOptions}
            selection
          />
        </div>
        <div className="dropdown-label-container">
          <div className="dropdown-label">Coverage Format</div>
          <Dropdown
            icon={UI_ICON.CHEVRON.DOWN}
            className="icon"
            onChange={this.handleTestCoverageChange}
            placeholder="Please Select"
            options={coverageFormatOptions}
            selection
          />
        </div>
      </div>
    );
  };

  renderReferenceDocumentation = () => {
    return (
      <div className="setup-help">
        <div className="setup-help-card">
          <div className="first-row">Need Help</div>
          <div className="second-row">
            <a className="link" href={'https://docs.runforesight.com/'} target="_blank" rel="noopener noreferrer">
              Refer our Documentation
            </a>

            <i id="test" className={UI_ICON.REDIRECTION.DEFAULT} />
          </div>
        </div>
      </div>
    );
  };

  onContinueClick = () => {
    this.props.history.push(routeList.repositories.path);
  };

  onSkipClick = () => {
    this.props.history.push(routeList.repositories.path);
  };

  render() {
    const {
      userAccount: { userAccount },
    } = this.props;

    let apiKeyStr = `api_key: ${userAccount?.apiKey}`;
    let testFrameworkStr = 'test_framework: <test_framework_optional>';
    let testFormatStr = 'test_format: <test_format_optional>';
    let testPathStr = 'test_path: <test_results_path_optional>';
    let coverageFormatStr = 'coverage_format: <coverage_format_optional>';
    let coveratePathStr = 'coverage_path: <coverage_results_path_optional>';

    const { selectedTestConfig, selectedTestCoverage } = this.state;
    if (selectedTestConfig) {
      const arr = selectedTestConfig.split('-');
      const testFrameworkParam = arr[0];
      const testFormatParam = arr[1];
      testFrameworkStr = `test_framework: ${testFrameworkParam}`;
      testFormatStr = testFormatParam === 'n/a' ? testFormatStr : `test_format: ${testFormatParam}`;
    }

    if (selectedTestCoverage) {
      const coverageFormatParam = selectedTestCoverage;
      coverageFormatStr = `coverage_format: ${coverageFormatParam}`;
    }

    let updateYAMLScript = `# ...

	steps:
    # Make sure you put workflow kit action on top of all the steps 
    - name: Collect Workflow Telemetry
      uses: runforesight/foresight-workflow-kit-action@v1
      if: $\{{ always() }}
      with:
        ${apiKeyStr}`;

    let updateYAMLScript2 = `# ...
 
    # Make sure you put test kit action after your test execution step 
    - name: Analyze Test and/or Coverage Results
      uses: runforesight/foresight-test-kit-action@v1
      if: $\{{ always() }}
      with:
        ${apiKeyStr}
        ${testFormatStr}
        ${testFrameworkStr}
        ${testPathStr}
        ${coverageFormatStr}
        ${coveratePathStr}`;

    return (
      <div className="integrate-your-test-page-main-container">
        <div className="main-content-container">
          <div className="main-content-header-container">
            <PageHeader style={{ width: 'calc(100% - 240px)' }}>
              <PageHeaderTitleContainer>
                <PageHeaderTitle> Add Foresight’s GitHub actions </PageHeaderTitle>
              </PageHeaderTitleContainer>
              <PageHeaderText>
                Get the most out of the Foresight. Update your YAML file with Foresight's Test Kit and Workflow Kit
                Actions
              </PageHeaderText>
            </PageHeader>
            {this.renderReferenceDocumentation()}
          </div>

          <GitHubActionsBenefits />
          <div className="main-content-section-one">
            <div className="main-content-section-one-header">
              <span className="number-sign">1</span> Create secrets for the GitHub repositories
            </div>
            <div className="main-content-section-one-content">
              <div className="main-content-input-one-label">API Key</div>
              <Popup
                trigger={
                  <Container className="configuration-code" fluid onClick={() => copyToClipBoard(userAccount?.apiKey)}>
                    {
                      <div style={{ position: 'relative' }}>
                        <div className="copy-icon-with-input">
                          <i className={UI_ICON.COPY_CODE} />
                        </div>
                        <Input
                          placeholder="1111-1111-1111-1111"
                          className="main-content-section-one-input"
                          disabled={true}
                          value={userAccount?.apiKey}
                        />
                      </div>
                    }
                  </Container>
                }
                on={'click'}
                content={'Copied!'}
                position="left center"
              />
            </div>
          </div>
          <div className="main-content-section-two">
            <div className="main-content-section-two-header">
              <span className="number-sign">2</span> Select your test kit configuration
            </div>
            <div className="main-content-section-two-content">
              <div className="main-content-section-two-content-sub-cont">
                {this.renderTestFrameworkConfigurations()}
              </div>
            </div>
          </div>
          <div className="main-content-section-three">
            <div className="main-content-section-three-header">
              <span className="number-sign">3</span> Update the YAML
            </div>
            <div className="kit-title">WORKFLOW KIT</div>
            <div className="main-content-section-three-content">
              <div className="info-part">
                <div className="box-desc-container">
                  <i className={UI_ICON.ALERT.INFO_BLUE_CIRCLE} />
                  <span>
                    Make sure you put workflow kit action on top of all the steps.
                    <a
                      className="link"
                      href={'https://docs.runforesight.com/advanced-configuration/workflow-kit'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </span>
                </div>
                <Popup
                  trigger={
                    <Container className="configuration-code" fluid onClick={() => copyToClipBoard(updateYAMLScript)}>
                      <div className="left-part">
                        {
                          <SyntaxHighlighter className="configuration-code-sample" language="yaml" style={tomorrow}>
                            {updateYAMLScript}
                          </SyntaxHighlighter>
                        }
                      </div>
                      <div className="copy-icon">
                        <i className={UI_ICON.COPY_CODE} />
                      </div>
                    </Container>
                  }
                  on={'click'}
                  content={'Copied!'}
                  position="bottom center"
                />
              </div>
              <div style={{ minHeight: 20 }}></div>
              <div className="kit-title">TEST KIT</div>
              <div className="info-part">
                <div className="box-desc-container">
                  <i className={UI_ICON.ALERT.INFO_BLUE_CIRCLE} />
                  <span>
                    Make sure that your tests run before the test report uploader step.
                    <a
                      className="link"
                      href={'https://docs.runforesight.com/advanced-configuration/test-kit'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </span>
                </div>
                <Popup
                  trigger={
                    <Container className="configuration-code" fluid onClick={() => copyToClipBoard(updateYAMLScript2)}>
                      <div className="left-part">
                        {
                          <SyntaxHighlighter className="configuration-code-sample" language="yaml" style={tomorrow}>
                            {updateYAMLScript2}
                          </SyntaxHighlighter>
                        }
                      </div>
                      <div className="copy-icon">
                        <i className={UI_ICON.COPY_CODE} />
                      </div>
                    </Container>
                  }
                  on={'click'}
                  content={'Copied!'}
                  position="bottom center"
                />
              </div>
            </div>
          </div>
          <div className="footer-wrapper">
            <div className="footer-info">
              <div className="check-for-approve">
                <Checkbox
                  checked={this.state.checked}
                  label="I have updated my GitHub Actions pipeline with the configurations above."
                  onChange={() =>
                    this.setState({
                      checked: !this.state.checked,
                    })
                  }
                />
              </div>
              <div className="action-wrapper">
                <Button
                  primary
                  onClick={this.onContinueClick}
                  className="continue-button"
                  disabled={!this.state.checked}
                >
                  Continue
                </Button>
                <Button onClick={this.onSkipClick} className="skip-button">
                  Skip this step
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GitHubTestIntegrationPage;
