import React, { Component } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, Tooltip, XAxis, YAxis } from 'recharts';
import './ExecutionCountWidget.scss';

const screenSize = window.screen.width > 480 ? 520 : 290;
export class ExecutionCountWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: new Array(this.props.data.length).fill(0.8),
    };
  }

  handleMouseEnter = (e, val) => {
    e.preventDefault();
    const { selectedBar } = this.props;
    const newOpacity = {};
    this.props.data.forEach(type => {
      let newWorkflowName = type?.repo?.fullName + '/' + type?.name;
      newOpacity[newWorkflowName] = val === newWorkflowName ? 0.8 : 0.2;
    });
    this.setState({
      opacity: newOpacity,
    });
    if (selectedBar) {
      selectedBar(val);
    }
  };

  handleMouseLeave = () => {
    const { selectedBar } = this.props;
    const newOpacity = {};
    this.props.data.forEach(type => {
      let newWorkflowName = type?.repo?.fullName + '/' + type?.name;
      newOpacity[newWorkflowName] = 0.8;
    });
    this.setState({
      opacity: newOpacity,
    });
    if (selectedBar) {
      selectedBar(null);
    }
  };

  getStrokeColor = entry => {
    const { fail } = entry;
    if (fail === 'SUCCESSFUL') {
      return 'red';
    }
    return 'green';
  };

  getFillColor = entry => {
    const { fail } = entry;
    if (fail !== 0) {
      return '#2DCD8A';
    }
    return '#FF5630';
  };

  getMask = () => {
    return '';
  };

  handleSentListName = val => {
    const newOpacity = {};
    if (val) {
      this.props.data.forEach(type => {
        let name = type?.repo?.fullName + '/' + type?.name;
        newOpacity[name] = val === name ? 0.8 : 0.2;
      });
      this.setState({
        opacity: newOpacity,
      });
    } else {
      this.props.data.forEach(type => {
        let name = type?.repo?.fullName + '/' + type?.name;
        newOpacity[name] = 0.8;
      });
      this.setState({
        opacity: newOpacity,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { sentListName } = this.props;
    if (prevProps.sentListName !== this.props.sentListName) {
      {
        this.handleSentListName(sentListName);
      }
    }
  }

  customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ background: '#161B22', borderColor: '#161B22', borderRadius: '3px', padding: '20px' }}>
          <p style={{ color: '#dcdfe5', fontSize: 12 }}>{label}</p>
          <p style={{ color: '#2abf81', fontSize: 12 }}>{`${payload[0]?.dataKey} : ${payload[0]?.value}`}</p>
          <p style={{ color: '#f26c6c', fontSize: 12 }}>{`${payload[1]?.dataKey} : ${payload[1]?.value}`}</p>
        </div>
      );
    }

    return null;
  };

  render() {
    const { data } = this.props;
    const transformedData = data.map((el, index) => {
      return {
        index: index + 1,
        name: el?.repo?.fullName + '/' + el?.name,
        success: el.successCount,
        fail: el.failureCount,
      };
    });

    return (
      <div className="execution-count-chart">
        <div className="chart-title"> Execution Count</div>
        <BarChart
          width={screenSize}
          height={232}
          data={transformedData}
          margin={{
            top: 3,
            right: 3,
            left: -38,
            bottom: 3,
          }}
        >
          <CartesianGrid horizontal={true} vertical={false} stroke="#2A3B50" />
          <XAxis
            dataKey="index"
            tick={{
              fill: '#788496',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '11x',
              lineHeight: '16px',
            }}
          ></XAxis>
          <YAxis
            axisLine={false}
            tick={{
              fill: '#788496',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '11x',
              lineHeight: '16px',
            }}
          />
          <Tooltip
            contentStyle={{ background: 'red', borderColor: '#161B22', borderRadius: '3px' }}
            cursor={{ fill: 'rgba(255, 255, 255, 0.12)' }}
            content={this.customTooltip}
          />

          <Bar onClick={this.handleTestExecTimeClick} name="success" dataKey="success" stackId="a">
            {transformedData.map((entry, index) => (
              <Cell
                key={index}
                stroke={'#2DCD8A'}
                fill={'#2DCD8A'}
                getStrokeWidth={0}
                fillOpacity={this.state.opacity[entry.name]}
                strokeOpacity={this.state.opacity[entry.name]}
                onMouseEnter={e => this.handleMouseEnter(e, entry.name)}
                onMouseLeave={e => this.handleMouseLeave(e, entry.name)}
              />
            ))}
          </Bar>

          <Bar onClick={this.handleTestExecTimeClick} name="fail" barSize={12} dataKey="fail" stackId="a">
            {transformedData.map((entry, index) => (
              <Cell
                key={index}
                stroke={'#e01212'}
                fill={'#e01212'}
                getStrokeWidth={0}
                fillOpacity={this.state.opacity[entry.name]}
                strokeOpacity={this.state.opacity[entry.name]}
                onMouseEnter={e => this.handleMouseEnter(e, entry.name)}
                onMouseLeave={e => this.handleMouseLeave(e, entry.name)}
              />
            ))}
          </Bar>
        </BarChart>
      </div>
    );
  }
}
