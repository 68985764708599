import React, { Component } from 'react';
import { JobsWidget } from './JobsWidget/JobsWidget';
import { StepsWidget } from './StepsWidget/StepsWidget';
import './TotalTimeSpendStepsWidget.scss';
export class TotalTimeSpendStepsWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idx: 0,
        };
    }

    handlerId = (val) => {
        this.setState({
            idx: val,
        });
    };

    render() {
        const { avgDurationTimeSeriesWidget, timeRange } = this.props;
        const { idx } = this.state;
        return (
            <div className='jobs-and-steps-duration-container'>
                <div className='jobs-and-steps-duration-header'>
                    Total time spend by steps
                </div>

                <div className="jobs-and-steps-duration-chart-cont">
                    <div className='steps-jobs-widget'>
                        <JobsWidget workflowRuns={avgDurationTimeSeriesWidget} handlerJobs={(val) => this.handlerId(val)} />
                    </div>
                    <div className='steps-jobs-chart'>
                        <StepsWidget data={avgDurationTimeSeriesWidget} idx={idx} timeRange={timeRange} />
                    </div>
                </div>
            </div>

        );
    }
}