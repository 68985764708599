import React, { Component } from 'react';
import { Table, Popup, Image, Modal, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { USER_ROLES_ENUM } from 'utils/user-roles-enum';
import { INVITED_USER_STATUS_ENUM } from 'utils/invited-user-status-enum';
import { LoadingDimmer } from 'components/ComponentPlaceholder';
import { isUserInInvitedUserRole } from 'utils/user-role-util';
import routeList, { computePath } from 'routes';
import './OrganizationList.scss';

import avatarOwnerLogo from 'assets/images/avatar-owner.svg';
import blueChecked from 'assets/images/blue-checked.svg';
import successCircle from 'assets/images/success-circle.png';
import { ForesightTable } from 'custom-components/Table/Table';
import {
  PageHeader,
  PageHeaderText,
  PageHeaderTitle,
  PageHeaderTitleContainer,
} from 'custom-components/PageHeader/PageHeader';
import { DOMAIN_ICON, UI_ICON } from 'assets/font-icons/IconManager';

class OrganizationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationName: '',
      isAreYouSureModalOpen: false,
      organizationId: undefined,
    };
  }

  componentDidMount() {
    this.props.getAllOrganizationList();
  }

  getOrganizationNameCell = (name, accountOwner) => {
    const orgNames = name ? name.split(' ') : undefined;

    return (
      <div className="organization-table-name-cell">
        {orgNames ? (
          <div className="user-letter-avatar">{`${orgNames[0][0].toUpperCase()}${
            orgNames[1] ? orgNames[1][0].toUpperCase() : ''
          }`}</div>
        ) : (
          <i className={`${UI_ICON.PROFILE.AVATAR} avatar-icon`} />
        )}
        <div className="text-cell">
          {orgNames ? name : 'No name'}
          {accountOwner && (
            <Popup
              className="owner-popup right"
              trigger={<Image className="avatar-owner-img" src={avatarOwnerLogo} fluid />}
              hideOnScroll={true}
              content="Owner"
              position="right center"
            />
          )}
        </div>
      </div>
    );
  };

  getAccountStatus = (isEnabled, isPending) => {
    if (isPending) {
      return <span className="pending-label">Pending</span>;
    }

    return (
      <div>
        {isEnabled ? (
          <div className="status-container">
            <Image className="checked-img" src={blueChecked} fluid />
            <span>Active</span>
          </div>
        ) : (
          <div className="status-container">
            <i className={DOMAIN_ICON.ACCOUNT_STATUS_INACTIVE} />
            <span>Inactive</span>
          </div>
        )}
      </div>
    );
  };

  handleOrganizationSettingsClick = () => {
    const organizationSettingsPath = computePath(routeList.teamOrganizationWithTab.path, { tabName: 'organization' });
    this.props.history.push(organizationSettingsPath);
  };

  onUserAccountSuccess = user => {
    console.log('getUserAccount success => ', user);
    this.props.history.push(routeList.repositories.path);
  };

  onUserAccountError = error => {
    console.log('getUserAccount error => ', error);
  };

  onSwitchOrganizationSuccess = () => {
    this.props.getUserAccount(this.onUserAccountSuccess, this.onUserAccountError);
  };

  onLeaveOrganizationSuccess = () => {
    this.props.getAllOrganizationList();
    toast.success(
      <div className="toast-image-wrapper">
        <Image src={successCircle} />
        You successfully left the organization
      </div>,
    );

    this.setState({
      isAreYouSureModalOpen: false,
    });

    this.props.getActiveOrganizationList();
  };

  acceptInvitation = invitationId => {
    this.props.acceptUserInvitation(invitationId, this.onAcceptInvitationSuccess);
  };

  onAcceptInvitationSuccess = () => {
    this.props.getAllOrganizationList();

    // Topbar should re-render
    this.props.getActiveOrganizationList();
  };

  renderOrganizationActionsPopup = organization => {
    if (!organization.invitedUser.enabled) {
      return (
        <div id="team-organization-dropdown-id" className="organization-action-dropdown">
          <div className="item item-first" onClick={() => this.acceptInvitation(organization.invitedUser.id)}>
            Accept invitation
          </div>
        </div>
      );
    }
    const organizationId = organization.organization.id;
    const isCurrentOrg = organization.invitedUser.active;
    const accountOwner = organization.invitedUser.accountOwner;
    return (
      <div id="team-organization-dropdown-id" className="organization-action-dropdown">
        {!isUserInInvitedUserRole(organization.invitedUser.role) && (
          <div
            className={`item item-first ${isCurrentOrg ? '' : 'disabled'}`}
            onClick={this.handleOrganizationSettingsClick}
          >
            Settings
          </div>
        )}
        <div
          className={`item ${isCurrentOrg ? 'disabled' : ''}`}
          onClick={() => this.props.switchOrganization(organizationId, this.onSwitchOrganizationSuccess)}
        >
          Switch to organization
        </div>
        {!accountOwner && (
          <>
            <div className="item-delimiter" />
            <div className="item item-last" onClick={() => this.leaveOrganization(organizationId)}>
              Leave organization
            </div>
          </>
        )}
      </div>
    );
  };

  leaveOrganization = organizationId => {
    this.setState({
      isAreYouSureModalOpen: true,
      organizationId,
    });
  };

  getAreYouSureModal = () => {
    const { isAreYouSureModalOpen } = this.state;
    const { leaveFromOrganizationFetching } = this.props.organization;

    return (
      <Modal className="leave-organization-modal" open={isAreYouSureModalOpen}>
        <div className="title-section">
          <i className={UI_ICON.ALERT.WARNING} />
          <div className="title">You are leaving the organization</div>
        </div>

        <div className="description">
          You will not be able to rejoin until the organization admin invites you again. You will lose access to all the
          data related to this data.
        </div>
        <div className="delete-actions">
          <Button
            loading={leaveFromOrganizationFetching}
            disabled={leaveFromOrganizationFetching}
            className="cancel-button"
            onClick={this.cancelLeaveOrganization}
          >
            Cancel
          </Button>
          <Button
            loading={leaveFromOrganizationFetching}
            disabled={leaveFromOrganizationFetching}
            primary
            onClick={() => this.onLeaveOrganization()}
          >
            Leave organization
          </Button>
        </div>
      </Modal>
    );
  };

  cancelLeaveOrganization = () => {
    this.setState({ isAreYouSureModalOpen: false, organizationId: undefined });
  };

  onLeaveOrganization = () => {
    const { organizationId } = this.state;

    this.props.leaveOrganization(organizationId, this.onLeaveOrganizationSuccess);
  };

  getOrganizationTable = () => {
    const { organization } = this.props;

    if (organization.fetching) {
      return (
        <div style={{ marginTop: 10 }}>
          <LoadingDimmer msg={'Loading...'} />
        </div>
      );
    }

    return (
      <ForesightTable basic="very">
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell width={1} />
            <Table.HeaderCell width={6}>Organization Name</Table.HeaderCell>
            <Table.HeaderCell width={6}>My role</Table.HeaderCell>
            <Table.HeaderCell width={2}>Account Status</Table.HeaderCell>
            <Table.HeaderCell width={1} />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {organization.userAllOrganizationList &&
            organization.userAllOrganizationList.map((org, index) => {
              const organizationId = org.organization.id;
              return (
                <Table.Row key={organizationId} className="table-row">
                  <Table.Cell className="current-org">
                    <Popup
                      className="owner-popup bottom"
                      trigger={
                        <i
                          id={index}
                          className={
                            org.invitedUser.active
                              ? `${DOMAIN_ICON.SELECTED_ORG} active-organization-icon`
                              : DOMAIN_ICON.SELECTED_ORG
                          }
                          onClick={() =>
                            this.props.switchOrganization(organizationId, this.onSwitchOrganizationSuccess)
                          }
                        />
                      }
                      hideOnScroll={true}
                      content={org.invitedUser.active ? 'Current organization' : 'Swicth to organization'}
                      position="center bottom"
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {this.getOrganizationNameCell(org.organization.organizationName, org.invitedUser.accountOwner)}
                  </Table.Cell>
                  <Table.Cell>{USER_ROLES_ENUM[org.invitedUser.role]}</Table.Cell>
                  <Table.Cell>
                    {this.getAccountStatus(
                      org.invitedUser.enabled,
                      INVITED_USER_STATUS_ENUM[org.invitedUser.status] === INVITED_USER_STATUS_ENUM['PENDING'],
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Popup
                      id="organization-dropdown-parent"
                      trigger={
                        <div className="icons-wrapper">
                          <i className={UI_ICON.MORE.VERTICAL} />
                          <i className={UI_ICON.MORE.VERTICAL_WHITE} />
                        </div>
                      }
                      basic
                      hideOnScroll={true}
                      on="click"
                      content={this.renderOrganizationActionsPopup(org)}
                      position="bottom left"
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </ForesightTable>
    );
  };

  render() {
    return (
      <div className="organization-lists-wrapper">
        {this.getAreYouSureModal()}

        <PageHeader>
          <PageHeaderTitleContainer>
            <PageHeaderTitle>My Organizations</PageHeaderTitle>
          </PageHeaderTitleContainer>

          <PageHeaderText>
            Here are your organizations. You can see your role in your organizations and manage your organization.
          </PageHeaderText>
        </PageHeader>

        {this.getOrganizationTable()}
      </div>
    );
  }
}

export default OrganizationList;
