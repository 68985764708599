import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { hasArrayElement } from './array-util';

export function copyToClipBoard(text) {
  navigator.clipboard.writeText(text);
}

// https://lodash.com/docs/4.17.15#isEqual
export function isEqualDeep(value, other) {
  return isEqual(value, other);
}

export function cloneArray(arr) {
  return cloneDeep(arr);
}

export function checkEmailValidation(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
}

export function checkURLisValid(url) {
  try {
    new URL(url);
    return url;
  } catch (error) {
    return false;
  }
}

export function isInPricingWhiteList(whiteList) {
  if (hasArrayElement(whiteList)) {
    return whiteList.some(item => item?.type === 'PRICING');
  }
  return false;
}
