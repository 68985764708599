import React, { Component } from 'react';
import { getTestRunOverviewObj, getTestSuite } from 'utils/test-run-detail-util';
import { getTestCaseOverviewDetail } from 'utils/test-detail-util';
import './TestRunOverviewSummaryCard.scss';
import { calcDisplayedTime, timeDiffAsMillis } from 'utils/date-util';
import { DOMAIN_ICON } from 'assets/font-icons/IconManager';

class TestRunOverviewSummaryCard extends Component {
  getTestOverviewData = props => {
    const { transactionId } = props.match.params;
    const { testDetailMap } = props.testDetails;
    return getTestCaseOverviewDetail(testDetailMap, transactionId);
  };

  //==================================================
  // TEST RUN
  //===================================================
  renderTestRunOverview = () => {
    const { testRunId } = this.props.match.params;
    const { testRunDetailMap } = this.props.testRunDetails;

    const testRunOverviewObj = getTestRunOverviewObj(testRunDetailMap, testRunId);
    const overview = testRunOverviewObj.overview || {};
    const { environment, branch, failedCount, ignoredCount, abortedCount, successfulCount, duration } = overview;

    return (
      <div className="table-and-title-container">
        <table className="stats-table">
          <tbody>
            <tr>
              <td style={{ width: '25%' }}>
                <div className="block">
                  <div className="value">
                    <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.PASSED} />
                    {successfulCount}
                  </div>
                  <div className="title">Successful</div>
                </div>
              </td>
              <td style={{ width: '25%' }}>
                <div className="block">
                  <div className="value">
                    <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.FAILED} />
                    {failedCount}
                  </div>
                  <div className="title">Failed</div>
                </div>
              </td>

              <td style={{ width: '25%' }}>
                <div className="block">
                  <div className="value">
                    <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.SKIPPED} />
                    {ignoredCount}
                  </div>
                  <div className="title">Skipped</div>
                </div>
              </td>
              <td style={{ width: '25%' }}>
                <div className="block">
                  <div className="value">
                    <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.ABORTED} />
                    {abortedCount}
                  </div>
                  <div className="title">Aborted</div>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ width: '25%' }}>
                <div className="block">
                  <div className="value">
                    <i className="icon-elapsed-time" />
                    {calcDisplayedTime(duration)}
                  </div>
                  <div className="title">Execution Time</div>
                </div>
              </td>
              <td style={{ width: '25%' }}>
                <div className="block">
                  <div className="value">
                    <i className={DOMAIN_ICON.LOGOS.GITHUB_LOGO} />
                    {environment}
                  </div>
                  <div className="title">Environment</div>
                </div>
              </td>
              <td colSpan={2} style={{ width: '50%' }}>
                <div className="block">
                  <div className="value">
                    <i className={DOMAIN_ICON.GITHUB.BRANCHES} />
                    {branch}
                  </div>
                  <div className="title">Branch</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  //==================================================
  // TEST SUIT
  //===================================================

  renderTestSuitOverview = () => {
    const { testRunId, testSuiteName } = this.props.match.params;
    const { testRunDetailMap } = this.props.testRunDetails;
    const testSuite = getTestSuite(testRunDetailMap, testRunId, testSuiteName);
    const tags = (testSuite && testSuite.tags) || {};
    const duration = (testSuite && testSuite.duration) || 0;

    const afterAllDuration = tags['test.after.all.duration'] || 0;
    const beforeAllDuration = tags['test.before.all.duration'] || 0;

    const failedCount = tags['test.suite.failed.count'] || 0;
    const skippedCount = tags['test.suite.skipped.count'] || 0;
    const abortedCount = tags['test.suite.aborted.count'] || 0;
    const successfulCount = tags['test.suite.successful.count'] || 0;

    let execTime = duration;
    let bodyTime = duration - (parseInt(beforeAllDuration) + parseInt(afterAllDuration));

    return (
      <div className="table-and-title-container">
        <table className="stats-table">
          <tr>
            <td>
              <div className="block">
                <div className="value">
                  <i className="icon-elapsed-time" />
                  {calcDisplayedTime(execTime)}
                </div>
                <div className="title">Execution Time</div>
              </div>
            </td>
            <td>
              <div className="block">
                <div className="value">
                  <i className="icon-elapsed-time" />
                  {calcDisplayedTime(bodyTime)}
                </div>
                <div className="title">Body</div>
              </div>
            </td>
            <td>
              <div className="block">
                <div className="value">
                  <i className="icon-elapsed-time" />
                  {calcDisplayedTime(beforeAllDuration)}
                </div>
                <div className="title">BeforeAll</div>
              </div>
            </td>
            <td>
              <div className="block">
                <div className="value">
                  <i className="icon-elapsed-time" />
                  {calcDisplayedTime(afterAllDuration)}
                </div>
                <div className="title">AfterAll</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="block">
                <div className="value">
                  <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.PASSED} />
                  {successfulCount}
                </div>
                <div className="title">Successful</div>
              </div>
            </td>
            <td>
              <div className="block">
                <div className="value">
                  <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.FAILED} />
                  {failedCount}
                </div>
                <div className="title">Failed</div>
              </div>
            </td>
            <td>
              <div className="block">
                <div className="value">
                  <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.SKIPPED} />
                  {skippedCount}
                </div>
                <div className="title">Skipped</div>
              </div>
            </td>
            <td>
              <div className="block">
                <div className="value">
                  <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.ABORTED} />
                  {abortedCount}
                </div>
                <div className="title">Aborted</div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  };

  //==================================================
  // TEST CASE
  //===================================================

  renderTestCaseOverview = () => {
    const testOverviewObj = this.getTestOverviewData(this.props);

    let execTime = '';
    let body = '';
    let beforeAllDuration = '';
    let afterAllDuration = '';

    //TestOverviewOK
    if (testOverviewObj && testOverviewObj.fetched) {
      const { startTime, endTime, duration, before, after } = testOverviewObj.testOverview;
      beforeAllDuration = before;
      afterAllDuration = after;
      const iBefore = isNaN(before) ? 0 : before;
      const iAfter = isNaN(after) ? 0 : after;
      execTime = duration;
      body = timeDiffAsMillis(endTime, startTime) - (iBefore + iAfter);
    }

    return (
      <div className="table-and-title-container">
        <table className="stats-table one-row">
          <tr>
            <td>
              <div className="block">
                <div className="value">
                  <i className="icon-elapsed-time" />
                  {calcDisplayedTime(execTime)}
                </div>
                <div className="title">Execution Time</div>
              </div>
            </td>
            <td>
              <div className="block">
                <div className="value">
                  <i className="icon-elapsed-time" />
                  {calcDisplayedTime(body)}
                </div>
                <div className="title">Body</div>
              </div>
            </td>
            <td>
              <div className="block">
                <div className="value">
                  <i className="icon-elapsed-time" />
                  {calcDisplayedTime(beforeAllDuration)}
                </div>
                <div className="title">BeforeEach</div>
              </div>
            </td>
            <td>
              <div className="block">
                <div className="value">
                  <i className="icon-elapsed-time" />
                  {calcDisplayedTime(afterAllDuration)}
                </div>
                <div className="title">AfterEach</div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  };

  render() {
    const { title, type } = this.props;
    let vDOM = '';

    //Run Summary
    if (type === 'TestRunOverview') {
      vDOM = this.renderTestRunOverview();
    }

    //Suit Summary
    if (type === 'TestSuitOverview') {
      vDOM = this.renderTestSuitOverview();
    }

    //Test Summary
    if (type === 'TestCaseOverview') {
      vDOM = this.renderTestCaseOverview();
    }

    return (
      <div className="summary-container">
        <div className="title">{title}</div>
        <div className="content">{vDOM}</div>
      </div>
    );
  }
}

export default TestRunOverviewSummaryCard;
