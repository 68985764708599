import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { GET_INVOICES } from 'store/constants/billing-invoices';

export const getInvoices = organizationId => {
  return {
    type: GET_INVOICES,
    payload: axios.get(CONFIG.apiUrl + `/organizations/${organizationId}/invoices`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
