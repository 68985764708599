import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
import React from 'react';

export function getTestCaseStatusIcon(status, isFilled = false) {
  let iDOM = '';
  if (!isFilled) {
    switch (status) {
      case 'SUCCESSFUL':
        iDOM = <i className={'successful' + DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.SUCCESS} />;
        break;

      case 'FAILED':
        iDOM = <i className={'failed' + DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.FAILED} />;
        break;

      case 'ABORTED':
        iDOM = <i className={'aborted' + DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.ABORTED} />;
        break;

      case 'SKIPPED':
        iDOM = <i className={'skipped' + DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.SKIPPED} />;
        break;
      default:
        break;
    }
  } else {
    switch (status) {
      case 'SUCCESSFUL':
        iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.SUCCESS} />;
        break;

      case 'FAILED':
        iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.FAILED} />;
        break;

      case 'ABORTED':
        iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.ABORTED} />;
        break;

      case 'SKIPPED':
        iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.SKIPPED} />;
        break;
      default:
        break;
    }
  }

  return iDOM;
}
