export const WORKFLOWRUN_CONCLUSION = {
	ACTION_REQUIRED: 'ACTION_REQUIRED',
	CANCELLED: 'CANCELLED',
	FAILURE: 'FAILURE',
	NEUTRAL: 'NEUTRAL',
	SUCCESS: 'SUCCESS',
	SKIPPED: 'SKIPPED',
	STALE: 'STALE',
	TIMED_OUT: 'TIMED_OUT',
	UNKNOWN: 'UNKNOWN',
};
