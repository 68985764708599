import { getStringColor } from 'utils/color-util';
import { getRandomInt } from 'utils/random-util';
import moment from 'moment';
import { toFixed } from 'utils/math';

export const addLine = (charData, chartConfig, dataKey, name, max) => {
	chartConfig.dataProps.push({ dataKey, name, color: getStringColor(name) });
	charData.forEach(el => {
		el[dataKey] = getRandomInt(0, max);
	});
};

const removeLine = (charData, chartConfig, dataKey) => {
	const index = chartConfig.dataProps.findIndex(el => el.dataKey === dataKey);
	if (index >= 0) {
		chartConfig.dataProps.splice(index, 1);
	}

	charData.forEach(el => {
		delete el[dataKey];
	});
};

export const LineChartSampleConfig = {
	sizeProps: {
		width: 600,
		height: 350,
	},
	dataProps: [],
	yAxisProps: {
		domain: [0, 100],
		tickFormatter: val => toFixed(val, 0),
	},
	xAxisProps: {
		dataKey: 'time',
		tickFormatter: val => {
			return moment(val, 'YYYY-MM-DD HH:mm:ss.sss Z').local().format('HH:mm');
		},
	},
};

const lineChartSample = [
	{
		time: '2021-10-15 03:46:00.000 +0000',
	},
	{
		time: '2021-10-16 03:48:00.000 +0000',
	},
	{
		time: '2021-10-17 03:50:00.000 +0000',
	},
	{
		time: '2021-10-18 03:52:00.000 +0000',
	},
	{
		time: '2021-10-19 03:54:00.000 +0000',
	},
	{
		time: '2021-10-20 03:56:00.000 +0000',
	},
	{
		time: '2021-10-21 03:58:00.000 +0000',
	},
	{
		time: '2021-10-22 04:00:00.000 +0000',
	},
	{
		time: '2021-10-23 04:02:00.000 +0000',
	},
	{
		time: '2021-10-24 04:04:00.000 +0000',
	},
	{
		time: '2021-10-25 04:06:00.000 +0000',
	},
	{
		time: '2021-10-26 04:08:00.000 +0000',
	},
	{
		time: '2021-10-27 04:10:00.000 +0000',
	},
	{
		time: '2021-10-28 04:12:00.000 +0000',
	},
	{
		time: '2021-10-29 04:14:00.000 +0000',
	},
];

export const LineChartSampleActions = {
	addLine,
	removeLine,
};

export const getLineChartSample = (id, name, max) => {
	const cloneData = JSON.parse(JSON.stringify(lineChartSample));
	const cloneConfig = { ...LineChartSampleConfig, dataProps: [] };

	addLine(cloneData, cloneConfig, `${id}1`, `${name} 1`, max);
	addLine(cloneData, cloneConfig, `${id}2`, `${name} 2`, max);

	return {
		chartData: cloneData,
		chartConfig: cloneConfig,
	};
};
