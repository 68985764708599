import React, { Component } from 'react';
import { formatDateShortMonthNoYearWithTime } from 'utils/date-util';
import {
  getCiProviderBranchLink,
  getCiProviderCommitLink,
  getCiProviderPullRequestLink,
} from 'utils/git-provider-config-links';
import {
  OverviewInfoCell,
  OverviewInfoContainer,
  OverviewInfoLinkCell,
  OverviewInfoRow,
  OverviewInfoValue,
} from 'custom-components/Overview/OverviewInfo';
import { DOMAIN_ICON } from 'assets/font-icons/IconManager';

export class RepositoryWorkflowOverviewInfo extends Component {
  renderWorkflowRunOverviewInfo = () => {
    const { workflowRunOverview } = this.props.workflowRuns;

    const pullRequests = workflowRunOverview.pullRequests || [];
    const pullRequestNumbers = pullRequests.map(el => `#${el.number}`);
    const strPullRequestNumbers = pullRequestNumbers.join(',') || 'N/A';

    return (
      <OverviewInfoContainer>
        <OverviewInfoRow>
          <OverviewInfoCell>
            <OverviewInfoValue
              subTitle="Date"
              styleClassName="date"
              iconName={DOMAIN_ICON.GITHUB.EXEC_DATE}
              tooltipContent={formatDateShortMonthNoYearWithTime(workflowRunOverview.createdAt)}
              value={formatDateShortMonthNoYearWithTime(workflowRunOverview.createdAt)}
            />
          </OverviewInfoCell>
          <OverviewInfoCell>
            <OverviewInfoValue
              subTitle="By"
              iconName={DOMAIN_ICON.GITHUB.PR_OWNER}
              tooltipContent={workflowRunOverview.headCommit?.author?.name}
              value={workflowRunOverview.headCommit?.author?.name}
            />
          </OverviewInfoCell>
          <OverviewInfoLinkCell styleClassName="cursor" to={{ pathname: getCiProviderBranchLink(workflowRunOverview) }}>
            <OverviewInfoValue
              subTitle="Branch"
              iconName={DOMAIN_ICON.GITHUB.BRANCHES}
              tooltipContent={workflowRunOverview.headBranch}
              value={workflowRunOverview.headBranch}
            />
          </OverviewInfoLinkCell>
          <OverviewInfoCell>
            <OverviewInfoValue
              subTitle="Event"
              iconName={DOMAIN_ICON.GITHUB.EVENT}
              tooltipContent={workflowRunOverview.event}
              value={workflowRunOverview.event}
            />
          </OverviewInfoCell>
          <OverviewInfoLinkCell styleClassName="cursor" to={{ pathname: getCiProviderCommitLink(workflowRunOverview) }}>
            <OverviewInfoValue
              subTitle="Commit"
              iconName={DOMAIN_ICON.GITHUB.COMMIT}
              tooltipContent={workflowRunOverview.headCommit?.id?.slice(0, 7)}
              value={workflowRunOverview.headCommit?.id?.slice(0, 7)}
            />
          </OverviewInfoLinkCell>
          <OverviewInfoLinkCell
            styleClassName={pullRequests.length > 0 ? 'cursor' : 'no-link'}
            to={{ pathname: getCiProviderPullRequestLink(workflowRunOverview) }}
          >
            <OverviewInfoValue
              subTitle="Pull Request"
              iconName={DOMAIN_ICON.GITHUB.PULL_REQUEST}
              tooltipContent={strPullRequestNumbers}
              value={strPullRequestNumbers}
            />
          </OverviewInfoLinkCell>
        </OverviewInfoRow>
      </OverviewInfoContainer>
    );
  };

  render() {
    return this.renderWorkflowRunOverviewInfo();
  }
}
