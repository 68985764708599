import { RESEND_USER_INVITATION, ACCEPT_USER_INVITATION } from 'store/constants/invitation';
import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';

export function resendUserInvitation(invitationId) {
  return {
    type: RESEND_USER_INVITATION,
    payload: axios.post(
      CONFIG.apiUrl + `/invitations/${invitationId}/resend`,
      {},
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function acceptUserInvitation(invitationId) {
  return {
    type: ACCEPT_USER_INVITATION,
    payload: axios.post(
      CONFIG.apiUrl + `/invitations/${invitationId}/complete`,
      {},
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}
