import {
  GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS_FULFILLED,
  GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS_PENDING,
  GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS_REJECTED,
  GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_FULFILLED,
  GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_PENDING,
  GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_REJECTED,
  GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY_FULFILLED,
  GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY_PENDING,
  GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY_REJECTED,
  SET_TEST_SUITES_OVERVIEW_DETAIL_FILTER_OBJECT,
  SET_TEST_SUITES_OVERVIEW_DETAIL_SELECTED_HIDE_FORK,
} from 'store/constants/test-suites-overview-details';

const initialState = {
  testSuitesOverviewDetailFetching: false,
  testSuitesOverviewDetailFetched: false,
  testSuitesOverviewDetailError: null,

  testSuitesOverviewDetailFilterFetching: false,
  testSuitesOverviewDetailFilterFetched: false,
  testSuitesOverviewDetailFilterError: null,

  testSuitesOverviewDetailSummaryFetching: false,
  testSuitesOverviewDetailSummaryFetched: false,
  testSuitesOverviewDetailSummaryError: null,

  filterContent: [],
  content: [],
  summaryContent: [],
  selectedFilterObject: [],
  isFetchingByInfiniteTable: false,
  lastPage: false,
  hideForkBranch: false,
  page: 0,
};

export function testSuitesOverviewDetail(state = initialState, action) {
  switch (action.type) {
    case GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_PENDING:
      return {
        ...state,
        testSuitesOverviewDetailFetching: true,
        testSuitesOverviewDetailFetched: false,
        testSuitesOverviewDetailError: null,
        isFetchingByInfiniteTable: true,
        lastPage: false,
      };
    case GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_FULFILLED: {
      const testSuitesDetailNewData = { ...action.payload.data };

      if (action.payload.data.page > 0) {
        testSuitesDetailNewData.content = [...(state.content || []), ...action.payload.data.content];
      }

      return {
        ...state,
        testSuitesOverviewDetailFetching: false,
        testSuitesOverviewDetailFetched: true,
        testSuitesOverviewDetailError: null,
        lastPage: action.payload.data.lastPage,
        page: action.payload.data.page,
        content: testSuitesDetailNewData.content,
      };
    }
    case GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_REJECTED:
      return {
        ...state,
        content: [],
        testSuitesOverviewDetailFetching: false,
        testSuitesOverviewDetailFetched: false,
        testSuitesOverviewDetailError: action.payload,
        page: 0,
        lastPage: false,
      };

    case GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS_PENDING:
      return {
        ...state,
        testSuitesOverviewDetailFilterFetching: true,
        testSuitesOverviewDetailFilterFetched: false,
        testSuitesOverviewDetailFilterError: null,
      };
    case GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS_FULFILLED:
      return {
        ...state,
        testSuitesOverviewDetailFilterFetching: false,
        testSuitesOverviewDetailFilterFetched: true,
        testSuitesOverviewDetailFilterError: null,
        filterContent: action.payload.data,
      };
    case GET_TEST_SUITES_OVERVIEW_DETAIL_LIST_BY_FILTERS_REJECTED:
      return {
        ...state,
        testSuitesOverviewDetailFilterFetching: false,
        testSuitesOverviewDetailFilterFetched: false,
        testSuitesOverviewDetailFilterError: action.payload,
      };

    case GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY_PENDING:
      return {
        ...state,
        testSuitesOverviewDetailSummaryFetching: true,
        testSuitesOverviewDetailSummaryFetched: false,
        testSuitesOverviewDetailSummaryError: null,
      };
    case GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY_FULFILLED:
      return {
        ...state,
        testSuitesOverviewDetailSummaryFetching: false,
        testSuitesOverviewDetailSummaryFetched: true,
        testSuitesOverviewDetailSummaryError: null,
        summaryContent: action.payload.data,
      };
    case GET_TEST_SUITES_OVERVIEW_DETAIL_SUMMARY_REJECTED:
      return {
        ...state,
        testSuitesOverviewDetailSummaryFetching: false,
        testSuitesOverviewDetailSummaryFetched: false,
        testSuitesOverviewDetailSummaryError: action.payload,
      };

    case SET_TEST_SUITES_OVERVIEW_DETAIL_SELECTED_HIDE_FORK:
      return {
        ...state,
        hideForkBranch: action.payload.data,
      };
    case SET_TEST_SUITES_OVERVIEW_DETAIL_FILTER_OBJECT:
      return {
        ...state,
        selectedFilterObject: action.payload.data,
      };

    default:
      return state;
  }
}
