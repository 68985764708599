/* eslint-disable */

import { clipTestSuiteName, getTestRunOverviewObj } from './test-run-detail-util';
import { getTestCaseOverviewDetail } from './test-detail-util';

//================COMMON FUNCTIONS

export const getProjectName = (props, projectId) => {
  if (props.projects.fetched) {
    const { projectList } = props.projects;
    const projectData = projectList.find(el => el.id === projectId);
    if (projectData && projectData.projectName) {
      return projectData.projectName;
    }
  }
  return '';
};

const getRunOverview = props => {
  const { testRunId } = props.match.params;
  const { testRunDetailMap } = props.testRunDetails;
  const testRunOverviewObj = getTestRunOverviewObj(testRunDetailMap, testRunId);
  return testRunOverviewObj.overview || {};
};

const getTestOverview = props => {
  const { transactionId } = props.match.params;
  const { testDetailMap } = props.testDetails;
  return getTestCaseOverviewDetail(testDetailMap, transactionId);
};

//=================DEFINE FUNCTIONS
export function defineRepoNameToPageTitle(props, titleTemplate) {
  const { repoName } = props.match.params;
  return titleTemplate.replace('${repoName}', repoName);
}

export function definePullRequestToPageTitle(titleTemplate, prName, tabName, repoName) {
  return titleTemplate.replace('${prName}', prName).replace('${tabName}', tabName).replace('${repoName}', repoName);
}

export function defineWorkflowRunOverviewPageTitle(commitName, workflowRunName, titleTemplate) {
  return titleTemplate.replace('${commitName}', commitName).replace('${workflowRunName}', workflowRunName);
}

export function defineTestRunsPageTitle(props, titleTemplate, projectId) {
  return titleTemplate.replace('${projectName}', getProjectName(props, projectId));
}

export function defineTestRunOverviewPageTitle(props, titleTemplate) {
  const overview = getRunOverview(props);
  if (overview) {
    const { commitMessage, repo } = overview;
    return titleTemplate.replace('${repoName}', repo?.name).replace('${testRunName}', commitMessage);
  }
  return titleTemplate.replace('${repoName}', '').replace('${testRunName}', '');
}

export function defineTestRunSuiteOverviewPageTitle(props, titleTemplate) {
  const { testSuiteName } = props.match.params;

  const overview = getRunOverview(props);
  if (overview) {
    const { repo } = overview;
    return titleTemplate
      .replace('${repoName}', repo?.name)
      .replace('${testSuiteName}', clipTestSuiteName(testSuiteName));
  }
  return titleTemplate.replace('${repoName}', '').replace('${testSuiteName}', testSuiteName);
}

export function defineTestCasePageTitle(props, titleTemplate) {
  let repoName = '';
  let testName = '';

  const overview = getRunOverview(props);
  if (overview) {
    repoName = overview.repo?.name;
  }

  const testOverviewObj = getTestOverview(props);
  if (testOverviewObj && testOverviewObj.testOverview) {
    testName = testOverviewObj.testOverview.testName || '';
  }

  return titleTemplate.replace('${repoName}', repoName).replace('${testName}', testName);
}
