//TODO Can be Deleted IF Logs Design only display ErrorViewer
import { UI_ICON } from 'assets/font-icons/IconManager';
import { Tooltip } from 'components/Tooltip';
import React, { Component } from 'react';
import { Popup } from 'semantic-ui-react';
import './ExtraInfoTooltip.scss';

export function InfoTooltipContent(props) {
  return (
    <div className="info-tooltip-container">
      <div className="info-tooltip-title">{props.title}</div>
      <div>{props.desc}</div>
    </div>
  );
}

export function InfoTooltip(props) {
  return (
    <Tooltip blackEdition content={props?.content}>
      <i className={UI_ICON.ALERT.INFO} />
    </Tooltip>
  );
}

export class ExtraInfoTooltipWrapComponent extends Component {
  render() {
    return (
      <Popup
        className="wrapper-extra-info-popup"
        trigger={this.props.children} // we use custom icons so we use className here instead of name prop.
        content={this.props.tooltipText}
        inverted
        basic
        position="bottom center"
        hoverable
      />
    );
  }
}
