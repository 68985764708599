import { OverviewTitleContainer } from 'custom-components/Overview/OverviewTitle';
import React, { Component } from 'react';
import { getWorkflowStatusIcon } from 'utils/workflow-status-icon';
import { getWorkflowStatusTooltipContent } from 'utils/workflowrun-conclusion-status-tooltips';

export class RepositoryWorkflowTitle extends Component {
  renderWorkflowRunOverviewTitle = () => {
    const { workflowRunOverview } = this.props.workflowRuns;
    const { displayTitle, headCommit } = workflowRunOverview;
    const title = displayTitle || headCommit?.message;

    return (
      <OverviewTitleContainer
        statusTooltipContent={getWorkflowStatusTooltipContent(workflowRunOverview)}
        status={getWorkflowStatusIcon(workflowRunOverview)}
        titleTooltipContent={title}
        title={headCommit?.message}
      />
    );
  };

  render() {
    return this.renderWorkflowRunOverviewTitle();
  }
}
