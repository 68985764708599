import {
	GET_TEST_HISTORY_FULFILLED,
	GET_TEST_HISTORY_PENDING,
	GET_TEST_HISTORY_REJECTED,
	GET_TEST_LOGS_FULFILLED,
	GET_TEST_LOGS_PENDING,
	GET_TEST_LOGS_REJECTED,
	GET_TEST_OVERVIEW_FULFILLED,
	GET_TEST_OVERVIEW_PENDING,
	GET_TEST_OVERVIEW_REJECTED,
	GET_TEST_SCREENSHOTS_FULFILLED,
	GET_TEST_SCREENSHOTS_PENDING,
	GET_TEST_SCREENSHOTS_REJECTED,
} from 'store/constants/test-details';

const initialState = {
	testDetailMap: {},
};

export default function testRunDetails(state = initialState, action) {
	const { transactionId } = action.meta || { transactionId: undefined };
	switch (action.type) {
	case GET_TEST_OVERVIEW_PENDING:
		return setTestCaseOverview(state, transactionId, {
			fetching: true,
			fetched: false,
			error: null,
			errorTime: -1,
			testOverview: {},
		});
	case GET_TEST_OVERVIEW_FULFILLED:
		return setTestCaseOverview(state, transactionId, {
			fetching: false,
			fetched: true,
			error: null,
			errorTime: -1,
			testOverview: action.payload.data,
		});
	case GET_TEST_OVERVIEW_REJECTED:
		return setTestCaseOverview(state, transactionId, {
			fetching: false,
			fetched: false,
			error: action.payload,
			errorTime: new Date().getTime(),
			testOverview: {},
		});

	case GET_TEST_HISTORY_PENDING:
		return setTestCaseHistory(state, transactionId, {
			fetchingTime: new Date().getTime(),
			fetching: true,
			fetched: false,
			error: null,
			errorTime: -1,
			testHistory: {},
		});
	case GET_TEST_HISTORY_FULFILLED:
		return setTestCaseHistory(state, transactionId, {
			fetching: false,
			fetched: true,
			error: null,
			testHistory: action.payload.data,
		});
	case GET_TEST_HISTORY_REJECTED:
		return setTestCaseHistory(state, transactionId, {
			fetching: false,
			fetched: false,
			error: action.payload,
			errorTime: new Date().getTime(),
			testHistory: {},
		});

	case GET_TEST_LOGS_PENDING:
		return setTestCaseLogs(state, transactionId, {
			fetching: true,
			fetched: false,
			error: null,
			errorTime: -1,
			testLogs: [],
		});
	case GET_TEST_LOGS_FULFILLED:
		return setTestCaseLogs(state, transactionId, {
			fetching: false,
			fetched: true,
			error: null,
			errorTime: -1,
			testLogs: action.payload.data,
		});
	case GET_TEST_LOGS_REJECTED:
		return setTestCaseLogs(state, transactionId, {
			fetching: false,
			fetched: false,
			error: action.payload,
			errorTime: new Date().getTime(),
			testLogs: [],
		});

	case GET_TEST_SCREENSHOTS_PENDING:
		return setTestCaseScreenshots(state, transactionId, {
			fetching: true,
			fetched: false,
			error: null,
			errorTime: -1,
			testScreenshots: [],
		});
	case GET_TEST_SCREENSHOTS_FULFILLED:
		return setTestCaseScreenshots(state, transactionId, {
			fetching: false,
			fetched: true,
			error: null,
			errorTime: -1,
			testScreenshots: action.payload.data,
		});
	case GET_TEST_SCREENSHOTS_REJECTED:
		return setTestCaseScreenshots(state, transactionId, {
			fetching: false,
			fetched: false,
			error: action.payload,
			errorTime: new Date().getTime(),
			testScreenshots: [],
		});

	default:
		return state;
	}
}

function setTestCaseOverview(state, transactionId, testOverviewObj) {
	const testDetail = getTestRunDetail(state, transactionId);
	testDetail.testOverviewObj = testOverviewObj;
	return { testDetailMap: state.testDetailMap };
}

function setTestCaseHistory(state, transactionId, testHistoryObj) {
	const testDetail = getTestRunDetail(state, transactionId);
	testDetail.testHistoryObj = testHistoryObj;
	return { testDetailMap: state.testDetailMap };
}

function setTestCaseLogs(state, transactionId, testLogsObj) {
	const testDetail = getTestRunDetail(state, transactionId);
	testDetail.testLogsObj = testLogsObj;
	return { testDetailMap: state.testDetailMap };
}

function setTestCaseScreenshots(state, transactionId, testScreenshotsObj) {
	const testDetail = getTestRunDetail(state, transactionId);
	testDetail.testScreenshotsObj = testScreenshotsObj;
	return { testDetailMap: state.testDetailMap };
}

function getTestRunDetail(state, transactionId) {
	const { testDetailMap } = state;

	//Check Is TestRun Exist
	if (!testDetailMap[transactionId]) {
		const testRunDetail = {
			transactionId: transactionId,
			testOverviewObj: {
				fetching: false,
				fetched: false,
				error: null,
				errorTime: -1,
				testOverview: {},
			},
			testHistoryObj: {
				fetching: false,
				fetched: false,
				error: null,
				errorTime: -1,
				testHistory: {},
			},
			testLogsObj: {
				fetching: false,
				fetched: false,
				error: null,
				errorTime: -1,
				testLogs: [],
			},
			testScreenshotsObj: {
				fetching: false,
				fetched: false,
				error: null,
				errorTime: -1,
				testScreenshots: [],
			},
		};
		testDetailMap[transactionId] = testRunDetail;
	}
	return testDetailMap[transactionId];
}

