import {
	GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS_FULFILLED,
	GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS_PENDING,
	GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS_REJECTED,
	GET_COST_TIME_WORKFLOW_RUNS_FULFILLED,
	GET_COST_TIME_WORKFLOW_RUNS_PENDING,
	GET_COST_TIME_WORKFLOW_RUNS_REJECTED,
	GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS_FULFILLED,
	GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS_PENDING,
	GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS_REJECTED,
	GET_DURATION_SUMMARY_WORKFLOW_RUNS_FULFILLED,
	GET_DURATION_SUMMARY_WORKFLOW_RUNS_PENDING,
	GET_DURATION_SUMMARY_WORKFLOW_RUNS_REJECTED,
	GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS_FULFILLED,
	GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS_PENDING,
	GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS_REJECTED,
	GET_JOBS_WORKFLOW_RUNS_FULFILLED,
	GET_JOBS_WORKFLOW_RUNS_PENDING,
	GET_JOBS_WORKFLOW_RUNS_REJECTED,
	GET_LATEST_TEN_WORKFLOW_RUNS_FULFILLED,
	GET_LATEST_TEN_WORKFLOW_RUNS_PENDING,
	GET_LATEST_TEN_WORKFLOW_RUNS_REJECTED,
	GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS_FULFILLED,
	GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS_PENDING,
	GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS_REJECTED,
	GET_WORKFLOW_DETAIL_FULFILLED,
	GET_WORKFLOW_DETAIL_PENDING,
	GET_WORKFLOW_DETAIL_REJECTED
} from 'store/constants/single-workflow-hightlights';

const initialServerState = {
	data: {},
	fetching: false,
	fetched: false,
	error: null,
};

function pendingServerState() {
	return {
		fetching: true,
		fetched: false,
		error: null,
	};
}

function fulfilledServerState(action) {
	return {
		data: action.payload,
		fetching: false,
		fetched: true,
		error: null,
	};
}

function rejectedServerState(action) {
	return {
		fetching: false,
		fetched: false,
		error: action.payload,
	};
}

const initialState = {
	latestTenWorkflowRuns: { ...initialServerState },
	countOf30DaysWorkflowRuns: { ...initialServerState },
	statusSummaryOfLatest10WorkflowRuns: { ...initialServerState },
	executionCountLatest10WorkflowRuns: { ...initialServerState },
	durationSummaryWorkflowRuns: { ...initialServerState },
	averageDurationTimeWorkflowRuns: { ...initialServerState },
	costTimeWorklowRuns: { ...initialServerState },
	jobsWorkflowRuns: { ...initialServerState },
	workflowDetail: { ...initialServerState }
};

export default function singleWorkflowHighlights(state = initialState, action) {
	switch (action.type) {
		case GET_LATEST_TEN_WORKFLOW_RUNS_PENDING:
			return { ...state, latestTenWorkflowRuns: pendingServerState() };
		case GET_LATEST_TEN_WORKFLOW_RUNS_FULFILLED:
			return { ...state, latestTenWorkflowRuns: fulfilledServerState(action) };
		case GET_LATEST_TEN_WORKFLOW_RUNS_REJECTED:
			return { ...state, latestTenWorkflowRuns: rejectedServerState(action) };

		case GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS_PENDING:
			return { ...state, countOf30DaysWorkflowRuns: pendingServerState() };
		case GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS_FULFILLED:
			return { ...state, countOf30DaysWorkflowRuns: fulfilledServerState(action) };
		case GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS_REJECTED:
			return { ...state, countOf30DaysWorkflowRuns: rejectedServerState(action) };

		case GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS_PENDING:
			return { ...state, statusSummaryOfLatest10WorkflowRuns: pendingServerState() };
		case GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS_FULFILLED:
			return { ...state, statusSummaryOfLatest10WorkflowRuns: fulfilledServerState(action) };
		case GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS_REJECTED:
			return { ...state, statusSummaryOfLatest10WorkflowRuns: rejectedServerState(action) };

		case GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS_PENDING:
			return { ...state, executionCountLatest10WorkflowRuns: pendingServerState() };
		case GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS_FULFILLED:
			return { ...state, executionCountLatest10WorkflowRuns: fulfilledServerState(action) };
		case GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS_REJECTED:
			return { ...state, executionCountLatest10WorkflowRuns: rejectedServerState(action) };

		case GET_DURATION_SUMMARY_WORKFLOW_RUNS_PENDING:
			return { ...state, durationSummaryWorkflowRuns: pendingServerState() };
		case GET_DURATION_SUMMARY_WORKFLOW_RUNS_FULFILLED:
			return { ...state, durationSummaryWorkflowRuns: fulfilledServerState(action) };
		case GET_DURATION_SUMMARY_WORKFLOW_RUNS_REJECTED:
			return { ...state, durationSummaryWorkflowRuns: rejectedServerState(action) };

		case GET_COST_TIME_WORKFLOW_RUNS_PENDING:
			return { ...state, costTimeWorklowRuns: pendingServerState() };
		case GET_COST_TIME_WORKFLOW_RUNS_FULFILLED:
			return { ...state, costTimeWorklowRuns: fulfilledServerState(action) };
		case GET_COST_TIME_WORKFLOW_RUNS_REJECTED:
			return { ...state, costTimeWorklowRuns: rejectedServerState(action) };

		case GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS_PENDING:
			return { ...state, averageDurationTimeWorkflowRuns: pendingServerState() };
		case GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS_FULFILLED:
			return { ...state, averageDurationTimeWorkflowRuns: fulfilledServerState(action) };
		case GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS_REJECTED:
			return { ...state, averageDurationTimeWorkflowRuns: rejectedServerState(action) };

		case GET_JOBS_WORKFLOW_RUNS_PENDING:
			return { ...state, jobsWorkflowRuns: pendingServerState() };
		case GET_JOBS_WORKFLOW_RUNS_FULFILLED:
			return { ...state, jobsWorkflowRuns: fulfilledServerState(action) };
		case GET_JOBS_WORKFLOW_RUNS_REJECTED:
			return { ...state, jobsWorkflowRuns: rejectedServerState(action) };

		case GET_WORKFLOW_DETAIL_PENDING:
			return { ...state, workflowDetail: pendingServerState() };
		case GET_WORKFLOW_DETAIL_FULFILLED:
			return { ...state, workflowDetail: fulfilledServerState(action) };
		case GET_WORKFLOW_DETAIL_REJECTED:
			return { ...state, workflowDetail: rejectedServerState(action) };

		default:
			return state;
	}
}
