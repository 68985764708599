import { TeamOrganizationWorkflows } from 'components/TeamOrganizationWorkflows';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updateOrganizationWorkflowLabel } from 'store/actions/organization-workflows-labels';
import {
  attachOrganizationWorkflowLabels,
  createOrganizationWorkflowLabels,
  deleteOrganizationWorkflowLabels,
  detachOrganizationWorkflowLabels,
  getOrganizationLabels,
  getOrganizationWorkflowIdLabelMap,
} from 'store/actions/organization-workflows-labels';
import {
  getOrganizationWorkflowsSettings,
  getOrganizationWorkflowsSettingsDetails,
  getOrganizationWorkflowsStatus,
  updateOrganizationWorkflowsSettings,
} from 'store/actions/organization-workflows-settings';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    userAccount: store.userAccount,
    organizationWorkflowsSettings: store.organizationWorkflowsSettings,
    organizationWorkflowsLabels: store.organizationWorkflowsLabels,
  };
}

function mapDispatchToProps() {
  return {
    getOrganizationWorkflowsSettings(organizationId) {
      dispatchWithExceptionHandler(getOrganizationWorkflowsSettings(organizationId), false);
    },
    getOrganizationLabels(organizationId) {
      dispatchWithExceptionHandler(getOrganizationLabels(organizationId), false);
    },
    getOrganizationWorkflowIdLabelMap(organizationId) {
      dispatchWithExceptionHandler(getOrganizationWorkflowIdLabelMap(organizationId), false);
    },
    createOrganizationWorkflowLabels(organizationId, labelName, workflowIds, onSuccess, onError) {
      dispatchWithExceptionHandler(
        createOrganizationWorkflowLabels(organizationId, labelName, workflowIds),
        false,
        onSuccess,
        onError,
      );
    },
    updateOrganizationWorkflowLabel(organizationId, labelId, labelName, onSuccess, onError) {
      dispatchWithExceptionHandler(
        updateOrganizationWorkflowLabel(organizationId, labelId, labelName),
        false,
        onSuccess,
        onError,
      );
    },
    deleteOrganizationWorkflowLabels(organizationId, labelId, onSuccess, onError) {
      dispatchWithExceptionHandler(
        deleteOrganizationWorkflowLabels(organizationId, labelId),
        false,
        onSuccess,
        onError,
      );
    },
    attachOrganizationWorkflowLabels(organizationId, labelId, workflowIds, onSuccess, onError) {
      dispatchWithExceptionHandler(
        attachOrganizationWorkflowLabels(organizationId, labelId, workflowIds),
        false,
        onSuccess,
        onError,
      );
    },
    detachOrganizationWorkflowLabels(organizationId, labelId, workflowIds, onSuccess, onError) {
      dispatchWithExceptionHandler(
        detachOrganizationWorkflowLabels(organizationId, labelId, workflowIds),
        false,
        onSuccess,
        onError,
      );
    },
    getOrganizationWorkflowsStatus(organizationId, onSuccess, onError) {
      dispatchWithExceptionHandler(getOrganizationWorkflowsStatus(organizationId), false, onSuccess, onError);
    },
    getOrganizationWorkflowsSettingsDetails(repoId) {
      dispatchWithExceptionHandler(getOrganizationWorkflowsSettingsDetails(repoId), false);
    },
    updateOrganizationWorkflowsSettings(organizationId, settings, onSuccess, onError) {
      dispatchWithExceptionHandler(
        updateOrganizationWorkflowsSettings(organizationId, settings),
        false,
        onSuccess,
        onError,
      );
    },
  };
}

const TeamOrganizationWorkflowsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamOrganizationWorkflows),
);
export default TeamOrganizationWorkflowsContainer;
