import {
  GET_TEST_RUNS_LIST,
  GET_TEST_RUNS_LIST_BY_FILTERS,
  SET_TEST_RUNS_BRANCHES_SELECTED,
  SET_TEST_RUNS_SELECTED_HIDE_FORK,
  SET_TEST_RUNS_STATUSES_SELECTED,
  SET_TEST_RUNS_TAGS_SELECTED,
} from 'store/constants/test-runs';
import { CONFIG } from 'utils/config-util';
import axios from 'axios';
import auth from 'auth/auth-factory';
import { decodeRepoName } from 'utils/test-run-detail-util';

export function getTestRunsListByFilters(ciProvider, repoOwner, name, isFirstPage, hideForkBranch) {
  const repoName = decodeRepoName(name);
  return {
    meta: { isFirstPage, ciProvider: ciProvider, repoOwner: repoOwner, repoName: repoName },
    type: GET_TEST_RUNS_LIST_BY_FILTERS,
    payload: axios.post(
      CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/testruns/filters`,
      { hideForkBranch: hideForkBranch },
      {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      },
    ),
  };
}

export function getTestRunsList(provider, owner, name, afterKey, offset, filter, isFirstPage, hideForkBranch) {
  const repoName = decodeRepoName(name); //Pipeline%20Demo => Pipeline Demo
  return {
    meta: { isFirstPage, ciProvider: provider, repoOwner: owner, repoName: repoName },
    type: GET_TEST_RUNS_LIST,
    payload: axios.post(
      CONFIG.apiUrl + `/repos/${provider}/${owner}/${repoName}/testruns`,
      {
        size: offset,
        afterKey: afterKey,
        branches: filter ? filter.selectedTestRunsBranches : [],
        statuses: filter ? filter.selectedTestRunsStatuses : [],
        userTags: filter ? filter.selectedTestRunsTags : [],
        hideForkBranch: hideForkBranch || false,
      },
      { headers: { Authorization: 'Bearer ' + auth.getAccessToken() } },
    ),
  };
}

export function setSelectedHideFork(hideFork) {
  return {
    type: SET_TEST_RUNS_SELECTED_HIDE_FORK,
    payload: { data: hideFork },
  };
}

export function setSelectedTestRunsBranches(branches) {
  return {
    type: SET_TEST_RUNS_BRANCHES_SELECTED,
    payload: { data: branches },
  };
}

export function setSelectedTestRunsStatuses(statuses) {
  return {
    type: SET_TEST_RUNS_STATUSES_SELECTED,
    payload: { data: statuses },
  };
}

export function setSelectedTestRunsTags(tags) {
  return {
    type: SET_TEST_RUNS_TAGS_SELECTED,
    payload: { data: tags },
  };
}
