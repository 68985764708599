import {
  MainLayoutContainer,
  RepositoryPullRequestsFilterContainer,
  RepositoryPullRequestsListContainer,
  RepositoryTabsContainer,
} from 'containers';
import ForesightBreadcrumb, { createForesightBreadCrumbDataItem } from 'custom-components/Breadcrumb/Breadcrumb';
import { ListCardsContainer, ListCardsHeader } from 'custom-components/ListCards/ListCards';
import React, { Component } from 'react';
import routeList, { computePath } from 'routes';
import { defineRepoNameToPageTitle } from 'utils/page-title-util';
import './RepositoryPRsPage.scss';

class RepositoryPRsPage extends Component {
  createBreadCrumbDataItem = (text, data, handleOnClick) => {
    return {
      text: text,
      data: data,
      handleOnClick: handleOnClick,
    };
  };

  componentDidMount() {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    this.props.getPullRequests_Filters(ciProvider, repoOwner, repoName);
  }

  renderContentWithData = () => {
    let columnStyle = {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '20px',
      alignItems: 'center',
      letterSpacing: '-0.01em',
      color: '#788496',
    };
    return (
      <div className="pull-requests">
        <div className="left-part">
          <RepositoryPullRequestsFilterContainer />
        </div>
        <div id="pull-requests-scrollable" className="right-part">
          <ListCardsContainer style={{ width: '100%' }} withBorder>
            <ListCardsHeader
              columnsObjectsArray={[
                { text: 'Status', style: { columnStyle } },
                { text: 'Pull Requests', style: { columnStyle } },
                {
                  text: 'Est. Cost',
                  tooltipText:
                    'This is an estimated cost of your workflows runs for this PR. This is an estimation based on your workflow run metadata, and your plan is not deducted. This is not the billable cost information and it is not official.',
                  style: { columnStyle },
                },
                {
                  text: 'WF Run Duration',
                  tooltipText:
                    'You can detect the time spent on your workflows while this PR is live. This is the sum of all the workflow run durations in this pull request. ',
                  style: { columnStyle },
                },
                {
                  text: 'Change Impact',
                  tooltipText:
                    'This is the coverage analysis of the latest workflow runs. You can determine how much of the latest changes are covered by the tests.',
                  style: { columnStyle },
                },
              ]}
              className="pr-list-header"
            ></ListCardsHeader>
            <RepositoryPullRequestsListContainer />
          </ListCardsContainer>
        </div>
      </div>
    );
  };

  handleGetStartedRedirection = e => {
    e.preventDefault();
    this.props.history.push(computePath(routeList.startCreateProjectSelectGithubRepos.path));
  };

  renderContent = () => {
    return this.renderContentWithData();
  };

  //==== Breadcrumb Functions ====//
  renderBreadcrumb = () => {
    const { repoName } = this.props.match.params;

    const data = [];
    const pathData = {};
    data.push(
      createForesightBreadCrumbDataItem('Repositories', pathData, () => {
        this.props.history.push(routeList.repositories.path);
      }),
    );
    data.push(createForesightBreadCrumbDataItem(repoName, pathData));
    data.push(createForesightBreadCrumbDataItem('Pull Requests', pathData));

    return <ForesightBreadcrumb type="link-item" breadcrumbData={data} />;
  };

  render() {
    const { repoName } = this.props.match.params;

    return (
      <MainLayoutContainer title={defineRepoNameToPageTitle(this.props, routeList.repositoryPRs.title)}>
        <div className="repository-pr-view-page-container">
          {this.renderBreadcrumb()}
          <div className="repository-title-container">
            <div className="title">{repoName}</div>
          </div>
          <RepositoryTabsContainer />
          {this.renderContent()}
        </div>
      </MainLayoutContainer>
    );
  }
}

export default RepositoryPRsPage;
