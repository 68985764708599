import React, { Component } from 'react';
import './RepositoriesProjectCard.scss';
import { Button, Image, Popup, Modal } from 'semantic-ui-react';
import execDate from 'assets/images/execution-date.svg';
import execTime from 'assets/images/execution-time.svg';
import routeList, { computePath } from 'routes';
import { PieChart, Pie, Cell } from 'recharts';
import { WORKFLOWRUN_STATUS } from 'utils/workflowrun-status';
import { formatDateWithTime, millisToMinutesAndSeconds, calcDisplayedTime } from 'utils/date-util';
import { isUserInInvitedUserRole } from 'utils/user-role-util.js';
import { WorkflowSummaryIcon, TestRunSummaryIcon, Tooltip } from 'components';
import emptyTabWaitingWorkflow from 'assets/images/empty-tab.svg';
import workflowEmptyConnect from 'assets/images/workflow-empty-connect.svg';
import { renderLogo } from 'utils/git-provider-logos';
import { isDemoEnv } from 'utils/config-util.js';
import { getCiProviderName } from 'utils/git-provider-human-readable-properties';
import { BadgeTypeEnum, WorkflowRunStateEnum } from 'enums';
import { LoadingDimmerWithNoLine } from 'components/ComponentPlaceholder';
import { NoDataAvailable } from 'components/ComponentPlaceholder';
import { Link } from 'react-router-dom';
import { hasArrayElement } from 'utils/array-util';
import {
  DualHorizontalItemsContainer,
  DualHorizontalItemsLeftPart,
  DualHorizontalItemsRightPart,
} from 'custom-components/DualHorizontalItems/DualHorizontalItems';
import { ForesightTabs } from 'custom-components/Tabs/Tabs';
import { DOMAIN_ICON, UI_ICON } from 'assets/font-icons/IconManager';

const excludeLast = 1;
const SUCCESS_RATE_DIGIT_PRECISION = 2;
const COLORS = ['#2DCD8A', '#6A7689'];
const BUTTON_TEXT_ARR = ['Workflows', 'Tests'];

class RepositoriesProjectCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: BUTTON_TEXT_ARR[0],
      moreButtonFocused: false,
      isStopWatchingModalOpen: false,
      isProjectPopupOpen: false,
      cardOver: false,
    };
  }

  componentDidMount() {
    const { workflowRuns, testRuns } = this.props.dashboardRepo;

    if (workflowRuns === null && testRuns != null) {
      this.setState({
        selectedTab: BUTTON_TEXT_ARR[1],
      });
    }
  }

  renderMoreIconSvgWithColor = () => {
    const NORMAL_COLOR = '#B2BACA';
    const FOCUSED_COLOR = '#09152F';

    return (
      <div>
        {this.state.moreButtonFocused === false ? (
          <svg
            className="normal-state"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10C6.10457 10 7 10.8954 7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10ZM12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10ZM19 10C20.1046 10 21 10.8954 21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10Z"
              fill={NORMAL_COLOR}
            />
          </svg>
        ) : (
          <svg
            className="focus-state"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10C6.10457 10 7 10.8954 7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10ZM12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10ZM19 10C20.1046 10 21 10.8954 21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10Z"
              fill={FOCUSED_COLOR}
            />
          </svg>
        )}
      </div>
    );
  };

  redirectWorkflowRunsOrTestRuns = dashboardRepo => {
    let singleRepo = dashboardRepo.watchedRepo;
    let ciProvider = singleRepo.repo.provider?.toLowerCase();
    let repoOwner = singleRepo.repo.owner;
    let repoName = singleRepo.repo.name;
    const { selectedTab } = this.state;

    //First Check Repo Has PullRequest..
    if (dashboardRepo.latestPullRequest) {
      return computePath(routeList.repositoryPRs.path, {
        ciProvider: ciProvider,
        repoOwner: repoOwner,
        repoName: repoName,
      });
    } else {
      if (selectedTab == BUTTON_TEXT_ARR[0]) {
        return computePath(routeList.repositoryWorkflowRuns.path, {
          ciProvider: ciProvider,
          repoOwner: repoOwner,
          repoName: repoName,
        });
      } else {
        return computePath(routeList.repositoryTestRuns.path, {
          ciProvider: ciProvider,
          repoOwner: repoOwner,
          repoName: repoName,
        });
      }
    }
  };

  getHumanReadableStatus = workflowRun => {
    if (workflowRun.status !== WORKFLOWRUN_STATUS.COMPLETED) {
      if (typeof workflowRun.status === 'string') {
        workflowRun.status.replace('_', ' ');
        return workflowRun.status.charAt(0).toUpperCase() + workflowRun.status.slice(1).toLowerCase();
      }
      return 'Unknown';
    } else {
      if (typeof workflowRun.conclusion === 'string') {
        return workflowRun.conclusion.charAt(0).toUpperCase() + workflowRun.conclusion.slice(1).toLowerCase();
      }
      return 'Unknown';
    }
  };

  onStopWatchingModalOpen = e => {
    e.stopPropagation();
    this.setState({
      isStopWatchingModalOpen: true,
    });
  };

  onBagdeModalOpen = badgeType => {
    const { dashboardRepo, onBadgeModalOpen } = this.props;
    onBadgeModalOpen(dashboardRepo.watchedRepo.repoId, badgeType);
  };

  getDropdownMenu = () => {
    const { userRole } = this.props;
    return (
      <>
        <div className="modal-header">BADGES</div>
        <div
          className="modal-item"
          onClick={e => {
            e.stopPropagation();
            this.onBagdeModalOpen(BadgeTypeEnum.LatestTestRun);
          }}
        >
          Latest Test Run Result
        </div>
        <div
          className="modal-item"
          onClick={e => {
            e.stopPropagation();
            this.onBagdeModalOpen(BadgeTypeEnum.SuccessfulTests);
          }}
        >
          Successful Tests
        </div>
        <div
          className="modal-item"
          onClick={e => {
            e.stopPropagation();
            this.onBagdeModalOpen(BadgeTypeEnum.Utilization);
          }}
        >
          Utilization
        </div>
        {!isUserInInvitedUserRole(userRole) && (
          <>
            <div className="delimiter" />
            <div className="modal-item" onClick={this.onStopWatchingModalOpen}>
              Stop watching repository
            </div>
          </>
        )}
      </>
    );
  };

  renderRepositoryCardMenuTriggerItem = () => {
    let singleRepo = this.props.dashboardRepo.watchedRepo;
    const { cardOver, isStopWatchingModalOpen, isProjectPopupOpen } = this.state;
    return (
      !isDemoEnv() && (
        <div
          className="more-icon-container"
          onClick={e => {
            e.preventDefault();
            this.setState({
              isProjectPopupOpen: !isProjectPopupOpen,
            });
          }}
        >
          {cardOver && (
            <Popup
              id="stop-watching-parent"
              trigger={this.renderMoreIconSvgWithColor()}
              basic
              hideOnScroll={true}
              onClick={e => {
                e.stopPropagation();
                this.setState({
                  moreButtonFocused: true,
                });
              }}
              on="click"
              content={this.getDropdownMenu()}
              onMount={() => {
                this.setState({
                  moreButtonFocused: true,
                });
              }}
              onUnmount={() => {
                this.setState({
                  moreButtonFocused: false,
                  isProjectPopupOpen: false,
                });
              }}
              position="bottom right"
              hoverable
              mouseLeaveDelay={500}
              open={isProjectPopupOpen}
            />
          )}
          <Modal
            className="stop-repo-watching-modal"
            open={isStopWatchingModalOpen}
            onClose={() => this.setState({ isStopWatchingModalOpen: false })}
          >
            <div className="title-section">
              <i className={UI_ICON.ALERT.WARNING} />
              <div className="title">Stop watching {singleRepo.repo.name}</div>
            </div>

            <div className="description">
              You will lose your workflow runs and test runs after you stop watching your repository. You need to
              disconnect the Foresight agent if you have integrated your tests.
            </div>
            <div className="description">Do you want to proceed?</div>
            <div className="delete-actions">
              <Button
                className="cancel-button"
                onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    isStopWatchingModalOpen: false,
                    moreButtonFocused: false,
                    isProjectPopupOpen: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                primary
                onClick={e => {
                  e.stopPropagation();
                  this.props.unwatchRepo(
                    singleRepo.repo.provider,
                    singleRepo.repo.owner,
                    singleRepo.repo.name,
                    this.props.foresightProjectId,
                    this.onUnwatchRepoSuccess,
                  );
                }}
              >
                Stop watching
              </Button>
            </div>
          </Modal>
        </div>
      )
    );
  };

  onUnwatchRepoSuccess = () => {
    window.location.reload();
  };

  renderWorkFlowTabWithRepos = () => {
    let { dashboardRepo } = this.props;
    let { watchedRepo } = dashboardRepo;
    let { repo } = watchedRepo;

    const workflowSuccessRate = [
      { name: 'succcesful_rate', value: dashboardRepo?.workflowRunStatistic?.successRate },
      { name: 'failed_rate', value: 100 - dashboardRepo?.workflowRunStatistic?.successRate },
    ];

    return (
      <div className="ci-cd-body">
        <div className="ci-cd-header top">
          <Link
            className="link-wrapper-hover"
            to={computePath(routeList.workflowRunTimeline.path, {
              workflowId: dashboardRepo.workflowRuns[0].id,
              ciProvider: repo.provider?.toLowerCase(),
              repoOwner: repo.owner,
              repoName: repo.name,
            })}
          >
            <div className="title-container">
              <span className="title-sub-header-card">LATEST WORKFLOW RUN</span>
              <i className={UI_ICON.CHEVRON.RIGHT + ' chevron-icon'} />
            </div>
          </Link>
          {this.renderCiCdHeaderForWorkflow(dashboardRepo.workflowRuns[0])}
        </div>
        <div className="ci-cd-header">
          <span>LATEST 5 WORKFLOW RUNS</span>
          <div className="last-workflow-runs">
            {dashboardRepo.workflowRuns
              .slice(excludeLast)
              .reverse()
              .map(workflowRun => {
                return this.renderHoverStatus(workflowRun, '#788496');
              })}
            <div className="workflow-icon-with-hover">{this.renderHoverStatus(dashboardRepo.workflowRuns[0])}</div>
          </div>
        </div>
        <div className="recent-stats">
          <span>LAST 14 DAYS</span>
          {dashboardRepo?.workflowRunStatistic ? (
            <div className="stats">
              <div className="workflow-piechart">
                <PieChart className="piechart" width={35} height={35}>
                  <Pie
                    data={workflowSuccessRate}
                    fill="#8884d8"
                    stroke="#151a20"
                    innerRadius={12}
                    outerRadius={16}
                    paddingAngle={0}
                    dataKey="value"
                  >
                    {workflowSuccessRate.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
                <div className="workflow-info">
                  <div className="success-rate">
                    {dashboardRepo?.workflowRunStatistic?.successRate?.toFixed(SUCCESS_RATE_DIGIT_PRECISION)}
                  </div>
                  <div className="success-rate-text">CI Success Rate</div>
                </div>
              </div>
              <div className="workflow-piechart">
                <Image className="recent-stats-exec-icon" src={execTime} />
                <div className="workflow-info">
                  <div className="success-rate">
                    {dashboardRepo?.workflowRunStatistic?.averageDuration &&
                      millisToMinutesAndSeconds(dashboardRepo?.workflowRunStatistic?.averageDuration)}
                  </div>
                  <div className="success-rate-text">Avg. Execution Time</div>
                </div>
              </div>
            </div>
          ) : (
            <NoDataAvailable msg={'No data available'} isDisplayInline={true} />
          )}
        </div>
      </div>
    );
  };

  renderWorkflowTab = () => {
    let { dashboardRepo } = this.props;

    return Array.isArray(dashboardRepo.workflowRuns) && dashboardRepo.workflowRuns.length > 0
      ? this.renderWorkFlowTabWithRepos()
      : this.renderTabNonCompletedStateForWorkflow();
  };

  renderTestRunsTabWithData = () => {
    let { dashboardRepo } = this.props;
    const { watchedRepo } = dashboardRepo;
    const { repo } = watchedRepo;

    const testRunStatistic = [
      { name: 'succcesful_rate', value: dashboardRepo?.testRunStatistic?.successRate },
      { name: 'failed_rate', value: 100 - dashboardRepo?.testRunStatistic?.successRate },
    ];

    return (
      <div className="latest-test-runs">
        <div className="ci-cd-header top">
          <Link
            className="link-wrapper-hover"
            to={computePath(routeList.testRunOverview.path, {
              testRunId: hasArrayElement(dashboardRepo?.testRuns) && dashboardRepo.testRuns[0].id,
              ciProvider: repo.provider?.toLowerCase(),
              repoOwner: repo.owner,
              repoName: repo.name,
            })}
          >
            <div className="title-container">
              <span className="title-sub-header-card">LATEST TEST RUN </span>
              <i className={UI_ICON.CHEVRON.RIGHT + ' chevron-icon'} />
            </div>
          </Link>

          {this.renderCiCdHeaderForTestruns(dashboardRepo.testRuns[0])}
        </div>
        {dashboardRepo.testRuns.map(testrun => {
          return (
            <div className="tests-run-result-section" key={testrun.id + 'tests-run-result-section'}>
              <div className="failed-tests item">
                <DualHorizontalItemsContainer>
                  <DualHorizontalItemsLeftPart className="scale">
                    <Tooltip content="Failed">
                      <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.FAILED} />
                    </Tooltip>
                  </DualHorizontalItemsLeftPart>
                  <DualHorizontalItemsRightPart>
                    <div className="testrun-count">{testrun.failedCount}</div>
                  </DualHorizontalItemsRightPart>
                </DualHorizontalItemsContainer>
              </div>
              <div className="successful-tests item">
                <DualHorizontalItemsContainer>
                  <DualHorizontalItemsLeftPart className="scale">
                    <Tooltip content="Successful">
                      <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.PASSED} />
                    </Tooltip>
                  </DualHorizontalItemsLeftPart>
                  <DualHorizontalItemsRightPart>
                    <div className="testrun-count">{testrun.successfulCount}</div>
                  </DualHorizontalItemsRightPart>
                </DualHorizontalItemsContainer>
              </div>
              <div className="skipped-tests item">
                <DualHorizontalItemsContainer>
                  <DualHorizontalItemsLeftPart className="scale">
                    <Tooltip content="Skipped">
                      <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.SKIPPED} />
                    </Tooltip>
                  </DualHorizontalItemsLeftPart>
                  <DualHorizontalItemsRightPart>
                    <div className="testrun-count">{testrun.ignoredCount}</div>
                  </DualHorizontalItemsRightPart>
                </DualHorizontalItemsContainer>
              </div>
              <div className="aborted-tests item">
                <DualHorizontalItemsContainer>
                  <DualHorizontalItemsLeftPart className="scale">
                    <Tooltip content="Aborted">
                      <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.ABORTED} />
                    </Tooltip>
                  </DualHorizontalItemsLeftPart>
                  <DualHorizontalItemsRightPart>
                    <div className="testrun-count">{testrun.abortedCount}</div>
                  </DualHorizontalItemsRightPart>
                </DualHorizontalItemsContainer>
              </div>
            </div>
          );
        })}
        <div className="recent-stats">
          <span>LAST 14 DAYS</span>
          <div className="stats">
            <div className="testrun-piechart">
              <PieChart className="piechart" width={35} height={35}>
                <Pie
                  data={testRunStatistic}
                  fill="#8884d8"
                  stroke="#151a20"
                  innerRadius={12}
                  outerRadius={16}
                  paddingAngle={0}
                  dataKey="value"
                >
                  {testRunStatistic.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
              <div className="testrun-info">
                <div className="success-rate">
                  {dashboardRepo?.testRunStatistic?.successRate?.toFixed(SUCCESS_RATE_DIGIT_PRECISION)}
                </div>
                <div className="success-rate-text">Test Success Rate</div>
              </div>
            </div>
            <div className="testrun-piechart">
              <Image className="recent-stats-exec-icon" src={execTime} />
              <div className="testrun-info">
                <div className="success-rate">
                  {dashboardRepo?.testRunStatistic?.averageDuration &&
                    millisToMinutesAndSeconds(dashboardRepo?.testRunStatistic?.averageDuration)}
                </div>
                <div className="success-rate-text">Avg. Execution Time</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTestRunsTab = () => {
    let { dashboardRepo } = this.props;

    return dashboardRepo.testRuns && dashboardRepo.testRuns[0].completed
      ? this.renderTestRunsTabWithData()
      : this.renderTabEmptyStateForTestRun();
  };

  handleActiveTab = (e, name) => {
    e.preventDefault();
    this.setState({ selectedTab: name });
  };

  renderProjectCard = () => {
    let { dashboardRepo } = this.props;
    let { cardOver } = this.state;
    let singleRepo = dashboardRepo.watchedRepo;

    return (
      <li
        onMouseEnter={() => this.setState({ cardOver: true })}
        onMouseLeave={() => this.setState({ cardOver: false })}
        className="repo-card-list-item"
      >
        <Link className="link-wrapper-hover" to={this.redirectWorkflowRunsOrTestRuns(dashboardRepo)}>
          <div
            className="card"
            onMouseEnter={() =>
              this.setState({
                cardOver: true,
              })
            }
            onMouseLeave={() =>
              this.setState({
                cardOver: false,
              })
            }
          >
            <div className="card-header">
              <div className="repo-title-and-logo">
                <Link className="link-wrapper-hover" to={this.redirectWorkflowRunsOrTestRuns(dashboardRepo)}>
                  <div className={`title ${cardOver ? 'hover' : ''}`}>{singleRepo.repo.fullName}</div>
                </Link>
                <div className="title-right-section">
                  {this.renderRepositoryCardMenuTriggerItem()}
                  <div className="logo">{renderLogo(singleRepo.repo.provider, 'github-icon-style')}</div>
                </div>
              </div>
              <div className="tabs">
                {
                  <ForesightTabs
                    withLine={false}
                    id="repositories-project-card"
                    onClick={(e, data) => {
                      this.handleActiveTab(e, data.name);
                    }}
                    buttonTextArr={BUTTON_TEXT_ARR}
                    active={this.state.selectedTab}
                  />
                }
              </div>
            </div>
            <div className="card-body">
              {this.state.selectedTab == BUTTON_TEXT_ARR[0] ? this.renderWorkflowTab() : this.renderTestRunsTab()}
            </div>
          </div>
        </Link>
      </li>
    );
  };

  onSetSelectedProjectSuccessTestSelected = () => {
    this.props.history.push(routeList.startIntegrationGitHubTestRuns.path);
  };

  getNoWorkflowsFoundContent = () => {
    return (
      <div className="empty-tab">
        <Image id="empty-icon" className="center" src={emptyTabWaitingWorkflow} fluid />
        <div className="content">
          <div className="text">No workflows found</div>
          <div className="light-text no-workflows-found">
            Possible reasons:
            <ul>
              <li>There are no workflows in your repository</li>
              <li>You haven't triggered your workflows in the last 14 days</li>
              <li>You have uninstalled Foresight's GitHub app</li>
            </ul>
            <div className="help-text">
              You can{' '}
              <a
                onClick={e => {
                  e.stopPropagation();
                }}
                href="#help"
                id={'sidebar-intercom-item'}
              >
                contact us{' '}
              </a>
              to get help
            </div>
          </div>
        </div>
      </div>
    );
  };

  getPreparingWorkflowsContent = () => {
    return (
      <div className="empty-tab">
        <div className="flying-bird">
          <LoadingDimmerWithNoLine />
        </div>
        <div className="content">
          <div className="preparing-header">We are preparing your workflows</div>
          <div className="preparing-text">It may take a few minutes</div>
        </div>
      </div>
    );
  };

  renderTabNonCompletedStateForWorkflow = () => {
    const { dashboardRepo } = this.props;
    const { watchedRepo } = dashboardRepo;
    const { repo } = watchedRepo;

    if (
      (!dashboardRepo.workflowRuns ||
        (Array.isArray(dashboardRepo.workflowRuns) && dashboardRepo.workflowRuns.length === 0)) &&
      dashboardRepo.watchedRepo &&
      repo?.repoInformedStatus !== WorkflowRunStateEnum.STARTED
    ) {
      return this.getNoWorkflowsFoundContent();
    }

    if (repo && repo.repoInformedStatus === WorkflowRunStateEnum.INITIAL) {
      return (
        <div className="empty-tab">
          <Image id="empty-icon" className="center" src={workflowEmptyConnect} fluid />
          <div className="content">
            {/* // TODO Github text should come by be */}
            <div className="text">
              Connect your {getCiProviderName(dashboardRepo.watchedRepo.repo.provider)} account
            </div>
            <div className="light-text connect-repository">
              Start to monitor CI workflows by connecting your{' '}
              {getCiProviderName(dashboardRepo.watchedRepo.repo.provider)} account
            </div>
            <div className="card-connect-button-wrapper">
              <Button
                onClick={() => {
                  this.props.setRepoSelectedProject(
                    dashboardRepo.watchedRepo.projectId,
                    this.props.onSetSelectedProjectSuccess,
                  );
                }}
              >
                Connect
              </Button>
            </div>
          </div>
        </div>
      );
    }

    if (repo && repo.repoInformedStatus === WorkflowRunStateEnum.STARTED) {
      return this.getPreparingWorkflowsContent();
    }

    if (repo && repo.repoInformedStatus === WorkflowRunStateEnum.COMPLETED_WITH_NO_WORKFLOW) {
      return this.getNoWorkflowsFoundContent();
    }
  };

  renderTabEmptyStateForTestRun = () => {
    return (
      <div className="empty-tab">
        <Image id="empty-icon" className="center" src={emptyTabWaitingWorkflow} fluid />
        <div className="content">
          <div className="test-text">No test runs found</div>
          <div className="light-text no-testrun-found">
            Possible reasons:
            <ul>
              <li>You haven't integrated Foresight into your tests</li>
              <li>You haven't ran your tests</li>
            </ul>
            <div className="help-text">
              You can{' '}
              <a
                onClick={e => {
                  e.stopPropagation();
                }}
                href="#help"
                id={'sidebar-intercom-item'}
              >
                contact us{' '}
              </a>
              to get help
            </div>
          </div>
          <Link className="link-wrapper-hover" to={computePath(routeList.startIntegrationGitHubTestRuns.path)}>
            <Button className="integrate-tests-button">Integrate your tests</Button>
          </Link>
        </div>
      </div>
    );
  };

  renderHoverStatus = (workflowRun, overrideColor) => {
    let { dashboardRepo } = this.props;
    let { watchedRepo } = dashboardRepo;
    let { repo } = watchedRepo;

    return (
      <Popup
        id="hover-status"
        key={workflowRun.id}
        basic
        trigger={
          <div className="workflow">
            <WorkflowSummaryIcon
              styleClass="latest-workflows"
              workflowRun={workflowRun}
              overrideColor={overrideColor}
              history={this.props.history}
              ciProvider={repo.provider?.toLowerCase()}
              repoOwner={repo.owner}
              repoName={repo.name}
            />
          </div>
        }
        content={<div className="ci-cd-header at-hover">{this.renderCiCdHeaderForWorkflow(workflowRun)}</div>}
        position="top left"
        hoverable
      />
    );
  };

  renderCiCdHeaderForWorkflow = workflowRun => {
    let { dashboardRepo } = this.props;
    let { watchedRepo } = dashboardRepo;
    let { repo } = watchedRepo;

    return (
      <>
        <div className="status item">
          <DualHorizontalItemsContainer>
            <DualHorizontalItemsLeftPart className="status-icon">
              <WorkflowSummaryIcon
                workflowRun={workflowRun}
                history={this.props.history}
                ciProvider={repo.provider?.toLowerCase()}
                repoOwner={repo.owner}
                repoName={repo.name}
              />
            </DualHorizontalItemsLeftPart>
            <DualHorizontalItemsRightPart>
              <div className="status-text ci-cd-header-texts">{this.getHumanReadableStatus(workflowRun)}</div>
            </DualHorizontalItemsRightPart>
          </DualHorizontalItemsContainer>
          <DualHorizontalItemsContainer />
        </div>
        <div className="execution-date item">
          <DualHorizontalItemsContainer>
            <DualHorizontalItemsLeftPart>
              <>
                <Tooltip content="Date">
                  <Image className="exec-icon" src={execDate} fluid />
                </Tooltip>
              </>
            </DualHorizontalItemsLeftPart>
            <DualHorizontalItemsRightPart>
              <div className="exec-time-text ci-cd-header-texts">{formatDateWithTime(workflowRun.createdAt)}</div>
            </DualHorizontalItemsRightPart>
          </DualHorizontalItemsContainer>
        </div>
        <div className="execution-time item">
          <DualHorizontalItemsContainer>
            <DualHorizontalItemsLeftPart>
              <Tooltip content="Execution duration">
                <Image className="exec-icon" src={execTime} fluid />
              </Tooltip>
            </DualHorizontalItemsLeftPart>
            <DualHorizontalItemsRightPart>
              <div className="exec-time-text ci-cd-header-texts">{calcDisplayedTime(workflowRun.duration)}</div>
            </DualHorizontalItemsRightPart>
          </DualHorizontalItemsContainer>
        </div>
      </>
    );
  };

  renderCiCdHeaderForTestruns = testRun => {
    let { dashboardRepo } = this.props;
    let { watchedRepo } = dashboardRepo;
    let { repo } = watchedRepo;
    return (
      <>
        <div className="status item">
          <DualHorizontalItemsContainer>
            <DualHorizontalItemsLeftPart>
              <div className="status-icon">
                <TestRunSummaryIcon
                  testRun={testRun}
                  history={this.props.history}
                  onClick={e => {
                    e.preventDefault();
                    this.props.history.push(
                      computePath(routeList.testRunOverview.path, {
                        testRunId: testRun.id,
                        ciProvider: repo.provider.toLowerCase(),
                        repoOwner: repo.owner,
                        repoName: repo.name,
                      }),
                    );
                  }}
                />
              </div>
            </DualHorizontalItemsLeftPart>
            <DualHorizontalItemsRightPart>
              <div className="status-text ci-cd-header-texts">{testRun.status}</div>
            </DualHorizontalItemsRightPart>
          </DualHorizontalItemsContainer>
        </div>
        <div className="execution-date item">
          <DualHorizontalItemsContainer>
            <DualHorizontalItemsLeftPart>
              <div>
                <Tooltip content="Date">
                  <Image className="exec-icon" src={execDate} fluid />
                </Tooltip>
              </div>
            </DualHorizontalItemsLeftPart>
            <DualHorizontalItemsRightPart>
              <div className="exec-date-text ci-cd-header-texts">{formatDateWithTime(testRun.startTime)}</div>
            </DualHorizontalItemsRightPart>
          </DualHorizontalItemsContainer>
        </div>
        <div className="execution-time item">
          <DualHorizontalItemsContainer>
            <DualHorizontalItemsLeftPart>
              <Tooltip content="Execution duration">
                <Image className="exec-icon" src={execTime} fluid />
              </Tooltip>
            </DualHorizontalItemsLeftPart>
            <DualHorizontalItemsRightPart>
              <div className="exec-time-text ci-cd-header-texts"> {calcDisplayedTime(testRun.duration)}</div>
            </DualHorizontalItemsRightPart>
          </DualHorizontalItemsContainer>
        </div>
      </>
    );
  };

  render() {
    return this.renderProjectCard();
  }
}

export default RepositoriesProjectCard;
