import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import {
  GET_ORGANIZATION_WORKFLOW_SETTINGS,
  GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS,
  GET_ORGANIZATION_WORKFLOW_STATUS,
  UPDATE_ORGANIZATION_WORKFLOW_SETTINGS,
} from 'store/constants/organization-workflows-settings';

export const getOrganizationWorkflowsSettings = organizationId => {
  return {
    meta: { organizationId: organizationId },
    type: GET_ORGANIZATION_WORKFLOW_SETTINGS,
    payload: axios.get(CONFIG.apiUrl + '/repos/watched', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};

export const getOrganizationWorkflowsStatus = organizationId => {
  return {
    meta: { organizationId: organizationId },
    type: GET_ORGANIZATION_WORKFLOW_STATUS,
    payload: axios.get(CONFIG.apiUrl + `/organizations/${organizationId}/workflows/hiding/state`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};

export const getOrganizationWorkflowsSettingsDetails = repoId => {
  const sArr = repoId.split('/');
  const ciProvider = sArr[0].toLowerCase();
  const repoOwner = sArr[1];
  const repoName = sArr[2];
  return {
    meta: { repoId: repoId },
    type: GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS,
    payload: axios.get(CONFIG.apiUrl + `/repos/${ciProvider}/${repoOwner}/${repoName}/workflows/watched`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};

export function updateOrganizationWorkflowsSettings(organizationId, settings) {
  return {
    meta: { settings: settings },
    type: UPDATE_ORGANIZATION_WORKFLOW_SETTINGS,
    payload: axios.post(CONFIG.apiUrl + `/organizations/${organizationId}/workflows/watch`, settings, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
}
