import { LoadingDimmer, NoDataAvailable, SomethingError } from 'components/index';
import moment from 'moment';
import React, { Component } from 'react';
import { hasArrayElement } from 'utils/array-util';
import { getStringColor } from 'utils/color-util';
import { calcDisplayedTime } from 'utils/date-util';
import { LineChartViewer } from './LineChartViewer';
import { getLineChartSample } from './LineChartViewer/linechart-sample';
import { StackAreaChartViewer } from './StackAreaChartViewer/StackAreaChartViewer';
import './WidgetCard.scss';

const arrMap = {
  one: '1',
  two: '2',
  three: '3',
  five: '5',
  fifteen: '15',
  thirty: '30',
};
const chartConfig = {
  sizeProps: { width: 470, height: 200 },
  dataProps: [], //Fill on the Fly
  yAxisProps: {
    tickFormatter: tick => {
      return calcDisplayedTime(tick);
    },
    tickAreaFormatter: tick => {
      return parseInt(tick);
    },
  },
  xAxisProps: {
    dataKey: 'time',
    tickFormatter: val => {
      return moment(val, 'YYYY-MM-DD HH:mm:ss.sss Z').local().format('HH:mm');
    },
    tickAreaFormatter: val => {
      return moment(val, 'x').local().format('HH:mm:ss');
    },
  },
  tooltipProps: {
    valueFormatter: entry => {
      return calcDisplayedTime(entry.value);
    },
  },
  legendProps: {},
};

export default class WidgetCard extends Component {
  constructor(props) {
    super(props);
    const { timeConfig } = this.props;
    const lineChartSample = getLineChartSample('flowId', 'Flow', 10000);
    this.state = {
      chartConfig: timeConfig ? { ...chartConfig } : { ...lineChartSample.chartConfig },
    };
  }
  formatTimeNumber = text => {
    if (arrMap[`${text}`]) return arrMap[`${text}`];
    return text;
  };

  formatTimeRange = timeRange => {
    if (typeof timeRange !== 'string') return '';
    return timeRange
      .split('_')
      .map(el => this.formatTimeNumber(el.toLowerCase()))
      .join(' ');
  };

  generateChartConfig(data) {
    const { chartConfig } = this.state;
    const { chartDomain } = this.props;
    const widgetChartData = hasArrayElement(data) ? data : [];
    const lineNameSet = new Set();

    let yMinValue = Number.MAX_SAFE_INTEGER;
    let yMaxValue = 0;
    widgetChartData.forEach(el => {
      for (const key in el) {
        if (key !== 'time') {
          lineNameSet.add(key);
          if (el[key] < yMinValue) yMinValue = el[key];
          if (el[key] > yMaxValue) yMaxValue = el[key];
        }
      }
    });

    chartConfig.dataProps = [];
    lineNameSet.forEach((name, index) => {
      chartConfig.dataProps.push({ dataKey: name, name: name, color: getStringColor(index) });
    });

    chartConfig.yAxisProps.domain = chartDomain ? chartDomain : [yMinValue, yMaxValue];
    return { ...chartConfig };
  }

  renderWithContent = () => {
    const {
      chartData,
      onClick,
      legendValueFormatter,
      type = 'Line',
      refLineValue,
      refLineLabel,
      selectedLineName,
      sentListName,
      hasLegend,
      yAxisPropsUseInt,
      xAxisProps = null,
      tooltipProps = null,
      useOwnXTimeFormatter,
      chartDomain,
    } = this.props;
    const chartConfig = this.generateChartConfig(chartData);
    if (type === 'Line') {
      return (
        <div className="dashboard-widget-card-container">
          <div className="chart">
            <LineChartViewer
              margin={{
                top: 3,
                right: 3,
                left: -20,
                bottom: 3,
              }}
              refLineValue={refLineValue}
              refLineLabel={refLineLabel}
              chartDomain={chartDomain}
              legendValueFormatter={legendValueFormatter}
              data={chartData}
              dataProps={chartConfig.dataProps}
              yAxisPropsUseInt={yAxisPropsUseInt}
              yAxisProps={chartConfig.yAxisProps}
              xAxisProps={xAxisProps ? xAxisProps : chartConfig.xAxisProps}
              tooltipProps={tooltipProps ? tooltipProps : chartConfig.tooltipProps}
              legendProps={chartConfig.legendProps}
              selectedLineName={selectedLineName}
              sentListName={sentListName}
              hasLegend={hasLegend}
              useOwnXTimeFormatter={useOwnXTimeFormatter}
              size={{
                width: 520,
                height: 232,
              }}
              onClick={onClick}
            />
          </div>
        </div>
      );
    } else if (type === 'Area') {
      return (
        <div className="dashboard-widget-card-container">
          <div className="chart">
            <StackAreaChartViewer
              margin={{
                top: 3,
                right: 3,
                left: -20,
                bottom: 3,
              }}
              legendValueFormatter={legendValueFormatter}
              data={chartData}
              dataProps={chartConfig.dataProps}
              yAxisProps={chartConfig.yAxisProps}
              xAxisProps={chartConfig.xAxisProps}
              tooltipProps={tooltipProps ? tooltipProps : chartConfig.tooltipProps}
              legendProps={chartConfig.legendProps}
              chartDomain={chartDomain}
              hasLegend={hasLegend}
              size={{
                width: 520,
                height: 232,
              }}
              onClick={onClick}
            />
          </div>
        </div>
      );
    }
  };

  renderWithoutContent = () => {
    const { error, loading, unknown } = this.props;

    let contentJSX = <NoDataAvailable msg={'No data available'} />;
    if (error) {
      contentJSX = <SomethingError msg={error} />;
    } else if (loading) {
      contentJSX = <LoadingDimmer msg={'Loading...'} />;
    } else if (unknown) {
      contentJSX = <LoadingDimmer msg={'Unknown State...'} />;
    }

    return (
      <div className="dashboard-widget-card-container">
        <div className="other-contents">{contentJSX}</div>
      </div>
    );
  };

  render() {
    const { chartData } = this.props;

    if (hasArrayElement(chartData)) {
      return this.renderWithContent();
    } else {
      return this.renderWithoutContent();
    }
  }
}
