import { connect } from 'react-redux';
import { TestRunTestTabs } from 'components';
import { withRouter } from 'react-router';

function mapStateToProps(store) {
	return {
		testDetails: store.testDetails,
		testRunDetails: store.testRunDetails,
	};
}

function mapDispatchToProps() {
	return {};
}

const TestRunTestTabsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(TestRunTestTabs));
export default TestRunTestTabsContainer;
