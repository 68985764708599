import { Helmet } from 'react-helmet';
import React, { Component } from 'react';
import { Loader, Image } from 'semantic-ui-react';
import auth from 'auth/auth-factory';
import lock from 'auth/lock-factory';
import routeList from 'routes';
import './landing-hammer.scss';
import './LandingPage.scss';

import awsLambdaDevops from 'assets/images/landing-Lambda+Devops.svg';
import gdprBadge from 'assets/images/landing-GDPR.svg';
import foresightLogo from 'assets/images/foresight-logo.svg';
import githubMpDescriptionLogo from 'assets/images/github-mp-desc.svg';

class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoginFormShown: true,
    };
  }

  UNSAFE_componentWillMount() {
    console.log('FORESIGHT, LandingPage, CWM; props: ', JSON.stringify(this.props));
    const installationId = localStorage.getItem('github_installation_id');

    if (auth.isAuthenticated() && installationId) {
      console.log('FORESIGHT, CP, redirect to repo watch page; installationId: ', installationId);
      this.props.history.replace(
        `${routeList.startGitHubExistedProjectRepoSelectionPage.path}?github_installation_id=${installationId}`,
      );
    } else if (auth.isAuthenticated()) {
      this.props.history.replace(routeList.repositories.path);
    } else {
      console.log('render landing page, no redirection');
    }
  }

  componentDidMount() {
    console.log('FORESIGHT, LandingPage, CDM; props: ', JSON.stringify(this.props));
    const { pathname, search } = this.props.location;

    const params = new URLSearchParams(search);
    const onboardingSignupSource = params.get('from');

    if (search && onboardingSignupSource) {
      // Here to put item to local storage if user came from an 3rd party integration page,
      // url should have 'from' url param.
      localStorage.setItem('onboarding-signupsource-token', onboardingSignupSource);
    }

    const invitationId = params.get('invitationId');

    if (search && invitationId) {
      localStorage.setItem('invitationId', invitationId);
    }

    const initialScreen = pathname === '/signup' ? 'signUp' : 'login';
    lock.show({
      initialScreen: initialScreen,
    });

    lock.on('signin ready', () => {
      this.loginTabShown();
    });

    lock.on('signup ready', () => {
      this.signupTabShown();
    });
  }

  loginTabShown = () => {
    console.log('loginTabShown');
    this.setState({
      isLoginFormShown: true,
    });
  };
  signupTabShown = () => {
    console.log('signupTabShown');
    this.setState({
      isLoginFormShown: false,
    });
  };

  isForceLogoutTrue = () => {
    // Parse url and find if 'forceLogout' is true.
    var regex = /[?&]([^=#]+)=([^&#]*)/g,
      url = window.location.href,
      params = {},
      match;
    while ((match = regex.exec(url))) {
      params[match[1]] = match[2];
    }

    if (params.forceLogout === 'true') {
      return true;
    } else {
      return false;
    }
  };

  isSignupFormTrue = () => {
    // Parse url and find if 'signupForm' is true.
    var regex = /[?&]([^=#]+)=([^&#]*)/g,
      url = window.location.href,
      params = {},
      match;
    while ((match = regex.exec(url))) {
      params[match[1]] = match[2];
    }

    if (params.signupForm === 'true') {
      return true;
    } else {
      return false;
    }
  };
  renderLandingPageLeftContainer = () => {
    return (
      <div>
        <div className="github-mp-content">
          <div className="github-mp-desc-logo">
            <Image src={githubMpDescriptionLogo} fluid />
          </div>
          <div className="description-bold-text">
            Welcome, <br /> Only one step left to complete your <br />
            installation
          </div>
          <div className="description-body-text">
            Hi there! You have successfully started to install Foresight’s GitHub app. You need to sign up for Foresight
            and complete your installation.
          </div>

          <a href="https://docs.runforesight.com/" rel="noopener noreferrer" target="_blank" className="docs">
            Check our docs
          </a>
        </div>
      </div>
    );
  };

  renderThundraLogo = () => {
    return (
      <div className="thundra-logo">
        <div className="image">
          <Image src={foresightLogo} fluid />
        </div>
      </div>
    );
  };

  getHelmet = isLoginFormShown => {
    let text = isLoginFormShown
      ? 'Resolve bottlenecks in CI pipelines, reduce build times, prioritize tests, and deliver high-quality software at speed with Foresight. Login to continue monitor your Github Actions Workflows.'
      : 'Sign up for your account to monitor & optimize your Github Actions Workflows. Identify slow, flaky, and erroneous tests to optimize clogged CI pipelines with Foresight!';
    return (
      <Helmet>
        <meta name="description" content={text} />
        <meta property="og:description" content={text} />
      </Helmet>
    );
  };

  render() {
    console.log('LandingPage, render; props: ', this.props, window.location.href);

    let githubMarketplaceScreenPresent = localStorage.getItem('github_installation_id');

    let loginPageWrapperClass;
    githubMarketplaceScreenPresent
      ? (loginPageWrapperClass = 'landing-page-wrapper')
      : (loginPageWrapperClass = 'landing-page-wrapper merge');

    return (
      <>
        {this.getHelmet(this.state.isLoginFormShown)}
        <div className="landing-page">
          <div className="thundra-logo">
            <div className="image">
              <Image src={foresightLogo} fluid />
            </div>
          </div>
          <div className="layout-main-container">
            <div className={loginPageWrapperClass}>
              <div className="landing-page-left-container">
                {githubMarketplaceScreenPresent && this.renderLandingPageLeftContainer()}
              </div>
              <div className="landing-page-right-container">
                <div id="login-container" className="action-rect">
                  {/* Auth0 is embbedded in here*/}
                </div>

                {this.isSignupFormTrue() && (
                  <div className="landing-loader">
                    <Loader active inline>
                      Welcome to Foresight...
                    </Loader>
                  </div>
                )}

                <div className="certificates">
                  <div className="image aws">
                    <Image src={awsLambdaDevops} fluid />
                  </div>
                  <div className="image gdpr">
                    <Image src={gdprBadge} fluid />
                  </div>
                </div>

                <div className="tos-copyright">
                  <a href="https://www.thundra.io/termsofservices" rel="noopener noreferrer" target="_blank">
                    Terms of Service
                  </a>
                  <span className="interpunct-span">•</span>
                  <span>Copyright © {new Date().getFullYear()}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LandingPage;
