import { WidgetCard } from 'components/HighlightsPage/HighlightParts/WidgetCard';
import { Tooltip } from 'components/Tooltip';
import moment from 'moment';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import './IOMetrics.scss';

const chartConfig = {
  xAxisProps: {
    dataKey: 'time',
    tickFormatter: val => {
      return moment(val, 'x').local().format('HH:mm:ss');
    },
  },
};
export class IOMetrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartDataDisk: [],
      chartDataNetwork: [],
    };
  }

  componentDidMount() {
    this.convertHistogramDataForDiskMetrics();
    this.convertHistogramDataForNetworkMetrics();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workflowMetrics.diskMetrics.data !== this.props.workflowMetrics.diskMetrics.data) {
      this.convertHistogramDataForDiskMetrics();
    } else if (prevProps.workflowMetrics.networkMetrics.data !== this.props.workflowMetrics.networkMetrics.data) {
      this.convertHistogramDataForNetworkMetrics();
    }
  }

  convertHistogramDataForDiskMetrics() {
    const { metrics: diskMetrics } = this.props.workflowMetrics.diskMetrics.data;

    const chartData = {};
    if (diskMetrics) {
      for (const [key] of Object.entries(diskMetrics).sort()) {
        const obj = diskMetrics[key];
        if (key) {
          chartData[key] = {
            ...obj,
            time: key,
          };
        }
      }

      const convertData = Object.values(chartData);

      this.setState({
        ...this.state,
        chartDataDisk: convertData,
      });
    } else {
      this.setState({
        chartDataDisk: [],
      });
    }
  }

  convertHistogramDataForNetworkMetrics() {
    const { metrics: networkMetrics } = this.props.workflowMetrics.networkMetrics.data;

    const chartData = {};
    if (networkMetrics) {
      for (const [key] of Object.entries(networkMetrics).sort()) {
        const obj = networkMetrics[key];
        if (key) {
          chartData[key] = {
            ...obj,
            time: key,
          };
        }
      }

      const convertData = Object.values(chartData);

      this.setState({
        chartDataNetwork: convertData,
      });
    } else {
      this.setState({
        ...this.state,
        chartDataNetwork: [],
      });
    }
  }

  ioContentGenerator = type => {
    return (
      <div className="io-content-container">
        <div className="io-content-header">
          <h4 className="io-content-header-text">{type === 'network' ? 'Network' : 'Disk'} Metrics</h4>
        </div>
        <div className="io-content-body">
          <p className="io-info-text">{type === 'network' ? 'Network' : 'Disk'} I/O (read and write) in MB</p>
          <div className="y-axis-and-graph-cont">
            <div className="y-axis-text">{type === 'network' ? 'Network' : 'Disk'} (Mb)</div>
            <div className="io-info-graph" />
          </div>
          <span> Time</span>
        </div>
      </div>
    );
  };

  render() {
    const { chartDataDisk, chartDataNetwork } = this.state;
    const { fetching, error } = this.props.workflowMetrics.diskMetrics;
    const { fetching: networkFetching, error: networkError } = this.props.workflowMetrics.networkMetrics;
    return (
      <div>
        <div className="io-metrics-container">
          <div className="header-metrics-container">
            <div className="header-metrics-content ">
              <h4 className="header-metrics">Network Metrics</h4>
              <Tooltip hoverable blackEdition content={this.ioContentGenerator('network')}>
                <Icon style={{ height: '20px' }} name="info circle" />
              </Tooltip>
            </div>
          </div>
          <div className="io-chart-container">
            <WidgetCard
              error={networkError}
              loading={networkFetching}
              chartData={chartDataNetwork}
              legendValueFormatter={entry => entry.value + ' mb'}
              timeConfig
              hasLegend
              xAxisProps={chartConfig.xAxisProps}
              yAxisProps={chartConfig.yAxisProps}
              yAxisPropsUseInt
              useOwnXTimeFormatter
            />
          </div>
        </div>
        <div className="io-metrics-container">
          <div className="header-metrics-container">
            <div className="header-metrics-content ">
              <h4 className="header-metrics">Disk Metrics</h4>
              <Tooltip hoverable blackEdition content={this.ioContentGenerator('disk')}>
                <Icon style={{ height: '20px' }} name="info circle" />
              </Tooltip>
            </div>
          </div>
          <div className="io-chart-container">
            <WidgetCard
              error={error}
              loading={fetching}
              chartData={chartDataDisk}
              legendValueFormatter={entry => entry.value + ' mb'}
              timeConfig
              hasLegend
              xAxisProps={chartConfig.xAxisProps}
              yAxisProps={chartConfig.yAxisProps}
              yAxisPropsUseInt
              useOwnXTimeFormatter
            />
          </div>
        </div>
      </div>
    );
  }
}
