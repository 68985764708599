export const GET_TEST_RUN_DETAIL_TEST_SUIT = 'GET_TEST_RUN_DETAIL_TEST_SUIT';
export const GET_TEST_RUN_DETAIL_TEST_SUIT_PENDING = 'GET_TEST_RUN_DETAIL_TEST_SUIT_PENDING';
export const GET_TEST_RUN_DETAIL_TEST_SUIT_REJECTED = 'GET_TEST_RUN_DETAIL_TEST_SUIT_REJECTED';
export const GET_TEST_RUN_DETAIL_TEST_SUIT_FULFILLED = 'GET_TEST_RUN_DETAIL_TEST_SUIT_FULFILLED';

export const GET_TEST_RUN_DETAIL_OVERVIEW = 'GET_TEST_RUN_DETAIL_OVERVIEW';
export const GET_TEST_RUN_DETAIL_OVERVIEW_PENDING = 'GET_TEST_RUN_DETAIL_OVERVIEW_PENDING';
export const GET_TEST_RUN_DETAIL_OVERVIEW_REJECTED = 'GET_TEST_RUN_DETAIL_OVERVIEW_REJECTED';
export const GET_TEST_RUN_DETAIL_OVERVIEW_FULFILLED = 'GET_TEST_RUN_DETAIL_OVERVIEW_FULFILLED';

export const SET_TEST_RUN_DETAIL_CLIENT_FILTER = 'SET_TEST_RUN_DETAIL_CLIENT_FILTER';
