import { TestRunsInfiniteTable } from 'components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getPullRequestTestRunsList } from 'store/actions/pull-request-test-runs';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    testRuns: store.pullRequestTestWRuns,
    testRunDetails: store.testRunDetails,
    userAccount: store.userAccount,
  };
}

function mapDispatchToProps() {
  return {
    getTestRunPRList(
      provider,
      owner,
      name,
      afterKey,
      offset,
      filter,
      prId,
      isFirstPage,
      hideForkBranch,
      onGetTestRunsListSuccess,
    ) {
      dispatchWithExceptionHandler(
        getPullRequestTestRunsList(provider, owner, name, afterKey, offset, filter, prId, isFirstPage, hideForkBranch),
        true,
        onGetTestRunsListSuccess,
      );
    },
  };
}

const TestRunsInfiniteTablePRContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TestRunsInfiniteTable),
);
export default TestRunsInfiniteTablePRContainer;
