import {
  GET_REPOS_PENDING,
  GET_REPOS_FULFILLED,
  GET_REPOS_REJECTED,
  GET_REPOS_WITHOUT_RELOAD_PENDING,
  GET_REPOS_WITHOUT_RELOAD_FULFILLED,
  GET_REPOS_WITHOUT_RELOAD_REJECTED,
  UPDATE_REPO_LIST,
  UNWATCH_CI_PROVIDE_REPO_PENDING,
  UNWATCH_CI_PROVIDE_REPO_FULFILLED,
  UNWATCH_CI_PROVIDE_REPO_REJECTED,
} from 'store/constants/repos';
import { hasArrayElement } from 'utils/array-util';

const initialState = {
  repoList: [],
  isRepoListFetching: false,
  isRepoListFetched: false,
  isRepoListError: null,

  isUnwatchRepoFetching: false,
  isUnwatchRepoFetched: false,
  isUnwatchRepoError: null,

  hasNewUpdates: false,
};

function prepareRepoList(data) {
  if (data.length === 0) {
    return [];
  }

  return data.map(item => {
    item.key = `${item.id}-${item.status}`;
    return item;
  });
}

function checkRepoOrderIsChanged(prevState, data) {
  // We know that `prevState.repoList` is array and always has 0 element at t0.
  if (!prevState.repoList[0].dashboardRepos && data[0].dashboardRepos) {
    return true;
  }

  if (hasArrayElement(prevState.repoList[0].dashboardRepos) && hasArrayElement(data[0].dashboardRepos)) {
    return prevState.repoList[0].dashboardRepos[0].watchedRepo.repoId !== data[0].dashboardRepos[0].watchedRepo.repoId;
  }

  return false;
}

export default function repos(state = initialState, action) {
  switch (action.type) {
    case GET_REPOS_PENDING:
      return {
        ...state,
        repoList: [],
        isRepoListFetching: true,
        isRepoListFetched: false,
        isRepoListError: null,
        hasNewUpdates: false,
      };
    case GET_REPOS_FULFILLED:
      return {
        ...state,
        repoList: prepareRepoList(action.payload.data),
        isRepoListFetching: false,
        isRepoListFetched: true,
        isRepoListError: null,
      };
    case GET_REPOS_REJECTED:
      return {
        ...state,
        repoList: [],
        isRepoListFetching: false,
        isRepoListFetched: false,
        isRepoListError: action.payload,
      };
    case GET_REPOS_WITHOUT_RELOAD_PENDING:
      return state;
    case GET_REPOS_WITHOUT_RELOAD_FULFILLED: {
      const isRepoOrderChanged = checkRepoOrderIsChanged(state, action.payload.data);
      return {
        ...state,
        repoList: isRepoOrderChanged ? state.repoList : prepareRepoList(action.payload.data),
        hasNewUpdates: isRepoOrderChanged,
        willUpdateRepoList: isRepoOrderChanged ? prepareRepoList(action.payload.data) : state.willUpdateRepoList,
      };
    }
    case GET_REPOS_WITHOUT_RELOAD_REJECTED:
      return state;
    case UPDATE_REPO_LIST:
      return {
        ...state,
        repoList: state.willUpdateRepoList,
        willUpdateRepoList: undefined,
        hasNewUpdates: false,
      };
    case UNWATCH_CI_PROVIDE_REPO_PENDING:
      return {
        ...state,
        isUnwatchRepoFetching: true,
        isUnwatchRepoFetched: false,
        isUnwatchRepoError: null,
      };
    case UNWATCH_CI_PROVIDE_REPO_FULFILLED:
      console.log('UNWATCH_CI_PROVIDE_REPO_FULFILLED, reducer; action: ', action);
      return {
        ...state,
        isUnwatchRepoFetching: false,
        isUnwatchRepoFetched: true,
        isUnwatchRepoError: null,
      };
    case UNWATCH_CI_PROVIDE_REPO_REJECTED:
      return {
        ...state,
        isUnwatchRepoFetching: false,
        isUnwatchRepoFetched: false,
        isUnwatchRepoError: action.payload,
      };
    default:
      return state;
  }
}
