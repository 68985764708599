import { CI_PROVIDER } from 'enums/git-provider';

export const getCiProviderName = ciProvider => {
	switch (ciProvider) {
	case CI_PROVIDER.GITHUB:
		return 'GitHub';
	default:
		return ciProvider;
	}
};


export const getCiProviderSelectionTexts = ciProvider => {
	switch (ciProvider) {
	case CI_PROVIDER.GITHUB:
		return 'GitHub Actions';
	default:
		return ciProvider;
	}
};
