import React, { Component } from 'react';
import { getTestCaseOverviewDetail } from 'utils/test-detail-util';
import { Button } from 'semantic-ui-react';
import './TestRunTestPlatformDashBtn.scss';
import { UI_ICON } from 'assets/font-icons/IconManager';

class TestRunTestPlatformDashBtn extends Component {
  getTestOverview = () => {
    const { transactionId } = this.props.match.params;
    const { testDetailMap } = this.props.testDetails;

    const testOverviewObj = getTestCaseOverviewDetail(testDetailMap, transactionId);
    if (testOverviewObj && testOverviewObj.testOverview) {
      return testOverviewObj.testOverview;
    }
    return null;
  };

  getTestPlatformDashboardUrl = () => {
    const testOverview = this.getTestOverview();
    if (
      testOverview &&
      testOverview.testPlatform &&
      testOverview.testPlatform.tags &&
      testOverview.testPlatform.tags.dashboardUrl
    ) {
      return testOverview.testPlatform.tags.dashboardUrl;
    } else {
      return null;
    }
  };

  handleClick = e => {
    e.preventDefault();
    window.open(this.getTestPlatformDashboardUrl(), '_blank');
  };

  render() {
    return (
      <div className="test-platform-dashboard-link-container">
        {this.getTestPlatformDashboardUrl() && (
          <Button primary onClick={this.handleClick}>
            See on BrowserStack
            <i className={UI_ICON.REDIRECTION.DEFAULT} />
          </Button>
        )}
      </div>
    );
  }
}
export default TestRunTestPlatformDashBtn;
