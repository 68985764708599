import {
  GET_WORKFLOW_RUN_OVERVIEW_FULFILLED,
  GET_WORKFLOW_RUN_OVERVIEW_PENDING,
  GET_WORKFLOW_RUN_OVERVIEW_REJECTED,
  GET_WORKFLOW_RUNS_LIST_FULFILLED,
  GET_WORKFLOW_RUNS_LIST_PENDING,
  GET_WORKFLOW_RUNS_LIST_REJECTED,
  GET_WORKFLOW_RUN_COVERAGE_PENDING,
  GET_WORKFLOW_RUN_COVERAGE_REJECTED,
  GET_WORKFLOW_RUN_COVERAGE_FULFILLED,
} from 'store/constants/workflow-runs';

const workflowRunsInitialState = {
  fetching: false,
  fetched: false,
  error: null,
  workflowRuns: {},
  workflowRunOverview: {},
  workflowRunOverviewFetching: false,
  workflowRunOverviewFetched: false,
  workflowRunOverviewError: null,
  workflowRunCoverage: {},
  workflowRunCoverageFetching: false,
  workflowRunCoverageFetched: false,
  workflowRunCoverageError: null,
};

const calculateFailedJobs = workflowRunsNewsContent => {
  workflowRunsNewsContent.map(workflowRun => {
    let failedJobs = [];
    workflowRun.jobs.map(job => {
      if (job.status == 'COMPLETED' && job.conclusion == 'FAILURE') {
        failedJobs.push(job);
      }
    });
    workflowRun['failedJobs'] = failedJobs;
  });
};

export default function workflowRuns(state = workflowRunsInitialState, action) {
  switch (action.type) {
    case GET_WORKFLOW_RUNS_LIST_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_WORKFLOW_RUNS_LIST_FULFILLED: {
      const workflowRunsNew = { ...action.payload.data };
      if (action.payload.data.pageNumber > 0) {
        workflowRunsNew.content = [...(state.workflowRuns.content || []), ...action.payload.data.content];
      }
      calculateFailedJobs(workflowRunsNew.content);
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        workflowRuns: workflowRunsNew,
      };
    }
    case GET_WORKFLOW_RUNS_LIST_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: true,
        workflowRuns: {},
      };

    case GET_WORKFLOW_RUN_OVERVIEW_PENDING:
      return {
        ...state,
        workflowRunOverview: {},
        workflowRunOverviewFetching: true,
        workflowRunOverviewFetched: false,
        workflowRunOverviewError: null,
      };
    case GET_WORKFLOW_RUN_OVERVIEW_FULFILLED:
      return {
        ...state,
        workflowRunOverview: action.payload.data,
        workflowRunOverviewFetching: false,
        workflowRunOverviewFetched: true,
        workflowRunOverviewError: false,
      };
    case GET_WORKFLOW_RUN_OVERVIEW_REJECTED:
      return {
        ...state,
        workflowRunOverview: {},
        workflowRunOverviewFetching: false,
        workflowRunOverviewFetched: false,
        workflowRunOverviewError: true,
      };

    case GET_WORKFLOW_RUN_COVERAGE_PENDING:
      return {
        ...state,
        workflowRunCoverage: {},
        workflowRunCoverageFetching: true,
        workflowRunCoverageFetched: false,
        workflowRunCoverageError: null,
      };
    case GET_WORKFLOW_RUN_COVERAGE_FULFILLED:
      return {
        ...state,
        workflowRunCoverage: action.payload.data,
        workflowRunCoverageFetching: false,
        workflowRunCoverageFetched: true,
        workflowRunCoverageError: false,
      };
    case GET_WORKFLOW_RUN_COVERAGE_REJECTED:
      return {
        ...state,
        workflowRunCoverage: {},
        workflowRunCoverageFetching: false,
        workflowRunCoverageFetched: false,
        workflowRunCoverageError: true,
      };

    default:
      return state;
  }
}
