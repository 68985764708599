import PieChartWidget from 'components/HighlightsPage/HighlightParts/PieChartWidget/PieChartWidget';
import React, { Component } from 'react';
import './CostlyPieWidget.scss';

export class CostlyPieWidget extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { selectedPie, sentListName } = this.props;
        return (
            <PieChartWidget
                chartData={this.props.chartData}
                selectedPie={selectedPie}
                sentListName={sentListName}

            />
        );
    }
}