import { UI_ICON } from 'assets/font-icons/IconManager';
import React from 'react';
import './PageHeader.scss';

export const PageHeaderBackButtonTitleContainer = ({ children, ...props }) => {
  return (
    <div className="page-header-backbutton-title-container" {...props}>
      {children}
    </div>
  );
};

export const PageHeaderBackButton = ({ ...props }) => {
  return <i className={UI_ICON.CHEVRON.LEFT + ' page-header-back-button'} {...props} />;
};

export const PageHeaderText = ({ children, ...props }) => {
  return (
    <div className="page-header-text" {...props}>
      {children}
    </div>
  );
};

export const PageHeaderTextContainer = ({ children, ...props }) => {
  return (
    <div className="page-header-text-container" {...props}>
      {children}
    </div>
  );
};

export const PageHeaderTitle = ({ children, ...props }) => {
  return (
    <div className="page-header-title" {...props}>
      {children}
    </div>
  );
};

export const PageHeaderTitleContainerSection = ({ children, ...props }) => {
  return (
    <div className="page-header-title-container-section" {...props}>
      {children}
    </div>
  );
};

export const PageHeaderTitleContainer = ({ children, ...props }) => {
  return (
    <div className="page-header-title-container" {...props}>
      {children}
    </div>
  );
};

export const PageHeader = ({ children, ...props }) => {
  return (
    <div className="page-header" {...props}>
      {children}
    </div>
  );
};
