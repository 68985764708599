import React, { Component } from 'react';
import './HorizontalDelimiterGroup.scss';

export default class HorizontalDelimiterGroup extends Component {
  getMiddleIcon = () => {
  	switch (this.props.middleIcon) {
  	case 'check': {
  		return (
  			<span className="checkmark display-block">
  				<div className="checkmark_circle"></div>
  				<div className="checkmark_stem"></div>
  				<div className="checkmark_kick"></div>
  			</span>
  		);
  	}
  	case 'dot': {
  		return <span className="dot-icon display-block" />;
  	}
  	default: {
  		return <></>;
  	}
  	}
  };

  render() {
  	return (
  		<>
  			<div className={`${this.props.stepOneClass} horizontal-line display-block`} />
  			{this.getMiddleIcon()}
  			<div className={`${this.props.stepTwoClass} horizontal-line display-block`} />
  		</>
  	);
  }
}
