export const GET_REPOS = 'GET_REPOS';
export const GET_REPOS_PENDING = 'GET_REPOS_PENDING';
export const GET_REPOS_FULFILLED = 'GET_REPOS_FULFILLED';
export const GET_REPOS_REJECTED = 'GET_REPOS_REJECTED';

export const GET_REPOS_WITHOUT_RELOAD = 'GET_REPOS_WITHOUT_RELOAD';
export const GET_REPOS_WITHOUT_RELOAD_PENDING = 'GET_REPOS_WITHOUT_RELOAD_PENDING';
export const GET_REPOS_WITHOUT_RELOAD_FULFILLED = 'GET_REPOS_WITHOUT_RELOAD_FULFILLED';
export const GET_REPOS_WITHOUT_RELOAD_REJECTED = 'GET_REPOS_WITHOUT_RELOAD_REJECTED';

export const UPDATE_REPO_LIST = 'UPDATE_REPO_LIST';

export const UNWATCH_CI_PROVIDE_REPO = 'UNWATCH_CI_PROVIDE_REPO';
export const UNWATCH_CI_PROVIDE_REPO_PENDING = 'UNWATCH_CI_PROVIDE_REPO_PENDING';
export const UNWATCH_CI_PROVIDE_REPO_FULFILLED = 'UNWATCH_CI_PROVIDE_REPO_FULFILLED';
export const UNWATCH_CI_PROVIDE_REPO_REJECTED = 'UNWATCH_CI_PROVIDE_REPO_REJECTED';
