export const GET_WEBHOOK_INTEGRATION_PREFERENCES = 'GET_LIST_WEBHOOK_INTEGRATION_PREFERENCES';
export const GET_WEBHOOK_INTEGRATION_PREFERENCES_PENDING = 'GET_LIST_WEBHOOK_INTEGRATION_PREFERENCES_PENDING';
export const GET_WEBHOOK_INTEGRATION_PREFERENCES_FULFILLED = 'GET_LIST_WEBHOOK_INTEGRATION_PREFERENCES_FULFILLED';
export const GET_WEBHOOK_INTEGRATION_PREFERENCES_REJECTED = 'GET_LIST_WEBHOOK_INTEGRATION_PREFERENCES_REJECTED';

export const GET_LIST_WEBHOOK_INTEGRATION = 'GET_LIST_WEBHOOK_INTEGRATION';
export const GET_LIST_WEBHOOK_INTEGRATION_PENDING = 'GET_LIST_WEBHOOK_INTEGRATION_PENDING';
export const GET_LIST_WEBHOOK_INTEGRATION_FULFILLED = 'GET_LIST_WEBHOOK_INTEGRATION_FULFILLED';
export const GET_LIST_WEBHOOK_INTEGRATION_REJECTED = 'GET_LIST_WEBHOOK_INTEGRATION_REJECTED';

export const CREATE_BATCH_WEBHOOK_INTEGRATION = 'CREATE_BATCH_WEBHOOK_INTEGRATION';
export const CREATE_BATCH_WEBHOOK_INTEGRATION_PENDING = 'CREATE_BATCH_WEBHOOK_INTEGRATION_PENDING';
export const CREATE_BATCH_WEBHOOK_INTEGRATION_FULFILLED = 'CREATE_BATCH_WEBHOOK_INTEGRATION_FULFILLED';
export const CREATE_BATCH_WEBHOOK_INTEGRATION_REJECTED = 'CREATE_BATCH_WEBHOOK_INTEGRATION_REJECTED';

export const GET_WEBHOOK_INTEGRATION = 'GET_WEBHOOK_INTEGRATION';
export const GET_WEBHOOK_INTEGRATION_PENDING = 'GET_WEBHOOK_INTEGRATION_PENDING';
export const GET_WEBHOOK_INTEGRATION_FULFILLED = 'GET_WEBHOOK_INTEGRATION_FULFILLED';
export const GET_WEBHOOK_INTEGRATION_REJECTED = 'GET_WEBHOOK_INTEGRATION_REJECTED';

export const GET_SLACK_INTEGRATION_LIMIT = 'GET_SLACK_INTEGRATION_LIMIT';
export const GET_SLACK_INTEGRATION_LIMIT_PENDING = 'GET_SLACK_INTEGRATION_LIMIT_PENDING';
export const GET_SLACK_INTEGRATION_LIMIT_FULFILLED = 'GET_SLACK_INTEGRATION_LIMIT_FULFILLED';
export const GET_SLACK_INTEGRATION_LIMIT_REJECTED = 'GET_SLACK_INTEGRATION_LIMIT_REJECTED';

export const DELETE_WEBHOOK_INTEGRATION = 'DELETE_WEBHOOK_INTEGRATION';
export const DELETE_WEBHOOK_INTEGRATION_PENDING = 'DELETE_WEBHOOK_INTEGRATION_PENDING';
export const DELETE_WEBHOOK_INTEGRATION_FULFILLED = 'DELETE_WEBHOOK_INTEGRATION_FULFILLED';
export const DELETE_WEBHOOK_INTEGRATION_REJECTED = 'DELETE_WEBHOOK_INTEGRATION_REJECTED';

export const DISABLE_WEBHOOK_INTEGRATION = 'DISABLE_WEBHOOK_INTEGRATION';
export const DISABLE_WEBHOOK_INTEGRATION_PENDING = 'DISABLE_WEBHOOK_INTEGRATION_PENDING';
export const DISABLE_WEBHOOK_INTEGRATION_FULFILLED = 'DISABLE_WEBHOOK_INTEGRATION_FULFILLED';
export const DISABLE_WEBHOOK_INTEGRATION_REJECTED = 'DISABLE_WEBHOOK_INTEGRATION_REJECTED';

export const ENABLE_WEBHOOK_INTEGRATION = 'ENABLE_WEBHOOK_INTEGRATION';
export const ENABLE_WEBHOOK_INTEGRATION_PENDING = 'ENABLE_WEBHOOK_INTEGRATION_PENDING';
export const ENABLE_WEBHOOK_INTEGRATION_FULFILLED = 'ENABLE_WEBHOOK_INTEGRATION_FULFILLED';
export const ENABLE_WEBHOOK_INTEGRATION_REJECTED = 'ENABLE_WEBHOOK_INTEGRATION_REJECTED';
