import React from 'react';
import ForesightBreadcrumb, { createForesightBreadCrumbDataItem } from 'custom-components/Breadcrumb/Breadcrumb';
import { decodeRepoName } from 'utils/test-run-detail-util';

export function TestsSuitesBreadcrumb(props) {
  const getPublicDomainName = () => {
    const host = window.location.host;
    if (typeof host === 'string') {
      const domainName = window.location.host.split('.app.runforesight')[0];
      return domainName.capitalize();
    }
    return '';
  };

  const pathData = {};
  const data = [];
  const { organizationName } = props.userAccount.userAccount.organization;
  data.push(
    createForesightBreadCrumbDataItem(
      organizationName !== null ? decodeRepoName(organizationName) : getPublicDomainName(),
      pathData,
    ),
  );
  data.push(createForesightBreadCrumbDataItem('Test Suites', pathData));

  return (
    <div style={{ marginTop: 16 }}>
      <ForesightBreadcrumb type="link-item" breadcrumbData={data} />
    </div>
  );
}
