export const GET_ALL_RULES = 'GET_ALL_RULES';
export const GET_ALL_RULES_PENDING = 'GET_ALL_RULES_PENDING';
export const GET_ALL_RULES_FULFILLED = 'GET_ALL_RULES_FULFILLED';
export const GET_ALL_RULES_REJECTED = 'GET_ALL_RULES_REJECTED';

export const CREATE_RULES = 'CREATE_RULES';
export const CREATE_RULES_PENDING = 'CREATE_RULES_PENDING';
export const CREATE_RULES_FULFILLED = 'CREATE_RULES_FULFILLED';
export const CREATE_RULES_REJECTED = 'CREATE_RULES_REJECTED';

export const GET_RULE = 'GET_RULE';
export const GET_RULE_PENDING = 'GET_RULE_PENDING';
export const GET_RULE_FULFILLED = 'GET_RULE_FULFILLED';
export const GET_RULE_REJECTED = 'GET_RULE_REJECTED';

export const GET_WORKFLOW_NAMES = 'GET_WORKFLOW_NAMES';
export const GET_WORKFLOW_NAMES_PENDING = 'GET_WORKFLOW_NAMES_PENDING';
export const GET_WORKFLOW_NAMES_FULFILLED = 'GET_WORKFLOW_NAMES_FULFILLED';
export const GET_WORKFLOW_NAMES_REJECTED = 'GET_WORKFLOW_NAMES_REJECTED';

export const UPDATE_RULES = 'UPDATE_RULES';
export const UPDATE_RULES_PENDING = 'UPDATE_RULES_PENDING';
export const UPDATE_RULES_FULFILLED = 'UPDATE_RULES_FULFILLED';
export const UPDATE_RULES_REJECTED = 'UPDATE_RULES_REJECTED';

export const DELETE_RULES = 'DELETE_RULES';
export const DELETE_RULES_PENDING = 'DELETE_RULES_PENDING';
export const DELETE_RULES_FULFILLED = 'DELETE_RULES_FULFILLED';
export const DELETE_RULES_REJECTED = 'DELETE_RULES_REJECTED';

export const DISABLED_RULES = 'DISABLED_RULES';
export const DISABLED_RULES_PENDING = 'DISABLED_RULES_PENDING';
export const DISABLED_RULES_FULFILLED = 'DISABLED_RULES_FULFILLED';
export const DISABLED_RULES_REJECTED = 'DISABLED_RULES_REJECTED';

export const ENABLE_RULES = 'ENABLE_RULES';
export const ENABLE_RULES_PENDING = 'ENABLE_RULES_PENDING';
export const ENABLE_RULES_FULFILLED = 'ENABLE_RULES_FULFILLED';
export const ENABLE_RULES_REJECTED = 'ENABLE_RULES_REJECTED';
