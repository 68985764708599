import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import {
  getHaveTestRuns,
  getJobCpuMetrics,
  getJobHistory,
  getJobMemoryMetrics,
  getJobTestCount,
  getMostFailedTest,
  getStepCpuMetrics,
  getStepHistory,
  getStepMemoryMetrics,
  getWorkflowHistory,
} from 'store/actions/workflow-timeline';
import RepositoryWorkflowRunTimelineSummary from 'components/RepositoryWorkflowRunTimelinePage/page-components/Summary/RepositoryWorkflowTimelineSummary';

function mapStateToProps(store) {
  return {
    userAccount: store.userAccount,
    ciConnect: store.ciConnect,
    ciRepos: store.ciRepos,
    workflowRunTimeline: store.workflowRunTimeline,
  };
}

function mapDispatchToProps() {
  return {
    getWorkflowHistory(workflowRunId, params, onSuccess, onFail) {
      dispatchWithExceptionHandler(getWorkflowHistory(workflowRunId, params), false, onSuccess, onFail);
    },
    getHaveTestRuns(workflowRunId, onSuccess, onFail) {
      dispatchWithExceptionHandler(getHaveTestRuns(workflowRunId), false, onSuccess, onFail);
    },
    getMostFailedTest(workflowRunId, onSuccess, onFail) {
      dispatchWithExceptionHandler(getMostFailedTest(workflowRunId), false, onSuccess, onFail);
    },
    /* --------------------------------------------------------- */
    getJobTestCount(workflowRunId, jobId, onSuccess, onFail) {
      dispatchWithExceptionHandler(getJobTestCount(workflowRunId, jobId), false, onSuccess, onFail);
    },
    getJobCpuMetrics(workflowRunId, jobId, onSuccess, onFail) {
      dispatchWithExceptionHandler(getJobCpuMetrics(workflowRunId, jobId), false, onSuccess, onFail);
    },
    getJobMemoryMetrics(workflowRunId, jobId, onSuccess, onFail) {
      dispatchWithExceptionHandler(getJobMemoryMetrics(workflowRunId, jobId), false, onSuccess, onFail);
    },
    getJobHistory(workflowRunId, jobName, params, onSuccess, onFail) {
      dispatchWithExceptionHandler(getJobHistory(workflowRunId, jobName, params), false, onSuccess, onFail);
    },
    /* --------------------------------------------------------- */
    getStepHistory(workflowRunId, stepNumber, params, onSuccess, onFail) {
      dispatchWithExceptionHandler(getStepHistory(workflowRunId, stepNumber, params), false, onSuccess, onFail);
    },
    getStepMemoryMetrics(workflowRunId, jobId, startTime, endTime, onSuccess, onFail) {
      dispatchWithExceptionHandler(
        getStepMemoryMetrics(workflowRunId, jobId, startTime, endTime),
        false,
        onSuccess,
        onFail,
      );
    },
    getStepCpuMetrics(workflowRunId, jobId, startTime, endTime, onSuccess, onFail) {
      dispatchWithExceptionHandler(
        getStepCpuMetrics(workflowRunId, jobId, startTime, endTime),
        false,
        onSuccess,
        onFail,
      );
    },
  };
}

const RepositoryWorkflowRunTimelineSummaryContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepositoryWorkflowRunTimelineSummary),
);
export default RepositoryWorkflowRunTimelineSummaryContainer;
