import React, { Component } from 'react';
import { MainLayoutContainer } from 'containers';
import routeList from 'routes';
import {
  TestRunDetailHeaderContainer,
  TestRunOverviewCardContainer,
  TestRunOverviewSummaryCardContainer,
  TestRunSuiteCardListContainer,
  TestRunTestHistoryChartContainer,
  TestRunTestTabsContainer,
} from 'containers';
import './TestRunTestPerformancePage.scss';
import { getTestCaseHistoryDetail, getTestCaseOverviewDetail } from 'utils/test-detail-util';
import {
  getTestRunOverviewObj,
  getTestRunSuitesObj,
  isSuitesForceFetchNeeded,
  TESTRUN_OVERVIEW_PAGE_TYPE,
} from 'utils/test-run-detail-util';
import { defineTestCasePageTitle } from 'utils/page-title-util';
import { dispatchWithCacheCheck } from 'utils/dispatch-with-cache-check-handler';

class TestRunTestPerformancePage extends Component {
  componentDidMount() {
    this.fetchData(this.props);
  }

  getTestRunSuitesData = props => {
    const { testRunId } = props.match.params;
    const { testRunDetailMap } = props.testRunDetails;
    return getTestRunSuitesObj(testRunDetailMap, testRunId);
  };

  getTestOverviewData = props => {
    const { transactionId } = props.match.params;
    const { testDetailMap } = props.testDetails;
    return getTestCaseOverviewDetail(testDetailMap, transactionId);
  };

  getTestRunOverviewData = props => {
    const { testRunId } = props.match.params;
    const { testRunDetailMap } = props.testRunDetails;
    return getTestRunOverviewObj(testRunDetailMap, testRunId);
  };

  getTestHistoryData = props => {
    const { transactionId } = props.match.params;
    const { testDetailMap } = props.testDetails;
    return getTestCaseHistoryDetail(testDetailMap, transactionId);
  };

  fetchData = props => {
    const { ciProvider, repoOwner, repoName, testRunId, transactionId } = props.match.params;
    const testSuiteObj = this.getTestRunSuitesData(props);
    const testRunOverviewObj = this.getTestRunOverviewData(props);
    const testOverviewObj = this.getTestOverviewData(props);
    const isSuiteFetchingForce = isSuitesForceFetchNeeded(testSuiteObj, testRunOverviewObj);

    // console.log('Fetchingggg Suits' + testRunId);
    dispatchWithCacheCheck(testSuiteObj, () => props.getTestRunDetailTestSuits(testRunId), isSuiteFetchingForce);
    dispatchWithCacheCheck(testRunOverviewObj, () =>
      props.getTestRunDetailOverview(ciProvider, repoOwner, repoName, testRunId),
    );
    dispatchWithCacheCheck(testOverviewObj, () => props.getTestOverview(transactionId));
    this.fetchHistoryData(props); //Extra Call Needed if Performance Second Tab and TestOverview Called Before and not Trigger fetchHistoryData
  };

  fetchHistoryData = props => {
    const { transactionId } = props.match.params;
    const testOverviewObj = this.getTestOverviewData(props);

    //TestOverviewOK Then Get History... We need suiteName, testName and endTime...
    if (testOverviewObj && testOverviewObj.fetched) {
      const { suiteName, testName, endTime } = testOverviewObj.testOverview;
      if (suiteName && testName && endTime) {
        const testHistoryObj = this.getTestHistoryData(props);
        dispatchWithCacheCheck(testHistoryObj, () => props.getTestHistory(transactionId, suiteName, testName, endTime));
      } else {
        console.error(`${transactionId} test overview is empty`);
      }
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { testRunId, transactionId } = this.props.match.params;
    const { testRunId: testRunIdOld, transactionId: transactionIdOld } = nextProps.match.params;
    // console.log('TP_testRunId', testRunId, testRunIdOld);
    // console.log('TP_transactionId', transactionId, transactionIdOld);
    if (testRunId !== testRunIdOld || transactionId !== transactionIdOld) {
      // console.log('fetched Performance');
      this.fetchData(nextProps);
    }
    this.fetchHistoryData(nextProps);
  }

  render() {
    return (
      <MainLayoutContainer title={defineTestCasePageTitle(this.props, routeList.testRunTestPerformance.title)}>
        <div className="test-run-testcase-performance-container">
          <div className="header">
            <TestRunDetailHeaderContainer />
          </div>
          <div className="content">
            <div className="content-left">
              <TestRunSuiteCardListContainer />
            </div>
            <div className="content-right">
              <div className="line">
                <TestRunOverviewCardContainer pageType={TESTRUN_OVERVIEW_PAGE_TYPE.TEST_CASE} />
              </div>
              <div className="line">
                <TestRunTestTabsContainer selectedTab="performance" />
              </div>
              <div className="line">
                <TestRunOverviewSummaryCardContainer type="TestCaseOverview" />
              </div>
              <div className="line">
                <TestRunTestHistoryChartContainer />
              </div>
            </div>
          </div>
        </div>
      </MainLayoutContainer>
    );
  }
}

export default TestRunTestPerformancePage;
