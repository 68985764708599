import React, { useEffect, useState } from 'react';
import './ListCards.scss';
import { LoadingDimmer, SomethingError } from 'components/ComponentPlaceholder';
import { Tooltip } from 'components/Tooltip';
import { UI_ICON } from 'assets/font-icons/IconManager';
import { hasArrayElement } from 'utils/array-util';

export const ListCardsContainer = ({ children, withBorder, ...props }) => {
  return (
    <table
      className={withBorder ? 'list-cards-main-container-parent with-border' : 'list-cards-main-container-parent'}
      {...props}
    >
      {children}
    </table>
  );
};

export const ListCardsHeader = ({ columnsObjectsArray, clickedSort = () => {}, ...props }) => {
  const [sortData, setSortData] = useState({ field: '', order: '' });

  const handleSortClick = data => {
    setSortData(prevState => ({ field: data, order: prevState.order === 'DESC' ? 'ASC' : 'DESC' }));
  };

  useEffect(() => {
    clickedSort(sortData);
  }, [sortData]);

  const hasSortableItem = (sortText, order) => {
    if (sortData.field === sortText) {
      return order;
    }
  };

  return (
    <thead>
      <tr className="list-cards-header" {...props}>
        {columnsObjectsArray &&
          columnsObjectsArray.map((item, index) => {
            return (
              <th
                colSpan={item.colSpan}
                key={item + index}
                className={'list-cards-header-items-parent'}
                style={{ ...item?.style?.columnStyle }}
              >
                <div className="list-cards-header-column-wrapper">
                  <div
                    className={'list-cards-header-items'}
                    onClick={item?.sortable ? () => handleSortClick(item.text) : () => {}}
                  >
                    <div className="text">{item.text}</div>
                    {item?.sortable && (
                      <div className="sortable-icon-container">
                        <i
                          className={
                            hasSortableItem(item.text, sortData.order) === 'ASC'
                              ? `${UI_ICON.SORT.UP} selected`
                              : UI_ICON.SORT.UP
                          }
                        />
                        <i
                          className={
                            hasSortableItem(item.text, sortData.order) === 'DESC'
                              ? `${UI_ICON.SORT.DOWN} selected`
                              : UI_ICON.SORT.DOWN
                          }
                        />
                      </div>
                    )}
                  </div>
                  {item.tooltipText && (
                    <div className="header-icon-style">
                      <Tooltip blackEdition content={<>{item.tooltipText}</>}>
                        <i className={UI_ICON.ALERT.INFO_BLUE_CIRCLE + ' column-header-icon'} />
                      </Tooltip>
                    </div>
                  )}
                </div>
                {item.children && <div className="list-card-header-component">{item.children}</div>}
              </th>
            );
          })}
      </tr>
    </thead>
  );
};

export const ListCardsBody = ({
  fetched,
  fetching,
  error,
  children,
  colSpan = '4',
  infiniteFetching,
  tableContent,
  emptyContent,
  ...props
}) => {
  if (fetched || infiniteFetching) {
    if (!hasArrayElement(tableContent)) {
      return (
        <tbody className="list-cards-body-loading" {...props}>
          <tr>
            <td colSpan={colSpan}>{emptyContent}</td>
          </tr>
        </tbody>
      );
    } else {
      return (
        <tbody className="list-cards-body" {...props}>
          {children}
          {infiniteFetching && (
            <tr>
              <td colSpan={colSpan}>
                <LoadingDimmer msg="Loading..." />
              </td>
            </tr>
          )}
        </tbody>
      );
    }
  } else if (fetching) {
    return (
      <tbody className="list-cards-body-loading" {...props}>
        <tr>
          <td colSpan={colSpan}>
            <LoadingDimmer msg="Loading..." />
          </td>
        </tr>
      </tbody>
    );
  } else if (error) {
    return (
      <tbody className="list-cards-body-loading" {...props}>
        <tr>
          <td colSpan={colSpan}>
            <SomethingError msg={error} />;
          </td>
        </tr>
      </tbody>
    );
  } else {
    return (
      <tbody className="list-cards-body-loading" {...props}>
        <tr>
          <td colSpan={colSpan}>
            <LoadingDimmer msg="Loading..." />
          </td>
        </tr>
      </tbody>
    );
  }
};

export const ListCardsRowBody = ({ children, withClickIcon, ...props }) => {
  return (
    <tr className="list-cards-body-row" {...props}>
      {children}
      {withClickIcon && (
        <div className="list-cards-message">
          <div className="with-click-icon">
            <i className={UI_ICON.CHEVRON.RIGHT} />
          </div>
        </div>
      )}
    </tr>
  );
};

export const ListCardsCellContainer = ({ children, ...props }) => {
  return (
    <td className="list-cards-message" {...props}>
      {children}
    </td>
  );
};
