import React, { Component } from 'react';
import { getStringColor } from 'utils/color-util';
import './CostlyListWidget.scss';

export class CostlyListWidget extends Component {
  renderWithColor = str => {
    return getStringColor(str);
  };

  handleListMouseOver = (event, el) => {
    const { handleSentListName } = this.props;
    handleSentListName(el.repoWorkflowName);
  };

  handleListMouseLeave = () => {
    const { handleSentListName } = this.props;
    handleSentListName(null);
  };

  render() {
    const { data, handlerId, handleName } = this.props;

    const tempData = data.map(el => {
      let newWorkflowName = el?.repo?.fullName + '/' + el?.name;
      return {
        repoWorkflowName: newWorkflowName,
        totalCost: el.totalCost,
        pathData: {
          workflowId: el.id,
          workflowName: newWorkflowName,
          repoFullName: el?.repo?.fullName,
        },
      };
    });
    const rowJSX = tempData.map((el, index) => {
      return (
        <tr
          className={`${handleName === el.repoWorkflowName ? 'active-line' : ''} costly-list-widget-row`}
          key={index}
          onClick={event => handlerId(event, el.pathData)}
          onMouseEnter={event => this.handleListMouseOver(event, el)}
          onMouseLeave={this.handleListMouseLeave}
          style={{ height: '24px' }}
        >
          <td>
            <div className="workflow-id">
              {index + 1}
              <span
                className="workflow-id-color"
                style={{ backgroundColor: getStringColor(el.repoWorkflowName) }}
              ></span>
            </div>
          </td>
          <td>
            <div className="workflow-name">
              <span className="workflow-list-cell-text"> {el.repoWorkflowName}</span>
            </div>
          </td>
          <td>
            <div className="workflow-stat">
              <span className="workflow-list-cell-text"> ${el.totalCost.toFixed(2)}</span>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <div className="costly-widget-container">
        <table className="costly-list-table" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <th className="header-workflow-id"></th>
              <th style={{ textAlign: 'left' }} className="header-workflow-name">
                Workflow name
              </th>
              <th style={{ textAlign: 'right' }} className="header-workflow-name">
                Total Cost
              </th>
            </tr>
            {rowJSX}
          </tbody>
        </table>
      </div>
    );
  }
}
