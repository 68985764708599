export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const GET_ALL_PROJECTS_PENDING = 'GET_ALL_PROJECTS_PENDING';
export const GET_ALL_PROJECTS_FULFILLED = 'GET_ALL_PROJECTS_FULFILLED';
export const GET_ALL_PROJECTS_REJECTED = 'GET_ALL_PROJECTS_REJECTED';
export const CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT';
export const CREATE_NEW_PROJECT_PENDING = 'CREATE_NEW_PROJECT_PENDING';
export const CREATE_NEW_PROJECT_FULFILLED = 'CREATE_NEW_PROJECT_FULFILLED';
export const CREATE_NEW_PROJECT_REJECTED = 'CREATE_NEW_PROJECT_REJECTED';
export const SET_PROJECT = 'SET_PROJECT';
export const SET_PROJECT_PENDING = 'SET_PROJECT_PENDING';
export const SET_PROJECT_FULFILLED = 'SET_PROJECT_FULFILLED';
export const SET_PROJECT_REJECTED = 'SET_PROJECT_REJECTED';
export const GET_PROJECT_BADGE = 'GET_PROJECT_BADGE';
export const GET_PROJECT_BADGE_PENDING = 'GET_PROJECT_BADGE_PENDING';
export const GET_PROJECT_BADGE_FULFILLED = 'GET_PROJECT_BADGE_FULFILLED';
export const GET_PROJECT_BADGE_REJECTED = 'GET_PROJECT_BADGE_REJECTED';
export const SET_REPO_SELECTED_PROJECT = 'SET_REPO_SELECTED_PROJECT';
export const SET_REPO_SELECTED_PROJECT_REJECTED = 'SET_REPO_SELECTED_PROJECT_REJECTED';
export const SET_REPO_SELECTED_PROJECT_PENDING = 'SET_REPO_SELECTED_PROJECT_PENDING';
export const SET_REPO_SELECTED_PROJECT_FULFILLED = 'SET_REPO_SELECTED_PROJECT_FULFILLED';
export const SET_REPO_SELECTED_PROJECT_FLUSHED = 'SET_REPO_SELECTED_PROJECT_FLUSHED';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_PENDING = 'DELETE_PROJECT_PENDING';
export const DELETE_PROJECT_FULFILLED = 'DELETE_PROJECT_FULFILLED';
export const DELETE_PROJECT_REJECTED = 'DELETE_PROJECT_REJECTED';
export const SET_PROJECT_SELECTION_COMPONENT_STATE = 'SET_PROJECT_SELECTION_COMPONENT_STATE';
