export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_PENDING = 'UPDATE_USER_PROFILE_PENDING';
export const UPDATE_USER_PROFILE_FULFILLED = 'UPDATE_USER_PROFILE_FULFILLED';
export const UPDATE_USER_PROFILE_REJECTED = 'UPDATE_USER_PROFILE_REJECTED';

export const CHANGE_CURRENT_USER_INFORMATION = 'CHANGE_CURRENT_USER_INFORMATION';
export const CHANGE_CURRENT_USER_INFORMATION_PENDING = 'CHANGE_CURRENT_USER_INFORMATION_PENDING';
export const CHANGE_CURRENT_USER_INFORMATION_FULFILLED = 'CHANGE_CURRENT_USER_INFORMATION_FULFILLED';
export const CHANGE_CURRENT_USER_INFORMATION_REJECTED = 'CHANGE_CURRENT_USER_INFORMATION_REJECTED';

export const UPDATE_USER_LAST_LOGIN_DATE = 'UPDATE_USER_LAST_LOGIN_DATE';
