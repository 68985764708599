import React, { Component } from 'react';
import './Layout.scss';
class Layout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      firstItem,
      secondItem,
      thirdItem,
      fourthItem,
      fifthItem,
      sixthItem,
      seventhItem,
      eigthItem,
      layoutFirstRowRef,
      layoutSecondRowRef,
      layoutThirdRowRef,
      widthHeaders,
      withRef = false,
    } = this.props;

    return (
      <div className="layout-container">
        <div className="layout-container-wrapper">
          {withRef && <div ref={layoutFirstRowRef} />}
          <div className={`section ${widthHeaders ? 'section-none' : ''}`}>Status</div>
          <div className={`status-section-desc ${widthHeaders ? 'section-none' : ''}`}>
            Keep an eye on success and failures, and track how your workflows are performing. &nbsp;
            <a href="https://docs.runforesight.com/features/highlights#status" target="_blank" rel="noreferrer">
              Learn how we calculate status.{' '}
            </a>
          </div>
          <div className="first-row-main-container">
            <div className="first-item">{firstItem}</div>
            <div className="second-item">{secondItem}</div>
          </div>
          <div className="row-container">
            <div className="third-item">{thirdItem}</div>
          </div>
          {withRef && <div ref={layoutSecondRowRef} />}
          <div className={`section ${widthHeaders ? 'section-none' : ''}`}>GitHub Actions Cost</div>
          <div className={`status-section-desc ${widthHeaders ? 'section-none' : ''}`}>
            This is an estimated cost of your workflows. Your GitHub package is not deducted. You can detect the most
            costly workflows. &nbsp;
            <a href="https://docs.runforesight.com/features/highlights#cost" target="_blank" rel="noreferrer">
              Learn how we calculate the estimated costs.
            </a>
          </div>
          <div className="row-container">
            <div className="forth-item">{fourthItem}</div>
          </div>
          <div className="row-container">
            <div className="fifth-item">{fifthItem}</div>
          </div>
          {withRef && <div ref={layoutThirdRowRef} />}
          <div className={`section ${widthHeaders ? 'section-none' : ''}`}>Duration</div>
          <div className={`status-section-desc ${widthHeaders ? 'section-none' : ''}`}>
            Reveal how long it usually takes for your workflow to complete successfully, gather insights to optimize the
            workflow's duration, and assess the duration trend. &nbsp;
            <a href="https://docs.runforesight.com/features/highlights#duration" target="_blank" rel="noreferrer">
              Learn how we calculate the duration.
            </a>
          </div>
          <div className="row-container">
            <div className="sixth-item">{sixthItem}</div>
          </div>
          <div className="row-container">
            <div className="seventh-item">{seventhItem}</div>
          </div>
          <div className="row-container">
            <div className="eight-item">{eigthItem}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;
