import { connect } from 'react-redux';
import { TestRunTestPlatformDashBtn } from 'components';
import { withRouter } from 'react-router';

function mapStateToProps(store, props) {
	return {
		testDetails: store.testDetails,
		testRunDetails: store.testRunDetails,
		isSuiteTraceButton: props.isSuiteTraceButton,
	};
}

function mapDispatchToProps() {
	return {};
}

const TestRunTestPlatformBtnDashContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TestRunTestPlatformDashBtn),
);

export default TestRunTestPlatformBtnDashContainer;
