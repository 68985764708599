import { UI_ICON } from 'assets/font-icons/IconManager';
import { CustomModal } from 'components/CustomModal';
import { RedButton } from 'custom-components/Button/Button';
import { PageSubHeader, PageSubHeaderTitle } from 'custom-components/PageSubHeader/PageSubHeader';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import { ToastConfig } from 'utils/toast-util';
import './CancelPlan.scss';

class CancelPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }

  openCancelModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  onCancelModalApprove = () => {
    const organizationId = this.props.organizationId;

    this.props.cancelSubscription(
      organizationId,
      () => {
        this.setState({
          modalIsOpen: false,
        });
        this.props.onCancelSubscriptionSucceded();
      },
      () => {
        toast.error('Cancel plan failed', ToastConfig);
      },
    );
  };

  onCancelModalCancel = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  getCancelAproveModal = () => {
    const { modalIsOpen } = this.state;
    return (
      <CustomModal
        isOpen={modalIsOpen}
        onModalClose={this.onCancelModalCancel}
        content="Are you sure you want to cancel your plan?"
        dimmer={false}
        className="cancel-plan-modal"
        size="tiny"
      >
        <div className="cancel-plan-modal-container">
          <div className="cancel-plan-modal-header">
            <i className={UI_ICON.ALERT.WARNING} />
            <span className="cancel-plan-modal-header-title">Cancel Plan</span>
          </div>
          <div className="cancel-plan-modal-content">
            <div className="cancel-plan-modal-content-header-info">By cancelling your plan:</div>
            <ul className="cancel-plan-list">
              <li>Your plan will be downgraded to the free plan.</li>
              <li>If you have more than 20 committers, you will lost access to your Foresight data.</li>
            </ul>
            <div className="cancel-plan-modal-content-header-info-footer">Do you want to proceed?</div>
          </div>
          <div className="cancel-plan-modal-footer">
            <Button secondary onClick={this.onCancelModalCancel}>
              Close
            </Button>
            <Button loading={this.props.cancelSubscriptionResult?.fetching} primary onClick={this.onCancelModalApprove}>
              Cancel Plan
            </Button>
          </div>
        </div>
      </CustomModal>
    );
  };

  render() {
    let cancelPlanDisplay = false;
    const { activeSubscription } = this.props.activeSubscription;
    if (!activeSubscription.trial) {
      const activeCommitterLimit = activeSubscription.activeCommitterLimit;
      const freeCommitterLimit = activeSubscription.freeCommitterLimit;
      if (activeCommitterLimit > freeCommitterLimit) {
        cancelPlanDisplay = true;
      }
    }

    if (cancelPlanDisplay) {
      return (
        <section className="cancel-plan-container">
          <PageSubHeader style={{ paddingBottom: '12px' }}>
            <PageSubHeaderTitle style={{ color: '#fb4932' }}>Cancel Plan</PageSubHeaderTitle>
          </PageSubHeader>

          <div className="cancel-plan-content">
            <div className="cancel-plan-content-header-info">
              If you cancel now, you can still access your data until next billing date.
            </div>
            <RedButton content="Cancel" onClick={() => this.openCancelModal()} />
          </div>
          {this.getCancelAproveModal()}
        </section>
      );
    } else {
      return '';
    }
  }
}

export default CancelPlan;
