import { LoadingDimmer } from 'components/ComponentPlaceholder';
import React, { Component } from 'react';
import { JobSummary } from './JobSummary/JobSummary';
import './RepositoryWorkflowTimelineSummary.scss';
import { StepSummary } from './StepSummary/StepSummary';
import WorkflowSummary from './WorkflowSummary/WorkflowSummary';

class RepositoryWorkflowRunTimelineSummary extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { workflowId, ciProvider, repoOwner, repoName } = this.props.match.params;
    const {
      workflowRunTimelineTraceChart: {
        workflow: { id },
      },
    } = this.props.workflowRunTimeline;
    this.props.getWorkflowHistory(id, { provider: ciProvider, owner: repoOwner, repoName });
    this.props.getHaveTestRuns(workflowId);
    this.props.getMostFailedTest(workflowId);
  };

  render() {
    const { match, workflowRunTimeline, onClickDetails } = this.props;
    const {
      workflowRunTimelineTraceChartFetching,
      workflowRunTimelineTraceChart,
      workflowHistoryContent,
      haveTestRunContent,
      haveTestRunContentFetching,
      mostFailedTestContent,
    } = workflowRunTimeline;
    const { jobId, workflowId, stepId } = match.params;
    if (workflowRunTimelineTraceChartFetching || haveTestRunContentFetching) {
      return <LoadingDimmer />;
    }
    return (
      <div>
        {workflowId && !jobId && !stepId && (
          <WorkflowSummary
            history={this.props.history}
            matchParams={this.props.match.params}
            workflowRunOverview={workflowRunTimelineTraceChart}
            workflowHistoryContent={workflowHistoryContent}
            haveTestRunContent={haveTestRunContent}
            mostFailedTest={mostFailedTestContent}
          />
        )}

        {workflowId && jobId && !stepId && (
          <JobSummary
            onClickDetails={onClickDetails}
            workflowRunId={workflowId}
            jobId={jobId}
            jobSummaryProps={this.props}
          />
        )}
        {workflowId && jobId && stepId && (
          <StepSummary
            onClickDetails={onClickDetails}
            workflowRunId={workflowId}
            stepId={stepId}
            jobId={jobId}
            stepSummaryProps={this.props}
          />
        )}
      </div>
    );
  }
}

export default RepositoryWorkflowRunTimelineSummary;
