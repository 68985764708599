import { WidgetCard } from 'components/HighlightsPage/HighlightParts/WidgetCard';
import React, { Component } from 'react';
import { calcDisplayedTime } from 'utils/date-util';
import './AvgWorkflowDurationChart.scss';

export class AvgWorkflowDurationChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: [],
            referenceValue: null,
        };
    }

    componentDidMount() {
        this.convertHistogramData();
        this.calculateReferanceLine();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.convertHistogramData();
        }
    }

    calculateReferanceLine() {
        const { data } = this.props;

        this.setState({
            referenceValue: data.averageDuration,
        });
    }

    convertHistogramData() {
        const { data } = this.props;
        const chartData = {};
        let newWorkflowName = data?.repo?.fullName + '/' + data.name;
        if (Object.getPrototypeOf(data) === Object.prototype) {
            for (const [key, value] of Object.entries(data.durations).sort()) {
                const obj = chartData[key];
                if (obj) {
                    chartData[key] = {
                        ...obj,
                        [newWorkflowName]: value,
                    };
                }
                else {
                    if (key !== 'repoWorkflowName') {
                        chartData[key] = {
                            [newWorkflowName]: value,
                            time: key,
                        };
                    }
                }
            }
        } else {
            data.forEach((el) => {
                let newWorkflowName = data?.repo?.fullName + '/' + data.name;
                for (const [key, value] of Object.entries(el).sort()) {
                    const obj = chartData[key];
                    if (obj) {
                        chartData[key] = {
                            ...obj,
                            [newWorkflowName]: value,
                        };
                    }
                    else {
                        if (key !== 'repoWorkflowName') {
                            chartData[key] = {
                                [newWorkflowName]: value,
                                time: key,
                            };
                        }

                    }
                }
            }
            );
        }

        const convertData = Object.values(chartData);
        this.setState({
            chartData: convertData,
        });
    }

    render() {
        const { timeRange } = this.props;
        const { chartData, referenceValue } = this.state;
        return (
            <div>
                <div className='avg-duration-chart-title'>Avg. Workflow Duration</div>
                <WidgetCard
                    timeRange={timeRange}
                    chartData={chartData}
                    legendValueFormatter={(entry) => calcDisplayedTime(entry.value) + ' min'}
                    timeConfig
                    refLineValue={referenceValue}
                />
            </div>
        );
    }
}