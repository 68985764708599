import { connect } from 'react-redux';
import { RepositoryTabs } from 'components';
import { withRouter } from 'react-router';

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}

const PredefinedRulesContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(RepositoryTabs));
export default PredefinedRulesContainer;
