import {
  GET_WORKFLOW_VIEW_FILTERS_PENDING,
  GET_WORKFLOW_VIEW_FILTERS_REJECTED,
  GET_WORKFLOW_VIEW_FILTERS_FULFILLED,
} from 'store/constants/workflow-view-filters';

const workflowViewFiltersInitialState = {
  workflowViewFilters: {},
  fetching: false,
  error: false,
  fetched: false,
};

export default function workflowViewFilters(state = workflowViewFiltersInitialState, action) {
  switch (action.type) {
    case GET_WORKFLOW_VIEW_FILTERS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_WORKFLOW_VIEW_FILTERS_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: true,
        workflowViewFilters: {},
      };
    case GET_WORKFLOW_VIEW_FILTERS_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        workflowViewFilters: action.payload.data,
      };
    default:
      return state;
  }
}
