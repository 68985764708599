import { connect } from 'react-redux';
import { TestRunTestHistoryChart } from 'components';
import { withRouter } from 'react-router';

function mapStateToProps(store) {
	return {
		testDetails: store.testDetails,
		testRunDetails: store.testRunDetails,
	};
}

function mapDispatchToProps() {
	return {};
}

const TestRunTestHistoryChartContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TestRunTestHistoryChart),
);

export default TestRunTestHistoryChartContainer;
