import React, { Component } from 'react';
import { Tooltip } from 'components/Tooltip';
import { hasArrayElement } from 'utils/array-util';
import { LoadingDimmer } from 'components';
import PropTypes from 'prop-types';
import './AccordionCheckboxTreeList.scss';
import { ForesightCheckbox } from 'custom-components/Checkbox/Checkbox';
import { Accordion, Icon } from 'semantic-ui-react';

export default class AccordionCheckboxTreeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newDataList: [],
      copyDataList: [],
      parentChecked: false,
      loading: false,
      filterText: null,
      activeIndex: 0,
      isStateFilterGroupCollapsed: true,
      isBranchFilterGroupCollapsed: true,
      isAuthorFilterCollapsed: true,
      isLabelsFilterCollapsed: true,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const convertData = data.map(item => {
      item.checked = item.checked || false;
      item.name = item.name || item.repo.name;
      item.visible = item.visible === undefined ? true : item.visible;
      return { ...item };
    });
    const isParentChecked = convertData.every(item => item.checked === true);

    this.setState({ newDataList: convertData, copyDataList: convertData, parentChecked: isParentChecked });
  }

  handleCheckboxClick = (idx, data) => {
    const { newDataList } = this.state;
    const filteredData = newDataList.find((item, index) => index === idx);

    filteredData.checked = !filteredData.checked;
    let allChecked = newDataList.every(element => element.checked === true);

    this.setState(prevState => {
      return {
        ...prevState,
        newDataList: [...prevState.newDataList],
        parentChecked: allChecked ? true : false,
      };
    });
    data['parentName'] = this.props.parentName;
    this.props.handleCheckboxOnClick(idx, data);
  };

  handleInterminate = () => {
    const { newDataList } = this.state;
    const allChecked = newDataList.every(element => element.checked === true);
    if (allChecked) {
      return false;
    } else {
      return newDataList.some(element => element.checked === true);
    }
  };

  /* A function that is called when the parent checkbox is clicked. It is used to check or uncheck all
 the child checkboxes. */
  handleOwnSelectAllReposToggle = data => {
    const { newDataList } = this.state;
    if (!hasArrayElement(newDataList)) {
      return false;
    }
    newDataList.forEach(element => {
      element.checked = data.checked ? true : false;
    });
    this.setState(prevState => {
      return { ...prevState, newDataList: [...prevState.newDataList], parentChecked: data.checked };
    });
    this.props.handleSelectAllReposToggle(data);
  };

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data !== data) {
      const convertData = data.map(item => {
        item.checked = item.checked || false;
        item.name = item?.name || item?.repo?.name;
        item.visible = item.visible || true;
        return { ...item };
      });
      const isParentChecked = convertData.every(item => item.checked === true);

      this.setState({ newDataList: convertData, copyDataList: convertData, parentChecked: isParentChecked });
    }
  }

  /* Checking if the data is loading or not. If it is loading, it will show the
  loading icon. */
  renderBranches = () => {
    const { loading, fetched, iconPopupJsx, ellipsisLength = 35 } = this.props;
    const { newDataList } = this.state;

    if (loading || !fetched) {
      return (
        <div className="loading-container">
          <LoadingDimmer />
        </div>
      );
    }

    const branches = newDataList.map((item, idx) => {
      const childName = item.name;
      const itemLenght = childName.length;
      const hasIcon = item.withIconName || null;

      return (
        <div className={item.visible ? 'repo-owner-repo-item' : 'repo-owner-repo-item-not-visible'} key={`${idx}}`}>
          {itemLenght > ellipsisLength ? (
            <Tooltip blackEdition={true} content={childName}>
              <ForesightCheckbox
                checked={item.checked}
                label={childName}
                onChange={(e, data) => this.handleCheckboxClick(idx, data)}
                withIconName={hasIcon || null}
                iconPopupJsx={iconPopupJsx}
              />
            </Tooltip>
          ) : (
            <ForesightCheckbox
              checked={item.checked}
              label={childName}
              onChange={(e, data) => this.handleCheckboxClick(idx, data)}
              withIconName={hasIcon || null}
              iconPopupJsx={iconPopupJsx}
            />
          )}
        </div>
      );
    });
    return branches;
  };

  /* A debounce function. */
  handleFilterName = data => {
    this.setState({ loading: true, filterText: data });
    this.servicesValue(data);
  };

  handleClick = property => {
    switch (property) {
      case 'STATE': {
        this.setState({
          isStateFilterGroupCollapsed: !this.state.isStateFilterGroupCollapsed,
        });
        break;
      }
      case 'BRANCH': {
        this.setState({
          isBranchFilterGroupCollapsed: !this.state.isBranchFilterGroupCollapsed,
        });
        break;
      }
      case 'AUTHOR': {
        this.setState({
          isAuthorFilterCollapsed: !this.state.isAuthorFilterCollapsed,
        });
        break;
      }
      case 'LABEL': {
        this.setState({
          isLabelsFilterCollapsed: !this.state.isLabelsFilterCollapsed,
        });
        break;
      }
      default:
        break;
    }
  };

  render() {
    const { parentChecked, newDataList, loading, isBranchFilterGroupCollapsed } = this.state;
    const { parentName, emptyJsx = 'No Data' } = this.props;
    return (
      <Accordion>
        <div className="checkbox-tree-data-list">
          {newDataList.length === 0 && !loading ? (
            <div className="no-repo-in-list">{emptyJsx}</div>
          ) : (
            <Accordion.Title active={!isBranchFilterGroupCollapsed} index={0} className="collapsable-title">
              <div
                className="collapsable-icon-wrapper"
                onClick={() =>
                  this.setState({
                    isBranchFilterGroupCollapsed: !isBranchFilterGroupCollapsed,
                  })
                }
              >
                <Icon
                  className="collapsable-icon"
                  id="icon-resize"
                  name={isBranchFilterGroupCollapsed ? 'angle up' : 'angle down'}
                />
              </div>

              <div className="repo-owner-select-all">
                <ForesightCheckbox
                  indeterminate={this.handleInterminate()}
                  disabled={newDataList.every(item => item.visible === false)}
                  checked={parentChecked}
                  label={parentName}
                  onChange={(e, data) => this.handleOwnSelectAllReposToggle(data)}
                />
              </div>
            </Accordion.Title>
          )}
          {loading ? (
            <LoadingDimmer />
          ) : (
            <div className="repo-owner-repo-list">
              {this.state.isBranchFilterGroupCollapsed && this.renderBranches()}
            </div>
          )}
        </div>
      </Accordion>
    );
  }
}
AccordionCheckboxTreeList.propTypes = {
  data: [PropTypes.arrayOf, PropTypes.instanceOf, PropTypes.objectOf, PropTypes.func],
  loading: PropTypes.bool,
  fetched: PropTypes.bool,
  handleCheckboxOnClick: PropTypes.func,
  handleSelectAllReposToggle: PropTypes.func,
};
