export const GET_TEST_RUNS_LIST = 'GET_TEST_RUNS_LIST';
export const GET_TEST_RUNS_LIST_PENDING = 'GET_TEST_RUNS_LIST_PENDING';
export const GET_TEST_RUNS_LIST_REJECTED = 'GET_TEST_RUNS_LIST_REJECTED';
export const GET_TEST_RUNS_LIST_FULFILLED = 'GET_TEST_RUNS_LIST_FULFILLED';

export const GET_TEST_RUNS_LIST_BY_FILTERS = 'GET_TEST_RUNS_LIST_BY_FILTERS';
export const GET_TEST_RUNS_LIST_BY_FILTERS_PENDING = 'GET_TEST_RUNS_LIST_BY_FILTERS_PENDING';
export const GET_TEST_RUNS_LIST_BY_FILTERS_REJECTED = 'GET_TEST_RUNS_LIST_BY_FILTERS_REJECTED';
export const GET_TEST_RUNS_LIST_BY_FILTERS_FULFILLED = 'GET_TEST_RUNS_LIST_BY_FILTERS_FULFILLED';

export const SET_TEST_RUNS_SELECTED_HIDE_FORK = 'SET_TEST_RUNS_SELECTED_HIDE_FORK';
export const SET_TEST_RUNS_STATUSES_SELECTED = 'SET_TEST_RUNS_STATUSES_SELECTED';
export const SET_TEST_RUNS_BRANCHES_SELECTED = 'SET_TEST_RUNS_BRANCHES_SELECTED';
export const SET_TEST_RUNS_TAGS_SELECTED = 'SET_TEST_RUNS_TAGS_SELECTED';
