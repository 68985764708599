import React, { Component } from 'react';
import { MainLayoutContainer } from 'containers';
import routeList from 'routes';
import { TestRunDetailHeaderContainer, TestRunOverviewCardContainer, TestRunSuiteCardListContainer } from 'containers';
import './TestRunTestSuitePage.scss';
import {
  getTestRunOverviewObj,
  getTestRunSuitesObj,
  isSuitesForceFetchNeeded,
  TESTRUN_OVERVIEW_PAGE_TYPE,
} from 'utils/test-run-detail-util';
import { defineTestRunSuiteOverviewPageTitle } from 'utils/page-title-util';
import { dispatchWithCacheCheck } from 'utils/dispatch-with-cache-check-handler';

export class TestRunTestSuitePage extends Component {
  getTestRunSuitesData = props => {
    const { testRunId } = props.match.params;
    const { testRunDetailMap } = props.testRunDetails;
    return getTestRunSuitesObj(testRunDetailMap, testRunId);
  };

  componentDidMount() {
    this.fetchData(this.props);
  }

  getTestRunOverviewData = props => {
    const { testRunId } = props.match.params;
    const { testRunDetailMap } = props.testRunDetails;
    return getTestRunOverviewObj(testRunDetailMap, testRunId);
  };

  fetchData = props => {
    const { ciProvider, repoOwner, repoName, testRunId } = props.match.params;
    const testSuiteObj = this.getTestRunSuitesData(props);
    const testRunOverviewObj = this.getTestRunOverviewData(props);
    const forceSuitesFetching = isSuitesForceFetchNeeded(testSuiteObj, testRunOverviewObj);

    dispatchWithCacheCheck(testSuiteObj, () => props.getTestRunDetailTestSuits(testRunId), forceSuitesFetching);
    dispatchWithCacheCheck(testRunOverviewObj, () =>
      props.getTestRunDetailOverview(ciProvider, repoOwner, repoName, testRunId),
    );
  };

  render() {
    return (
      <MainLayoutContainer title={defineTestRunSuiteOverviewPageTitle(this.props, routeList.testRunTestSuite.title)}>
        <div className="test-run-suite-container">
          <div className="header">
            <TestRunDetailHeaderContainer />
          </div>
          <div className="content">
            <div className="content-left">
              <TestRunSuiteCardListContainer />
            </div>
            <div className="content-right">
              <TestRunOverviewCardContainer pageType={TESTRUN_OVERVIEW_PAGE_TYPE.TEST_SUITE} />
            </div>
          </div>
        </div>
      </MainLayoutContainer>
    );
  }
}

export default TestRunTestSuitePage;
