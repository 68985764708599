import Loadable from 'react-loadable';
import React from 'react';
import { CallbackPage, ErrorPage } from 'components';

const Loading = props => {
	if (props.error) {
		return <ErrorPage />;
	} else if (props.pastDelay) {
		return <CallbackPage />;
	} else {
		return null;
	}
};

const AsyncLandingPageContainer = Loadable({
	loader: () => import('./LandingPageContainer'),
	loading: Loading,
	delay: 300,
});

export { AsyncLandingPageContainer };
