import {
  GET_ORGANIZATION_LABELS_FULFILLED,
  GET_ORGANIZATION_LABELS_REJECTED,
  GET_ORGANIZATION_WORKFLOW_LABELS_FULFILLED,
  GET_ORGANIZATION_WORKFLOW_LABELS_PENDING,
  GET_ORGANIZATION_WORKFLOW_LABELS_REJECTED,
  CREATE_ORGANIZATION_WORKFLOW_LABELS_FULFILLED,
  CREATE_ORGANIZATION_WORKFLOW_LABELS_PENDING,
  CREATE_ORGANIZATION_WORKFLOW_LABELS_REJECTED,
  ATTACH_ORGANIZATION_WORKFLOW_LABELS_FULFILLED,
  ATTACH_ORGANIZATION_WORKFLOW_LABELS_PENDING,
  ATTACH_ORGANIZATION_WORKFLOW_LABELS_REJECTED,
  DETACH_ORGANIZATION_WORKFLOW_LABELS_FULFILLED,
  DETACH_ORGANIZATION_WORKFLOW_LABELS_PENDING,
  DETACH_ORGANIZATION_WORKFLOW_LABELS_REJECTED,
} from '../constants/organization-workflows-labels';

const initialState = {
  organizationId: null,
  organizationLabels: [],
  organizationWorkflowsLabel: {},

  fetching: false,
  fetched: false,
  error: null,

  update_fetching: false,
  update_fetched: false,
  update_error: null,
};

export default function organizationWorkflowsLabels(state = initialState, action) {
  const { organizationId } = action.meta || { organizationId: undefined };
  switch (action.type) {
    case GET_ORGANIZATION_LABELS_FULFILLED:
      if (organizationId !== state.organizationId) {
        state.organizationLabels = [];
      }

      return {
        ...state,
        organizationId: organizationId,
        organizationLabels: action.payload.data,
      };

    case GET_ORGANIZATION_LABELS_REJECTED:
      return {
        ...state,
        organizationId: organizationId,
        organizationLabels: [],
      };

    //Fetch Settings
    case GET_ORGANIZATION_WORKFLOW_LABELS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_ORGANIZATION_WORKFLOW_LABELS_FULFILLED:
      if (organizationId !== state.organizationId) {
        state.organizationWorkflowsLabel = {};
      }

      return {
        ...state,
        organizationId: organizationId,
        fetching: false,
        fetched: true,
        error: null,
        organizationWorkflowsLabel: action.payload.data,
      };
    case GET_ORGANIZATION_WORKFLOW_LABELS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    //Update Setting
    case CREATE_ORGANIZATION_WORKFLOW_LABELS_FULFILLED:
    case ATTACH_ORGANIZATION_WORKFLOW_LABELS_FULFILLED:
    case DETACH_ORGANIZATION_WORKFLOW_LABELS_FULFILLED:
      return { ...state, update_fetching: true, update_fetched: false, update_error: null };
    case CREATE_ORGANIZATION_WORKFLOW_LABELS_PENDING:
    case ATTACH_ORGANIZATION_WORKFLOW_LABELS_PENDING:
    case DETACH_ORGANIZATION_WORKFLOW_LABELS_PENDING:
      return {
        ...state,
        update_fetching: false,
        update_fetched: true,
        update_error: null,
      };
    case CREATE_ORGANIZATION_WORKFLOW_LABELS_REJECTED:
    case ATTACH_ORGANIZATION_WORKFLOW_LABELS_REJECTED:
    case DETACH_ORGANIZATION_WORKFLOW_LABELS_REJECTED:
      return {
        ...state,
        update_fetching: false,
        update_fetched: false,
        update_error: action.payload,
      };
    default:
      return state;
  }
}
