import React, { Component } from 'react';
import './TestRunOverviewCard.scss';
import { clipTestSuiteName, TESTRUN_OVERVIEW_PAGE_TYPE } from 'utils/test-run-detail-util';
import { TestRunOverviewSortingCardContainer, TestRunOverviewSummaryCardContainer } from 'containers';
import { getTestCaseOverviewDetail } from 'utils/test-detail-util';
import { DOMAIN_ICON } from 'assets/font-icons/IconManager';

class TestRunOverviewCard extends Component {
  getTestOverviewData = props => {
    const { transactionId } = props.match.params;
    const { testDetailMap } = props.testDetails;
    return getTestCaseOverviewDetail(testDetailMap, transactionId);
  };

  renderTestRunOverview = () => {
    return (
      <div className="test-run-overview-card-container test-run">
        <div className="title">
          <div className="name">Test Run Overview</div>
        </div>

        <div className="scroll-content">
          <TestRunOverviewSummaryCardContainer type="TestRunOverview" />
          <TestRunOverviewSortingCardContainer title="Most Erroneous Test Suites" type="MostErroneousSuites" />
          <TestRunOverviewSortingCardContainer title="Slowest Test Suites" type="MostSlowestSuites" />
          <TestRunOverviewSortingCardContainer title="Slowest Tests" type="MostSlowestTests" />
        </div>
      </div>
    );
  };

  renderTestSuiteOverview = () => {
    const { testSuiteName } = this.props.match.params;
    const title = `${clipTestSuiteName(testSuiteName)}`;
    return (
      <div className="test-run-overview-card-container test-suite">
        <div className="title">
          <div className="name">{title}</div>
        </div>
        <div className="scroll-content">
          <TestRunOverviewSummaryCardContainer type="TestSuitOverview" />
          <TestRunOverviewSortingCardContainer title="Most Failed Tests" type="MostErroneousTestsInSuit" />
          <TestRunOverviewSortingCardContainer title="Slowest Tests" type="MostSlowestTestsInSuit" />
        </div>
      </div>
    );
  };

  renderTestCaseOverview = () => {
    const testOverviewObj = this.getTestOverviewData(this.props);

    //TestOverviewOK
    if (testOverviewObj && testOverviewObj.fetched) {
      const { testName, status } = testOverviewObj.testOverview;
      const title = testName;

      let iDOM = '';
      switch (status) {
        case 'SUCCESSFUL':
          iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.PASSED + 'successful'} />;
          break;

        case 'FAILED':
          iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.FAILED + 'failed'} />;
          break;

        case 'ABORTED':
          iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.ABORTED + 'aborted'} />;
          break;

        case 'SKIPPED':
          iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.SKIPPED + 'skipped'} />;
          break;
        default:
          break;
      }

      return (
        <div className="test-run-overview-card-container test-case">
          <div className="title">
            <div className="name">
              {iDOM}
              {title}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="test-run-overview-card-container test-case">
        <div className="title">
          <div className="name">Loading...</div>
        </div>
      </div>
    );
  };

  render() {
    const { pageType } = this.props;
    if (pageType === TESTRUN_OVERVIEW_PAGE_TYPE.TEST_SUITE) {
      return this.renderTestSuiteOverview();
    } else if (pageType === TESTRUN_OVERVIEW_PAGE_TYPE.TEST_CASE) {
      return this.renderTestCaseOverview();
    } else {
      return this.renderTestRunOverview();
    }
  }
}

export default TestRunOverviewCard;
