import {
  GET_JOBS_HISTORY_FULFILLED,
  GET_JOBS_HISTORY_PENDING,
  GET_JOBS_HISTORY_REJECTED,
} from 'store/constants/workflow-jobs-history';

const jobHistoryInitialState = {
  jobHistory: {},
};

export default function workflowRunJobHistory(state = jobHistoryInitialState, action) {
  const { jobId } = action.meta || { jobId: undefined };
  let jobObj = null;
  switch (action.type) {
    case GET_JOBS_HISTORY_PENDING:
      jobObj = getJobObj(state.jobHistory, jobId);
      jobObj.fetching = true;
      jobObj.error = false;
      jobObj.fetched = false;
      return { ...state };
    case GET_JOBS_HISTORY_REJECTED:
      jobObj = getJobObj(state.jobHistory, jobId);
      jobObj.fetching = false;
      jobObj.error = true;
      jobObj.fetched = false;
      return { ...state };
    case GET_JOBS_HISTORY_FULFILLED:
      jobObj = getJobObj(state.jobHistory, jobId);
      jobObj.fetching = false;
      jobObj.error = false;
      jobObj.fetched = true;
      jobObj.historyMap = generateHistoryMap(action.payload.data);
      return { ...state };

    default:
      return state;
  }
}

const getJobObj = (jobHistory, jobId) => {
  const jobObj = jobHistory[jobId];
  if (jobObj !== undefined) return jobObj;
  else {
    jobHistory[jobId] = {
      fetching: false,
      error: false,
      fetched: false,
      historyMap: {},
    };
    return jobHistory[jobId];
  }
};

const generateHistoryMap = stepHistoryList => {
  const historyMap = {};
  stepHistoryList.forEach(step => {
    step.stepName = step.workflowStepDtoList[0].name;
    step.stepNumber = step.workflowStepDtoList[0].number;
    historyMap[step.stepName + '_' + step.stepNumber] = step;
  });
  return historyMap;
};
