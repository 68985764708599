import { TestRunsFilterPRContainer, TestRunsInfiniteTablePRContainer } from 'containers';
import React, { Component } from 'react';
import routeList from 'routes';
import './RepositoryPRTestRunsTab.scss';

export default class RepositoryPRTestRunsTab extends Component {
  redirectProjectsPage = () => {
    this.props.history.push(routeList.projectStart.path);
  };

  render() {
    console.log('TestRunsPage, render; props: ', this.props);
    const { repos, projects } = this.props;
    const { prId } = this.props.match.params;

    return (
      <div className="testruns-page-wrapper">
        <div className="content">
          <div className="content-left">
            <TestRunsFilterPRContainer prId={prId} />
          </div>
          <div className="content-right">
            <div id="testruns-scrollable">
              <TestRunsInfiniteTablePRContainer prId={prId} repos={repos} projects={projects} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
