import React from 'react';
import './ForesightStatusCard.scss';

export const ForesightStatusCard = ({ data, hasVerticalLine = true }) => {
  return (
    <div className="foresight-status-card-main-container">
      {data.map((item, index) => (
        <>
          <div key={index} className="foresight-status-card-item-container">
            <div className="foresight-status-card-item">{item.component}</div>
          </div>
          {hasVerticalLine && data.length - 1 > index && <div className="vertical-line" />}
        </>
      ))}
    </div>
  );
};
