import React from 'react';
import './Badge.scss';

export const Badge = ({ badgeColor, text }) => {
  return (
    <div className="badge-container" style={{ backgroundColor: `#${badgeColor}` }}>
      <div className="badge-text">{text}</div>
    </div>
  );
};
