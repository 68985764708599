import {
  GET_JOB_PROCESS_SPANS_PENDING,
  GET_JOB_PROCESS_SPANS_REJECTED,
  GET_JOB_PROCESS_SPANS_FULFILLED,
} from 'store/constants/workflow-jobs-processes';

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  data: {},
};

export default function workflowJobProcesses(state = initialState, action) {
  switch (action.type) {
    case GET_JOB_PROCESS_SPANS_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: false,
      };
    case GET_JOB_PROCESS_SPANS_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        data: action.payload.data,
      };
    case GET_JOB_PROCESS_SPANS_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: true,
      };
    default:
      return state;
  }
}
