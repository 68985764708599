export const dispatchWithCacheCheck = (cacheObj, dispatchFn, forceFetch) => {
  //This function checks cacheObj statuses. If it's not fetched before then call dispatchFn();
  if (!cacheObj) return;
  if (cacheObj.fetching) return;
  if (cacheObj.fetched) {
    if (forceFetch) {
      dispatchFn();
      return;
    } else {
      return;
    }
  }
  if (cacheObj.error) {
    if (cacheObj.errorTime <= 0) return;

    const currentTime = new Date().getTime();
    const timeoutEndTime = cacheObj.errorTime + 20000; //20sec;
    if (currentTime < timeoutEndTime) return; // 20sec not completed
  }
  dispatchFn();
};
