import moment from 'moment';
import React, { Component } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { hasArrayElement } from 'utils/array-util';
import { computeDateFormatFromInterval, roundData } from 'utils/computeStartDateFromInterval';
import { ENUM_FORMAT_DATE } from 'utils/date-util';
import { toFixed } from 'utils/math';
import './StackAreaChartViewer.scss';

export class StackAreaChartViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: new Array(this.props.dataProps.length).fill(0.8),
    };
  }

  handleMouseEnter = e => {
    const newOpacity = {};
    this.props.dataProps.forEach(type => {
      newOpacity[type.dataKey] = e.name === type.dataKey ? 0.8 : 0.3;
    });
    this.props.yAxisProps.domain = [];
    this.setState({
      opacity: newOpacity,
    });
  };

  handleMouseLeave = () => {
    const newOpacity = {};
    this.props.dataProps.forEach(type => {
      newOpacity[type.dataKey] = 0.8;
    });
    this.props.yAxisProps.domain = [];
    this.setState({
      opacity: newOpacity,
    });
  };

  renderLines = () => {
    return this.props.dataProps.map(prop => {
      return (
        <Area
          type="monotone"
          key={prop.dataKey}
          dataKey={prop.dataKey}
          stackId="1"
          name={prop.name}
          stroke={prop.color}
          fill={prop.color}
          fillOpacity={this.state.opacity[prop.dataKey] || this.state.opacity[0]}
          strokeOpacity={this.state.opacity[prop.dataKey] || this.state.opacity[0]}
          animationDuration={800}
          animationEasing={'ease-in-out'}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        />
      );
    });
  };

  convertChartDataName = dataKey => {
    let newEntryName = dataKey.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    newEntryName = newEntryName.charAt(0).toUpperCase() + newEntryName.slice(1);
    return newEntryName;
  };

  renderLegend = props => {
    const { payload } = props;

    return (
      <div className="area-line-chart-legend-container">
        {payload.map((entry, index) => {
          let newLegendName = this.convertChartDataName(entry.value);
          return (
            <div
              key={'item-' + index}
              name={entry.value}
              className="legend"
              onMouseEnter={() => this.handleMouseEnter({ name: entry.value })}
              onMouseLeave={() => this.handleMouseLeave({ name: entry.value })}
            >
              <div className="legend-icon" style={{ backgroundColor: entry.color }} />
              <div className="legend-title">{newLegendName}</div>
            </div>
          );
        })}
      </div>
    );
  };

  renderTooltip = props => {
    const { payload } = props;
    const { xAxisProps } = this.props;

    let listItemsDOM = '';
    if (hasArrayElement(payload)) {
      listItemsDOM = payload
        .filter(entry => entry.value !== 0)
        .map((entry, index) => {
          let legendValue = toFixed(entry.value, 0);
          if (this.props.legendValueFormatter) {
            legendValue = this.props.legendValueFormatter(entry);
          }
          let newEntryName = this.convertChartDataName(entry?.name);
          return (
            <li key={'item-' + index}>
              <div className="li-item">
                <div className="li-item-left">
                  <div className="legend-icon" style={{ backgroundColor: entry.color }} />
                </div>
                <div className="li-item-right">
                  <div className="legend-name">{newEntryName}</div>
                  <div className="legend-value">{legendValue}</div>
                </div>
              </div>
            </li>
          );
        });
    }

    const popupHeight = listItemsDOM.length * 42 + 40;
    return (
      <div className="highlight-line-chart-tooltip-container" style={{ height: popupHeight }}>
        {hasArrayElement(payload) && (
          <div className="tooltip-header">{xAxisProps.tickAreaFormatter(payload[0].payload?.time)}</div>
        )}
        <ul>{listItemsDOM}</ul>
      </div>
    );
  };

  xAxisTimeFormatter = val => {
    const { data } = this.props;
    const formattedData = roundData(data, 2);
    const times = formattedData.map(el => el.time).sort();
    const startDate = moment(times[0], ENUM_FORMAT_DATE.hours);
    const endDate = moment(times[times.length - 1], ENUM_FORMAT_DATE.hours);

    const dateFormatterString = moment(val, ENUM_FORMAT_DATE.hours)
      .local()
      .format(computeDateFormatFromInterval(startDate, endDate));
    return dateFormatterString;
  };

  render() {
    const { data, size, xAxisProps, yAxisProps, onClick, margin, hasLegend, keyProp, chartDomain } = this.props;
    const styleObj = { width: '100%', height: size.height };

    if (hasArrayElement(data)) {
      const formattedData = roundData(data, 2);
      return (
        <div style={styleObj} key={keyProp}>
          <ResponsiveContainer>
            <AreaChart margin={margin} width={size.width} height={size.height} data={formattedData} onClick={onClick}>
              <XAxis axisLine={false} dataKey={xAxisProps.dataKey} tickFormatter={xAxisProps.tickAreaFormatter} />
              <YAxis
                axisLine={false}
                domain={chartDomain || yAxisProps.domain}
                tickFormatter={yAxisProps.tickAreaFormatter}
              />
              <CartesianGrid stroke="#2A3B50" strokeDasharray="3 3" />
              <Tooltip
                content={this.renderTooltip}
                wrapperStyle={{ zIndex: 1 }}
                allowEscapeViewBox={{ x: false, y: true }}
              />
              {this.renderLines()}
              {hasLegend && <Legend verticalAlign={'top'} iconType="square" content={this.renderLegend} />}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      );
    }

    return <div>Data Not Applicable</div>;
  }
}
