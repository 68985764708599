//Summary Simple Status Cards
export const GET_LATEST_TEN_WORKFLOW_RUNS = 'GET_LATEST_TEN_WORKFLOW_RUNS';
export const GET_LATEST_TEN_WORKFLOW_RUNS_PENDING = 'GET_LATEST_TEN_WORKFLOW_RUNS_PENDING';
export const GET_LATEST_TEN_WORKFLOW_RUNS_FULFILLED = 'GET_LATEST_TEN_WORKFLOW_RUNS_FULFILLED';
export const GET_LATEST_TEN_WORKFLOW_RUNS_REJECTED = 'GET_LATEST_TEN_WORKFLOW_RUNS_REJECTED';

//GitHub Style Heatmap
export const GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS = 'GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS';
export const GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS_PENDING = 'GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS_PENDING';
export const GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS_FULFILLED = 'GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS_FULFILLED';
export const GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS_REJECTED = 'GET_COUNT_OF_30_DAYS_WORKFLOW_RUNS_REJECTED';

//Summary Statistics Overall 
export const GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS = 'GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS';
export const GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS_PENDING = 'GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS_PENDING';
export const GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS_FULFILLED = 'GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS_FULFILLED';
export const GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS_REJECTED = 'GET_SUMMARY_OF_LATEST_10_WORKFLOW_RUNS_REJECTED';
//Standart HeatMap
export const GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS = 'GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS';
export const GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS_PENDING = 'GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS_PENDING';
export const GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS_FULFILLED = 'GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS_FULFILLED';
export const GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS_REJECTED = 'GET_EXECUTION_COUNT_LATEST_10_WORKFLOW_RUNS_REJECTED';

// Duration Summary
export const GET_DURATION_SUMMARY_WORKFLOW_RUNS = 'GET_DURATION_SUMMARY_WORKFLOW_RUNS';
export const GET_DURATION_SUMMARY_WORKFLOW_RUNS_PENDING = 'GET_DURATION_SUMMARY_WORKFLOW_RUNS_PENDING';
export const GET_DURATION_SUMMARY_WORKFLOW_RUNS_FULFILLED = 'GET_DURATION_SUMMARY_WORKFLOW_RUNS_FULFILLED';
export const GET_DURATION_SUMMARY_WORKFLOW_RUNS_REJECTED = 'GET_DURATION_SUMMARY_WORKFLOW_RUNS_REJECTED';

// Cost Line Chart
export const GET_COST_TIME_WORKFLOW_RUNS = 'GET_COST_TIME_WORKFLOW_RUNS';
export const GET_COST_TIME_WORKFLOW_RUNS_PENDING = 'GET_COST_TIME_WORKFLOW_RUNS_PENDING';
export const GET_COST_TIME_WORKFLOW_RUNS_FULFILLED = 'GET_COST_TIME_WORKFLOW_RUNS_FULFILLED';
export const GET_COST_TIME_WORKFLOW_RUNS_REJECTED = 'GET_COST_TIME_WORKFLOW_RUNS_REJECTED';

export const GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS = 'GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS';
export const GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS_PENDING = 'GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS_PENDING';
export const GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS_FULFILLED = 'GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS_FULFILLED';
export const GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS_REJECTED = 'GET_AVERAGE_DURATION_TIME_WORKFLOW_RUNS_REJECTED';

export const GET_JOBS_WORKFLOW_RUNS = 'GET_JOBS_WORKFLOW_RUNS';
export const GET_JOBS_WORKFLOW_RUNS_PENDING = 'GET_JOBS_WORKFLOW_RUNS_PENDING';
export const GET_JOBS_WORKFLOW_RUNS_FULFILLED = 'GET_JOBS_WORKFLOW_RUNS_FULFILLED';
export const GET_JOBS_WORKFLOW_RUNS_REJECTED = 'GET_JOBS_WORKFLOW_RUNS_REJECTED';

export const GET_WORKFLOW_DETAIL = 'GET_WORKFLOW_DETAIL';
export const GET_WORKFLOW_DETAIL_PENDING = 'GET_WORKFLOW_DETAIL_PENDING';
export const GET_WORKFLOW_DETAIL_FULFILLED = 'GET_WORKFLOW_DETAIL_FULFILLED';
export const GET_WORKFLOW_DETAIL_REJECTED = 'GET_WORKFLOW_DETAIL_REJECTED';


