import React, { Component } from 'react';
import './CostWorkflowRunChart.scss';
import { WidgetCard } from 'components/HighlightsPage/HighlightParts/WidgetCard';

export class CostWorkflowRunChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: [],
            referenceValue: null
        };
    }

    componentDidMount() {
        this.convertHistogramData();
        this.calculateReferanceLine();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.convertHistogramData();
        }
    }

    calculateReferanceLine() {
        const { data } = this.props;

        this.setState({
            referenceValue: data.averageCost,
        });
    }

    convertHistogramData() {
        const { data } = this.props;
        const chartData = {};

        let newWorkflowName = data?.repo?.fullName + '/' + data.name;
        for (const [key, value] of Object.entries(data.costs).sort()) {
            const obj = chartData[key];
            if (obj) {
                chartData[key] = {
                    ...obj,
                    [newWorkflowName]: value,
                };
            }
            else {
                chartData[key] = {
                    [newWorkflowName]: value,
                    time: key,
                };
            }
        }

        const convertData = Object.values(chartData);
        this.setState({
            chartData: convertData,
        });
    }

    render() {
        const { chartData, referenceValue } = this.state;
        return (
            <div>
                <div className='single-cost-chart-title'>Cost</div>
                <WidgetCard
                    chartData={chartData}
                    legendValueFormatter={(entry) => entry.value + ' $'}
                    refLineValue={referenceValue}
                />
            </div>
        );
    }
}