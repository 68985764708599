import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { Tooltip } from 'components';
import './Checkbox.scss';

export const ForesightCheckbox = ({ withIconName = null, iconPopupJsx, ...props }) => {
  const chxClassName = props.className ? 'foresight-checkbox ' + props.className : 'foresight-checkbox';

  return (
    <div className="foresight-checkbox-container">
      <Checkbox {...props} className={chxClassName} />
      {withIconName && (
        <Tooltip blackEdition content={iconPopupJsx}>
          <div className="checkbox-icon">
            <i className={withIconName} />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
