import { isEqualDeep } from 'utils/common-util';
import { WORKFLOWRUN_CONCLUSION } from 'utils/workflowrun-conclusion-status';

export const checkIfFiltersActive = filtersObject => {
  if (
    isEqualDeep(filtersObject, {
      repos: [],
      startDate: null,
      endDate: null,
      statuses: [],
    }) ||
    isEqualDeep(filtersObject, {
      repos: [],
      startDate: dateCalculatedBefore,
      endDate: dateCalculatedToday,
      statuses: [],
    })
  ) {
    return false;
  } else {
    return true;
  }
};

export const dateCalculatedBefore = () => {
  let dateCalculatedBefore = new Date();
  dateCalculatedBefore.setDate(dateCalculatedBefore.getDate() - 30);
  dateCalculatedBefore.setHours(0, 0, 0, 0);

  return dateCalculatedBefore;
};

export const dateCalculatedToday = () => {
  let dateCalculatedToday = new Date();
  dateCalculatedToday.setDate(dateCalculatedToday.getDate());
  dateCalculatedToday.setHours(23, 59, 59, 999);

  return dateCalculatedToday;
};

export const checkIfConclusionIsOtherType = statuses => {
  if (
    statuses.includes(WORKFLOWRUN_CONCLUSION.ACTION_REQUIRED) ||
    statuses.includes(WORKFLOWRUN_CONCLUSION.CANCELLED) ||
    statuses.includes(WORKFLOWRUN_CONCLUSION.NEUTRAL) ||
    statuses.includes(WORKFLOWRUN_CONCLUSION.SKIPPED) ||
    statuses.includes(WORKFLOWRUN_CONCLUSION.STALE) ||
    statuses.includes(WORKFLOWRUN_CONCLUSION.TIMED_OUT) ||
    statuses.includes(WORKFLOWRUN_CONCLUSION.UNKNOWN)
  )
    return true;
  else return false;
};
