import { Tooltip } from 'components/Tooltip';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { AvgDurationListWidget } from './AvgDurationListWidget/AvgDurationListWidget';
import { AvgDurationTimeSeriesWidget } from './AvgDurationTimeSeriesWidget/AvgDurationTimeSeriesWidget';
import './TenHightestAvgDurationWorkflows.scss';


const infoText = 'This chart shows your successful workflows\' cumulative average duration calculation. You can track the trend on them and detect if there is any anomaly in increase or decrease in time. If you improve the duration of your workflow, you can see a decline in the average duration. ';

export class TenHightestAvgDurationWorkflows extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLine: null,
            sentListName: null,
        };
    }

    handleSelectedLine = (val) => {
        this.setState({ selectedLine: val });
    };

    handleSentListName = (val) => {
        this.setState({ sentListName: val });
    };

    render() {
        const { avgDurationTimeSeriesWidget, handlerId, title } = this.props;
        const { selectedLine, sentListName } = this.state;

        return (
            <div className='avg-and-total-duration-container'>
                <div className='avg-and-total-duration-header'>
                    {title}&nbsp;<Tooltip hoverable blackEdition content={infoText}>
                        <Icon style={{ height: '20px' }} name="info circle" />
                    </Tooltip>
                </div>

                <div className="avg-and-total-duration-chart-cont">
                    <div className='avg-chart'>
                        <AvgDurationTimeSeriesWidget
                            data={avgDurationTimeSeriesWidget}
                            selectedLineName={this.handleSelectedLine}
                            sentListName={sentListName}

                        />
                    </div>
                    <div className='avg-chart'>
                        <AvgDurationListWidget
                            data={avgDurationTimeSeriesWidget}
                            handlerId={handlerId}
                            handleName={selectedLine}
                            handleSentListName={this.handleSentListName}

                        />
                    </div>
                </div>
            </div>

        );
    }
}