import React, { Component } from 'react';
import { getStringColor } from 'utils/color-util';
import { millisToMAndS } from 'utils/date-util';
import './AvgDurationListWidget.scss';

export class AvgDurationListWidget extends Component {

    renderWithColor = (str) => {
        return getStringColor(str);
    };

    handleListMouseOver = (event, el) => {
        const { handleSentListName } = this.props;
        handleSentListName(el.repoWorkflowName);
    };

    handleListMouseLeave = () => {
        const { handleSentListName } = this.props;
        handleSentListName(null);
    };

    render() {
        const { data, handlerId, handleName } = this.props;

        const tempData = data.map(el => {
            let newWorkflowName = el?.repo?.fullName + '/' + el?.name;
            return {
                repoWorkflowName: newWorkflowName,
                averageDuration: el.averageDuration,
                pathData: {
                    workflowId: el.id,
                    workflowName: newWorkflowName,
                    repoFullName: el?.repo?.fullName,
                }

            };
        });
        const rowJSX = tempData.map((el, index) => {
            return (
                <tr className={`${handleName === el.repoWorkflowName ? 'active-line' : ''} avg-duration-list-widget-row`}
                    key={index}
                    onClick={event => handlerId(event, el.pathData)}
                    onMouseEnter={(event) => this.handleListMouseOver(event, el)}
                    onMouseLeave={this.handleListMouseLeave}
                    style={{ height: '24px' }}
                >
                    <td>
                        <div className='workflow-id'>
                            {index + 1}
                            <span className='workflow-id-color' style={{ backgroundColor: getStringColor(el.repoWorkflowName ? el.repoWorkflowName : '') }}></span>
                        </div>
                    </td>

                    <td>
                        <div className='workflow-name'>
                            <span className='workflow-list-cell-text'> {el.repoWorkflowName ? el.repoWorkflowName : ''}</span>
                        </div>
                    </td>

                    <td>
                        <div className='workflow-stat'>
                            <span className='workflow-list-cell-text'> {millisToMAndS(el.averageDuration)}</span>
                        </div>
                    </td>
                </tr>
            );

        });

        return (
            <div>
                <div className='avg-header-container'>
                    <table className='avg-duration-list-table' cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <th className='header-workflow-id'></th>
                                <th style={{ textAlign: 'left' }} className='header-workflow-name'>Workflow name</th>
                                <th style={{ textAlign: 'right' }} className='header-workflow-duration'>Average Duration</th>
                            </tr>
                            {rowJSX}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}