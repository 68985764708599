import ForesightBreadcrumb, { createForesightBreadCrumbDataItem } from 'custom-components/Breadcrumb/Breadcrumb';
import React from 'react';
import routeList, { computePath } from 'routes';
import { decodeRepoName } from 'utils/test-run-detail-util';

export function TestViewBreadCrumb(props) {
  const getPublicDomainName = () => {
    const host = window.location.host;
    if (typeof host === 'string') {
      const domainName = window.location.host.split('.app.runforesight')[0];
      return domainName.capitalize();
    }
    return '';
  };

  const pathData = {};
  const data = [];
  const { testSuiteName, testCaseName, ciProvider, repoOwner, repoName, workflowId, jobName } = props.match.params;
  const { organizationName } = props.userAccount?.userAccount?.organization || 'UnknownOrg';
  data.push(
    createForesightBreadCrumbDataItem(
      decodeRepoName(organizationName == null ? getPublicDomainName() : organizationName),
      pathData,
    ),
  );
  data.push(
    createForesightBreadCrumbDataItem('Test Suites', pathData, () => {
      props.history.push(routeList.testSuites.path);
    }),
  );
  data.push(
    createForesightBreadCrumbDataItem(
      decodeRepoName(testSuiteName),
      {
        testSuiteName: decodeURIComponent(testSuiteName),
        ciProvider,
        repoOwner,
        repoName,
        workflowId,
        jobName: decodeURIComponent(jobName),
      },

      () => {
        props.history.push(
          computePath(routeList.testSuitesDetail.path, {
            testSuiteName: decodeURIComponent(testSuiteName),
            ciProvider,
            repoOwner,
            repoName,
            workflowId,
            jobName: decodeURIComponent(jobName),
          }),
        );
      },
    ),
  );
  data.push(createForesightBreadCrumbDataItem(decodeRepoName(testCaseName), pathData));

  return (
    <div style={{ marginTop: 16 }}>
      <ForesightBreadcrumb type="link-item" breadcrumbData={data} />
    </div>
  );
}
