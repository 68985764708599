import { Tooltip } from 'components/Tooltip';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { CostlyListWidget } from './CostlyListWidget/CostlyListWidget';
import { CostlyPieWidget } from './CostlyPieWidget/CostlyPieWidget';
import { CostlyTimeSeriesWidget } from './CostlyTimeSeriesWidget/CostlyTimeSeriesWidget';
import './TenCostlyWorkflows.scss';


const infoText = 'You can identify the costly workflows and start investigating to optimize the cost.';

export class TenCostlyWorkflows extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLine: null,
            sentListName: null,
        };
    }

    handleSelectedLine = (val) => {
        this.setState({ selectedLine: val });
    };

    handleSentListName = (val) => {
        this.setState({ sentListName: val });
    };

    render() {
        const { costOfMostCostly10Workflows, handlerId, title } = this.props;
        const { selectedLine, sentListName } = this.state;

        return (
            <div className='ten-most-costly-workflows'>
                <div className='ten-most-costly-workflows-header'>
                    {title}&nbsp;<Tooltip hoverable blackEdition content={infoText}>
                        <Icon style={{ height: '20px' }} name="info circle" />
                    </Tooltip>
                </div>
                <div className='ten-most-costly-workflows-charts-container'>
                    <div className='line-cost-chart'>
                        <CostlyTimeSeriesWidget
                            data={costOfMostCostly10Workflows}
                            selectedLineName={this.handleSelectedLine}
                            sentListName={sentListName}
                        />
                    </div>
                    <div className='pie-cost-chart'>
                        <CostlyPieWidget
                            chartData={costOfMostCostly10Workflows}
                            selectedPie={this.handleSelectedLine}
                            sentListName={sentListName}
                        />
                    </div>
                </div>
                <div className='count-cost-list'>
                    <CostlyListWidget
                        data={costOfMostCostly10Workflows}
                        handlerId={handlerId}
                        handleName={selectedLine}
                        handleSentListName={this.handleSentListName}
                    />
                </div>
            </div>


        );
    }
}