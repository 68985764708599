import { ButtonLink } from 'custom-components/ALink/ALink';
import {
  PageSubHeader,
  PageSubHeaderTitle,
  PageSubHeaderTitleContainer,
} from 'custom-components/PageSubHeader/PageSubHeader';
import React from 'react';
import { hasArrayElement } from 'utils/array-util';
import './StandardPlanTitleAndRedirection.scss';

class StandardPlanTitleAndRedirection extends React.Component {
  render() {
    const { billingPlans } = this.props.billingPlans;
    const { activeSubscription } = this.props.activeSubscription;

    let activeCommitterLimit = 0;
    let freeCommitterLimit = 0;
    let planName = 'Trial Plan';

    if (hasArrayElement(billingPlans) && activeSubscription) {
      activeCommitterLimit = activeSubscription.activeCommitterLimit;
      freeCommitterLimit = activeSubscription.freeCommitterLimit;
      planName = activeCommitterLimit <= freeCommitterLimit ? 'Free Plan' : 'Standard Plan';
    }

    activeCommitterLimit = activeSubscription.activeCommitterLimit;
    freeCommitterLimit = activeSubscription.freeCommitterLimit;

    return (
      <PageSubHeader>
        <PageSubHeaderTitleContainer>
          <PageSubHeaderTitle>{planName}</PageSubHeaderTitle>
          <ButtonLink href="https://www.runforesight.com/pricing">See Plans</ButtonLink>
        </PageSubHeaderTitleContainer>
      </PageSubHeader>
    );
  }
}

export default StandardPlanTitleAndRedirection;
