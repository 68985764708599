import React, { Component } from 'react';
import { CustomModal } from 'components';
import { Button, Image } from 'semantic-ui-react';
import { checkEmailValidation } from 'utils/common-util';
import { toast } from 'react-toastify';
import './UserInvitationModal.scss';

import successCircle from 'assets/images/success-circle.png';
import { ForesightInput } from 'custom-components/Input/Input';

class UserInvitationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddressList: [''],
      notValidEmailAddress: [],
    };
  }

  handleInputOnChange = (value, key) => {
    const { emailAddressList } = this.state;

    emailAddressList[key] = value;

    this.setState({
      emailAddressList,
    });
  };

  removeEmailAddressInput = index => {
    const { emailAddressList } = this.state;

    delete emailAddressList[index];

    this.setState({
      emailAddressList,
    });
  };

  isEmailValid = email => {
    const { notValidEmailAddress } = this.state;
    if (notValidEmailAddress.length === 0 || email.length === 0) {
      return true;
    }

    return notValidEmailAddress.find(x => x === email) === undefined;
  };

  getEmailAddressListInputs = () => {
    const { emailAddressList } = this.state;
    let inputJsx = [];

    for (let itemIndex in emailAddressList) {
      inputJsx.push(
        <div className="invite-input-container">
          <ForesightInput
            placeholder="Enter email address"
            key={itemIndex}
            hasError={!this.isEmailValid(emailAddressList[itemIndex])}
            value={emailAddressList[itemIndex]}
            onChange={e => this.handleInputOnChange(e.target.value, itemIndex)}
            onKeyDown={this.onPressEnterOnInput}
            autoFocus
          />
          {itemIndex !== '0' && (
            <div onClick={() => this.removeEmailAddressInput(itemIndex)} className="minus-line-container">
              <div className="minus-line" />
            </div>
          )}
        </div>,
      );
    }

    return <>{inputJsx.map(item => item)}</>;
  };

  onPressEnterOnInput = e => {
    if (e.key === 'Enter') {
      this.addNewUserEmaillAddressInput();
    }
  };

  addNewUserEmaillAddressInput = () => {
    const { emailAddressList } = this.state;

    emailAddressList.push('');

    this.setState({
      emailAddressList,
    });
  };

  getAddUserButton = () => {
    return (
      <div onClick={this.addNewUserEmaillAddressInput} className="add-user-button">
        + Add User
      </div>
    );
  };

  cancelInvitationModal = isSuccess => {
    this.setState({
      emailAddressList: [''],
    });

    this.props.onUserInviteModalClose(isSuccess);
  };

  onInviteUser = () => {
    let { emailAddressList } = this.state;

    emailAddressList = emailAddressList.filter(item => item && item !== '');

    let notValidEmailAddress = this.checkEmailValidationFromList(emailAddressList);

    if (notValidEmailAddress.length === 0) {
      this.props.inviteUsers(emailAddressList, this.onInviteUserSuccessful, this.onInviteUserFail);
    } else {
      toast.error('Email addresses is not valid. Please check your email addresses.');
    }
  };

  onInviteUserSuccessful = () => {
    toast.success(
      <div className="toast-image-wrapper">
        <Image src={successCircle} />
        Invitations have been sent successfully
      </div>,
    );
    this.cancelInvitationModal(true);
  };

  onInviteUserFail = err => {
    toast.error('Invitation has been failed ' + err.response.data);
  };

  isEmailValid2 = email => {
    if (email !== '') {
      return checkEmailValidation(email);
    } else {
      return false;
    }
  };

  checkEmailValidationFromList = emailAddressList => {
    let notValidEmailAddress = [];
    let isOneOfEmailValid = false;

    emailAddressList.forEach(email => {
      if (!this.isEmailValid2(email)) {
        notValidEmailAddress.push(email);
      } else {
        isOneOfEmailValid = true;
      }
    });

    if (!isOneOfEmailValid) {
      notValidEmailAddress = ['No valid email address'];
    }

    this.setState({
      notValidEmailAddress,
    });

    return notValidEmailAddress;
  };

  getActionButtons = () => {
    const { userInvitationFetching } = this.props.organization;

    return (
      <div className="action-container">
        <Button onClick={() => this.cancelInvitationModal(false)} className="cancel-button">
          Cancel
        </Button>
        <Button
          onClick={this.onInviteUser}
          loading={userInvitationFetching}
          disabled={userInvitationFetching}
          className="submit-button"
        >
          Invite
        </Button>
      </div>
    );
  };

  render() {
    return (
      <CustomModal
        className="invite-modal"
        dimmer={false}
        isOpen={this.props.isUserInvitationModalOpen}
        size="tiny"
        onModalClose={() => this.cancelInvitationModal(false)}
      >
        <h1 className="invite-modal-header">Invite users</h1>
        <p className="invite-modal-text">Start collaborating today by inviting your team to your workspace.</p>
        {this.getEmailAddressListInputs()}
        {this.getAddUserButton()}
        {this.getActionButtons()}
      </CustomModal>
    );
  }
}

export default UserInvitationModal;
