import Loadable from 'react-loadable';
import React from 'react';

const Loading = props => {
	if (props.error) {
		return <div>Error Page</div>;
	} else if (props.pastDelay) {
		return <div>Callback Page</div>;
	} else {
		return null;
	}
};

const AsyncPlaygroundPageContainer = Loadable({
	loader: () => import('./PlaygroundPageContainer'),
	loading: Loading,
	delay: 300,
});

export { AsyncPlaygroundPageContainer };
