import { getLanguage } from './localization';

export function getRandomInt(max) {
	return Math.floor(Math.random() * Math.floor(max));
}

export function toFixed(numObj, fixCount) {
	if (!isNaN(numObj) && typeof numObj.toFixed === 'function') {
		// Details of toLocaleString can be seen here; https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
		return numObj.toLocaleString(getLanguage(), {
			style: 'decimal',
			minimumFractionDigits: fixCount,
			maximumFractionDigits: fixCount,
		});
	} else {
		return numObj;
	}
}
