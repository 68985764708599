import React, { Component } from 'react';
import './TestRunTestScreenshotsViewer.scss';
import { getTestCaseScreenshotsDetail } from 'utils/test-detail-util';
import { seleniumIcon } from './SeleniumIcon';
import { browserStackIcon } from './BrowserStackIcon';
import { LoadingDimmer, NoSSAvailable, SomethingError } from 'components';
import { hasArrayElement } from 'utils/array-util';
import { Modal } from 'semantic-ui-react';

class TestRunTestScreenshotsViewer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			imgUrl: '',
		};
	}
  

  renderModal = () => {
  	return (
  		<Modal
  			closeIcon
  			open={this.state.isOpen}
  			onClose={e => {
  				e.preventDefault();
  				e.stopPropagation();
  				this.setState({ isOpen: false });
  			}}
  		>
  			<div
  				className="ss-modal-content"
  				onClick={e => {
  					e.stopPropagation();
  					this.setState({ isOpen: false });
  				}}
  			>
  				<img src={this.state.imgUrl} alt="import"></img>
  			</div>
  		</Modal>
  	);
  };

  render() {
  	const { testDetailMap } = this.props.testDetails;
  	const { transactionId } = this.props.match.params;
  	const testScreenshotsObj = getTestCaseScreenshotsDetail(testDetailMap, transactionId);
  	if (testScreenshotsObj) {
  		const { fetched, fetching, error, testScreenshots } = testScreenshotsObj;
  		let ssVDOM = '';

  		if (hasArrayElement(testScreenshots)) {
  			ssVDOM = testScreenshots.map((el, index) => {
  				const timeObj = new Date(el.creationTime);
  				const time = timeObj.toTimeString().split(' ')[0];
  				return (
  					<div key={index} className="screenshot-container">
  						<div
  							className="screenshot-container-in"
  							onClick={() => this.setState({ isOpen: true, imgUrl: el.imageSourceUrl })}
  						>
  							<img src={el.imageSourceUrl} alt="import"></img>
  							<div className="info-part">
  								<div className="left-part">
  									{el.imgSource === 'BROWSERSTACK' && browserStackIcon()}
  									{el.imgSource === 'SELENIUM' && seleniumIcon()}
  								</div>
  								<span className="right-part">
  									{time} {el.title}
  								</span>
  							</div>
  						</div>
  					</div>
  				);
  			});
  		}

  		if (fetching) {
  			return (
  				<div className="ss-loading-msg-container">
  					<LoadingDimmer msg="Loading" />
  				</div>
  			);
  		} else if (error) {
  			return (
  				<div className="error-ss-msg-container">
  					<SomethingError msg={error} />
  				</div>
  			);
  		} else if (fetched) {
  			if (ssVDOM) {
  				return (
  					<div>
  						{this.renderModal()}
  						<div className="test-case-screenshots-container">{ssVDOM}</div>
  					</div>
  				);
  			} else {
  				return (
  					<div className="no-ss-data-msg-container">
  						<NoSSAvailable msg="No Screenshot" />
  					</div>
  				);
  			}
  		}
  	}

  	return <div>Screenshots</div>;
  }
}

export default TestRunTestScreenshotsViewer;
