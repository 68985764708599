import { connect } from 'react-redux';
import { PopupPageDesignCloseIconHeader } from 'components';
import { withRouter } from 'react-router';

function mapStateToProps() {
	return {};
}

function mapDispatchToProps() {
	return {};
}

const PopupPageDesignCloseIconHeaderContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PopupPageDesignCloseIconHeader),
);
export default PopupPageDesignCloseIconHeaderContainer;
