import { PRChangeImpactAnalysis } from 'components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  getPullRequestCoverages,
  getPullRequestWorkflowRunLatestJobCoverageResult,
} from 'store/actions/pull-request-coverage';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    pullRequestCoverages: store.pullRequestCoverages,
  };
}

function mapDispatchToProps() {
  return {
    getPullRequestCoverages(ciProvider, repoOwner, repoName, prId, handleSuccess, handleError) {
      dispatchWithExceptionHandler(
        getPullRequestCoverages(ciProvider, repoOwner, repoName, prId),
        false,
        handleSuccess,
        handleError,
      );
    },
    getPullRequestWorkflowRunLatestJobCoverageResult(workflowRunId, jobId, handleSuccess, handleError) {
      dispatchWithExceptionHandler(
        getPullRequestWorkflowRunLatestJobCoverageResult(workflowRunId, jobId),
        false,
        handleSuccess,
        handleError,
      );
    },
  };
}

const PRChangeImpactAnalysisContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PRChangeImpactAnalysis),
);
export default PRChangeImpactAnalysisContainer;
