import React, { Component } from 'react';
import { MainLayoutContainer } from 'containers';
import routeList from 'routes';
import {
  TestViewAdvancedFilterButton as AdvancedFilterButton,
  TestViewAdvancedFilterLabels as AdvancedFilterLabels,
} from './page-components/filter/advanced/TestViewAdvanced';
import { TestViewSummary as Summary } from './page-components/summary/TestViewSummary';
import { TestViewChart as Chart } from './page-components/chart/TestViewChart';
import { TestViewTable as Table } from './page-components/table/TestViewTable';
import { defaultRange, TestViewDateFilter as DateFilter } from './page-components/filter/calendar/TestViewDateFilter';
import { TestViewSidePanel as SidePanel } from './page-components/filter/sidepanel/TestViewSidePanel';
import { TestViewBreadCrumb as Breadcrumb } from './page-components/breadcrumb/TestViewBreadcrumb';
import { TestViewPageHeader as PageHeader } from './page-components/pageheader/TestViewPageHeader';
import './TestViewPage.scss';
import moment from 'moment';

export class TestViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      sortFetching: false,
      sort: {
        field: 'duration',
        order: 'DESC',
      },
      page: 0,
      range: defaultRange,
      chartRange: null,
      isFilterOpen: false,
      sidePanelFilterBranches: [],
      sidePanelFilterStatus: [],
      isClearFilterClicked: false,
      backendObject: {},
      searchText: '',
    };
  }

  //States====
  componentDidMount() {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    this.props.getTestViewBranchFilter({ ciProvider, repoName, repoOwner }, this.handleInitialFilter);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.range !== this.state.range) {
      this.fetchData();
    }
  }

  handleInitialFilter = () => {
    const { testViewBranches } = this.props.testViewFilters;
    const transformedData = [];
    let output = [];
    for (const item of testViewBranches) {
      const transformedItem = { repoName: item.name, repoId: item.id };
      transformedItem.branches = item.branches.map(element => {
        return {
          name: element,
          checked: item.defaultBranch === element,
          visible: true,
        };
      });
      transformedData.push(transformedItem);
    }
    if (transformedData?.length > 0) {
      output = transformedData.map(item => {
        return {
          repoId: item.repoId,
          repoName: item.repoName,
          branches: item.branches.filter(branch => branch.checked).map(branchItem => branchItem.name),
        };
      });
    }
    const sendToBackendObj = output.filter(item => item.branches.length > 0);

    this.setState({ sidePanelFilterBranches: transformedData, backendObject: { branches: sendToBackendObj } }, () => {
      this.fetchData();
    });
  };

  //Fetching =======

  getQueryObj = flagForListView => {
    const { ciProvider, repoOwner, repoName, workflowId, jobName, testSuiteName, testCaseName } =
      this.props.match.params;

    const { range, backendObject, chartRange, sort, page } = this.state;
    const { branches, hideForkBranch, statuses } = backendObject;

    const activeRange =
      flagForListView && chartRange
        ? {
            startDate: chartRange.startTime,
            endDate: chartRange.endTime,
          }
        : range;

    const qObj = {
      ciProvider,
      repoOwner,
      repoName,
      workflowId,
      jobName,
      testSuiteName,
      testCaseName,
      sort,
      page,
      range: activeRange,
      branches: branches || [],
      statuses: statuses || [],
      hideForkBranch: hideForkBranch,
    };

    return qObj;
  };

  fetchData = () => {
    const qObj = this.getQueryObj();

    this.props.getTestViewStatusFilter(qObj);
    this.props.getTestViewSummary(qObj);
    this.props.getTestViewChart(qObj);
    this.props.getTestViewList(qObj);
  };

  fetchOnlyTestViewList = () => {
    const qObj = this.getQueryObj(true);
    this.props.getTestViewList(qObj);
  };

  //handler =====
  handleTableSortChange = sortData => {
    this.setState({ sort: sortData, page: 0 }, () => {
      this.fetchOnlyTestViewList();
    });
  };
  handleTablePaginationChange = pageNo => {
    this.setState({ page: pageNo }, () => {
      this.fetchOnlyTestViewList();
    });
  };

  //calendar handler =====
  handleCalendarChange = ({ value }) => {
    if (value?.length === 2) {
      let startDate = moment(value[0], 'YYYY-MM-DD HH:mm:ss Z');
      let endDate = moment(value[1], 'YYYY-MM-DD HH:mm:ss Z');
      // console.log('Calendar change');
      // const a = `WithoutLocale start ${startDate.unix()} end ${endDate.unix()}
      //   WithLocale start ${startDate.local().unix()} end ${endDate.local().unix()}`;
      // alert(a);
      const rangeObj = { startDate: startDate.local(), endDate: endDate.local() };
      this.setState({ range: rangeObj, page: 0, chartRange: null });
    } else if (Object.is(value, null)) {
      this.setState({ range: {} });
    }
  };

  //advanced filter handler ====
  handleFilterChange = (filterObj, isFilterOpen = false) => {
    const branchObject = filterObj?.filterData || [];
    const statusObject = filterObj?.status.map(item => item.toUpperCase()) || [];
    console.log('Test Suites Page handle');
    let output = [];
    if (branchObject?.length > 0) {
      output = branchObject.map(item => {
        return {
          repoId: item.repoId,
          repoName: item.repoName,
          branches: item.branches.filter(branch => branch.checked).map(branchItem => branchItem.name),
        };
      });
    }
    const sendToBackendObj = output.filter(item => item.branches.length > 0);
    const newFilterObject = {
      branches: sendToBackendObj,
      statuses: statusObject,
      hideForkBranch: filterObj?.hideForkBranch,
    };
    this.setState(
      {
        sidePanelFilterBranches: branchObject,
        sidePanelFilterStatus: filterObj?.status,
        hideForkBranch: filterObj?.hideForkBranch,
        isFilterOpen: isFilterOpen,
        page: 0,
        backendObject: newFilterObject,
        searchText: filterObj.searchText || '',
      },
      () => {
        this.fetchData();
      },
    );
  };

  handleClearFilter = () => {
    const branchObject = {};

    this.setState({ backendObject: branchObject, isFilterOpen: false }, () => {
      this.fetchData();
    });
  };

  //handle Chart Item Select
  handleChartItemSelect = (index, selection) => {
    //console.log('Chart Item Select', index, selection);
    this.setState({ chartRange: selection, page: 0 }, () => {
      this.fetchOnlyTestViewList();
    });
  };

  convertTooltipData = data => {
    const transformedData = [];
    if (data) {
      data.forEach(item => item.branches.forEach(element => transformedData.push(`${item.repoName}/${element}`)));
      return transformedData;
    }
  };

  //Render ====
  render() {
    const {
      isFilterOpen,
      isClearFilterClicked,
      range,
      backendObject,
      chartRange,
      sort,
      page,
      sidePanelFilterStatus,
      sidePanelFilterBranches,
      searchText,
    } = this.state;

    return (
      <MainLayoutContainer hasFullScreenStickyTableHeader title={routeList.testSuites.title}>
        <div className="test-view-page-wrapper">
          <div className="test-view-header-container">
            <Breadcrumb {...this.props} />
            <div className="header-first-section">
              <PageHeader {...this.props} />
              <div className="test-view-advanced-container">
                <AdvancedFilterLabels
                  handleClearFilter={this.handleClearFilter}
                  branches={this.convertTooltipData(backendObject.branches)}
                  statuses={backendObject.statuses}
                  filterLabelClick={() => this.setState({ isFilterOpen: !isFilterOpen, isClearFilterClicked: false })}
                />
                <AdvancedFilterButton
                  onClick={update => {
                    this.setState(update);
                  }}
                />
              </div>
            </div>
            <div className="header-second-section">
              <div></div>
              <DateFilter onChange={this.handleCalendarChange} />
            </div>
          </div>
          <Summary {...this.props} />
          <Chart
            selectedRange={chartRange}
            range={range}
            handleChartItemSelect={this.handleChartItemSelect}
            {...this.props}
          />
          <Table
            {...this.props}
            selectedRange={chartRange}
            sortData={sort}
            pageNo={page}
            handleChartItemSelect={this.handleChartItemSelect}
            handleSortChange={this.handleTableSortChange}
            handlePageChange={this.handleTablePaginationChange}
          />
          {isFilterOpen && (
            <SidePanel
              testViewPageProps={this.props}
              isOpen={isFilterOpen}
              applyFilter={this.handleFilterChange}
              isClearFilter={isClearFilterClicked}
              closeDetailModal={() => this.setState({ isFilterOpen: false })}
              defaultBranchFilterList={sidePanelFilterBranches}
              defaultStatusFilterList={sidePanelFilterStatus}
              searchText={searchText}
            />
          )}
        </div>
      </MainLayoutContainer>
    );
  }
}
