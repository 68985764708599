import {
  GET_MY_ORGANIZATION_USER_LIST_PENDING,
  GET_MY_ORGANIZATION_USER_LIST_FULFILLED,
  GET_MY_ORGANIZATION_USER_LIST_REJECTED,
  GET_USER_ALL_ORGANIZATION_LIST_PENDING,
  GET_USER_ALL_ORGANIZATION_LIST_FULFILLED,
  GET_USER_ALL_ORGANIZATION_LIST_REJECTED,
  GET_USER_ACTIVE_ORGANIZATION_LIST_PENDING,
  GET_USER_ACTIVE_ORGANIZATION_LIST_FULFILLED,
  GET_USER_ACTIVE_ORGANIZATION_LIST_REJECTED,
  UPDATE_ORGANIZATION_PENDING,
  UPDATE_ORGANIZATION_FULFILLED,
  UPDATE_ORGANIZATION_REJECTED,
  SWITCH_ORGANIZATION_PENDING,
  SWITCH_ORGANIZATION_FULFILLED,
  SWITCH_ORGANIZATION_REJECTED,
  INVITE_USERS_TO_CURRENT_ORGANIZATION_PENDING,
  INVITE_USERS_TO_CURRENT_ORGANIZATION_FULFILLED,
  INVITE_USERS_TO_CURRENT_ORGANIZATION_REJECTED,
  CHANGE_USER_ROLE_IN_ORGANIZATION_PENDING,
  CHANGE_USER_ROLE_IN_ORGANIZATION_FULFILLED,
  CHANGE_USER_ROLE_IN_ORGANIZATION_REJECTED,
  REMOVE_USER_FROM_ORGANIZATION_PENDING,
  REMOVE_USER_FROM_ORGANIZATION_FULFILLED,
  REMOVE_USER_FROM_ORGANIZATION_REJECTED,
  LEAVE_FROM_ORGANIZATION_PENDING,
  LEAVE_FROM_ORGANIZATION_FULFILLED,
  LEAVE_FROM_ORGANIZATION_REJECTED,
} from 'store/constants/organization';

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  switchOrganizationFetching: false,
  switchOrganizationFetched: false,
  switchOrganizationError: null,
  userActiveOrganizationList: [],
  userAllOrganizationList: [],
  userInvitationFetching: false,
  userInvitationFetched: false,
  userInvitationError: null,
  settingsFetching: false,
  settingsFetched: false,
  settingsError: null,
  leaveFromOrganizationFetching: false,
  leaveFromOrganizationFetched: false,
  leaveFromOrganizationError: null,
};

export default function organization(state = initialState, action) {
  switch (action.type) {
    case GET_USER_ALL_ORGANIZATION_LIST_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_USER_ALL_ORGANIZATION_LIST_FULFILLED:
      return {
        ...state,
        userAllOrganizationList: action.payload.data,
        fetching: false,
        fetched: true,
        error: false,
      };
    case GET_USER_ALL_ORGANIZATION_LIST_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };
    case GET_USER_ACTIVE_ORGANIZATION_LIST_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_USER_ACTIVE_ORGANIZATION_LIST_FULFILLED:
      return {
        ...state,
        userActiveOrganizationList: action.payload.data,
        fetching: false,
        fetched: true,
        error: false,
      };
    case GET_USER_ACTIVE_ORGANIZATION_LIST_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };
    case GET_MY_ORGANIZATION_USER_LIST_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_MY_ORGANIZATION_USER_LIST_FULFILLED:
      return {
        ...state,
        myOrganizationUserList: action.payload.data,
        fetching: false,
        fetched: true,
        error: false,
      };
    case GET_MY_ORGANIZATION_USER_LIST_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };
    case UPDATE_ORGANIZATION_PENDING:
      return { ...state, settingsFetching: true, settingsFetched: false, settingsError: null };
    case UPDATE_ORGANIZATION_FULFILLED:
      return {
        ...state,
        settingsFetching: false,
        settingsFetched: true,
        settingsError: false,
      };
    case UPDATE_ORGANIZATION_REJECTED:
      return { ...state, settingsFetching: false, settingsFetched: false, settingsError: action.payload };
    case SWITCH_ORGANIZATION_PENDING:
      return {
        ...state,
        switchOrganizationFetching: true,
        switchOrganizationFetched: false,
        switchOrganizationError: null,
      };
    case SWITCH_ORGANIZATION_FULFILLED:
      return {
        ...state,
        switchOrganizationFetching: false,
        switchOrganizationFetched: true,
        switchOrganizationError: false,
      };
    case SWITCH_ORGANIZATION_REJECTED:
      return {
        ...state,
        switchOrganizationFetching: false,
        switchOrganizationFetched: false,
        switchOrganizationError: action.payload,
      };
    case INVITE_USERS_TO_CURRENT_ORGANIZATION_PENDING:
      return {
        ...state,
        userInvitationFetching: true,
        userInvitationFetched: false,
        userInvitationError: false,
      };
    case INVITE_USERS_TO_CURRENT_ORGANIZATION_FULFILLED:
      return {
        ...state,
        userInvitationFetching: false,
        userInvitationFetched: true,
        userInvitationError: false,
      };
    case INVITE_USERS_TO_CURRENT_ORGANIZATION_REJECTED:
      return {
        ...state,
        userInvitationFetching: false,
        userInvitationFetched: false,
        userInvitationError: true,
      };
    case CHANGE_USER_ROLE_IN_ORGANIZATION_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: false,
      };
    case CHANGE_USER_ROLE_IN_ORGANIZATION_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
      };
    case CHANGE_USER_ROLE_IN_ORGANIZATION_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: true,
      };
    case REMOVE_USER_FROM_ORGANIZATION_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: false,
      };
    case REMOVE_USER_FROM_ORGANIZATION_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
      };
    case REMOVE_USER_FROM_ORGANIZATION_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: true,
      };
    case LEAVE_FROM_ORGANIZATION_PENDING:
      return {
        ...state,
        leaveFromOrganizationFetching: true,
        leaveFromOrganizationFetched: false,
        leaveFromOrganizationError: false,
      };
    case LEAVE_FROM_ORGANIZATION_FULFILLED:
      return {
        ...state,
        leaveFromOrganizationFetching: false,
        leaveFromOrganizationFetched: true,
        leaveFromOrganizationError: false,
      };
    case LEAVE_FROM_ORGANIZATION_REJECTED:
      return {
        ...state,
        leaveFromOrganizationFetching: false,
        leaveFromOrganizationFetched: false,
        leaveFromOrganizationError: true,
      };
    default:
      return state;
  }
}
