export const UI_ICON = {
  COPY_CODE: 'icon-copy-code',
  EDIT: 'icon-edit',
  DELETE: 'icon-trash',
  CHEVRON: {
    DOWN: 'icon-chevron-down',
    UP: 'icon-chevron-up',
    LEFT: 'icon-chevron-left',
    RIGHT: 'icon-chevron-right',
  },
  ALERT: {
    INFO: 'icon-circle-info',
    INFO_BLUE_CIRCLE: 'icon-info-circle-project-integration',
    WARNING: 'icon-warning',
    ERROR: 'icon-exclamation-red',
  },
  CROSS: {
    CIRCLE: 'icon-circle-x',
    REPO_MODAL_CLOSE: 'icon-cross',
  },
  CHECK: {
    DEFAULT_GREEN: 'icon-test-status-passed',
  },
  ADD: {
    CIRCLE: 'icon-add-circle',
  },
  REDIRECTION: {
    DEFAULT: 'icon-external-redirect',
  },
  PROFILE: {
    AVATAR: 'icon-user-avatar',
  },
  SORT: {
    UP: 'icon-up',
    DOWN: 'icon-down',
  },
  SETTINGS: {
    LIGHT: 'icon-settings-light',
  },
  REFRESH: 'icon-refresh',
  MORE: {
    HORIZONTAL: 'icon-more-dot3',
    HORIZONTAL_IN_CIRCLE: 'icon-other-wf-run',
    VERTICAL: 'icon-more-vertical',
    VERTICAL_WHITE: 'icon-more-vertical-white',
  },
};

export const DOMAIN_ICON = {
  LOGOS: {
    GITHUB_LOGO: 'icon-github-logo',
    SLACK_LOGO: 'icon-slack-logo',
  },
  SELECTED_ORG: 'icon-home',
  ACCOUNT_STATUS_INACTIVE: 'icon-state-inactive',
  GITHUB: {
    REPOSITORY: 'icon-bookmark',
    PULL_REQUEST: 'icon-pullrequest',
    BRANCHES: 'icon-branches',
    WORKFLOW: 'icon-workflows',
    JOB: 'icon-icons-action-job',
    COMMIT: 'icon-commit',
    DURATION: 'icon-elapsed-time',
    DATE: 'icon-icons-calendar',
    EXEC_DATE: 'icon-execution-date',
    EVENT: 'icon-play',

    PR_OWNER: 'icon-person',
    PR_REVIEWERS: 'icon-person',
    HEAD_COMMIT_AUTHOR: 'icon-person',

    CI_CHECK: {
      FAILED: 'icon-test-status-failed',
      PASSED: 'icon-test-status-passed',
    },
    TEST_STATUS: {
      PASSED: 'icon-test-status-passed',
      FAILED: 'icon-test-status-failed',
      ABORTED: 'icon-test-status-aborted',
      SKIPPED: 'icon-test-status-skipped',
    },

    RULE_CONDITIONS: {
      INCREASE: 'up-side-arrow-icon',
      DECREASE: 'down-side-arrow-icon',
      HIGHER: 'up-side-arrow-icon',
      LOWER: 'down-side-arrow-icon',
    },

    TEST_RUN_STATUS: {
      SUCCESS_IN_CIRCLE: 'icon-run-successful',
      SUCCESS: 'icon-status-success',
      ALL_PASSED: 'icon-passed-and-arrow-in-circle',
      FAILED: 'icon-run-failed',
      RUNNING: 'icon-run-running',
      PASSED: 'icon-run-passed',
      TIMEOUT: 'icon-status-timed-out',
      ABORTED: 'icon-test-status-aborted',
      SKIPPED: 'icon-test-status-skipped',
      CHECK: 'icon-icons-check',
      UNKNOWN: 'icon-status-unknown',
    },

    WORKFLOW_RUN_STATUS: {
      FAILURE: 'icon-status-timed-out', // Will be change icon name
      SUCCESS: 'icon-status-success',
      QUEUED: 'icon-status-queued',
      IN_PROGRESS: 'icon-status-inProgress',
      IN_ACTIVE: 'icon-state-inactive',
      CANCELLED: 'icon-status-cancelled',
      ACTION_REQUIRED: 'icon-status-action-required',
      NEUTRAL: 'icon-status-neutral',
      STALE: 'icon-status-stale',
      TIMEOUT: 'icon-status-timed-out',
      SKIPPED: 'icon-status-skipped',
      UNKNOWN: 'icon-status-unknown',
    },
    PR_STATUS: {
      CLOSED: 'icon-pull-request-status-closed',
      DRAFT: 'icon-pull-request-status-draft',
      MERGED: 'icon-pull-request-status-merged',
      OPEN: 'icon-pull-request-status-open',
    },
  },
  BILLING: {
    CREDIT_CARD: 'icon-credit-card',
  },
  NOTIFICATION: {
    SLACK: 'icon-slackmini',
  },
};
