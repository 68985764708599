import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import './Dropdown.scss';

export const ForesightDropdown = ({ hasError, searchable = true, labelText = null, ...props }) => {
  return (
    <div className="foresight-dropdown-container">
      {labelText && <label>{labelText}</label>}
      <Dropdown
        {...props}
        className={`${
          hasError ? 'foresight-dropdown dropdown-filter error-valid' : 'foresight-dropdown dropdown-filter'
        }`}
        search={searchable}
        fluid
        selection
        selectOnBlur={false}
      />
    </div>
  );
};
