import {
  GET_PULL_REQUESTS_META_FULFILLED,
  GET_PULL_REQUESTS_META_PENDING,
  GET_PULL_REQUESTS_META_REJECTED,
  SET_PULL_REQUESTS_WORKFLOW_IDS_SELECTED,
  SET_PULL_REQUESTS_WORKFLOW_RUN_BRANCHES_SELECTED,
  GET_PULL_REQUESTS_FILTER_LIST_FULFILLED,
  GET_PULL_REQUESTS_FILTER_LIST_PENDING,
  GET_PULL_REQUESTS_FILTER_LIST_REJECTED,
  SET_PULL_REQUESTS_AUTHORS_SELECTED,
  SET_PULL_REQUESTS_LABELS_SELECTED,
  SET_PULL_REQUESTS_STATES_SELECTED,
  SET_PULL_REQUESTS_HIDE_FORK_BRANCH_SELECTED,
} from 'store/constants/pull-requests-filter';

const pullRequestsMetaInitialState = {
  filterId: '',
  fetching: false,
  fetched: false,
  error: null,
  pullRequestsMeta: [],
  pullRequests_WorkflowRunFilters: {},
  selectedPullRequests_WorkflowRunBranches: [],
  selectedPullRequests_Authors: [],
  selectedPullRequests_Labels: [],
  selectedPullRequests_States: [],
  selectedPullRequests_HideFork: false,
};

const TAGS = {
  filter: 'filter',
};

const generateId = (tag, action) => {
  const { ciProvider, repoOwner, repoName } = action.meta || {
    ciProvider: undefined,
    repoOwner: undefined,
    repoName: undefined,
  };
  return `${tag}-${ciProvider}-${repoOwner}-${repoName}`;
};

const getSelectedPRLabels = (state, action) => {
  if (state.metaId === generateId(TAGS.filter, action)) {
    return state.selectedPullRequests_Labels;
  }
  return [];
};

const getSelectedPRAuthors = (state, action) => {
  const newFilterId = generateId(TAGS.filter, action);
  if (state.filterId === newFilterId) {
    return state.selectedPullRequests_Authors;
  }
  return [];
};

const getSelectedPRStates = (state, action) => {
  const newFilterId = generateId(TAGS.filter, action);
  if (state.filterId === newFilterId) {
    return state.selectedPullRequests_States;
  }
  return [];
};

const getSelectedWorkflowRunBranches = (state, action) => {
  if (state.filterId === generateId(TAGS.filter, action)) {
    return state.selectedPullRequests_WorkflowRunBranches;
  }
  return [];
};

export default function pullRequestsMeta(state = pullRequestsMetaInitialState, action) {
  switch (action.type) {
    case GET_PULL_REQUESTS_META_PENDING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
        pullRequestsMeta: [],
      };
    case GET_PULL_REQUESTS_META_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        pullRequestsMeta: action.payload.data,
      };
    case GET_PULL_REQUESTS_META_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: true,
        pullRequestsMeta: [],
      };
    case SET_PULL_REQUESTS_WORKFLOW_IDS_SELECTED:
      return {
        ...state,
        selectedPullRequests_WorkflowIds: action.payload.data,
      };

    case SET_PULL_REQUESTS_WORKFLOW_RUN_BRANCHES_SELECTED:
      return {
        ...state,
        selectedPullRequests_WorkflowRunBranches: action.payload.data,
      };
    case SET_PULL_REQUESTS_HIDE_FORK_BRANCH_SELECTED:
      return {
        ...state,
        selectedPullRequests_WorkflowRunBranches: [],
        selectedPullRequests_Authors: [],
        selectedPullRequests_Labels: [],
        selectedPullRequests_States: [],
        selectedPullRequests_HideFork: action.payload.data,
      };
    case SET_PULL_REQUESTS_AUTHORS_SELECTED:
      return {
        ...state,
        selectedPullRequests_Authors: action.payload.data,
      };
    case SET_PULL_REQUESTS_LABELS_SELECTED:
      return {
        ...state,
        selectedPullRequests_Labels: action.payload.data,
      };
    case SET_PULL_REQUESTS_STATES_SELECTED:
      return {
        ...state,
        selectedPullRequests_States: action.payload.data,
      };
    case GET_PULL_REQUESTS_FILTER_LIST_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_PULL_REQUESTS_FILTER_LIST_FULFILLED:
      return {
        ...state,
        filterId: generateId(TAGS.filter, action),
        fetching: false,
        fetched: true,
        error: false,
        pullRequests_WorkflowRunFilters: { ...action.payload.data },
        selectedPullRequests_WorkflowRunBranches: getSelectedWorkflowRunBranches(state, action),
        selectedPullRequests_Authors: getSelectedPRAuthors(state, action),
        selectedPullRequests_Labels: getSelectedPRLabels(state, action),
        selectedPullRequests_States: getSelectedPRStates(state, action),
      };
    case GET_PULL_REQUESTS_FILTER_LIST_REJECTED:
      return {
        ...state,
        filterId: generateId(TAGS.filter, action),
        fetching: false,
        fetched: false,
        error: true,
        pullRequests_WorkflowRunFilters: {},
        selectedPullRequests_WorkflowRunBranches: [],
        selectedPullRequests_Authors: [],
        selectedPullRequests_Labels: [],
        selectedPullRequests_States: [],
      };

    default:
      return state;
  }
}
