export const FLAKY_COLORS = {
  low: 'FFDDA1',
  medium: 'FFC772',
  high: 'FFAE43',
  urgent: 'D56F0F',
};

export const whichIsSeverity = percentage => {
  if (0 <= percentage && percentage <= 25) {
    return 'low';
  } else if (25 < percentage && percentage <= 50) {
    return 'medium';
  } else if (50 < percentage && percentage <= 75) {
    return 'high';
  } else if (75 < percentage && percentage <= 100) {
    return 'urgent';
  }
};
