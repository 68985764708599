import React, { Component } from 'react';
import { getStringColor } from 'utils/color-util';
import './ActiveListWidget.scss';

export class ActiveListWidget extends Component {


    handleListMouseOver = (event, el) => {
        const { handleSentListName } = this.props;
        handleSentListName(el.name);
    };

    handleListMouseLeave = () => {
        const { handleSentListName } = this.props;
        handleSentListName(null);
    };

    render() {
        const { data, handlerId, handleName } = this.props;
        const transformedData = data.map((el, index) => {
            return {
                index: index,
                name: el?.repo?.fullName + '/' + el?.name,
                total: el.totalCount,
                success: el.successCount,
                fail: el.failureCount,
                successRate: Math.round(el.successRate),
                pathData: {
                    workflowId: el.id,
                    workflowName: el?.repo?.fullName + '/' + el?.name,
                    repoFullName: el?.repo?.fullName,
                }
            };
        });

        const rowJSX = transformedData.map((el, index) => {
            return (
                <tr
                    className={`${handleName === el.name ? 'active-line' : ''} active-list-widget-row`}
                    key={index}
                    style={{ height: '24px' }}
                    onClick={event => handlerId(event, el.pathData)}
                    onMouseEnter={(event) => { this.handleListMouseOver(event, el); }}
                    onMouseLeave={this.handleListMouseLeave}
                >
                    <td>
                        <div className='workflow-id'>
                            {el.index + 1}
                            <span className='workflow-id-color' style={{ backgroundColor: getStringColor(el.name) }}></span>
                        </div>
                    </td>
                    <td>
                        <div className='workflow-name'>
                            <span className='workflow-list-cell-text'> {el.name}</span>
                        </div>
                    </td>
                    <td>
                        <div className='workflow-stat'>
                            <span className='workflow-list-cell-text'>  {el.total}</span>
                        </div>
                    </td>
                    <td>
                        <div className='workflow-stat'>
                            <span className='workflow-list-cell-text'> {el.success}</span>
                        </div>

                    </td>
                    <td>
                        <div className='workflow-stat'>
                            <span className='workflow-list-cell-text'> {el.fail}</span>
                        </div></td>
                    <td>
                        <div className='workflow-stat'>
                            <span className='workflow-list-cell-text'> {el.successRate}%</span>
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <table className='most-active-list-table' cellSpacing="0" cellPadding="0">
                <tbody>
                    <tr>
                        <th className='header-workflow-id'></th>
                        <th style={{ textAlign: 'left' }} className='header-workflow-name'>Workflow name</th>
                        <th style={{ textAlign: 'right' }} className='header-workflow-totalruns'># of total runs</th>
                        <th style={{ textAlign: 'right' }} className='header-workflow-failed'># of successful runs</th>
                        <th style={{ textAlign: 'right' }} className='header-workflow-failed'># of failed runs</th>
                        <th style={{ textAlign: 'right' }} className='header-workflow-successrate'>success rate</th>
                    </tr>
                    {rowJSX}
                </tbody>
            </table>
        );
    }

}