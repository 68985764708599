import {
  GET_SOURCE_COVERAGE_PENDING,
  GET_SOURCE_COVERAGE_REJECTED,
  GET_SOURCE_COVERAGE_FULFILLED,
} from 'store/constants/source-coverage';

const initialState = {
  sourceCoverageDetailMap: {},
};

export default function sourceCoverageDetails(state = initialState, action) {
  const { workflowRunId, fullFilePath } = action.meta || { workflowRunId: undefined, fullFilePath: undefined };
  switch (action.type) {
    case GET_SOURCE_COVERAGE_PENDING:
      return setSourceCoverage(state, workflowRunId, fullFilePath, {
        fetching: true,
        fetched: false,
        error: null,
        errorTime: -1,
        srcCoverage: {},
      });
    case GET_SOURCE_COVERAGE_FULFILLED:
      return setSourceCoverage(state, workflowRunId, fullFilePath, {
        fetching: false,
        fetched: true,
        error: null,
        errorTime: -1,
        srcCoverage: action.payload.data,
      });
    case GET_SOURCE_COVERAGE_REJECTED:
      return setSourceCoverage(state, workflowRunId, fullFilePath, {
        fetching: false,
        fetched: false,
        error: action.payload,
        errorTime: new Date().getTime(),
        srcCoverage: {},
      });

    default:
      return state;
  }
}

function setSourceCoverage(state, workflowRunId, fullFilePath, sourceCoverageObj) {
  const sourceCoverageDetail = getSourceCoverageDetail(state, workflowRunId, fullFilePath);
  sourceCoverageDetail.sourceCoverageObj = sourceCoverageObj;
  return { sourceCoverageDetailMap: state.sourceCoverageDetailMap };
}

function getSourceCoverageDetail(state, workflowRunId, fullFilePath) {
  const { sourceCoverageDetailMap } = state;

  //Check Is TestRun Exist
  if (!sourceCoverageDetailMap[workflowRunId + '-' + fullFilePath]) {
    const sourceCoverageDetail = {
      workflowRunId: workflowRunId,
      fullFilePath: fullFilePath,
      sourceCoverageObj: {
        fetching: false,
        fetched: false,
        error: null,
        errorTime: -1,
        srcCoverage: {},
      },
    };
    sourceCoverageDetailMap[workflowRunId + fullFilePath] = sourceCoverageDetail;
  }
  return sourceCoverageDetailMap[workflowRunId + fullFilePath];
}
