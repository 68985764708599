import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import routeList, { computePath } from 'routes';
import { hasArrayElement } from 'utils/array-util';
import { calcDisplayedTime, timeDiffAsMillis } from 'utils/date-util';
import { clipTestSuiteName } from 'utils/test-run-detail-util';
import './RepositoryWorkflowJobFailedTests.scss';
import { Link } from 'react-router-dom';
import { DOMAIN_ICON, UI_ICON } from 'assets/font-icons/IconManager';

export class RepositoryWorkflowJobFailedTests extends Component {
  getURLForTestCase = (testRunId, transactionId, status) => {
    const { ciProvider, repoOwner, repoName } = this.props.match.params;
    const testErrorOrPerfPath = status === 'FAILED' ? routeList.testRunTestErrors : routeList.testRunTestPerformance;
    const testComputedPath = computePath(testErrorOrPerfPath.path, {
      testRunId: testRunId,
      transactionId: transactionId,
      ciProvider: ciProvider,
      repoOwner: repoOwner,
      repoName: repoName,
    });
    return window.location.origin + testComputedPath + '#auto-focus';
  };

  renderStatus = status => {
    let iDOM = '';
    switch (status) {
      case 'SUCCESSFUL':
        iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.PASSED + 'successful'} />;
        break;

      case 'FAILED':
        iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.FAILED + 'failed'} />;
        break;

      case 'ABORTED':
        iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.ABORTED + 'aborted'} />;
        break;

      case 'SKIPPED':
        iDOM = <i className={DOMAIN_ICON.GITHUB.TEST_STATUS.SKIPPED + 'skipped'} />;
        break;
      default:
        break;
    }
    return <div>{iDOM}</div>;
  };

  renderItem = ({ index, testCaseURL, status, name, valueType, value, suiteName }) => {
    let vDOM = value;
    if (valueType === 'duration') {
      vDOM = (
        <div className="duration">
          <i className={DOMAIN_ICON.GITHUB.DURATION} />
          <span>{value}</span>
        </div>
      );
    }

    if (valueType === 'duration&suite') {
      vDOM = (
        <>
          <div className="duration">
            <i className={DOMAIN_ICON.GITHUB.DURATION} />
            <span>{value}</span>
            <FontAwesomeIcon icon={faCircle} />
            <span className="suite-name-in-testcase">{clipTestSuiteName(suiteName)}</span>
          </div>
        </>
      );
    }

    if (valueType === 'errorCount') {
      vDOM = (
        <div className="error-count">
          <span>{value}</span>
        </div>
      );
    }

    return (
      <Link key={index} className="item-wrapper" to={{ pathname: testCaseURL }} target="_blank">
        <div className="item" key={index}>
          <div className="left-part">
            <div className="status">{this.renderStatus(status)}</div>
            <div className="item-title">
              <div className="name">{name}</div>
              <div className="value">{vDOM}</div>
            </div>
          </div>
          <div className="right-part">
            <i className={UI_ICON.CHEVRON.RIGHT} />
          </div>
        </div>
      </Link>
    );
  };

  renderFailedTests = failedTests => {
    const cases = failedTests;
    let caseVDOM = (
      <div className="all-test-passed-container">
        <div className="centered-container">
          <span>No failed tests</span>
        </div>
      </div>
    );

    if (hasArrayElement(cases)) {
      const sortedCases = cases
        .filter(e => e !== undefined)
        .map(el => {
          const { testName, endTime, startTime, transactionId, status, suiteName, testRunId } = el;
          return {
            execTime: timeDiffAsMillis(endTime, startTime),
            name: testName,
            testRunId: testRunId,
            transactionId: transactionId,
            status: status,
            suiteName: suiteName,
          };
        })
        .sort((a, b) => {
          return b.execTime - a.execTime;
        })
        .slice(0, 5);

      caseVDOM = sortedCases.map(el => {
        const { name, execTime, transactionId, status, suiteName, testRunId } = el;
        const itemProp = {
          name: name,
          suiteName: suiteName,
          testCaseURL: this.getURLForTestCase(testRunId, transactionId, status),
          status: status,
          valueType: 'duration&suite',
          value: `${calcDisplayedTime(execTime)}`,
        };

        return <>{this.renderItem(itemProp)}</>;
      });
    }

    return <div className="list-items">{caseVDOM}</div>;
  };

  render() {
    const { failedTests } = this.props.workflowRunJobTests;
    if (failedTests && hasArrayElement(failedTests.data)) {
      return (
        <div className="failed-test-card-container">
          <div className="title">Failed Tests</div>
          <div className="content">{this.renderFailedTests(failedTests.data)}</div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
