import {
  GET_CONNTECTED_REPO_WATCH_FULFILLED,
  GET_CONNTECTED_REPO_WATCH_PENDING,
  GET_CONNTECTED_REPO_WATCH_REJECTED,
} from 'store/constants/has-connected-repo-watched';

const initialState = {
  hasWatchedRepo: false,
  hasWatchedRepoFetching: false,
  hasWatchedRepoFetched: false,
  hasWatchedRepoError: null,
};

export default function hasConnectedRepoWacthed(state = initialState, action) {
  switch (action.type) {
    case GET_CONNTECTED_REPO_WATCH_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_CONNTECTED_REPO_WATCH_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        hasWatchedRepo: action.payload?.data?.length > 0,
      };
    case GET_CONNTECTED_REPO_WATCH_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    default:
      return state;
  }
}
