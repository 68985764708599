import { connect } from 'react-redux';
import { TeamOrganization } from 'components';
import { withRouter } from 'react-router';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import {
  getMyOrganizationUserList,
  updateOrganizationName,
  getAllOrganizationList,
  changeUserRoleInOrganization,
  removeUserFromOrganization,
  getActiveOrganizationList,
} from 'store/actions/organization';
import { resendUserInvitation } from 'store/actions/invitation';
import { getUserAccount } from 'store/actions/user-account';
import { getWhitelist } from 'store/actions/whitelist';
import { getOrganizationSettings, updateOrganizationSettings } from 'store/actions/organization-settings';

function mapStateToProps(store) {
  return {
    organization: store.organization,
    userAccount: store.userAccount,
    whiteList: store.whiteList,
    organizationSettings: store.organizationSettings,
  };
}

function mapDispatchToProps() {
  return {
    getMyOrganizationUserList(onGetUserListSuccess, onGetUserListFail) {
      dispatchWithExceptionHandler(getMyOrganizationUserList(), false, onGetUserListSuccess, onGetUserListFail);
    },
    updateOrganizationName(organizationName, onUpdateOrganizationNameSuccess, onUpdateOrganizationNameFail) {
      dispatchWithExceptionHandler(
        updateOrganizationName(organizationName),
        false,
        onUpdateOrganizationNameSuccess,
        onUpdateOrganizationNameFail,
      );
    },
    getAllOrganizationList(onGetAllOrganizationListSuccess, onGetAllOrganizationListFail) {
      dispatchWithExceptionHandler(
        getAllOrganizationList(),
        false,
        onGetAllOrganizationListSuccess,
        onGetAllOrganizationListFail,
      );
    },
    getActiveOrganizationList() {
      dispatchWithExceptionHandler(getActiveOrganizationList());
    },
    getUserAccount(onGetUserAccountSuccess, onGetUserAccountFail) {
      dispatchWithExceptionHandler(
        getUserAccount(),
        // true,
        false,
        onGetUserAccountSuccess,
        onGetUserAccountFail,
      );
    },
    changeUserRoleInOrganization(
      userId,
      role,
      onChangeUserRoleInOrganizationSuccess,
      onChangeUserRoleInOrganizationFail,
    ) {
      dispatchWithExceptionHandler(
        changeUserRoleInOrganization(userId, role),
        // true,
        false,
        onChangeUserRoleInOrganizationSuccess,
        onChangeUserRoleInOrganizationFail,
      );
    },
    removeUserFromOrganization(userId, removeUserFromOrganizationSuccess, removeUserFromOrganizationFail) {
      dispatchWithExceptionHandler(
        removeUserFromOrganization(userId),
        // true,
        false,
        removeUserFromOrganizationSuccess,
        removeUserFromOrganizationFail,
      );
    },
    resendUserInvitation(invitationId, resendUserInvitationSuccess, resendUserInvitationFail) {
      dispatchWithExceptionHandler(
        resendUserInvitation(invitationId),
        // true,
        false,
        resendUserInvitationSuccess,
        resendUserInvitationFail,
      );
    },
    getWhiteList(organizationId, onSuccess, onError) {
      dispatchWithExceptionHandler(getWhitelist(organizationId), false, onSuccess, onError);
    },
    getOrganizationSettings(organizationId, onSuccess, onError) {
      dispatchWithExceptionHandler(getOrganizationSettings(organizationId), false, onSuccess, onError);
    },
    updateOrganizationSettings(
      organizationId,
      settings,
      onUpdateOrganizationSettingsSuccess,
      onUpdateOrganizationSettingsFail,
    ) {
      dispatchWithExceptionHandler(
        updateOrganizationSettings(organizationId, settings),
        false,
        onUpdateOrganizationSettingsSuccess,
        onUpdateOrganizationSettingsFail,
      );
    },
  };
}

const TeamOrganizationContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamOrganization));
export default TeamOrganizationContainer;
