import { LoadingDimmer } from 'components/ComponentPlaceholder';
import React from 'react';
import { Segment, TransitionablePortal, Button } from 'semantic-ui-react';
import AccordionCheckboxTreeList from './CheckboxTreeList/AccordionCheckboxTreeList';
import './WorkflowsFiltersSidePanel.scss';
import { ForesightInput } from 'custom-components/Input/Input';
import { ForesightCheckbox } from 'custom-components/Checkbox/Checkbox';
import { debounce } from 'lodash';
import { getConclusionToReadableMapping } from 'utils/workflowrun-conclusion-status-tooltips';
import { ForesightToggle } from 'custom-components/RadioButton/RadioButton';
import { checkIfFiltersActive } from 'utils/workflow-view-filters';
import { isEqualDeep, cloneArray } from 'utils/common-util';
import { hasArrayElement } from 'utils/array-util';
import { UI_ICON } from 'assets/font-icons/IconManager';

const initialState = {
  allPossibleBranchFilters: [],
  filteredRepoBranches: [],
  conclusions: [],
  filteredConclusions: [],
  selectAllStatusesClicked: false,
  hasOnlyDefaultBranchesSelected: false,
  hideForks: true,
};

const mobileStyle = {
  margin: '0',
  padding: '0',
  width: '%50',
  height: '100vh',
  zIndex: 1000,
  right: '0',
  position: 'fixed',
  top: '0%',
  backgroundColor: '#252B1F',
};
const webStyle = {
  margin: '0',
  padding: '0',
  width: '30%',
  height: '100vh',
  zIndex: 1000,
  left: '70%',
  position: 'fixed',
  top: '0%',
  backgroundColor: '#252B1F',
};

export class WorkflowsFiltersSidePanel extends React.Component {
  constructor(props) {
    super(props);
    this.handleStatusSearch = debounce(this.handleStatusSearch.bind(this), 100);
    this.handleBranchSearch = debounce(this.handleBranchSearch.bind(this), 100);
    this.state = initialState;
  }

  componentDidMount() {
    this.initializeData();
  }

  componentDidUpdate(nextProps) {
    // If filters are not active in incoming props, reset all advanced filters
    if (
      !checkIfFiltersActive(nextProps.filtersObject) &&
      !isEqualDeep(nextProps.filtersObject, this.props.filtersObject)
    ) {
      this.uncheckAllAdvancedFilters();
    }
  }

  checkOnlyDefaultBranchesSelected = possibleBranches => {
    let onlyDefaultSelected = true;
    // Foreach / map or other declarative loops has not used
    // since we can eager break the loop when we encounter a
    // non default branch
    for (let parent of possibleBranches) {
      for (let branchObject of parent.branches) {
        if (branchObject.checked && !branchObject.isDefaultBranch) {
          onlyDefaultSelected = false;
          break;
        } else if (!branchObject.checked && branchObject.isDefaultBranch) {
          onlyDefaultSelected = false;
          break;
        }
      }
    }
    return onlyDefaultSelected;
  };

  selectOnlyDefaultBranches = () => {
    this.state.allPossibleBranchFilters?.forEach(element => {
      element.branches = element.branches.map(branch => ({
        ...branch,
        checked: branch.isDefaultBranch,
      }));
    });
  };

  initializeData = () => {
    // If repoBranches is not undefined and filters are active in inital state, then enrich and initalize data
    if (this.props.workflowViewFilters?.workflowViewFilters?.repoBranches) {
      const { filtersObject } = this.props;
      const possibleBranchFilters = cloneArray(this.props.workflowViewFilters?.workflowViewFilters?.repoBranches);
      const defaultBranches = [];
      if (possibleBranchFilters && Object.keys(possibleBranchFilters).length > 0) {
        possibleBranchFilters?.forEach(element => {
          if (element.branches.includes(element.defaultBranch)) {
            defaultBranches.push({ branchName: element.defaultBranch, parent: element.fullName });
          }
          let enrichedBranches = [];
          element.branches.forEach(branch =>
            enrichedBranches.push({
              parent: element.fullName,
              name: branch,
              // isFilterActive would be true when one of the filter fields selected.
              // It would be false when all of the filter fields is in their default value.
              // See checkIfFiltersActive() method for details
              checked: filtersObject.repos.some(x => x.branches.includes(branch) && x.repoId == element.id),
              isDefaultBranch: defaultBranches.some(
                branchObj => branchObj.branchName == branch && branchObj.parent == element.fullName,
              ),
            }),
          );
          element.branches = enrichedBranches;
        });
      }
      let conclusions = this.props.workflowViewFilters?.workflowViewFilters?.conclusions;
      conclusions = conclusions?.map(conclusion => ({
        name: conclusion,
        checked: filtersObject.statuses.includes(conclusion),
        label: getConclusionToReadableMapping(conclusion),
      }));

      this.setState({
        allPossibleBranchFilters: possibleBranchFilters,
        filteredRepoBranches: possibleBranchFilters,
        conclusions: conclusions,
        filteredConclusions: conclusions,
        selectAllStatusesClicked: this.checkAllConclusionsSelected(conclusions),
        hasOnlyDefaultBranchesSelected: this.checkOnlyDefaultBranchesSelected(possibleBranchFilters),
      });
    }

    if (!checkIfFiltersActive(this.props.filtersObject)) {
      this.setState({ hasOnlyDefaultBranchesSelected: false });
    }
  };

  toggleNodeAllElements = (parent, toggleFlag) => {
    parent.branches.map(branch => {
      branch.checked = toggleFlag;
    });
  };

  uncheckBranchFilters = () => {
    const allPossibleBranchFilters = [...this.state.allPossibleBranchFilters];
    allPossibleBranchFilters?.forEach(element => {
      element.branches = element.branches.map(branch => ({
        ...branch,
        checked: false,
      }));
    });
    this.setState({
      allPossibleBranchFilters: allPossibleBranchFilters,
      hasOnlyDefaultBranchesSelected: false,
    });
  };

  uncheckConclusionFilters = () => {
    let conclusions = [...this.state.conclusions];
    conclusions?.forEach(conclusion => {
      conclusion.checked = false;
    });

    this.setState({
      conclusions: conclusions,
      selectAllStatusesClicked: false,
    });
  };

  uncheckAllAdvancedFilters = () => {
    this.uncheckBranchFilters();
    this.uncheckConclusionFilters();
  };

  renderRepoBranches = () => {
    if (this.state.filteredRepoBranches.some(filteredRepoBranch => hasArrayElement(filteredRepoBranch.branches))) {
      return this.state.filteredRepoBranches.map((repo, index) => {
        return (
          <AccordionCheckboxTreeList
            data={repo.branches}
            loading={false}
            key={index}
            parentName={repo.fullName}
            fetched
            handleCheckboxOnClick={this.handleCheckboxClick}
            handleSelectAllReposToggle={this.handleSelectAllReposToggle}
          />
        );
      });
    } else {
      return <div className="no-repo-in-list">No branch found</div>;
    }
  };

  /* A function that handles the checkbox click event. */
  handleCheckboxClick = (event, data) => {
    let allPossibleBranchFilters = [...this.state.allPossibleBranchFilters];

    allPossibleBranchFilters.map(parent => {
      parent.branches.map(branchObject => {
        if (data.label == branchObject.name && branchObject.parent == data.parentName) {
          branchObject.checked = data.checked;
        }
      });
    });

    this.setState({
      hasOnlyDefaultBranchesSelected: this.checkOnlyDefaultBranchesSelected(allPossibleBranchFilters),
      allPossibleBranchFilters: allPossibleBranchFilters,
    });
  };

  /* A function that is called when the user clicks on the "Select All" checkbox. */
  handleSelectAllReposToggle = data => {
    const allPossibleBranchFilters = [...this.state.allPossibleBranchFilters];

    const index = allPossibleBranchFilters.findIndex(branchObject => data.label == branchObject.fullName);
    let parent = allPossibleBranchFilters[index];
    if (index !== -1 && data.checked) {
      parent.branches.map(branch => {
        branch.checked = true;
      });
    } else if (index !== -1 && !data.checked) {
      {
        parent.branches.map(branch => {
          branch.checked = false;
        });
      }
    }
    this.setState({
      hasOnlyDefaultBranchesSelected: this.checkOnlyDefaultBranchesSelected(allPossibleBranchFilters),
      allPossibleBranchFilters: allPossibleBranchFilters,
    });
  };

  selectAllStatuses = (event, data) => {
    const conclusions = this.state.conclusions;
    conclusions.forEach(conclusion => {
      conclusion.checked = data.checked;
    });

    this.setState({
      selectAllStatusesClicked: data.checked,
    });
  };

  checkAllConclusionsSelected = conclusions => {
    return conclusions.every(c => c.checked == true);
  };

  handleCheckboxClickForStatus = data => {
    const curConclusions = [...this.state.conclusions];
    const index = curConclusions.findIndex(c => c.label == data.label);
    curConclusions[index].checked = data.checked;
    this.setState({
      conclusions: curConclusions,
      selectAllStatusesClicked: this.checkAllConclusionsSelected(curConclusions),
    });
  };

  renderStatuses = () => {
    return this.state.conclusions?.map((conclusion, index) => {
      return (
        <ForesightCheckbox
          checked={conclusion.checked}
          label={conclusion.label}
          key={index}
          onChange={(e, c) => this.handleCheckboxClickForStatus(c)}
          withIconName={null}
        />
      );
    });
  };

  handleStatusSearch = e => {
    const filteredConclusions = cloneArray(this.state.conclusions);
    this.setState({
      filteredConclusions: filteredConclusions.filter(repoConclusion => {
        const itemWords = repoConclusion.name.toLowerCase();
        const inputWords = e.target.value.toLowerCase();
        return itemWords.includes(inputWords);
      }),
    });
  };

  handleBranchSearch = e => {
    let allBranchesFilteredToBe = cloneArray(this.state.allPossibleBranchFilters);
    if (e.target.value !== '') {
      allBranchesFilteredToBe?.map(repoBranch => {
        repoBranch.branches =
          repoBranch.branches?.filter(branch => {
            if (branch.name.toLowerCase) {
              const itemWords = branch.name.toLowerCase();
              const parentWords = branch.parent.toLowerCase();
              const inputWords = e.target.value.toLowerCase();
              return itemWords.includes(inputWords) || parentWords.includes(inputWords);
            }
          }) || [];
      });
    }
    this.setState({
      filteredRepoBranches: allBranchesFilteredToBe,
    });
  };

  onSelectOnlyDefaultBranchesChange = data => {
    if (data.checked) {
      this.selectOnlyDefaultBranches();
    } else {
      this.uncheckBranchFilters();
    }
    this.setState({
      hasOnlyDefaultBranchesSelected: data.checked,
    });
  };

  /* Rendering the branches of a repository. */
  renderContent = () => {
    // Object destructuring for state elements
    const { fetching, error, fetched } = this.props.workflowViewFilters;
    const { selectAllStatusesClicked, hasOnlyDefaultBranchesSelected } = this.state;
    if (fetching) {
      return (
        <div>
          <LoadingDimmer />
        </div>
      );
    } else if (error) {
      return <div>Error</div>;
    } else if (fetched) {
      return (
        <>
          <>
            <div className="status-title">STATUS</div>
            <ForesightInput
              style={{ height: 32, marginBottom: 20 }}
              placeholder={'Search for status'}
              onChange={this.handleStatusSearch}
            />
            <ForesightCheckbox checked={selectAllStatusesClicked} label="Select all" onClick={this.selectAllStatuses} />
            <div className="statuses-wrapper">
              <div className="single-statuses-title">STATUS NAMES</div>
              {this.renderStatuses()}
            </div>
          </>
          <div className="select-tree-branches-container">
            <label className="repos-title">REPOSITORIES</label>
            <div className="show-only-main-branches">
              <ForesightCheckbox
                checked={hasOnlyDefaultBranchesSelected}
                label={'Select only default branches'}
                onChange={(e, data) => this.onSelectOnlyDefaultBranchesChange(data)}
              />
            </div>

            <ForesightInput
              style={{ height: 32 }}
              placeholder={'Search for a repository or branch'}
              onChange={this.handleBranchSearch}
            />
            <div className="wf-check-box-tree-list-cont">{this.renderRepoBranches()}</div>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <>
        {this.props.isOpen && (
          <div
            style={{
              width: '100%',
              height: '100%',
              background: 'rgba(106, 118, 137, 0.6)',
              zIndex: '999',
              top: 0,
              left: 0,
              position: 'absolute',
            }}
          />
        )}

        <TransitionablePortal
          closeOnDocumentClick
          onClose={this.props.closeDetailModal}
          closeOnEscape
          open={this.props.isOpen}
          isClearFilter={this.props.isClearFilter}
          transition={{ animation: 'slide left', duration: 300 }}
        >
          <Segment style={window.screen.width < 480 ? mobileStyle : webStyle}>
            <div className="workflows-filters-container">
              <div className="workflow-filters-title">
                <span className="title-text">Filter your workflows</span>
                <span className="title-icon" onClick={this.props.closeDetailModal}>
                  <i className={UI_ICON.CROSS.REPO_MODAL_CLOSE} />
                </span>
              </div>
              <div className="hide-forks-wrapper">
                <ForesightToggle
                  checked={!this.state.hideForks}
                  fitted
                  className="radio-button"
                  onClick={() => {
                    const newForkState = !this.state.hideForks;
                    this.setState({ hideForks: newForkState }, () => this.props.getWorkflowFilters(newForkState));
                  }}
                />
                <span className="text">Show Forks</span>
              </div>
              <div className="workflow-filters-content-container">
                <div className="workflow-filters-content">{this.renderContent()}</div>
              </div>
              <div className="filter-footer-container">
                <hr className="footer-divider"></hr>
                <div className="workflow-filters-actions">
                  <Button
                    primary
                    onClick={() => {
                      this.props.applyFilter({
                        allPossibleBranchFilters: this.state.allPossibleBranchFilters,
                        conclusions: this.state.conclusions,
                      });
                    }}
                  >
                    Apply Filter
                  </Button>
                </div>
              </div>
            </div>
          </Segment>
        </TransitionablePortal>
      </>
    );
  }
}
