export const GET_PR_SUMMARY_STATUS = 'GET_PR_SUMMARY_STATUS';
export const GET_PR_SUMMARY_STATUS_PENDING = 'GET_PR_SUMMARY_STATUS_PENDING';
export const GET_PR_SUMMARY_STATUS_REJECTED = 'GET_PR_SUMMARY_STATUS_REJECTED';
export const GET_PR_SUMMARY_STATUS_FULFILLED = 'GET_PR_SUMMARY_STATUS_FULFILLED';

export const GET_PR_SUMMARY_WORKFLOW_LIST = 'GET_PR_SUMMARY_WORKFLOW_LIST';
export const GET_PR_SUMMARY_WORKFLOW_LIST_PENDING = 'GET_PR_SUMMARY_WORKFLOW_LIST_PENDING';
export const GET_PR_SUMMARY_WORKFLOW_LIST_REJECTED = 'GET_PR_SUMMARY_WORKFLOW_LIST_REJECTED';
export const GET_PR_SUMMARY_WORKFLOW_LIST_FULFILLED = 'GET_PR_SUMMARY_WORKFLOW_LIST_FULFILLED';

export const GET_PR_SUMMARY_TEST_RUN_LIST = 'GET_PR_SUMMARY_TEST_RUN_LIST';
export const GET_PR_SUMMARY_TEST_RUN_LIST_PENDING = 'GET_PR_SUMMARY_TEST_RUN_LIST_PENDING';
export const GET_PR_SUMMARY_TEST_RUN_LIST_REJECTED = 'GET_PR_SUMMARY_TEST_RUN_LIST_REJECTED';
export const GET_PR_SUMMARY_TEST_RUN_LIST_FULFILLED = 'GET_PR_SUMMARY_TEST_RUN_LIST_FULFILLED';
