import { WORKFLOWRUN_CONCLUSION } from 'utils/workflowrun-conclusion-status';
import { WORKFLOWRUN_STATUS } from 'utils/workflowrun-status';

export const getWorkflowStatusTooltipContent = latestWorkflowRun => {
  return getWorkflowStatusContentText(latestWorkflowRun.status, latestWorkflowRun.conclusion);
};

export const getConclusionToReadableMapping = conclusion => {
  return getWorkflowStatusContentText(WORKFLOWRUN_STATUS.COMPLETED, conclusion);
};

export const getWorkflowStatusContentText = (status, conclusion) => {
  if (status !== WORKFLOWRUN_STATUS.COMPLETED) {
    switch (status) {
      case WORKFLOWRUN_STATUS.IN_PROGRESS:
        return 'Running';
      case WORKFLOWRUN_STATUS.QUEUED:
        return 'Queued';
      case WORKFLOWRUN_STATUS.UNKNOWN:
        return 'Unknown';
      default:
        return 'Not implemented';
    }
  } else {
    switch (conclusion) {
      case WORKFLOWRUN_CONCLUSION.SUCCESS:
        return 'Success';

      case WORKFLOWRUN_CONCLUSION.FAILURE:
        return 'Failure';

      case WORKFLOWRUN_CONCLUSION.ACTION_REQUIRED:
        return 'Action Required';

      case WORKFLOWRUN_CONCLUSION.CANCELLED:
        return 'Cancelled';
      case WORKFLOWRUN_CONCLUSION.NEUTRAL:
        return 'Neutral';

      case WORKFLOWRUN_CONCLUSION.SKIPPED:
        return 'Skipped';

      case WORKFLOWRUN_CONCLUSION.STALE:
        return 'Stale';

      case WORKFLOWRUN_CONCLUSION.TIMED_OUT:
        return 'Timed Out';

      case WORKFLOWRUN_STATUS.UNKNOWN:
        return 'Unknown';

      default:
        return 'Not implemented';
    }
  }
};
