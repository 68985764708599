import { PageSubHeader, PageSubHeaderText, PageSubHeaderTitle } from 'custom-components/PageSubHeader/PageSubHeader';
import React from 'react';
import './ManagePlan.scss';

class ManagePlan extends React.Component {
  getSlider = (min, value) => {
    return (
      <>
        <div className={'input-container'}>
          <input
            type={'range'}
            min={min}
            max={50}
            value={value}
            onChange={this.props.handleChangeCommitters}
            step={'1'}
          />
        </div>
        <div className={'input-container increment'}>
          <input
            disabled
            type={'number'}
            min={min}
            max={50}
            value={value}
            onChange={this.props.handleChangeCommitters}
          />
        </div>
      </>
    );
  };

  calculateEstimateCommitterCount = (committers, freeCommitterLimit) => {
    if (committers > freeCommitterLimit) return committers - freeCommitterLimit;
    else return 0;
  };

  render() {
    const { freeCommitterLimit, committers } = this.props;
    const { billingCustomer } = this.props.billingCustomer;

    return (
      <div className="manage-plan-container">
        <PageSubHeader style={{ paddingTop: 0, paddingBottom: 0 }}>
          <PageSubHeaderTitle>Manage Plan</PageSubHeaderTitle>
          <PageSubHeaderText>
            You can manage your plan by changing the committer limit you have. Please set your committer limit by
            checking the active committer list on the left.
          </PageSubHeaderText>
        </PageSubHeader>

        <div className="monthly-committer-limit-container">
          <div className="title">Monthly Committer Limit</div>
          <div className={'calculator-container'}>{this.getSlider(freeCommitterLimit, committers)}</div>
        </div>

        <div className="committer-and-payment-container">
          <div className="payment-container">
            <div className="payment-title">Payment Method</div>
            <div className="payment-credit-number">
              {billingCustomer.sourceExists ? '**** ' + billingCustomer.last4 : ''}
            </div>

            <div className="payment-change-action" onClick={this.props.handleChangePaymentMethod}>
              {billingCustomer.sourceExists ? 'Change Payment Method' : 'Add Card'}
            </div>
          </div>
          <div className="committer-info-container">
            <div className="committer-info-all">
              <div className="free-committers">
                <span className="title">Free Committers</span>
                <span className="value">{freeCommitterLimit}</span>
              </div>
              <div className="charge-committers">
                <span className="title">Charged Committers</span>
                <span className="value">{this.calculateEstimateCommitterCount(committers, freeCommitterLimit)}</span>
              </div>
              <div className="peer-committers">
                <span className="title">Peer Committers</span>
                <span className="value">$9</span>
              </div>
            </div>
            <div className="committer-info-sum">
              <div className="total-monthly">
                <span className="title">Total Monthly</span>
                <span className="value">
                  ${9 * this.calculateEstimateCommitterCount(committers, freeCommitterLimit)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManagePlan;
