import React from 'react';
import './ComponentPlaceholder.scss';

import { ALink } from 'custom-components/ALink/ALink';
import NoDataAvailableSVG from 'assets/images/no-data-available.svg';
import { Image } from 'semantic-ui-react';
import { UI_ICON } from 'assets/font-icons/IconManager';

//===========NO DATA PLACEHOLDERS =============================
function svgNoData() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.8H12.8V8H0V4.8ZM16 27.2V4.8H32V27.2H16ZM28.8 24V8H19.2V24H28.8ZM12.8 11.2H0V14.4H12.8V11.2ZM0 17.6H12.8V20.8H0V17.6ZM12.8 24H0V27.2H12.8V24Z"
        fill="#5C697C"
      />
    </svg>
  );
}

export const NoDataAvailable = ({ msg, isDisplayInline = false }) => {
  const displayInline = isDisplayInline ? 'horizontal' : '';

  return (
    <div className="no-resource-available-container">
      <div className={`image-and-text-container ${displayInline}`}>
        <div className={`image-part ${displayInline}`}>{svgNoData()}</div>
        <div className={`text-part ${displayInline}`}>{msg}</div>
      </div>
    </div>
  );
};

//===========NO ScreenShot PLACEHOLDERS =============================
function svgNoSS() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3989 7H3V20.3787L7.49999 15.8787L11.5 19.8787L20 11.3787L29 20.3787V7H13.6011C15.0133 7.61751 16 9.02685 16 10.6667C16 12.8758 14.2091 14.6667 12 14.6667C9.79086 14.6667 8 12.8758 8 10.6667C8 9.02685 8.98674 7.61751 10.3989 7ZM3 25.0706V24.6213L7.49999 20.1213L11.5 24.1213L20 15.6213L28.9393 24.5607L29 24.5V25.0706H3ZM0 4V28H32V4H0ZM13.3333 10.6667C13.3333 11.403 12.7364 12 12 12C11.2636 12 10.6667 11.403 10.6667 10.6667C10.6667 9.93029 11.2636 9.33333 12 9.33333C12.7364 9.33333 13.3333 9.93029 13.3333 10.6667Z"
        fill="#5C697C"
      />
    </svg>
  );
}

export const NoSSAvailable = ({ msg }) => (
  <div className="no-resource-available-container">
    <div className="image-and-text-container">
      <div className="image-part">{svgNoSS()}</div>
      <div className="text-part">{msg}</div>
    </div>
  </div>
);

//===========NO CHART PLACEHOLDERS =============================

function svgNoChart() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 4H3V25H32V28H0V4Z" fill="#5C697C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.7213 9.12132L19.6607 21.182L11.6607 13.182L6.72133 18.1213L4.60001 16L11.6607 8.93934L19.6607 16.9393L29.6 7L31.7213 9.12132Z"
        fill="#5C697C"
      />
    </svg>
  );
}

export const NoChartAvailable = ({ msg }) => (
  <div className="no-resource-available-container">
    <div className="image-and-text-container">
      <div className="image-part">{svgNoChart()}</div>
      <div className="text-part">{msg}</div>
    </div>
  </div>
);

export const SomethingError = ({ msg }) => {
  const sMsg = typeof msg === 'string' ? msg : 'fetching error';
  return (
    <div className="no-resource-available-container">
      <div className="image-and-text-container">
        <div className="image-part">{svgNoData()}</div>
        <div className="text-part">{sMsg}</div>
      </div>
    </div>
  );
};

//===========COMPONENT LOADINGS =============================
function svgLoadingFly() {
  return (
    <svg width="83" height="56" viewBox="0 0 83 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5927 55.552V54.8291L20.7061 53.4919L16.6334 53.9365L16.6979 52.5076C16.6979 52.5076 23.1464 52.881 25.3015 51.7101C27.4567 50.5391 40.4996 42.801 40.4996 42.801C40.4996 42.801 33.43 43.0555 27.3345 39.3358L30.5451 33.9054L24.7754 38.1615C21.8762 37.0043 19.1882 35.3759 16.8201 33.3421L16.7284 33.2233L22.7289 27.5113C22.7289 27.5113 17.8891 29.6393 14.5291 30.8339C14.37 30.7057 14.2181 30.5686 14.0744 30.4233C15.7713 29.3644 18.1471 27.9084 18.1471 27.9084L12.4487 28.6482C12.1093 28.4955 11.0096 27.4095 9.94393 25.665C9.33346 24.6823 8.79905 23.6543 8.34539 22.5901L21.653 18.0761L6.05787 16.8C6.05787 16.8 5.78636 16.6676 5.25011 15.5918C4.8998 14.8536 4.63012 14.0799 4.44575 13.2839L16.8133 11.6887L2.15823 8.064C2.15823 8.064 1.00769 6.16 0.814234 4.43588C0.655219 2.95282 0.744689 1.45362 1.07896 0C1.07896 0 12.5165 0.386909 23.9439 4.24242C35.3713 8.09794 36.9665 15.3236 36.9665 15.3236C37.2346 24.7622 39.5153 31.5127 47.3146 37.2315C55.1139 42.9503 63.575 41.2262 67.2065 37.9103C69.9963 35.3513 66.5277 33.8783 66.5277 33.8783C66.5277 33.8783 71.4659 33.4371 75.3961 34.1464C79.8353 34.944 82.2756 37.3571 82.2756 37.3571L16.5927 55.552Z"
        fill="#788496"
      />
    </svg>
  );
}

function svgLoadingLittleFly(animated = false) {
  return (
    <svg width="64" height="56" viewBox="0 0 83 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5927 55.552V54.8291L20.7061 53.4919L16.6334 53.9365L16.6979 52.5076C16.6979 52.5076 23.1464 52.881 25.3015 51.7101C27.4567 50.5391 40.4996 42.801 40.4996 42.801C40.4996 42.801 33.43 43.0555 27.3345 39.3358L30.5451 33.9054L24.7754 38.1615C21.8762 37.0043 19.1882 35.3759 16.8201 33.3421L16.7284 33.2233L22.7289 27.5113C22.7289 27.5113 17.8891 29.6393 14.5291 30.8339C14.37 30.7057 14.2181 30.5686 14.0744 30.4233C15.7713 29.3644 18.1471 27.9084 18.1471 27.9084L12.4487 28.6482C12.1093 28.4955 11.0096 27.4095 9.94393 25.665C9.33346 24.6823 8.79905 23.6543 8.34539 22.5901L21.653 18.0761L6.05787 16.8C6.05787 16.8 5.78636 16.6676 5.25011 15.5918C4.8998 14.8536 4.63012 14.0799 4.44575 13.2839L16.8133 11.6887L2.15823 8.064C2.15823 8.064 1.00769 6.16 0.814234 4.43588C0.655219 2.95282 0.744689 1.45362 1.07896 0C1.07896 0 12.5165 0.386909 23.9439 4.24242C35.3713 8.09794 36.9665 15.3236 36.9665 15.3236C37.2346 24.7622 39.5153 31.5127 47.3146 37.2315C55.1139 42.9503 63.575 41.2262 67.2065 37.9103C69.9963 35.3513 66.5277 33.8783 66.5277 33.8783C66.5277 33.8783 71.4659 33.4371 75.3961 34.1464C79.8353 34.944 82.2756 37.3571 82.2756 37.3571L16.5927 55.552Z"
        fill="#788496"
        className={animated ? 'animated-bird' : ''}
      />
    </svg>
  );
}

export const LoadingDimmer = ({ msg, withoutFly }) => (
  <div className="fly-loading-container">
    {!withoutFly && <div className="image-part">{svgLoadingFly()}</div>}
    <div className="text-part">{msg}</div>
    <div className="loading-lines">
      <div className="load-base">
        <div className="load-in" />
      </div>
    </div>
  </div>
);

export const LoadingDimmerWithNoLine = () => (
  <div className="fly-loading-container">
    <div className="image-part">{svgLoadingLittleFly(true)}</div>
  </div>
);

//===========EXTRA MESSAGE FOR TESTING MORE VISIBILITY =============================
export const GainMoreVisibilityOnYourTests = () => (
  <div className="gain-more-visibility-test-container">
    <div className="left-part">
      <div className="icon-part">
        <i className={UI_ICON.CROSS.CIRCLE} />
      </div>
      <div className="text-part">
        <div className="text-part-row-1">Gain more visibility on your tests</div>
        <div className="text-part-row-2">
          To see what happens when your test calls external services, attach our Java agent.
        </div>
        <div className="text-part-row-3">Learn More</div>
      </div>
    </div>
    <div className="right-part">Got It!</div>
  </div>
);

//=================NO_DATA_AVAILABE========================
export const renderNoDataAvailableForWorkflow = () => {
  return (
    <div className="list-nodata-wrapper">
      <div className="list-nodata-container">
        <div className="list-nodata-center-container">
          <div className="img-container">
            <Image src={NoDataAvailableSVG} />
          </div>
          <div className="content-container">
            <div className="header">No workflows found</div>
            <div className="text-container">
              <div className="text">
                <div>Possible reasons:</div>
                <ul>
                  <li>There are no workflows in your repository</li>
                  <li>You haven’t triggered your workflows in the last 14 days</li>
                  <li> You have uninstalled Foresight’s GitHub app</li>
                </ul>
              </div>
              <div className="text-container">
                <div className="text2">
                  Please feel free to reach out to us at
                  <br />
                  <ALink href="mailto: support@runforesight.com"> support@runforesight.com</ALink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const renderNoDataAvailableForTestRuns = () => {
  return (
    <div className="list-nodata-wrapper">
      <div className="list-nodata-container">
        <div className="list-nodata-center-container">
          <div className="img-container">
            <Image src={NoDataAvailableSVG} />
          </div>
          <div className="content-container">
            <div className="header">No Test Run</div>
            <div className="text-container">
              <div className="text">
                <div>Possible reasons:</div>
                <ul>
                  <li>You haven’t integrated Foresight into your tests</li>
                  <li>You haven’t ran your tests</li>
                </ul>
              </div>
              <div className="text-container">
                <div className="text2">
                  Please feel free to reach out to us at
                  <br />
                  <ALink href="mailto: support@runforesight.com"> support@runforesight.com</ALink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const renderNoDataAvailableForPRSummaryTestRuns = () => {
  return (
    <div className="list-nodata-wrapper">
      <div className="list-nodata-container">
        <div className="list-nodata-center-container">
          <div className="img-container">
            <Image src={NoDataAvailableSVG} />
          </div>
          <div className="content-container">
            <div className="header">No Failed Test Run</div>
            <div className="text-container">
              <div className="text">
                <div>Possible reasons:</div>
                <ul>
                  <li>Your tests are all sucessful</li>
                  <li>You haven’t integrated Foresight into your tests</li>
                  <li>You haven’t ran your tests</li>
                </ul>
              </div>
              <div className="text-container">
                <div className="text2">
                  Please feel free to reach out to us at
                  <br />
                  <ALink href="mailto: support@runforesight.com"> support@runforesight.com</ALink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const renderNoDataAvailableForPullRequest = () => {
  return (
    <div className="list-nodata-wrapper">
      <div className="list-nodata-container">
        <div className="list-nodata-center-container">
          <div className="img-container">
            <Image src={NoDataAvailableSVG} />
          </div>
          <div className="content-container">
            <div className="header">No Pull Request</div>
            <div className="text-container">
              <div className="text">
                <div>Integrate test kit action and see change impact analysis of your workflow run</div>
              </div>
              <div className="text-container">
                <div className="text2">
                  Already integrated?
                  <span>&nbsp;</span>
                  <ALink href="mailto: support@runforesight.com"> Click here to troubleshoot</ALink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const renderNoDataAvailableForChangeImpact = () => {
  return (
    <div className="list-nodata-wrapper">
      <div className="list-nodata-container">
        <div className="list-nodata-center-container">
          <div className="img-container">
            <Image src={NoDataAvailableSVG} />
          </div>
          <div className="content-container">
            <div className="header">No Change Impact Analysis</div>
            <div className="text-container">
              <div className="text">
                <div>Integrate test kit action and see change impact analysis of your workflow run</div>
              </div>
              <div className="text-container">
                <div className="text2">
                  Already integrated?
                  <span>&nbsp;</span>
                  <ALink href="mailto: support@runforesight.com"> Click here to troubleshoot</ALink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const renderNoDataAvailableForTestSuites = () => {
  return (
    <div className="list-nodata-wrapper">
      <div className="list-nodata-container">
        <div className="list-nodata-center-container">
          <div className="img-container">
            <Image src={NoDataAvailableSVG} />
          </div>
          <div className="content-container">
            <div className="header">No Test Suites</div>
            <div className="text-container">
              <div className="text">
                <div>Possible reasons:</div>
                <ul>
                  <li>You haven’t integrated Foresight into your tests</li>
                  <li>You haven’t ran your tests</li>
                </ul>
              </div>
              <div className="text-container">
                <div className="text2">
                  Please feel free to reach out to us at
                  <br />
                  <ALink href="mailto: support@runforesight.com"> support@runforesight.com</ALink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const renderNoDataAvailableForTestView = () => {
  return (
    <div className="list-nodata-wrapper">
      <div className="list-nodata-container">
        <div className="list-nodata-center-container">
          <div className="img-container">
            <Image src={NoDataAvailableSVG} />
          </div>
          <div className="content-container">
            <div className="header">No Test Views</div>
            <div className="text-container">
              <div className="text">
                <div>Possible reasons:</div>
                <ul>
                  <li>You haven’t integrated Foresight into your tests</li>
                  <li>You haven’t ran your tests</li>
                </ul>
              </div>
              <div className="text-container">
                <div className="text2">
                  Please feel free to reach out to us at
                  <br />
                  <ALink href="mailto: support@runforesight.com"> support@runforesight.com</ALink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
