import { connect } from 'react-redux';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { TestRunsFilter } from 'components';
import {
  getPullRequestTestRunsListByFilters,
  setSelectedPullRequestTestRunsBranches,
  setSelectedPullRequestTestRunsStatuses,
  setSelectedPullRequestTestRunsHideFork,
  getPullRequestTestRunsList,
  setSelectedPullRequestTestRunsTags,
} from 'store/actions/pull-request-test-runs';
import { withRouter } from 'react-router';

function mapStateToProps(store) {
  return {
    testRuns: store.pullRequestTestWRuns,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTestRunsList(
      ciProvider,
      repoOwner,
      repoName,
      afterKey,
      offset,
      filter,
      prId,
      isFirstPage = false,
      hideForkBranch,
      onGetTestRunsListSuccess,
      onGetTestRunsListFail,
    ) {
      dispatchWithExceptionHandler(
        getPullRequestTestRunsList(
          ciProvider,
          repoOwner,
          repoName,
          afterKey,
          offset,
          filter,
          prId,
          isFirstPage,
          hideForkBranch,
        ),
        false,
        onGetTestRunsListSuccess,
        onGetTestRunsListFail,
      );
    },
    getTestRunsListByFilters(ciProvider, repoOwner, repoName, prId, isFirstPage, hideFork, onSuccess, onError) {
      dispatchWithExceptionHandler(
        getPullRequestTestRunsListByFilters(ciProvider, repoOwner, repoName, prId, isFirstPage, hideFork),
        false, //  Organization Switch caused Error
        onSuccess,
        onError,
      );
    },

    setSelectedHideFork(hideFork, prId) {
      dispatch(setSelectedPullRequestTestRunsHideFork(hideFork, prId));
    },
    setSelectedTestRunsBranches(branches, prId) {
      dispatch(setSelectedPullRequestTestRunsBranches(branches, prId));
    },
    setSelectedTestRunsStatuses(status, prId) {
      dispatch(setSelectedPullRequestTestRunsStatuses(status, prId));
    },
    setSelectedTestRunsTags(tags, prId) {
      dispatch(setSelectedPullRequestTestRunsTags(tags, prId));
    },
  };
}

const TestRunsFilterPRContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(TestRunsFilter));
export default TestRunsFilterPRContainer;
