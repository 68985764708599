export const GET_WORKFLOW_RUNS_META = 'GET_WORKFLOW_RUNS_META';
export const GET_WORKFLOW_RUNS_META_PENDING = 'GET_WORKFLOW_RUNS_META_PENDING';
export const GET_WORKFLOW_RUNS_META_REJECTED = 'GET_WORKFLOW_RUNS_META_REJECTED';
export const GET_WORKFLOW_RUNS_META_FULFILLED = 'GET_WORKFLOW_RUNS_META_FULFILLED';

export const SET_WORKFLOW_IDS_SELECTED = 'SET_WORKFLOW_IDS_SELECTED';
export const SET_WORKFLOW_RUN_BRANCHES_SELECTED = 'SET_WORKFLOW_RUN_BRANCHES_SELECTED';
export const SET_WORKFLOW_RUN_CONCLUSIONS_SELECTED = 'SET_WORKFLOW_RUN_CONCLUSIONS_SELECTED';
export const SET_WORKFLOW_RUN_EVENTS_SELECTED = 'SET_WORKFLOW_RUN_EVENTS_SELECTED';
export const SET_WORKFLOW_RUN_HIDE_FORK_SELECTED = 'SET_WORKFLOW_RUN_HIDE_FORK_SELECTED';
export const SET_WORKFLOW_RUN_LABELS_SELECTED = 'SET_WORKFLOW_RUN_LABELS_SELECTED';

export const GET_WORKFLOW_RUNS_FILTER_LIST = 'GET_WORKFLOW_RUNS_FILTER_LIST';
export const GET_WORKFLOW_RUNS_FILTER_LIST_PENDING = 'GET_WORKFLOW_RUNS_FILTER_LIST_PENDING';
export const GET_WORKFLOW_RUNS_FILTER_LIST_REJECTED = 'GET_WORKFLOW_RUNS_FILTER_LIST_REJECTED';
export const GET_WORKFLOW_RUNS_FILTER_LIST_FULFILLED = 'GET_WORKFLOW_RUNS_FILTER_LIST_FULFILLED';

export const GET_WORKFLOW_RUNS_LABELS = 'GET_WORKFLOW_RUNS_LABELS';
export const GET_WORKFLOW_RUNS_LABELS_PENDING = 'GET_WORKFLOW_RUNS_LABELS_PENDING';
export const GET_WORKFLOW_RUNS_LABELS_REJECTED = 'GET_WORKFLOW_RUNS_LABELS_REJECTED';
export const GET_WORKFLOW_RUNS_LABELS_FULFILLED = 'GET_WORKFLOW_RUNS_LABELS_FULFILLED';
