import { PRSummary } from 'components/RepositoryPRPage/PRSummary/PRSummary';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  getPullRequestSummaryStatus,
  getPullRequestSummaryTestRunList,
  getPullRequestSummaryWorkflowList,
} from 'store/actions/pull-request-summary';
import { setSelectedPullRequest_WorkflowRuns_WorkflowIds } from 'store/actions/pull-request-workflow-runs-filter';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    pullRequestSummary: store.pullRequestSummary,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedWorkflowIds(ids, prId) {
      dispatch(setSelectedPullRequest_WorkflowRuns_WorkflowIds(ids, prId));
    },
    getPullRequestSummaryStatus(ciProvider, repoOwner, repoName, prId, onSummaryStatusSuccess, onSummaryStatusFail) {
      dispatchWithExceptionHandler(
        getPullRequestSummaryStatus(ciProvider, repoOwner, repoName, prId, onSummaryStatusSuccess, onSummaryStatusFail),
        false,
        onSummaryStatusSuccess,
        onSummaryStatusFail,
      );
    },
    getPullRequestSummaryWorkflowList(
      ciProvider,
      repoOwner,
      repoName,
      prId,
      pageNo,
      pageSize,
      onSummaryWorkflowListSuccess,
      onSummaryWorkflowListFail,
    ) {
      dispatchWithExceptionHandler(
        getPullRequestSummaryWorkflowList(
          ciProvider,
          repoOwner,
          repoName,
          prId,
          pageNo,
          pageSize,
          onSummaryWorkflowListSuccess,
          onSummaryWorkflowListFail,
        ),
        false,
        onSummaryWorkflowListSuccess,
        onSummaryWorkflowListFail,
      );
    },
    getPullRequestSummaryTestRunList(
      ciProvider,
      repoOwner,
      repoName,
      prId,
      pageNo,
      pageSize,
      onSummaryTestRunSuccess,
      onSummaryTestRunFail,
    ) {
      dispatchWithExceptionHandler(
        getPullRequestSummaryTestRunList(
          ciProvider,
          repoOwner,
          repoName,
          prId,
          pageNo,
          pageSize,
          onSummaryTestRunSuccess,
          onSummaryTestRunFail,
        ),
        false,
        onSummaryTestRunSuccess,
        onSummaryTestRunFail,
      );
    },
  };
}

const PRSummaryContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PRSummary));
export default PRSummaryContainer;
