import React from 'react';
import './Overview.scss';

export const OverviewCompContainer = ({ children, ...props }) => {
  return (
    <div className="overview-comp-container" {...props}>
      {children}
    </div>
  );
};
