import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
import {
  LoadingDimmer,
  renderNoDataAvailableForPRSummaryTestRuns,
  renderNoDataAvailableForWorkflow,
} from 'components/ComponentPlaceholder';
import { Tooltip } from 'components/Tooltip';
import { GrayBorderButton } from 'custom-components/Button/Button';
import { ForesightCircle } from 'custom-components/Circle/ForesightCircle';
import { ForesightStatusCard } from 'custom-components/StatusCard/ForesightStatusCard';
import { ForesightTable } from 'custom-components/Table/Table';
import React from 'react';
import { Link } from 'react-router-dom';
import routeList, { computePath } from 'routes';
import { Table } from 'semantic-ui-react';
import { millisToMAndS, millisToMAndSNotFixed } from 'utils/date-util';
import { getWorkflowStatusIconName } from 'utils/workflow-status-icon';
import { getWorkflowStatusContentText } from 'utils/workflowrun-conclusion-status-tooltips';
import './PRSummary.scss';

const TEST_STATUS_TEXT = {
  SUCCESSFUL: 'Success',
  FAILED: 'Failure',
  ABORTED: 'Aborted',
  SKIPPED: 'Skipped',
};
const ICON_NAME_TEST_STATUS = {
  SUCCESSFUL: DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.SUCCESS,
  FAILED: DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.FAILED,
  ABORTED: DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.ABORTED,
  SKIPPED: DOMAIN_ICON.GITHUB.TEST_RUN_STATUS.SKIPPED,
};

export class PRSummary extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { ciProvider, repoOwner, repoName, prId } = this.props.match.params;
    this.props.getPullRequestSummaryStatus(ciProvider, repoOwner, repoName, prId);
    this.props.getPullRequestSummaryWorkflowList(ciProvider, repoOwner, repoName, prId);
    this.props.getPullRequestSummaryTestRunList(ciProvider, repoOwner, repoName, prId);
  }

  statusWithIcon = (iconName, text) => {
    return (
      <div className="pr-summary-status-icon">
        <i className={iconName} />
        <div className="pr-summary-icon-text">{iconName === 'icon-icons-summary-cost' ? `$ ${text}` : text}</div>
      </div>
    );
  };

  renderStatusSection = () => {
    const { pullRequestSummaryStatus } = this.props.pullRequestSummary;
    const data = [
      {
        component: (
          <div className="pr-sum-status-item-header-cont">
            <div className="pr-sum-status-item-header">Total WF Runs Cost</div>
            {this.statusWithIcon(
              'icon-icons-summary-cost',
              pullRequestSummaryStatus.workflowEstimatedCost.toFixed(2) || 0,
            )}
          </div>
        ),
      },
      {
        component: (
          <div className="pr-sum-status-item-header-cont">
            <div className="pr-sum-status-item-header">Total WF Runs Duration</div>
            {this.statusWithIcon(
              'icon-icons-summary-duration',
              millisToMAndS(pullRequestSummaryStatus.workflowTotalDuration) || 0,
            )}
          </div>
        ),
      },
      {
        component: (
          <div className="pr-sum-status-item-header-cont">
            <div className="pr-sum-status-item-header">Test Runs Duration Cost</div>
            {this.statusWithIcon(
              'icon-icons-summary-duration',
              millisToMAndS(pullRequestSummaryStatus.testTotalDuration || 0),
            )}
          </div>
        ),
      },
      {
        component: (
          <div className="pr-sum-status-item-header-cont">
            <div className="pr-sum-status-item-header">Change Impact</div>
            <ForesightCircle
              subText={`${pullRequestSummaryStatus.patchCoveredLineCount}/${
                pullRequestSummaryStatus.patchUncoveredLineCount + pullRequestSummaryStatus.patchCoveredLineCount
              }`}
              textPosition="right"
              size="small"
              data={[
                {
                  name: 'Group A',
                  value: pullRequestSummaryStatus.coverageRate,
                  color: pullRequestSummaryStatus.coverageRate < 50 ? '#FF9214' : '#2DCD8A',
                },
                {
                  name: 'Group B',
                  value: 100 - pullRequestSummaryStatus.coverageRate,
                  color: '#2d353c',
                },
              ]}
            />
          </div>
        ),
      },
    ];

    return <ForesightStatusCard hasVerticalLine={false} data={data} />;
  };

  renderPRTable = () => {
    const { pullRequestSummaryWorkflowList } = this.props.pullRequestSummary;
    const { ciProvider, repoOwner, repoName, prId } = this.props.match.params;

    return (
      <>
        {pullRequestSummaryWorkflowList.length > 0 ? (
          <ForesightTable basic="very">
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell width={4}>Workflow</Table.HeaderCell>
                <Table.HeaderCell width={3}># of exec.</Table.HeaderCell>
                <Table.HeaderCell width={3}>Avg. Duration</Table.HeaderCell>
                <Table.HeaderCell width={5}>Latest Run</Table.HeaderCell>
                <Table.HeaderCell textAlign="right" width={3}>
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {pullRequestSummaryWorkflowList.map(item => {
                return (
                  <Table.Row key={item.id} className="table-row">
                    <Table.Cell>
                      <div style={{ display: 'inline-flex', fontSize: '14px' }}>{item.name}</div>
                    </Table.Cell>
                    <Table.Cell>{item.executionCount}</Table.Cell>
                    <Table.Cell>{millisToMAndS(item.averageDuration)}</Table.Cell>
                    <Table.Cell>
                      <div style={{ display: 'flex', gap: 66 }}>
                        <div style={{ display: 'flex', gap: 6, alignItems: 'center', width: 100 }}>
                          <Tooltip blackEdition content="Latest run status">
                            <i
                              style={{ fontSize: 16 }}
                              className={getWorkflowStatusIconName(item?.latestRunStatus, item.latestRunConclusion)}
                            />
                          </Tooltip>
                          <div style={{ fontSize: '14px' }}>
                            {getWorkflowStatusContentText(item?.latestRunStatus, item.latestRunConclusion)}
                          </div>
                        </div>

                        <div style={{ display: 'flex', gap: 6, alignItems: 'center', justifyContent: 'space-between' }}>
                          {item.latestRunTestFailRate !== null && (
                            <Tooltip blackEdition content="Latest Run Test Failure Rate">
                              <i style={{ fontSize: 12 }} className="icon-failure-rate1" />
                            </Tooltip>
                          )}
                          <div style={{ fontSize: '14px' }}>
                            {item.latestRunTestFailRate === null ? 'N/A' : `${item.latestRunTestFailRate.toFixed(2)} %`}{' '}
                          </div>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Link
                        className="workflow-run-item-link-wrapper"
                        onClick={() => this.props.setSelectedWorkflowIds([item.id], prId)}
                        to={computePath(routeList.prOverview.path, {
                          ciProvider: ciProvider,
                          repoOwner: repoOwner,
                          repoName: repoName,
                          prId: prId,
                          tabName: 'workflow-runs',
                        })}
                      >
                        <GrayBorderButton style={{ width: 160 }}>See Workflow Runs</GrayBorderButton>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </ForesightTable>
        ) : (
          renderNoDataAvailableForWorkflow()
        )}
      </>
    );
  };

  renderPRTestTable = () => {
    const { pullRequestSummaryTestRunList } = this.props.pullRequestSummary;
    const { ciProvider, repoOwner, repoName } = this.props.match.params;

    return (
      <>
        {pullRequestSummaryTestRunList.length > 0 ? (
          <ForesightTable basic="very">
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell width={4}>Test</Table.HeaderCell>
                <Table.HeaderCell width={2}># of exec.</Table.HeaderCell>
                <Table.HeaderCell width={2}>Failure Count</Table.HeaderCell>
                <Table.HeaderCell width={3}>Avg. Duration</Table.HeaderCell>
                <Table.HeaderCell width={5}>Latest Run</Table.HeaderCell>
                <Table.HeaderCell textAlign="right" width={3}>
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {pullRequestSummaryTestRunList.map(item => {
                return (
                  <Table.Row key={item.id} className="table-row">
                    <Table.Cell>
                      <div style={{ display: 'inline-flex', fontSize: '14px' }}>{item.testName}</div>
                      <div style={{ display: 'flex', fontSize: '14px', alignItems: 'center', gap: 3, marginTop: 5 }}>
                        <i className="icon-icons-checks-tests" style={{ fontSize: '12px' }} />
                        <div style={{ fontSize: '12px' }}>{item.testSuiteName}</div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{item.executionCount}</Table.Cell>
                    <Table.Cell>{item.failCount}</Table.Cell>
                    <Table.Cell>{millisToMAndSNotFixed(item.averageDuration)}</Table.Cell>
                    <Table.Cell>
                      <div style={{ display: 'flex', gap: 66 }}>
                        <div style={{ display: 'flex', gap: 6, alignItems: 'center', width: 100 }}>
                          <Tooltip blackEdition content="Latest run status">
                            <i style={{ fontSize: 16 }} className={ICON_NAME_TEST_STATUS[item.latestRunStatus]} />
                          </Tooltip>
                          <div style={{ fontSize: '14px' }}>{TEST_STATUS_TEXT[item.latestRunStatus]}</div>
                        </div>

                        <div style={{ display: 'flex', gap: 6, alignItems: 'center', justifyContent: 'space-between' }}>
                          <Tooltip blackEdition content="Latest Run Test Failure Rate">
                            <i style={{ fontSize: 12 }} className="icon-failure-rate1" />
                          </Tooltip>
                          <div style={{ fontSize: '14px' }}>{item.failRate.toFixed(2)} %</div>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Link
                        className="workflow-run-item-link-wrapper"
                        to={computePath(routeList.testRunOverview.path, {
                          testRunId: item.latestRunId,
                          ciProvider: ciProvider,
                          repoOwner: repoOwner,
                          repoName: repoName,
                        })}
                      >
                        <GrayBorderButton style={{ width: 170 }}>See Latest Test Run</GrayBorderButton>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </ForesightTable>
        ) : (
          renderNoDataAvailableForPRSummaryTestRuns()
        )}
      </>
    );
  };

  render() {
    const { pullRequestSummaryFetched, pullRequestSummaryWorkflowListFetched } = this.props.pullRequestSummary;
    if (!pullRequestSummaryFetched && !pullRequestSummaryWorkflowListFetched) {
      return <LoadingDimmer />;
    }
    return (
      <div className="pr-sum-main-container">
        {this.renderStatusSection()}
        <div style={{ marginTop: 58 }}>
          <div className="pr-sum-workflow-table">Workflow Summary</div>
          {this.renderPRTable()}
        </div>
        <div style={{ marginTop: 58 }}>
          <div className="pr-sum-workflow-table">Failed Test Summary</div>
          {this.renderPRTestTable()}
        </div>
      </div>
    );
  }
}
