export const GET_CPU_METRICS = 'GET_CPU_METRICS';
export const GET_CPU_METRICS_PENDING = 'GET_CPU_METRICS_PENDING';
export const GET_CPU_METRICS_REJECTED = 'GET_CPU_METRICS_REJECTED';
export const GET_CPU_METRICS_FULFILLED = 'GET_CPU_METRICS_FULFILLED';

export const GET_MEMORY_METRICS = 'GET_MEMORY_METRICS';
export const GET_MEMORY_METRICS_PENDING = 'GET_MEMORY_METRICS_PENDING';
export const GET_MEMORY_METRICS_REJECTED = 'GET_MEMORY_METRICS_REJECTED';
export const GET_MEMORY_METRICS_FULFILLED = 'GET_MEMORY_METRICS_FULFILLED';

export const GET_DISK_METRICS = 'GET_DISK_METRICS';
export const GET_DISK_METRICS_PENDING = 'GET_DISK_METRICS_PENDING';
export const GET_DISK_METRICS_REJECTED = 'GET_DISK_METRICS_REJECTED';
export const GET_DISK_METRICS_FULFILLED = 'GET_DISK_METRICS_FULFILLED';

export const GET_NETWORK_METRICS = 'GET_NETWORK_METRICS';
export const GET_NETWORK_METRICS_PENDING = 'GET_NETWORK_METRICS_PENDING';
export const GET_NETWORK_METRICS_REJECTED = 'GET_NETWORK_METRICS_REJECTED';
export const GET_NETWORK_METRICS_FULFILLED = 'GET_NETWORK_METRICS_FULFILLED';
