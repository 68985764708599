import { connect } from 'react-redux';
import { TestRunOverviewCard } from 'components';
import { withRouter } from 'react-router';

function mapStateToProps(store) {
	return {
		testRunDetails: store.testRunDetails,
		testDetails: store.testDetails,
	};
}

function mapDispatchToProps() {
	return {};
}

const TestRunOverviewCardContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(TestRunOverviewCard));
export default TestRunOverviewCardContainer;
