import { LoadingDimmer, NoDataAvailable, SomethingError } from 'components/index';
import React, { Component } from 'react';
import { hasArrayElement } from 'utils/array-util';
import PieChartComponent from './PieChartComponent';
import './PieChartWidget.scss';

const arrMap = {
	one: '1',
	two: '2',
	three: '3',
	five: '5',
	fifteen: '15',
	thirty: '30',
};


export default class PieChartWidget extends Component {
	constructor(props) {
		super(props);
	}

	formatTimeNumber = text => {
		if (arrMap[`${text}`]) return arrMap[`${text}`];
		return text;
	};

	formatTimeRange = timeRange => {
		if (typeof timeRange !== 'string') return '';
		return timeRange
			.split('_')
			.map(el => this.formatTimeNumber(el.toLowerCase()))
			.join(' ');
	};

	renderWithContent = () => {
		const { chartData, tooltipContent, outerRadius, onClick, selectedPie, sentListName } = this.props;

		return (
			<div className="dashboard-widget-card-container">
				<div className="chart">
					<PieChartComponent
						pieChartData={chartData}
						tooltipContent={tooltipContent}
						outerRadius={outerRadius}
						onClick={onClick}
						selectedPie={selectedPie}
						sentListName={sentListName}
					/>
				</div>
			</div>
		);
	};

	renderWithoutContent = () => {
		const { title, timeRange, error, loading, unknown } = this.props;

		let contentJSX = <NoDataAvailable msg={'No data available'} />;
		if (error) {
			contentJSX = <SomethingError msg={error} />;
		} else if (loading) {
			contentJSX = <LoadingDimmer msg={'Loading...'} />;
		} else if (unknown) {
			contentJSX = <LoadingDimmer msg={'Unknown State...'} />;
		}

		return (
			<div className="dashboard-widget-card-container">
				<div className="header">
					<div className="title">{title}</div>
					<div className="period-and-actions">
						<div className="period">Last {this.formatTimeRange(timeRange)}</div>
					</div>
				</div>
				<div className="other-contents">{contentJSX}</div>
			</div>
		);
	};

	render() {
		const { chartData } = this.props;

		if (hasArrayElement(chartData)) {
			return this.renderWithContent();
		} else {
			return this.renderWithoutContent();
		}
	}
}
