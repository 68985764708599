import Loadable from 'react-loadable';
import React from 'react';
import { LoadingPage, ErrorPage } from 'components';

const Loading = props => {
	if (props.error) {
		return <ErrorPage />;
	} else if (props.pastDelay) {
		return <LoadingPage />;
	} else {
		return null;
	}
};

const AsyncErrorPageContainer = Loadable({
	loader: () => import('./ErrorPageContainer'),
	loading: Loading,
	delay: 300,
});

export { AsyncErrorPageContainer };
