export const BadgeTypeEnum = {
	LatestTestRun: {
		text: 'Latest Test Run Result',
		value: 'test',
	},
	SuccessfulTests: {
		text: 'Successful Tests',
		value: 'success',
	},
	Utilization: {
		text: 'Utilization',
		value: 'utilization',
	},
};
