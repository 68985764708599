import React from 'react';
import { Button } from 'semantic-ui-react';
import './Button.scss';

export const BlueButton = ({ ...props }) => {
  return <Button disabled={props.loading || props.disabled} primary className="BlueButton" {...props}></Button>;
};

export const RedButton = ({ ...props }) => {
  return <Button {...props} className="RedButton" secondary></Button>;
};

export const TextButton = ({ ...props }) => {
  return <Button {...props} className="TextButton"></Button>;
};

export const ToggleButton = ({ ...props }) => {
  return <Button toggle {...props} className="ToggleButton"></Button>;
};

export const GrayBorderButton = ({ ...props }) => {
  return <Button {...props} className="GrayBorderButton"></Button>;
};
