import { SourceFilesCoverageDetails } from 'components/RepositoryWorkflowRunOverviewPage/ImpactAnalysis/SourceFilesCoverageDetails';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getSourceCoverage } from 'store/actions/source-coverage';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    sourceCoverageDetails: store.sourceCoverageDetails,
  };
}

function mapDispatchToProps() {
  return {
    getSourceCoverage(workflowId, fileFullPath, jobId) {
      dispatchWithExceptionHandler(getSourceCoverage(workflowId, fileFullPath, jobId));
    },
  };
}

const SourceFilesCoverageDetailsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SourceFilesCoverageDetails),
);
export default SourceFilesCoverageDetailsContainer;
