import React, { Component } from 'react';
import { Tooltip } from 'components/Tooltip';
import moment from 'moment';
import HeatMap from 'react-heatmap-grid';
import HeatColorRanges from './HeatColorRanges/HearColorRanges';
import './HeatMapGridComponent.scss';
import { HIGHLIGHTS_DATE_ENUM } from 'utils/highlights-enums';

const cellRenderStyle = {
  oneWeekCell: {
    width: '32px',
    height: '32px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  oneMonthCell: {
    width: '18px',
    height: '18px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
};

const TIME_RANGE_CSS = {
  THIRTY_DAYS: {
    fontSize: '11px',
    width: '18px',
    height: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '2px',
    marginRight: '4px',
  },
  SEVEN_DAYS: {
    fontSize: '11px',
    width: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px',
    marginRight: '48px',
    color: '#fff',
  },
  ONE_DAY: {
    fontSize: '11px',
    width: '21px',
    height: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '2px',
  },
};

class HeatMapGridComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newData: [],
      yLabels: [],
      xLabels: [],
      xLabelsVisibility: [],
    };
  }

  componentDidMount() {
    this.manupulateData();
  }

  manupulateData = () => {
    const { data, timeRange } = this.props;
    let xLabels = [];
    const yLabels = [];
    const newData = [];
    let doubleArr = [];
    let xLabelsVisibilityShow = [];
    Object.entries(data[0].errorCounts).map((_, index) => {
      if (timeRange === HIGHLIGHTS_DATE_ENUM.ONE_MONTH) {
        xLabelsVisibilityShow.push(index % 3 === 0);
      } else if (timeRange === HIGHLIGHTS_DATE_ENUM.ONE_DAY) {
        xLabelsVisibilityShow.push(index % 2 === 0);
      }
    });
    data.forEach((item, index) => {
      doubleArr = [];
      yLabels.push(item.name);
      for (const [key, value] of Object.entries(item.errorCounts).sort()) {
        if (index === 0) {
          xLabels.push(
            moment(key, 'YYYY-MM-DD HH:mm:ss.sss Z')
              .local()
              .format(timeRange !== HIGHLIGHTS_DATE_ENUM.ONE_DAY ? 'DD/MM' : 'hh:mm A'),
          );
        }
        doubleArr.push(value);
      }
      newData.push(doubleArr);
    });
    this.setState({
      yLabels: yLabels,
      xLabels: xLabels,
      newData: newData,
      xLabelsVisibility: xLabelsVisibilityShow,
    });
  };

  handleTimeRangeCss = timeRange => {
    switch (timeRange) {
      case HIGHLIGHTS_DATE_ENUM.ONE_DAY:
        return 'heatmap-grid-container-one-day';
      case HIGHLIGHTS_DATE_ENUM.ONE_WEEK:
        return 'heatmap-grid-seven-container';
      case HIGHLIGHTS_DATE_ENUM.ONE_MONTH:
        return 'heatmap-grid-container';
      default:
    }
  };

  render() {
    const { yLabels, xLabels, newData, xLabelsVisibility } = this.state;
    const { timeRange, data, handlerId } = this.props;

    const tempData = data.map(el => {
      let newWorkflowName = el?.repo?.fullName + '/' + el?.name;
      return {
        repoWorkflowName: newWorkflowName,
        total: el.totalErrorCount,
        pathData: {
          workflowId: el.id,
          workflowName: newWorkflowName,
          repoFullName: el?.repo?.fullName,
        },
      };
    });

    const rowJSX = tempData.map((el, index) => {
      return (
        <tr
          className="avg-duration-list-widget-row"
          key={index}
          onClick={event => handlerId(event, el.pathData)}
          style={{ height: '24px' }}
        >
          <td>
            <div className="workflow-id">{index + 1}</div>
          </td>
          <td>
            <div className="workflow-name">
              <span className="workflow-list-cell-text"> {el.repoWorkflowName}</span>
            </div>
          </td>
          <td>
            <div className="workflow-stat">
              <span className="workflow-list-cell-text"> {el.total}</span>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className={this.handleTimeRangeCss(timeRange)}>
          <HeatColorRanges data={newData} range={timeRange} />
          <HeatMap
            xLabels={xLabels}
            yLabels={yLabels.map((value, index) => index + 1)}
            xLabelWidth={1220}
            xLabelsLocation={'bottom'}
            yLabelWidth={70}
            xLabelsVisibility={timeRange === HIGHLIGHTS_DATE_ENUM.ONE_WEEK ? undefined : xLabelsVisibility}
            data={newData || []}
            squares
            onClick={(x, y) => console.log(x, y)}
            cellStyle={(_background, value, min, max) => ({
              background: `${
                Object.is(0, value) ? '#151A20' : `rgb(239, 38, 34, ${1 - (max - value) / (max - min) + 0.1})`
              }`,
              ...TIME_RANGE_CSS[timeRange],
            })}
            cellRender={(value, date, x) => (
              <Tooltip
                blackEdition
                content={
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ color: '#DCDFE5', fontSize: '12px' }}>{value}</div>

                    <div style={{ color: '#96A0B0', fontSize: '10px' }}>
                      {timeRange === HIGHLIGHTS_DATE_ENUM.ONE_DAY
                        ? 'Today - ' + date
                        : moment(date, 'DD/MM').format('DD MMM')}
                    </div>
                    <div style={{ color: '#DCDFE5', fontSize: '10px' }}>{yLabels[x - 1]}</div>
                  </div>
                }
              >
                <div
                  title=""
                  style={
                    timeRange === HIGHLIGHTS_DATE_ENUM.ONE_WEEK
                      ? cellRenderStyle.oneWeekCell
                      : cellRenderStyle.oneMonthCell
                  }
                >
                  {timeRange === HIGHLIGHTS_DATE_ENUM.ONE_WEEK ? value && `${value}` : null}
                </div>
              </Tooltip>
            )}
            title={(value, unit) => `${value + unit}`}
          />
        </div>
        <div className={timeRange === HIGHLIGHTS_DATE_ENUM.ONE_WEEK ? 'label-que' : 'label-que-small'}>
          <div className="avg-header-container">
            <table className="avg-duration-list-table" cellSpacing="0" cellPadding="0">
              <tbody>
                <tr>
                  <th className="header-workflow-id"></th>
                  <th style={{ textAlign: 'left' }} className="header-workflow-name">
                    Workflow name
                  </th>
                  <th style={{ textAlign: 'right' }} className="header-workflow-duration">
                    # of error
                  </th>
                </tr>
                {rowJSX}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}
export default HeatMapGridComponent;
