import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { GET_ACTIVE_SUBSCRIPTION } from 'store/constants/billing-active-subscription';

export const getActiveSubscription = organizationId => {
  return {
    type: GET_ACTIVE_SUBSCRIPTION,
    payload: axios.get(CONFIG.apiUrl + `/organizations/${organizationId}/subscriptions/active`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
