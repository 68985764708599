import { DOMAIN_ICON } from 'assets/font-icons/IconManager';
import { Tooltip } from 'components/Tooltip';
import React, { useEffect } from 'react';
import { MiniCard } from '../../MiniCard/MiniCard';
import './StepSummary.scss';
import { calculatePercentage } from 'components/RepositoryWorkflowRunTimelinePage/util/Util';
import StepBarChart from '../../BarChart/StepBarChart';
import { LoadingDimmer } from 'components/ComponentPlaceholder';
import { millisToMAndSNotFixed } from 'utils/date-util';

export const StepSummary = ({ workflowRunId, jobId, stepId, stepSummaryProps, onClickDetails }) => {
  const {
    workflowRunTimeline: {
      workflowRunTimelineTraceChart: {
        jobs,
        workflow: { id },
      },
      stepCpuMetricsContent,
      stepMemoryMetricsContent,
      stepHistoryContent,
      stepHistoryContentFetching,
    },
  } = stepSummaryProps;

  const foundJob = jobs.find(item => item.id === jobId);
  const foundStep = foundJob.steps.find(item => item.number === Number(stepId));

  useEffect(() => {
    const { getStepCpuMetrics, getStepMemoryMetrics, getStepHistory } = stepSummaryProps;

    getStepCpuMetrics(workflowRunId, jobId, foundJob.startedAt, foundJob.completedAt);
    getStepMemoryMetrics(workflowRunId, jobId, foundJob.startedAt, foundJob.completedAt);
    getStepHistory(id, stepId, { jobName: foundJob.name });
  }, [stepId]);

  return (
    <>
      <div className="wf-run-summary-title">Step Summary</div>
      <div className="wf-run-summary-container">
        <div className="wf-run-job-summary-name-cont">
          <div className="icon-cont">
            <i className={`${DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS[foundStep.conclusion]}`} />
          </div>
          <Tooltip blackEdition content={foundStep.name}>
            <div className="text-cont">{foundStep.name}</div>
          </Tooltip>
        </div>
        <MiniCard title="WORKFLOW HISTORY">
          {stepHistoryContentFetching ? (
            <LoadingDimmer />
          ) : (
            <>
              <div className="mini-card-sub-info" style={{ marginBottom: 12 }}>
                {millisToMAndSNotFixed(stepHistoryContent[0]?.workflowStepDtoList[0]?.duration)}
                <div className="mini-card-title-not-uppercase">Latest Execution Time</div>
              </div>
              <StepBarChart data={stepHistoryContent[0]?.workflowStepDtoList || []} />
              <div className="mini-card-title-not-uppercase">Step Execution History</div>
            </>
          )}
        </MiniCard>

        <MiniCard>
          <div className="step-process">
            <div className="mini-card-title">LOGS</div>
            <div
              className="link-text"
              onClick={() => {
                onClickDetails('logs');
              }}
            >
              View all logs
            </div>
          </div>
          <div className="step-process">
            <div className="mini-card-title">PROCESS</div>
            <div
              className="link-text"
              style={{ marginBottom: 24 }}
              onClick={() => {
                onClickDetails('processes');
              }}
            >
              View process
            </div>
          </div>
          <div className="job-metric-container">
            <div className="memory-usage-active-job">
              <div className="value">
                {stepCpuMetricsContent.cpuLoadTotal
                  ? `${calculatePercentage(
                      stepCpuMetricsContent.cpuLoadSystem,
                      stepCpuMetricsContent.cpuLoadUser,
                      stepCpuMetricsContent.cpuLoadTotal,
                    )}
                %`
                  : 'No data'}
              </div>
              <div className="sub-text">CPU Load System</div>
            </div>
            <div className="memory-usage-active-job">
              <div className="value">
                {stepMemoryMetricsContent.memoryUsageTotal
                  ? `${stepMemoryMetricsContent.memoryUsageActive} mb (
                  ${calculatePercentage(
                    stepMemoryMetricsContent.memoryUsageActive,
                    stepMemoryMetricsContent.memoryUsageAvailable,
                    stepMemoryMetricsContent.memoryUsageTotal,
                  )}
                  %)`
                  : 'No data'}
              </div>
              <div className="sub-text">Active Memory Usage</div>
            </div>
          </div>
        </MiniCard>
      </div>
    </>
  );
};
