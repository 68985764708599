import React, { Component } from 'react';
import HeatMap from '@uiw/react-heat-map';
import PropTypes from 'prop-types';
import { Tooltip } from 'components/Tooltip';
import moment from 'moment';
import _ from 'lodash';

const tooltipContentStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '12px',
    color: '#96A0B0',
};
class HeatMapComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newCountData: [],
            cssValues: null,
            lagendArr: [],
        };
    }

    componentDidMount() {
        const { value } = this.props;
        let min = 0;
        let max = 0;
        let newArr = [];
        value.forEach(item => {
            if (item.count > max) max = item.count;
            if (item.count < min) min = item.count;
        });

        const rangeCount = 8;
        const maxControl = max > 7 ? max : 7;
        const gap = Math.round(maxControl / 7);
        for (let i = 0; i < maxControl; i += gap) {
            newArr.push(i);
        }

        let color = {};
        const stepVal = 1 / 7;
        for (let i = 0; i < rangeCount; i++) {
            color[i] = `rgb(128, 70, 250, ${stepVal * (i + 1)})`;
        }

        const convertHeatMapData = value.map(el => {
            return {
                date: el.date,
                count: el.count,
            };
        });
        this.setState({
            newCountData: convertHeatMapData,
            cssValues: color,
            lagendArr: newArr,
        });
    }

    handlePanelColors = () => {
        const { cssValues, lagendArr } = this.state;
        let panelObj = {};
        lagendArr.forEach((item, index) => {
            if (index < 7) {
                panelObj[item] = cssValues && cssValues[index];
            }
        }

        );

        return panelObj;
    };

    render() {
        const { newCountData } = this.state;
        const { range, width, startDate, endDate } = this.props;
        return (
            <div>
                <HeatMap
                    style={{ color: '#96A0B0', fontSize: '11px', fontFamily: 'Inter', lineHeight: '12px' }}
                    value={newCountData}
                    width={width}
                    startDate={startDate}
                    endDate={endDate}
                    rectSize={20}
                    legendRender={(props) => <rect {...props} y={props.y + 5} x={props.x + 5} rx={range - 3} />}
                    rectProps={{
                        rx: range,
                    }}
                    rectRender={(props, data) => {
                        if (Object.is(data.count, undefined)) return <rect key={_.uniqueId()} />;
                        if (Object.is(data.count, 0)) {
                            return (
                                <Tooltip
                                    key={_.uniqueId()}
                                    placement="top"
                                    content={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontSize: '12px' }}>{0} runs</div>
                                        <div style={tooltipContentStyle}>on {moment(data.date).format('DD MMM')}</div>
                                    </div>}
                                    blackEdition

                                >
                                    <rect {...props} fill='#151A20' />
                                </Tooltip >);
                        }
                        return (
                            <Tooltip
                                blackEdition
                                key={_.uniqueId()}
                                placement="top"
                                content={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ fontSize: '12px' }}>{data.count} runs</div>
                                    <div style={tooltipContentStyle}>on {moment(data.date).format('DD MMM')}</div>
                                </div>}>
                                <rect {...props} />
                            </Tooltip>
                        );
                    }}
                    panelColors={this.handlePanelColors()}
                />
            </div >
        );
    }
}

HeatMapComponent.propTypes = {
    data: PropTypes.array,
    range: PropTypes.number,
    width: PropTypes.number,
    startDate: PropTypes.instanceOf(Date),
    panelColors: PropTypes.array
};

HeatMapComponent.defaultProps = {
    data: [],
    range: 0,
    startDate: new Date(),
};

export default HeatMapComponent;