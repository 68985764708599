import { connect } from 'react-redux';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { TestRunsFilter } from 'components';
import {
  getTestRunsList,
  getTestRunsListByFilters,
  setSelectedHideFork,
  setSelectedTestRunsBranches,
  setSelectedTestRunsStatuses,
  setSelectedTestRunsTags,
} from 'store/actions/test-runs';
import { withRouter } from 'react-router';

function mapStateToProps(store) {
  return {
    testRuns: store.testWRuns,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTestRunsList(
      ciProvider,
      repoOwner,
      repoName,
      afterKey,
      offset,
      filter,
      isFirstPage = false,
      hideForkBranch,
      onGetTestRunsListSuccess,
      onGetTestRunsListFail,
    ) {
      dispatchWithExceptionHandler(
        getTestRunsList(ciProvider, repoOwner, repoName, afterKey, offset, filter, isFirstPage, hideForkBranch),
        false,
        onGetTestRunsListSuccess,
        onGetTestRunsListFail,
      );
    },
    getTestRunsListByFilters(ciProvider, repoOwner, repoName, isFirstPage, hideFork, onSuccess, onError) {
      dispatchWithExceptionHandler(
        getTestRunsListByFilters(ciProvider, repoOwner, repoName, isFirstPage, hideFork),
        false, //  Organization Switch caused Error
        onSuccess,
        onError,
      );
    },

    setSelectedHideFork(hideFork) {
      dispatch(setSelectedHideFork(hideFork));
    },
    setSelectedTestRunsBranches(branches) {
      dispatch(setSelectedTestRunsBranches(branches));
    },
    setSelectedTestRunsStatuses(events) {
      dispatch(setSelectedTestRunsStatuses(events));
    },
    setSelectedTestRunsTags(tags) {
      dispatch(setSelectedTestRunsTags(tags));
    },
  };
}

const TestRunsFilterContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(TestRunsFilter));
export default TestRunsFilterContainer;
