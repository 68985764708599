import React from 'react';
import { FilterLabel } from 'custom-components/FilterLabel/FilterLabel';
import { FilterLabelGroup } from 'custom-components/FilterLabelGroup/FilterLabelGroup';
import { GrayBorderButton } from 'custom-components/Button/Button';
import './TestViewAdvanced.scss';
import { hasArrayElement } from 'utils/array-util';

export function TestViewAdvancedFilterButton(props) {
  const { isFilterOpen } = props;
  return (
    <GrayBorderButton onClick={() => props.onClick({ isFilterOpen: !isFilterOpen, isClearFilterClicked: false })}>
      <i className="icon-filter-icon" />
      Advanced Filter
    </GrayBorderButton>
  );
}

export function TestViewAdvancedFilterLabels(props) {
  const { isFilterOpen } = props;
  if (hasArrayElement(props.branches) || hasArrayElement(props.statuses)) {
    return (
      <FilterLabelGroup clearFilterHandle={() => props.handleClearFilter()}>
        {hasArrayElement(props.branches) && (
          <FilterLabel
            filterArray={props?.branches || ['All']}
            labelTitle="Branches"
            filterLabelClick={props.filterLabelClick}
          />
        )}
        {hasArrayElement(props.statuses) && (
          <FilterLabel
            filterArray={props?.statuses || ['All']}
            labelTitle="Status"
            filterLabelClick={() => props.onClick({ isFilterOpen: !isFilterOpen, isClearFilterClicked: false })}
          />
        )}
      </FilterLabelGroup>
    );
  }
  return '';
}
