import moment from 'moment';

export const formatTime = timeAsMs => {
  const d = new Date(timeAsMs);
  const date = d.toISOString().split('T')[0];
  const time = d.toTimeString().split(' ')[0];
  return `${date} ${time}`;
};

export const formatDate = value => {
  let date = new Date(value);
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return day + ' ' + month + ', ' + year;
};

export const formatDateWithTime = timeAsMs => {
  const d = new Date(timeAsMs);
  const month = d.toLocaleString('default', { month: 'long' });
  const day = d.getDate();
  const year = d.getFullYear();
  let hours = d.getHours();
  let minutes = d.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${month} ${day},${year} ${hours}:${minutes} ${ampm}`;
};

export const formatDateFromNow = timeAsMs => {
  return moment(timeAsMs).fromNow();
};

export const millisToMinutesAndSeconds = millis => {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
};

export const millisToMAndS = millis => {
  if (millis <= 0) {
    return '<1s';
  }
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);

  if (minutes > 0 && seconds > 0) {
    return minutes + 'm ' + seconds + 's';
  }

  if (minutes > 0 && seconds === 0) {
    return minutes + 'm';
  }

  if (minutes === 0 && seconds > 0) {
    return seconds + 's';
  }

  return '0s';
};

export const millisToMAndSNotFixed = millis => {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(2);

  if (minutes > 0 && seconds > 0) {
    return minutes + 'm ' + seconds + 's';
  }

  if (minutes > 0 && seconds === 0) {
    return minutes + 'm';
  }

  if (minutes === 0 && seconds > 0) {
    return seconds + 's';
  }

  return '0.00s';
};

export function timeDiffAsMillis(sEndTime, sStartTime) {
  try {
    const diffMs = new Date(sEndTime).getTime() - new Date(sStartTime).getTime();
    if (diffMs <= 0) return 0;
    return diffMs;
  } catch (err) {
    return 0;
  }
}

//More than 1 seconds → mm:ss (12:51)
//Less than 1 second → 0.XXX s (0.006s) // TODO: when less than 1 sec is it 's' or 'ms'?
export const calcDisplayedTime = execTimeMillis => {
  if (execTimeMillis <= 0) {
    return '<1s';
  }

  const displayTime =
    execTimeMillis < 1000 ? (execTimeMillis / 1000).toFixed(3) + 's' : millisToMinutesAndSeconds(execTimeMillis);
  return displayTime;
};

//More than 1 seconds → mm:ss (12:51)
//Less than 1 second → 0.XXX s (6ms) //
export const calcDisplayedTimeInMs = execTimeMillis => {
  const displayTime = execTimeMillis < 1000 ? execTimeMillis + 'ms' : millisToMinutesAndSeconds(execTimeMillis);
  return displayTime;
};

export const formatDateTime = date => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutes}:${seconds} ${ampm}`;
};

export const ENUM_FORMAT_DATE = {
  allTime: 'YYYY-MM-DD HH:mm:ss.sss Z',
  hours: 'HH:mm',
  hoursWithSeconds: 'HH:mm:ss',
};

export const millisToMinutes = execTimeMillis => {
  let minutes = Math.floor(execTimeMillis / 60000);
  let seconds = ((execTimeMillis % 60000) / 1000).toFixed(0);

  if (seconds === 60) {
    minutes += 1;
  }

  return minutes;
};

export const millisToSeconds = execTimeMillis => {
  return ((execTimeMillis % 60000) / 1000).toFixed(0);
};

export const formatDateShortMonthNoYearWithTime = timeAsMs => {
  const d = new Date(timeAsMs);
  const month = d.toLocaleString('default', { month: 'short' });
  const day = d.getDate();
  let hours = d.getHours();
  let minutes = d.getMinutes();
  let seconds = d.getSeconds();
  let miliseconds = d.getMilliseconds();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${month} ${day}, ${hours}:${minutes}:${seconds}.${miliseconds} ${ampm}`;
};

export const formatDateShortMonthDayWithTimeNoMili = timeAsMs => {
  const d = new Date(timeAsMs);
  const month = d.toLocaleString('default', { month: 'short' });
  const day = d.getDate();
  let hours = d.getHours();
  let minutes = d.getMinutes();
  let seconds = d.getSeconds();
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  return `${day} ${month}, ${hours}:${minutes}:${seconds}`;
};

export const formatDateWithoutMili = timeAsMs => {
  const d = new Date(timeAsMs);
  const month = d.toLocaleString('default', { month: 'short' });
  const day = d.getDate();
  let hours = d.getHours();
  let minutes = d.getMinutes();
  let seconds = d.getSeconds();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${month} ${day}, ${hours}:${minutes}:${seconds} ${ampm}`;
};
