import { connect } from 'react-redux';
import { RepositoryWorkflowRunsFilter } from 'components';
import { withRouter } from 'react-router';
import {
  getWorkflowRunsMeta,
  setSelectedWorkflowIds,
  setSelectedConclusions,
  setSelectedBranches,
  setSelectedEvents,
  setSelectedHideFork,
  getWorkflowRunFilters,
  setSelectedLabels,
} from 'store/actions/workflow-runs-meta';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    workflowRunsMeta: store.workflowRunsMeta,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getWorkflowRunsMeta(ciProvider, repoOwner, repoName) {
      dispatchWithExceptionHandler(getWorkflowRunsMeta(ciProvider, repoOwner, repoName));
    },

    setSelectedWorkflowIds(ids) {
      dispatch(setSelectedWorkflowIds(ids));
    },
    setSelectedConclusions(conclusions) {
      dispatch(setSelectedConclusions(conclusions));
    },
    setSelectedBranches(branches) {
      dispatch(setSelectedBranches(branches));
    },
    setSelectedEvents(events) {
      dispatch(setSelectedEvents(events));
    },
    setSelectedLabels(labels) {
      dispatch(setSelectedLabels(labels));
    },
    setSelectedHideFork(hideFork) {
      dispatch(setSelectedHideFork(hideFork));
    },
    getWorkflowRunFilters(ciProvider, repoOwner, repoName, hideFork, onSuccess, onError) {
      dispatchWithExceptionHandler(
        getWorkflowRunFilters(ciProvider, repoOwner, repoName, hideFork),
        false, //  Organization Switch caused Error
        onSuccess,
        onError,
      );
    },
  };
}

const RepositoryWorkflowRunsFilterContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepositoryWorkflowRunsFilter),
);
export default RepositoryWorkflowRunsFilterContainer;
