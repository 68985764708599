import React, { Component } from 'react';
import { Image, Button } from 'semantic-ui-react';
import './IntegrationInstruction.scss';

export class IntegrationInstruction extends Component {
  render() {
    const { title, content, onButtonClick, imageSrc, contentURL, troubleshootUrl } = this.props;

    return (
      <div className="integration-instruction-container">
        <div className="text-area">
          <Image className="image-icon" src={imageSrc} fluid />
          <div className="header">{title}</div>
          <div className="content">
            {content}
            <a rel="noreferrer" target="_blank" href={contentURL} className="content-link">
              Click here
            </a>{' '}
            to see it in action
          </div>
          <Button onClick={onButtonClick} primary>
            Integrate now
          </Button>
          <div className="troubleshoot-content">
            Already integrated?{' '}
            <a rel="noreferrer" target="_blank" href={troubleshootUrl} className="content-link">
              Click here to troubleshoot
            </a>
          </div>
        </div>
      </div>
    );
  }
}
