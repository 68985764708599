export const calculatePercentage = (avg, total) => {
  if (typeof avg === 'number') {
    return ((avg / total) * 100).toFixed(2);
  } else {
    return 'No Data';
  }
};

export const getSelectedJob = (matchParams, wfTimeLineChart) => {
  const { jobId } = matchParams;
  const jobs = wfTimeLineChart.jobs || [];
  const job = jobs.find(job => job.id === jobId);
  return job;
};

export const getSelectedStep = (matchParams, wfTimeLineChart) => {
  const { jobId, stepId } = matchParams;
  const { jobs } = wfTimeLineChart;
  const job = jobs.find(job => job.id === jobId);
  const steps = job?.steps ? job?.steps : [];
  const stepIndex = parseInt(stepId);
  if (steps.length > stepIndex) {
    return steps[stepIndex];
  }
  return null;
};
