import { connect } from 'react-redux';
import { RepositoryPullRequestsFilter } from 'components';
import { withRouter } from 'react-router';
import {
  getPullRequests_Filters,
  setSelectedPullRequests_Authors,
  setSelectedPullRequests_Branches,
  setSelectedPullRequests_HideFork,
  setSelectedPullRequests_Labels,
  setSelectedPullRequests_States,
} from 'store/actions/pull-requests-filter';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';

function mapStateToProps(store) {
  return {
    pullRequestsMeta: store.pullRequestsMeta,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedPullRequests_Authors(authors) {
      dispatch(setSelectedPullRequests_Authors(authors));
    },
    setSelectedPullRequests_Labels(labels) {
      dispatch(setSelectedPullRequests_Labels(labels));
    },
    setSelectedPullRequests_States(states) {
      dispatch(setSelectedPullRequests_States(states));
    },
    setSelectedPullRequests_Branches(branches) {
      dispatch(setSelectedPullRequests_Branches(branches));
    },
    setSelectedPullRequests_HideFork(hideFork) {
      dispatch(setSelectedPullRequests_HideFork(hideFork));
    },
    getPullRequests_Filters(ciProvider, repoOwner, repoName, hideFork, onSuccess, onError) {
      dispatchWithExceptionHandler(
        getPullRequests_Filters(ciProvider, repoOwner, repoName, hideFork),
        true,
        onSuccess,
        onError,
      );
    },
  };
}

const RepositoryPullRequestsFilterContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepositoryPullRequestsFilter),
);
export default RepositoryPullRequestsFilterContainer;
