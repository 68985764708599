import React, { Component } from 'react';
import { MainLayoutContainer } from 'containers';
import routeList from 'routes';
import { TestRunDetailHeaderContainer, TestRunTestTabsContainer, TestRunSuiteCardListContainer } from 'containers';
import './TestRunTestSnapshotsPage.scss';

export class TestRunTestSnapshotsPage extends Component {
	render() {
		return (
			<MainLayoutContainer title={routeList.testRunTestSnapshots.title} pageHeader="TestRun Test Snapshots">
				<div className="test-run-testcase-snapshots-container">
					<div className="header">
						<TestRunDetailHeaderContainer />
					</div>
					<div className="content">
						<div className="content-left">
							<TestRunSuiteCardListContainer />
						</div>
						<div className="content-right">
							<TestRunTestTabsContainer selectedTab="snapshots" />
						</div>
					</div>
				</div>
			</MainLayoutContainer>
		);
	}
}

export default TestRunTestSnapshotsPage;
