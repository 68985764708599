import { connect } from 'react-redux';
import { RepositorySelectableList } from 'components';
import { withRouter } from 'react-router';

function mapStateToProps(store, props) {
  return {
    ciRepos: store.ciRepos,
    projects: store.projects,
    ciConnect: store.ciConnect,
    ciRepoList: props.ciRepoList,
    repoList: props.repoList,
    handleCheckboxClick: props.handleCheckboxClick,
    handleSelectAllReposToggle: props.handleSelectAllReposToggle,
    ciProviderList: props.ciProviderList,
  };
}

function mapDispatchToProps() {
  return {};
}

const RepositorySelectableListContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepositorySelectableList),
);
export default RepositorySelectableListContainer;
