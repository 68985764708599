import React, { Component } from 'react';
import './SelectedCiProviderBox.scss';
import { renderLogo } from 'utils/git-provider-logos';

class SelectedCiProviderBox extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { title, ciProvider, darkMode } = this.props;
		return (
			<div className={`provider-box-container ${darkMode ? 'dark-box' : 'ligth-box'}`}>
				<div className={`icon-logo-container ${darkMode ? 'dark-box' : ''}`}>
					<div className="icon-logo">{renderLogo(ciProvider)}</div>
				</div>
				<div className="provider-title-container">
					<div className="provider-title">{title}</div>
				</div>
			</div>
		);
	}
}

export default SelectedCiProviderBox;
