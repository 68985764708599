import { UI_ICON } from 'assets/font-icons/IconManager';
import React from 'react';
import './Accordion.scss';

export class ForesightAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: props.isOpened,
    };
  }

  render() {
    const { isOpened } = this.state;
    const { header, content } = this.props;
    const className = isOpened ? 'foresight-accordion-container opened' : 'foresight-accordion-container';

    return (
      <div className={className}>
        <div
          className="foresight-accordion-header-container"
          onClick={() => {
            this.setState({ isOpened: !isOpened });
          }}
        >
          <div className="foresight-accordion-header-left-container">{header}</div>
          <div className="foresight-accordion-header-right-container">
            <i className={`${isOpened ? UI_ICON.CHEVRON.UP : UI_ICON.CHEVRON.DOWN}`} />
          </div>
        </div>
        {isOpened && <div className="foresight-accordion-content-container">{content}</div>}
      </div>
    );
  }
}

export class ForesightAccordionWithAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: props.isOpened,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpened !== this.props.isOpened) {
      this.setState({ isOpened: this.props.isOpened });
    }
  }

  render() {
    const { isOpened } = this.state;
    const { header, content, contentScroll = true } = this.props;
    const className = isOpened
      ? 'foresight-accordion-container-with-animation opened'
      : 'foresight-accordion-container-with-animation ';

    return (
      <div className={className}>
        <div
          className="foresight-accordion-header-container-with-animation "
          onClick={() => {
            this.setState({ isOpened: !isOpened });
          }}
        >
          <div className="foresight-accordion-header-left-container">{header}</div>
          <div className="foresight-accordion-header-right-container">
            <i className={`${isOpened ? UI_ICON.CHEVRON.DOWN + ' translate' : UI_ICON.CHEVRON.DOWN}`} />
          </div>
        </div>
        <div
          className={`${
            isOpened
              ? `foresight-accordion-content-container-with-animation  ${
                  contentScroll ? 'closed-content' : 'closed-content-not-scroll'
                }`
              : 'foresight-accordion-content-container-with-animation '
          }`}
        >
          {content}
        </div>
      </div>
    );
  }
}
