import {
	CREATE_CI_PROVIDER_INTEGRATION_PENDING,
	CREATE_CI_PROVIDER_INTEGRATION_FULFILLED,
	CREATE_CI_PROVIDER_INTEGRATION_REJECTED,

	GET_CI_PROVIDER_INTEGRATIONS_PENDING,
	GET_CI_PROVIDER_INTEGRATIONS_FULFILLED,
	GET_CI_PROVIDER_INTEGRATIONS_REJECTED,

	DELETE_CI_PROVIDER_INTEGRATION_PENDING,
	DELETE_CI_PROVIDER_INTEGRATION_FULFILLED,
	DELETE_CI_PROVIDER_INTEGRATION_REJECTED
} from 'store/constants/ci-provider-connect';

const initialState = {
	createCIProvider: {},
	isCreateCIProviderFetching: false,
	isCreateCIProviderFetched: false,
	isCreateCIProviderError: null,

	ciProviderList: [],
	isCIProviderListFetching: false,
	isCIProviderListFetched: false,
	isCIProviderListError: null,

	isDeleteCIProviderFetching: false,
	isDeleteCIProviderFetched: false,
	isDeleteCIProviderError: false,
};

export default function ciConnect(state = initialState, action) {
	switch (action.type) {
	case CREATE_CI_PROVIDER_INTEGRATION_PENDING:
		return {
			...state,
			isCreateCIProviderFetching: true,
			isCreateCIProviderFetched: false,
			isCreateCIProviderError: null,
		};
	case CREATE_CI_PROVIDER_INTEGRATION_FULFILLED:
		console.log('CREATE_CI_PROVIDER_INTEGRATION_FULFILLED, reducer; action: ', action);
		return {
			...state,
			createCIProvider: action.payload.data,
			isCreateCIProviderFetching: false,
			isCreateCIProviderFetched: true,
			isCreateCIProviderError: null,
		};
	case CREATE_CI_PROVIDER_INTEGRATION_REJECTED:
		return {
			...state,
			isCreateCIProviderFetching: false,
			isCreateCIProviderFetched: false,
			isCreateCIProviderError: action.payload,
		};

	case GET_CI_PROVIDER_INTEGRATIONS_PENDING:
		return {
			...state,
			isCIProviderListFetching: true,
			isCIProviderListFetched: false,
			isCIProviderListError: null,
		};
	case GET_CI_PROVIDER_INTEGRATIONS_FULFILLED:
		console.log('GET_CI_PROVIDER_INTEGRATIONS_FULFILLED, reducer; action: ', action);
		return {
			...state,
			ciProviderList: action.payload.data,
			isCIProviderListFetching: false,
			isCIProviderListFetched: true,
			isCIProviderListError: null,
		};
	case GET_CI_PROVIDER_INTEGRATIONS_REJECTED:
		return {
			...state,
			isCIProviderListFetching: false,
			isCIProviderListFetched: false,
			isCIProviderListError: action.payload,
		};
	case DELETE_CI_PROVIDER_INTEGRATION_PENDING:
		return {
			...state,
			isDeleteCIProviderFetching: true,
			isDeleteCIProviderFetched: false,
			isDeleteCIProviderError: null,
		};
	case DELETE_CI_PROVIDER_INTEGRATION_FULFILLED:
		return {
			...state,
			isDeleteCIProviderFetching: false,
			isDeleteCIProviderFetched: true,
			isDeleteCIProviderError: null,
		};
	case DELETE_CI_PROVIDER_INTEGRATION_REJECTED:
		return {
			...state,
			isDeleteCIProviderFetching: false,
			isDeleteCIProviderFetched: false,
			isDeleteCIProviderError: null,
		};
	default:
		return state;
	}
}
