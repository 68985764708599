export const CREATE_CI_PROVIDER_INTEGRATION = 'CREATE_CI_PROVIDER_INTEGRATION';
export const CREATE_CI_PROVIDER_INTEGRATION_PENDING = 'CREATE_CI_PROVIDER_INTEGRATION_PENDING';
export const CREATE_CI_PROVIDER_INTEGRATION_FULFILLED = 'CREATE_CI_PROVIDER_INTEGRATION_FULFILLED';
export const CREATE_CI_PROVIDER_INTEGRATION_REJECTED = 'CREATE_CI_PROVIDER_INTEGRATION_REJECTED';

export const GET_CI_PROVIDER_INTEGRATIONS = 'GET_CI_PROVIDER_INTEGRATIONS';
export const GET_CI_PROVIDER_INTEGRATIONS_PENDING = 'GET_CI_PROVIDER_INTEGRATIONS_PENDING';
export const GET_CI_PROVIDER_INTEGRATIONS_FULFILLED = 'GET_CI_PROVIDER_INTEGRATIONS_FULFILLED';
export const GET_CI_PROVIDER_INTEGRATIONS_REJECTED = 'GET_CI_PROVIDER_INTEGRATIONS_REJECTED';

export const DELETE_CI_PROVIDER_INTEGRATION = 'DELETE_CI_PROVIDER_INTEGRATION';
export const DELETE_CI_PROVIDER_INTEGRATION_PENDING = 'DELETE_CI_PROVIDER_INTEGRATION_PENDING';
export const DELETE_CI_PROVIDER_INTEGRATION_FULFILLED = 'DELETE_CI_PROVIDER_INTEGRATION_FULFILLED';
export const DELETE_CI_PROVIDER_INTEGRATION_REJECTED = 'DELETE_CI_PROVIDER_INTEGRATION_REJECTED';
