import { CustomModal } from 'components';
import React, { Component } from 'react';
import { injectStripe } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { Message } from 'semantic-ui-react';
import { ToastConfig } from 'utils/toast-util';
import { CreditCardForm } from '../CreditCardForm';

import { BlueButton, TextButton } from 'custom-components/Button/Button';
import './CardModal.scss';

class CardModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tokenError: null,
      cardNumberError: null,
      cardExpiryError: null,
      cardCVCError: null,
      postalCodeError: null,
      name: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zip: '',
      isButtonsDisabled: false,
    };
  }

  handleInputChange = e => {
    const fieldName = e.target.name;
    const value = e.target.value;
    // console.log("CardModal, handleInputChange; fieldName, value: ", fieldName, value);
    this.setState({
      [fieldName]: value,
    });
  };

  handleCardNumberChange = change => {
    // console.log("CardModal, handleCardNumberChange; change: ", change);
    this.setState({
      cardNumberError: change.error ? change.error.message : null,
    });
  };

  handleCardExpiryChange = change => {
    // console.log("CardModal, handleCardExpiryChange; change: ", change);
    this.setState({
      cardExpiryError: change.error ? change.error.message : null,
    });
  };

  handleCardCVCChange = change => {
    // console.log("CardModal, handleCardCVCChange; change: ", change);
    this.setState({
      cardCVCError: change.error ? change.error.message : null,
    });
  };

  handlePostalCodeChange = change => {
    // console.log("CardModal, handlePostalCodeChange; change: ", change);
    this.setState({
      postalCodeError: change.error ? change.error.message : null,
      zip: change.value ? change.value : '',
    });
  };

  handleSaveCard = () => {
    console.log('handleSaveCard; props: ', this.props);
    this.setState({
      isButtonsDisabled: true,
    });

    if (this.props.stripe) {
      this.props.stripe
        .createToken({
          name: this.state.name,
          address_line1: this.state.address,
          address_city: this.state.city,
          address_state: this.state.state,
          address_country: this.state.country,
          address_zip: this.state.zip,
        })
        .then(result => {
          if (result.error) {
            console.log('ERROR-createToken; result: ', result);
            this.setState({
              tokenError: result.error.message,
              isButtonsDisabled: false,
            });
          } else {
            console.log('WIN-createToken; result: ', result);
            this.props.onUpsertCardSource(result.token.id, this.handleSaveCardSuccess, this.handleSaveCardError);
          }
        });
    }
  };

  handleSaveCardSuccess = () => {
    // console.log("handleSaveCardSuccess, WIN-upsert; props: ", this.props);
    this.setState({
      isButtonsDisabled: false,
    });
    this.props.onClose();

    toast.success('You successfully update your card information.', ToastConfig);
  };

  handleSaveCardError = () => {
    // console.log("handleSaveCardError; props: ", this.props);

    this.setState({
      isButtonsDisabled: false,
    });
    this.props.onClose();

    const { error } = this.props.upsertCardResult;

    toast.error(`Your card information update failed; ${error.response.data}`, ToastConfig);
  };

  handleCardModalClose = () => {
    // Do not close while stripe creates token
    if (this.props.upsertCardResult === undefined || this.props.upsertCardResult?.fetching === false) {
      this.props.onClose();

      // Clear any errors upon close
      this.setState({
        tokenError: null,
        cardNumberError: null,
        cardExpiryError: null,
        cardCVCError: null,
        postalCodeError: null,
      });
    }
  };

  render() {
    // console.log("CardModal, render; props, state: ", this.props, this.state);

    const blueButtonInProgress = this.state.isButtonsDisabled || this.props.upsertCardResult?.fetching;

    return (
      <CustomModal
        className="billing-method-container"
        isOpen={this.props.open}
        onModalClose={this.handleCardModalClose}
      >
        <div className="billing-method-title">Change Payment Method</div>

        <div>
          {!!this.state.tokenError && (
            <Message negative>
              <p>{this.state.tokenError}</p>
            </Message>
          )}
          <CreditCardForm
            onInputChange={this.handleInputChange}
            onCardNumberChange={this.handleCardNumberChange}
            onCardExpiryChange={this.handleCardExpiryChange}
            onCardCVCChange={this.handleCardCVCChange}
            onPostalCodeChange={this.handlePostalCodeChange}
            cardNumberError={this.state.cardNumberError}
            cardExpiryError={this.state.cardExpiryError}
            cardCVCError={this.state.cardCVCError}
            postalCodeError={this.state.postalCodeError}
          />
        </div>

        <div className="actions-container">
          <TextButton onClick={this.handleCardModalClose} content="Cancel" />

          <BlueButton onClick={this.handleSaveCard} content="Save" loading={blueButtonInProgress} />
        </div>
      </CustomModal>
    );
  }
}

export default injectStripe(CardModal);
