import { connect } from 'react-redux';
import { TestRunOverviewSortingCard } from 'components';
import { withRouter } from 'react-router';

function mapStateToProps(store) {
	return {
		testRunDetails: store.testRunDetails,
	};
}

function mapDispatchToProps() {
	return {};
}

const TestRunOverviewSortingCardContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TestRunOverviewSortingCard),
);
export default TestRunOverviewSortingCardContainer;
