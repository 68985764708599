import {
	GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS_FULFILLED,
	GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS_PENDING,
	GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS_REJECTED,
	GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_FULFILLED,
	GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_PENDING,
	GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_REJECTED,
	GET_COST_OF_MOST_COSTLY_10_WORKFLOWS_FULFILLED,
	GET_COST_OF_MOST_COSTLY_10_WORKFLOWS_PENDING,
	GET_COST_OF_MOST_COSTLY_10_WORKFLOWS_REJECTED,
	GET_COUNT_OF_30_DAYS_WORKFLOWS_FULFILLED,
	GET_COUNT_OF_30_DAYS_WORKFLOWS_PENDING,
	GET_COUNT_OF_30_DAYS_WORKFLOWS_REJECTED,
	GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS_FULFILLED,
	GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS_PENDING,
	GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS_REJECTED,
	GET_OVERALL_COUNT_STATS_OF_WORKFLOWS_FULFILLED,
	GET_OVERALL_COUNT_STATS_OF_WORKFLOWS_PENDING,
	GET_OVERALL_COUNT_STATS_OF_WORKFLOWS_REJECTED,
	GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS_FULFILLED,
	GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS_PENDING,
	GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS_REJECTED,
	GET_SUMMARY_OF_LATEST_10_WORKFLOWS_FULFILLED,
	GET_SUMMARY_OF_LATEST_10_WORKFLOWS_PENDING,
	GET_SUMMARY_OF_LATEST_10_WORKFLOWS_REJECTED,
	GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_FULFILLED,
	GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_PENDING,
	GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_REJECTED,
	GET_TOTAL_COST_SUMMARY_FULFILLED,
	GET_TOTAL_COST_SUMMARY_PENDING,
	GET_TOTAL_COST_SUMMARY_REJECTED
} from 'store/constants/highlights';

const initialServerState = {
	data: {},
	fetching: false,
	fetched: false,
	error: null,
};

function pendingServerState() {
	return {
		fetching: true,
		fetched: false,
		error: null,
	};
}

function fulfilledServerState(action) {
	return {
		data: action.payload,
		fetching: false,
		fetched: true,
		error: null,
	};
}

function rejectedServerState(action) {
	return {
		fetching: false,
		fetched: false,
		error: action.payload,
	};
}

const initialState = {
	summaryOfLatest10Workflows: { ...initialServerState },
	countOf30DaysWorkflows: { ...initialServerState },
	overallCountStatsOfWorkflows: { ...initialServerState },
	countOfMostErroneous10Workflows: { ...initialServerState },
	successFailCountOfMostActive10Workflows: { ...initialServerState },
	costOfMostCostly10Workflows: { ...initialServerState },
	avgDurationOfLongest10Workflows: { ...initialServerState },
	averageAndTotalDurationSummaryWorkflows: { ...initialServerState },
	sumDurationOfLongest10Workflows: { ...initialServerState },
	totalCostSummary: { ...initialServerState }
};

export default function highlights(state = initialState, action) {
	switch (action.type) {
		//New

		case GET_SUMMARY_OF_LATEST_10_WORKFLOWS_PENDING:
			return { ...state, summaryOfLatest10Workflows: pendingServerState() };
		case GET_SUMMARY_OF_LATEST_10_WORKFLOWS_FULFILLED:
			return { ...state, summaryOfLatest10Workflows: fulfilledServerState(action) };
		case GET_SUMMARY_OF_LATEST_10_WORKFLOWS_REJECTED:
			return { ...state, summaryOfLatest10Workflows: rejectedServerState(action) };

		case GET_COUNT_OF_30_DAYS_WORKFLOWS_PENDING:
			return { ...state, countOf30DaysWorkflows: pendingServerState() };
		case GET_COUNT_OF_30_DAYS_WORKFLOWS_FULFILLED:
			return { ...state, countOf30DaysWorkflows: fulfilledServerState(action) };
		case GET_COUNT_OF_30_DAYS_WORKFLOWS_REJECTED:
			return { ...state, countOf30DaysWorkflows: rejectedServerState(action) };

		case GET_OVERALL_COUNT_STATS_OF_WORKFLOWS_PENDING:
			return { ...state, overallCountStatsOfWorkflows: pendingServerState() };
		case GET_OVERALL_COUNT_STATS_OF_WORKFLOWS_FULFILLED:
			return { ...state, overallCountStatsOfWorkflows: fulfilledServerState(action) };
		case GET_OVERALL_COUNT_STATS_OF_WORKFLOWS_REJECTED:
			return { ...state, overallCountStatsOfWorkflows: rejectedServerState(action) };

		case GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS_PENDING:
			return { ...state, countOfMostErroneous10Workflows: pendingServerState() };
		case GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS_FULFILLED:
			return { ...state, countOfMostErroneous10Workflows: fulfilledServerState(action) };
		case GET_COUNT_OF_MOST_ERRONEOUS_10_WORKFLOWS_REJECTED:
			return { ...state, countOfMostErroneous10Workflows: rejectedServerState(action) };

		case GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS_PENDING:
			return { ...state, successFailCountOfMostActive10Workflows: pendingServerState() };
		case GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS_FULFILLED:
			return { ...state, successFailCountOfMostActive10Workflows: fulfilledServerState(action) };
		case GET_SUCCESS_FAIL_COUNT_OF_MOST_ACTIVE_10_WORKFLOWS_REJECTED:
			return { ...state, successFailCountOfMostActive10Workflows: rejectedServerState(action) };

		case GET_COST_OF_MOST_COSTLY_10_WORKFLOWS_PENDING:
			return { ...state, costOfMostCostly10Workflows: pendingServerState() };
		case GET_COST_OF_MOST_COSTLY_10_WORKFLOWS_FULFILLED:
			return { ...state, costOfMostCostly10Workflows: fulfilledServerState(action) };
		case GET_COST_OF_MOST_COSTLY_10_WORKFLOWS_REJECTED:
			return { ...state, costOfMostCostly10Workflows: rejectedServerState(action) };

		case GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_PENDING:
			return { ...state, avgDurationOfLongest10Workflows: pendingServerState() };
		case GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_FULFILLED:
			return { ...state, avgDurationOfLongest10Workflows: fulfilledServerState(action) };
		case GET_AVG_DURATION_OF_LONGEST_10_WORKFLOWS_REJECTED:
			return { ...state, avgDurationOfLongest10Workflows: rejectedServerState(action) };

		case GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS_PENDING:
			return { ...state, averageAndTotalDurationSummaryWorkflows: pendingServerState() };
		case GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS_FULFILLED:
			return { ...state, averageAndTotalDurationSummaryWorkflows: fulfilledServerState(action) };
		case GET_AVERAGE_AND_TOTAL_DURATION_SUMMARY_WORKFLOWS_REJECTED:
			return { ...state, averageAndTotalDurationSummaryWorkflows: rejectedServerState(action) };

		case GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_PENDING:
			return { ...state, sumDurationOfLongest10Workflows: pendingServerState() };
		case GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_FULFILLED:
			return { ...state, sumDurationOfLongest10Workflows: fulfilledServerState(action) };
		case GET_SUM_DURATION_OF_LONGEST_10_WORKFLOWS_REJECTED:
			return { ...state, sumDurationOfLongest10Workflows: rejectedServerState(action) };

		case GET_TOTAL_COST_SUMMARY_PENDING:
			return { ...state, totalCostSummary: pendingServerState() };
		case GET_TOTAL_COST_SUMMARY_FULFILLED:
			return { ...state, totalCostSummary: fulfilledServerState(action) };
		case GET_TOTAL_COST_SUMMARY_REJECTED:
			return { ...state, totalCostSummary: rejectedServerState(action) };

		default:
			return state;
	}
}
