import React from 'react';
import { beautifyJSONInStr } from 'utils/string-util';
import './LogViewer.scss';

export class LogViewer extends React.Component {
  // This is to color error line number differently.
  renderErrorContent = errLine => {
  	// Sample errors:
  	// at java.lang.Class.forName0(Native Method)
  	// at lambdainternal.LambdaRTEntry.main(LambdaRTEntry.java:119)
  	// at Connection.emit (events.js:198:13)
  	const splittedErr = errLine.split(':');
  	let lineNumber = splittedErr.pop();
  	lineNumber = lineNumber.substring(0, lineNumber.length - 1);
  	// console.log("renderErrorContent; splittedErr, lineNumber: ", splittedErr, lineNumber);

  	if (errLine.includes('at ') && splittedErr.length > 0) {
  		let err = '';
  		for (let e of splittedErr) {
  			err = err + ':' + e;
  		}
  		const modifiedErrLine = (
  			<>
  				{err.substring(1)}:<span className="code-line-number">{lineNumber}</span>)
  			</>
  		);

  		return <span>{modifiedErrLine}</span>;
  	}

  	return <span>{errLine}</span>;
  };

  render() {
  	// console.log("renderErrorRows; stack: ", stack);
  	const { text: stack } = this.props;
  	if (stack === '' || stack === undefined) {
  		return null;
  	}

  	//This Code Detect JSON in String and Format JSON.stringify(parsed, null, 4)
  	const modifiedStack = beautifyJSONInStr(stack);
  	const stackArr = modifiedStack.split(/(?:\r\n|\r|\n)/);
  	const stackList = stackArr.map((errLine, index) => {
  		if (errLine === '') {
  			return null;
  		}

  		console.log(`${index}=> ${errLine}`);
  		return (
  			<div className="error-stack-row" key={`${index}=>${errLine}`}>
  				<div className="line-number">
  					<span>{index + 1}</span>
  				</div>
  				<div className="error-content-line">{this.renderErrorContent(errLine)}</div>
  			</div>
  		);
  	});

  	return (
  		<div className="error-stack-flex-container">
  			<div className="error-stack-row-list">{stackList}</div>
  		</div>
  	);
  }
}
