import auth from 'auth/auth-factory';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CONFIG, isDemoEnv } from 'utils/config-util.js';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { isUserInInvitedUserRole } from 'utils/user-role-util';
import { identifyUsers } from './monitoringSetup';

import { getUserAccount } from 'store/actions/user-account';
import store from 'store/store';

import {
  AsyncCallbackPageContainer,
  AsyncChangePricingPlanPageContainer,
  AsyncCiProviderIntegrationsPageContainer,
  AsyncErrorPageContainer,
  AsyncGitHubAppExistionProjectRepoSelectionChangePageContainer,
  AsyncGitHubAppFirstProjectCreationAndRepoSelectionPageContainer,
  AsyncGitHubAppInstallmentTriggerPageContainer,
  AsyncGitHubTestIntegrationPageContainer,
  AsyncHighlightsPageContainer,
  AsyncLandingPageContainer,
  AsyncOrganizationListPageContainer,
  AsyncPlaygroundPageContainer,
  AsyncRepositoriesPageContainer,
  AsyncRepositoriesTestRunsPageContainer,
  AsyncRepositoryPRsPageContainer,
  AsyncRepositoryWorkflowPageContainer,
  AsyncRepositoryWorkflowRunOverviewPageContainer,
  AsyncRulesPageContainer,
  AsyncSettingsProfilePageContainer,
  AsyncSingleWorkflowHighlightsPageContainer,
  AsyncTeamOrganizationPageContainer,
  AsyncTestRunOverviewPageContainer,
  AsyncTestRunTestCodePageContainer,
  AsyncTestRunTestErrorsPageContainer,
  AsyncTestRunTestLogsPageContainer,
  AsyncTestRunTestPerformancePageContainer,
  AsyncTestRunTestScreenshotsPageContainer,
  AsyncTestRunTestSnapshotsPageContainer,
  AsyncTestRunTestSuitePageContainer,
  AsyncWorkflowsPageContainer,
  AsyncTestsSuitesPageContainer,
  AsyncTestsSuitesDetailPageContainer,
  AsyncTestViewPageContainer,
  AsyncRepositoryWorkflowRunTimelinePageContainer,
} from 'containers';
import { LoadingPage } from 'components';
import successCircle from 'assets/images/success-circle.png';
import { AsyncRepositoryPRPageContainer } from 'containers/RepositoryPRPageContainer';
import { toast } from 'react-toastify';
import { Image } from 'semantic-ui-react';
import { acceptUserInvitation } from 'store/actions/invitation';
import 'utils/axios-interceptors.js';
import routeList from './routes';
import { checkRedirectionPageNavigatedThenClear, setRedirectionForceURL } from './utils/redirection-force-util';

let isUserIdentified = false;
import { isInPricingWhiteList } from 'utils/common-util';
import { BILLING_SUBSCRIPTION_CAPABILITY } from 'utils/subscription-capability';

const getUserAccountWhenRouting = () => {
  dispatchWithExceptionHandler(
    getUserAccount(),
    true,
    user => {
      console.log('App, getUserAccount; user, store, currentUser: ', user, store.getState(), auth.getCurrentUser());

      if (!isUserIdentified) {
        identifyUsers(user.value.data); // TODO: this may change to userAccount!
        isUserIdentified = true;
        checkRedirectionPageNavigatedThenClear();
      }
    },
    error => {
      console.log('getUserAccount error => ', error);
      // Backend returns HTTP 401 in case of an expired token but there is still a possibility
      // to refresh token if session is still alive. For other errors, HTTP status code will be
      // different hence we should logout the user
      const HTTP_UNAUTHORIZED_STATUS = 401;
      if (error && error.response && error.response.status === HTTP_UNAUTHORIZED_STATUS) {
        auth.refreshTokenOrForceLogout(window.location.pathname);
      } else {
        auth.logout();
      }
    },
  );
};

const completeInvitationSuccess = () => {
  localStorage.removeItem('invitationId');
  toast.success(
    <div className="toast-image-wrapper">
      <Image src={successCircle} />
      Invitation has been completed successfully.
    </div>,
  );
};

const acceptInvitationWhenRouting = invitationId => {
  localStorage.removeItem('invitationId');
  dispatchWithExceptionHandler(
    acceptUserInvitation(invitationId),
    true,
    () => {
      console.log('App, accept invitation');
      completeInvitationSuccess();
      getUserAccountWhenRouting();
    },
    error => {
      getUserAccountWhenRouting();
      console.log('accept invitation error => ', error);
    },
  );
};

const UserRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        console.log('UserRoute; props: ', JSON.stringify(props));
        const { search } = props.location;
        const params = new URLSearchParams(search);
        let installationId = params.get('installation_id');
        if (!installationId) {
          installationId = params.get('github_installation_id');
        }

        console.log('App, UserRoute; installationId: ', installationId);
        if (search && installationId) {
          console.log('UserRoute; installationId: ', installationId);
          localStorage.setItem('github_installation_id', installationId);
        }

        if (auth.isAuthenticated()) {
          // console.log("after UserRoute isAuthenticated");
          //check is authenticated

          //Is Invited
          const invitationId = localStorage.getItem('invitationId');
          if (invitationId) {
            acceptInvitationWhenRouting(invitationId);
          } else {
            getUserAccountWhenRouting();
          }

          return React.createElement(component, props);
        }

        setRedirectionForceURL();
        return <Redirect to={routeList.callback.path} />;
      }}
    />
  );
};

class App extends Component {
  render() {
    const isDevEnv = CONFIG.DEV;

    const userRole = store.getState().userAccount.userAccount.role;
    return (
      <div className="App">
        <Switch>
          <Route exact path="/" component={AsyncCallbackPageContainer} />
          <Route path={routeList.callback.path} component={AsyncCallbackPageContainer} />
          <UserRoute exact path={routeList.testRunOverview.path} component={AsyncTestRunOverviewPageContainer} />
          <UserRoute exact path={routeList.testRunTestSuite.path} component={AsyncTestRunTestSuitePageContainer} />
          <UserRoute exact path={routeList.testViews.path} component={AsyncTestViewPageContainer} />
          <UserRoute
            exact
            path={routeList.testRunTestPerformance.path}
            component={AsyncTestRunTestPerformancePageContainer}
          />
          <UserRoute exact path={routeList.testRunTestLogs.path} component={AsyncTestRunTestLogsPageContainer} />
          <UserRoute
            exact
            path={routeList.testRunTestSnapshots.path}
            component={AsyncTestRunTestSnapshotsPageContainer}
          />
          <UserRoute exact path={routeList.testRunTestCode.path} component={AsyncTestRunTestCodePageContainer} />
          <UserRoute exact path={routeList.testRunTestErrors.path} component={AsyncTestRunTestErrorsPageContainer} />
          <UserRoute
            exact
            path={routeList.testRunTestScreenshots.path}
            component={AsyncTestRunTestScreenshotsPageContainer}
          />
          <UserRoute
            exact
            path={routeList.repositoryTestRuns.path}
            component={AsyncRepositoriesTestRunsPageContainer}
          />
          {!isUserInInvitedUserRole(userRole) && !isDemoEnv() && (
            <UserRoute
              exact
              path={routeList.startInstallGitHubAppTrigger.path}
              component={AsyncGitHubAppInstallmentTriggerPageContainer}
            />
          )}

          {!isUserInInvitedUserRole(userRole) && !isDemoEnv() && (
            <UserRoute
              exact
              path={routeList.startCreateProjectSelectGithubRepos.path}
              component={AsyncGitHubAppFirstProjectCreationAndRepoSelectionPageContainer}
            />
          )}

          <UserRoute
            exact
            path={routeList.startIntegrationGitHubTestRuns.path}
            component={AsyncGitHubTestIntegrationPageContainer}
          />
          {!isUserInInvitedUserRole(userRole) && !isDemoEnv() && (
            <UserRoute
              exact
              path={routeList.startGitHubExistedProjectRepoSelectionPage.path}
              component={AsyncGitHubAppExistionProjectRepoSelectionChangePageContainer}
            />
          )}
          <UserRoute exact path={routeList.workflows.path} component={AsyncWorkflowsPageContainer} />
          <UserRoute exact path={routeList.testSuites.path} component={AsyncTestsSuitesPageContainer} />
          <UserRoute exact path={routeList.testSuitesDetail.path} component={AsyncTestsSuitesDetailPageContainer} />
          <UserRoute exact path={routeList.highlights.path} component={AsyncHighlightsPageContainer} />
          <UserRoute
            exact
            path={routeList.singleWorkflowHighlights.path}
            component={AsyncSingleWorkflowHighlightsPageContainer}
          />
          {!isDemoEnv() && <UserRoute exact path={routeList.rules.path} component={AsyncRulesPageContainer} />}
          {!isDemoEnv() && (
            <UserRoute path={routeList.settingsProfile.path} component={AsyncSettingsProfilePageContainer} />
          )}
          <UserRoute exact path={routeList.repositories.path} component={AsyncRepositoriesPageContainer} />
          <UserRoute
            exact
            path={routeList.workflowRunOverview.path}
            component={AsyncRepositoryWorkflowRunOverviewPageContainer}
          />
          <UserRoute
            exact
            path={routeList.workflowRunOverviewWithJobId.path}
            component={AsyncRepositoryWorkflowRunOverviewPageContainer}
          />

          <UserRoute
            exact
            path={routeList.workflowRunTimeline.path}
            component={AsyncRepositoryWorkflowRunTimelinePageContainer}
          />

          <UserRoute
            exact
            path={routeList.workflowRunTimelineJob.path}
            component={AsyncRepositoryWorkflowRunTimelinePageContainer}
          />

          <UserRoute
            exact
            path={routeList.workflowRunTimelineStep.path}
            component={AsyncRepositoryWorkflowRunTimelinePageContainer}
          />

          <UserRoute
            exact
            path={routeList.repositoryWorkflowRuns.path}
            component={AsyncRepositoryWorkflowPageContainer}
          />

          <UserRoute exact path={routeList.repositoryPRs.path} component={AsyncRepositoryPRsPageContainer} />
          <UserRoute exact path={routeList.prOverview.path} component={AsyncRepositoryPRPageContainer} />
          <UserRoute exact path={routeList.prPage.path} component={AsyncRepositoryPRPageContainer} />

          {!isDemoEnv() && (
            <UserRoute exact path={routeList.integrations.path} component={AsyncCiProviderIntegrationsPageContainer} />
          )}
          {!isDemoEnv() && (
            <UserRoute exact path={routeList.organizationList.path} component={AsyncOrganizationListPageContainer} />
          )}
          {!isDemoEnv() && !isUserInInvitedUserRole(userRole) && (
            <UserRoute exact path={routeList.teamOrganization.path} component={AsyncTeamOrganizationPageContainer} />
          )}
          {!isDemoEnv() && !isUserInInvitedUserRole(userRole) && (
            <UserRoute
              exact
              path={routeList.teamOrganizationWithTab.path}
              component={AsyncTeamOrganizationPageContainer}
            />
          )}
          {BILLING_SUBSCRIPTION_CAPABILITY && !isDemoEnv() && !isUserInInvitedUserRole(userRole) && !isInPricingWhiteList() && (
            <UserRoute exact path={routeList.changePlan.path} component={AsyncChangePricingPlanPageContainer} />
          )}
          {/* Playground page should only be available for dev env. */}
          {isDevEnv && <UserRoute path={routeList.playground.path} component={AsyncPlaygroundPageContainer} />}
          {/* Other pages */}
          <Route exact path={routeList.landing.path} component={AsyncLandingPageContainer} />
          <Route exact path={routeList.loading.path} component={LoadingPage} />
          <Route exact path={routeList.errorWithCode.path} component={AsyncErrorPageContainer} />
          <Route exact path={routeList.error.path} component={AsyncErrorPageContainer} />
          <Route exact path={routeList.login.path} component={AsyncLandingPageContainer} />
          <Route exact path={routeList.signup.path} component={AsyncLandingPageContainer} />
          <Redirect from="*" to={routeList.callback.path} />
        </Switch>
      </div>
    );
  }
}

export default App;
