import React, { Component } from 'react';
import './ApiKeyAndProjectIdComponent.scss';
import { copyToClipBoard } from 'utils/common-util';
import { Input, Popup } from 'semantic-ui-react';
import { UI_ICON } from 'assets/font-icons/IconManager';

class ApiKeyAndProjectIdComponent extends Component {
  constructor(props) {
    super(props);
  }

  getProjectIdApiKey = (projectId, apiKey) => {
    return (
      <div className="thundra-keys-container">
        <div className="variable-form-label">FORESIGHT_APIKEY</div>
        <Popup
          trigger={
            <div onClick={() => copyToClipBoard(apiKey)}>
              <Input className="variable-form-input">
                <span>{apiKey}</span>
                <i className={UI_ICON.COPY_CODE} />
              </Input>
            </div>
          }
          content={'Copied!'}
          on="click"
          position="bottom center"
        />
        <div className="variable-form-label">FORESIGHT_PROJECT_ID</div>
        <Popup
          trigger={
            <div onClick={() => copyToClipBoard(projectId)}>
              <Input className="variable-form-input">
                <span>{projectId}</span>
                <i className={UI_ICON.COPY_CODE} />
              </Input>
            </div>
          }
          content={'Copied!'}
          on="click"
          position="bottom center"
        />
      </div>
    );
  };

  render() {
    return this.getProjectIdApiKey(this.props.projectId, this.props.apiKey);
  }
}

export default ApiKeyAndProjectIdComponent;
