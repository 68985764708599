import { Tooltip } from 'components/Tooltip';
import React, { Component } from 'react';
import { hasArrayElement } from 'utils/array-util';
import { calcDisplayedTime } from 'utils/date-util';
import { getWorkflowStatusIcon } from 'utils/workflow-status-icon';
import { getWorkflowStatusTooltipContent } from 'utils/workflowrun-conclusion-status-tooltips';
import './RepositoryWorkflowJobList.scss';

export class RepositoryWorkflowJobList extends Component {
  renderJobList = () => {
    const { workflowRunOverview } = this.props.workflowRuns;
    const jobList = workflowRunOverview.jobs;
    if (!hasArrayElement(jobList)) {
      return null;
    }

    const jobListDom = jobList.map((job, idx) => {
      const isSelected = idx === this.props.selectedJobIdx;
      let itemClass = 'job-item';
      if (isSelected) {
        itemClass = itemClass + ' selected';
      }
      return (
        <div className={itemClass} key={`${job.headSha}-${job.completedAt}`} onClick={() => this.props.selectJob(idx)}>
          <Tooltip content={getWorkflowStatusTooltipContent(job)}>{getWorkflowStatusIcon(job)}</Tooltip>
          <Tooltip content={job.name}>{<span className="job-name">{job.name}</span>}</Tooltip>
          <span className="job-duration">{calcDisplayedTime(job.duration)}</span>
        </div>
      );
    });

    return <div className="job-list-wrapper">{jobListDom}</div>;
  };

  render() {
    return this.renderJobList();
  }
}
