export const CI_PROVIDER = {
	GIT: 'GIT',
	GITHUB: 'GITHUB',
  };
  
  export const CI_PROVIDER_URL = {
	GIT: 'git',
	GITHUB: 'github',
  };
  
  export const NOTIFICATION_INTEGRATION = {
	SLACK: 'slack',
  };
  