export const CONFIG = require('config/appConfig-' + process.env.REACT_APP_ENV + '.json');

export const isDemoEnv = () => {
  return 'true' === CONFIG.DISABLE_AUTH;
};

export const isPublicDomain = () => {
  return 'true' === CONFIG.ENABLE_PUBLIC_DOMAIN;
};

export const getForesightAppUrl = () => {
  return CONFIG.FORESIGHT_APP_URL;
};