import { ForesightTabs } from 'custom-components/Tabs/Tabs';
import React, { Component } from 'react';
import './RepositoryWorkflowJobTabs.scss';

const BUTTON_TEXT_ARR = ['Steps', 'Tests', 'Processes', 'Metrics'];
export class RepositoryWorkflowJobTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.selectedTab || 'Steps',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedTab !== this.props.selectedTab) {
      this.setState({ activeTab: this.props.selectedTab });
    }
  }

  handleActiveTab = name => {
    this.props.selectTab(name.toLowerCase());
    this.setState({
      activeTab: name,
    });
  };

  renderJobTabs = () => {
    const infoMap = {
      Processes: {
        title: 'Top 250 Processes',
        desc: 'To have a meaningful analysis, you see 250 processes with the highest duration.',
      },
    };

    return (
      <ForesightTabs
        buttonTextArr={BUTTON_TEXT_ARR}
        infoMap={infoMap}
        onClick={(e, data) => this.handleActiveTab(data.name)}
        active={this.state.activeTab}
      />
    );
  };

  render() {
    return this.renderJobTabs();
  }
}
