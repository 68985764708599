import {
  GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS_FULFILLED,
  GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS_PENDING,
  GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS_REJECTED,
  GET_TEST_SUITES_OVERVIEW_LIST_FULFILLED,
  GET_TEST_SUITES_OVERVIEW_LIST_PENDING,
  GET_TEST_SUITES_OVERVIEW_LIST_REJECTED,
  SET_TEST_SUITES_OVERVIEW_FILTER_OBJECT,
  SET_TEST_SUITES_OVERVIEW_SELECTED_HIDE_FORK,
} from 'store/constants/test-suites-overview';

const initialState = {
  testSuitesOverviewFetching: false,
  testSuitesOverviewFetched: false,
  testSuitesOverviewError: null,
  testSuitesOverviewFilterFetching: false,
  testSuitesOverviewFilterFetched: false,
  testSuitesOverviewFilterError: null,
  filterContent: [],
  selectedFilterObject: [],
  content: [],
  isFetchingByInfiniteTable: false,
  lastPage: false,
  hideForkBranch: false,
  page: 0,
};

export function testSuitesOverview(state = initialState, action) {
  switch (action.type) {
    case GET_TEST_SUITES_OVERVIEW_LIST_PENDING:
      return {
        ...state,
        testSuitesOverviewFetching: true,
        testSuitesOverviewFetched: false,
        testSuitesOverviewError: null,
        isFetchingByInfiniteTable: true,
        page: 0,
        lastPage: false,
      };
    case GET_TEST_SUITES_OVERVIEW_LIST_FULFILLED: {
      const testRunsNewData = { ...action.payload.data };

      if (action.payload.data.page > 0) {
        testRunsNewData.content = [...(state.content || []), ...action.payload.data.content];
      }

      return {
        ...state,
        testSuitesOverviewFetching: false,
        testSuitesOverviewFetched: true,
        testSuitesOverviewError: null,
        lastPage: action.payload.data.lastPage,
        page: action.payload.data.page,
        content: testRunsNewData.content,
      };
    }
    case GET_TEST_SUITES_OVERVIEW_LIST_REJECTED:
      return {
        ...state,
        content: [],
        testSuitesOverviewFetching: false,
        testSuitesOverviewFetched: false,
        testSuitesOverviewError: action.payload,
        page: 0,
        lastPage: false,
      };

    case GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS_PENDING:
      return {
        ...state,
        testSuitesOverviewFilterFetching: true,
        testSuitesOverviewFilterFetched: false,
        testSuitesOverviewFilterError: null,
      };
    case GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS_FULFILLED:
      return {
        ...state,
        testSuitesOverviewFilterFetching: false,
        testSuitesOverviewFilterFetched: true,
        testSuitesOverviewFilterError: null,
        filterContent: action.payload.data,
      };
    case GET_TEST_SUITES_OVERVIEW_LIST_BY_FILTERS_REJECTED:
      return {
        ...state,
        testSuitesOverviewFilterFetching: false,
        testSuitesOverviewFilterFetched: false,
        content: [],
        testSuitesOverviewFilterError: action.payload,
      };

    case SET_TEST_SUITES_OVERVIEW_SELECTED_HIDE_FORK:
      return {
        ...state,
        hideForkBranch: action.payload.data,
      };

    case SET_TEST_SUITES_OVERVIEW_FILTER_OBJECT:
      return {
        ...state,
        selectedFilterObject: action.payload.data,
      };

    default:
      return state;
  }
}
