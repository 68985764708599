import Loadable from 'react-loadable';
import React from 'react';
import { LoadingPage, ErrorPage } from 'components';

const Loading = props => {
	if (props.error) {
		return <ErrorPage />;
	} else if (props.pastDelay) {
		return <LoadingPage />;
	} else {
		return null;
	}
};

const AsyncCiProviderIntegrationsPageContainer = Loadable({
	loader: () => import('./CiProviderIntegrationsPageContainer'),
	loading: Loading,
	delay: 300,
});

export { AsyncCiProviderIntegrationsPageContainer };
