import { Tooltip } from 'components/Tooltip';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import './CostSummary.scss';

const infoText = () => (
    <p>After gathering your workflow run duration metadata, Foresight combines that information with GitHub's <a href='https://docs.github.com/en/billing/managing-billing-for-github-actions/about-billing-for-github-actions' target="_blank" rel="noreferrer">pricing plan.</a></p>);

export class CostSummary extends Component {

    render() {
        const { totalCostSummary, title } = this.props;

        return (
            <div className='total-cost-container'>
                <div className="header">
                    <div className="title">{title}</div>
                </div>
                <div className='cards-container'>
                    <div className='sub-total-cost-card' >
                        <div className='info-part'>
                            <div className='sub-title'>Estimated Total Cost&nbsp;<Tooltip hoverable blackEdition content={infoText()}>
                                <Icon style={{ height: '20px' }} name="info circle" />
                            </Tooltip></div>
                            <div className='sub-value'><span className='dolar-symbol'>$</span>{totalCostSummary.length === 0 ? 0 : totalCostSummary.toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}