import {
  GET_ORGANIZATION_WORKFLOW_SETTINGS_FULFILLED,
  GET_ORGANIZATION_WORKFLOW_SETTINGS_PENDING,
  GET_ORGANIZATION_WORKFLOW_SETTINGS_REJECTED,
  GET_ORGANIZATION_WORKFLOW_STATUS_FULFILLED,
  GET_ORGANIZATION_WORKFLOW_STATUS_PENDING,
  GET_ORGANIZATION_WORKFLOW_STATUS_REJECTED,
  GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS_FULFILLED,
  GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS_PENDING,
  GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS_REJECTED,
  UPDATE_ORGANIZATION_WORKFLOW_SETTINGS_FULFILLED,
  UPDATE_ORGANIZATION_WORKFLOW_SETTINGS_PENDING,
  UPDATE_ORGANIZATION_WORKFLOW_SETTINGS_REJECTED,
} from '../constants/organization-workflows-settings';

const initialState = {
  status: false,
  organizationId: null,
  organizationWorkflowsSettings: {},
  organizationSettingUpdateCount: 0,
  fetching: false,
  fetched: false,
  error: null,
  update_fetching: false,
  update_fetched: false,
  update_error: null,
};

export default function organizationWorkflowsSettings(state = initialState, action) {
  const { repoId } = action.meta || { repoId: undefined };
  const { organizationId } = action.meta || { organizationId: undefined };
  const { settings } = action.meta || [];
  switch (action.type) {
    //Fetch Settings
    case GET_ORGANIZATION_WORKFLOW_SETTINGS_PENDING:
      return { ...state, fetching: true, fetched: false, error: null };
    case GET_ORGANIZATION_WORKFLOW_SETTINGS_FULFILLED:
      if (organizationId !== state.organizationId) {
        state.organizationWorkflowsSettings = {};
      }

      return {
        ...state,
        organizationId: organizationId,
        fetching: false,
        fetched: true,
        error: null,
        organizationWorkflowsSettings: generateWorkflowSettings(state, action.payload.data),
      };
    case GET_ORGANIZATION_WORKFLOW_SETTINGS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    //Fetching Status
    case GET_ORGANIZATION_WORKFLOW_STATUS_PENDING:
      return { ...state };
    case GET_ORGANIZATION_WORKFLOW_STATUS_FULFILLED:
      if (organizationId !== state.organizationId) {
        state.status = false;
      }

      return {
        ...state,
        status: action.payload.data === 'IN_PROGRESS',
      };
    case GET_ORGANIZATION_WORKFLOW_STATUS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload };

    //Fetch Setting Detail
    case GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS_PENDING:
      return setWorkflowSettingObj(state, repoId, true, false, null);
    case GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS_FULFILLED:
      return setWorkflowSettingObj(state, repoId, false, true, null, action.payload.data);
    case GET_ORGANIZATION_WORKFLOW_SETTING_DETAILS_REJECTED:
      return setWorkflowSettingObj(state, repoId, false, false, action.payload);

    //Update Setting
    case UPDATE_ORGANIZATION_WORKFLOW_SETTINGS_PENDING:
      return { ...state, update_fetching: true, update_fetched: false, update_error: null };
    case UPDATE_ORGANIZATION_WORKFLOW_SETTINGS_FULFILLED:
      return {
        update_fetching: false,
        update_fetched: true,
        update_error: null,
        organizationWorkflowsSettings: updateWorkflowSettings(state, settings),
      };
    case UPDATE_ORGANIZATION_WORKFLOW_SETTINGS_REJECTED:
      return {
        ...state,
        update_fetching: false,
        update_fetched: false,
        update_error: action.payload,
      };
    default:
      return state;
  }
}

function getWorkflowSettingObj(state, repoId) {
  const wSettingObj = state.organizationWorkflowsSettings[repoId];
  if (!wSettingObj) {
    const wSettingNewObj = {
      repoId: repoId,
      isChildrenCollapsed: true,
      settings: [],
      fetching: false,
      fetched: false,
      error: null,
    };
    state.organizationWorkflowsSettings[repoId] = wSettingNewObj;
    return wSettingNewObj;
  }
  return wSettingObj;
}

function generateRepoId({ provider, owner, name }) {
  return `${provider}/${owner}/${name}`;
}

function generateWorkflowSettings(state, data) {
  data.forEach(el => getWorkflowSettingObj(state, generateRepoId(el)));
  return { ...state.organizationWorkflowsSettings };
}

function updateWorkflowSettings(state, updatedSettings) {
  const settingMap = {};
  for (const key in state.organizationWorkflowsSettings) {
    const wSettingObj = state.organizationWorkflowsSettings[key];
    if (wSettingObj) {
      wSettingObj.settings.forEach(setting => {
        settingMap[setting.repoId + '/' + setting.id] = setting;
      });
    }
  }

  updatedSettings.forEach(el => {
    const settingObj = settingMap[el.repoId + '/' + el.id];
    if (settingObj) settingObj.watched = el.watched;
  });
  return { ...state.organizationWorkflowsSettings };
}

function setWorkflowSettingObj(state, repoId, fetching, fetched, error, data) {
  const wSettingObj = getWorkflowSettingObj(state, repoId);
  wSettingObj.fetching = fetching;
  wSettingObj.fetched = fetched;
  wSettingObj.error = error;
  if (data) {
    wSettingObj.settings = data;
  }
  return { ...state, organizationSettingUpdateCount: state.organizationSettingUpdateCount + 1 };
}
