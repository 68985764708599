export const GET_PULL_REQUEST_TEST_RUNS_LIST = 'GET_PULL_REQUEST_TEST_RUNS_LIST';
export const GET_PULL_REQUEST_TEST_RUNS_LIST_PENDING = 'GET_PULL_REQUEST_TEST_RUNS_LIST_PENDING';
export const GET_PULL_REQUEST_TEST_RUNS_LIST_REJECTED = 'GET_PULL_REQUEST_TEST_RUNS_LIST_REJECTED';
export const GET_PULL_REQUEST_TEST_RUNS_LIST_FULFILLED = 'GET_PULL_REQUEST_TEST_RUNS_LIST_FULFILLED';

export const GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS = 'GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS';
export const GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS_PENDING = 'GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS_PENDING';
export const GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS_REJECTED =
  'GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS_REJECTED';
export const GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS_FULFILLED =
  'GET_PULL_REQUEST_TEST_RUNS_LIST_BY_FILTERS_FULFILLED';

export const SET_PULL_REQUEST_TEST_RUNS_SELECTED_HIDE_FORK = 'SET_PULL_REQUEST_TEST_RUNS_SELECTED_HIDE_FORK';
export const SET_PULL_REQUEST_TEST_RUNS_STATUSES_SELECTED = 'SET_PULL_REQUEST_TEST_RUNS_STATUSES_SELECTED';
export const SET_PULL_REQUEST_TEST_RUNS_BRANCHES_SELECTED = 'SET_PULL_REQUEST_TEST_RUNS_BRANCHES_SELECTED';
export const SET_PULL_REQUEST_TEST_RUNS_TAGS_SELECTED = 'SET_PULL_REQUEST_TEST_RUNS_TAGS_SELECTED';
// http://localhost:3007/repositories/github/bcaglayan/thundra-ci-provider/pull-request/2/test-runs
