import auth from 'auth/auth-factory';
import axios from 'axios';
import { LoadingDimmer, NoDataAvailable, SomethingError } from 'components/ComponentPlaceholder';
import { PageSubHeader, PageSubHeaderTitle } from 'custom-components/PageSubHeader/PageSubHeader';
import { ForesightTable } from 'custom-components/Table/Table';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Table } from 'semantic-ui-react';
import { CONFIG } from 'utils/config-util';
import { formatDate } from 'utils/date-util';
import { ToastConfig } from 'utils/toast-util';
import './Invoices.scss';

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadingInvoiceId: '',
    };
  }

  handleDownloadInvoiceClick = invoiceId => {
    const invoiceUrl = CONFIG.apiUrl + `/organizations/${this.props.organizationId}/invoices/${invoiceId}/download`;
    this.setState({ downloadingInvoiceId: invoiceId }, () => {
      axios({
        url: invoiceUrl,
        method: 'GET',
        responseType: 'blob',
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      })
        .then(response => {
          setTimeout(() => {
            this.setState({ downloadingInvoiceId: '' });
          }, 1000);

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', invoiceId + '.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          // console.log("invoice download error");
          setTimeout(() => {
            this.setState({ downloadingInvoiceId: '' }, () => {
              toast.error('Invoice download error', ToastConfig);
            });
          }, 1000);
        });
    });
  };

  renderInvoiceRows = () => {
    let invoices = this.props.billingInvoices.billingInvoices.invoices;
    return invoices.map(invoice => (
      <Table.Row key={invoice.id} className="table-row">
        <Table.Cell className="current-org">{formatDate(invoice.created * 1000)}</Table.Cell>
        <Table.Cell>{invoice.invoiceItems[0].description}</Table.Cell>
        <Table.Cell>${invoice.total / 100}</Table.Cell>
        <Table.Cell className="last-column-invoices">
          <div>Paid</div>
          <span className={'download-link'} onClick={() => this.handleDownloadInvoiceClick(invoice.id)}>
            {this.state.downloadingInvoiceId === invoice.id ? 'Downloading' : 'Download'}
          </span>
        </Table.Cell>
      </Table.Row>
    ));
  };

  renderInvoicesTable = () => {
    const { fetched, fetching, error } = this.props.billingInvoices;

    if (fetching) {
      return (
        <div style={{ marginTop: 10 }}>
          <LoadingDimmer msg={'Loading...'} />
        </div>
      );
    }

    if (error) {
      return (
        <div style={{ marginTop: 10 }}>
          <SomethingError msg={error} />
        </div>
      );
    }

    if (fetched) {
      return (
        <ForesightTable basic="very">
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell width={4}>Date</Table.HeaderCell>
              <Table.HeaderCell width={6}>Package</Table.HeaderCell>
              <Table.HeaderCell width={4}>Invoice Total</Table.HeaderCell>
              <Table.HeaderCell width={4}>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderInvoiceRows()}</Table.Body>
        </ForesightTable>
      );
    }

    return (
      <div style={{ marginTop: 10 }}>
        <NoDataAvailable />
      </div>
    );
  };

  render() {
    return (
      <div className="invoices-main-container">
        <PageSubHeader>
          <PageSubHeaderTitle>Invoices</PageSubHeaderTitle>
        </PageSubHeader>
        {this.renderInvoicesTable()}
      </div>
    );
  }
}

export default Invoices;
