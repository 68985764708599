import { MainLayoutContainer, SourceFilesCoverageDetailsContainer } from 'containers';
import React, { Component } from 'react';
import routeList, { computePath } from 'routes';
import { defineWorkflowRunOverviewPageTitle } from 'utils/page-title-util';
import { LoadingDimmer } from '../ComponentPlaceholder';
import { RepositoryWorkflowBreadcrumb } from './Breadcrumb/RepositoryWorkflowBreadcrumb';
import { RepositoryWorkflowCardListPanel } from './CardListPanel/RepositoryWorkflowCardListPanel';
import { SourceFilesCoverageDetailsDimmer } from './ImpactAnalysis/SourceFilesCoverageDetails';
import { RepositoryWorkflowJobList } from './JobList/RepositoryWorkflowJobList';
import { RepositoryWorkflowJobTabs } from './JobTabs/RepositoryWorkflowJobTabs';
import { RepositoryWorkflowOverviewInfo } from './OverviewInfo/RepositoryWorkflowOverviewInfo';
import { RepositoryWorkflowJobMetrics } from './TabMetrics/RepositoryWorkflowJobMetrics';
import { RepositoryWorkflowJobProcesses } from './TabProcesses/RepositoryWorkflowJobProcesses';
import { RepositoryWorkflowJobStepList } from './TabSteps/RepositoryWorkflowJobStepList';
import { RepositoryWorkflowJobTests } from './TabTests/RepositoryWorkflowJobTests';
import { RepositoryWorkflowTitle } from './Title/RepositoryWorkflowTitle';
import './RepositoryWorkflowRunOverviewPage.scss';
import { dispatchWithCacheCheck } from 'utils/dispatch-with-cache-check-handler';
import { OverviewCompContainer } from 'custom-components/Overview/Overview';

export default class RepositoryWorkflowRunOverviewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedJobIdx: 0,
      selectedTab: 'steps',
      stepLogOpenStatusMap: {},
      isDetailOpen: false,
      processSortMode: 'duration',
    };
  }

  //================================================================================================
  //Fetchings
  //================================================================================================

  //Fetching Steps History
  getWorkflowRunJobStepsHistoryBySelectedJobId = idx => {
    const { workflowId: workflowRunId } = this.props.match.params;
    const { workflowRunOverview } = this.props.workflowRuns;
    const { jobs, workflow } = workflowRunOverview;
    const selectedJob = jobs[idx];
    const arrParams = {
      workflowId: workflow.id,
      jobName: selectedJob.name,
      endTime: workflowRunOverview.updatedAt,
      size: 5,
    };

    const cacheKey = `${workflowRunId}_${selectedJob.name}`;
    if (this.props.workflowRunJobHistory.jobHistory[cacheKey]) {
      dispatchWithCacheCheck(this.props.workflowRunJobHistory.jobHistory[cacheKey], () =>
        this.props.getJobHistory(workflowRunId, selectedJob.name, arrParams),
      );
    } else {
      this.props.getJobHistory(workflowRunId, selectedJob.name, arrParams);
    }
  };

  handleProcessSortChange = id => {
    this.setState({ processSortMode: id }, () => {
      this.fetchTabData();
    });
  };

  //Fetching Process
  getWorkflowRunJobProcessesBySelectedJobId = idx => {
    const { processSortMode } = this.state;
    const { jobs } = this.props.workflowRuns.workflowRunOverview;
    const selectedJob = jobs[idx];
    const { workflowId: workflowRunId } = this.props.match.params;
    this.props.getWorkflowRunJobProcesses(workflowRunId, selectedJob.id, processSortMode);
  };

  fetchTabData = () => {
    const { selectedJobIdx, selectedTab } = this.state;
    if (selectedTab === 'steps') {
      this.getWorkflowRunJobStepsHistoryBySelectedJobId(selectedJobIdx);
    } else if (selectedTab === 'processes') {
      this.getWorkflowRunJobProcessesBySelectedJobId(selectedJobIdx);
    }
  };

  //Check selected job index and tab exist
  checkSelectedJobIdxAndSelectedTabComeFromURL = () => {
    //If selectedJobIdx and selectedTab come from URL
    if (this.props.match.path === routeList.workflowRunOverviewWithJobId.path) {
      const { jobId, tabName } = this.props.match.params;
      const jobIdx = this.props.workflowRuns.workflowRunOverview.jobs.map(job => job.id).findIndex(el => el === jobId);
      this.setState({ selectedJobIdx: jobIdx === -1 ? 0 : jobIdx, selectedTab: tabName }, () => {
        this.fetchTabData();
      });
    } else {
      this.fetchTabData();
    }
  };

  //Fetching Overview
  fetchOverviewData = () => {
    const { workflowId: workflowRunId } = this.props.match.params;
    this.props.getWorkflowRunOverview(
      workflowRunId,
      () => {
        this.checkSelectedJobIdxAndSelectedTabComeFromURL();
      },
      err => {
        console.log(err);
      },
    );
  };

  componentDidMount() {
    this.fetchOverviewData();
  }

  openImpactAnalysisDetail = () => {
    this.setState({ isDetailOpen: true });
  };

  selectJob = idx => {
    const { workflowId, ciProvider, repoOwner, repoName } = this.props.match.params;
    const { selectedTab } = this.state;
    this.setState({ selectedJobIdx: idx, stepLogOpenStatusMap: {} }, () => {
      const newPath = computePath(routeList.workflowRunOverviewWithJobId.path, {
        ciProvider: ciProvider,
        repoOwner: repoOwner,
        repoName: repoName,
        workflowId: workflowId,
        jobId: this.props.workflowRuns.workflowRunOverview.jobs[idx].id,
        tabName: selectedTab,
      });
      this.props.history.push(newPath);
      this.fetchTabData();
    });
  };

  selectTab = tab => {
    const { workflowId, jobId, ciProvider, repoOwner, repoName } = this.props.match.params;
    const urlJobId = jobId ? jobId : this.props.workflowRuns?.workflowRunOverview?.jobs[0]?.id;
    this.setState({ selectedTab: tab }, () => {
      const newPath = computePath(routeList.workflowRunOverviewWithJobId.path, {
        ciProvider: ciProvider,
        repoOwner: repoOwner,
        repoName: repoName,
        workflowId: workflowId,
        jobId: urlJobId,
        tabName: tab,
      });
      this.props.history.push(newPath);
      this.fetchTabData();
    });
  };

  openStepDetails = cloneOpenStatusMap => {
    this.setState({ stepLogOpenStatusMap: cloneOpenStatusMap });
  };

  render() {
    console.log('RepositoryWorkflowRunOverviewPage, render; props: ', this.props);
    const { selectedJobIdx, stepLogOpenStatusMap, selectedTab, processSortMode } = this.state;
    const { workflowRunOverview, workflowRunOverviewFetching } = this.props.workflowRuns;
    const { name, headCommit } = workflowRunOverview;
    const { workflowId } = this.props.match.params;

    if (workflowRunOverviewFetching) {
      return (
        <MainLayoutContainer title="RepositoryWorkflowRunOverviewPage title">
          <div className="workflow-run-overview-page-wrapper loading">
            <LoadingDimmer msg={'Loading...'} />
          </div>
        </MainLayoutContainer>
      );
    }

    const overviewInfoProps = { ...this.props };
    const jobListProps = { ...this.props, selectedJobIdx: selectedJobIdx, selectJob: this.selectJob };

    const jobTabsProps = { ...this.props, selectedTab: selectedTab, selectTab: this.selectTab };
    const stepListProps = {
      ...this.props,
      selectedJobIdx: selectedJobIdx,
      stepLogOpenStatusMap: stepLogOpenStatusMap,
      openStepDetails: this.openStepDetails,
    };
    const testProps = {
      ...this.props,
      workflowRunId: workflowId,
      selectedJobIdx: selectedJobIdx,
      openImpactAnalysisDetail: this.openImpactAnalysisDetail,
    };
    const metricsProps = { ...this.props, workflowRunId: workflowId, selectedJobIdx: selectedJobIdx };
    const processesProps = this.props.workflowRunJobProcesses;

    return (
      <MainLayoutContainer
        title={defineWorkflowRunOverviewPageTitle(headCommit?.message, name, routeList.workflowRunOverview.title)}
      >
        <div className="workflow-run-overview-page-wrapper">
          <div className="header-wrapper">
            <RepositoryWorkflowBreadcrumb {...this.props} />
            <OverviewCompContainer>
              <RepositoryWorkflowTitle {...this.props} />
              <RepositoryWorkflowOverviewInfo {...overviewInfoProps} />
            </OverviewCompContainer>
          </div>
          <div className="worklow-card-list-panel-wrapper">
            <RepositoryWorkflowCardListPanel {...this.props} />
          </div>
          <div className="workflow-run-job-wrapper">
            <div className="worflow-job-list-wrapper">
              <div className="title">
                <span>Jobs</span>
              </div>
              <RepositoryWorkflowJobList {...jobListProps} />
            </div>

            <div className="workflow-job-tabs-wrapper">
              <RepositoryWorkflowJobTabs {...jobTabsProps} />
              {selectedTab === 'steps' && <RepositoryWorkflowJobStepList {...stepListProps} />}
              {selectedTab === 'tests' && <RepositoryWorkflowJobTests {...testProps} />}
              {selectedTab === 'metrics' && <RepositoryWorkflowJobMetrics {...metricsProps} />}
              {selectedTab === 'processes' && (
                <RepositoryWorkflowJobProcesses
                  selectedProcessSort={processSortMode}
                  handleProcessSortChange={this.handleProcessSortChange}
                  history={this.props.history}
                  workflowJobProcesses={processesProps}
                />
              )}
            </div>
          </div>
          <SourceFilesCoverageDetailsDimmer
            isOpen={this.state.isDetailOpen}
            closeDetailModal={() => this.setState({ isDetailOpen: false })}
          />
          <SourceFilesCoverageDetailsContainer
            isOpen={this.state.isDetailOpen}
            closeDetailModal={() => this.setState({ isDetailOpen: false })}
            workflowRunId={workflowId}
            selectedJobIdx={selectedJobIdx}
            workflowRuns={this.props.workflowRuns}
            workflowRunJobTests={this.props.workflowRunJobTests}
          />
        </div>
      </MainLayoutContainer>
    );
  }
}
