import {
  PageHeader,
  PageHeaderTitle,
  PageHeaderTitleContainer,
  PageHeaderTitleContainerSection,
} from 'custom-components/PageHeader/PageHeader';
import React from 'react';

export const TestsSuitesPageHeader = () => {
  return (
    <div>
      <PageHeader style={{ padding: '14px 0px 16px 0px' }}>
        <PageHeaderTitleContainer>
          <PageHeaderTitleContainerSection>
            <PageHeaderTitle> Test Suites </PageHeaderTitle>
          </PageHeaderTitleContainerSection>
        </PageHeaderTitleContainer>
      </PageHeader>
    </div>
  );
};
