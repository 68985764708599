import React, { Component } from 'react';
import { MainLayoutContainer } from 'containers';
import routeList from 'routes';
import './TestRunTestScreenshotsPage.scss';
import {
  getTestRunOverviewObj,
  getTestRunSuitesObj,
  isSuitesForceFetchNeeded,
  TESTRUN_OVERVIEW_PAGE_TYPE,
} from 'utils/test-run-detail-util';
import { getTestCaseOverviewDetail, getTestCaseScreenshotsDetail } from 'utils/test-detail-util';
import {
  TestRunDetailHeaderContainer,
  TestRunOverviewCardContainer,
  TestRunSuiteCardListContainer,
  TestRunTestPlatformDashBtnContainer,
  TestRunTestScreenshotsViewerContainer,
  TestRunTestTabsContainer,
} from 'containers';
import { defineTestCasePageTitle } from 'utils/page-title-util';
import { dispatchWithCacheCheck } from 'utils/dispatch-with-cache-check-handler';

class TestRunTestScreenshotsPage extends Component {
  componentDidMount() {
    this.fetchData(this.props);
  }

  getTestRunSuitesData = props => {
    const { testRunId } = props.match.params;
    const { testRunDetailMap } = props.testRunDetails;
    return getTestRunSuitesObj(testRunDetailMap, testRunId);
  };

  getTestOverviewData = props => {
    const { transactionId } = props.match.params;
    const { testDetailMap } = props.testDetails;
    return getTestCaseOverviewDetail(testDetailMap, transactionId);
  };

  getTestRunOverviewData = props => {
    const { testRunId } = props.match.params;
    const { testRunDetailMap } = props.testRunDetails;
    return getTestRunOverviewObj(testRunDetailMap, testRunId);
  };

  getTestScreenshotsData = props => {
    const { transactionId } = props.match.params;
    const { testDetailMap } = props.testDetails;
    return getTestCaseScreenshotsDetail(testDetailMap, transactionId);
  };

  fetchData = props => {
    const { ciProvider, repoOwner, repoName, testRunId, transactionId } = props.match.params;
    const testSuiteObj = this.getTestRunSuitesData(props);
    const testRunOverviewObj = this.getTestRunOverviewData(props);
    const testOverviewObj = this.getTestOverviewData(props);
    const testScreenshotsObj = this.getTestScreenshotsData(props);
    const forceSuitesFetching = isSuitesForceFetchNeeded(testSuiteObj, testRunOverviewObj);

    dispatchWithCacheCheck(testSuiteObj, () => props.getTestRunDetailTestSuits(testRunId), forceSuitesFetching);
    dispatchWithCacheCheck(testRunOverviewObj, () =>
      props.getTestRunDetailOverview(ciProvider, repoOwner, repoName, testRunId),
    );
    dispatchWithCacheCheck(testOverviewObj, () => props.getTestOverview(transactionId));
    dispatchWithCacheCheck(testScreenshotsObj, () => props.getTestScreenshots(transactionId));
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.fetchData(nextProps);
  }

  render() {
    return (
      <MainLayoutContainer title={defineTestCasePageTitle(this.props, routeList.testRunTestScreenshots.title)}>
        <div className="test-run-testcase-ss-container">
          <div className="header">
            <TestRunDetailHeaderContainer />
          </div>
          <div className="content">
            <div className="content-left">
              <TestRunSuiteCardListContainer />
            </div>
            <div className="content-right">
              <div className="line">
                <TestRunOverviewCardContainer pageType={TESTRUN_OVERVIEW_PAGE_TYPE.TEST_CASE} />
              </div>
              <div className="line">
                <TestRunTestTabsContainer selectedTab="screenshots" />
                <TestRunTestPlatformDashBtnContainer />
              </div>
              <div className="line">
                <TestRunTestScreenshotsViewerContainer />
              </div>
            </div>
          </div>
        </div>
      </MainLayoutContainer>
    );
  }
}

export default TestRunTestScreenshotsPage;
