/**
 * Axios Interceptors added for encodeURI https://github.com/axios/axios
 */

import axios from 'axios';
import * as Sentry from '@sentry/react';
import { generateUUID } from './object-util';
import { CONFIG } from './config-util';

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const traceId = generateUUID();
    config.headers['x-thundra-trace-id'] = traceId;
    if (config.headers.disableAutoEncode === true) {
      //Do Nothing
    } else {
      config.url = encodeURI(config.url);
    }
    return config;
  },
  function (err) {
    // Do something with request error
    return Promise.reject(err);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    if (error?.config?.headers) {
      const xTraceId = error.config.headers['x-thundra-trace-id'];
      const traceURL = `${CONFIG.APM_APP_URL}/tracemap/${xTraceId}`;

      Sentry.configureScope(scope => {
        scope.setTag('x-thundra-trace-id', xTraceId);
        scope.setTag('x-thundra-trace-url', traceURL);
        scope.setLevel('critical');
      });
    }
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);
