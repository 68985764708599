import axios from 'axios';
import { CONFIG } from 'utils/config-util';
import auth from 'auth/auth-factory';
import { GET_WHITELIST } from 'store/constants/whitelist';

export const getWhitelist = organizationId => {
  return {
    type: GET_WHITELIST,
    payload: axios.get(CONFIG.apiUrl + `/organizations/${organizationId}/whitelist`, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    }),
  };
};
