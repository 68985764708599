import React from 'react';
import { ExtraWarning } from '../ExtraWarning/ExtraWarning';
import { RepositoryWorkflowJobChangeImpactAnalysis } from './ChangeImpactAnalysis/RepositoryWorkflowJobChangeImpactAnalysis';
import { RepositoryWorkflowJobFailedTests } from './FailedTests/RepositoryWorkflowJobFailedTests';
import { RepositoryWorkflowJobTestOverview } from './TestsOverview/RepositoryWorkflowJobTestOverview';
import './RepositoryWorkflowJobTests.scss';

export class RepositoryWorkflowJobTests extends React.Component {
  componentDidMount() {
    this.fetchData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { workflowRunId, selectedJobIdx } = this.props;
    const { workflowRunId: nextWorkflowRunId, selectedJobIdx: nextSelectedJobIdx } = nextProps;
    if (nextWorkflowRunId !== workflowRunId || nextSelectedJobIdx !== selectedJobIdx) {
      this.fetchData(nextProps);
    }
  }

  fetchData = props => {
    const { workflowRunId, selectedJobIdx, getJobTestSummary, getJobFailedTests, getJobCoverage } = props;
    const { workflowRunOverview } = this.props.workflowRuns;

    if (workflowRunOverview && workflowRunOverview.jobs && workflowRunOverview.jobs.length > selectedJobIdx) {
      //JobId Used for Test Summary, Failed Tests, and Coverage
      const selectedJobId = workflowRunOverview.jobs[selectedJobIdx].id;
      getJobTestSummary(workflowRunId, selectedJobId);
      getJobFailedTests(workflowRunId, selectedJobId);
      getJobCoverage(workflowRunId, selectedJobId);
    }
  };

  isExtendedHeight = () => {
    const { fetched, data } = this.props.workflowRunJobTests.testingSummary;
    if (fetched) {
      if (!data) {
        return true;
      }
    }

    const { fetched: fetchedCovarage, data: dataCoverage } = this.props.workflowRunJobTests.testingCoverage;
    if (fetchedCovarage) {
      if (!dataCoverage) {
        return true;
      }
    }

    return false;
  };

  getExtraWarning = () => {
    if (localStorage.getItem('test-tab-got-extra-warning')) {
      return false;
    }

    const { fetched, data } = this.props.workflowRunJobTests.testingSummary;
    if (fetched) {
      if (!data) {
        return true;
      }
    }

    const { fetched: fetchedCovarage, data: dataCoverage } = this.props.workflowRunJobTests.testingCoverage;
    if (fetchedCovarage) {
      if (!dataCoverage) {
        return true;
      }
    }

    return false;
  };

  render() {
    const extendedClassName = this.isExtendedHeight() ? 'extended' : '';
    const newProps = { ...this.props, extendedClassName: extendedClassName };
    const isExtraWarning = this.getExtraWarning();

    return (
      <div className="test-tab-container">
        {isExtraWarning && (
          <ExtraWarning
            title="Can't see your test and change impact analysis?"
            content="If you have added Foresight's Test Kit action to your CI pipeline and can’t see your data below, check our docs to troubleshoot."
            learnMoreURL="https://docs.runforesight.com/how-to/troubleshooting"
            onClick={() => {
              localStorage.setItem('test-tab-got-extra-warning', 'true');
              this.forceUpdate();
            }}
          />
        )}
        <div className={`test-tab-header ${extendedClassName}`}>
          <RepositoryWorkflowJobChangeImpactAnalysis {...newProps} />
          <RepositoryWorkflowJobTestOverview {...newProps} />
        </div>
        <div className="test-tab-scroll">
          <RepositoryWorkflowJobFailedTests {...this.props} />
        </div>
      </div>
    );
  }
}
