import { ForesightInput } from 'custom-components/Input/Input';
import React, { Component } from 'react';
import { CardCVCElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements';
import { Divider, Form } from 'semantic-ui-react';
import './CreditCardForm.scss';

class CreditCardForm extends Component {
  render() {
    let stripeStyle = { base: { color: '#D9D9D9', fontWeight: 300 } };
    console.log('CreditCardForm, render; props, state: ', this.props, this.state);
    return (
      <Form className="credit-card-form">
        <Form.Group>
          <Form.Field required width={5} className="name-field">
            <ForesightInput
              autoComplete="off"
              required
              labelText="Name"
              placeholder="Name"
              name="name"
              onChange={this.props.onInputChange}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field required width={5} error={!!this.props.cardNumberError}>
            <label>Card Number</label>
            <CardNumberElement style={stripeStyle} onChange={this.props.onCardNumberChange} className="stripe-input" />
          </Form.Field>

          <Form.Field required width={3} error={!!this.props.cardExpiryError}>
            <label>Expiry Date</label>
            <CardExpiryElement style={stripeStyle} onChange={this.props.onCardExpiryChange} className="stripe-input" />
          </Form.Field>

          <Form.Field required width={2} error={!!this.props.cardCVCError}>
            <label>CVC</label>
            <CardCVCElement style={stripeStyle} onChange={this.props.onCardCVCChange} className="stripe-input" />
          </Form.Field>
        </Form.Group>

        <Divider />

        <Form.Field>
          <ForesightInput
            autoComplete="off"
            labelText="Billing Address"
            placeholder="Address"
            name="address"
            onChange={this.props.onInputChange}
          />
        </Form.Field>

        <Form.Group widths="equal">
          <Form.Field>
            <ForesightInput
              autoComplete="off"
              labelText="City"
              placeholder="City"
              name="city"
              onChange={this.props.onInputChange}
            />
          </Form.Field>
          <Form.Field>
            <ForesightInput labelText="State" placeholder="State" name="state" onChange={this.props.onInputChange} />
          </Form.Field>
          <Form.Field>
            <ForesightInput
              autoComplete="off"
              labelText="Country"
              placeholder="Country"
              name="country"
              onChange={this.props.onInputChange}
            />
          </Form.Field>
          <Form.Field>
            <ForesightInput
              autoComplete="off"
              labelText="ZIP Code"
              placeholder="Postal Code"
              name="postalCode"
              onChange={this.props.onPostalCodeChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

export default CreditCardForm;
