import React from 'react';
import { WORKFLOWRUN_STATUS } from 'utils/workflowrun-status';
import { WORKFLOWRUN_CONCLUSION } from 'utils/workflowrun-conclusion-status';
import { DOMAIN_ICON } from 'assets/font-icons/IconManager';

export const getWorkflowStatusIcon = (latestWorkflowRun, overrideColor) => {
  if (latestWorkflowRun.status != WORKFLOWRUN_STATUS.COMPLETED) {
    switch (latestWorkflowRun.status) {
      case WORKFLOWRUN_STATUS.IN_PROGRESS:
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8Z"
              fill={overrideColor || '#DBAB0A'}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 11.5346 4.46538 14.4 8 14.4ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
              fill={overrideColor || '#DBAB0A'}
              fillOpacity="0.5"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.9128 10.4492C14.2345 9.67269 14.4 8.84046 14.4 8H16C16 8.53871 15.9456 9.07471 15.8384 9.6C15.7365 10.0991 15.5869 10.5886 15.391 11.0615C14.989 12.0321 14.3997 12.914 13.6569 13.6569C13.564 13.7497 13.469 13.8402 13.3719 13.9282C12.6921 14.5441 11.9107 15.0393 11.0615 15.391C10.5886 15.5869 10.0991 15.7365 9.6 15.8384C9.07471 15.9456 8.53871 16 8 16V14.4C8.84046 14.4 9.67269 14.2345 10.4492 13.9128C11.2257 13.5912 11.9312 13.1198 12.5255 12.5255C13.1198 11.9312 13.5912 11.2257 13.9128 10.4492Z"
              fill={overrideColor || '#DBAB0A'}
            />
          </svg>
        );
      case WORKFLOWRUN_STATUS.QUEUED:
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8Z"
              fill={overrideColor || '#9E6A03'}
            />
          </svg>
        );
      case WORKFLOWRUN_STATUS.UNKNOWN:
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.236 4.93856C10.9586 3.73679 9.98089 2.76238 8.77829 2.48922C6.59835 1.9928 4.665 3.63576 4.665 5.7313C4.665 5.74781 4.66607 5.7723 4.6682 5.80479C4.70544 6.23013 5.06287 6.56413 5.49783 6.56413C5.94998 6.56413 6.31834 6.20321 6.33095 5.7539C6.33011 4.83445 7.07682 4.06576 7.99626 4.06576C8.91487 4.06576 9.66192 4.8128 9.66192 5.73141C9.66192 6.64919 8.91487 7.39706 7.99626 7.39706C7.54654 7.39706 7.17843 7.76434 7.17177 8.21906V10.3118C7.17177 10.7716 7.54487 11.1447 8.00459 11.1447C8.46515 11.1447 8.83742 10.7716 8.83742 10.3118V9.24511C8.83742 9.06438 8.95818 8.91281 9.12808 8.85201C10.638 8.30568 11.6449 6.71165 11.236 4.93856ZM8 13.6C8.44183 13.6 8.8 13.2418 8.8 12.8C8.8 12.3582 8.44183 12 8 12C7.55817 12 7.2 12.3582 7.2 12.8C7.2 13.2418 7.55817 13.6 8 13.6Z"
              fill={overrideColor || '#8B949E'}
            />
          </svg>
        );
      default:
        return <span> Not implemented {latestWorkflowRun.conclusion} status icon </span>;
    }
  } else {
    switch (latestWorkflowRun.conclusion) {
      case WORKFLOWRUN_CONCLUSION.SUCCESS:
        return (
          <svg id="passed" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.8157 6.56569C12.1281 6.25327 12.1281 5.74673 11.8157 5.43431C11.5033 5.12189 10.9967 5.12189 10.6843 5.43431L7.19063 8.928L5.24976 7.3753C4.90475 7.0993 4.40131 7.15523 4.1253 7.50024C3.8493 7.84525 3.90523 8.34869 4.25024 8.62469L6.75024 10.6247C7.06856 10.8793 7.52744 10.8539 7.81569 10.5657L11.8157 6.56569Z"
              fill={overrideColor || '#238636'}
            />
          </svg>
        );

      case WORKFLOWRUN_CONCLUSION.FAILURE:
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.56569 4.43432C5.25327 4.1219 4.74673 4.1219 4.43431 4.43432C4.12189 4.74673 4.12189 5.25327 4.43431 5.56569L6.86863 8L4.43431 10.4343C4.12189 10.7467 4.12189 11.2533 4.43431 11.5657C4.74673 11.8781 5.25327 11.8781 5.56569 11.5657L8 9.13137L10.4343 11.5657C10.7467 11.8781 11.2533 11.8781 11.5657 11.5657C11.8781 11.2533 11.8781 10.7467 11.5657 10.4343L9.13137 8L11.5657 5.56569C11.8781 5.25327 11.8781 4.74673 11.5657 4.43432C11.2533 4.1219 10.7467 4.1219 10.4343 4.43432L8 6.86863L5.56569 4.43432Z"
              fill={overrideColor || '#F85149'}
            />
          </svg>
        );

      case WORKFLOWRUN_CONCLUSION.ACTION_REQUIRED:
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 6C8.44183 6 8.8 6.35817 8.8 6.8V10.2C8.8 10.6418 8.44183 11 8 11C7.55817 11 7.2 10.6418 7.2 10.2V6.8C7.2 6.35817 7.55817 6 8 6Z"
              fill={overrideColor || '#D29922'}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.58885 13.9L13.4111 13.8472L8 2.78885L2.58885 13.9ZM7.10557 0.999999L0.723607 14.0528C0.391156 14.7177 0.874652 15.5 1.61803 15.5L14.382 15.4472C15.1253 15.4472 15.6088 14.6649 15.2764 14L8.89443 0.999998C8.5259 0.26295 7.4741 0.26295 7.10557 0.999999Z"
              fill={overrideColor || '#D29922'}
            />
            <path
              d="M8.8 12.4C8.8 12.8418 8.44183 13.2 8 13.2C7.55817 13.2 7.2 12.8418 7.2 12.4C7.2 11.9582 7.55817 11.6 8 11.6C8.44183 11.6 8.8 11.9582 8.8 12.4Z"
              fill={overrideColor || '#D29922'}
            />
          </svg>
        );

      case WORKFLOWRUN_CONCLUSION.CANCELLED:
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.6 5.66274V10.3373L5.66274 14.4H10.3373L14.4 10.3373V5.66274L10.3373 1.6H5.66274L1.6 5.66274ZM11.2929 0.292893C11.1054 0.105357 10.851 0 10.5858 0H5.41421C5.149 0 4.89464 0.105357 4.70711 0.292893L0.292893 4.70711C0.105357 4.89464 0 5.149 0 5.41421V10.5858C0 10.851 0.105357 11.1054 0.292893 11.2929L4.70711 15.7071C4.89464 15.8946 5.149 16 5.41421 16H10.5858C10.851 16 11.1054 15.8946 11.2929 15.7071L15.7071 11.2929C15.8946 11.1054 16 10.851 16 10.5858V5.41421C16 5.149 15.8946 4.89464 15.7071 4.70711L11.2929 0.292893ZM9 11.5C9 12.0523 8.55228 12.5 8 12.5C7.44772 12.5 7 12.0523 7 11.5C7 10.9477 7.44772 10.5 8 10.5C8.55228 10.5 9 10.9477 9 11.5ZM8.8 4.8C8.8 4.35817 8.44183 4 8 4C7.55817 4 7.2 4.35817 7.2 4.8V8.7C7.2 9.14183 7.55817 9.5 8 9.5C8.44183 9.5 8.8 9.14183 8.8 8.7V4.8Z"
              fill={overrideColor || '#8B949E'}
            />
          </svg>
        );
      case WORKFLOWRUN_CONCLUSION.NEUTRAL:
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 4H12V12H4V4Z" fill={overrideColor || '#8B949E'} />
          </svg>
        );

      case WORKFLOWRUN_CONCLUSION.SKIPPED:
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.4 8C14.4 11.5346 11.5346 14.4 8 14.4C4.46538 14.4 1.6 11.5346 1.6 8C1.6 4.46538 4.46538 1.6 8 1.6C11.5346 1.6 14.4 4.46538 14.4 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM11.0657 6.06568C11.3781 5.75326 11.3781 5.24673 11.0657 4.93431C10.7533 4.62189 10.2467 4.62189 9.93431 4.93431L4.93431 9.93431C4.62189 10.2467 4.62189 10.7533 4.93431 11.0657C5.24673 11.3781 5.75327 11.3781 6.06569 11.0657L11.0657 6.06568Z"
              fill={overrideColor || '#8B949E'}
            />
          </svg>
        );

      case WORKFLOWRUN_CONCLUSION.STALE:
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 11.5346 4.46538 14.4 8 14.4ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
              fill={overrideColor || '#8B949E'}
            />
          </svg>
        );

      case WORKFLOWRUN_CONCLUSION.TIMED_OUT:
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.56569 4.43432C5.25327 4.1219 4.74673 4.1219 4.43431 4.43432C4.12189 4.74673 4.12189 5.25327 4.43431 5.56569L6.86863 8L4.43431 10.4343C4.12189 10.7467 4.12189 11.2533 4.43431 11.5657C4.74673 11.8781 5.25327 11.8781 5.56569 11.5657L8 9.13137L10.4343 11.5657C10.7467 11.8781 11.2533 11.8781 11.5657 11.5657C11.8781 11.2533 11.8781 10.7467 11.5657 10.4343L9.13137 8L11.5657 5.56569C11.8781 5.25327 11.8781 4.74673 11.5657 4.43432C11.2533 4.1219 10.7467 4.1219 10.4343 4.43432L8 6.86863L5.56569 4.43432Z"
              fill={overrideColor || '#F85149'}
            />
          </svg>
        );
      case WORKFLOWRUN_STATUS.UNKNOWN:
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.236 4.93856C10.9586 3.73679 9.98089 2.76238 8.77829 2.48922C6.59835 1.9928 4.665 3.63576 4.665 5.7313C4.665 5.74781 4.66607 5.7723 4.6682 5.80479C4.70544 6.23013 5.06287 6.56413 5.49783 6.56413C5.94998 6.56413 6.31834 6.20321 6.33095 5.7539C6.33011 4.83445 7.07682 4.06576 7.99626 4.06576C8.91487 4.06576 9.66192 4.8128 9.66192 5.73141C9.66192 6.64919 8.91487 7.39706 7.99626 7.39706C7.54654 7.39706 7.17843 7.76434 7.17177 8.21906V10.3118C7.17177 10.7716 7.54487 11.1447 8.00459 11.1447C8.46515 11.1447 8.83742 10.7716 8.83742 10.3118V9.24511C8.83742 9.06438 8.95818 8.91281 9.12808 8.85201C10.638 8.30568 11.6449 6.71165 11.236 4.93856ZM8 13.6C8.44183 13.6 8.8 13.2418 8.8 12.8C8.8 12.3582 8.44183 12 8 12C7.55817 12 7.2 12.3582 7.2 12.8C7.2 13.2418 7.55817 13.6 8 13.6Z"
              fill={overrideColor || '#8B949E'}
            />
          </svg>
        );

      default:
        return <span> Not implemented {latestWorkflowRun.conclusion} conclusion icon </span>;
    }
  }
};

export const getWorkflowStatusIconName = (status, conclusion) => {
  if (status != WORKFLOWRUN_STATUS.COMPLETED) {
    switch (status) {
      case WORKFLOWRUN_STATUS.IN_PROGRESS:
        return DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.IN_PROGRESS;
      case WORKFLOWRUN_STATUS.QUEUED:
        return DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.QUEUED;
      case WORKFLOWRUN_STATUS.UNKNOWN:
        return DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.UNKNOWN;
      default:
        return <span> Not implemented {conclusion} status icon </span>;
    }
  } else {
    switch (conclusion) {
      case WORKFLOWRUN_CONCLUSION.SUCCESS:
        return DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.SUCCESS;

      case WORKFLOWRUN_CONCLUSION.FAILURE:
        return DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.FAILURE;

      case WORKFLOWRUN_CONCLUSION.ACTION_REQUIRED:
        return DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.ACTION_REQUIRED;

      case WORKFLOWRUN_CONCLUSION.CANCELLED:
        return DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.CANCELLED;
      case WORKFLOWRUN_CONCLUSION.NEUTRAL:
        return DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.NEUTRAL;

      case WORKFLOWRUN_CONCLUSION.SKIPPED:
        return DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.SKIPPED;

      case WORKFLOWRUN_CONCLUSION.STALE:
        return DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.STALE;

      case WORKFLOWRUN_CONCLUSION.TIMED_OUT:
        return DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.TIMEOUT;
      case WORKFLOWRUN_STATUS.UNKNOWN:
        return DOMAIN_ICON.GITHUB.WORKFLOW_RUN_STATUS.UNKNOWN;

      default:
        return <span> Not implemented {conclusion} conclusion icon </span>;
    }
  }
};
