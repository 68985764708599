import { connect } from 'react-redux';
import { RepositoryWorkflowRunsList } from 'components';
import { withRouter } from 'react-router';
import dispatchWithExceptionHandler from 'utils/dispatch-with-exception-handler';
import { getWorkflowRuns } from 'store/actions/workflow-runs';
import {
  getWorkflowRunFilters,
  setSelectedBranches,
  setSelectedConclusions,
  setSelectedEvents,
  setSelectedWorkflowIds,
} from 'store/actions/workflow-runs-meta';

function mapStateToProps(store) {
  return {
    workflowRunsMeta: store.workflowRunsMeta,
    workflowRuns: store.workflowRuns,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getWorkflowRuns(
      ciProvider,
      repoOwner,
      repoName,
      pageNo,
      pageSize,
      workflowIds,
      branches,
      conclusions,
      events,
      hideFork,
      onSuccess,
      onError,
    ) {
      dispatchWithExceptionHandler(
        getWorkflowRuns(
          ciProvider,
          repoOwner,
          repoName,
          pageNo,
          pageSize,
          workflowIds,
          branches,
          conclusions,
          events,
          hideFork,
        ),
        false, //Organization Switch caused Error
        onSuccess,
        onError,
      );
    },
    getWorkflowRunFilters(ciProvider, repoOwner, repoName, hideFork, onSuccess, onError) {
      dispatchWithExceptionHandler(
        getWorkflowRunFilters(ciProvider, repoOwner, repoName, hideFork),
        false, //  Organization Switch caused Error
        onSuccess,
        onError,
      );
    },
    setSelectedWorkflowIds(ids) {
      dispatch(setSelectedWorkflowIds(ids));
    },
    setSelectedConclusions(conclusions) {
      dispatch(setSelectedConclusions(conclusions));
    },
    setSelectedBranches(branches) {
      dispatch(setSelectedBranches(branches));
    },
    setSelectedEvents(events) {
      dispatch(setSelectedEvents(events));
    },
  };
}

const RepositoryWorkflowsRunsListContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepositoryWorkflowRunsList),
);
export default RepositoryWorkflowsRunsListContainer;
